import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`  
  background-color: ${theme.colors.cardB1};  
`;

export const Buttons = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: row;
`;

export const ButtonNotifications = styled.div`
  width:40%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.cardB2};
  border-top-width: 1px;
  border-top-color: ${theme.colors.iconsOff};
  border-left-width: 1px;
  border-left-color: ${theme.colors.iconsOff};  
  border-right-width: 1px;
  border-right-color: ${theme.colors.iconsOff};
  padding: 13px 5px;
`;

export const ButtonProfile = styled.div`
  width:60%;  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.cardB1};  
  padding: 13px 5px;
  cursor: pointer;
`;

export const TextButton = styled.p`
  color: ${theme.colors.title};  
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: 3px;
  position: relative;
`;

export const TextButtonOff = styled.p`
  color: ${theme.colors.iconsOff};  
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: 3px;
`;

export const Content = styled.div`
  position: relative;
  width:100%; 
  background-color: ${theme.colors.cardB2};
  padding: 10px;
  border-top-left-radius: 5px;
`;

export const IconNotification = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: yellow;
    position: absolute;
    right: -6px;
    top: 0px;
`;

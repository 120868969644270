import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  padding: 10px;
  background-color: ${theme.colors.cardB3};
  border-radius: 10px;
  width: 100%;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

export const Title = styled.span`
  color: ${theme.colors.title};
  font-weight: 600;
  font-size: 14px;
`;

export const Description = styled.span`
  color: ${theme.colors.gray};
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90%;
`;

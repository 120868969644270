import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  padding: 10px;
  background: ${appTheme.background};
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.p`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const InfoText = styled.p`
  color: ${appTheme.title};
  text-align: center;
  margin-bottom: 15px;
`;

export const ButtonContainer = styled.div`
  display:flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const CancelButton = styled.button`
  border: solid 2px;
  border-color: white;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px;
  background-color: unset;
  cursor: pointer;
`;

export const CancelButtonText = styled.p`
  color: white;
  text-transform: uppercase;
  font-weight: 500;
`;

export const RemoveButton = styled.button`
  border: solid 2px;
  border-color: ${appTheme.redAlert};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: unset;
  margin: 5px;
  cursor: pointer;
`;

export const RemoveButtonText = styled.p`
  color: ${appTheme.redAlert};
  text-transform: uppercase;
  font-weight: 500;
`;

export enum TrainingTypes {
  RUN = 'Run',
  DRILLS = 'Drills',
  RIDE = 'Ride',
  SWIM = 'Swim',
  WEIGHT_TRAINING = 'WeightTraining',
  WALK = 'Walk',
  CARDIO = 'Cardio',
  OTHERS = 'Others'
}

export enum TrainingActivityTypes {
  DURATION = 'DURATION'
}

export enum TrainingTypesAvailableForExerciseFlow {
  Drills = 'DRILLS',
  WeightTraining = 'WEIGHTTRAINING',
  Cardio = 'CARDIO',
  Others = 'OTHERS'
}

export enum executedStatus {
  EXECUTED_HIGH = 'EXECUTED_HIGH',
  EXECUTED_OVER = 'EXECUTED_OVER',
  EXECUTED_PARTIAL = 'EXECUTED_PARTIAL',
  EXECUTED_MEDIUM = 'EXECUTED_MEDIUM',
  EXECUTED_LOW = 'EXECUTED_LOW'
}

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  background-color: ${appTheme.cardB3};
  padding: 8px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  @media (max-width: 667px) {
    padding: 4px;
    font-size: 5px;
    margin-bottom: 5px;
  }
`;

export const ActivityName = styled.div`
  color: ${appTheme.text};
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 667px) {
    font-size: 8px;
  }
`;

export const ActivityDate = styled.div`
  color: ${appTheme.text};
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: end;
  align-items: center;
  justify-items: center;
  

  @media (max-width: 667px) {
    font-size: 8px;
  }
`;

export const TimeText = styled.div`
  color: ${appTheme.text};
  font-size: 13px;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const ActivityInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActivityContainer = styled.div`
  width: 100%;
  display: flex;
 
  justify-content:space-between;
`;

export const DataContainer = styled.div`
  display: flex;  
  align-items: end;
  justify-content: flex-end;
  
`;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';

import {
  Container, Title, Button,
} from './style';

interface BackArrowI {
  text: string;
  bigger?:boolean;
  backTo?: string;
  callback?: () => void;
}

export default function BackArrow({
  text, bigger = true, backTo, callback,
}: BackArrowI) {
  const navigate = useNavigate();
  const goBack = () => {
    if (backTo) return navigate(backTo);
    if (callback) return callback();
    return navigate(-1);
  };

  return (
    <Container>
      <Button type="button" onClick={goBack}>
        <BsChevronLeft color="#FFFFFF" size={bigger ? 24 : 22} />
      </Button>
      <Title onClick={goBack}>
        {' '}
        {text}
      </Title>
    </Container>
  );
}

import styled from 'styled-components';
import theme from '../../../../theme';

export const ModalContent = styled.div`
  width: 100%;  
  padding: 0px 25px 35px 25px;    
  border-radius: 5px;
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${theme.colors.title};
  margin-bottom: 23px;
`;

export const ModalText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.colors.title};
`;

export const StyledLink = styled.a`  
  text-decoration-color: ${theme.colors.title}  
`;

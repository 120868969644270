import styled from 'styled-components';
import THEME from '../../theme';

export const Table = styled.table`
    width: 100%;
    text-align: center;
    padding: 5px;

    th {
        color: ${THEME.colors.gray};
        padding: 5px;
    }

    td {
        color: ${THEME.colors.title};
        padding: 5px;
    }
`;

export const OptionsButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
  ArrowButton, Button, Container, Content,
} from './style';

function Pagination({
  billsPerPage, totalBills, paginate, currentPage,
}: any) {
  const pageNumbers = [];
  const pageQtd = Math.ceil(totalBills / billsPerPage);

  for (let i = 1; i <= pageQtd; i++) {
    pageNumbers.push(i);
  }

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  return (
    <div>
      <ul>
        <Content>
          <ArrowButton
            type="button"
            disabled={currentPage === 1}
            onClick={prevPage}
          >
            <MdChevronLeft size={15} />
          </ArrowButton>

          {pageNumbers.map((number) => (
            <Container key={number}>
              <Button
                className={number === currentPage ? 'selected' : ''}
                type="button"
                onClick={() => paginate(number)}
              >
                {number}
              </Button>
            </Container>
          ))}

          <ArrowButton
            type="button"
            disabled={currentPage === pageQtd}
            onClick={nextPage}
          >
            <MdChevronRight size={15} />
          </ArrowButton>
        </Content>
      </ul>
    </div>
  );
}

export default Pagination;

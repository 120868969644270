import React from 'react';
import InconNotification from './styles';

function IconNotifications() {
  return (
    <InconNotification />
  );
}

export default IconNotifications;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  margin-top: 15px;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
`;

export const AvailablePoints = styled.div`
  width: 270px;
  display: flex;
  background-color:#0D0D3D;
  align-items: center;
  align-self: center;
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  @media (max-width: 667px) {
       width: 135px;
  }
`;
export const PointText = styled.div`
  color: ${theme.colors.title};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
   @media (max-width: 667px) {
       font-size: 11px;
  }
`;

export const Header = styled.div`
  color:#FBE308;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Text = styled.div`
  color:${theme.colors.title};
  font-size: 14px;
  font-weight: 500;
`;

export const CountContainer = styled.div`
font-size: 32px;
font-weight:700;
color:${theme.colors.title};
 @media (max-width: 667px) {
       font-size: 24px;
  }
`;

export const PendingPoints = styled.div`
  width: 270px;
  display: flex;
  background-color:#0D0D3D;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
   @media (max-width: 667px) {
       width: 135px;
  }
`;

export const ButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
align-self: center;
padding-top: 10px;
`;

export const Button = styled.button`
background-color: ${theme.colors.cardB2};
border: none;
cursor: pointer;
`;
export const TextButton = styled.div`
color: ${theme.colors.gray};
text-decoration: underline;
font-size: 13px;
font-weight: 500;
`;

import React, { useEffect } from 'react';
import {
  Container, Item, Label, LabelContainer, List, Radio, RadioContainer, RadioInner, Title,
} from './styles';
import { appTheme } from '../../../../theme';
import { IStep } from '../../index';

export interface ISetDistanceScreenProps {
  step: IStep;
  onClick: (value: any) => void;
  setButtonDisabled: (set: boolean) => void;
  distance?: number | null;
}

function SetDistanceScreen({
  step, distance, onClick, setButtonDisabled,
}: ISetDistanceScreenProps) {
  useEffect(() => {
    if (distance) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [distance]);

  return (
    <Container>
      <Title>{step.title}</Title>

      <List>
        {
          step.data.map((item: any) => (
            <Item
              key={item.label}
              onClick={() => onClick(item.value)}
              style={{ borderColor: `${distance === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: distance === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: distance === item.value ? appTheme.title : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{item.label}</Label>
              </LabelContainer>
            </Item>
          ))
        }
      </List>
    </Container>
  );
}

export default SetDistanceScreen;

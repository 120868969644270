import styled from 'styled-components';
import theme from '../../theme';

interface ButtonI {
  secondary?: boolean
  danger?: boolean
  disabled?: boolean
}

export const Button = styled.button<ButtonI>`
  min-width: 200px;
  background: ${(props) => (props.secondary || props.danger ? 'transparent' : theme.colors.graph)};
  padding: 4% 12%;
  margin: 0 auto;
  margin: ${(props) => (props.secondary || props.danger ? 2 : 1)}%;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border-color: ${(props) => (props.danger ? theme.colors.red : theme.colors.title)};
  border-width: ${(props) => (props.secondary || props.danger ? 1 : 0)}px;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  cursor: pointer;
`;

export const Label = styled.span<ButtonI>`
  color: ${(props) => (props.danger ? theme.colors.red : theme.colors.title)};
  font-size: 12px;
  font-weight: bold;
`;

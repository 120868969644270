import { createSlice } from '@reduxjs/toolkit';
import { format, addHours } from 'date-fns';
import toast from 'react-hot-toast';

import { AppThunk } from '.';
import api from '../services/api';
import {
  ExerciseI,
  exercisesTypes,
  TrainingActivityI,
  TrainingGroupsI,
} from '../types/Exercise';

export interface stateI {
  courses: {
    [key in exercisesTypes]: Array<ExerciseI>;
  },
  inProgressExercise: ExerciseI | null,
  loading: boolean
}

const initialCourses = Object.values(exercisesTypes).reduce((accumulator, exerciseType) => ({
  ...accumulator,
  [exerciseType]: [],
}), {} as { [key in exercisesTypes]: Array<ExerciseI> });

const exercises = createSlice({
  name: 'exercises',
  initialState: {
    courses: initialCourses,
    inProgressExercise: null,
    loading: false,
  } as stateI,
  reducers: {
    setExercises: (state, action) => {
      const { type, data } = action.payload;

      if (Object.values(exercisesTypes).includes(type as exercisesTypes)) {
        state.courses[type as exercisesTypes] = data;
      }
    },

    startExercise: (state, action) => {
      const { payload } = action;
      state.inProgressExercise = { ...payload, start_date: format(addHours(new Date(), 3), 'yyyy-MM-dd HH:mm:ss') };
    },

    startLoading: (state) => {
      state.loading = true;
    },

    endLoading: (state) => {
      state.loading = false;
    },
  },

});

export default exercises.reducer;

export const {
  setExercises, startExercise, startLoading, endLoading,
} = exercises.actions;

export const loadExercises = (type: exercisesTypes, reload: boolean = false): AppThunk => (
  async (dispatch, getState) => {
    dispatch(startLoading());
    if (getState().exercises.courses[type]?.length <= 0 || reload) {
      await api.get(`/trainings?category_type=${type}`)
        .then((res) => {
          const { data } = res;
          const images: Array<{uri: string}> = [];
          data?.forEach((training: ExerciseI) => {
            training?.trainingGroups.forEach((group: TrainingGroupsI) => {
              group?.trainingActivities.forEach(async (activity: TrainingActivityI) => {
                const { media_link } = activity;
                if (media_link) images.push({ uri: media_link });
              });
            });
          });

          dispatch(setExercises({ data, type }));
        })
        .catch(() => {
          toast.error('Erro de conexão , tente novamente mais tarde!');
        });
    }
    dispatch(endLoading());
  }
);

export const getExercises = (type: exercisesTypes, callback: any, id?: number | null): AppThunk => (
  (_, getState) => {
    callback(id
      ? getState().exercises.courses[type].find((element) => element?.id === id)
      : getState().exercises.courses[type]);
  }
);

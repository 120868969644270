import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatMonthNumberToNameOfMonth(monthNumber: number): string {
  return format(new Date().setMonth(monthNumber), 'LLLL', { locale: ptBR });
}

export function formatDayNumberToDayNameOfWeek(date: string): string {
  const weekDayNumber = new Date(date).getDay() + 1;
  const currentWeekDay = new Date().getDay();
  const weekDayOffset = currentWeekDay - weekDayNumber;

  const monthDayOffseted = subDays(new Date(), weekDayOffset);

  return format(monthDayOffseted, 'EEEEEE', { locale: ptBR });
}

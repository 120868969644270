import styled from 'styled-components';
import theme from '../../theme';

type DirectionType = 'top' | 'right' | 'bottom' | 'left';

interface ChevronProps {
  direction: DirectionType;
}

export const Chevron = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 0.16rem 0.16rem 0 0;
  height: 0.6rem;
  width: 0.6rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === 'top' && 'rotate(-45deg)'};
  transform: ${(p) => p.direction === 'right' && 'rotate(45deg)'};
  transform: ${(p) => p.direction === 'bottom' && 'rotate(135deg)'};
  transform: ${(p) => p.direction === 'left' && 'rotate(-135deg)'};
`;

export const Container = styled.div`  
  padding: 0 1.25rem;
  border-radius: 0px 0px 10px 10px;

  svg {
    margin-right: 10px;
  }

  &:nth-of-type(odd){
    background-color: ${theme.colors.cardB2};
    z-index: 2;
  }
  &:nth-of-type(even){
    background-color: ${theme.colors.cardB3};
    z-index: 1;
  }

  & + & {
    margin-top: -0.125rem;
  }
`;

interface TitleProps {
  pointer: boolean
}

export const Title = styled.div<TitleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.25;
  ${(props) => (props.pointer ? 'cursor: pointer;' : '')}
  color:${theme.colors.title};
  text-transform: uppercase;  

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const ContentWrapper = styled.div<{ maxHeight: number }>`
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 0 0 1rem;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.5;
`;

export const Layout = styled.div`
  padding: 2rem;
  background: #f5f5f5;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
`;

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonContainer = styled.div`
    align-items: center;
    padding-right: 3px;
`;

export const Button = styled.button`
   background-color: ${theme.colors.cardB1};
   border: none;
`;

export const TextButton = styled.span`
   color: ${theme.colors.gray};
   text-decoration: underline;
   font-size: 14px;
   font-weight: 500;
   line-height: 12px;
@media (max-width: 768px) {
      font-size: 11px;
  }
  cursor:pointer;
`;

export const ModalText = styled.p`
  color: ${theme.colors.title};
  font-size: 14px;
`;
export const ItemsContainer = styled.div`
  flex-direction: column;
  margin-top: 10px;
  padding-left: 5px;
`;

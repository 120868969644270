import styled from 'styled-components';

export const Button = styled.button`
  background-color: white;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  height: 45px;
  cursor: pointer;
  font-size: 12pt;
  font-weight: bold;
  color: black;
  transition: 0.3s;
  margin-top: 20px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

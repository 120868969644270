import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.cardB1};
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.title};
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
`;

export const Header = styled.div`
  width: 100%;
  margin: 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderText = styled.div`
  color: ${theme.colors.title};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

interface IContentContainer {
  transparent?: boolean
}

export const ContentContainer = styled.div<IContentContainer>`
  width: 100%;
  margin-top: 20px;
  padding: 25px;
  background-color: ${(props) => (props.transparent ? 'transparent' : theme.colors.cardB2)};
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => (props.transparent
    ? 'border: solid 1px transparent;'
    : 'border: solid 1px #1B63F2;')}
  z-index: -1;
`;

export const ContentContainerText = styled.div`
  color: ${theme.colors.title};
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
`;

interface IActionButton {
  selected?: boolean
  disabled?: boolean
}

export const ActionButton = styled.div<IActionButton>`
  cursor: pointer;
  position: relative;
  width: 150px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: ${theme.colors.title};
  border-radius: 10px;
  background-color: ${theme.colors.cardB2};
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
  ${(props) => (props.selected
    ? 'border: solid 1px #1B63F2;'
    : 'border: solid 1px transparent;')}

  @media (max-width: 667px) {
    width: 100px;
  }
`;

export const WhiteActionButton = styled(ActionButton)`
  border-radius: 5px;
  width: 100%;
  background-color: ${theme.colors.title};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextButton = styled.div`
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  cursor: pointer;
`;

export const ButtonTitle = styled.div`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;

export const WhiteButtonTitle = styled(ButtonTitle)`
  color: black;
  font-weight: 700;
  font-size: 14px;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-bottom: 20px;
  }

  @media (max-width: 667px) {
    padding: 0 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FooterButtonsContainer = styled.div`
  padding: 0 40px;
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
`;

export const ResultContainer = styled.div`
  margin-top: 30px;
  display: flex;
  border-radius: 10px;
  width: 100%;
  height: 53px;
  background-color: ${theme.colors.cardB3};
  border: solid 1px #1B63F2;
`;

export const ResultDistance = styled.div`
  display: flex;
  background-color: ${theme.colors.cardB1};
  min-width: 60px;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 1px;
`;

export const ResultData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ResultTitle = styled.div`
  color: ${theme.colors.title};

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const ResultValue = styled.div`
  color: ${theme.colors.title};

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const ContainerSVG = styled.div`
  position: absolute;
  z-index: 999;
  transform: translate(-50%, 80%);
  left: 50%;
`;

export const ErrorText = styled.div`
  color: ${theme.colors.secondary};
  font-size: 11px;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
`;

export const Card = styled.img`
  width: 100%;
  border-radius: 10px;
  background-color: ${theme.colors.cardB4};
  margin-bottom: 13px;
`;

import React from 'react';
import { MdChevronRight } from 'react-icons/md';
import {
  Container, Distance, IconContainer, ImageButton, Info, InfoContainer, Medal, Title,
} from './styles';

interface Props {
  image: string,
  medal: string,
  title: string,
  info: string,
  distance: number,
  action: () => void,
}

export default function ChallengeCardList({
  image, medal, title, info, distance, action,
}: Props) {
  return (
    <Container onClick={action}>
      <ImageButton image={image}>
        <Medal src={medal} />
        <InfoContainer>
          <Title>
            {title}
          </Title>
          <Distance>
            {`${distance}KM`}
          </Distance>
          <Info>
            {info}
          </Info>
        </InfoContainer>
        <IconContainer onClick={() => {}}>
          <MdChevronRight size={50} color="white" />
        </IconContainer>
      </ImageButton>
    </Container>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import DoughnutChart from '../../../DoughnutChart';
import { RootState } from '../../../../store';
import {
  Card,
  CardFooter,
  CardNumber,
  CardNumberContainer,
  CardTitle,
  ContainerCards,
  GraphContainer,
} from './styles';

import RunIcon from '../../../../assets/progress/Run.svg';
import WeightTrainingIcon from '../../../../assets/progress/WeightTraining.svg';
import DrillsIcon from '../../../../assets/progress/Drills.svg';

import { ICalendarResult } from '../../../../store/CalendarProgress.store';
import THEME from '../../../../theme';

const width = window.innerWidth;

interface IWeekData {
  weightsTotal: number;
  runsTotal: number;
  drillsTotal: number;
  weightsRegular: number;
  runsRegular: number;
  drillsRegular: number;
}

function resetGMT(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

const isDateInThisWeek = (date: Date) => {
  const now = new Date();

  const start = resetGMT(startOfWeek(now, { weekStartsOn: 1 }));
  const end = resetGMT(endOfWeek(now, { weekStartsOn: 1 }));

  return isWithinInterval(date, { start, end });
};

function filterWeekData(monthData: ICalendarResult[]) {
  const weekData: IWeekData = {
    weightsTotal: 0,
    runsTotal: 0,
    drillsTotal: 0,
    weightsRegular: 0,
    runsRegular: 0,
    drillsRegular: 0,
  };

  monthData.forEach((dayData: ICalendarResult) => {
    if (isDateInThisWeek(new Date(dayData.start_date))) {
      dayData.trainings.forEach((training) => {
        if (training.regular) {
          weekData.weightsRegular += training.type === 'WeightTraining' || training.type === 'Workout' ? 1 : 0;
          weekData.runsRegular += training.type === 'Run' ? 1 : 0;
          weekData.drillsRegular += training.type === 'Drills' ? 1 : 0;
        }

        weekData.weightsTotal += training.type === 'WeightTraining' || training.type === 'Workout' ? 1 : 0;
        weekData.runsTotal += training.type === 'Run' ? 1 : 0;
        weekData.drillsTotal += training.type === 'Drills' ? 1 : 0;
      });
    }
  });

  return weekData;
}

const baseGraphData = {
  data: {
    datasets: [{
      data: [1, 1],
      borderWidth: 2,
      borderColor: [THEME.colors.cardB3],
    }],
  },
  options: {
    cutout: '70%',
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  },
};
const baseGraphCanvasStyle = {
  height: width > 667 ? 100 : 60,
  width: width > 667 ? 100 : 60,
};
const cardIconSize = width > 667 ? 27 : 20;

function Cards() {
  const weekData = filterWeekData(useSelector((state: RootState) => state.calendarData.calendarData.result));
  const graphData = React.useMemo(() => ({
    weightsData: {
      ...baseGraphData,
      data: {
        datasets: [{
          ...baseGraphData.data.datasets[0],
          backgroundColor: [
            weekData.weightsRegular > 0 ? THEME.colors.graph : THEME.colors.iconsOff,
            weekData.weightsRegular > 1 ? THEME.colors.graph : THEME.colors.iconsOff,
          ],
        }],
      },
    },
    drillsData: {
      ...baseGraphData,
      data: {
        datasets: [{
          ...baseGraphData.data.datasets[0],
          backgroundColor: [
            weekData.drillsRegular > 0 ? THEME.colors.graph : THEME.colors.iconsOff,
            weekData.drillsRegular > 1 ? THEME.colors.graph : THEME.colors.iconsOff,
          ],
        }],
      },
    },
    runsData: {
      ...baseGraphData,
      data: {
        datasets: [{
          ...baseGraphData.data.datasets[0],
          data: [1, 1, 1],
          backgroundColor: [
            weekData.runsRegular > 0 ? THEME.colors.graph : THEME.colors.iconsOff,
            weekData.runsRegular > 1 ? THEME.colors.graph : THEME.colors.iconsOff,
            weekData.runsRegular > 2 ? THEME.colors.graph : THEME.colors.iconsOff,

          ],
        }],
      },
    },
  }), [weekData]);

  return (
    <ContainerCards>
      <Card>
        <GraphContainer>
          <DoughnutChart {...graphData.weightsData} canvasStyle={baseGraphCanvasStyle} />
          <CardNumberContainer>
            <CardNumber>
              {weekData.weightsTotal}
            </CardNumber>
          </CardNumberContainer>
        </GraphContainer>

        <CardFooter>
          <img src={WeightTrainingIcon} alt="Ícone treino força" width={cardIconSize} height={cardIconSize} />
          <CardTitle>
            Treinos de Força
          </CardTitle>
        </CardFooter>
      </Card>

      <Card>
        <GraphContainer>
          <DoughnutChart {...graphData.drillsData} canvasStyle={baseGraphCanvasStyle} />
          <CardNumberContainer>
            <CardNumber>
              {weekData.drillsTotal}
            </CardNumber>
          </CardNumberContainer>
        </GraphContainer>

        <CardFooter>
          <img src={DrillsIcon} alt="Ícone treino educativos" width={cardIconSize} height={cardIconSize} />
          <CardTitle>
            Treinos de Educativos
          </CardTitle>
        </CardFooter>
      </Card>

      <Card>
        <GraphContainer>
          <DoughnutChart {...graphData.runsData} canvasStyle={baseGraphCanvasStyle} />
          <CardNumberContainer>
            <CardNumber>
              {weekData.runsTotal}
            </CardNumber>
          </CardNumberContainer>
        </GraphContainer>

        <CardFooter>
          <img src={RunIcon} alt="Ícone treino corrida" width={cardIconSize} height={cardIconSize} />
          <CardTitle>
            Treinos de Corrida
          </CardTitle>
        </CardFooter>
      </Card>
    </ContainerCards>
  );
}

export default Cards;

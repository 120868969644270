import React from 'react';

import ExerciseMetrics from '../ExerciseMetrics';
import ModalButton from '../ModalButton';

import {
  ButtonsContainer,
  CongratsContainer,
  CongratsTitle,
  Container,
  DefaultCongratsText,
  FinishSerieActionContainer,
  Header,
  Scroll,
  TimerContainer,
  TimerLabel,
  UnderlinedButton,
  UnderlinedButtonText,
} from './styles';

interface FinishExerciseSerieI {
  title: string
  time: string
  isAbandon: boolean
  heartRate: string
  maxHeartRate: string
  calories: string
  saving: boolean
  setHeartRate: (heartRate: string) => void
  setMaxHeartRate: (maxHeartRate: string) => void
  setCalories: (calories: string) => void
  continueExercise: () => void
  setIsAbandon: (abandon: boolean) => void
  saveExercise: () => void
  exitExercise: () => void
}

function FinishExerciseSerie({
  title,
  time,
  isAbandon,
  heartRate,
  maxHeartRate,
  calories,
  saving,
  setHeartRate,
  setMaxHeartRate,
  setCalories,
  continueExercise,
  setIsAbandon,
  saveExercise,
  exitExercise,
}: FinishExerciseSerieI) {
  return (
    <Container data-testid="finish-exercise-serie">
      <Header>
        <CongratsContainer>
          <DefaultCongratsText>PARABÉNS</DefaultCongratsText>
          <CongratsTitle>{title}</CongratsTitle>
          <DefaultCongratsText>FINALIZADO</DefaultCongratsText>
        </CongratsContainer>
        <TimerContainer>
          <TimerLabel>{time}</TimerLabel>
        </TimerContainer>
      </Header>
      <FinishSerieActionContainer isAbandon={isAbandon}>
        <Scroll>
          {
            isAbandon
          && (
            <ExerciseMetrics
              heartRate={heartRate}
              maxHeartRate={maxHeartRate}
              calories={calories}
              setHeartRate={setHeartRate}
              setMaxHeartRate={setMaxHeartRate}
              setCalories={setCalories}
            />
          )
          }
          <ButtonsContainer>
            <ModalButton
              disabled={saving}
              title="CONTINUAR TREINO"
              onPress={continueExercise}
            />
            {
              !isAbandon ? (
                <UnderlinedButton onClick={() => setIsAbandon(true)}>
                  <UnderlinedButtonText>ABANDONAR TREINO</UnderlinedButtonText>
                </UnderlinedButton>
              )
                : (
                  <>
                    <ModalButton
                      disabled={saving}
                      title="SALVAR E SAIR"
                      danger
                      onPress={saveExercise}
                    />
                    <ModalButton
                      disabled={saving}
                      title="SAIR"
                      danger
                      onPress={exitExercise}
                    />
                  </>
                )
            }
          </ButtonsContainer>
        </Scroll>
      </FinishSerieActionContainer>
    </Container>
  );
}

export default FinishExerciseSerie;

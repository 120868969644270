import * as React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/locale';

import {
  Input, Container, Label, ErrorMessage, InputContainer,
} from './styles';

export interface IFormInput extends React.InputHTMLAttributes<HTMLInputElement>{
  label?: string;
  inputValue: string;
  error?: boolean;
  errorMessage?: string;
  showPasswordToggle?: boolean;
  minDate?: Date;
  maxDate?: Date;
  customContainerStyle?: React.CSSProperties;
  customInputContainerStyle?: React.CSSProperties;
  customInputStyle?: React.CSSProperties;
  customLabelStyle?: React.CSSProperties;
}

export default function FormInput({
  label, inputValue, error, errorMessage, showPasswordToggle, minDate, maxDate, customContainerStyle, customInputContainerStyle, customInputStyle, customLabelStyle, ...rest
}: IFormInput) {
  const [showPassword, setshowPassword] = React.useState<boolean>(false);

  const dynamicType = React.useMemo(() => {
    if (showPasswordToggle) {
      return showPassword ? 'text' : 'password';
    }
    return rest.type;
  }, [showPasswordToggle, showPassword]);

  const isValidDate = (date: string) => {
    const dateObject = new Date(date);

    // eslint-disable-next-line no-restricted-globals
    return dateObject instanceof Date && !isNaN(dateObject as any);
  };

  return (
    <Container data-testid="outer-input-container" style={customContainerStyle}>
      {label && <Label style={customLabelStyle}>{label}</Label>}
      <InputContainer style={customInputContainerStyle}>

        {(rest.type === 'date' && (
          <DatePicker
            locale={pt}
            selected={isValidDate(inputValue) ? new Date(inputValue) : null}
            dateFormat="dd/MM/yyyy"
            onChange={(e) => rest.onChange?.({
              target: {
                value: e?.toISOString() || '',
              },
            } as React.ChangeEvent<HTMLInputElement>)}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="dd/mm/aaaa"
            name={rest.name}
          />
        ))
        || (
          <>
            <Input
              style={customInputStyle}
              value={inputValue}
              {...rest}
              type={dynamicType}
            />
            {showPasswordToggle
          && (
            <>
              {showPassword && <FaEyeSlash title="hide-password" size={10} onClick={() => { setshowPassword(!showPassword); }} />}
              {!showPassword && <FaEye title="show-password" onClick={() => { setshowPassword(!showPassword); }} />}
            </>
          )}
          </>
        )}

      </InputContainer>

      {error && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
    </Container>
  );
}

import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

import Lottie from 'lottie-react-web';

import { ExerciseI } from '../../types/Exercise';
import Accordion from '../Accordion';
import ExerciseMetrics from '../ExerciseMetrics';
import ModalButton from '../ModalButton';
import theme from '../../theme';
import CONGRATS from '../../assets/congrats.json';

import {
  TimerContainer,
  TimerLabel,
  Container,
  Header,
  CongratsContainer,
  CongratsTitle,
  TrophyContainer,
  TrophyText,
  ExerciseTitle,
  Scroll,
  FastImage,
  ContainerButton,
  CongratsAnimationContainer,
} from './styles';

interface FinishExerciseI {
  currentExercise?: ExerciseI,
  time: string
  heartRate: string
  maxHeartRate: string
  calories: string
  saving: boolean
  setHeartRate: (heartRate: string) => void
  setMaxHeartRate: (maxHeartRate: string) => void
  setCalories: (calories: string) => void
  saveExercise: () => void
}

function FinishExercise({
  currentExercise,
  time,
  heartRate,
  maxHeartRate,
  calories,
  saving,
  setHeartRate,
  setMaxHeartRate,
  setCalories,
  saveExercise,
}: FinishExerciseI) {
  return (
    <>

      <Container data-testid="finish-exercise">
        <Header>
          <FastImage
            imageUrl={`${currentExercise?.cover_link || ''}`}
          >
            <CongratsContainer>
              <CongratsTitle>
                PARABÉNS
              </CongratsTitle>
              <TrophyContainer>

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 488.7 488.7" xmlSpace="preserve">
                  <g>
                    <path d="M441.95,441.5c-0.7,0.9-0.7,2.1,0,3l22.4,27.3c2,2.4,0.3,6-2.8,6h-80.1v-70.2h80.3c3.1,0,4.8,3.6,2.8,5.9L441.95,441.5z    M24.45,413.5l22.4,27.3c0.7,0.9,0.7,2.1,0,3l-22.6,28c-1.9,2.4-0.2,5.9,2.8,5.9h80.4v-70.2h-80.1   C24.25,407.5,22.55,411.1,24.45,413.5z M384.75,44.9h96v73.7c0,52.9-43.1,96-96,96h-12.5c-18.1,40-54.4,70-98.3,79.4v60.2h34.5   c10.6,0,19.2,8.6,19.2,19.2v25.2h29.4v90.1h-225.3v-90.1h29.4v-25.2c0-10.6,8.6-19.2,19.2-19.2h34.4V294   c-43.9-9.4-80.2-39.4-98.3-79.4h-12.5c-52.9,0-96-43.1-96-96V44.9h96V0h280.6v44.9H384.75z M104.45,166c-0.2-3-0.3-6.1-0.3-9.2   V93.5h-47.4v25.1c0,26.1,21.3,47.4,47.4,47.4H104.45z M205.45,403.2v48.3h78v-48.3H205.45z M309.05,105.3l-24.5-24.6l-56.5,56.1   l-23.6-23.7l-24.6,24.5l23.6,23.7l24.5,24.6l24.6-24.5L309.05,105.3z M384.75,93.5v63.3c0,3.1-0.1,6.1-0.3,9.2h0.3   c26.1,0,47.4-21.3,47.4-47.4V93.5L384.75,93.5L384.75,93.5z" />
                  </g>
                </svg>

                <TrophyText>
                  TREINO FINALIZADO
                </TrophyText>
              </TrophyContainer>
              <ExerciseTitle>
                {currentExercise?.name}
                {currentExercise?.level}
              </ExerciseTitle>
            </CongratsContainer>
          </FastImage>
        </Header>
        <Scroll>
          {
            currentExercise?.trainingGroups.map((group) => (
              <Accordion
                title={group?.name}
                key={group?.id}
                showCompletitionIcon
              />
            ))
          }
        </Scroll>
        <CongratsAnimationContainer>
          <Lottie
            options={{
              animationData: CONGRATS,
              loop: false,
            }}
          />
        </CongratsAnimationContainer>
        <TimerContainer>
          <AiOutlineClockCircle color={theme.colors.title} size={20} />
          <TimerLabel>{time}</TimerLabel>
        </TimerContainer>
        <>
          <ExerciseMetrics
            heartRate={heartRate}
            maxHeartRate={maxHeartRate}
            calories={calories}
            setHeartRate={setHeartRate}
            setMaxHeartRate={setMaxHeartRate}
            setCalories={setCalories}
          />
        </>
      </Container>
      <ContainerButton>
        <ModalButton
          title="SALVAR TREINO"
          disabled={saving}
          style={{ marginTop: '4%' }}
          onPress={saveExercise}
        />
      </ContainerButton>
    </>
  );
}
export default FinishExercise;

import React from 'react';
import theme from '../../theme';
import Modal from '../BaseModal';
import { GenericButton, GenericLinkButton } from '../GenericButton/styles';

interface IProps {
  show: boolean
  toggle: () => void
}

export default function PauseModal({ show, toggle }: IProps) {
  return (
    <Modal
      isVisible={show}
      setIsVisible={toggle}
      data-testid="modal"
    >
      <h4>
        Para solicitar a pausa nos serviços, envie um e-mail para
        {' '}
        <b>
          <u>assinatura@corridaperfeita.com</u>
        </b>
        {' '}
        informando quantos meses (de 1 a 6) você pretende se ausentar.
      </h4>

      <br />

      <h4>Lembrando que a pausa não interrompe eventuais parcelas do seu pagamento feitas por cartão.</h4>

      <GenericLinkButton
        backgroundColor={theme.colors.primary}
        borderColor={theme.colors.title}
        textColor={theme.colors.title}
        href="mailto:assinatura@corridaperfeita.com"
        style={{ marginTop: '30px', marginBottom: '30px' }}
      >
        ENVIAR EMAIL
      </GenericLinkButton>

      <GenericButton
        backgroundColor={theme.colors.primary}
        borderColor={theme.colors.redAlert}
        textColor={theme.colors.redAlert}
        type="button"
        style={{ marginBottom: '30px', width: '100%' }}
        onClick={toggle}
      >
        SAIR
      </GenericButton>
    </Modal>
  );
}

import React, { useState } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
  Container, LinkButton, LinkButtonText, NewTestButton, NewTextButtonText, TextContainer,
  TextInfo, TextLinkContainer,
} from './styles';

import BackArrow from '../../components/BackArrow';
import ZoneInfoModal from '../../components/ZonesInfoModal';
import NewZoneTestModal from '../../components/NewZoneTestModal';

function NewZone() {
  const navigate = useNavigate();

  const [newTestModal, setNewTestModal] = useState(false);
  const [zoneInfoModal, setZoneInfoModal] = useState(false);

  const zoneInfoData = {
    videoUri: 'https://player.vimeo.com/video/580398056',
    desc: 'Veja no vídeo como realizar o teste de corrida, cujo resultado, ao ser inserido em nossa plataforma, vai calcular suas zonas de treino de corrida por ritmo (min/km) e/ou por Frequência Cardíaca (BPM).',
    title: 'Como fazer o teste',
  };

  const callback = () => {
    setNewTestModal(false);

    navigate('/zonelist');
  };

  return (
    <Container>
      <ZoneInfoModal
        visible={zoneInfoModal}
        setIsVisible={() => setZoneInfoModal(false)}
        {...zoneInfoData}
      />

      <NewZoneTestModal
        isVisible={newTestModal}
        setIsVisible={() => setNewTestModal(false)}
        callback={callback}
      />

      <BackArrow text="JÁ FEZ O TESTE PARA DEFINIÇÃO DE ZONAS?" bigger backTo="/zonelist" />

      <TextContainer>
        <TextInfo>
          Suas zonas de treino podem ser analisadas pela sua frequência
          cardíaca (BPM) e pelo seu ritmo (min/km), desde que você faça nosso teste
          de corrida para cálculo das zonas.
        </TextInfo>
        <TextLinkContainer>
          <TextInfo>
            Se você não consegue correr por 20 minutos consecutivos e no mesmo ritmo,
            ainda não faça o teste. Siga os treinos pela Percepção de Esforço.
          </TextInfo>
        </TextLinkContainer>
        <LinkButton onClick={() => setZoneInfoModal(true)}>
          <MdPlayCircleFilled size={15} color="#FFFFFF" />
          <LinkButtonText>Veja como fazer o teste</LinkButtonText>
        </LinkButton>
      </TextContainer>
      <NewTestButton onClick={() => setNewTestModal(true)}>
        <NewTextButtonText>Registrar resultado do teste</NewTextButtonText>
      </NewTestButton>
    </Container>
  );
}

export default NewZone;

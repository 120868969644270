import React, { useState } from 'react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import { RootState } from '../../store';
import { refreshUser } from '../../store/Auth.store';
import BaseModal, { IBaseModalProps } from '../BaseModal';
import {
  Container, FormContainer,
  Button, ButtonText, ErrorMessage,
} from './styles';
import Loader from '../Loader';
import FormInput from '../FormInput';

export interface NewZoneTestModalProps extends IBaseModalProps {
  callback: Function;
}

interface IPace {
  minutes: number;
  seconds: number;
}

function NewZoneTestModal({
  isVisible, setIsVisible, callback,
}: NewZoneTestModalProps) {
  const [pace, setPace] = useState<IPace>({
    minutes: 0,
    seconds: 0,
  });
  const [bpm, setBpm] = useState<string>();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [bpmError, setBpmError] = useState(false);
  const [paceError, setPaceError] = useState(false);

  const auth = useSelector((state : RootState) => state.auth);
  const { user } = auth;

  const dispatch = useDispatch();

  const paceToString = () => {
    if (pace) {
      const minutes = pace.minutes.toString().padStart(2, '0');
      const seconds = pace.seconds.toString().padStart(2, '0');

      return `${minutes}:${seconds} /Km`;
    }
    return '';
  };

  const formatPace = () => {
    if (pace.minutes || pace.seconds) {
      return `${pace.minutes.toString().padStart(2, '0')}:${pace.seconds.toString().padStart(2, '0')}`;
    }
    return null;
  };

  function sanitizePaceString(value: string) {
    return value.replace(' /Km', '');
  }

  function handleChangePace(value: string) {
    const splitedString = sanitizePaceString(value).split(':');

    setPace({
      minutes: Number(splitedString[0]),
      seconds: Number(splitedString[1]),
    });
  }

  const submit = async () => {
    if (loading) return;

    if (!bpm && (!pace.minutes && !pace.seconds)) {
      setBpmError(true);
      setPaceError(true);

      return;
    }

    setBpmError(false);
    setPaceError(false);

    try {
      setLoading(true);

      const payload = {
        user_id: user.id,
        pace: formatPace(),
        bpm_avg: bpm,
        execution_date: format(date, 'yyyy-MM-dd HH:mm:ss'),
        type: '20min',
      };

      await api.post('/user_zones', payload);

      dispatch(refreshUser(user?.id) as any);

      callback();
    } catch (error) {
      toast.error('Ocorreu um erro ao registar o teste, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      title="Registrar teste de zonas"
    >
      <Container>
        <FormContainer>
          <FormInput
            label="BPM Médio"
            placeholder="BPM"
            type="number"
            inputValue={bpm || ''}
            onChange={(e) => { if (Number(e.target.value) > -1 && Number(e.target.value) < 999)setBpm(e.target.value); }}
            error={bpmError}
          />

          <FormInput
            label="Ritmo (min/km)"
            placeholder="0:00/km"
            type="time"
            inputValue={sanitizePaceString(paceToString())}
            onChange={(e) => handleChangePace(e.target.value)}
            error={paceError}
          />

          {(bpmError && paceError) && <ErrorMessage>Insira o BPM ou Ritmo para o cálculo da zona.</ErrorMessage>}

          <FormInput
            label="Data"
            type="date"
            inputValue={date.toISOString()}
            onChange={(e) => setDate(new Date(e.target.value))}
          />
        </FormContainer>
        <Button onClick={() => submit()}>
          {loading
            ? <Loader />
            : <ButtonText>Registrar Teste</ButtonText>}
        </Button>
      </Container>
    </BaseModal>
  );
}

export default NewZoneTestModal;

import { createSlice } from '@reduxjs/toolkit';
import { purgeStoredState } from 'redux-persist';
import { AppThunk } from '.';
import api, { api2, LoginApi } from '../services/api';
import coreApi from '../services/coreApi';
import { GoogleLoginProps } from '../types/SocialLogin';
import { User } from '../types/User';
import { clearAllIntegrations } from './Integration.store';

const auth = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    loading: false,
    user: {} as User,
    coach: {} as User,
    path: '',
  },
  reducers: {
    logout: (state) => {
      state.token = null;
      state.user = {} as User;
      state.user.uninterrupted_weeks_regularity = 0;
      state.user.is_breaking_record = false;
      state.user.week_record = 0;
      purgeStoredState({ key: 'auth', storage: localStorage });
    },
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.user.isFree = action.payload.user.subscription_type === 'FREE';
      state.user.is_breaking_record = action.payload.user.is_breaking_record;
      state.user.week_record = action.payload.user.week_record;
    },
    getCoachSuccess: (state, action) => {
      state.coach = action.payload;
    },
    loginFinish: (state) => {
      state.loading = false;
    },
    refreshUserRequest: (state) => {
      state.loading = true;
    },
    refreshUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.user.isFree = action.payload.user.subscription_type === 'FREE';
      state.user.is_breaking_record = action.payload.user.is_breaking_record;
      state.user.week_record = action.payload.user.week_record;
    },
    refreshUserFail: (state) => {
      state.loading = false;
    },
    updateUserProps: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    routed: (state, action) => {
      state.path = action.payload;
    },
  },
});

export const {
  loginRequest, loginFinish,
  loginSuccess, logout, refreshUserRequest, refreshUserFail,
  refreshUserSuccess, updateUserProps, getCoachSuccess, routed,
} = auth.actions;

export default auth.reducer;

export const login = (email: string, password: string, callback: any): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await api.post('sessions', { email, password });

      const { token } = response.data.auth;
      const { user } = response.data;

      api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
      coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

      dispatch(getCoach());
      dispatch(loginSuccess({ token, user }));
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        callback({ hasError: true, title: 'Internet', message: 'Por favor, verifique sua conexão com a internet.' });
      } else {
        callback({ hasError: true, title: 'Falha na autenticação', message: 'E-mail ou senha não conferem, verifique seus dados e tente novamente.' });
      }
    } finally {
      dispatch(loginFinish());
    }
  }
);

export const getCoach = (): AppThunk => (
  async (dispatch) => {
    await api.get('get_coach')
      .then((response) => {
        if (response.data) {
          dispatch(getCoachSuccess(response.data));
        }
      });
  }
);

export const loginWithGoogle = (data: GoogleLoginProps, callback: any): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await LoginApi.post('google-login', data);

      const { token } = response.data.auth;
      const { user } = response.data;

      api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
      coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

      dispatch(loginSuccess({ token, user }));
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        callback({ hasError: true, title: 'Internet', message: 'Por favor, verifique sua conexão com a internet.' });
      } else {
        callback({ hasError: true, title: 'Falha na autenticação', message: 'E-mail ou senha não conferem, verifique seus dados e tente novamente.' });
      }
    } finally {
      dispatch(loginFinish());
    }
  }
);

export const updateProfileProps = (user: any): AppThunk => (
  (dispatch) => {
    dispatch(updateUserProps({ ...user }));
  }
);

export const refreshUser = (userId: number): AppThunk => (
  async (dispatch) => {
    dispatch(refreshUserRequest());
    await api.get(`users/${userId}`)
      .then((response) => {
        if (response.data) {
          const user = response.data;
          dispatch(refreshUserSuccess({ user }));
        }
      })
      .catch(() => {
        dispatch(refreshUserFail());
      });
  }
);

export const loginWithToken = (userId: number, token: string): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    api.defaults.headers.common = { Authorization: `Bearer ${token}` };
    api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
    coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

    await api.get(`users/${userId}`)
      .then((response) => {
        if (response.data) {
          const user = response.data;
          dispatch(loginSuccess({ token, user }));
        }
      }).catch(() => {
        dispatch(loginFinish());
      });
    dispatch(loginFinish());
  }
);

export const fullLogout = (): AppThunk => (
  (dispatch) => {
    dispatch(clearAllIntegrations());
    dispatch(logout());
  }
);

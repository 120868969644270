import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  InfoText, Header,

  ModalContent, OkButton, OkButtonText,

} from './style';
import {
  ModalDialog,
  ModalBackDrop,
  ModalContainer,
} from '../Modal/Modal.styles';

export default function RecordedClassesInfoModal({
  isOpen, toggle,
} : any) {
  const navigate = useNavigate();
  const goToRecordedClasses = () => {
    navigate('/club/RecordedClasses');
  };

  return (
    isOpen && (
      <>
        <ModalContainer data-testid="modal-container">
          <ModalDialog>
            <ModalContent>
              <Header>Acesse a seção de gravações</Header>
              <InfoText>
                Alguns minutos após o fim do treino, a gravação vai ficar
                disponível em nosso site, na seção de gravações, que pode
                ser acessada no menu de treinos ao vivo, em &ldquo;Gravações anteriores&ldquo;.
              </InfoText>

              <OkButton onClick={goToRecordedClasses}>
                <OkButtonText>Acessar Gravações</OkButtonText>
              </OkButton>
              <OkButton onClick={toggle || (() => {})}>
                <OkButtonText>Voltar</OkButtonText>
              </OkButton>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

import React, { HTMLAttributes } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClassI } from '../../../types/Class';
import {
  ContainerImg,
  CourseDescription,
  CourseImage,
  CourseImageTitle,
} from './styles';
import Mental from '../../../assets/courses/mental.png';
import Nutricao from '../../../assets/courses/nutricao.jpeg';
import Metodo from '../../../assets/courses/metodo.png';
import Zonas from '../../../assets/courses/zonas.png';
import Iniciantes from '../../../assets/courses/iniciantes.png';
import { setCurrentClass } from '../../../store/Classes.store';
import CarouselCard from '../../CarouselCard';

interface CourseCardProps extends HTMLAttributes<HTMLDivElement> {
  item: ClassI;
}

function CourseCard({ item }: CourseCardProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classHelp: any = {
    'Aulas para Iniciante': {
      image: Iniciantes,
      info: 'Está começando na corrida? Esta série de aulas é para você!',
      isLock: false,
    },
    'Zonas de Treinamento': {
      image: Zonas,
      info: 'Entenda como funcionam as zonas guiarão seus treinos de corrida',
      isLock: true,
    },
    'Método Corrida Perfeita': {
      image: Metodo,
      info: 'O passo a passo para aprimorar sua forma de correr',
      isLock: false,
    },
    'Nutrição para corredores': {
      image: Nutricao,
      info: 'O que é importante você saber sobre nutrição para corredores.',
      isLock: true,
    },
    'Preparo Mental': {
      image: Mental,
      info: 'Estratégias para sua mente estar preparada para treinos e provas',
      isLock: true,
    },
  };

  const goToClass = () => {
    if (item) {
      dispatch(setCurrentClass(item));
      navigate('/club/ClassesCover', { state: { item } });
    }
  };

  return (
    <CarouselCard data-testid="courses-card">
      <div role="presentation" onClick={() => goToClass()}>
        <CourseImage>
          <ContainerImg>
            <img
              src={classHelp[item?.name]?.image || { uri: item?.cover }}
              alt="Aulas para você"
            />
          </ContainerImg>
          <CourseImageTitle>
            <h3>{item?.name || ''}</h3>
          </CourseImageTitle>
        </CourseImage>
        <CourseDescription>
          <p>{classHelp[item?.name]?.info || item?.description}</p>
        </CourseDescription>
      </div>

    </CarouselCard>
  );
}

export default CourseCard;

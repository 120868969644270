import React, { useEffect } from 'react';
import { IStep } from '../..';
import {
  Container, Content, Description, Input, Title,
} from './styles';

export interface ISetMeasurementScreenProps {
  step: IStep,
  height: null | string,
  weight: null | string,
  onChangeWeight: (value: any) => void,
  onChangeHeight: (value: any) => void,
  setButtonDisabled: (value: any) => void,
}

function SetMeasurementScreen({
  step, height, weight, onChangeWeight, onChangeHeight, setButtonDisabled,
}: ISetMeasurementScreenProps) {
  useEffect(() => {
    onChangeHeight(height);
    onChangeWeight(weight);
    setButtonDisabled(false);
  }, []);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <Content>
        <Description>Altura</Description>

        <Input
          value={!!height && height !== 'null' ? height : ''}
          type="number"
          onChange={(e) => onChangeHeight(e.target.value)}
          placeholder="000 cm"
        />
      </Content>

      <Content>
        <Description>Peso</Description>

        <Input
          value={!!weight && weight !== 'null' ? weight : ''}
          type="number"
          onChange={(e) => onChangeWeight(e.target.value)}
          placeholder="00.00 kg"
        />
      </Content>
    </Container>
  );
}

export default SetMeasurementScreen;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import BackArrow from '../BackArrow';
import {
  Container,
  Info,
  List,
} from './styles';
import { RootState } from '../../store';
import { setCurrentClass, getClasses } from '../../store/Classes.store';
import ClassListItem from '../ClassesListItem';
import ClassesListSkeleton from '../ClassesListSkeleton';

function ClassesList() {
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useSelector((state: RootState) => state.classes.ClassList);
  const dispatch = useDispatch<any>();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClasses());
  }, []);

  useEffect(() => {
    if (classes?.length > 0) {
      setLoading(false);
    }
  }, [classes]);

  return (
    <Container>
      <BackArrow text="ORIENTAÇÕES EM VÍDEO AULAS" bigger backTo="/club" />
      <Info>
        Confira a seguir todas as nossas séries de vídeo aulas que vão
        orientar sua jornada de sucesso na corrida.
      </Info>
      {loading
        ? (
          <ClassesListSkeleton />
        )
        : (
          <>
            <List>
              {
                classes.map((item) => (
                  <ClassListItem
                    key={item.id}
                    onPress={() => {
                      dispatch(setCurrentClass(item));
                      navigate('/club/ClassesCover');
                    }}
                    {...item}
                  />
                ))
              }
            </List>
          </>
        )}
    </Container>
  );
}

export default ClassesList;

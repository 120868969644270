import React, { useEffect, useCallback, useState } from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  addDays, endOfMonth, format, parseISO, startOfMonth, subDays,
} from 'date-fns';
import moment from 'moment';
import { Container, ActivityContainer, LoadingText } from './styles';

import useNavbar from '../../hooks/useNavbar';
import { objectExists } from '../../utils';

import TodayTraining from '../../components/TodayTraining';
import CourseCarousel from '../../components/CoursesCarousel';
import CurrentPlanRequest from '../../components/CurrentPlanRequest';
import CurrentObjective from '../../components/CurrentObjective';
import NextEventsCard from '../../components/NextEvents';
import WeekProgress from '../../components/WeekProgress';
import NewObjective from '../../components/NewObjective/NewObjective';

import api from '../../services/api';
import { RootState } from '../../store';
import {
  loadingTrainingPlanRequest,
  reloadTrainingPlanRequest,
  trainingPlanRequest as trainingPlanRequestAction,
} from '../../store/TrainingPlanRequests.store';
import {
  loadTrainingPlan, putLoading,
} from '../../store/TrainingPlan.store';

import { ITrainingPlanRequest } from '../../types/TrainingPlanRequest';
import RecentActivities from '../../components/RecentActivities';
import Loader from '../../components/Loader';
import { appTheme } from '../../theme';
import { hasAccess } from '../../services/utils';
import HomeBanner from '../../components/HomeBanner';
import coreApi from '../../services/coreApi';
import { BannerType } from '../../types/Banner';
import InfoModal from '../../components/InfoModal';

enum Statuses {
  CREATED = 'CREATED',
  OPEN = 'OPEN',
  PENDING = 'PENDING'
}

export default function Home() {
  const { setSelected } = useNavbar();
  const dispatch = useDispatch<any>();

  const [banner, setBanner] = useState<BannerType>();
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const trainingPlanRequest = useSelector(
    (state: RootState) => state.trainingPlanRequests.trainingPlanRequest,
  );

  const trainingPlanEndDate = useSelector((state: RootState) => state.trainingPlan.endDate);

  const finished = moment().isAfter(trainingPlanEndDate);

  const trainingPlanId = useSelector((state: RootState) => state.trainingPlan.planId);
  const trainingPlanIdLoading = useSelector((state: RootState) => state.trainingPlan.loading);

  const trainingPlanRequestReloadFlag = useSelector((state: RootState) => state.trainingPlanRequests.reload);
  const trainingPlanRequestLoading = useSelector((state: RootState) => state.trainingPlanRequests.loading);

  const loading = trainingPlanIdLoading || trainingPlanRequestLoading;
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const loadTrainingPlanRequest = useCallback(() => {
    dispatch(loadingTrainingPlanRequest(true));

    api.get(`get_user_requests?userId=${user?.id}`)
      .then((response) => {
        if (response?.data?.data?.length) {
          const tpr: ITrainingPlanRequest = response.data.data[0];

          if (Statuses[tpr?.status as Statuses]) {
            dispatch(trainingPlanRequestAction(tpr));
          } else {
            dispatch(trainingPlanRequestAction(null));
            dispatch(loadingTrainingPlanRequest(false));
          }
        } else {
          dispatch(trainingPlanRequestAction(null));
          dispatch(loadingTrainingPlanRequest(false));
        }
      })
      .catch(() => {
        dispatch(loadingTrainingPlanRequest(false));
      });
  }, []);

  const calendarDays = useSelector(
    (state: RootState) => state.trainingPlan.days,
  );

  const currentWeek = useSelector(
    (state: RootState) => state.trainingPlan.currentWeekDays,
  );

  const today = format(new Date(), 'yyyy-MM-dd');

  const initialLoad = () => {
    const parsedDate = parseISO(today);
    const startAt = format(subDays(startOfMonth(parsedDate), 7), 'yyyy-MM-dd');
    const endAt = format(addDays(endOfMonth(parsedDate), 7), 'yyyy-MM-dd');

    dispatch(loadTrainingPlan(startAt, endAt, true) as any);
  };

  const clearBanner = () => {
    setBanner(undefined);
  };

  useEffect(() => {
    setSelected('home');
    loadTrainingPlanRequest();

    if (calendarDays.length === 0) {
      initialLoad();
    } else {
      dispatch(putLoading(false) as any);
    }
  }, []);

  useEffect(() => {
    if (trainingPlanRequestReloadFlag) {
      loadTrainingPlanRequest();

      dispatch(reloadTrainingPlanRequest(false));
    }
  }, [trainingPlanRequestReloadFlag]);

  useEffect(() => {
    coreApi.get(`home-banner/type/${user.subscription_type}`)
      .then(((response) => {
        setBanner(response.data);
      }));
  }, []);

  return (
    <Container>
      {
        loading
        && (
          <>
            <LoadingText>Carregando informações de plano de treino...</LoadingText>
            <Loader color={appTheme.title} />
          </>
        )
      }

      <br />
      {banner && <InfoModal showModal={infoModalVisible} setShowModal={setInfoModalVisible} banner={banner} clearBanner={clearBanner} />}
      {banner && !banner.is_viewed && <HomeBanner banner={banner} setInfoModalVisible={setInfoModalVisible} clearBanner={clearBanner} />}

      {
        hasAccess(user, ['FREE', 'ESSENTIAL', 'START'])
        && !loading
        && !objectExists(trainingPlanRequest)
        && !!trainingPlanId
        && !finished
        && <CurrentObjective />
      }

      {
        hasAccess(user, ['FREE', 'ESSENTIAL', 'START'])
        && !loading
        && objectExists(trainingPlanRequest)
        && <CurrentPlanRequest />
      }

      {
        hasAccess(user, ['ESSENTIAL', 'START'])
        && !loading
        && !objectExists(trainingPlanRequest)
        && (!trainingPlanId || finished)
        && <NewObjective />
      }
      {

        hasAccess(user, ['FREE', 'ESSENTIAL', 'START'])
        && !!trainingPlanId
        && !finished
        && <WeekProgress currentWeek={currentWeek} />
      }

      <ActivityContainer>
        {
          hasAccess(user, ['FREE', 'ESSENTIAL', 'START'])
          && !!trainingPlanId
          && !finished
          && <TodayTraining />
        }
        <RecentActivities />

      </ActivityContainer>

      <NextEventsCard />

      <CourseCarousel />

    </Container>
  );
}

import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { appTheme } from '../../theme';

export const Input = styled(InputMask)`
  border-radius: 5px;
  background-color: ${appTheme.cardB3};
  width: 100%;
  color: ${appTheme.title};
  padding: 0px;
  padding-left: 10px;
  padding-right: 5px;
  border: none;
  height: 25px;
  font-size: 12px;

  @media (max-width: 667px) {
    height: 20px;
    font-size: 10px;
  }
`;

export const Label = styled.div`
  color: ${appTheme.title};
  margin-bottom: 5px;
  margin-left: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const Container = styled.div`
  margin: 0 5px;
  flex: 1;

  svg {
    position: absolute;
    right: 5px;
    transform: translate(0, 30%);
    cursor: pointer;

    height: 15px;
    width: 15px;

    user-select: none;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin: 5px;
  font-size: 13px;
`;

import styled from 'styled-components';
import theme from '../../theme';
import imagem from '../../assets/objective/textbox-solicitacao.png';

export const Container = styled.section`
    background-image: url(${imagem});
    background-size: cover;
    border-radius:10px;
    padding: 10px;
    width: 100%;
    margin-bottom: 25px;
    background-position: bottom;
    min-height: 110px;
`;

export const HeaderContainer = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const PlanText = styled.div`
  h3{
    color: ${theme.colors.notifications};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }  
`;

export const TextInfo = styled.div`
  p{
    color: ${theme.colors.text};
    font-size: 10px;
    max-width: 70%;
    text-align: start;
  }  
`;

export const ButtonOpenModalTitle = styled.button`
  color: ${theme.colors.title};
  font-size: 10px;
  text-decoration: underline;
  text-decoration-color: ${theme.colors.title};
  margin-top: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;

import styled from 'styled-components';

export const Container = styled.div<{full?: boolean}>`
  display: flex;
  justify-content: ${({ full }) => (full ? 'center' : 'flex-end')};
  width: 100%;
  align-items: center;
  margin-top: 22px;

  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const Label = styled.span`
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  margin-right: 13px;

  @media (max-width: 667px) {
    margin-right: 5px;
    font-size: 13px;
  }
`;

export const LabelContainer = styled.div`
   @media (max-width: 667px) {
    width: 70%;
  }
`;

export const BarContainer = styled.div<{full?: boolean}>`
  display: flex;
  gap: 2px;
  width: ${({ full }) => (full ? 90 : 70)}%;
`;

export const Bar = styled.div<{color: string, active?: boolean}>`
  background-color: ${({ color, active }) => (active ? color : '#FFFFFF1A')};
  height: 36px;
  min-width: 2%;
  border-radius: 8px;

  @media (max-width: 667px) {
      min-width: 1.7%;
  }
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.div`
  cursor: pointer;
  background-color: ${theme.colors.cardB3};
  border-radius: 10px;
  width: 33.33%;
  min-width: 180px;
  max-width: 180px;
  margin-right: 10px;

  &:last-of-type{
    margin-right: 0;
  }

  &:hover{
    box-shadow: 0 0 3px 2px rgba(255,255,255,0.1);
  }

  @media (max-width: 667px) {
    min-width: 100px;
  }
`;

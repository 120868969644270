import React from 'react';

import { List } from './styles';
import Item from './Item';
import { avaliableExercisesCategories } from '../../utils/avaliableExercisesCategories';

function ExerciseCategories() {
  return (
    <List data-testid="today-training-list">
      {
        avaliableExercisesCategories.map((exercise) => (
          <Item {...exercise} key={exercise.id} />
        ))
      }
    </List>
  );
}

export default ExerciseCategories;

import React from 'react';
import { User } from '../../../types/User';
import {
  SetStartAtScreen,
  SetDaysWeeklyWorkoutScreen,
  SetMeasurementScreen,
  SetNotesScreen,
  SetSelectedWeekScreen,
  SetDistanceHistoryScreen,
} from '../CommonScreens';
import { ITrainingRequestScreen } from '../interface';

export interface ISlimmingProps extends ITrainingRequestScreen {
  setDistanceHistory: (value: any) => void,
  distanceHistory: any,
  daysWeeklyWorkout: string | null,
  setHeight: (value: string) => void,
  height: string,
  setWeight: (value: string) => void,
  weight: string | null,
  profile: User,
  setDaysWeeklyWorkout: (value: string | null) => void,
  handleSetSelectedWeek: (value: number) => void;
  selectedWeek: number | null;
}

export default function Slimming({
  selectedSchema,
  step,
  setValueForEnableNextButton,
  handleSetSelectedWeek,
  selectedWeek,
  startAt,
  minStartAt,
  handleStartAt,
  distanceHistory,
  setDistanceHistory,
  daysWeeklyWorkout,
  setDaysWeeklyWorkout,
  height,
  setHeight,
  weight,
  setWeight,
  setGeneralNotes,
  setMHistoryNotes,
  maxStartAt,
  profile,
  setButtonDisabled,
}: ISlimmingProps) {
  const commonProps = {
    step: selectedSchema.steps[step],
    setButtonDisabled,
  };

  return (
    <>
      {selectedSchema.type === 'SLIMMING' && step === 0
                && (
                  <SetSelectedWeekScreen
                    {...commonProps}
                    selectedWeek={selectedWeek}
                    clickCallBack={
                      (value: any) => {
                        setValueForEnableNextButton(() => handleSetSelectedWeek(value));
                      }
                    }
                  />
                )}

      {selectedSchema.type === 'SLIMMING'
                && step === 1
                && (
                  <SetStartAtScreen
                    {...commonProps}
                    minStartAt={minStartAt}
                    maxStartAt={maxStartAt}
                    startAt={startAt}
                    onChangeValue={
                      (text: string) => setValueForEnableNextButton(() => handleStartAt(text))
                    }
                  />
                )}

      {selectedSchema.type === 'SLIMMING' && step === 2
                && (
                  <SetDistanceHistoryScreen
                    {...commonProps}
                    distanceHistory={distanceHistory}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => setDistanceHistory(value))
                    }
                  />
                )}

      {selectedSchema.type === 'SLIMMING' && step === 3
                && (
                  <SetDaysWeeklyWorkoutScreen
                    {...commonProps}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => setDaysWeeklyWorkout(value))
                    }
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    profile={profile}
                  />
                )}

      {selectedSchema.type === 'SLIMMING' && step === 4
                && (
                  <SetMeasurementScreen
                    {...commonProps}
                    height={height}
                    weight={weight}
                    onChangeHeight={
                      (text: string) => setValueForEnableNextButton(() => setHeight(text))
                    }
                    onChangeWeight={
                      (text: string) => setValueForEnableNextButton(() => setWeight(text))
                    }
                  />
                )}

      {selectedSchema.type === 'SLIMMING' && step === 5
                && (
                  <SetNotesScreen
                    {...commonProps}
                    onChangeHistoryNotes={
                      (text: string) => setValueForEnableNextButton(() => setMHistoryNotes(text))
                    }
                    onChangeGeneralNotes={
                      (text: string) => setValueForEnableNextButton(() => setGeneralNotes(text))
                    }
                  />
                )}
    </>
  );
}

import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import theme, { appTheme } from '../../theme';

import { LectureFinishText, LectureFinishContainer } from './styles';

interface ClassDoneIndicatorI {
  finished: boolean
  onPress?: () => void
}

function ClassDoneIndicator({
  finished,
  onPress,
}: ClassDoneIndicatorI) {
  return (
    <LectureFinishContainer onClick={onPress} data-testid="class-done-indicator">
      <BsCheckCircle
        color={
          finished
            ? theme.colors.graph
            : appTheme.iconsOff
        }
        size={16}
      />
      <LectureFinishText
        finished={finished}
      >
        {
          finished
            ? 'Concluído'
            : 'Marcar como concluído'
        }
      </LectureFinishText>
    </LectureFinishContainer>
  );
}

export default ClassDoneIndicator;

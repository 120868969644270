import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import alert from 'react-hot-toast';
import logo from '../../assets/logo.png';
import BadgeSplash from '../../assets/progress/badge-icons/badge-splash.svg';
import BaseModal from '../BaseModal';
import {
  BadgeContainer, BadgeContent, BadgeDescription, BadgeGreeting, BadgeTitle, ButtonsFooter, CloseButton, Logo, ShareButton, ViewShot, Badge, Splash, WeeksTrainingText, TextNumberContainer,
} from './styles';
import { IBadge } from '../RegularityLevelProgress';
import { RootState } from '../../store';
import { exportAsImage } from '../../utils';

interface IBadgeModalProps {
  showModal: boolean;
  closeModalCallback: () => void;
  badgeToShow: IBadge;
}

export default function BadgeModal({
  showModal, badgeToShow, closeModalCallback,
}: IBadgeModalProps) {
  const viewShotRef = useRef(null);
  const [hideBtnsFooter, setHideBtnsFooter] = useState(false);
  const weeks_regularity = useSelector((state: RootState) => state.auth.user.weeks_regularity);

  const saveImage = async () => {
    if (viewShotRef.current) {
      setHideBtnsFooter(true);
    }
  };

  const generateImage = async () => {
    try {
      const viewShot = await exportAsImage(viewShotRef.current as any);

      const fileName = 'CorridaPerfeita.jpg';
      const el = document.createElement('a');
      el.setAttribute('href', viewShot);
      el.setAttribute('download', fileName);
      document.body.appendChild(el);
      el.click();
      el.remove();
    } catch (err) {
      alert('Erro ao compartilhar');
    } finally {
      setHideBtnsFooter(false);
    }
  };

  useEffect(() => {
    if (hideBtnsFooter) {
      generateImage();
    }
  }, [hideBtnsFooter]);

  return (
    <BaseModal isVisible={showModal}>
      <ViewShot ref={viewShotRef}>

        <Logo src={logo} alt="Logo do Corrida Perfeita" />

        <BadgeContainer>
          <Splash src={BadgeSplash} />

          <BadgeContent elevatedLevel={!!badgeToShow.elevatedLevel}>
            <BadgeGreeting>Conquista desbloqueada!</BadgeGreeting>

            {!badgeToShow.elevatedLevel && !!badgeToShow.icon && <Badge src={badgeToShow.icon} />}

            {
              badgeToShow.elevatedLevel
              && (
                <TextNumberContainer>
                  {weeks_regularity.toString().padStart(2, '0')}
                </TextNumberContainer>
              )
            }

            <BadgeTitle elevatedLevel={!!badgeToShow.elevatedLevel}>{badgeToShow.title}</BadgeTitle>
          </BadgeContent>
        </BadgeContainer>

        {
          !badgeToShow.elevatedLevel
          && (
            <WeeksTrainingText>
              {`${weeks_regularity} semana${weeks_regularity > 1 ? 's' : ''} de treinos regulares`}
            </WeeksTrainingText>
          )
        }

        {
          badgeToShow.elevatedLevel
          && !!badgeToShow.secondaryTitle
          && (
            <BadgeTitle style={{ fontSize: 16, marginBottom: 10 }}>
              {badgeToShow.secondaryTitle}
            </BadgeTitle>
          )
        }

        <BadgeDescription>
          {badgeToShow.description}
        </BadgeDescription>

        {!hideBtnsFooter && (
          <ButtonsFooter>
            <ShareButton onClick={saveImage}>
              Salvar imagem
            </ShareButton>
            <CloseButton onClick={closeModalCallback}>
              Fechar
            </CloseButton>
          </ButtonsFooter>
        )}
      </ViewShot>
    </BaseModal>
  );
}

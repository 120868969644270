import styled from 'styled-components';

import { appTheme } from '../../../../../../theme';

export const Container = styled.div`
    width: 100%;
    min-height: 100px;
    align-items: flex-start;
    background-color: #2f2f58;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
`;

export const EventCardHeader = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
    }

    svg {
        margin-left: auto;
        cursor: pointer;
    }
`;

export const EventCardBody = styled.div`
    margin-top: 10px;
    color: ${appTheme.text};
`;

export const EventCardFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: ${appTheme.text};

    cursor: pointer;

    svg {
        margin-right: 5px;
        height: 15px;
        width: 15px;
    }

    div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .underline {
        text-decoration: underline;
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;

    p {
        margin-right: 10px;
    }

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

export const SubTitle = styled.div`
    font-size: 10px;
`;

export const TrainingSubDescription = styled.div`
    display: flex;
    align-items: center;
`;

export const TrainingSubDescriptionText = styled.div`
    font-size: 11px;
    color: ${appTheme.text};

    @media (max-width: 667px) {
        font-size: 9px;
    }
`;

export const TrainingGroupContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;
`;

export const TrainingGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;

    li {
        margin-left: 20px;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        padding-left: 10px;
    }
`;

export const TrainingGroupContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`;

export const TrainingGroupCount = styled.div`
    font-size: 11px;
    margin-right: 5px;
`;

export const TrainingGroupText = styled.div`
    font-size: 11px;
`;

export const DescriptionModal = styled.div`
    color: ${appTheme.title};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0 20px 34px;

    h1 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    p {
        font-size: 13px;
        line-height: 20px;
    }
`;

export const Button = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: ${appTheme.title};
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    width: fit-content;

    color: ${appTheme.cardB3};
    font-size: 14px;
    font-weight: bold;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

import styled from 'styled-components';

export const Content = styled.div`
text-align: center;
padding-top: 5px;

`;

export const ArrowButton = styled.button`
  color: #0d0d3d;
  background: #808099;
  justify-content: center;
  border-radius: 5px;
  border-color: #808099;
  cursor: pointer;
`;

export const Container = styled.span`
  display: inline-block;
  height: 21.7px;
  width: 18.98px;
  border: none;
`;
export const Button = styled.button`
  color: #c3c3c3;
  background-color: #0d0d3d;
  border-width: 0px;
  font-size: x-small;
  text-shadow: gray;
  height: 15px;
  width: 15px;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;
  :hover,
  &.selected {
    color: #ffffff;
    opacity: 1;
  }
`;

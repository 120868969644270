import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

const btnBaseStyle = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.title};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const HeaderText = styled.p`
  color: ${theme.colors.title};
  font-weight: 700;
  text-transform: uppercase;
  margin: 10px 0 15px;
  font-size: 13px;
  text-align: center;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const HeaderTextShare = styled.p`
  color: ${theme.colors.title};
  text-transform: uppercase;
  margin: 10px 15% 15px;
  text-align: center;
  font-size: 14;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px 25px 30px;
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 667px) {
    padding: 5px 10px 25px;

  }
`;

export const ContentContainerText = styled.p`
  color: ${theme.colors.title};
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;

  @media (max-width: 667px) {
    font-size: 10px;
    margin-bottom: 20px;
  }
`;

export const WhiteActionButton = styled(btnBaseStyle)`
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  background-color: ${theme.colors.title};
`;

export const WhiteButtonTitle = styled.p`
  color: ${appTheme.background};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 667px) {
    flex-direction: column;
    margin-bottom: 10px;

    & > div {
      margin-bottom: 15px;
    }
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding: 35px 25px;
`;

export const ErrorText = styled.p`
  color: ${theme.colors.secondary};
  font-size: 11px;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
`;

export const TimeInputContainer = styled.div`
  background-color: ${theme.colors.cardB3};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-width: 47%;
`;

export const TimeInputContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

export const TimeInputContentText = styled.p`
  align-items: center;
`;

export const TimeInputTitle = styled.p`
  color: ${theme.colors.title};
  font-size: 14;
  font-weight: 700;t-size: 12px;
  }
`;

export const TimeInputSubTitle = styled.p`
  color: ${theme.colors.title};
  font-size: 12px;
  height: 15px;
`;

export const TimeInputData = styled.p`
  color: ${theme.colors.title};
  font-size: 14px;
`;

export const ResultContainer = styled.div`
  border-radius: 5px;
  width: 100%;
  background-color: ${theme.colors.cardB3};
  margin-bottom: 15px;
`;

export const ResultDistance = styled.div`
  display: flex;
  background-color: ${appTheme.background};
  min-width: 60px;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const ResultRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ResultData = styled.div`
  display: flex;
  flex: .3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ResultTitle = styled.p`
  color: ${theme.colors.iconsOff};
  text-transform: uppercase;
  font-size: 14px;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const ResultValue = styled.p`
  color: ${theme.colors.title};
  font-size: 17px;

  @media (max-width: 667px) {
    font-size: 14px;
  }
`;

export const ResultValueShare = styled.p`
  color: ${theme.colors.secondary};
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const ShareButton = styled(btnBaseStyle)`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

export const ShareButtonTitle = styled.p`
  color: ${theme.colors.title};
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
  margin-left: 5px;
`;

export const FooterButtonsContainer = styled.div`
  width: 100%;
  height: 35px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const BadgeContent = styled.div`
  margin-bottom: 20px;
`;

export const BadgeTextContent = styled.div`
  margin-left: 5px;
`;

export const BadgeInnerContent = styled.div`
  display: flex;
  position: absolute;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  transform: translateY(-103%) ;
`;

export const BadgeDistanceText = styled.p`
  color: ${theme.colors.title};
  font-weight: 700;
  font-size: 19px;

  @media (max-width: 667px) {
    font-size: 16px;
  }
`;

export const BadgeTimeText = styled.p`
  color: ${theme.colors.title};
  font-size: 17px;

  @media (max-width: 667px) {
    font-size: 15px;
  }
`;

export const Logo = styled.img`
  width: 130px;
  height: 60px;
`;

export const BadgeIcon = styled.img`
  width: 200px;
  height: 200px;
`;

export const TrophyIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const PredictionDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.cardB2};
  padding: 20px 20px 50px;
  margin: 15px 0 25px;
  border-radius: 10px;
`;

export const ShareBtnText = styled(WhiteButtonTitle)`
  font-size: 15px;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const FooterMessage = styled.p`
  color: ${theme.colors.iconsOff};
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
`;

import React from 'react';
import {
  ModalContentTitle, ModalContentSubTitleContainer, ModalContentSubTitle, ModalContentButtonContainer, ModalContentButton, ModalContentButtonTextCancel, ModalContentButtonText,

  ModalContent,
} from './style';
import {
  ModalDialog,
  ModalBackDrop,
  ModalContainer,
} from '../Modal/Modal.styles';

interface IntegrationConfirmModalI {
  children?: any
  isOpen: any
  confirm: () => void
  cancel: () => void
}

export default function IntegrationConfirmModal({
  isOpen, children, confirm, cancel,
}: IntegrationConfirmModalI) {
  return (
    isOpen && (
      <>
        <ModalContainer>
          <ModalDialog>
            <ModalContent>
              <ModalContentTitle>
                AVISO
              </ModalContentTitle>
              <ModalContentSubTitleContainer>

                <ModalContentSubTitle>
                  {children}
                </ModalContentSubTitle>
              </ModalContentSubTitleContainer>
              <ModalContentButtonContainer>

                <ModalContentButton color="primary" onClick={cancel}>
                  <ModalContentButtonTextCancel>cancelar</ModalContentButtonTextCancel>
                </ModalContentButton>

                <ModalContentButton color="title" onClick={confirm}>
                  <ModalContentButtonText>continuar</ModalContentButtonText>
                </ModalContentButton>

              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

import React from 'react';
import {
  ProgressBar,
  ProgressBarFill,
  ProgressContainer,
  ProgressText,
} from './styles';

interface ClassProgressI {
  percentage: number
}

function ClassProgress({ percentage = 0 } :ClassProgressI) {
  return (
    <ProgressContainer data-testid="class-progress">
      <ProgressText>{`${percentage || 0}% concluído`}</ProgressText>
      <ProgressBar>
        <ProgressBarFill percentage={percentage} />
      </ProgressBar>
    </ProgressContainer>
  );
}

export default ClassProgress;

import styled from 'styled-components/';
import theme from '../../theme';

export const ItemButton = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.cardB2};
  border-radius: 10px; 
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const DateText = styled.span`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 25%;
  height: 120px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${theme.colors.cardB3};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 5px solid ${theme.colors.primary};
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-top: 15px; 
  padding-bottom: 15px; 
  padding-left: 10px; 
  padding-right: 10px; 
`;

export const Description = styled.span`
  margin-top: 8px;
  color: ${theme.colors.title};
  font-size: 14px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const Title = styled.span`
  text-transform: uppercase;
  color: ${theme.colors.title};;
  font-size: 18px;
  font-weight: bold;
  
  @media (max-width: 667px) {
    font-size: 14px;
  }
`;

export const Image = styled.img`
  max-width: 60px;
  height: 75%;

  @media (max-width: 667px) {
    height: 45%;
    width: 70%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
`;

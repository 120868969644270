import styled from 'styled-components';

import theme from '../../theme';

export const Card = styled.section`
  display: flex;
  border-radius: 10px;
  background: ${theme.colors.cardB2};
  width: 100%;
  padding: 15px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const InfoContainer = styled.span`
  width: 60%;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: column;

  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const DateText = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.title};
`;

export const IconButton = styled.button`
  border: '1px solid';
  border-color: ${theme.colors.title};
  border-style: solid;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
  background-color: ${theme.colors.cardB2};
  cursor: pointer;
`;

export const TextIconButton = styled.span`
  color: ${theme.colors.title};
  margin-left: 5px;
  font-weight: 500;
  text-transform: uppercase;
`;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export const Title = styled.h3`
    margin-bottom: 20px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
    color: ${appTheme.title};
`;

export const SubscriptionContent = styled.div`
  display: block;
  text-align: center;
  padding-bottom: 20px;


  h4 {
    color: ${theme.colors.text};
    font-size: 13x;
    text-align: center;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 20px;
  }

  p,
  h5 {
    display: inline;
    padding-top: 10px;
  }

  h5 {
    color: ${theme.colors.text};
    font-size: 11px;

    text-align: center;
    font-weight: lighter;
    line-height: 12px;
  }

  p {
    color: ${theme.colors.title};
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    padding-left: 5px;

    &.text-danger {
      color: #b52b12;
    }

    &.text-success {
      color: #36ba14;
    }
  }
`;

export const HeaderText = styled.div`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const HeaderContainer = styled.div`
  display: block;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
`;

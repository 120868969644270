import React, { useState } from 'react';
import { IZone } from '..';
import ZoneInfoModal from '../../ZonesInfoModal';
import {
  ColorIndicator, InfoContainer, ItemContainer, ItemText, ItemTextZone, ValueContainer, ZoneInfo,
} from './styles';

export interface IListItemProps {
  color: string,
  index: number,
  pace: boolean,
  zone: IZone | any,
}

function ListItem({
  zone, color, index, pace,
}: IListItemProps) {
  const [infoModal, setInfoModal] = useState<boolean>(false);

  function formatItemInfo() {
    let prefix = '';
    let separator = '';
    let firstData = '';
    let lastData = '';

    if (pace) {
      if (!zone.start_pace && !zone.end_pace) return '---';

      firstData = zone.end_pace || '';
      lastData = zone.start_pace || '';
    } else {
      if (!zone.start_bpm && !zone.end_bpm) return '---';

      firstData = zone.start_bpm || '';
      lastData = zone.end_bpm || '';
    }

    if (pace) {
      if (zone.order === 1) prefix = 'Acima de';
      if (zone.order === 7) prefix = 'Abaixo de';
    } else {
      if (zone.order === 1) prefix = 'Abaixo de';
      if (zone.order === 7) prefix = 'Acima de';
    }

    if (!(zone.order === 1 || zone.order === 7)) separator = 'a';

    return `${prefix} ${firstData} ${separator} ${lastData}`;
  }

  return (
    <>
      <ItemContainer
        index={index}
      >
        <ColorIndicator
          color={color}
          index={index}
        />
        <InfoContainer onClick={() => setInfoModal(true)}>
          <ZoneInfo>
            <ItemTextZone>{zone.name.split(' - ')[0]}</ItemTextZone>
            <ItemText>
              {
                zone.description.split(' - ').length > 1 ? zone.description.split(' - ')[1] : zone.description
              }
            </ItemText>
          </ZoneInfo>
          <ValueContainer>
            <ItemText>
              {formatItemInfo()}
            </ItemText>
          </ValueContainer>
        </InfoContainer>
      </ItemContainer>
      <ZoneInfoModal
        visible={infoModal}
        setIsVisible={setInfoModal}
        title={zone.name}
        videoUri={zone.video}
      />
    </>
  );
}

export default ListItem;

import styled from 'styled-components';

export const Container = styled.div`
  height: 50%;
  background: black;
  iframe{
    min-height: 220px;
    width: 100%;
    @media (min-width: 768px) {
        min-height: 312px;
    }
    img{
        width: 100%;
    }
  }
`;

export const ContentImage = styled.div`
  min-height: 186px;
  width: 100%;
  background-position: center;
  background-size: cover;
  @media (min-width: 768px) {
    min-height: 312px;
  }
`;

import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import { RootState } from '../../store';
import theme from '../../theme';
import Modal from '../BaseModal';
import { GenericButton, GenericLinkButton } from '../GenericButton/styles';
import Loader from '../Loader';

interface IProps {
  show: boolean
  toggle: () => void
}

export const cancellationReasons = [
  'Financeiro',
  'Falta de tempo para treinar',
  'Falta de disciplina/motivação',
  'Lesão',
  'Outros problemas de saúde',
  'Contratei outro serviço',
  'Mudei de atividade física',
  'Não gostei do serviço',
  'Motivos pessoais',
  'Outros',
];

export default function CancellationModal({ show, toggle }: IProps) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [cancellationReason, setCancellationReason] = useState('');
  const [comments, setComments] = useState('');

  const subscription = useSelector((state: RootState) => state.subscription.subscription);

  const handleCancelSubscription = async () => {
    const data = {
      status: 'CREATED',
      cancellation_reason: cancellationReason,
      comments,
      subscription_id: subscription?.id,
    };

    setLoading(true);

    try {
      await api.post('/subscription_cancellations', data);

      setLoading(false);
      setStep(step + 1);
    } catch (e) {
      setLoading(false);
      setStep(6);
    }
  };

  return (
    <Modal
      isVisible={show}
      setIsVisible={toggle}
      data-testid="modal"
    >
      {
        step === 1
        && (
          <div>
            <h3>Poxa! Ficamos tristes com isso, mas respeitamos sua escolha.</h3>

            <br />

            <h4>
              Ao continuar, você vai cancelar apenas a renovação/pagamento futuro previstos para
              {' '}
              {
                subscription?.next_billing_at
                && format(parseISO(subscription?.next_billing_at!), 'dd/MM/yyyy')
              }
              .
              Caso queira pedir devolução de valores da garantia de 15 dias,&nbsp;
              <a
                href="https://ajuda.corridaperfeita.com/support/solutions/articles/47001154428-como-pedir-o-reembolso-da-garantia-prevista-no-site"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#fff' }}
              >
                veja aqui como fazer
              </a>
              .
            </h4>
          </div>
        )
      }

      {
        step === 2
        && (
          <div>
            <h4>
              Antes de confirmar sua solicitação, poderia nos dizer o principal motivo do seu pedido ?
            </h4>

            <br />

            <span>A resposta é opcional</span>

            <br />
            <br />

            {
              cancellationReasons.map((reason) => (
                <div key={reason}>
                  <label htmlFor="reason">
                    <input
                      checked={cancellationReason === reason}
                      type="radio"
                      name="reason"
                      value={reason}
                      onChange={(e) => setCancellationReason(e.target.value)}
                    />
                    {' '}
                    {reason}
                  </label>
                </div>
              ))
            }
          </div>
        )
      }

      {
        step === 3
        && (
          <div>
            <h4>E antes de finalizar, gostaria de acrescentar algo? Pode ser um elogio, uma crítica, o que quiser.</h4>

            <br />

            <span>A resposta é opcional</span>

            <br />
            <br />

            <textarea
              rows={8}
              style={{ width: '100%' }}
              onChange={
                (e) => setComments(e.target.value)
              }
              value={comments}
              name="comments"
              id="comments"
            />
          </div>
        )
      }

      {
        step === 4
        && (
          <h4>
            Confirma o cancelamento da renovação/cobrança previsto para
            {' '}
            {
              subscription?.next_billing_at
              && format(parseISO(subscription?.next_billing_at!), 'dd/MM/yyyy')
            }
            ?
          </h4>
        )
      }

      {
        step === 5
        && <h4>A sua renovação foi cancelada com sucesso!</h4>
      }

      {
        step === 6
        && (
          <div>
            <h4>Falha!</h4>

            <br />

            <h4>O cancelamento automático da renovação NÃO pode ser finalizado!</h4>

            <br />

            <h4>Isso pode ter acontecido por alguma falha técnica de sistema.</h4>

            <br />

            <h4>
              Você pode tentar novamente, ou ainda, enviar um e-mail para assinatura@corridaperfeita.com
              informando seu CPF e o ocorrido.
            </h4>
          </div>
        )
      }

      {
        step === 5
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            type="button"
            onClick={() => window.location.reload()}
          >
            FECHAR
          </GenericButton>
        )
      }

      {
        step === 1
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            type="button"
            onClick={() => {
              setStep(step + 1);
            }}
          >
            SOLICITAR CANCELAMENTO
          </GenericButton>
        )
      }

      {
        step > 1 && step < 4
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            type="button"
            onClick={() => {
              setStep(step + 1);
            }}
          >
            CONTINUAR
          </GenericButton>
        )
      }

      {
        step === 4
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            type="button"
            onClick={handleCancelSubscription}
          >
            {
              loading
                ? <Loader color="#fff" />
                : 'CONFIRMAR CANCELAMENTO'
            }
          </GenericButton>
        )
      }

      {
        step === 6
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            type="button"
            onClick={() => setStep(1)}
          >
            TENTAR NOVAMENTE
          </GenericButton>
        )
      }

      {
        step === 6
        && (
          <GenericLinkButton
            style={{ width: '100%', marginTop: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.title}
            textColor={theme.colors.title}
            href="mailto:assinatura@corridaperfeita.com"
          >
            ENVIAR E-MAIL
          </GenericLinkButton>
        )
      }

      {
        step !== 6 && step !== 5
        && (
          <GenericButton
            style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.redAlert}
            textColor={theme.colors.redAlert}
            type="button"
            onClick={toggle}
          >
            SAIR
          </GenericButton>
        )
      }
    </Modal>
  );
}

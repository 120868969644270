import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  VictoryBar, VictoryGroup, VictoryLabel,
} from 'victory';
import { yearChartTheme } from '../chartTheme';
import { ChartData } from '../../ProgressAccumulatedDistance';
import theme from '../../../theme';
import ChartSkeleton from '../ChartSkeleton';
import month from '../month';
import { ChartContainer } from '../styles';

interface YearChartProps {
  data: ChartData
  loading: boolean
}

const labelComponent = (
  <VictoryLabel
    textAnchor="middle"
    style={[
      { fontSize: 10, fill: theme.colors.grayProg },
      { fontSize: 6, fill: theme.colors.title },
    ]}
    y={130}
    // @ts-ignore
    text={
      ({ datum }) => [
        `${month[moment(datum.month.start).month()]}`,
        `${`${Math.round(datum.y)} Km`}`,
      ]
    }

  />
);

function useComponentWidth(ref: any) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect();
        setWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return width;
}

export default function YearChart({ data, loading }: YearChartProps) {
  const containerRef = useRef(null);
  const containerWidth = useComponentWidth(containerRef);
  const chartWidth = containerWidth * 0.80;

  return (
    <>
      {
        loading
          ? <ChartSkeleton />
          : (
            <ChartContainer ref={containerRef}>
              <VictoryGroup
                theme={yearChartTheme}
                width={chartWidth}
                height={130}
                padding={{
                  left: 20, bottom: 25, right: 20, top: 10,
                }}
              >
                <VictoryBar
                  style={{
                    data: {
                      stroke: 'none',
                      fill: ({ datum }) => datum.color,
                    },
                  }}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  data={data.data}
                  alignment="middle"
                  barWidth={15}
                  labelComponent={labelComponent}
                  labels={({ datum }) => [datum.month.start, datum.y]}
                />
              </VictoryGroup>
            </ChartContainer>
          )
      }
    </>
  );
}

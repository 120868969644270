import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reloadTrainingPlanRequest } from '../../../../store/TrainingPlanRequests.store';
import BaseModal from '../../../BaseModal';
import { IProps } from './contracts';
import {
  ModalBackground,
  ModalContent,
  ModalContentTitle,
  ModalContentSubTitle,
  ModalContentButton,
  ModalContentButtonText,
  ModalContentButtonContainer,
  ModalContentSubTitleContainer,
  ModalContentBody,
} from './styles';

function FinishRequestModal({
  showModal, setShowModal, selectedSchemaType,
}: IProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(reloadTrainingPlanRequest(true) as any);

    setShowModal(false);
    navigate(-1);
  };

  return (
    <BaseModal
      isVisible={showModal}
    >
      <ModalBackground>
        <ModalContent>
          <ModalContentBody>
            <ModalContentTitle>
              {
                selectedSchemaType! === 'ALTERNATIVE'
                  ? 'RECEBEMOS SUA SOLICITAÇÃO DE PLANO DE TREINO ALTERNATIVO COM SUCESSO.'
                  : 'RECEBEMOS SUA SOLICITAÇÃO DE PLANO DE TREINO DE CORRIDA COM SUCESSO.'
              }
            </ModalContentTitle>

            <ModalContentSubTitleContainer>
              <ModalContentSubTitle>
                Em até 2 (dois) dias úteis o seu treinador
                irá preparar e liberar o seu treino.
              </ModalContentSubTitle>
            </ModalContentSubTitleContainer>

            <ModalContentButtonContainer>
              <ModalContentButton onClick={handleClose}>
                <ModalContentButtonText>OK</ModalContentButtonText>
              </ModalContentButton>
            </ModalContentButtonContainer>
          </ModalContentBody>
        </ModalContent>
      </ModalBackground>
    </BaseModal>
  );
}

export default FinishRequestModal;

import React from 'react';
import Modal from '../BaseModal';
import {
  Content, Title,
} from './styles';
import { GenericButton } from '../GenericButton/styles';
import theme from '../../theme';

interface IProps {
  show: boolean
  toggle: () => void
  setShowRefundFormModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowPauseModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function RefundModal({
  show, toggle, setShowRefundFormModal, setShowPauseModal,
}: IProps) {
  return (
    <Modal
      isVisible={show}
      setIsVisible={toggle}
    >
      <Title>Você deseja mesmo cancelar sua adesão e ter seu dinheiro de volta?</Title>

      <h4>
        Se a pausa no uso dos nossos serviços for temporária, você pode solicitar apenas uma suspensão do
        seu tempo de acesso, para assim não perder o valor que pagou por sua adesão, em caso de reajuste
        futuro.
      </h4>

      <Content>
        <GenericButton
          style={{ marginBottom: '30px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.title}
          textColor={theme.colors.title}
          type="button"
          onClick={() => setShowRefundFormModal(true)}
        >
          QUERO O REEMBOLSO
        </GenericButton>

        <GenericButton
          style={{ marginBottom: '30px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.title}
          textColor={theme.colors.title}
          type="button"
          onClick={() => setShowPauseModal(true)}
        >
          QUERO A PAUSA TEMPORÁRIA
        </GenericButton>

        <GenericButton
          style={{ marginBottom: '30px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.redAlert}
          textColor={theme.colors.redAlert}
          type="button"
          onClick={toggle}
        >
          SAIR
        </GenericButton>
      </Content>
    </Modal>
  );
}

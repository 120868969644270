import React from 'react';
import { Container } from './styles';

// Use customLayout to customize the look of skeleton container.
export interface ISkeletonContainer {
  customLayout?: any;
}

function SkeletonContainer({ customLayout }: ISkeletonContainer) {
  return (
    <>
      <Container
        customLayout={customLayout}
      />
      ;
    </>
  );
}
export default SkeletonContainer;

import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const Container = styled.div`
    width: 100%;
    background-color: ${appTheme.cardB2};
    border-radius: 10px;
`;

export const WeekContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 5px 5px 0;
    margin-top: 10px;

    @media (max-width: 667px) {
    }
`;

export const CalendarContainer = styled.div`
    padding: 16px;
    color: ${appTheme.title};
`;

export const DayTrainingsContainer = styled.div`
    align-items: center;
    width: 100%;
    padding: 0 5px;
`;

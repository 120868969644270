import React, { useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import theme from '../../theme';
import ProgressModal from '../ProgressModal';

import {
  Container, Content, TextContainer, TextInfo, Title,
} from './styles';

export default function RegularityLevel() {
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const toggleInfoModal = () => {
    setInfoModalVisible((isOpen) => !isOpen);
  };
  return (
    <Container data-testid="regularity-container">
      <Content>
        <TextContainer>
          <Title>
            Nível de regularidade
          </Title>
        </TextContainer>
        <TextInfo
          onClick={() => setInfoModalVisible(true)}
        >
          <MdInfoOutline name="info-outline" color={theme.colors.text} size={18} style={{ cursor: 'pointer' }} />
        </TextInfo>
      </Content>
      <ProgressModal isOpen={infoModalVisible} toggle={toggleInfoModal} />
    </Container>
  );
}

import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import api, { api2 } from '../../services/api';
import { RootState } from '../../store';
import {
  Container, ItemTitle, InputGroup, InputLabel, Input,
} from './styles';
import { GenericButton } from '../GenericButton/styles';
import theme from '../../theme';
import Loader from '../Loader';
import StopAskShoesModal from '../MyShoes/StopAskShoesModal';
import { IShoesBrand } from '../../store/Activities.store';
import { IProps } from './contracts';
import { updateProfileProps } from '../../store/Auth.store';
import SearchBrandInput from '../SearchBrandInput';

export default function RegisterShoes({ cb }: IProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const [askAboutActivityShoes, setAskAboutActivityShoes] = useState(false);
  const [showStopAskModal, setShowStopAskModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveModalLoading, setSaveModalLoading] = useState(false);
  const [brand, setBrand] = useState<IShoesBrand | null>(null);
  const [name, setName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [distance, setDistance] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    setAskAboutActivityShoes(!!user?.ask_about_activity_shoes!);
  }, [user?.ask_about_activity_shoes!]);

  const clearInputs = () => {
    setBrandName('');
    setName('');
    setBrand(null);
    setDistance('');
    setColor('');
  };

  const handleSaveShoes = () => {
    setSaveLoading(true);

    const payload: any = {
      name,
      shoes_brand_id: brand?.id,
      color: color || null,
    };

    if (distance) {
      payload.distance_total = parseFloat(distance.replace(',', '.'));
    }

    api2.post('shoes', payload)
      .then((response) => {
        toast.success('Tênis salvo com sucesso');
        clearInputs();
        cb!(response.data.id);
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          switch (e.response.data.message) {
            case 'shoes_already_exists':
              toast.error('Você já salvou esse tênis');
              break;
            default:
              toast.error('Erro ao salvar tênis');
              break;
          }
        } else {
          toast.error('Erro ao salvar tênis');
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const updateOptions = useCallback(async (payload: any) => {
    try {
      await api.put(`/users/${user.id}`, payload);

      dispatch(updateProfileProps(payload) as any);
    } catch (error) {
      toast.error('Ocorreu um erro ao alterar a opção, tente novamente');
    }
  }, []);

  const handleSaveModalShoes = () => {
    updateOptions({ ask_about_activity_shoes: true });

    setSaveModalLoading(true);

    const payload = {
      name,
      shoes_brand_id: brand?.id,
      color: color || null,
    };

    api2.post('shoes', payload)
      .then((response) => {
        toast.success('Tênis salvo com sucesso');
        clearInputs();
        setShowStopAskModal(false);
        cb!(response.data.id);
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          switch (e.response.data.message) {
            case 'shoes_already_exists':
              toast.error('Você já salvou esse tênis');
              break;
            default:
              toast.error('Erro ao salvar tênis');
              break;
          }
        } else {
          toast.error('Erro ao salvar tênis');
        }
      })
      .finally(() => {
        setSaveModalLoading(false);
      });
  };

  return (
    <>
      <StopAskShoesModal
        visible={showStopAskModal}
        setVisible={setShowStopAskModal}
        handleSave={handleSaveModalShoes}
        loading={saveModalLoading}
      />

      <Container>
        <ItemTitle>
          CADASTRAR NOVO TÊNIS
        </ItemTitle>

        <InputGroup>
          <InputLabel>Marca</InputLabel>

          <SearchBrandInput
            setBrand={setBrand}
            setBrandName={setBrandName}
            brandName={brandName}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Modelo</InputLabel>

          <Input
            type="text"
            placeholder="Digite o modelo"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Cor</InputLabel>

          <Input
            type="text"
            placeholder="Digite a cor (Opcional)"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Distância já percorrida anteriormente em km</InputLabel>

          <Input
            type="number"
            placeholder="0 km (Opcional)"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
        </InputGroup>

        <GenericButton
          backgroundColor={!brand || !name ? theme.colors.cardB3 : theme.colors.title}
          textColor={theme.colors.primary}
          borderColor={!brand || !name ? theme.colors.cardB3 : theme.colors.title}
          style={{
            width: '50%',
            marginTop: '30px',
            marginBottom: '20px',
          }}
          disabled={!brand || !name}
          onClick={askAboutActivityShoes ? handleSaveShoes : () => setShowStopAskModal(true)}
        >
          {saveLoading ? <Loader color={theme.colors.primary} /> : 'SALVAR'}
        </GenericButton>
      </Container>
    </>
  );
}

import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import BaseModal, { IBaseModalProps } from '../../BaseModal';
import api from '../../../services/api';
import { Activity } from '../../../store/Activities.store';
import {
  Header, Container, ConfirmText, RemoveButton, RemoveText,
  CancelButton, CancelText,
} from './styles';

export interface RemoveActivityModalProps extends IBaseModalProps {
  activity: Activity;
  callback: Function;
}

function RemoveActivityModal({
  activity, callback, ...baseModalProps
}: RemoveActivityModalProps) {
  const navigation = useNavigate();
  const removeActivity = () => {
    const actToDelete = { id: activity.id, title: activity.title, visible: false };
    api.put(`user_activities/${activity.id}`, actToDelete)
      .then(() => {
        toast('Atividade removida com sucesso!');
        navigation(-1);
        callback();
      })
      .catch(() => {
        toast.error('Não foi possivel remover sua atividade, tente novamente.');
      });
  };

  return (
    <BaseModal {...baseModalProps}>
      <Container>
        <Header>Remover atividade</Header>
        <ConfirmText>Tem certeza?</ConfirmText>
        <RemoveButton onClick={() => removeActivity()}>
          <RemoveText>Remover</RemoveText>
        </RemoveButton>
        <CancelButton onClick={() => baseModalProps.setIsVisible?.(false)}>
          <CancelText>Cancelar</CancelText>
        </CancelButton>
      </Container>
    </BaseModal>
  );
}
export default RemoveActivityModal;

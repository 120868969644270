import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
    background-color: ${theme.colors.primary};
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 667px) {
        max-width: 320px;
    }
`;

export const Title = styled.h3`
    font-size: 12pt;
    color: ${theme.colors.title};
    font-weight: bold;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${theme.colors.background};
    width: 100%;
    max-width: 320px;
    height: 45px;
    border-radius: 4px;
    padding: 10px;
`;

export const Input = styled.input`
    background-color: ${theme.colors.background};
    width: 100%;
    height: 45px;
    border: none;
    padding: 10px;
    color: ${theme.colors.black};
    font-size: 12pt;

    ::placeholder {
        color: ${theme.colors.black};
        font-size: 12pt;
    }
`;

export const SignInButton = styled.button`
    background-color: ${theme.colors.secondary};
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    height: 45px;
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
    color: ${theme.colors.black};
    transition: 0.3s;
    margin-top: 20px;

    &:hover {
      opacity: 0.8;
    }
`;

export const ForgotPasswordLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.title};
    margin-top: 50px;
`;

export const SignUpLink = styled.a`
    color: ${theme.colors.title};
    margin-top: 50px;
    text-decoration: underline;
    cursor: pointer;
`;

export const Form = styled.form`
    width: 100%;
    max-width: 320px;
    margin-top: 50px;
`;

export const Logo = styled.img`
    margin-bottom: 50px;
    height: 100px;
`;

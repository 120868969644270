import React, { useRef } from 'react';
import { ChartContainer } from '../styles';

export default function ChartSkeleton() {
  const containerRef = useRef(null);
  return (
    <ChartContainer ref={containerRef} style={{ height: 130 }} />

  );
}

import React, {
  useEffect, useState, useRef, PropsWithChildren,
} from 'react';
import { useDispatch } from 'react-redux';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { getClasses } from '../../store/Classes.store';
import {
  Content,
  ArrowLeft,
  ArrowRight,
  ArrowsContainer,
} from './styles';

function Carousel({ children }: PropsWithChildren) {
  const [hideButtonLeft, setHideButtonLeft] = useState<boolean>(true);
  const [hideButtonRight, setHideButtonRight] = useState<boolean>(false);
  const SliderRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getClasses());
  }, []);

  const moveRight = () => {
    if (SliderRef.current != null) {
      SliderRef.current.scrollLeft += 200;
    }
  };

  const moveLeft = () => {
    if (SliderRef.current != null) {
      SliderRef.current.scrollLeft -= 200;
    }
  };

  const onSlider = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollAmt = e.currentTarget.scrollLeft;
    const sliderWidth = e.currentTarget.scrollWidth - e.currentTarget.offsetWidth;

    if (scrollAmt > 0) {
      setHideButtonLeft(false);
    } else {
      setHideButtonLeft(true);
    }
    if (scrollAmt < sliderWidth) {
      setHideButtonRight(false);
    } else {
      setHideButtonRight(true);
    }
  };

  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (SliderRef.current) {
        const carouselWidth = SliderRef.current.getBoundingClientRect().width;
        const contentWidth = SliderRef.current.scrollWidth;
        setShowArrow(contentWidth > carouselWidth + 10);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [children]);

  return (
    <>
      {showArrow
    && (
      <ArrowsContainer>
        <>
          <ArrowLeft onClick={moveLeft} style={{ visibility: hideButtonLeft ? 'hidden' : 'unset' }}>
            <MdChevronLeft size={60} />
          </ArrowLeft>
        </>
        <>
          <ArrowRight onClick={moveRight} style={{ visibility: hideButtonRight ? 'hidden' : 'unset' }}>
            <MdChevronRight size={60} />
          </ArrowRight>
        </>
      </ArrowsContainer>
    )}

      <Content ref={SliderRef} onScroll={onSlider}>
        {children}
      </Content>
    </>
  );
}

export default Carousel;

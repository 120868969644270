import styled from 'styled-components';
import { components } from 'react-select';
import theme from '../../../theme';

export const Container = styled.section`
  display: block;
  background: ${theme.colors.cardB1};
  padding-bottom: 0px;
`;

export const Content = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Text = styled.span`
  color: ${theme.colors.title};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 667px) {
    font-size: 14px;
  }
`;

export const SubTitle = styled.span`
  color: ${theme.colors.title};
  margin-top: 16px;
  font-size:  11px;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  background-color: ${theme.colors.graph};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 18px;
  padding-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: ${theme.colors.graph};
  margin-right: 80px;
  margin-left: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  color: ${theme.colors.title};
  font-weight: 600;
  text-transform: uppercase;
`;

export const FilterText = styled.span`
  color: ${theme.colors.gray};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  padding-bottom: 5px;
  width: 100%;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const Disclaimer = styled.span`
  text-align: right;
  color: ${theme.colors.gray};
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  width: 100%;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

interface IFilterButtonProps {
  flex?: number;
}

export const DisclaimerContent = styled.div<IFilterButtonProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex || 1};
`;

export const FilterContent = styled.div<IFilterButtonProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex || 1};
  margin-top: 10px;
  margin-bottom: 10px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"]::-moz-number-spin-box {
    display: none;
  }

  input[type="number"]::-ms-inner-spin-button {
    display: none;
  }
`;

export const FilterContent2 = styled(FilterContent)`
  margin: 20px 0 35px;

  @media (max-width: 667px) {
    margin: 15px 0 20px;
  }
`;

export const ErrorMessage = styled.div`
  text-align: center;
  color: ${theme.colors.redAlert};
`;

export const TimelineContainer = styled.div`
  margin-top: 20px;
`;

export const TimelineContent = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid #24244f;
  padding-left: 16px;
  margin-left: 15px;
  justify-content: center;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimelineMonth = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
export const MonthLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.colors.title};
  font-size: 16px;

  @media (max-width: 667px) {
    font-size: 14px;
  }
`;

export const DayLabel = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  color: ${theme.colors.title};
  font-size: 13px;
  margin-left: 7px;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const Events = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Input = styled.input`
  padding: 0 10px;
  color: ${theme.colors.title};
  background: ${theme.colors.cardB2};
  height: 40px;
  border-radius: 6px;
  width: 100%;
  border: none;

  @media (max-width: 667px) {
    font-size: 12px;
    height: 30px;
    padding: 0 5px;
  }

  &::placeholder {
    color: ${theme.colors.cardTxt};

    @media (max-width: 667px) {
      font-size: 12px;
    }
  }
`;

export const InputContainer = styled.div`
  padding: 0 10px;
  color: ${theme.colors.title};
  background: ${theme.colors.cardB2};
  height: 40px;
  border-radius: 6px;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${theme.colors.cardB2};
    margin: 0;
    font: inherit;
    color: ${theme.colors.title};
    width: 1.15em;
    height: 1.15em;
    border: 0.1em solid ${theme.colors.title};
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${theme.colors.title};
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
    background-color: ${theme.colors.title};
  }

  @media (max-width: 667px) {
    font-size: 12px;
    height: 50px;
    padding: 0 5px;
  }
`;

export const customSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any) => ({
    ...provided,
    height: '40px',
    background: theme.colors.cardB2,
    border: 'none',
    boxShadow: 'none',
    minHeight: '20px',
    paddingRight: '10px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'white',
    padding: '0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: theme.colors.title,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.cardTxt,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    margin: '5px 10px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const StyledControl = styled(components.Control)`
  @media (max-width: 667px) {
    font-size: 12px;
    height: 30px !important;
    padding-right: 0 !important;
  }
`;

export const StyledIndicatorSeparator = styled(components.IndicatorSeparator)`
  @media (max-width: 667px) {
    margin: 6px 0 !important;
  }
`;

export const StyledIndicatorPlaceholder = styled(components.Placeholder)`
  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const StateTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.title};
  position: absolute;
  text-align: center;

  left: 50%;
  transform: translateX(-50%);
`;

import React, { useEffect, useState } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackArrow from '../BackArrow';
import {
  Container, HeaderContainer, LinkButton, LinkButtonText, NewTestButton, NewTestContainer,
  NewTextButtonText, ResultsContainer, ResultsHeader, ResultsValues, ValuesContainer, ValuesText,
  IconButton,
  ResultsTitle,
  TextHeader,
  NewTestHeader,
} from './styles';
import { appTheme } from '../../theme';
import api from '../../services/api';
import { RootState } from '../../store';
import RemoveZoneTestModal from '../RemoveZoneTestModal';
import ZoneInfoModal from '../ZonesInfoModal';
import NewZoneTestModal from '../NewZoneTestModal';
import { refreshUser } from '../../store/Auth.store';
import SkeletonContainer from '../SkeletonContainer';

export interface IUserZoneTest {
  'id': number,
  'user_id': number,
  'execution_date': string,
  'type': string | null,
  'bpm_avg': number | null,
  'duration': string | null,
  'pace': string | null,
  'created_at': string,
  'updated_at': string
}

export interface IZonesProps {
  _zones?: IUserZoneTest[]
}

function Zones({ _zones }: IZonesProps) {
  const navigate = useNavigate();

  const [zones, setZones] = useState(_zones || []);
  const [removeZoneModal, setRemoveZoneModal] = useState(false);
  const [zoneInfoModal, setZoneInfoModal] = useState(false);
  const [newTestModal, setNewTestModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const zoneInfoData = {
    videoUri: 'https://player.vimeo.com/video/580398056',
    desc: 'Veja no vídeo como realizar o teste de corrida, cujo resultado, ao ser inserido em nossa plataforma, vai calcular suas zonas de treino de corrida por ritmo (min/km) e/ou por Frequência Cardíaca (BPM).',
    title: 'Como fazer o teste',
  };

  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const loadZones = async () => {
    setLoading(true);

    try {
      const response = await api.get(`/user_zones?user_id=${user?.id}`);

      setZones(response.data);
    } finally {
      setLoading(false);
    }
  };

  const removeCallback = async () => {
    dispatch(refreshUser(user?.id) as any);

    await loadZones();
  };

  const newZoneTestCallback = async () => {
    await loadZones();

    setNewTestModal(false);
  };

  useEffect(() => {
    loadZones();
  }, []);

  return (
    <Container>
      <ZoneInfoModal
        visible={zoneInfoModal}
        setIsVisible={setZoneInfoModal}
        {...zoneInfoData}
      />

      <NewZoneTestModal
        isVisible={newTestModal}
        setIsVisible={setNewTestModal}
        callback={newZoneTestCallback}
      />

      <BackArrow
        text="ZONAS"
        bigger={false}
        callback={() => {
          navigate(
            '/training',
            {
              state: {
                selectedTabIndex: 3,
              },
            },
          );
        }}
      />

      <NewTestContainer>
        <NewTestHeader>
          <NewTestButton onClick={() => setNewTestModal(true)}>
            <NewTextButtonText>Registrar novo teste</NewTextButtonText>
          </NewTestButton>

          <LinkButton onClick={() => setZoneInfoModal(true)}>
            <MdPlayCircleFilled size={20} color="white" />
            <LinkButtonText>Como fazer o teste</LinkButtonText>
          </LinkButton>
        </NewTestHeader>

        <ResultsContainer>
          <ResultsTitle>Resultados do teste vigente</ResultsTitle>

          <ResultsHeader>
            <HeaderContainer width={25}>
              <TextHeader>DATA</TextHeader>
            </HeaderContainer>
            <HeaderContainer width={30} alignment="center">
              <TextHeader>BPM MÉDIO</TextHeader>
            </HeaderContainer>
            <HeaderContainer width={30} alignment="flex-end">
              <TextHeader>RITMO MÉDIO</TextHeader>
            </HeaderContainer>
          </ResultsHeader>

          {
            loading
              ? (
                <SkeletonContainer customLayout={{
                  width: '100%',
                  height: '35px',
                  marginTop: 1,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
                />
              )
              : zones.slice(0, 1).map((zone: any) => (
                <div key={zone.id}>
                  <RemoveZoneTestModal
                    visible={removeZoneModal}
                    zoneTest={zone}
                    close={() => setRemoveZoneModal(false)}
                    refresh={removeCallback}
                  />

                  <ResultsValues>
                    <ValuesContainer width={26}>
                      <ValuesText>{format(parseISO(zone.execution_date), 'dd/MM/yyyy')}</ValuesText>
                    </ValuesContainer>
                    <ValuesContainer width={24} alignment="center">
                      <ValuesText>{zone.bpm_avg ?? '-'}</ValuesText>
                    </ValuesContainer>
                    <ValuesContainer width={30} alignment="flex-end">
                      <ValuesText>{zone.pace ?? '-'}</ValuesText>
                      <IconButton onClick={() => setRemoveZoneModal(true)}>
                        <FaTrash size={15} color={appTheme.redAlert} />
                      </IconButton>
                    </ValuesContainer>
                  </ResultsValues>
                </div>
              ))
          }
        </ResultsContainer>

        {
          zones.length > 1
          && (
            <ResultsContainer>
              <ResultsTitle>Histórico de testes</ResultsTitle>
              <ResultsHeader>
                <HeaderContainer width={25}>
                  <TextHeader>DATA</TextHeader>
                </HeaderContainer>
                <HeaderContainer width={30} alignment="center">
                  <TextHeader>BPM MÉDIO</TextHeader>
                </HeaderContainer>
                <HeaderContainer width={30} alignment="flex-end">
                  <TextHeader>RITMO MÉDIO</TextHeader>
                </HeaderContainer>
              </ResultsHeader>

              {
                zones.slice(1, zones.length).map((zone: any) => (
                  <ResultsValues key={zone.id}>
                    <ValuesContainer width={26}>
                      <ValuesText>{format(parseISO(zone.execution_date), 'dd/MM/yyyy')}</ValuesText>
                    </ValuesContainer>
                    <ValuesContainer width={24} alignment="center">
                      <ValuesText>{zone.bpm_avg ?? '-'}</ValuesText>
                    </ValuesContainer>
                    <ValuesContainer width={30} alignment="flex-end">
                      <ValuesText>{zone.pace ?? '-'}</ValuesText>
                    </ValuesContainer>
                  </ResultsValues>
                ))
              }
            </ResultsContainer>
          )
        }
      </NewTestContainer>
    </Container>
  );
}

export default Zones;

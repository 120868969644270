import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: block;

  background: ${theme.colors.cardB1};
`;

export const HeaderContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LinkTextButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 5px;

  cursor: pointer;
`;

export const LinkText = styled.span`
  display: flex;
  text-decoration: underline;
  color: ${theme.colors.title};
  margin-left: 3px;
  font-size: 11px;
`;

export const DetailContainer = styled.section`
  display: block;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px;
  background-color: ${theme.colors.cardB2};
`;

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 600;
  color: ${theme.colors.title};
  margin-bottom: 5px;
`;

export const Info = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${theme.colors.title};
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LiveButton = styled.button`
  display: flex;
  justify-items: center;
  background-color: white;
  padding: 8px;
  max-width: 40%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  align-self: center;
  cursor: pointer;
`;

export const LiveButtonText = styled.span`
  color: ${theme.colors.cardB1};
  padding: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;

export const RecordedClassesContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  
`;

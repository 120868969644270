import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { ClassI } from '../types/Class';
import { AppThunk } from '.';
import api from '../services/api';

interface initialStateI {
  ClassList: Array<ClassI>;
  currentClass: ClassI | null;
  id?: number;
}

const classes = createSlice({
  name: 'classes',
  initialState: {
    ClassList: [],
    currentClass: null,
    id: 0,
  } as initialStateI,
  reducers: {
    setCurrentClass: (state, action) => {
      state.currentClass = action.payload;
    },
    setIdClass: (state, action) => {
      state.id = action.payload;
    },
    setClasses: (state, action) => {
      state.ClassList = action.payload;
    },
  },
});

export default classes.reducer;
export const { setCurrentClass, setIdClass, setClasses } = classes.actions;

export const getClasses = (): AppThunk => async (dispatch, getState) => {
  const id = getState()?.auth.user.id;
  await api
    .get(`/courses?user_id=${id}`)
    .then((res) => {
      const { data } = res;
      dispatch(setClasses(data));
    })
    .catch(() => {
      toast.error('Erro de conexão');
    });
};

import React, { useEffect, useRef } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import 'chart.js/auto';

interface IDoughnutChartProps {
  data: ChartData<'doughnut', number[], string>;
  options: ChartOptions<'doughnut'>;
  canvasStyle?: React.CSSProperties;
}

function DoughnutChart({ data, options, canvasStyle = { height: 100, width: 100 } }: IDoughnutChartProps) {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (ctx) {
        // eslint-disable-next-line no-new
        new Chart(ctx, {
          type: 'doughnut',
          data,
          options,
        });
      }
    }
  }, []);

  return <canvas ref={chartRef} style={canvasStyle} />;
}

export default DoughnutChart;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import BackArrow from '../../components/BackArrow';
import { PROFILE_FIELD } from '../../components/ProfileCard';
import { Container, ProfileEditContainer } from './style';
import SubscriptionInfo from '../../components/Subscription';
import PersonalInfo from '../../components/PersonalInfos';
import AccountData from '../../components/AccountData';
import Integration from '../../components/Integration';
import {
  integrateCoros, integrateGarmin, integratePolar, integrateStrava,
} from '../../store/Integration.store';
import { throughStravaProviders, useQuery } from '../../utils';
import MyShoes from '../../components/MyShoes';
import IntegrationDetails from '../../components/IntegrationDetails';

export default function ProfileEdit() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const parsedParams = queryString.parse(location.search);
  const {
    code, provider, oauth_token, oauth_token_secret, oauth_verifier, error,
  } = parsedParams;
  const query = useQuery();
  const [field, setField] = useState('');

  useEffect(() => {
    if (error === 'access_denied') {
      setField(PROFILE_FIELD.INTEGRACOES);
    } else {
      setField(query.get('field') || PROFILE_FIELD.DADOS_DA_CONTA);
    }
  }, [query.get('field')]);

  useEffect(() => {
    const isGarminValid = provider === 'garmin' && !!oauth_token && !!oauth_token_secret && !!oauth_verifier;

    if (isGarminValid) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integrateGarmin(window.location.href));
    }
  }, [oauth_token, oauth_token_secret, oauth_verifier]);

  useEffect(() => {
    const providerThrough = throughStravaProviders[capitalize(provider as string)] || null;
    const isStravaValid = (!!providerThrough || provider === 'strava') && !!code && provider !== throughStravaProviders.Polar;

    if (isStravaValid) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integrateStrava(window.location.href, providerThrough));
    }
  }, [code]);

  useEffect(() => {
    const isCorosValid = provider === 'coros' && !!code;

    if (isCorosValid) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integrateCoros(window.location.href));
    }
  }, [code]);

  useEffect(() => {
    const isPolarValid = provider === 'polar' && !!code;

    if (isPolarValid) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integratePolar(window.location.href));
    }
  }, [code]);

  return (
    <Container>
      <BackArrow
        text={field === PROFILE_FIELD.DETALHES_INTEGRACAO ? 'INTEGRAÇÕES' : 'PERFIL E CONFIGURAÇÕES'}
        backTo={field === PROFILE_FIELD.DETALHES_INTEGRACAO ? '/profile/edit?field=integracoes' : '/profile'}
        bigger
      />

      <ProfileEditContainer>
        {field === PROFILE_FIELD.DADOS_DA_CONTA ? (
          <AccountData />
        ) : field === PROFILE_FIELD.FINANCEIRO ? (
          <SubscriptionInfo />
        ) : field === PROFILE_FIELD.INFORMACOES_PESSOAIS ? (
          <PersonalInfo />
        ) : field === PROFILE_FIELD.INTEGRACOES ? (
          <Integration />
        ) : field === PROFILE_FIELD.DETALHES_INTEGRACAO ? (
          <IntegrationDetails provider={provider as string} />
        ) : field === PROFILE_FIELD.MEUS_TENIS ? (
          <MyShoes />
        ) : <></>}
      </ProfileEditContainer>
    </Container>
  );
}

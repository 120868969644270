import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;  
  margin-right: 15px;
`;

export const Button = styled.button`
  background-color: ${theme.colors.primary} ;
  border: none;
  padding: 10px 5px; 
  margin: 5px;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: #fff;
  font-size: 16px;
`;

export const NumberDisplay = styled.div`
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  border: solid 1px #FFF;
  width: 45px;
  height: 35px;
  color: #fff;
  background-color: ${theme.colors.primary};
  padding: 7px;
  border-radius: 4px;
`;

export const ButtonPlaceholder = styled.div`
  width: 26px;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
  border-radius: 15px;
  padding: 16px;
  background: linear-gradient(#24244F, #42427E);
`;

export const Container = styled.div`
`;

export const Title = styled.h3`
  margin-top: 13px;
  color: ${theme.colors.notifications};
  text-transform: uppercase;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const ActiveTitle = styled.h3`
  margin-top: 13px;
  color: ${theme.colors.notifications};
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const Info = styled.p`
  margin-top: 13px;
  color: white; 
  font-size: 16px;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Button = styled.button`
  border: none;
  background-color: white;
  color: ${theme.colors.primary};
  width: 45%;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 13px;
  margin-bottom: 13px;
  border-radius: 10px;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: 667px) {
    width: 75%;
    font-size: 13px;
  }
`;

export const Image = styled.img`
  height: 75%;
  padding: 8px;
  margin-right: 13px;

  @media (max-width: 667px) {
    height: 120px;
  }
`;

export const LinkButton = styled.span`
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

import styled from 'styled-components';
import theme from '../../theme';

interface IWrapperProps {
  displayNavbar: boolean;
}

export const Background = styled.main`
    background-color: ${theme.colors.primary2};
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
`;
export const BackgroundProgress = styled.main`
    background-color: ${theme.colors.primary};
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div<IWrapperProps>`
    width: 100%;
    max-width: 600px;
    margin-top: ${(props) => (props.displayNavbar ? 140 : 80)}px;

    @media (max-width: 667px) {
        margin-top: ${(props) => (props.displayNavbar ? 115 : 70)}px;
        max-width: 320px;
    }
`;

import React from 'react';
import {
  Container, Content, Title, Description,
} from './style';
import Switch from '../Switch/index';

interface IntegrationI {
  title: string
  description: string
  enabled: boolean
  toggle?: () => void
  hideDescription?: boolean
}

export default function IntegrationContent({
  title,
  description,
  enabled,
  toggle = () => { },
  hideDescription = false,
}: IntegrationI) {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>

        {!hideDescription && <Description>{description}</Description>}
      </Content>

      <Switch isEnabled={enabled} toggle={toggle} />
    </Container>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { ItemTitle, ModalContainer, ModalButtonsContainer } from './styles';
import BaseModal from '../BaseModal';
import { GenericButton } from '../GenericButton/styles';
import Loader from '../Loader';
import { IShoes } from '../../store/Activities.store';
import theme from '../../theme';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  shoes: IShoes;
  handleDetach: () => void;
  loading: boolean;
}

export default function DetachShoesModal({
  visible, setVisible, shoes, handleDetach, loading,
}: IProps) {
  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          {shoes ? `Tem certeza que deseja desvincular o tênis ${shoes?.name}?` : 'Tem certeza que deseja alterar?'}
        </ItemTitle>

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.redAlert}
            borderColor={theme.colors.redAlert}
            style={{
              width: '100%',
            }}
            onClick={handleDetach}
          >
            {loading && <Loader color={theme.colors.title} />}
            {!loading && !!shoes && 'DESVINCULAR'}
            {!loading && !shoes && 'SIM'}
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ActivityContainer = styled.div`
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    display: inline-flex;
    gap: 25px;

    @media (max-width: 667px) {
        gap: 8px;    
    }
`;

export const LoadingText = styled.span`
    color: ${appTheme.title};
    font-size: 11px; 
    margin-bottom: 10px;
`;

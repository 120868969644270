import React from 'react';
import ContentLoader from 'react-content-loader';
import { appTheme } from '../../theme';

function ExerciseListItemSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 568 500"
      backgroundColor={appTheme.background}
      foregroundColor={appTheme.cardB3}
    >
      <rect x="0" y="41" rx="5" ry="5" width="100%" height="184" />
      <rect x="0" y="255" rx="5" ry="5" width="100%" height="35" />
      <rect x="0" y="320" rx="5" ry="5" width="100%" height="35" />
      <rect x="0" y="385" rx="5" ry="5" width="100%" height="35" />
    </ContentLoader>
  );
}

export default ExerciseListItemSkeleton;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  margin-top: 40px;
  max-width: 100%;
  text-align: center;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
`;

export const SubText = styled.p`
  color: ${theme.colors.title};
  font-weight: 600;
  text-align: center;
  font-size: 12px;
`;

export const Title = styled(SubText)`
  font-size: 24px;
  font-weight: bold;
`;

import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import BackArrow from '../BackArrow';
import {
  Button, ButtonText, Content, Container, TextContainer, TextInfo, TimeInfoContainer, TimeInfoText,
} from './styles';

export default function AskQuestions() {
  return (

    <Container data-testid="ask-container">
      <BackArrow text="TIRA DÚVIDAS COM TREINADORES" bigger backTo="/club" />
      <Content>
        <TextContainer>
          <TextInfo>
            Interaja ao vivo com nossos treinadores, numa sala virtual exclusiva,
            e tire suas principais dúvidas sobre corrida ou sobre seus treinos.
          </TextInfo>
        </TextContainer>
        <TimeInfoContainer>
          <FaRegClock size={20} color="white" />
          <TimeInfoText>Sempre na última quinta-feira do mês, 18h30m (UTM-3)</TimeInfoText>
        </TimeInfoContainer>
        <Button onClick={() => window.open('https://us02web.zoom.us/webinar/register/WN_LB6IJK0UTmWfZ85lkjl4qQ')}>
          <ButtonText>Inscreva-se no próximo Tira dúvidas</ButtonText>
        </Button>
      </Content>
    </Container>
  );
}

import styled from 'styled-components';
import theme from '../../../theme';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1075;
  overflow: hidden;
  outline: 0;
  opacity: 1;
  overflow-x: hidden;
  display: block;
   overflow-y: scroll;
    ::-webkit-scrollbar {
  width: 0.1em; 
}

::-webkit-scrollbar-track {
  background-color: transparent; 
}

::-webkit-scrollbar-thumb {
  background-color: transparent; 
}
`;

export const ModalDialog = styled.div`
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  position: relative;
  width: auto;
  margin: 60px auto;
  max-width: 433px;
 
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  background-color: #000033;
  background-clip: padding-box;
  outline: 0;
`;

export const ModalBackDrop = styled.div`
  opacity: 1.0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000033;
`;

export const TextCoupon = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 400px;
margin-top: 50px;
`;

export const Text = styled.span`
color: ${theme.colors.title};
font-size: 16px;
font-weight: 700;
margin-top: 40px;
`;

import React, { useEffect, useRef, useState } from 'react';
import {
  VictoryBar, VictoryLabel, VictoryGroup,
} from 'victory';
import { ChartData } from '../../ProgressAccumulatedDistance';
import theme from '../../../theme';
import ChartSkeleton from '../ChartSkeleton';
import { ChartContainer } from '../styles';
import week from '../week';

interface WeekChartProps {
  data: ChartData
  loading: boolean
}

const labelComponent = (
  <VictoryLabel
    textAnchor="middle"
    style={[
      { fontSize: 15, fill: theme.colors.grayProg },
      { fontSize: 12, fill: theme.colors.title },
    ]}
    y={180}
    lineHeight={[1, 17]}
    // @ts-ignore
    text={
      ({ datum }) => [
        `${datum.weekday}`,
        `${datum.y ? `${Math.round(datum.y)} Km` : '----'}`,
      ]
    }
  />
);

function useComponentWidth(ref: any) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect();
        setWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return width;
}

export default function WeekChart({ data, loading }: WeekChartProps) {
  const containerRef = useRef(null);
  const containerWidth = useComponentWidth(containerRef);
  const chartWidth = containerWidth * 0.80;
  const barWidthChart = (containerWidth * 0.75) / 7;

  const localeData = data.data.map((dayData, index) => ({
    ...dayData,
    weekday: week[index],
  }));

  return (
    <>
      {
        loading
          ? <ChartSkeleton />
          : (
            <>
              <ChartContainer ref={containerRef}>
                <VictoryGroup
                  width={chartWidth}
                  height={120}
                  padding={{
                    left: 20, bottom: 20, right: 20, top: 20,
                  }}
                >
                  <VictoryBar
                    style={{
                      data: {
                        stroke: 'none',
                        fill: ({ datum }) => datum.color,
                      },
                    }}
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 1000 },
                    }}
                    data={localeData}
                    barWidth={barWidthChart}
                    x="x"
                    y="y"
                    labels={({ datum }) => [datum.y, datum.weekday]}
                    labelComponent={labelComponent}
                  />
                </VictoryGroup>
              </ChartContainer>
            </>
          )
      }
    </>
  );
}

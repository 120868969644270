import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: block;
`;

export const Buttons = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: row;
`;

export const ButtonProfile = styled.div`
  width: 60%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.cardB2};
  border-top-width: 1px;
  border-top-color: ${theme.colors.iconsOff};
  border-left-width: 1px;
  border-left-color: ${theme.colors.iconsOff};  
  border-right-width: 1px;
  border-right-color: ${theme.colors.iconsOff}; 
  padding: 13px 5px;
  cursor: pointer;
`;

export const ButtonNotifications = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.cardB1};  
  border: solid 1px transparent;
  padding: 13px 5px;
  cursor: pointer;
`;

export const TextButton = styled.p`
  color: ${theme.colors.title};  
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: 3px;
`;

export const TextButtonOff = styled.p`
  color: ${theme.colors.iconsOff};  
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: 3px;
  position: relative;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

export const AvatarDefault = styled.img.attrs(() => ({
  src: 'https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/medium-bee27e393b8559be0995b6573bcfde897d6af934dac8f392a6229295290e16dd.png',
}))`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: ${theme.colors.title};
`;

export const AvatarContainer = styled.div`
  width: 100%;  
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  background-color: ${theme.colors.cardB2};
  border-top-right-radius: 10px;
`;

export const ProfileContainer = styled.div`  
  background-color: ${theme.colors.cardB2};
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 20px;  
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: block;
  width: 100%;

  background-color: ${theme.colors.primary2};
  height: 180px;

 
`;

export const LinkText = styled.a`
  text-decoration: underline;
  text-decoration-color: ${theme.colors.title};
  font-size: 13px;
  color: ${theme.colors.title};
  margin: 5px;
  cursor:pointer;
`;

export const PolicyContainer = styled.section`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const VersionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.title};
  width: 100%;
`;

export const LogoutButton = styled.button`
  text-decoration: underline;
  text-decoration-color: red;
  font-size: 13px;
  color: red;
  margin-top: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const IconNotification = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: yellow;
    position: absolute;
    right: -6px;
    top: 0px;
`;

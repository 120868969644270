import styled from 'styled-components';
import theme from '../../theme';

interface Props {
  bigger?: boolean;
}

export const Container = styled.div`  
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const Title = styled.span<Props>`
 
  color: ${theme.colors.title};
  font-size: ${(props) => (props.bigger ? 14 : 12)}px;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  @media (min-width: 768px) {
      font-size: 14px;
  }
  cursor:pointer;
`;

export const Button = styled.button`
  background-color: ${theme.colors.primary2};
  border: none;
  padding-top: 5px;
  margin-right: 0.2rem;
  cursor: pointer;
`;

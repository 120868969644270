import React from 'react';
import {
  Button,
  ButtonText,
  Container, Image, Points, Title,
} from './styles';
import { IRewardProduct } from '../../../pages/RedeemProduct/types';

export interface ProductCardProps {
  product: IRewardProduct;
  active: boolean;
  onRedeem: (product: IRewardProduct) => void;
}

export default function ProductCard({ product, onRedeem, active }: ProductCardProps) {
  return (
    <Container>
      <Image src={product.images[0].url} alt="imagem" />
      <Title>{product.name}</Title>
      <Points>
        {}
        {' '}
        {`${product.required_points_quantity} indicaç${product.required_points_quantity > 1 ? 'ões' : 'ão'}`}
      </Points>
      <Button active={active} onClick={active ? () => onRedeem(product) : undefined}>
        <ButtonText>
          Resgatar
        </ButtonText>
      </Button>
    </Container>
  );
}

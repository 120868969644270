import React from 'react';
import toast from 'react-hot-toast';
import {
  Card, Coupon, CouponButton, CouponContainer, CouponTextContainer, Description, Image, InfoContainer, LinkButton, Title, TitleContainer,
} from './styles';
import { Benefit } from '../../pages/Benefits';

interface Props {
  benefit: Benefit
}

export default function BenefitCard({ benefit }: Props) {
  const handleCopyButton = (coupon: string) => {
    navigator.clipboard.writeText(coupon);
    toast.success('Cupom copiado com sucesso!');
  };

  return (
    <Card>
      <InfoContainer>
        <Image src={benefit.image} />
        <TitleContainer>
          <Title>{benefit.title}</Title>
          <Description>{benefit.description}</Description>
        </TitleContainer>
      </InfoContainer>
      {!!benefit.coupon && (
        <CouponContainer>
          <CouponTextContainer>
            <Coupon>{benefit.coupon}</Coupon>
          </CouponTextContainer>
          <CouponButton onClick={() => handleCopyButton(benefit.coupon!)}>
            COPIAR
          </CouponButton>
        </CouponContainer>
      )}
      {!!benefit.action_url && (
        <LinkButton onClick={() => window.open(benefit.action_url, '_blank')}>
          IR PARA O SITE
        </LinkButton>
      )}
    </Card>
  );
}

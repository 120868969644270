import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MainLayout from '../components/MainLayout';
import store from '../store';
import { routed } from '../store/Auth.store';

export default function PrivateRoute({ displayNavbar = true }: any) {
  const { token } = store.getState().auth;

  const dispatch = useDispatch();

  const url = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    dispatch(routed(url));
  }, []);

  switch (window.location.hostname) {
    case 'calendario.corridaperfeita.com':
      return <Navigate to="/corridaderua" />;
    case 'calculadoras.corridaperfeita.com':
      return <Navigate to="/calculadora-de-ritmo" />;
    default:
      break;
  }

  return token ? <MainLayout displayNavbar={displayNavbar}><Outlet /></MainLayout> : <Navigate to="/login" />;
}

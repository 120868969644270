import React, { useMemo } from 'react';
import { isYesterday, isToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Activity, setCurrentEditActivity } from '../../store/Activities.store';

import { formatActivityDate, formatDuration } from '../../utils';

import {
  ActivityInfoContainer, ActivityName, Container,
  TimeText, ActivityContainer, ActivityDate, DataContainer,
} from './styles';

interface ActivityItemProps {
  activity: Activity;
}

function ActivityItem({ activity }: ActivityItemProps) {
  const displayDate = useMemo(() => {
    const parsedDate = new Date(activity?.start_date);

    if (isToday(parsedDate)) return 'Hoje';
    if (isYesterday(parsedDate)) return 'Ontem';
    return formatActivityDate(parsedDate.toString() || '');
  }, [activity]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRun = activity.type === 'Run' || activity.type === 'VirtualRun' || activity.type === 'Walk';

  return (
    <Container onClick={() => {
      dispatch(setCurrentEditActivity(activity) as any);
      navigate(
        '/activity-detail',
      );
    }}
    >
      <ActivityContainer>
        <div>
          <ActivityInfoContainer>
            <ActivityName>{activity?.title || ''}</ActivityName>
            {
              !isRun && (
                <TimeText>{formatDuration(activity?.duration as number || 0, 'time').toString()}</TimeText>
              )
            }
            {
              isRun && (
                <TimeText>{`${activity?.distance} km`}</TimeText>
              )
            }

          </ActivityInfoContainer>
        </div>

      </ActivityContainer>
      <DataContainer>
        <ActivityDate>{displayDate}</ActivityDate>
      </DataContainer>
    </Container>
  );
}

export default ActivityItem;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface FinishedI {
  finished: boolean
}

export const LectureFinishContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LectureFinishText = styled.p<FinishedI>`
  color: ${(props) => (props.finished ? theme.colors.graph : appTheme.iconsOff)};
  font-size: 10px;
  font-weight: 700;
  text-decoration-line: underline;
  margin-left: 5px;
`;

import styled from 'styled-components';
import THEME from '../../../../theme';

export const Container = styled.div<{ isToday: boolean }>`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: ${(props) => (props.isToday ? THEME.colors.cardB1 : 'transparent')};
    border-radius: 5px;

    @media (max-width: 667px) {
        padding: 5px;
    }
`;

export const DayName = styled.span<{ isToday: boolean }>`
    text-transform: uppercase;
    color: ${(props) => (props.isToday ? THEME.colors.title : THEME.colors.iconsOff)};
    text-align: left;
    font-size: ${(props) => (props.isToday ? '16' : '14')}px;
    font-weight: ${(props) => (props.isToday ? '700' : '400')};
    margin-top: 10px;

    @media (max-width: 667px) {
        font-size: ${(props) => (props.isToday ? '12' : '10')}px;
    }
`;

export const SmallDate = styled.span`
    color: ${THEME.colors.title};
    font-size: 10px;
`;

export const ContentCalendar = styled.div`    
    background-color: ${THEME.colors.cardB2};
    width: 100%;
    padding: 10px;
    border-radius: 10px;    
    margin-top: 20px;
    margin-bottom: 10px;
    ${THEME.defaultShadow}
`;

export const TrainingIconsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const FutureDayIcon = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 2px solid ${THEME.colors.iconsOff};

    @media (max-width: 667px) {
        height: 15px;
        width: 15px;
    }
`;

export const PastDayIcon = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: ${THEME.colors.iconsOff};

    @media (max-width: 667px) {
        height: 15px;
        width: 15px;
    }
`;

export const PastDayIconInnerContent = styled.p`
    color: ${THEME.colors.cardB1};
    font-size: 25px;
    text-align: center;
    line-height: 25px;

    @media (max-width: 667px) {
        font-size: 13px;
        line-height: 13px;
    }
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  width: 100%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  padding: 5px;
  margin-bottom: 10px;
`;

export const TextInfo = styled.p`
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  color: ${theme.colors.title};
  font-weight: normal;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 250px;
  padding: 10px;
  background-color: ${theme.colors.title};
  border-radius: 5px;
  cursor: pointer;
  margin: 15px auto;

`;

export const ButtonText = styled.p`
  color: ${theme.colors.cardB2};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`;

import React from 'react';
import { BsThreeDots } from 'react-icons/bs';
import {
  Table, OptionsButton,
} from './styles';
import theme from '../../theme';
import { IShoes } from '../../store/Activities.store';

interface IProps {
  shoes: IShoes[];
  setSelectedShoes: (item: IShoes) => void;
  setShowDetailsModal: (value: boolean) => void;
}

export default function ShoesTable({ shoes, setSelectedShoes, setShowDetailsModal }: IProps) {
  const handleDetailsShoes = (item: IShoes) => {
    setSelectedShoes(item);
    setShowDetailsModal(true);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Marca</th>
          <th>Modelo</th>
          <th>Cor</th>
          <th>KMs</th>
        </tr>
      </thead>

      <tbody>
        {
          shoes?.map((item) => (
            <tr key={item?.id}>
              <td>{item?.shoesBrand?.name}</td>
              <td>{item?.name}</td>
              <td>{item?.color || '-'}</td>
              <td>
                {item?.distance_total ? item.distance_total.toFixed(1) : 0}
                {' '}
                km
              </td>
              <td>
                <OptionsButton type="button" onClick={() => handleDetailsShoes(item)}>
                  <BsThreeDots color={theme.colors.title} size={20} />
                </OptionsButton>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalButton from '../ModalButton';
import {
  Container, SubText, TextContainer, Title,
} from './styles';

interface FinishClassI {
  title: string
  continueClass?: boolean
  nextModule?: () => void
}

function FinishClass({
  title,
  continueClass,
  nextModule = () => { },
}: FinishClassI) {
  const navigate = useNavigate();

  return (
    <Container>
      <TextContainer>
        <SubText>{continueClass ? 'MODULO' : 'PARABÉNS'}</SubText>
        <Title>
          {title?.toUpperCase()}
        </Title>
        <SubText>FINALIZADO</SubText>
      </TextContainer>
      {
        continueClass
          ? (
            <>
              <ModalButton title="PRÓXIMO MÓDULO" onPress={nextModule} />
              <ModalButton title="VOLTAR PARA A LISTA DE AULAS" secondary onPress={() => navigate('/club/ClassesCover')} />
            </>
          )
          : <ModalButton title="OK" onPress={() => navigate('/club/ClassesCover')} />
      }
    </Container>
  );
}

export default FinishClass;

/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CalendarCustomHeader = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    *{
        display: flex;
        align-items: center;
    }

    .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
`;

export const ActivePlanButton = styled.div`
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    width: fit-content;
    cursor: pointer;

    svg {
        height: 18px;
        width: 18px;
        margin-right: 6px;
        
        path {
            
        }
    }
`;

export const CustomNav = styled.div`
    justify-content: center;
`;

export const CustomTodayButton = styled.div`
    justify-content: flex-end;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
    
    .mbsc-material.mbsc-calendar-button.mbsc-button.mbsc-font {
        text-transform: uppercase;

        ::before {
            content: 'IR PARA ';
            white-space: pre;
        }
    }
`;

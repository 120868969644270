import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.p`
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 10px;
    color: ${appTheme.title};
    font-weight: bold;
    text-transform: uppercase;
`;

export const Description = styled.p`
    font-size: 12px;
    margin-bottom: 10px;
    color: ${appTheme.title};
`;

export const TextArea = styled.textarea`
    width: 100%;
    padding: 10px;
    background-color: ${appTheme.cardB3};
    border-radius: 5px;
    color: ${appTheme.gray};
    border: unset;
`;

export const Content = styled.div`
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

export const FieldBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import toast from 'react-hot-toast';
import api from '../../services/api';
import { RootState } from '../../store';
import { updateProfileProps } from '../../store/Auth.store';
import dateMask from '../../services/utils';
import Loader from '../Loader';
import {
  Card, CardTitle, InputLabel, Input, InputGroupContainer, InputGroup, InputContainer, InputAddon, ButtonContainer, SaveButton, SaveButtonTitle, Select, Option,
} from './style';

export default function PersonalInfo() {
  const dispatch = useDispatch();

  const profile = useSelector((state: RootState) => state.auth.user);

  const [name, setName] = useState(profile.name!);
  const [bio, setBio] = useState(profile.bio!);
  const [birth, setBirth] = useState({
    label: format(new Date(profile.birth!), 'dd/MM/yyyy'),
    value: profile.birth!,
  });
  const [genre, setGenre] = useState<any>(profile.genre!);
  const [weight, setWeight] = useState(String(profile.weight!));
  const [height, setHeight] = useState(String(profile.height!));
  const [loading, setLoading] = useState(false);

  const handleUpdateData = async () => {
    try {
      const payload = {
        name,
        bio,
        birth: birth.value,
        genre,
        weight: parseFloat(weight),
        height: parseFloat(height),
      };

      setLoading(true);

      await api.put(`/users/${profile.id}`, payload);

      setLoading(false);

      dispatch(updateProfileProps(payload) as any);

      toast('Informações alteradas com sucesso!');
    } catch (error: any) {
      setLoading(false);

      toast.error('Ocorreu um erro ao alterar suas informações, tente novamente!');
    }
  };

  return (
    <Card>
      <CardTitle>INFORMAÇÕES PESSOAIS</CardTitle>

      <InputLabel>Nome de exibição</InputLabel>
      <Input
        type="text"
        value={name && name !== 'null' ? name : ''}
        onChange={({ currentTarget: { value } }) => setName(value)}
      />

      <InputLabel>Sobre mim</InputLabel>
      <Input
        value={bio && bio !== 'null' ? bio : ''}
        onChange={({ currentTarget: { value } }) => setBio(value)}
      />

      <InputLabel>Data de nascimento</InputLabel>
      <Input
        value={birth.label ? birth.label : ''}
        onChange={
          ({ currentTarget: { value } }) => {
            setBirth({
              label: dateMask(value),
              value: `${dateMask(value).split('/')[2]}-${dateMask(value).split('/')[1]}-${dateMask(value).split('/')[0]}T03:00:00.000Z`,
            });
          }
        }
        maxLength={10}
      />

      <InputGroupContainer>
        <InputGroup style={{ maxWidth: '40%' }}>
          <InputLabel>Gênero</InputLabel>
          <Select
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
          >
            <Option>
              Masculino

            </Option>
            <Option>
              Feminino
            </Option>

          </Select>

        </InputGroup>

        <InputGroup style={{ maxWidth: '28%' }}>
          <InputLabel>Peso</InputLabel>

          <InputContainer>
            <Input
              style={{ width: '4ch', marginBottom: 0 }}
              value={weight && weight !== 'null' ? weight : ''}
              onChange={({ currentTarget: { value } }) => setWeight(value)}
              maxLength={3}
            />
            <InputAddon>kg</InputAddon>
          </InputContainer>
        </InputGroup>

        <InputGroup style={{ maxWidth: '28%' }}>
          <InputLabel>Altura</InputLabel>

          <InputContainer>
            <Input
              style={{ width: '4ch', marginBottom: 0 }}
              value={height && height !== 'null' ? height : ''}
              onChange={({ currentTarget: { value } }) => setHeight(value)}
              maxLength={3}
            />
            <InputAddon>cm</InputAddon>
          </InputContainer>
        </InputGroup>
      </InputGroupContainer>

      <ButtonContainer>
        <SaveButton onClick={handleUpdateData}>
          <SaveButtonTitle>SALVAR</SaveButtonTitle>
          {loading && <Loader />}
        </SaveButton>
      </ButtonContainer>
    </Card>
  );
}

import styled from 'styled-components';

export const Text = styled.p`
  color: white;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  padding: 8px;
`;

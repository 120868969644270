import React from 'react';
import Loader from '../Loader';
import { Button, Icon } from './styles';
import Google from '../../assets/social-icons/google.png';
import Apple from '../../assets/social-icons/apple.png';

interface Props {
  title: string;
  loading: boolean;
  type: 'apple' | 'google';
  onClick: () => void;
  disabled?: boolean;
}

export default function SocialLoginButton({
  title, loading, type, onClick, disabled = false,
}: Props) {
  const renderIcon = () => {
    switch (type) {
      case 'google':
        return Google;
      case 'apple':
        return Apple;
      default:
        return '';
    }
  };
  return (
    <Button onClick={onClick} disabled={disabled}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Icon src={renderIcon()} alt={`Logo ${type}`} />
          {title}
        </>
      )}
    </Button>
  );
}

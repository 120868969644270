import React, { ReactNode, CSSProperties } from 'react';
import { Container, Title } from './styles';

export interface IRequestTrainingButtonProps {
  disabled: boolean
  onClick: Function
  text: string
  children?: ReactNode
  style?: CSSProperties
}

function RequestTrainingButton({
  disabled, onClick, style, text, children,
}: IRequestTrainingButtonProps) {
  return (
    <Container
      disabled={disabled}
      isDisabled={disabled}
      onClick={() => onClick()}
      style={style}
    >
      <Title>{text}</Title>
      {children}
    </Container>
  );
}

export default RequestTrainingButton;

import React from 'react';
import { Helmet } from 'react-helmet';
import { MdChevronRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import PaceCalculatorDimmedIcon from '../../assets/club/pace-calculator-dimmed.svg';
import DistanceIcon from '../../assets/club/distance.svg';
import {
  Container,
  ListItemContainer,
  IconContainer,
  TextContainer,
  ItemName,
  TitleBlock,
  Title,
  Icon,
} from './styles';
import BackArrow from '../../components/BackArrow';

const ItensList = [
  {
    id: 1,
    name: 'Calculadora de pace/ritmo',
    customIcon: PaceCalculatorDimmedIcon,
    route: '/calculadora-de-ritmo',
  },
  {
    id: 2,
    name: 'Previsão de desempenho',
    customIcon: DistanceIcon,
    route: '/previsao-de-tempos',
  },
];

export default function CalculatorsList() {
  const navigate = useNavigate();

  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          data-react-helmet="true"
          content="Calculadora de pace e previsão de tempo nos 10km, 21km e 42km. Preparamos tudo isso para você corredor de rua."
        />
        <title>
          Calculadora de pace e previsão de tempo
        </title>
      </Helmet>
      <TitleBlock>
        <BackArrow text="" bigger />
        <Title>
          Calculadoras
        </Title>
      </TitleBlock>

      {ItensList.map((item) => (
        <ListItemContainer key={item.id} onClick={() => navigate(item.route)}>
          <IconContainer>
            <Icon src={item.customIcon} />
          </IconContainer>
          <TextContainer>
            <ItemName>{item.name}</ItemName>
            <MdChevronRight size={40} color="white" />
          </TextContainer>
        </ListItemContainer>
      ))}
    </Container>
  );
}

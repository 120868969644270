import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface ButtonI {
  primary?: boolean
}

export const Container = styled.div`
    position: fixed;
    cursor: initial;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${appTheme.whiteBG};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`;

export const Content = styled.div`
    max-width: 400px;
    background-color: ${theme.colors.cardB1};
    padding: 40px;
    color: ${theme.colors.title};
    text-align: center;
    z-index: 100;
    svg{
        font-size: 27px;
        margin-bottom: 5px;
    }
    p{
        margin-bottom: 1rem;
        font-size: 16px;
        font-weight: 500;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 4%;
    min-height: 140px;
    margin-bottom: 1rem;
    
`;

export const Buttom = styled.button<ButtonI>`
    display: block;
    margin: 15px;
    border-radius: 5px;
    padding: 13px 35px;
    border-width: ${(props) => (props.primary ? '0px' : '1px')};
    border-color: ${theme.colors.title};
    background: ${(props) => (props.primary ? theme.colors.graph : 'transparent')};
    color: ${theme.colors.title};
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
`;

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AvatarButton,
  Container, ContainerInfo, TextName, TextTitle, Avatar, AvatarDefault,
} from './styles';

import { RootState } from '../../store';

export default function ProgressProfile() {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const { strava_config } = useSelector((state: RootState) => state.integration);

  const bioProfile = user.bio;
  const navigate = useNavigate();

  return (
    <Container data-testid="progress-profile">
      <AvatarButton onClick={() => navigate('/profile')}>
        <>
          {
            (!!user.s_photo || !!strava_config?.photo_url)
              ? <Avatar src={user.s_photo || strava_config?.photo_url} />
              : <AvatarDefault />
          }
        </>
      </AvatarButton>
      <ContainerInfo>
        <TextName>
          {user.name}
        </TextName>
        <TextTitle>
          {
            bioProfile
            && (
              <>
                {bioProfile.length < 45
                  ? `${bioProfile}`
                  : `${bioProfile.substring(0, 42)}...`}
              </>
            )
          }

        </TextTitle>
      </ContainerInfo>
    </Container>
  );
}

import React from 'react';
import {
  ItemTitle, ModalContainer, ModalButtonsContainer, ItemSeparator, ItemInfo, ItemTextDimmed, ItemText,
} from './styles';
import { IShoesDetailsModalProps } from '../contracts';
import BaseModal from '../../BaseModal';
import { GenericButton } from '../../GenericButton/styles';
import theme from '../../../theme';

export default function ShoesDetailsModal({
  visible, setVisible, shoes, update, retire,
}: IShoesDetailsModalProps) {
  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          {shoes?.name}
        </ItemTitle>

        <ItemSeparator />
        <ItemInfo>
          <ItemTextDimmed>Marca:</ItemTextDimmed>
          <ItemText>{shoes?.shoesBrand.name}</ItemText>
        </ItemInfo>
        <ItemSeparator />
        <ItemInfo>
          <ItemTextDimmed>Modelo:</ItemTextDimmed>
          <ItemText>{shoes?.name}</ItemText>
        </ItemInfo>
        <ItemSeparator />
        <ItemInfo>
          <ItemTextDimmed>Cor:</ItemTextDimmed>
          <ItemText>{shoes?.color || '-'}</ItemText>
        </ItemInfo>
        <ItemSeparator />

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '100%',
            }}
            onClick={retire}
          >
            {shoes?.status === 'ACTIVE' ? 'APOSENTAR TÊNIS' : 'VOLTAR TÊNIS AO USO'}
          </GenericButton>

          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            onClick={update}
          >
            EDITAR TÊNIS
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

import React from 'react';
import {
  SetDistanceScreen,
  SetStartAtScreen,
  SetRecordsScreen,
  SetTargetDateScreen,
  SetDaysWeeklyWorkoutScreen,
  SetMeasurementScreen,
  SetNotesScreen,
  SetTrainingTypeScreen,
  SetKeepFitConditionScreen,
  SetSelectedWeekScreen,
} from '../CommonScreens';

import { User } from '../../../types/User';
import { ITrainingRequestScreen } from '../interface';

export interface IKeepFitProps extends ITrainingRequestScreen {
  setTrainingType: (value: string) => void;
  trainingType: string;
  handleSetDistance: (value: number) => void;
  distance: number;
  keepFitCondition: string | null;
  setKeepFitCondition: React.Dispatch<React.SetStateAction<string | null>>;
  getKmTime: (type: number) => string | null;
  getKmDate: (type: number) => string | null;
  setKmTime: (type: number, value: any) => void;
  setKmDate: (type: number, value: string) => void;
  handleSetSelectedWeek: (value: number) => void;
  selectedWeek: number;
  targetDate: Date | undefined;
  minTargetDate: Date;
  handleTargetDate: (value: string) => void;
  setDaysWeeklyWorkout: React.Dispatch<React.SetStateAction<string | null>>;
  daysWeeklyWorkout: string | null;
  height: string;
  setHeight: React.Dispatch<React.SetStateAction<string>>;
  weight: string;
  setWeight: React.Dispatch<React.SetStateAction<string>>;
  setGeneralNotes: React.Dispatch<React.SetStateAction<string | null>>;
  maxTargetDate: Date;
  profile: User;
  minWeek: Number;
  maxWeek: Number;
}

export default function KeepFit({
  selectedSchema,
  step,
  setValueForEnableNextButton,
  setTrainingType,
  trainingType,
  handleSetDistance,
  distance,
  keepFitCondition,
  setKeepFitCondition,
  getKmTime,
  getKmDate,
  setKmTime,
  setKmDate,
  handleSetSelectedWeek,
  selectedWeek,
  targetDate,
  minTargetDate,
  handleTargetDate,
  minStartAt,
  startAt,
  handleStartAt,
  setDaysWeeklyWorkout,
  daysWeeklyWorkout,
  height,
  setHeight,
  weight,
  setWeight,
  setMHistoryNotes,
  setGeneralNotes,
  maxStartAt,
  maxTargetDate,
  profile,
  minWeek,
  maxWeek,
  setButtonDisabled,
}: IKeepFitProps) {
  const commonProps = {
    step: selectedSchema.steps[step],
    setButtonDisabled,
  };

  return (
    <>
      {selectedSchema.type === 'KEEP_FIT' && step === 0
                && (
                  <SetTrainingTypeScreen
                    {...commonProps}
                    trainingType={trainingType}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => setTrainingType(value))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT' && step === 1
                && (
                  <SetDistanceScreen
                    {...commonProps}
                    distance={distance}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => handleSetDistance(value))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT' && step === 2
                && (
                  <SetKeepFitConditionScreen
                    {...commonProps}
                    keepFitCondition={keepFitCondition}
                    distance={distance}
                    onClick={
                      (text: string) => setValueForEnableNextButton(() => setKeepFitCondition(text))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT' && step === 3
                && (
                  <SetRecordsScreen
                    {...commonProps}
                    getKmDate={getKmDate}
                    getKmTime={getKmTime}
                    distance={distance}
                    onChangeDate={
                      (index: number, date: string) => {
                        setValueForEnableNextButton(() => setKmDate(index, date));
                      }
                    }
                    onChangeTime={
                      (index: number, time: string) => {
                        setValueForEnableNextButton(() => setKmTime(index, time));
                      }
                    }
                    profile={profile}
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 4
                && (
                  <SetStartAtScreen
                    {...commonProps}
                    minStartAt={minStartAt}
                    maxStartAt={maxStartAt}
                    startAt={startAt}
                    onChangeValue={
                      (value: string) => setValueForEnableNextButton(() => handleStartAt(value))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 5
                && trainingType === 'Tenho um objetivo próximo com data marcada'
                && (
                  <SetTargetDateScreen
                    {...commonProps}
                    description="Os treinos de manutenção da forma podem ser de no mínimo 4 semanas e no máximo 8 semanas."
                    targetDate={targetDate}
                    minTargetDate={minTargetDate}
                    maxTargetDate={maxTargetDate}
                    onChangeValue={
                      (text: string) => setValueForEnableNextButton(() => handleTargetDate(text))
                    }
                    minWeek={minWeek}
                    maxWeek={maxWeek}
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 5
                && trainingType === 'Quero apenas manter minha forma'
                && (
                  <SetSelectedWeekScreen
                    {...commonProps}
                    selectedWeek={selectedWeek}
                    clickCallBack={
                      (value: any) => {
                        setValueForEnableNextButton(() => handleSetSelectedWeek(value));
                      }
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 6
                && (
                  <SetDaysWeeklyWorkoutScreen
                    {...commonProps}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => setDaysWeeklyWorkout(value))
                    }
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    profile={profile}
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 7
                && (
                  <SetMeasurementScreen
                    {...commonProps}
                    height={height}
                    weight={weight}
                    onChangeHeight={
                      (text: string) => setValueForEnableNextButton(() => setHeight(text))
                    }
                    onChangeWeight={
                      (text: string) => setValueForEnableNextButton(() => setWeight(text))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 9
                && trainingType === 'Tenho um objetivo próximo com data marcada'
                && (
                  <SetMeasurementScreen
                    {...commonProps}
                    height={height}
                    weight={weight}
                    onChangeHeight={
                      (text: string) => setValueForEnableNextButton(() => setHeight(text))
                    }
                    onChangeWeight={
                      (text: string) => setValueForEnableNextButton(() => setWeight(text))
                    }
                  />
                )}

      {selectedSchema.type === 'KEEP_FIT'
                && step === 8
                && (
                  <SetNotesScreen
                    {...commonProps}
                    onChangeHistoryNotes={
                      (text: string) => setValueForEnableNextButton(() => setMHistoryNotes(text))
                    }
                    onChangeGeneralNotes={
                      (text: string) => setValueForEnableNextButton(() => setGeneralNotes(text))
                    }
                  />
                )}
    </>
  );
}

import styled from 'styled-components';

import theme from '../../theme';

export const Card = styled.section`
  display: flex;
  border-radius: 10px;
  background: ${theme.colors.cardB2};
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
`;

export const InfoContainer = styled.span`
  width: 60%;
  padding-left:15px;
  padding-top:10px;
  padding-bottom: 10px;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
  @media (max-width: 667px) {
    font-size:14px;
  }
`;

export const RaceText = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.gray};
  font-weight: 400;
  font-size: 12px;
  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const IconButton = styled.button`
  border: none;
  height: 63px;
  border-radius: 5px;
  align-items: center;
  background-color: #363661;
  cursor: pointer;
`;

export const IconButtonContainer = styled.div`
 display:flex;
 justify-content: center;
 align-items: center;
 padding-left: 15px;
 padding-right: 5px;
`;

export const TextIconButton = styled.span`
  color: ${theme.colors.title};
  font-weight: 700;
  font-size: 12px;
  text-decoration:underline;
  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

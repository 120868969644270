import { createSlice } from '@reduxjs/toolkit';

import { ITrainingGroup } from '../types/TrainingPlan';

interface IInitialState {
  exercisesList: {
    active: boolean;
    type: string;
    title: string;
    description: string;
    trainingGroups: ITrainingGroup[];
    id: number;
    cover_link: string;
    level: string;
    intensity: number;
    estimated_init: string;
    estimated_end: string;
    equipments: string;
    thumb_link: string;
    from?: string;
    start_date?: string;
    associateScheduledTraining?: boolean;
  }
}

const initialState = {
  exercisesList: {
    active: false,
    type: '',
    title: '',
    description: '',
    trainingGroups: [],
    id: 0,
    cover_link: '',
    level: '',
    intensity: 0,
    estimated_init: '',
    estimated_end: '',
    equipments: '',
    thumb_link: '',
    from: '',
    start_date: '',
    associateScheduledTraining: false,
  },
} as IInitialState;

const exercisesList = createSlice({
  name: 'exercisesList',
  initialState,
  reducers: {
    setExercisesList: (state, action) => {
      state.exercisesList = action.payload;
    },
  },
});

export const { setExercisesList } = exercisesList.actions;

export default exercisesList.reducer;

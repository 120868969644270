import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../services/api';

export enum CalendarLoadingState {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export interface ICalendarTraining {
  id: number;
  title: string;
  type: string;
  start_date: string;
  regular: boolean;
}

export interface ICalendarResult {
  start_date: string;
  trainings: ICalendarTraining[];
}

interface IInitialState {
  calendarData: {
    drills: number;
    runs: number;
    weights: number;
    result: ICalendarResult[];
  };
  loading: CalendarLoadingState;
  firstLoad: boolean;
}

const initialState: IInitialState = {
  calendarData:
    {
      drills: 0,
      runs: 0,
      weights: 0,
      result: [],
    },
  loading: CalendarLoadingState.idle,
  firstLoad: true,
};

export const getTrainingsByDateAndUser = createAsyncThunk(
  'calendarData/getTrainingsByDateAndUser',
  async ({ userId, start_date, end_date }: { userId: number; start_date: string; end_date: string }, { rejectWithValue }) => {
    try {
      const response = await api.get(`getTrainingsByDateAndUser?userId=${userId}&start_date=${start_date}&end_date=${end_date}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch trainings.');
    }
  },
);

const calendarData = createSlice({
  name: 'calendarData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingsByDateAndUser.pending, (state) => {
        state.loading = CalendarLoadingState.loading;
      })
      .addCase(getTrainingsByDateAndUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = CalendarLoadingState.succeeded;
        state.calendarData = action.payload;
        state.firstLoad = false;
      })
      .addCase(getTrainingsByDateAndUser.rejected, (state) => {
        state.loading = CalendarLoadingState.failed;
        state.firstLoad = false;
      });
  },
});

export default calendarData.reducer;

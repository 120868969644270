import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 13px;
  position: relative;
  cursor: pointer;
  &:last-of-type{
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    min-height: 184px;
  }  
  &::after{
    content: "";
    background-color: ${theme.colors.cardB3};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: 5;
    opacity: 0.3;
  }
  
`;

export const InfoContainer = styled.div`
  width: 100%;
  z-index: 6;
  h2{
    color: ${theme.colors.title};
    font-weight: 500;
    font-size: 13px;
    width: 60%;
    margin-bottom: 0px;
    @media (min-width: 768px) {
        font-size: 17px;
        margin-bottom: 0px;
    }
  }
  p{
    color: ${theme.colors.text};
    font-size: 10px;
    width: 45%;
    @media (min-width: 768px) {
        font-size: 14px;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
    @media (min-width: 768px) {        
        margin-bottom: 11px;
    }
`;

export const IconContainer = styled.div`
  padding-top: 2px;
  margin-right: 5px;
  color: ${theme.colors.title};
  font-size: 14px;
`;

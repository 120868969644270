import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import api from '../../services/api';
import { ButtonsContainer } from './styles';
import { logout } from '../../store/Auth.store';
import Modal from '../BaseModal';
import { RootState } from '../../store';
import { GenericButton } from '../GenericButton/styles';
import theme from '../../theme';
import { cancellationReasons } from '../CancellationModal';

interface IProps {
  show: boolean
  toggle: () => void
}

export default function RefundFormModal({
  show, toggle,
}: IProps) {
  const [cancellationReason, setCancellationReason] = useState('');
  const [optionPix, setOptionPix] = useState('');
  const [comments, setComments] = useState('');
  const [step, setStep] = useState<number | string>(1);
  const [valuePix, setValuePix] = useState('');
  const [loading, setLoading] = useState(false);

  const subscription = useSelector((state: RootState) => state.subscription.subscription);

  const dispatch = useDispatch();

  const resetModal = () => {
    setStep(1);
    setCancellationReason('');
    setOptionPix('');
    setComments('');
    setValuePix('');

    if (step === 'CREDIT_CARD_SUCCESS' || step === 'PIX_SUCCESS' || step === 'CREDIT_CARD_WAITING' || step === 'PIX_WAITING') {
      dispatch(logout());
    }

    toggle();
  };

  const sendPixRequest = () => {
    setLoading(true);

    api.post(
      `subscription_cancellations/refund_charge?typeOfPayment=${subscription?.payment_method.code}`,
      {
        status: 'CREATED',
        cancellation_reason: cancellationReason,
        comments,
        subscription_id: subscription?.id,
        pix_key: valuePix,
        pix_key_type: optionPix,
      },
    )
      .then((response) => {
        if (response.data.message === 'refund_success') {
          setStep('PIX_SUCCESS');
          setLoading(false);
        } else if (response.data.message === 'refund_waiting') {
          setStep('PIX_WAITING');
          setLoading(false);
        }
      })
      .catch(() => {
        setStep('PIX_FAILURE');
        setLoading(false);
      });
  };

  const sendCreditCardRequest = async () => {
    setLoading(true);

    api.post(
      `subscription_cancellations/refund_charge?typeOfPayment=${subscription?.payment_method.code}`,
      {
        status: 'CREATED',
        cancellation_reason: cancellationReason,
        comments,
        subscription_id: subscription?.id,
      },
    )
      .then((response) => {
        if (response.data.message === 'refund_success') {
          setStep('CREDIT_CARD_SUCCESS');
          setLoading(false);
        } else if (response.data.message === 'refund_waiting') {
          setStep('CREDIT_CARD_WAITING');
          setLoading(false);
        }
      })
      .catch(() => {
        setStep('CREDIT_CARD_FAILURE');
        setLoading(false);
      });
  };

  return (
    <Modal
      isVisible={show}
      setIsVisible={toggle}
      data-testid="modal"
    >
      {
        step === 1 ? (
          <div>
            <h4>Você poderia informar o motivo do seu pedido de reembolso?</h4>
            <br />
            <br />
            {
              cancellationReasons.map((reason) => (
                <div key={reason}>
                  <label htmlFor="reason">
                    <input
                      checked={cancellationReason === reason}
                      type="radio"
                      name="reason"
                      value={reason}
                      onChange={(e) => setCancellationReason(e.target.value)}
                    />
                    {' '}
                    {reason}
                  </label>
                </div>
              ))
            }

            <ButtonsContainer>
              <GenericButton
                style={{ width: '100%', marginLeft: '10px' }}
                backgroundColor={theme.colors.primary}
                borderColor={theme.colors.title}
                textColor={theme.colors.title}
                type="button"
                onClick={() => sendCreditCardRequest()}
              >
                CONTINUAR
              </GenericButton>
            </ButtonsContainer>
          </div>
        ) : step === 2 ? (
          <div>
            <h4>Gostaria de deixar algum comentário adicional sobre seu pedido de reembolso?</h4>

            <br />

            <span>A resposta é opcional.</span>

            <br />
            <br />

            <textarea
              rows={8}
              value={comments}
              style={{ width: '100%' }}
              onChange={(e) => setComments(e.target.value)}
            />

            <ButtonsContainer>
              <GenericButton
                style={{ width: '100%', marginRight: '10px' }}
                backgroundColor={theme.colors.title}
                borderColor={theme.colors.title}
                textColor={theme.colors.primary}
                type="button"
                onClick={() => setStep(1)}
              >
                VOLTAR
              </GenericButton>

              <GenericButton
                style={{ width: '100%', marginLeft: '10px' }}
                backgroundColor={theme.colors.primary}
                borderColor={theme.colors.title}
                textColor={theme.colors.title}
                type="button"
                onClick={() => {
                  if (['credit_card', 'credit_card_stone', 'credit_card_rede'].some((cc) => cc === subscription?.payment_method?.code)) {
                    setStep(3);
                  } else {
                    setStep(4);
                  }
                }}
              >
                CONTINUAR
              </GenericButton>
            </ButtonsContainer>
          </div>
        ) : step === 3 ? (
          <div>
            <h4>Podemos confirmar o pedido de reembolso?</h4>

            <br />

            <span>Essa ação não poderá ser desfeita.</span>

            <ButtonsContainer>
              <GenericButton
                style={{ width: '100%', marginRight: '10px' }}
                backgroundColor={theme.colors.title}
                borderColor={theme.colors.title}
                textColor={theme.colors.primary}
                type="button"
                onClick={() => setStep(2)}
              >
                VOLTAR
              </GenericButton>

              <GenericButton
                style={{ width: '100%', marginLeft: '10px' }}
                backgroundColor={theme.colors.primary}
                borderColor={theme.colors.title}
                textColor={theme.colors.title}
                type="button"
                onClick={sendCreditCardRequest}
              >
                {
                  loading
                    ? <Loader color="#fff" />
                    : 'CONFIRMAR'
                }
              </GenericButton>
            </ButtonsContainer>
          </div>
        ) : step === 4 ? (
          <div>
            <h4>Informe uma chave PIX de sua titularidade para recebimento do reembolso da sua garantia:</h4>

            <br />

            <div>
              <label htmlFor="cpf">
                <input
                  checked={optionPix === 'CPF'}
                  type="radio"
                  name="cpf"
                  value="CPF"
                  onChange={(e) => setOptionPix(e.target.value)}
                />
                {' '}
                CPF
              </label>
            </div>

            <div>
              <label htmlFor="email">
                <input
                  checked={optionPix === 'E-mail'}
                  type="radio"
                  name="email"
                  value="E-mail"
                  onChange={(e) => setOptionPix(e.target.value)}
                />
                {' '}
                E-mail
              </label>
            </div>

            <div>
              <label htmlFor="telefone">
                <input
                  checked={optionPix === 'Telefone'}
                  type="radio"
                  name="telefone"
                  value="Telefone"
                  onChange={(e) => setOptionPix(e.target.value)}
                />
                {' '}
                Telefone
              </label>
            </div>

            <br />

            <input
              style={{ width: '100%', height: '45px' }}
              type="text"
              value={valuePix}
              onChange={(e) => setValuePix(e.target.value)}
            />

            <br />

            {
              valuePix === ''
              && (
                <h4 style={{ color: 'red' }}>Não pode ser vazio</h4>
              )
            }

            <ButtonsContainer>
              <GenericButton
                style={{ width: '100%', marginRight: '10px' }}
                backgroundColor={theme.colors.title}
                borderColor={theme.colors.title}
                textColor={theme.colors.primary}
                type="button"
                onClick={() => setStep(2)}
              >
                VOLTAR
              </GenericButton>

              <GenericButton
                style={{ width: '100%', marginLeft: '10px' }}
                backgroundColor={theme.colors.primary}
                borderColor={theme.colors.title}
                textColor={theme.colors.title}
                type="button"
                disabled={valuePix === ''}
                onClick={sendPixRequest}
              >
                {
                  loading
                    ? <Loader color="#fff" />
                    : 'CONFIRMAR'
                }
              </GenericButton>
            </ButtonsContainer>
          </div>
        ) : step === 'CREDIT_CARD_SUCCESS' ? (
          <div>
            <h4>PEDIDO DE REEMBOLSO CONFIRMADO!</h4>

            <br />

            <h4>
              Conforme solicitado, seu cancelamento foi efetuado e o pedido de estorno do valor
              pago de
              {' '}
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(subscription?.bills[0]?.amount!)) || ''}
              {' '}
              foi enviado para seu cartão.
            </h4>

            <br />

            <h4>
              <b>Prazo:</b>
              {' '}
              O prazo estimado para o estorno constar em sua fatura varia de acordo
              com a política da entidade emissora do seu cartão. Ele costuma ocorrer de 2 a 30
              dias úteis.
              {' '}
            </h4>

            <br />

            <h4>
              <b>Importante:</b>
              {' '}
              Mesmo que tenha sido um pagamento parcelado no cartão de crédito,
              o estorno costuma ser feito pelos bancos no
              {' '}
              <b>
                valor total da compra (
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(subscription?.bills[0]?.amount!)) || ''}
                ).
              </b>
              {' '}
              Isso significa que seu banco pode manter as parcelas e devolver o valor total delas
              somado, todo de uma vez.
            </h4>

            <br />

            <h4>Essas informações também foram enviadas para seu E-mail.</h4>
          </div>
        ) : step === 'PIX_SUCCESS' ? (
          <div>
            <h4>PEDIDO DE REEMBOLSO CONFIRMADO!</h4>

            <br />

            <h4>
              Conforme solicitado, seu cancelamento foi efetuado e o pedido de estorno dos valores
              foi encaminhado para nossa equipe.
            </h4>

            <br />

            <h4>
              <b>Prazo:</b>
              {' '}
              O prazo para depósito na chave Pix informada é de até 7 (sete) dias
              úteis.
            </h4>

            <h4>Enviamos por e-mail o protocolo da sua solicitação.</h4>
          </div>
        ) : step === 'CREDIT_CARD_FAILURE' ? (
          <div>
            <h4>FALHA!</h4>

            <br />

            <h4>
              Nosso sistema não conseguiu se comunicar com o emissor do seu cartão para envio
              automático do pedido de estorno.
            </h4>

            <br />

            <h4>
              Nossa equipe já foi comunicada do fato e lhe retornará no e-mail cadastrado conosco em
              até
              {' '}
              <b>1(um) dia útil.</b>
            </h4>
          </div>
        ) : step === 'PIX_FAILURE' ? (
          <div>
            <h4>FALHA!</h4>

            <br />

            <h4>
              Nossa equipe já foi comunicada do fato e lhe retornará no e-mail cadastrado conosco em
              até
              {' '}
              <b>1(um) dia útil.</b>
            </h4>
          </div>
        ) : step === 'CREDIT_CARD_WAITING' ? (
          <div>
            <h4>PEDIDO DE REEMBOLSO EM PROCESSAMENTO!</h4>

            <br />

            <h4>
              Seu pedido de reembolso está em processamento junto à sua instituição emissora do cartão. Enviamos um protocolo para seu e-mail e assim que for processado, lhe avisaremos por lá.
            </h4>
          </div>
        ) : step === 'PIX_WAITING' ? (
          <div>
            <h4>PEDIDO DE REEMBOLSO EM PROCESSAMENTO!</h4>

            <br />

            <h4>
              Seu pedido de reembolso está em processamento. Enviamos um protocolo para seu e-mail e assim que for processado, lhe avisaremos por lá.
            </h4>
          </div>
        )
          : (
            <></>
          )
      }

      <ButtonsContainer>
        <GenericButton
          style={{ width: '100%', marginBottom: '30px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.redAlert}
          textColor={theme.colors.redAlert}
          type="button"
          onClick={resetModal}
        >
          SAIR
        </GenericButton>
      </ButtonsContainer>
    </Modal>
  );
}

import React, { useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  BtnClose,
  Button,
  ButtonText,
  ButtonsContainer,
  HeaderDescription,
  HeaderTitle,
  Container,
  Header,
  CancelModalContainer,
  ModalDescription,
} from './styles';
import BaseModal from '../../components/BaseModal';
import RedeemProductContext from './RedeemProductContext';
import SelectedProductDetails from '../../components/RedeemablePrizes/SelectProductDetails';
import theme from '../../theme';

export default function RedeemProduct() {
  const navigate = useNavigate();

  const {
    selectedProduct,
    showCancelConfirmation,
    setShowCancelConfirmation,
    stepAhead,
    stepBack,
    showRedeemSucceed,
    exitFlow,
  } = React.useContext(RedeemProductContext);

  useEffect(() => {
    if (!selectedProduct) {
      navigate('/home');
    }
  }, [selectedProduct]);

  return (
    <>
      <Container>
        <Header>
          <HeaderTitle>Solicitar resgate</HeaderTitle>
          <BtnClose onClick={() => setShowCancelConfirmation(true)}>
            <MdOutlineClose color="white" size={30} style={{ backgroundColor: theme.colors.primary }} />
          </BtnClose>
        </Header>

        <HeaderDescription>
          Confirme os detalhes do item escolhido para resgate:
        </HeaderDescription>
      </Container>

      <Container>
        <SelectedProductDetails />
      </Container>

      <ButtonsContainer>
        <Button onClick={() => stepBack()}>
          <ButtonText>Voltar</ButtonText>
        </Button>
        <Button whiteVariation onClick={() => stepAhead()}>
          <ButtonText whiteVariation>Enviar</ButtonText>
        </Button>
      </ButtonsContainer>

      <BaseModal
        isVisible={showCancelConfirmation}

      >
        <CancelModalContainer>
          <HeaderTitle>CANCELAR RESGATE</HeaderTitle>
          <ModalDescription>
            Deseja realmente cancelar o resgate?
            {' '}
            {'\n'}
            Você perderá todas as escolhas selecionadas até aqui.
          </ModalDescription>
          <ButtonsContainer>
            <Button onClick={() => setShowCancelConfirmation(false)}>
              <ButtonText>Voltar</ButtonText>
            </Button>
            <Button dangerVariation onClick={() => exitFlow()}>
              <ButtonText dangerVariation>Sim</ButtonText>
            </Button>
          </ButtonsContainer>
        </CancelModalContainer>
      </BaseModal>

      <BaseModal
        isVisible={showRedeemSucceed}

      >
        <CancelModalContainer>
          <HeaderTitle>Resgate solicitado!</HeaderTitle>
          <ModalDescription>
            Nossa equipe entrará em contato em até 2 dias úteis para dar
            andamento ao resgate do seu prêmio.
          </ModalDescription>
          <ButtonsContainer centered>
            <Button small onClick={() => exitFlow()}>
              <ButtonText>Voltar</ButtonText>
            </Button>
          </ButtonsContainer>
        </CancelModalContainer>
      </BaseModal>
    </>
  );
}

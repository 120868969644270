import React from 'react';
import { ItemTitle, ModalContainer, ModalButtonsContainer } from './styles';
import { IRemoveShoesModalProps } from '../contracts';
import BaseModal from '../../BaseModal';
import { GenericButton } from '../../GenericButton/styles';
import theme from '../../../theme';
import Loader from '../../Loader';

export default function RemoveShoesModal({
  visible, setVisible, shoes, remove, retire, removeLoading, editLoading,
}: IRemoveShoesModalProps) {
  const handleRetire = () => {
    retire({ status: shoes?.status === 'ACTIVE' ? 'RETIREE' : 'ACTIVE' });
  };

  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          {shoes?.shoesBrand?.name}
          &nbsp;-&nbsp;
          {shoes?.name}
        </ItemTitle>

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '100%',
            }}
            onClick={handleRetire}
          >
            {editLoading ? <Loader color={theme.colors.title} /> : <>{shoes?.status === 'ACTIVE' ? 'APOSENTAR' : 'VOLTAR AO USO'}</>}
          </GenericButton>

          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.redAlert}
            borderColor={theme.colors.redAlert}
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            onClick={remove}
          >
            {removeLoading ? <Loader color={theme.colors.redAlert} /> : 'APAGAR'}
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

import { format } from 'date-fns';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import DayGeneric from './components/DayGeneric';
import EventCard from './components/EventCard';
import {
  CalendarContainer, Container, DayTrainingsContainer, WeekContainer,
} from './styles';

import { RootState } from '../../../../store';
import { putSelectedDay } from '../../../../store/TrainingPlan.store';
import { ICalendarDayTraining } from '../../../../types/TrainingPlan';
import CustomHeader from '../CustomHeader';

export interface IMobileCalendarProps {
  weekDays?: ICalendarDayTraining[];
  handleOpenActivityDetailsModal: (tId: number) => void;
}

export default function MobileCalendar({ weekDays, handleOpenActivityDetailsModal }: IMobileCalendarProps) {
  const dispatch = useDispatch();

  const trainingPlanId = useSelector((state: RootState) => state.trainingPlan.planId);

  const { currentWeekDays, selectedDay: calendarDaySelected, days: calendarDays } = useSelector(
    (state: RootState) => state.trainingPlan,
  );

  const currentWeek = React.useMemo(() => {
    if (weekDays) return weekDays;

    return currentWeekDays;
  }, [weekDays, currentWeekDays]);

  const handleSelectDay = (date: string) => {
    dispatch(putSelectedDay(calendarDays?.find((day) => format(new Date(day?.date), 'yyy-MM-dd') === format(new Date(date!), 'yyy-MM-dd'))!) as any);
  };

  return (
    <Container>
      <CalendarContainer>
        <CustomHeader planRequest={trainingPlanId ? <>Plano Ativo</> : <>Você não possui um plano de treino</>} />
        <WeekContainer>
          {
            currentWeek?.map((day) => {
              const isToday = format(new Date(), 'yyyy-MM-dd') === day?.date!;

              const isSelected = day?.date === calendarDaySelected?.date;

              return (
                <DayGeneric
                  key={day?.id}
                  day={day!}
                  isToday={isToday}
                  isSelected={isSelected}
                  handleSelectDay={handleSelectDay}
                />
              );
            })
          }
        </WeekContainer>
        <DayTrainingsContainer>
          {
            calendarDaySelected?.trainingsOfDay?.map(
              (trainingOfDay) => (
                <EventCard
                  key={trainingOfDay.id}
                  trainingOfDay={trainingOfDay}
                  handleOpenActivityDetailsModal={handleOpenActivityDetailsModal}
                />
              ),
            )
          }
        </DayTrainingsContainer>
      </CalendarContainer>
    </Container>
  );
}

import React, { useState } from 'react';
import { addWeeks, format } from 'date-fns';
import toast from 'react-hot-toast';
import {
  GoalContainer, GoalTextContainer, GoalWeeksContainer, Info, WeeksSelect, Title,
  Line,
  DateCard,
  DateContainer,
  DateLabel,
  DateValue,
} from './styles';
import ModalButton from '../../components/ModalButton';
import { ChallengeStatesTypes, ChallengeType } from '.';
import coreApi from '../../services/coreApi';

interface Props {
  action: () => void
  challenge?: ChallengeType
  userId: number
  setChallenge: React.Dispatch<React.SetStateAction<ChallengeType | undefined>>
}

export default function ChallengeGoal({
  action, challenge, userId, setChallenge,
}: Props) {
  const [weeks, setWeeks] = useState(challenge ? challenge.weeks : 0);

  const started_date = challenge ? new Date(challenge.started_date) : new Date();

  const estimatedDate = weeks > 0 ? format(addWeeks(started_date, weeks), 'dd/MM/yyyy') : '-';
  const distancePerWeek = weeks > 0 ? 135 / weeks : 0;

  const buttonTitle = challenge ? 'SALVAR' : 'INICIAR';

  const createChallenge = async () => {
    const data = {
      user_id: userId,
      weeks,
      slug: 'serra-gaucha',
      status: ChallengeStatesTypes.IN_PROGRESS,
    };

    coreApi.post('challenge', data)
      .then((response) => {
        if (response.data) {
          setChallenge(response.data);
          action();
          toast.success('Desafio iniciado com sucesso!');
        }
      })
      .catch(() => {
        toast.error('Erro ao iniciar o desafio');
      });
  };

  const updateChallenge = async () => {
    coreApi.put(`challenge/${challenge?._id}`, { weeks })
      .then((response) => {
        if (response.data) {
          setChallenge(response.data);
          action();
          toast.success('Desafio atualizado com sucesso!');
        }
      })
      .catch(() => {
        toast.error('Erro ao atualizar o desafio');
      });
  };

  const handleSaveButton = async () => {
    if (weeks <= 0) {
      toast.error('Selecione o número de semanas');
      return;
    }
    if (challenge) {
      updateChallenge();
    } else {
      createChallenge();
    }
  };

  return (
    <GoalContainer>
      <Title>Defina sua meta de tempo</Title>
      <GoalTextContainer>
        <Info>
          Você tem até 24 meses para concluir seu desafio a partir da data em que se inscreveu. Você pode alterar a data de término a qualquer momento após entrar no desafio.
        </Info>
      </GoalTextContainer>
      <GoalWeeksContainer>
        <Line />
        <WeeksSelect value={weeks} onChange={(e) => setWeeks(+e.target.value)}>
          <option value={0} defaultChecked>Selecione o número de semanas</option>
          {Array.from({ length: 104 }, (_, i) => i + 1).map((week) => <option key={week} value={week}>{`${week} semana${week > 1 ? 's' : ''}`}</option>)}
        </WeeksSelect>
        <Line />
      </GoalWeeksContainer>
      <DateCard>
        <DateContainer>
          <DateLabel>Data estimada da conclusão:</DateLabel>
          <DateValue>{estimatedDate}</DateValue>
        </DateContainer>
        <DateContainer>
          <DateLabel>Distância por semana:</DateLabel>
          <DateValue>{distancePerWeek > 0 ? `${parseFloat(distancePerWeek.toFixed(2))} km` : '-'}</DateValue>
        </DateContainer>
      </DateCard>
      <ModalButton title={buttonTitle} onPress={handleSaveButton} style={{ padding: 8, marginTop: 43, marginBottom: 33 }} />
    </GoalContainer>
  );
}

import React from 'react';
import {
  ModalContentTitle, ModalContentSubTitleContainer, ModalContentSubTitle, ModalContentButtonContainer, ModalContentButton,
  ModalContentButtonText, ModalContentButtonTextCancel,
  ModalContent,
} from './style';
import {
  ModalDialog,
  ModalBackDrop,
  ModalContainer,
} from '../Modal/Modal.styles';

export default function Modal({
  isOpen, toggle, onClick, title, info, confirmText = 'Confirmar', cancelText = 'Cancelar',
} : any) {
  return (
    isOpen && (
      <>
        <ModalContainer>
          <ModalDialog>
            <ModalContent>
              <ModalContentTitle>
                {title}
              </ModalContentTitle>
              <ModalContentSubTitleContainer>

                <ModalContentSubTitle>
                  {info}
                </ModalContentSubTitle>
              </ModalContentSubTitleContainer>
              <ModalContentButtonContainer>
                <ModalContentButton color="primary" onClick={toggle}>
                  <ModalContentButtonTextCancel>{cancelText}</ModalContentButtonTextCancel>
                </ModalContentButton>
                <ModalContentButton color="red" onClick={onClick}>
                  <ModalContentButtonText>{confirmText}</ModalContentButtonText>
                </ModalContentButton>
              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

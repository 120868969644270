import React from 'react';
import { useSelector } from 'react-redux';
import { endOfMonth } from 'date-fns';
import {
  DayBox, DayContainer, DayContainerNumber, DateDay,
} from '../styles';
import { RootState } from '../../../../store';
import Run from '../../../../assets/progress/Run.svg';
import WeightTrainingun from '../../../../assets/progress/WeightTraining.svg';
import Drills from '../../../../assets/progress/Drills.svg';
import Circle from '../../../../assets/progress/circle.svg';
import { ICalendarResult, ICalendarTraining } from '../../../../store/CalendarProgress.store';

const renderDayType = (dayType: ICalendarTraining) => {
  switch (dayType?.type) {
    case 'Run':
      return <img src={Run} alt="Run-mini.svg" width={12} height={12} key={dayType?.id} />;
    case 'VirtualRun':
      return <img src={Run} alt="Run-mini.svg" width={12} height={12} key={dayType?.id} />;
    case 'Walk':
      return <img src={Run} alt="Run-mini.svg" width={12} height={12} key={dayType?.id} />;
    case 'WeightTraining':
      return <img src={WeightTrainingun} alt="WeightTraining-mini.svg" width={12} height={12} key={dayType?.id} />;
    case 'Drills':
      return <img src={Drills} alt="Drills-mini.svg" width={12} height={12} key={dayType?.id} />;
    default:
      return null;
  }
};

function Day({ date }: { date: Date }) {
  const renderedTypes: string[] = [];
  const calendarData = useSelector((state: RootState) => state.calendarData.calendarData);
  const filterData = calendarData.result.filter((dataStart: ICalendarResult) => dataStart.start_date === date?.toISOString().slice(0, 10));
  const isLastDayOfMonth = endOfMonth(date.getTime()).getDate() === date.getDate();
  return (
    <DayBox>
      <DayContainer>
        <DayContainerNumber>
          {
            (!filterData.length && isLastDayOfMonth && <DateDay>{date.getDate()}</DateDay>)
            || (!filterData.length && <img src={Circle} alt="circle.svg" width={12} height={12} />)
            || filterData.flatMap((filterDay: ICalendarResult) => filterDay.trainings.filter((training: ICalendarTraining) => {
              if (renderedTypes.includes(training.type)) {
                return false;
              }
              renderedTypes.push(training.type);
              return true;
            }).map(renderDayType))
          }
        </DayContainerNumber>
      </DayContainer>
    </DayBox>
  );
}

export default React.memo(Day);

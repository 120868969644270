import styled from 'styled-components';
import theme from '../../theme';

export const NextExercise = styled.div`
    display: flex;
    justify-content: center;
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: ${theme.colors.title};
        font-weight: 700;
        font-size: 12px;
        padding: 15px;
        background: none;
        border: solid 1px ${theme.colors.title};
        border-radius: 5px;
        cursor: pointer;
        @media (min-width: 768px) {
            padding: 19px;
            font-size: 16px;
        }
    }
`;
export const Label = styled.p`
    text-align: center;
    color: ${theme.colors.iconsOff};
`;

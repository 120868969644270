import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface EndCoverI {
  finishColor: boolean
}

export const Container = styled.div<EndCoverI>`
    background: ${(props) => (props.finishColor ? theme.colors.cardB1 : theme.colors.cardB1)};
    padding: 10px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
`;

export const ContentImage = styled.div`
    position: relative;
    margin-bottom: 35px;
    img{
        width: 100%;
        max-width: 100%;
        border-radius: 10px 10px 0px 0px;
    }
`;
export const ConainerLoader = styled.div`
    height: 300px;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.cardB2};
    border-radius: 10px 10px 0px 0px;
`;

export const TimerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;   
    text-align: center;
    h3{
        font-size: 30px;
        color: ${theme.colors.title};
        margin-bottom: 40px;
        span{
            font-size: 24px;
        }
    }
`;

export const ContainerNumber = styled.h3`
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    color: ${theme.colors.title};
    margin-bottom: 30px;
    @media (min-width: 768px) {
        font-size: 36px;
    }
`;
export const ContentButtons = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

export const ButtonPause = styled.div`
    width: 34px;
    height: 34px;
    padding: 4px;
    background-color: rgb(13, 13, 61, 0.7);
`;

export const Title = styled.div`  
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -4px;
    font-size: 14px;
    background-color:${theme.colors.cardB2};
    border-radius: 0px 0px 10px 10px;
    padding: 10px 15px;
    h3{
        color: ${theme.colors.title};
        font-weight: 900;
        font-size: 20px;
        margin-bottom: 3px;
        span{
            font-size: 12px;
            margin-left: 2px;
        }
    }
    h2{
        color: ${theme.colors.title};
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        @media (min-width: 768px) {
            font-size: 18px;
        }
    }
`;

export const Speed = styled.div`    
    
`;

export const NextExercise = styled.div`
    text-align: center;
    button{
        text-transform: uppercase;
        color: ${theme.colors.title};
        font-weight: 700;
        font-size: 12px;
        padding: 15px;
        background: none;
        border: solid 1px ${theme.colors.title};
        border-radius: 5px;
        @media (min-width: 768px) {
            padding: 19px;
            font-size: 16px;
        }
    }
`;

export const ButtomInfo = styled.button`
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;    
    display: flex;
    color: ${theme.colors.title};
    opacity: 0.8;
    background-color: ${theme.colors.cardB1};
    border: none;
    cursor: pointer;
    padding : 3px ;
    svg{
        width: 22px;
        height: 22px;
    }
`;

export const ModalManagement = styled.button`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${appTheme.whiteBG};
    cursor: pointer;
`;

export const ModalManagementContent = styled.button`
    width: 400px;
    max-width: 100%;
    padding: 40px;
    background-color: ${theme.colors.cardB1};
`;

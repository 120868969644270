import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  Container, TextNumberContainer, TextContainer, Separator, DimmedText,
} from './styles';

export default function WeekCardProgress() {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  return (

    <Container>
      <TextContainer>
        Você está há
      </TextContainer>
      <TextNumberContainer>
        {user.uninterrupted_weeks_regularity.toString().padStart(2, '0')}
      </TextNumberContainer>
      <TextContainer>
        semana
        {user.uninterrupted_weeks_regularity !== 1 && 's'}
        {' '}
        seguida
        {user.uninterrupted_weeks_regularity !== 1 && 's'}
        {' '}
        treinando!
      </TextContainer>
      {(user.is_breaking_record && (
        <>
          <Separator />
          <DimmedText>
            Este é o seu recorde de semanas!
          </DimmedText>
        </>
      )) || (user.week_record > 0 && (
        <>
          <Separator />
          <DimmedText>
            {`Seu recorde é de ${user.week_record} semana${user.week_record > 1 ? 's' : ''}`}
          </DimmedText>
        </>
      ))}
    </Container>

  );
}

import React from 'react';
import BackArrow from '../../../components/BackArrow';
import {
  Container, Item, ItemDesc, ItemsContainer, ItemTitle, ItemTitleUnderline,
} from './styles';

export default function TecnicalSuport() {
  return (
    <Container>
      <BackArrow text="SUPORTE TÉCNICO" bigger backTo="/help" />
      <ItemsContainer>
        <Item onClick={() => window.open('mailto:suporte@corridaperfeita.com')}>
          <ItemTitle>
            Para reportar problemas técnicos ao nosso time de tecnologia, escreva para o e-mail
            <ItemTitleUnderline> suporte@corridaperfeita.com</ItemTitleUnderline>
            .
          </ItemTitle>
          <ItemDesc>
            Respondemos em até 1 dia útil
          </ItemDesc>
        </Item>
      </ItemsContainer>
    </Container>
  );
}

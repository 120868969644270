import React from 'react';
import { Button, ButtonText, ButtonsContainer } from './styles';

interface SizeButtonsProps {
  sizesString: string;
  selectedSize: string | undefined;
  setSelectedSize: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function SizeButtons({ sizesString, selectedSize, setSelectedSize } : SizeButtonsProps) {
  const handleSizes = React.useCallback(() => {
    const sizesArray = sizesString.split(',');
    const sizes = sizesArray.map((size) => {
      const sizeObject = {
        label: size,
        value: size,
      };
      return sizeObject;
    });
    return sizes;
  }, [sizesString]);

  const handleSizeSelection = (sizeValue: string) => {
    setSelectedSize(sizeValue);
  };

  return (
    <ButtonsContainer>
      {handleSizes().map((size, index) => (
        <Button
          key={index}
          onClick={() => handleSizeSelection(size.value)}
          style={{ backgroundColor: selectedSize === size.value ? '#FFFFFF' : 'transparent' }}
        >
          <ButtonText style={{ color: selectedSize === size.value ? '#0D0D3DFC' : '#FFFFFF' }}>
            {size.label}
          </ButtonText>
        </Button>
      ))}

    </ButtonsContainer>
  );
}

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export const TimerContainer = styled.div`
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    min-height: 50px;
`;

export const TimerLabel = styled.h2`
    color: ${theme.colors.title};
    font-weight: bold;
    font-size: 30px;
    margin-left: 5px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 78%;
  border-radius: 10px;
  background: ${theme.colors.cardB1};
  overflow: hidden;
`;

export const Header = styled.div`
  height: 10%;
  width: 100%;
  margin: 0px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; 
`;

interface fastImageProps {
  imageUrl: string;
}

export const FastImage = styled.div<fastImageProps>`  
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(to bottom, rgba(16, 9, 93, 0.59), rgba(47, 47, 88, 0.7)),
    url(${(props) => (props.imageUrl)});
`;

export const CongratsContainer = styled.div`
  width: 70%;
  padding: 6%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9;
`;

export const CongratsTitle = styled.h3`
  color: ${theme.colors.title};
  margin-bottom: 4%;
  font-weight: 600;
  font-size: 14px;
`;

export const TrophyContainer = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  svg{
    width: 24px;
    height: 24px;
    stroke: yellow;
    path{
        stroke: yellow;
        fill: yellow;
    }
  }
`;

export const TrophyText = styled.h3`
  margin-left: 10px;
  color: ${theme.colors.notifications};
  font-weight: bold;
  font-size: 18px;
`;

export const ExerciseTitle = styled.h3`
  color: ${theme.colors.title};
  margin-bottom: 2%;
  font-weight: 600;
  font-size: 14px;
`;

export const Scroll = styled.div`
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  p{
    color: ${theme.colors.title};
    span{
        margin-right: 5px;
        color: ${appTheme.iconsOff};
    }
  }  
`;

export const CongratsAnimationContainer = styled.div`
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

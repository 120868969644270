import styled from 'styled-components';
import theme from '../../theme';
import { ModalContent as _ModalContent } from '../Modal/Modal.styles';

export const ModalContent = styled(_ModalContent)`
  padding: 1rem;
`;

export const ModalContentButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const ModalContentButton = styled.button`
  min-width: 40%;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  border-color: ${theme.colors.primary};
  background-color: ${theme.colors.title};
  border-width: 1.5px;
  border-style: solid;
  margin: 8px;
  cursor: pointer;
`;

export const ModalContentButtonText = styled.span`
  color: ${theme.colors.primary};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const Header = styled.span`
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
`;

export const InfoText = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 13px;
  margin-bottom: 10px;
`;

export const TextInfo = styled.button`  
  display: flex;
  align-items: center;
  border:none;
  background-color:${theme.colors.primary} ;
`;

export const TextHelp = styled.span`  
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-top: 15px;
  cursor: pointer;
`;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api, { api2 } from '../services/api';
import store from '.';

// Types
export type IRunData = {
  pace: number | undefined;
  speed: number | undefined;
  time: number | undefined;
};

type IUserRunData = {
  '1KmTime': number | undefined;
  '5KmTime': number | undefined;
};

export type IUserPredictedData = {
  '10': IRunData;
  '15': IRunData;
  '16': IRunData;
  '21': IRunData;
  '42': IRunData;
};

type IPredictionState = {
  userRunData: IUserRunData;
  userPredictedData: IUserPredictedData;
  apiError: string | undefined;
  loading: boolean;
  estimated1Km: boolean;
};

type RootState = {
  prediction: IPredictionState;
};

type MyThunkAPI = {
  dispatch: typeof store.dispatch;
  getState: () => RootState;
};

// Helpers
function truncateDecimal(value: number): string {
  return value.toString().split('.')[0].padStart(2, '0');
}

function checkTimeValues(seconds: number) {
  return seconds !== 0;
}

function secondsToTimeString(
  totalSeconds: number,
  aggregateToMinutes: boolean = false,
): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (aggregateToMinutes) {
    const minutesString = truncateDecimal(minutes + hours * 60);
    const secondsString = truncateDecimal(seconds);

    return `${minutesString}:${secondsString}`;
  }

  const hoursString = truncateDecimal(hours);
  const minutesString = truncateDecimal(minutes);
  const secondsString = truncateDecimal(seconds);

  return `${hoursString}:${minutesString}:${secondsString}`;
}

function calculate1KmTime(time5kmSeconds: number): number {
  const TIME_DEDUCTION_FACTOR = 0.18;
  const meanTimePerKm = time5kmSeconds / 5;
  return meanTimePerKm - meanTimePerKm * TIME_DEDUCTION_FACTOR;
}

function stringToTime(str: string): number {
  const parts = str.split(':').map(Number);

  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts;
    return hours * 3600 + minutes * 60 + seconds;
  }
  if (parts.length === 2) {
    const [totalMinutes, seconds] = parts;
    return totalMinutes * 60 + seconds;
  }
  throw new Error('Formato inválido. Deve ser hh:mm:ss ou mm:ss.');
}

function parseApiPredictData(input: Record<string, any>): IUserPredictedData {
  const emptyRunData: IRunData = {
    pace: undefined,
    speed: undefined,
    time: undefined,
  };

  const output: IUserPredictedData = {
    10: { ...emptyRunData },
    15: { ...emptyRunData },
    16: { ...emptyRunData },
    21: { ...emptyRunData },
    42: { ...emptyRunData },
  };

  Object.keys(input).forEach((key) => {
    output[key as keyof IUserPredictedData].pace = stringToTime(
      input[key].pace,
    );
    output[key as keyof IUserPredictedData].speed = parseFloat(
      input[key].velocidade,
    );
    output[key as keyof IUserPredictedData].time = stringToTime(
      input[key].tempo,
    );
  });

  return output;
}

function postConversionData() {
  try {
    api.post('conversion_app', { conversion: 'predicao-pace' });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Erro ao enviar dados de conversão RD Station.');
  }
}

async function sendDataForPrediction(userData: IUserRunData) {
  try {
    const response = await api2.get('predict-pace', {
      params: {
        pace1Km: secondsToTimeString(userData['1KmTime'] as number, true),
        tempo5km: secondsToTimeString(userData['5KmTime'] as number, true),
      },
    });

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return console.error('Erro ao enviar dados de conversão RD Station.');
  }
}

// Thunks
export const calculateAndSendData = createAsyncThunk<any, void, MyThunkAPI>(
  'prediction/calculateAndSendData',
  async (_, { getState, dispatch, rejectWithValue }) => {
    postConversionData();

    const state = getState() as RootState;
    const predictionState = state.prediction;

    if (
      !predictionState.userRunData['5KmTime']
      || !checkTimeValues(predictionState.userRunData['5KmTime'])
    ) {
      return rejectWithValue(
        'Para calcular você deve preencher o tempo de 5km.',
      );
    }

    if (predictionState.userRunData['5KmTime'] < 600) {
      return rejectWithValue(
        'O tempo preenchido para os 5km foi muito pequeno.',
      );
    }

    if (!predictionState.userRunData['1KmTime']) {
      try {
        const calculatedTime = calculate1KmTime(
          predictionState.userRunData['5KmTime'] as number,
        );
        await dispatch({
          type: 'prediction/setUser1KmTime',
          payload: calculatedTime,
        });
        await dispatch({ type: 'prediction/setEstimated1Km', payload: true });
      } catch (error) {
        return rejectWithValue(
          'Houve algum problema ao estimar o tempo de 1Km.',
        );
      }
    }

    try {
      const newState = getState() as RootState;
      const newPredictionState = newState.prediction;
      const predictionData = await sendDataForPrediction(
        newPredictionState.userRunData,
      );
      return predictionData;
    } catch (error) {
      return rejectWithValue(
        'Houve algum problema ao tentar calcular. Tente novamente por favor.',
      );
    }
  },
);

// Constants
const initialState: IPredictionState = {
  userRunData: {
    '1KmTime': undefined,
    '5KmTime': undefined,
  },
  userPredictedData: {
    10: {
      pace: undefined,
      speed: undefined,
      time: undefined,
    },
    15: {
      pace: undefined,
      speed: undefined,
      time: undefined,
    },
    16: {
      pace: undefined,
      speed: undefined,
      time: undefined,
    },
    21: {
      pace: undefined,
      speed: undefined,
      time: undefined,
    },
    42: {
      pace: undefined,
      speed: undefined,
      time: undefined,
    },
  },
  apiError: undefined,
  loading: false,
  estimated1Km: false,
};

const predictionSlice = createSlice({
  name: 'prediction',
  initialState,
  reducers: {
    clearData: (state) => {
      Object.assign(state, initialState);
    },
    setUser1KmTime: (state, action: PayloadAction<number>) => {
      state.userRunData['1KmTime'] = action.payload;
    },
    setUser5KmTime: (state, action: PayloadAction<number>) => {
      state.userRunData['5KmTime'] = action.payload;
    },
    setEstimated1Km: (state, action: PayloadAction<boolean>) => {
      state.estimated1Km = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateAndSendData.pending, (state) => {
        state.loading = true;
        state.apiError = undefined;
      })
      .addCase(calculateAndSendData.fulfilled, (state, action) => {
        state.loading = false;
        state.userPredictedData = parseApiPredictData(action.payload);
      })
      .addCase(calculateAndSendData.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload as string;
      });
  },
});

export const { setUser1KmTime, setUser5KmTime, clearData } = predictionSlice.actions;

export default predictionSlice.reducer;

import styled from 'styled-components';

interface BackgroundProps {
  bgColor: string;
  size: number;
  further?: boolean;
}

const Background = styled.div<BackgroundProps>`
  background-color: ${(props: BackgroundProps) => props.bgColor};
  width: ${(props: BackgroundProps) => props.size}px;
  height: ${(props: BackgroundProps) => props.size}px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.further ? 0 : 5)}px;

  svg {
    height: 100%;
    width: auto;
  }

  @media (max-width: 667px) {
    width: 15px;
    height: 15px;
    padding: ${(props) => (props.further ? 0 : 2)}px;
  }
`;

export default Background;

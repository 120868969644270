import styled from 'styled-components';
import theme from '../../theme';

export const Content = styled.div`   
   overflow-x: auto;
   scroll-behavior: smooth;
   align-items: stretch;
   padding: 3px;
   display: inline-flex;
   width: 100%;
  
   &::-webkit-scrollbar {
    display: none;
   }
`;

export const ArrowsContainer = styled.div`
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    width: 80px;
    height: 80px;
    align-items: center;
    color: #0d0d3d;
    background:${theme.colors.arrow};
    box-shadow: 0px 0px 5px 0px black;
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 667px) {
        width: 35px;
        height: 40px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export const ArrowLeft = styled(Arrow)`
    left: -40px;
    padding-left: 30px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 99;

    @media (max-width: 667px) {
        left: -15px;
        padding-left: 10px;
    }
`;

export const ArrowRight = styled(Arrow)`
    right: -40px;
    padding-right: 30px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    justify-content: center;
    z-index: 99;

    @media (max-width: 667px) {
        right: -15px;
        padding-right: 10px;
    }
`;

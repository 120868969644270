import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html {
        background: ${theme.colors.primary2};
    }

    body {
        font-family: 'Lato', sans-serif;
        background: ${theme.colors.background};
        -webkit-font-smoothing: antialiased;
    }
`;

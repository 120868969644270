import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background: ${theme.colors.cardB1};
  flex: 1;
  padding: 3%;
`;

export const TextContainer = styled.div`
  padding: 8px;
`;

export const TextInfo = styled.p`
  color: ${theme.colors.title};
  margin-bottom: 16px;
  text-align: justify;
`;

export const CouponContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.cardB2};
  width: 90%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const Text = styled.p`
  color: white;
`;

import React, { useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setCurrentClass } from '../../store/Classes.store';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';

import List from './List';
import {
  InfoButton, InfoButtonText, MetricButton, MetricButtonsContainer, MetricButtonText,
  NewZoneButton,
  NewZoneButtonText,
  PersonalContainer,
  PersonalTextInfo,
  UpdateZoneButton,
  UpdateZoneText,
  ZoneListContainer,
  ListFooter,
} from './styles';
import FreeUserCard from '../FreeUserCard';

export interface IZone {
  id: number;
  name: string;
  description: string;
  order: number;
  start_pace: string | null;
  end_pace: string | null;
  start_bpm: number | null;
  end_bpm: number | null;
  user_zone_id: number;
  video: string;
  created_at: string;
  updated_at: string;
}

enum filterType {
  min_km,
  bpm
}

export interface IZoneListProps {
  _isFree?: boolean;
  _isPersonal?: boolean;
  _zones?: IZone[];
}

function ZoneList({ _isFree, _isPersonal, _zones }: IZoneListProps) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [filterBy, setFilterBy] = useState<filterType>(filterType.min_km);

  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const isFree = _isFree === undefined ? user.isFree : _isFree;
  const isPersonal = _isPersonal === undefined ? user.subscription_type === 'PERSONAL' : _isPersonal;

  const zones = _zones === undefined ? useSelector((state: RootState) => state.auth.user.userZone?.zones) : _zones;

  const classes = useSelector((state: RootState) => state.classes.ClassList);

  const goToClass = () => {
    const item = classes.find((cl) => cl.name.includes('Zonas de Treinamento'));

    if (item) {
      dispatch(setCurrentClass(item));

      navigate('/club/ClassesCover', { state: { item } });
    }
  };

  return (
    <>
      {
        !isPersonal && !isFree && (
          <ZoneListContainer>
            <InfoButton onClick={goToClass}>
              <MdInfo size={22} color="#FFFFFF" />
              <InfoButtonText> Entenda as zonas de treino</InfoButtonText>
            </InfoButton>
            <MetricButtonsContainer>
              <MetricButton
                active={filterBy === filterType.min_km}
                onClick={() => setFilterBy(filterType.min_km)}
              >
                <MetricButtonText active={filterBy === filterType.min_km}>MIN/KM</MetricButtonText>
              </MetricButton>
              <MetricButton
                active={filterBy === filterType.bpm}
                onClick={() => setFilterBy(filterType.bpm)}
              >
                <MetricButtonText active={filterBy === filterType.bpm}>BPM</MetricButtonText>
              </MetricButton>
            </MetricButtonsContainer>
          </ZoneListContainer>
        )
      }
      {
        !isPersonal && !isFree && (
          <>
            {
              zones
                ? <List zones={zones} pace={filterBy === filterType.min_km} />
                : <List pace={filterBy === filterType.min_km} />
            }

            <ListFooter>
              {
                zones ? (
                  <UpdateZoneButton onClick={() => navigate('/zones', { replace: false })}>
                    <UpdateZoneText>Atualizar zonas</UpdateZoneText>
                  </UpdateZoneButton>
                )
                  : (
                    <NewZoneButton onClick={() => navigate('/howtozones')}>
                      <NewZoneButtonText>Registrar zonas por ritmo/bpm</NewZoneButtonText>
                    </NewZoneButton>
                  )
              }
            </ListFooter>
          </>
        )
      }

      {
        isPersonal
        && (
          <PersonalContainer>
            <PersonalTextInfo>
              Suas zonas de treino são configuradas no App TrainingPeaks.
              Em caso de dúvidas, fale com seu treinador.
            </PersonalTextInfo>
          </PersonalContainer>
        )
      }

      {
        isFree && (
          <FreeUserCard
            text="As zonas de treino de corrida estão disponíveis apenas para membros do Corrida Perfeita"
            link={freeUserRedirectLinks.zones}
          />
        )
      }
    </>
  );
}

export default ZoneList;

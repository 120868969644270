import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackArrow from '../BackArrow';
import {
  Button, ButtonText, Content, Container, TextContainer, TextInfo,
} from './style';

export default function RacingDiscount() {
  const navigate = useNavigate();
  return (
    <Container data-testid="discount-container">
      <BackArrow text="DESCONTOS EM PROVAS" bigger backTo="/club" />
      <Content>
        <TextContainer>
          <TextInfo>
            Temos descontos em corridas de todo o Brasil!  Clique no botão abaixo e fique de olho, pois estamos sempre atualizando essa lista, para beneficiar nossos atletas.
          </TextInfo>
        </TextContainer>
        <Button onClick={() => navigate('/corridaderua/-brasil/coupon')}>
          <ButtonText>Acessar descontos</ButtonText>
        </Button>
      </Content>
    </Container>
  );
}

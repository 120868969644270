import React from 'react';
import { FooterContainer, FooterText } from './styles';

export interface IFloatingFooterProps {
  text: string;
  clickCallback?: () => void;
}

function FloatingFooter({ text, clickCallback }: IFloatingFooterProps) {
  return (
    <FooterContainer onClick={clickCallback}>
      <FooterText>{text}</FooterText>
    </FooterContainer>
  );
}

export default FloatingFooter;

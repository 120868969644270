import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div<{color: string, image: string}>`
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 10px;
  background: ${({ color }) => color || '#2A6AC0'} url(${({ image }) => image});
  background-repeat: no-repeat, no-repeat;
  background-position: right, left; 
  background-size: cover;
  flex-direction: row;
  margin-bottom: 8px;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 13px 0 13px 13px;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 40%;
`;

export const Title = styled.p<{color: string}>`
  color: ${({ color }) => color || 'white'};
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 20px;
`;

export const Text = styled.p<{color: string}>`
  color: ${({ color }) => color || 'white'};
  font-size: 16px;
  margin-bottom: 16px;
`;

export const Button = styled.div<{color: string}>`
  display: flex;
  padding: 8px;
  border-radius: 5px;
  background-color: ${({ color }) => color || 'white'};
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonText = styled.p<{color: string}>`
  color: ${({ color }) => color || theme.colors.background};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CloseButton = styled.div`
  position: absolute;
  padding: 5px;
  top: 0;
  right: 0;
  cursor: pointer;
`;

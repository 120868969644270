import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  ItemTitle, ModalContainer, ModalButtonsContainer, ItemDescription, ItemImage, DismissButton,
} from './styles';
import BaseModal from '../BaseModal';
import { GenericButton } from '../GenericButton/styles';
import theme from '../../theme';
import titleImage from '../../assets/attach-shoes/icon1.png';
import RegisterShoes from '../RegisterShoes';
import { RootState } from '../../store';
import { IShoes } from '../../store/Activities.store';
import api2 from '../../services/coreApi';
import SelectShoes from '../SelectShoes';

interface IAttachShoesModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleDismiss: () => void;
  handleAttachShoes: (shoesId: number | string) => void;
}

export default function AttachShoesModal({
  visible, setVisible, handleDismiss, handleAttachShoes,
}: IAttachShoesModalProps) {
  const [step, setStep] = useState(1);
  const [shoes, setShoes] = useState<IShoes[]>([]);
  const [selectedShoesId, setSelectedShoesId] = useState<number | string | null>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleSaveShoesCallback = (shoesId: number) => {
    handleAttachShoes(shoesId);

    setStep((prev) => prev + 1);
  };

  const handleAttachShoesCallback = () => {
    if (!selectedShoesId) {
      toast.error('Selecione um tênis');
      return;
    }

    handleAttachShoes(selectedShoesId);

    if (selectedShoesId === 'barefoot') {
      setVisible(false);
    } else {
      setStep((prev) => prev + 2);
    }
  };

  const loadData = () => {
    api2.get('shoes')
      .then((response) => {
        setShoes(response.data.data);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar seus tênis');
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={(value: boolean) => {
        setVisible(value);
        setStep(1);
      }}
    >
      <ModalContainer>
        {
          step === 1
          && (
            <>
              <ItemImage src={titleImage} />

              <ItemTitle>
                COM QUAL TÊNIS VOCÊ CORREU?
              </ItemTitle>

              <ItemDescription>
                {
                  shoes && shoes.length > 0
                    ? 'Vincule o tênis de corrida que você usou para contabilizar os kms rodados.'
                    : 'Cadastre seu tênis de corrida e vincule aos treinos para contabilizar quantos kms rodados.'
                }
              </ItemDescription>

              {
                shoes && shoes.length > 0
                && <SelectShoes shoes={shoes} cb={setSelectedShoesId} />
              }

              <ModalButtonsContainer>
                <GenericButton
                  backgroundColor={!shoes || shoes.length === 0 || selectedShoesId ? theme.colors.title : theme.colors.cardB3}
                  textColor={theme.colors.primary}
                  borderColor={!shoes || shoes.length === 0 || selectedShoesId ? theme.colors.title : theme.colors.cardB3}
                  disabled={shoes && shoes.length > 0 && !selectedShoesId}
                  style={{
                    width: '50%',
                    marginBottom: '10px',
                  }}
                  onClick={shoes && shoes.length > 0 ? () => handleAttachShoesCallback() : () => setStep((prev) => prev + 1)}
                >
                  {shoes && shoes.length > 0 ? 'VINCULAR' : 'CADASTRAR'}
                </GenericButton>

                <GenericButton
                  backgroundColor={theme.colors.primary}
                  textColor={theme.colors.title}
                  borderColor={theme.colors.title}
                  style={{
                    width: '50%',
                  }}
                  onClick={shoes && shoes.length > 0 ? () => setStep((prev) => prev + 1) : () => setVisible(false)}
                >
                  {shoes && shoes.length > 0 ? 'CADASTRAR NOVO' : 'MAIS TARDE'}
                </GenericButton>
              </ModalButtonsContainer>

              {
                !!user?.ask_about_activity_shoes
                && (
                  <DismissButton
                    type="button"
                    onClick={handleDismiss}
                  >
                    Não mostrar esta mensagem novamente
                  </DismissButton>
                )
              }
            </>
          )
        }

        {
          step === 2
          && (
            <>
              <RegisterShoes cb={handleSaveShoesCallback} />

              <DismissButton
                type="button"
                onClick={() => setStep((prev) => prev - 1)}
              >
                Voltar
              </DismissButton>
            </>
          )
        }

        {
          step === 3
          && (
            <>
              <ItemImage src={titleImage} />

              <ItemTitle>
                TÊNIS&nbsp;
                {selectedShoesId ? 'VINCULADO!' : 'CADASTRADO!'}
              </ItemTitle>

              <ModalButtonsContainer>
                <GenericButton
                  backgroundColor={theme.colors.title}
                  textColor={theme.colors.primary}
                  borderColor={theme.colors.title}
                  style={{
                    width: '50%',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setVisible(false);
                    setStep(1);
                  }}
                >
                  OK
                </GenericButton>
              </ModalButtonsContainer>
            </>
          )
        }
      </ModalContainer>
    </BaseModal>
  );
}

import React from 'react';

import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import { AiOutlineBell } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import {
  Buttons,
  ButtonNotifications,
  ButtonProfile,
  Container,
  TextButton,
  TextButtonOff,
  Content,
  IconNotification,
} from './styles';

import CardNotifications from './CardNotification';
import theme from '../../../theme';
import { RootState } from '../../../store';

function Notifications() {
  const notificationIcon = useSelector((state: RootState) => state.notifications.notViewed);

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <Container>
      <Buttons>
        <ButtonProfile onClick={handleProfileClick}>
          <FaCog color={theme.colors.iconsOff} size={15} />
          <TextButtonOff>PERFIL E CONFIGURAÇÕES</TextButtonOff>
        </ButtonProfile>
        <ButtonNotifications>
          <AiOutlineBell color={theme.colors.title} size={15} />
          <TextButton>
            Notificações
            {notificationIcon >= 1 && <IconNotification />}
          </TextButton>
        </ButtonNotifications>
      </Buttons>
      <Content>
        <CardNotifications />
      </Content>
    </Container>
  );
}

export default Notifications;

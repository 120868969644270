import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../../BaseModal';
import { IProps } from './contracts';
import {
  Delimiter,
  ModalBackground,
  ModalContent,
  ModalContentBody,
  ModalContentButton,
  ModalContentButtonContainer,
  ModalContentButtonText,
  ModalContentChild,
  ModalContentHeader,
  ModalContentSubTitle,
  ModalContentSubTitleContainer,
  ModalContentTitle,
  TrainingPlanRequestButton,
  TrainingPlanRequestButtonText,
  TrainingPlanRequestContainer,
  TrainingPlanRequestContainerDescription,
  TrainingPlanRequestContainerTitle,
  TrainingPlanRequestDetails,
  TrainingPlanRequestDetailsItem,
  TrainingPlanRequestDetailsItemDelimiter,
  TrainingPlanRequestDetailsItemPrimaryText,
  TrainingPlanRequestDetailsItemSecondaryText,
} from './styles';
import api from '../../../services/api';
import { RootState } from '../../../store';
import { formatRequestKeepFitType, formatTrainingPlanRequestObjective, objectExists } from '../../../utils';
import { appTheme } from '../../../theme';
import {
  newPlanRequest,
  trainingPlanRequest as trainingPlanRequestAction,
} from '../../../store/TrainingPlanRequests.store';
import Loader from '../../Loader';

enum Statuses {
  CREATED = 'CREATED',
  OPEN = 'OPEN',
  PENDING = 'PENDING'
}

enum RequestTypes {
  KEEP_FIT = 'KEEP_FIT',
  SLIMMING = 'SLIMMING',
  RETURN_INITIATION = 'RETURN_INITIATION',
  ALTERNATIVE = 'ALTERNATIVE'
}

function ActiveTrainingPlanModal({
  showModal, setShowModal, trainingPlanName, trainingPlanEndDate,
  hasTrainingPlan = false,
}: IProps) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);

  const trainingPlanRequest = useSelector(
    (state: RootState) => state.trainingPlanRequests.trainingPlanRequest,
  );

  const [loading, setLoading] = useState(true);
  const [confirmCancelMode, setConfirmCancelMode] = useState(false);

  useEffect(() => {
    if (showModal) {
      api.get(`get_user_requests?userId=${auth?.user?.id}`)
        .then((response) => {
          if (response?.data?.data?.length) {
            const lastRequest = response.data.data[0];

            if (Statuses[lastRequest?.status as Statuses]) {
              dispatch(trainingPlanRequestAction({
                id: lastRequest?.id,
                title: lastRequest?.title,
                createdAt: lastRequest?.created_at,
                type: lastRequest?.type,
                trainingType: lastRequest?.trainingType,
                distance: lastRequest?.distance,
                distanceHistory: lastRequest?.distance_history,
                status: lastRequest?.status,
              }) as any);
            } else {
              dispatch(trainingPlanRequestAction(null) as any);
            }
          } else {
            dispatch(trainingPlanRequestAction(null) as any);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [showModal]);

  const clearRequest = () => {
    dispatch(trainingPlanRequestAction(null) as any);
  };

  const handleRequestNewTrainingPlan = () => {
    setShowModal(false);

    dispatch(newPlanRequest(true) as any);
    navigate('/request');
  };

  function cancelRequest() {
    api.put(`/training_plan_requests/${trainingPlanRequest?.id}`, { status: 'CANCELED' })
      .then(() => {
        setShowModal(false);
        setConfirmCancelMode(false);
        clearRequest();
        toast('Solicitação de treino cancelada com sucesso!');
      })
      .catch(() => {
        toast.error('Tivemos um problema no cancelamento, tente mais tarde');
        setShowModal(false);
        setConfirmCancelMode(false);
      });
  }

  return (
    <BaseModal
      isVisible={showModal}
    >
      <Toaster />
      <ModalBackground>
        <ModalContent>
          {
            !objectExists(trainingPlanRequest)
            && (
              <ModalContentHeader>
                <button type="button" onClick={() => setShowModal(false)}>
                  <MdClear size={20} color="#fff" />
                </button>
              </ModalContentHeader>
            )
          }

          <ModalContentChild>
            {
              loading
              && <Loader color="white" />
            }

            {
              !loading
              && (
                <>
                  {
                    hasTrainingPlan
                    && (
                      <>
                        <ModalContentBody>
                          <ModalContentTitle>
                            {
                              objectExists(trainingPlanRequest)
                                ? <>PLANO DE TREINO ATIVO</>
                                : <>PLANO DE TREINO INATIVO</>
                            }

                          </ModalContentTitle>

                          <ModalContentSubTitleContainer>
                            <ModalContentSubTitle>{trainingPlanName || 'n/a'}</ModalContentSubTitle>
                            <ModalContentSubTitle>
                              {trainingPlanEndDate ? `Termina em: ${format(parseISO(trainingPlanEndDate), 'dd/MM/yyyy')}` : 'n/a'}
                            </ModalContentSubTitle>
                          </ModalContentSubTitleContainer>
                        </ModalContentBody>
                        <ModalContentButtonContainer>
                          {objectExists(trainingPlanRequest)
                            ? (
                              <ModalContentButton buttonType="back" onClick={() => setShowModal(false)}>
                                <ModalContentButtonText buttonType="back">VOLTAR</ModalContentButtonText>
                              </ModalContentButton>
                            )
                            : (
                              <ModalContentButton buttonType="request" onClick={handleRequestNewTrainingPlan}>
                                <ModalContentButtonText buttonType="request">SOLICITAR NOVO PLANO</ModalContentButtonText>
                              </ModalContentButton>
                            )}
                        </ModalContentButtonContainer>
                      </>
                    )
                  }

                  {
                    objectExists(trainingPlanRequest)
                    && (
                      <>
                        {
                          hasTrainingPlan
                          && <Delimiter />
                        }

                        <TrainingPlanRequestContainer>
                          {(!confirmCancelMode && (
                            <>
                              <TrainingPlanRequestContainerTitle>
                                {
                                  trainingPlanRequest?.type === RequestTypes.ALTERNATIVE
                                    ? 'VOCÊ POSSUI UMA SOLICITAÇÃO DE PLANO DE TREINO ALTERNATIVO EM ABERTO.'
                                    : 'VOCÊ POSSUI UMA SOLICITAÇÃO DE PLANO DE TREINO DE CORRIDA EM ABERTO.'
                                }
                              </TrainingPlanRequestContainerTitle>

                              <TrainingPlanRequestDetails>
                                <TrainingPlanRequestDetailsItem>
                                  <TrainingPlanRequestDetailsItemPrimaryText>
                                    Plano de treino solicitado
                                  </TrainingPlanRequestDetailsItemPrimaryText>
                                  <TrainingPlanRequestDetailsItemSecondaryText>
                                    {
                                      trainingPlanRequest?.type === RequestTypes.KEEP_FIT
                                        ? formatRequestKeepFitType(trainingPlanRequest?.trainingType!)
                                        : formatTrainingPlanRequestObjective(
                                          trainingPlanRequest?.distanceHistory!,
                                        )
                                    }

                                    {!!trainingPlanRequest?.distance && ` ${trainingPlanRequest?.distance}km`}

                                    {
                                      trainingPlanRequest?.type === RequestTypes.SLIMMING
                                    || trainingPlanRequest?.type === RequestTypes.RETURN_INITIATION
                                    || trainingPlanRequest?.type === RequestTypes.ALTERNATIVE
                                        ? trainingPlanRequest?.title
                                        : ''
                                    }
                                  </TrainingPlanRequestDetailsItemSecondaryText>
                                </TrainingPlanRequestDetailsItem>
                                <TrainingPlanRequestDetailsItemDelimiter />
                                <TrainingPlanRequestDetailsItem>
                                  <TrainingPlanRequestDetailsItemPrimaryText>
                                    Data da solicitação
                                  </TrainingPlanRequestDetailsItemPrimaryText>
                                  <TrainingPlanRequestDetailsItemSecondaryText>
                                    {
                                      trainingPlanRequest?.createdAt
                                        ? format(parseISO(trainingPlanRequest?.createdAt), 'dd/MM/yyyy')
                                        : 'n/a'
                                    }
                                  </TrainingPlanRequestDetailsItemSecondaryText>
                                </TrainingPlanRequestDetailsItem>
                                <TrainingPlanRequestDetailsItemDelimiter />
                                <TrainingPlanRequestDetailsItem>
                                  <TrainingPlanRequestDetailsItemPrimaryText>
                                    Prazo para retorno
                                  </TrainingPlanRequestDetailsItemPrimaryText>
                                  <TrainingPlanRequestDetailsItemSecondaryText>
                                    2 dias úteis
                                  </TrainingPlanRequestDetailsItemSecondaryText>
                                </TrainingPlanRequestDetailsItem>
                              </TrainingPlanRequestDetails>

                              {
                                trainingPlanRequest?.status === Statuses.CREATED
                                  ? (
                                    <TrainingPlanRequestContainerDescription>
                                      Enquanto um de nossos treinadores não iniciar a
                                      preparação do seu treino,
                                      você pode cancelar a solicitação e fazer outra, caso desejar.
                                    </TrainingPlanRequestContainerDescription>
                                  )
                                  : (
                                    <TrainingPlanRequestContainerDescription>
                                      Seu pedido já está em atendimento por um treinador.
                                      Caso necessário, entre
                                      em contato conosco pela opção ajuda em seu perfil.
                                    </TrainingPlanRequestContainerDescription>
                                  )
                              }

                              {
                                !hasTrainingPlan
                            && (
                              <TrainingPlanRequestButton color="white" onClick={() => setShowModal(false)}>
                                <TrainingPlanRequestButtonText color="white">
                                  VOLTAR
                                </TrainingPlanRequestButtonText>
                              </TrainingPlanRequestButton>
                            )
                              }

                              {
                                trainingPlanRequest?.status === Statuses.CREATED
                            && (
                              <TrainingPlanRequestButton
                                color={appTheme.redAlert}
                                onClick={() => setConfirmCancelMode(true)}
                              >
                                <TrainingPlanRequestButtonText color={appTheme.redAlert}>
                                  CANCELAR E FAZER NOVA SOLICITAÇÃO
                                </TrainingPlanRequestButtonText>
                              </TrainingPlanRequestButton>
                            )
                              }
                            </>
                          ))
                          || (
                            <>
                              <TrainingPlanRequestContainerTitle>
                                Deseja cancelar sua solicitação de plano de treino?
                              </TrainingPlanRequestContainerTitle>

                              <TrainingPlanRequestContainerDescription>
                                Sua solicitação será cancelada e você poderá solicitar outro.
                              </TrainingPlanRequestContainerDescription>

                              <TrainingPlanRequestButton color="white" onClick={() => setConfirmCancelMode(false)}>
                                <TrainingPlanRequestButtonText color="white">
                                  VOLTAR
                                </TrainingPlanRequestButtonText>
                              </TrainingPlanRequestButton>

                              <TrainingPlanRequestButton
                                color={appTheme.redAlert}
                                onClick={() => cancelRequest()}
                              >
                                <TrainingPlanRequestButtonText color={appTheme.redAlert}>
                                  SIM CANCELAR
                                </TrainingPlanRequestButtonText>
                              </TrainingPlanRequestButton>
                            </>
                          )}

                        </TrainingPlanRequestContainer>
                      </>
                    )
                  }
                </>
              )
            }
          </ModalContentChild>
        </ModalContent>
      </ModalBackground>
    </BaseModal>
  );
}

export default ActiveTrainingPlanModal;

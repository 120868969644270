import styled from 'styled-components';
import THEME from '../../theme';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 15px;
  padding: 0 10px;
`;

export const Title = styled.span`
     color: ${THEME.colors.title};
     text-transform: uppercase;
     font-family: 'Lato';
     font-style: normal;
     font-size: 15px;

     @media (max-width: 667px) {
      font-size: 12px;
    }
`;

export const DateDay = styled.span`
    color: ${THEME.colors.title};
    text-decoration: underline;
    font-size: 13px;
`;

export const ContainerCards = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;  
`;

export const Card = styled.div`
    display: flex;
    min-width: 30%;
    height: 180px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${THEME.colors.cardB2};
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);

    @media (max-width: 667px) {
        min-width: 80px;
        height: 130px;
    }
`;

export const CardNumber = styled.span`    
    font-weight: 900;
    font-size: 35px;
    line-height: 42px;
    color: ${THEME.colors.title};
    margin-top: 5px;
    @media (min-width: 667px) {
         font-size: 45px;
         margin-top: 15px;
         margin-bottom:15px;
    }
`;

export const CardNumberDrills = styled.span`    
    font-weight: 900;
    font-size: 35px;
    line-height: 42px;
    color: ${THEME.colors.title};
     @media (min-width: 667px) {
         font-size: 45px;
          margin-top: 15px;
          margin-bottom:15px;
    }
`;

export const CardTitle = styled.span`    
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${THEME.colors.title};
    text-align: center;
     @media (min-width: 667px) {
         font-size: 13px;
    }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const ContentCalendar = styled.div`
  background-color: ${THEME.colors.cardB2};
  width: 100%;
  padding: 20px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #1b63f2;
  z-index: -100;
  box-shadow: ${THEME.defaultShadow};
`;

export const SwitchButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
`;

export const OuterSwitchContainer = styled.div`
  width: 45%;
`;

export const SwitchCurrentCalendarButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
  border-radius: 10px;
  background-color: ${THEME.colors.cardB2};
  border: 1px solid
    ${(props: any) => (props.active ? '#1B63F2' : 'transparent')};
  box-shadow: ${THEME.defaultShadow};
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
`;

export const SwitchCurrentCalendarButtonText = styled.span<{ active: boolean }>`
  color: ${(props) => (props.active ? THEME.colors.title : THEME.colors.titleCard)};
  font-size: 13px;
  margin-left: 5px;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const ContainerSVG = styled.div`
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

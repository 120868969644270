import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  position: relative;
  background-color: ${theme.colors.cardB2};
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 25px;

 @media (max-width: 667px) {
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
  }
`;

export const Header = styled.header`
  user-select: none;
  color: ${theme.colors.title};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;

  @media (max-width: 667px) {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const LoadingStyle = styled.div`
  margin: auto;
  padding: 2rem;
  align-items: center;
`;

export const ErrorStyle = styled.div`
  margin: auto;
  font-size: medium;
  color: red;
  padding: 2rem;
  text-align: center;
`;

export const EventsNone = styled.section`
  padding: 8px;
  align-items: center;
  background-color: #2f2f58;
  border-radius: 10px;
  width: 33%;
  min-width: 200px;
  font-family: "Roboto", sans-serif;
  justify-content: space-around;
`;

export const EventsNoneContent = styled.header`
  text-align: left;
  color: #c3c3c3;
  font-size: 0.8rem;
`;

export const EventsRecorded = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 667px) {
    margin-top: 10px;
  }
`;

export const EventsButton = styled.button`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${theme.colors.title};
  border-style: solid;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 667px) {
    height: 30px;
  }
`;

export const EventsButtonText = styled.p`
  font-size: 12px;
  color: ${theme.colors.title};

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

import React from 'react';
import { AiFillLock } from 'react-icons/ai';
import {
  Button, ButtonLink, ButtonLinkText, ButtonText, Container, IconContainer, Text,
} from './style';
import theme from '../../theme';
import {
  ModalContainer, ModalContent, ModalBackDrop, ModalDialog,
} from '../Modal/Modal.styles';

interface FreeUserModalProps {
  isVisible?: boolean;
  isModalOpen: boolean;
  link: string;
  text: string;
  toggle: () => void;

}

export default function FreeUserModal({
  isVisible, isModalOpen, text, link, toggle,
}: FreeUserModalProps) {
  const content = () => (
    <Container transparent={!isModalOpen}>
      <IconContainer>
        <AiFillLock size={25} color={theme.colors.title} />
      </IconContainer>
      <Text>{text}</Text>
      <Button onClick={() => window.open(link)}>
        <ButtonText>Quero fazer parte</ButtonText>
      </Button>

      <ButtonLink onClick={toggle}>
        <ButtonLinkText>Voltar</ButtonLinkText>
      </ButtonLink>

    </Container>
  );

  return isModalOpen ? (
    <>
      {isVisible && (
        <>
          <ModalContainer>
            <ModalDialog>
              <ModalContent>
                {content()}
              </ModalContent>
            </ModalDialog>
          </ModalContainer>
          <ModalBackDrop onClick={toggle} />
        </>

      )}
    </>
  ) : <>{content()}</>;
}

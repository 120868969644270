import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: flex;
  background: ${theme.colors.cardB1};
  flex-direction: column;
  padding-bottom: 0px;
`;

export const VideoContainer = styled.section`
  
  display:flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  background-color: ${theme.colors.cardB2};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  
`;

export const TitleContainer = styled.section`
  background-color: ${theme.colors.cardB2};
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Title = styled.span`
  color: ${theme.colors.title};
  font-size: 15px;
  font-weight: 500;
`;

export const RegisterButton = styled.button`
  margin-top: 20px;
  background-color: ${theme.colors.cardB1};
  border-radius: 5px;
  width: 40%;
  align-items: center;
  border: '1px';
  border-style: solid;
  border-color: ${theme.colors.title};
  align-self :center;
  padding: 10px;
 
`;

export const RegisterButtonText = styled.span`
  text-transform: uppercase;
  color: ${theme.colors.title};
  font-weight: 500;
  font-size: 15px;
`;

export const FavoriteButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.cardB2};
  border: none;
`;

interface FavoriteTextProps {
  active?: boolean;
}

export const FavoriteText = styled.span<FavoriteTextProps>`
  color: ${(props) => (props.active ? theme.colors.title : theme.colors.text)};
  text-decoration: underline;
  margin-left: 3px;
`;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import {
  getCoros, getGarmin, getPolar, getStrava,
} from '../../store/Integration.store';
import IntegrationContent from '../IntegrationContent';
import Loader from '../Loader';
import {
  Card, CardSubTitle, CardTitle, Paragraph,
} from './style';
import IntegrationConfirmModal from '../IntegrationConfirmModal';
import { otherIntegrations, partnerIntegrations } from './integrations';
import { PROFILE_FIELD } from '../ProfileCard';
import theme from '../../theme';

export default function Integration() {
  const dispatch = useDispatch<any>();

  const user = useSelector((state: RootState) => state.auth.user);

  const {
    coros_config, strava_config, garmin_config, loading, polar_config,
  } = useSelector((state: RootState) => state.integration);

  const [showRemoveIntegrationModal, setShowRemoveIntegrationModal] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = useState<typeof partnerIntegrations[0]>();

  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getGarmin());
    dispatch(getStrava());
    dispatch(getCoros());
    dispatch(getPolar());
  }, []);

  const getEnabled = (provider: string) => {
    if (provider === 'garmin' && garmin_config?.id) return true;
    if (provider === 'strava' && strava_config?.id && !strava_config.provider_through) return true;
    if (provider === 'coros' && coros_config?.id) return true;
    if (provider === 'polar' && polar_config?.id) return true;
    if (provider === strava_config?.provider_through) return true;
    if (provider === 'garmin' && user?.garmin_access_token) return true;
    if (provider === 'strava' && user?.s_id && !user.provider_through_strava) return true;
    if (provider === user?.provider_through_strava) return true;
    return false;
  };

  const toggle = (integration: typeof partnerIntegrations[0]) => {
    setSelectedIntegration(integration);

    if (getEnabled(integration.type)) {
      setShowRemoveIntegrationModal(true);
    } else {
      navigation(`?field=${PROFILE_FIELD.DETALHES_INTEGRACAO}&provider=${integration.type}`);
    }
  };

  return (
    <>
      {loading && <Loader key={Math.random()} color={theme.colors.title} />}

      <br />

      <Card>
        <CardTitle>INTEGRAÇÕES PARCEIRAS</CardTitle>

        {
          partnerIntegrations.map((integration) => (
            <IntegrationContent
              {...integration}
              key={integration.id}
              enabled={getEnabled(integration.type)}
              toggle={() => toggle(integration)}
            />
          ))
        }
      </Card>

      <Card>
        <CardTitle>OUTRAS INTEGRAÇÕES</CardTitle>

        <CardSubTitle>
          Selecione o fabricante na lista abaixo para conectar o seu dispositivo à sua conta no Corrida Perfeita, através do parceiro Strava.
        </CardSubTitle>

        {
          otherIntegrations.map((integration) => (
            <IntegrationContent
              {...integration}
              key={integration.id}
              enabled={getEnabled(integration.type)}
              toggle={() => toggle(integration)}
              hideDescription
            />
          ))
        }
      </Card>

      <IntegrationConfirmModal
        isOpen={showRemoveIntegrationModal}
        confirm={() => {
          setShowRemoveIntegrationModal(false);
          selectedIntegration?.removeIntegration?.();
        }}
        cancel={() => setShowRemoveIntegrationModal(false)}
      >
        <Paragraph>Deseja remover esta integração?</Paragraph>
      </IntegrationConfirmModal>
    </>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

interface ICommonProps {
  elevatedLevel?: boolean;
}

export const Logo = styled.img`
  width: 95px;
  height: 45px;
  margin: 20px;
`;

export const BadgeContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const BadgeContent = styled.div<ICommonProps>`
  position: absolute;
  top: 14%;
  left: ${(props) => (props.elevatedLevel ? '25%' : '28%')};

  @media (max-width: 667px) {
    top: 14%;
    left: ${(props) => (props.elevatedLevel ? '33%' : '32%')};
  }
`;

export const BadgeGreeting = styled.p`
  color: ${theme.colors.secondary};
  width: 75%;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 8px;
  @media (max-width: 667px) {
    width: 55%;
    font-size: 8px;
  }
`;

export const BadgeTitle = styled.p<ICommonProps>`
  color: ${(props) => (props.elevatedLevel ? theme.colors.secondary : theme.colors.title)};
  width: 73%;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;

  @media (max-width: 667px) {
    width: 55%;
    font-size: 8px;
  }
`;

export const WeeksTrainingText = styled.p`
  width: 100%;
  text-align: center;
  color: ${theme.colors.secondary};
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const BadgeDescription = styled.p`
  color: ${theme.colors.title};
  width: 75%;
  text-align: center;
  font-size: 15px;
  margin: 5px 0 20px;
  @media (max-width: 667px) {
    width: 80%;
    font-size: 12px;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
`;

export const ShareButton = styled.button`
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.cardB1};
  font-weight: bold;
  width: 100%;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 15px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const CloseButton = styled.button`
  margin-top: 10px;
  color: ${theme.colors.background};
  text-transform: uppercase;
  font-weight: 700;
  color: ${theme.colors.title};
  font-size: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const CloseButtonText = styled.p`
  color: ${theme.colors.title};
  font-size: 10px;
`;

export const ViewShot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.cardB1};
  padding-bottom: 100px;
`;

export const Badge = styled.img`
  width: 115px;
  height: 115px;
  @media (max-width: 667px) {
      width: 65px;
      height: 65px;
    }
`;

export const Splash = styled.img`
  width: 260px;
  height: 260px;
  @media (max-width: 667px) {
      width: 175px;
      height: 175px;
    }
`;

export const TextNumberContainer = styled.p`
    font-size: 70px;
    font-weight: 900;
    color: ${theme.colors.secondary};
    line-height: 34px;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 25px;

    @media (max-width: 667px) {
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: 8px;
    }
`;

import React, { Dispatch, SetStateAction } from 'react';
import { BiCamera } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import { RootState } from '../../store';
import { refreshUser } from '../../store/Auth.store';
import theme from '../../theme';
import {
  Card, InfoContainer, Header, CardInfo, ImageSelectionArea,
} from './style';

interface IProps {
  header: string;
  info: string;
  setUpdatePictureLoading: Dispatch<SetStateAction<boolean>>;
}

export default function ProfilePictureCard({ header, info, setUpdatePictureLoading }: IProps) {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const handleUpdateProfilePicture = async (event: any) => {
    if (event.target.files.length) {
      const file = event.target.files[0];

      const photoPayload = new FormData();

      photoPayload.append('file', file);

      setUpdatePictureLoading(true);

      await api.post(`/files/${user.id}/cloud`, photoPayload);

      setUpdatePictureLoading(false);

      dispatch(refreshUser(user.id) as any);
    }
  };

  return (
    <Card data-testid="card-container">
      <InfoContainer>
        <Header>{header}</Header>

        <CardInfo>{info}</CardInfo>
      </InfoContainer>
      <ImageSelectionArea>
        <label htmlFor="avatar">
          <BiCamera size={30} color={theme.colors.title} />
          <input
            data-testid="image-selection"
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleUpdateProfilePicture}
          />
        </label>
      </ImageSelectionArea>
    </Card>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

interface Props {
  bigger?: boolean
}

export const Container = styled.section`
 
 width: 100%;

`;

export const BackArrow = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    cursor: pointer;

`;

export const Title = styled.span<Props>`
    color: ${theme.colors.title};
    font-size: ${(props) => (props.bigger ? 14 : 12)}px;
    font-weight: bold;
    font-style: normal;    
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: 14px;
    }
`;

export const Button = styled.button`
background-color: ${theme.colors.primary2};
border: none;
padding-top:5px;
margin-right: .2rem;
cursor: pointer;
`;

export const Text = styled.h5`
width: 100%;
padding-left: 10px;
padding-top: 15px;
color: ${theme.colors.title};
font-weight: normal;
font-weight: 500;

`;

export const NoticeChannel = styled.section`
 
 margin-top: 25px;
 width: 100%;
  background-color: ${theme.colors.cardB2};
  border-radius: 5px;
 min-height: 100px;
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  

  h5 {
    color: ${theme.colors.title};
    font-weight: bold;
    font-size: 14px;
    padding-top: 10px;
    text-transform: uppercase;
  }

  p {
    font-weight: normal;
    color: ${theme.colors.title};
    font-size: 14px ;
    padding: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    align-content: center;
  }


 
`;

export const GroupButton = styled.button`
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 250px;
 
 padding: 10px;
    background-color: ${theme.colors.title};
    border-radius: 5px;
    
     cursor: pointer;

    

`;
export const ButtonTitle = styled.span`
color: ${theme.colors.cardB2};
text-transform: uppercase;
font-size: 14px;
    font-weight: bold;

`;

export const GroupContainer = styled.section`
 margin-top: 25px;
 width: 100%;
 margin-bottom: 20px;
  background-color: ${theme.colors.cardB2};
  border-radius: 5px;
 min-height: 200px;
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  p {
    font-weight: bold;
    color: ${theme.colors.title};
    font-size: 14px ;
    
   
  }
`;

export const HeaderGroups = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  h5 {
    color: ${theme.colors.title};
    font-weight: bold;
    font-size: 14px;
    padding-top: 10px;
    text-transform: uppercase;
  }
`;

export const FormatGroupLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start ;
  padding: 0 4px 4px 0;
  font-size: 14px;
  color: white;
`;

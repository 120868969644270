import styled from 'styled-components';
import THEME from '../../../../theme';

// eslint-disable-next-line import/prefer-default-export
export const MessageText = styled.div`
  color: ${THEME.colors.title};
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 20px 20px;
`;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export interface barLevelProps {
  level?: string;
  color?: string;
}

export const Container = styled.div`
  background: ${theme.colors.primary2};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;

  @media (max-width: 667px) {
    margin-bottom: 25px;
  }
`;

export const BadgeOuterContainer = styled.div<{dimmed?: boolean}>`
  opacity: ${(props) => (props.dimmed ? 0.3 : 1)};
  position: relative;
`;

export const BadgeInnerContainer = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  position: relative;
`;

export const BadgeTitle = styled.p<{active: boolean}>`
  color: ${(props) => (props.active ? theme.colors.title : appTheme.dimmedTitle)};
  text-align: center;
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: 700;
  font-size: ${(props) => (props.active ? 11 : 10)}px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);

  @media (max-width: 667px) {
    font-size: ${(props) => (props.active ? 9 : 8)}px;
  }
`;

export const Badge = styled.img<{bigger?: boolean}>`
  width: ${(props) => (props.bigger ? 100 : 75)}px;
  height: ${(props) => (props.bigger ? 100 : 75)}px;
  @media (max-width: 667px) {
      width: ${(props) => (props.bigger ? 70 : 45)}px;
      height: ${(props) => (props.bigger ? 70 : 45)}px;
    }
`;

export const BadgeInnerWeekValue = styled.p`
  color: ${appTheme.dimmedTitle};
  font-size: 45px;
  font-weight: 700;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);

  @media (max-width: 667px) {
    font-size: 20px;
  }
`;

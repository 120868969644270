import styled from 'styled-components';
import theme from '../../theme';

interface ContainerProps {
  transparent: boolean;
}

export const Modal = styled.section<ContainerProps>`
background-color: ${({ transparent }) => (transparent ? 'transparent' : theme.colors.title)};
align-items: center;
padding: 10px;
display: flex;
flex-direction: column;
border-radius: 5px ;
`;

export const Card = styled.div`
  background-color: white;
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${theme.colors.title};
  padding: 10px;
`;

export const HeaderText = styled.span`
  color: ${theme.colors.gray}; 
  font-weight: 400;
  font-size: 16px;
`;

export const MessageText = styled.span`
  color: black;
  font-weight: 300;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const TextButton = styled.span`
  color: ${theme.colors.primary};
  font-weight: 500;
  font-size: 15px;
`;

export const Button = styled.button`
  display: flex;
  height: 35px;
  width: 50px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
`;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import {
  Container, Content, Title, Input, Text, ErrorMessage, Card,
} from './styles';
import RaceCalendarCard from '../../components/RaceCalendarCard';
import Loader from '../../components/Loader';
import { RootState } from '../../store';
import { loadStateRaceCounts } from '../../store/RaceCalendar.store';
import RaceCalendarContext from './Context/RaceCalendarContext';
import coreApi from '../../services/coreApi';
import { AdBanner } from './types';

export const stateNames: Record<string, string> = {
  Brasil: ' Brasil',
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

function searchRecord(record: Record<string, number>, searchString: string): Record<string, number> {
  const normalizedSearchString = searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

  const result: Record<string, number> = {};

  Object.keys(record).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      const normalizedKey = key.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      let keyMatches = normalizedKey.includes(normalizedSearchString);

      if (searchString.length === 2) {
        const stateAcronym = Object.keys(stateNames).find((state) => stateNames[state] === key);
        if (stateAcronym?.toLowerCase() === normalizedSearchString) {
          keyMatches = true;
        }
      }

      if (keyMatches) {
        result[key] = record[key];
      }
    }
  });

  return result;
}

export default function RaceCalendar() {
  const dispatch = useDispatch();

  const { loading: calendarStoreLoading, error: calendarStoreError, stateCounts } = useSelector((state: RootState) => state.raceCalendar);
  const { token } = useSelector((state: RootState) => state.auth);

  const { ResetFilters } = React.useContext(RaceCalendarContext).actions;

  const [stateFilterInputValue, setStateFilterInputValue] = React.useState('');

  const [adBanner, setAdbanner] = useState<AdBanner>();

  React.useEffect(() => {
    if (Object.keys(stateCounts).length === 0) dispatch(loadStateRaceCounts({ start_date: format(new Date(), 'yyyy-MM-dd'), skip: 0 }) as any);
    ResetFilters();
  }, []);

  const racesWithStateNames = React.useMemo(() => {
    const result: Record<string, number> = {};

    Object.keys(stateCounts).forEach((state) => {
      result[stateNames[state]] = stateCounts[state];
    });

    return result;
  }, [stateCounts]);

  const filteredStateList = React.useMemo(() => {
    if (stateFilterInputValue === '') return racesWithStateNames;
    return searchRecord(racesWithStateNames, stateFilterInputValue);
  }, [stateFilterInputValue, racesWithStateNames]);

  React.useEffect(() => {
    let url = 'advertising/type/home';

    if (token) url += '/auth';

    coreApi.get(url)
      .then((response) => {
        setAdbanner(response.data);
      });
  }, []);

  return (
    <Container>
      <Helmet>
        <meta name="description" data-react-helmet="true" content="Encontre o calendário completo de corridas de rua em todo o Brasil no Calendário Corrida Perfeita. Planeje seu próximo desafio, acompanhe datas e locais das corridas e inscreva-se para participar. Tudo que você precisa para sua próxima corrida está aqui. Confira agora!" />
        <title>Calendário Corrida de Rua</title>
      </Helmet>
      <Content>
        <Title>
          Calendário de provas de corrida de rua
        </Title>
        <Text>
          Aqui você encontra as melhores provas de corrida de rua do Brasil. Provas de 5K / 10K / 21K / Maratonas e mais.
        </Text>
        {adBanner && <a href={adBanner.action_url}><Card alt={adBanner.title} src={adBanner.image} /></a>}
        <Text>
          Digite seu estado:
        </Text>
        <Input
          type="text"
          value={stateFilterInputValue}
          onChange={(e) => setStateFilterInputValue(e.target.value)}
        />
        <Text>
          Ou selecione na lista abaixo:
        </Text>
      </Content>
      <>
        {(calendarStoreError && <ErrorMessage>Houve um erro. Por favor, tente atualizar a página.</ErrorMessage>)

          || (calendarStoreLoading && <Loader color="white" />)

          || (Object.keys(filteredStateList).length === 0 && <ErrorMessage>Não há corridas cadastradas para este estado.</ErrorMessage>)

          || Object.keys(filteredStateList).sort((a, b) => a.localeCompare(b)).map((state) => (
            <RaceCalendarCard
              key={state}
              title={state}
              numberOfRaces={filteredStateList[state]}
            />
          ))}
      </>
    </Container>
  );
}

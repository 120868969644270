import React from 'react';
import moment from 'moment';
import { BsDot } from 'react-icons/bs';
import {
  Card, InfoContainer, Title, DateText, PointsContainer, Text, InfoContent, CancelText, CancelTitle, DateCancelText, StatusText, StatusContent,
} from './styles';
import { Statement } from '../../pages/PointsDetail';

export default function PointsDetailCard({
  item: {
    referralUser, created_at, points, referralRequest, statement_type,
  }, type,
}: { item: Statement; type: 'EXTRACT' | 'PENDING' | 'CANCELED' }) {
  const formattedDate = moment(created_at).format('DD/MM/YYYY');
  return (
    <Card>
      {type === 'CANCELED' ? (
        <>
          <InfoContainer>
            <CancelTitle>{referralUser?.name}</CancelTitle>
            <DateCancelText>{formattedDate}</DateCancelText>
          </InfoContainer>
          <PointsContainer>
            <CancelText>
              {points}
              {points === 1 ? ' indicação' : ' indicações'}
            </CancelText>
          </PointsContainer>
        </>
      ) : type === 'PENDING' ? (
        <>
          <InfoContainer>
            <Title>{referralUser?.name}</Title>
            <DateText>{formattedDate}</DateText>
          </InfoContainer>
          <PointsContainer>
            <Text>
              {points}
              {points === 1 ? ' indicação' : ' indicações'}
            </Text>
          </PointsContainer>
        </>
      ) : type === 'EXTRACT' ? (
        <>
          {referralRequest ? (
            <>
              {statement_type === 'REFUND' ? (
                <>
                  <InfoContainer>
                    <Title>
                      Estorno de pedido.
                    </Title>
                    <InfoContent>
                      <DateText>{formattedDate}</DateText>
                    </InfoContent>
                  </InfoContainer>
                  <PointsContainer>
                    <Text>
                      {points}
                      {points === 1 ? ' indicação' : ' indicações'}
                    </Text>
                  </PointsContainer>
                </>
              ) : referralRequest.status === 'PENDING' ? (
                <>
                  <InfoContainer>
                    <Title>
                      {`${referralRequest.items[0].quantity}x ${referralRequest.items[0].referral_reward_name} ${
                        referralRequest.items[0].gender ? referralRequest.items[0].gender + (referralRequest.items[0].size ? ' - ' : '') : ''
                      }${referralRequest.items[0].size ? referralRequest.items[0].size : ''}`}
                    </Title>
                    <InfoContent>
                      <DateText>{formattedDate}</DateText>
                      <StatusContent>
                        <BsDot size={18} style={{ color: '#FFC700' }} />
                        <StatusText>Solicitado</StatusText>
                      </StatusContent>
                    </InfoContent>
                  </InfoContainer>
                  <PointsContainer>
                    <Text>
                      -
                      {points}
                      {points === 1 ? ' indicação' : ' indicações'}
                    </Text>
                  </PointsContainer>
                </>
              ) : referralRequest.status === 'FINISHED' ? (
                <>
                  <InfoContainer>
                    <Title>
                      {`${referralRequest.items[0].quantity}x ${referralRequest.items[0].referral_reward_name} ${
                        referralRequest.items[0].gender ? referralRequest.items[0].gender + (referralRequest.items[0].size ? ' - ' : '') : ''
                      }${referralRequest.items[0].size ? referralRequest.items[0].size : ''}`}
                    </Title>
                    <InfoContent>
                      <DateText>{formattedDate}</DateText>
                      <StatusContent>
                        <BsDot size={18} style={{ color: '#009329 ' }} />
                        <StatusText>Resgatado</StatusText>
                      </StatusContent>
                    </InfoContent>
                  </InfoContainer>
                  <PointsContainer>
                    <Text>
                      -
                      {points}
                      {points === 1 ? ' indicação' : ' indicações'}
                    </Text>
                  </PointsContainer>
                </>
              ) : referralRequest.status === 'CANCELED' ? (
                <>
                  <InfoContainer>
                    <CancelTitle>
                      {`${referralRequest.items[0].quantity}x ${referralRequest.items[0].referral_reward_name} ${
                        referralRequest.items[0].gender ? referralRequest.items[0].gender + (referralRequest.items[0].size ? ' - ' : '') : ''
                      }${referralRequest.items[0].size ? referralRequest.items[0].size : ''}`}
                    </CancelTitle>
                    <InfoContent>
                      <DateCancelText>{formattedDate}</DateCancelText>
                      <StatusContent>
                        <BsDot size={18} style={{ color: '#FF0000' }} />
                        <StatusText>Resgate Cancelado</StatusText>
                      </StatusContent>
                    </InfoContent>
                  </InfoContainer>
                  <PointsContainer>
                    <CancelText>
                      -
                      {points}
                      {points === 1 ? ' indicação' : ' indicações'}
                    </CancelText>
                  </PointsContainer>
                </>
              ) : null}
            </>
          ) : (
            <>
              <InfoContainer>
                <Title>{referralUser?.name}</Title>
                <DateText>{formattedDate}</DateText>
              </InfoContainer>
              <PointsContainer>
                <Text>
                  {points}
                  {points === 1 ? ' indicação' : ' indicações'}
                </Text>
              </PointsContainer>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Card>
  );
}

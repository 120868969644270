import React, { useEffect } from 'react';
import { IStep } from '../..';
import {
  Container,
  Content,
  Description,
  FieldBox,
  Title,
  TextArea,
} from './styles';

export interface ISetNotesScreenProps {
  step: IStep,
  onChangeHistoryNotes: (values: any) => void,
  onChangeGeneralNotes: (values: any) => void,
  setButtonDisabled: (values: any) => void,
}

function SetNotesScreen({
  step, onChangeHistoryNotes, onChangeGeneralNotes, setButtonDisabled,
}: ISetNotesScreenProps) {
  useEffect(() => {
    setButtonDisabled(false);
  }, []);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Content>
        <FieldBox>
          <Description>{step.description}</Description>

          <TextArea
            onChange={(e) => onChangeHistoryNotes(e.target.value)}
            rows={5}
            placeholder="Opcional"
          />
        </FieldBox>
        <FieldBox style={{ marginTop: 10 }}>
          <Description>{step.description2}</Description>

          <TextArea
            onChange={(e) => onChangeGeneralNotes(e.target.value)}
            rows={4}
            placeholder="Opcional"
          />
        </FieldBox>
      </Content>
    </Container>
  );
}

export default SetNotesScreen;

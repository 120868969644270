import React from 'react';
import { MdDone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  Container, Content, ContentContainer, Header, Price, Title, Parcel,
} from './styles';
import theme from '../../theme';
import ModalButton from '../ModalButton';

interface Props {
  action: () => void
}

export default function ChallengeSubscriptionCard({ action }: Props) {
  const auth = useSelector((state: RootState) => state.auth);
  const { token } = auth;
  const redirectToPayment = () => {
    const tok = token ? `?token=${token}` : '';
    window.open(`https://pay.corridaperfeita.com/pay/product/serra-gaucha/${tok}`);
    console.log(action);
  };

  return (
    <Container>
      <Header>
        <Title>Inscrição + Medalha</Title>
        <Price>119,90</Price>
        <Parcel>em até 3 X 39,97</Parcel>
      </Header>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Inscrição no desafio</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Medalha de finisher exclusiva</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Acompanhamento do progresso</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Visualização de ruas da sua localização virtual</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Medalha enviada após a conclusão</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Frete grátis da medalha para todo o Brasil</Content>
      </ContentContainer>

      <ModalButton title="INSCREVA-SE AGORA" onPress={redirectToPayment} style={{ padding: 8, margin: 23 }} />
    </Container>
  );
}

export default {
  colors: {
    primary: '#000033',
    primary2: '#0D0D3D',
    primary3: '#242450',
    cardB1: '#0D0D3D',
    cardB2: '#24244F',
    cardB3: '#2F2F58',
    cardB4: '#404065',
    cardImg: '0,0,51,0.7',
    arrow: '#808099',
    cardTxt: '#C3C3C3',
    notifications: '#FFE100',
    graph: '#00AB2B',
    title: '#fff',
    text: '#C3C3C3',
    background: '#F8F8F8',
    secondary: '#FBE306',
    gray: '#aaa',
    black: '#000',
    iconsOff: '#808099',
    redAlert: '#FF4444',
    red: '#DB5148',
    level1: '#004311',
    level2: '#006D1C',
    level3: '#00AB2B',
    level4: '#00FF40',
    check: '#1B63F2',

    titleCard: '#878B99',
    grayProg: '#878B99',

  },
  defaultShadow: '0px 4px 40px rgba(0, 0, 0, 0.45)',
};

export const appTheme = {
  background: '#000033',
  cardB1: '#0D0D3D',
  cardB2: '#24244F',
  cardB3: '#2F2F58',
  title: '#FFFFFF',
  text: '#C3C3C3',
  graph: '#00AB2B',
  notifications: '#FFE100',
  iconsOn: '#FFFFFF',
  iconsOff: '#808099',
  redAlert: '#FF4444',

  primaryD4: '#000F2E',
  primaryD3: '#002B80',
  primaryD2: '#002B80',
  primaryD1: '#002B80',
  primary: '#003399',
  primaryL1: '#2453B2',
  primaryL2: '#527ACC',
  primaryL3: '#7EA1E5',
  primaryL4: '#B6CAF2',
  primaryL5: '#D9E6FF', // 217, 230, 255
  primaryL6: '#EBF1FF',

  whiteBG: 'rgba(255, 255, 255, 0.5)',

  primaryPlaceholder: 'rgba(217, 230, 255, 1)',
  primaryPlaceholderO1: 'rgba(217, 230, 255, 0.7)',
  primaryInputBG: 'rgba(0, 26, 77, 0.3)',
  primaryInputDBG: 'rgba(0, 15, 46, 0.3)',
  titleBG: 'rgba(0, 26, 77, 0.1)',

  overlayBG: 'rgb(60, 69, 105, .5)',
  successBtn: 'rgba(0, 153, 0, 0.7)',

  primaryGradD1: '#003399',
  primaryGradL1: '#505ccb',

  secondaryD3: '#4D4300',
  secondaryD2: '#8C7C00',
  secondaryD1: '#CCB400',
  secondary: '#FFE100',
  secondaryL1: '#FFE526',
  secondaryL2: '#FFED66',
  secondaryL3: '#FFEE73',
  secondaryL4: '#FFF4A6',
  secondaryL5: '#FFFAD4',

  grayD3: '#202833',
  grayD2: '#404B5A',
  grayD1: '#6D7989',
  gray: '#929FB1',
  grayL1: '#AEBBCD',
  grayL2: '#CCD2DB',
  grayL3: '#D5DCE5',
  grayL4: '#E0E5EB',
  grayL5: '#F8F9FA',
  grayBG: '#E5E5E5',
  grayProg: '#878B99',

  redD3: '#A3160D',
  redD2: '#B02820',
  redD1: '#C93B32',
  red: '#DB5148',
  redL1: '#ED6961',
  redL2: '#FA8B84',
  redL3: '#FFAEA8',
  redL4: '#FFCDC9',
  redL5: '#FFECEB',

  greenD3: '#07750D',
  greenD2: '#1B9421',
  greenD1: '#2C9E32',
  green: '#49BF4F',
  greenL1: '#5BD961',
  greenL2: '#96D699',
  greenL3: '#ADF0B0',
  greenL4: '#D9FADB',
  greenL5: '#F0FFF0',

  zone1: '#74D7ED',
  zone2: '#13CE66',
  zone3: '#FFFF7C',
  zone4: '#FFBB6A',
  zone5a: '#EB9C3D',
  zone5b: '#F95F62',
  zone5c: '#DB3B3E',

  dimmedTitle: '#42427A',
};

import styled from 'styled-components';
import THEME from '../../../theme';

export const ItemTitle = styled.p`
    color: ${THEME.colors.title};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const ModalContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;

export const ModalButtonsContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
`;

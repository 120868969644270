import styled from 'styled-components';
import THEME from '../../theme';
import Switch from '../Switch';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEME.colors.cardB2};
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 20px;
`;

export const ItemDescriptionContainer = styled.div`
    max-width: 80%;
`;

export const ItemTitle = styled.p`
    color: ${THEME.colors.title};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const ItemDescription = styled.p`
    color: ${THEME.colors.gray};
    font-size: 12px;
`;

export const ItemButton = styled(Switch)`
    max-width: 20%;
`;

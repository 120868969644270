import React, { useState, useEffect } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { BsCheckCircleFill, BsClockFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import BackArrow from '../../components/BackArrow';
import BaseModal from '../../components/BaseModal';
import PointsDetailCard from '../../components/PointsDetailCard/Index';
import api from '../../services/api';
import {
  Container,
  ItemsContainer,
  TextDescription,
  Content,
  HeaderContainer,
  Header,
  IconButton,
  InfoContainer,
  Button,
  TextButton,
  BalanceAvailable,
  BalanceText,
  HeaderText,
  ModalText,
} from './styles';

interface ReferralRewardItem {
  id: number;
  referral_reward_request_id: number;
  referral_reward_id: number;
  referral_reward_name: string;
  gender: string | null;
  size: string | null;
  quantity: number;
  used_points: number;
  created_at: string;
  updated_at: string;
}

interface ReferralRequest {
  id: number;
  user_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  items: ReferralRewardItem[];
}

export interface Statement {
  id: number;
  user_id: number;
  referral_user_id: number | null;
  referral_reward_request_id: number;
  subscription_id: number | null;
  product_id: number | null;
  product_name: string | null;
  points: number;
  origin: string;
  statement_type: string;
  created_at: string;
  updated_at: string;
  referralUser: null | { name: string };
  referralRequest: ReferralRequest;
}

export interface Statements {
  availablePoints: number;
  data: Statement[];
}

interface APIResponse {
  statements: {
    availablePoints: number;
    data: Statements['data'];
  };
  pendingStatements: {
    points: number;
    data: Statements['data'];
  };
  canceledStatements: {
    points: number;
    data: Statements['data'];
  };
}

function PointsContainer({ data, type, toggleModal }: { data: Statements; type: 'EXTRACT' | 'PENDING' | 'CANCELED'; toggleModal: () => void }) {
  const [showMore, setShowMore] = useState(false);
  const slicedData = data.data.slice(0, 4);
  const displayedData = showMore ? data.data : slicedData;

  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <Content>
      <HeaderContainer>
        <Header>
          {type === 'EXTRACT' ? (
            <BsCheckCircleFill size={10} style={{ color: 'green' }} />
          ) : type === 'CANCELED' ? (
            <MdCancel size={11} style={{ color: '#FF0000' }} />
          ) : (
            <BsClockFill size={9} style={{ color: '#FFC700' }} />
          )}
          <HeaderText>{type === 'EXTRACT' ? 'Extrato' : type === 'PENDING' ? 'Aguardando confirmação' : 'Indicações canceladas'}</HeaderText>
        </Header>
        <IconButton onClick={() => toggleModal()}>
          <BiInfoCircle size={20} style={{ color: 'white' }} />
        </IconButton>
      </HeaderContainer>
      <InfoContainer>
        {displayedData.map((item) => (
          <PointsDetailCard item={item} type={type} />
        ))}

        {!showMore && data.data.length > 4 && (
          <Button onClick={handleShowMore}>
            <TextButton>Ver mais</TextButton>
          </Button>
        )}
        <BalanceAvailable>
          <BalanceText style={{ color: 'white' }}>
            {type === 'EXTRACT' ? 'Saldo disponível' : type === 'PENDING' ? 'Total a confirmar' : 'Cancelados'}
            :
          </BalanceText>
          <BalanceText style={{ color: '#FFC700', textDecorationLine: 'underline', paddingLeft: 5 }}>
            {data.availablePoints}
            {' '}
            {data.availablePoints === 1 ? 'indicação' : 'indicações'}
          </BalanceText>
        </BalanceAvailable>
      </InfoContainer>
    </Content>
  );
}

export default function PointsDetail() {
  const [pointsData, setPointsData] = useState<APIResponse | null>(null);
  const [error, setError] = useState(null);
  const [showExtractPointsModal, setShowExtractPointsModal] = useState(false);
  const [showPendingPointsModal, setShowPendingPointsModal] = useState(false);
  const [showCanceledPointsModal, setShowCanceledPointsModal] = useState(false);
  const toggleShowExtractPointsModal = () => setShowExtractPointsModal((isOpen) => !isOpen);
  const toggleShowPendingPointsModal = () => setShowPendingPointsModal((isOpen) => !isOpen);
  const toggleShowCanceledPointsModal = () => setShowCanceledPointsModal((isOpen) => !isOpen);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get('/referrals/user_history');

        setPointsData(res.data);

        setError(null);
      } catch (err: any) {
        setError(err);
        setPointsData(null);
      }
    }

    fetchData();
  }, []);

  return (
    <Container>
      <BackArrow text="INDIQUE E GANHE" bigger />
      {error != null || pointsData == null ? (
        <div style={{ color: 'white' }}>Ocorreu um erro ao buscar as indicações</div>
      ) : (
        <ItemsContainer>
          <TextDescription>Confira a seguir todas as movimentações feita na sua conta referente ao programa Indique e Ganhe.</TextDescription>
          <PointsContainer
            data={{ data: pointsData.statements.data, availablePoints: pointsData.statements.availablePoints }}
            type="EXTRACT"
            toggleModal={toggleShowExtractPointsModal}
          />
          <PointsContainer
            data={{ data: pointsData.pendingStatements.data, availablePoints: pointsData.pendingStatements.points }}
            type="PENDING"
            toggleModal={toggleShowPendingPointsModal}
          />
          <PointsContainer
            data={{ data: pointsData.canceledStatements.data, availablePoints: pointsData.canceledStatements.points }}
            type="CANCELED"
            toggleModal={toggleShowCanceledPointsModal}
          />
        </ItemsContainer>
      )}
      <BaseModal isVisible={showExtractPointsModal} setIsVisible={setShowExtractPointsModal} title="EXTRATO">
        <ModalText>Aqui você acompanha o saldo disponível para resgate a partir do extrato de indicações confirmadas ou resgatadas em prêmios.</ModalText>
      </BaseModal>
      <BaseModal isVisible={showPendingPointsModal} setIsVisible={setShowPendingPointsModal} title="AGUARDANDO CONFIRMAÇÃO">
        <ModalText>
          As indicações aguardando confirmação são aqueles em que o indicado está no período de garantia, que dura de 15 a 30 dias. Caso ele não cancele nesse período, as indicações são
          confirmadas e passam a constar do seu extrato, como disponíveis.
        </ModalText>
      </BaseModal>
      <BaseModal isVisible={showCanceledPointsModal} setIsVisible={setShowCanceledPointsModal} title="CANCELADO">
        <ModalText>As indicações canceladas são as que estavam aguardando confirmação, mas o cliente pediu cancelamento do serviço dentro do período de garantia.</ModalText>
      </BaseModal>
    </Container>
  );
}

import styled from 'styled-components';
import { ButtonProps, ButtonTextProps } from './contracts';
import { appTheme } from '../../theme';

export const ModalBackground = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    max-height: 85%;
    background-color: ${appTheme.background};
    padding: 5px;
`;

export const ModalContentTitle = styled.p`
    color: ${appTheme.title};
    font-size: 14px;
    margin-top: 30px;
`;

export const ModalContentSubTitle = styled.p`
    color: ${appTheme.title};
    font-size: 12px;
    margin-top: 10px;
`;

export const ModalContentButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
`;

export const ModalContentButton = styled.button<ButtonProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${(props) => props.color};
    border-radius: 5px;
    margin-top: 10px;
    background-color: unset;
`;

export const ModalContentButtonText = styled.p<ButtonTextProps>`
    color: ${(props) => props.color};
    font-size: 16px;
`;

import React, {
  createContext, Dispatch, SetStateAction, useMemo, useState,
} from 'react';

type NavbarContextType = {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
};

export const NavbarContext = createContext({} as NavbarContextType);

export function NavbarProvider({ children }: any) {
  const [selected, setSelected] = useState('home');

  const value = useMemo(() => ({ selected, setSelected }), [selected]);

  return (
    <NavbarContext.Provider value={value}>
      {children}
    </NavbarContext.Provider>
  );
}

import React from 'react';
import alert from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import RaceCalendarContext from './RaceCalendarContext';
import { RootState } from '../../../store';
import {
  ICoupon, IRace, clearRaces, loadRaces,
} from '../../../store/RaceCalendar.store';
import { RaceCalendarContextData } from './types';
import api from '../../../services/api';

interface RaceCalendarProviderProps {
  children: React.ReactNode;
}

export default function RaceCalendarProvider({ children }: RaceCalendarProviderProps) {
  const dispatch = useDispatch();
  const { races, error: calendarStoreError } = useSelector((state: RootState) => state.raceCalendar);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Filters
  const [filteredCity, setFilteredCity] = React.useState<string>('');
  const [filteredCoupon, setFilteredCoupon] = React.useState<string>('');
  const [filteredName, setFilteredName] = React.useState<string>('');
  const [filteredState, setFilteredState] = React.useState<string>('');
  const [filteredMaxDistance, setFilteredMaxDistance] = React.useState<undefined | number>();
  const [filteredMinDistance, setFilteredMinDistance] = React.useState<undefined | number>();
  const [displayDistances, setDisplayDistances] = React.useState<[number, number]>([1, 42]);
  const [filteredYear, setFilteredYear] = React.useState<string>('');
  const [filteredMonth, setFilteredMonth] = React.useState<number | undefined>();
  const [startDate, setStartDate] = React.useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = React.useState<string>('');
  const [withStructure, setWithStructure] = React.useState(false);

  // Resolved Data
  const [racesByDate, setRacesByDate] = React.useState<{
    month: string;
    raceDays: {
      day: string;
      races: IRace[];
    }[];
  }[]>([]);

  // Methods
  function ResetFilters() {
    setFilteredCoupon('');
    setFilteredCity('');
    setFilteredName('');
    setFilteredState('');
    setFilteredMaxDistance(undefined);
    setFilteredMinDistance(undefined);
    setDisplayDistances([1, 42]);
    setFilteredYear('');
    setFilteredMonth(undefined);
    setStartDate(format(new Date(), 'yyyy-MM-dd'));
    setEndDate('');
    setWithStructure(false);
  }

  const FetchCalendar = React.useCallback((skip = 0) => {
    dispatch(loadRaces({
      name: filteredName,
      city: filteredCity,
      state: filteredState,
      max_distance: filteredMaxDistance,
      min_distance: filteredMinDistance,
      start_date: startDate,
      end_date: endDate,
      has_coupon: filteredCoupon === 'coupon',
      has_structure: withStructure,
      skip,
    }) as any);
  }, [filteredCity, filteredName, filteredState, filteredMaxDistance, filteredMinDistance, startDate, endDate, withStructure, filteredCoupon]);

  const FetchCalendarNextPage = React.useCallback(() => {
    FetchCalendar(races.length);
  }, [races]);

  function ShowGenericError() {
    alert('Erro ao carregar o calendário, tente novamente mais tarde.');
  }

  function SetMonthFilter(month: number) {
    const today = new Date();
    const year = today.getFullYear();

    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    setFilteredMonth(month);
    setStartDate(format(startDate, 'yyyy-MM-dd'));
    setEndDate(format(endDate, 'yyyy-MM-dd'));
  }

  function SetYearFilter(year: string) {
    setFilteredYear(year);

    if (Number(year) > 2000) {
      setStartDate(`${year}-${filteredMonth || 1}-01`);
      setEndDate(`${year}-${(filteredMonth || 11) + 1}-31`);
    }
  }

  function SetDistancesFilter(distances: [number, number]) {
    if (distances.length !== 2 || distances[0] > distances[1]) {
      throw new Error('Entrada inválida. A entrada deve ser uma array de dois números onde o primeiro é menor ou igual ao segundo.');
    }

    setFilteredMinDistance(distances[0]);
    setFilteredMaxDistance(distances[1] === 42 ? 999 : distances[1]);

    setDisplayDistances(distances);
  }

  const groupRacesByDate = (races: IRace[]) => {
    const groupedByDate = races.reduce((grouped, race) => {
      const date = new Date(race.date);
      const yearMonthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const dayKey = `${date.getDate()}`;

      if (!grouped[yearMonthKey]) {
        grouped[yearMonthKey] = {};
      }

      if (!grouped[yearMonthKey][dayKey]) {
        grouped[yearMonthKey][dayKey] = [];
      }

      grouped[yearMonthKey][dayKey].push(race);

      return grouped;
    }, {} as Record<string, Record<string, IRace[]>>);

    return Object.entries(groupedByDate).map(([month, days]) => {
      const [year, monthNumber] = month.split('-');
      return {
        month: format(new Date(Number(year), Number(monthNumber) - 1), 'MMM', { locale: ptBR }),
        raceDays: Object.entries(days).map(([day, races]) => ({
          day,
          races,
        })),
      };
    });
  };

  // RD Station Tracking
  interface TrackingData {
    visitorUserName: string;
    visitorEmail: string;
    visitorPhone: string;
    redeemedCoupons: string[];
    racesOfInterest: string[];
    statesOfInterest: string[];
    visitorDataSaved: boolean;
  }

  const localTrackingData: TrackingData = JSON.parse(localStorage.getItem('@RaceCalendar:TrackingData') || '{}');
  const { user, token } = useSelector((state: RootState) => state.auth);

  const [visitorDataSaved, setVisitorDataSaved] = React.useState<boolean>(localTrackingData.visitorDataSaved || false);
  const [visitorUserName, setVisitorUserName] = React.useState<string>(localTrackingData.visitorUserName || '');
  const [visitorEmail, setVisitorEmail] = React.useState<string>(localTrackingData.visitorEmail || '');
  const [visitorPhone, setVisitorPhone] = React.useState<string>(localTrackingData.visitorPhone || '');
  const [redeemedCoupons, setRedeemedCoupons] = React.useState<string[]>(localTrackingData.redeemedCoupons || []);
  const [racesOfInterest, setRacesOfInterest] = React.useState<string[]>(localTrackingData.racesOfInterest || []);
  const [statesOfInterest, setStatesOfInterest] = React.useState<string[]>(localTrackingData.statesOfInterest || []);

  function PurgeTrackingData() {
    setRedeemedCoupons([]);
    setRacesOfInterest([]);
    setStatesOfInterest([]);
  }

  React.useEffect(() => {
    const scheduleTask = setTimeout(() => {
      localStorage.setItem('@RaceCalendar:TrackingData', JSON.stringify({
        visitorUserName,
        visitorEmail,
        visitorPhone,
        redeemedCoupons,
        racesOfInterest,
        statesOfInterest,
        visitorDataSaved,
        userId: user.id,
      }));
    }, 2000);

    return () => {
      clearTimeout(scheduleTask);
    };
  }, [
    visitorUserName,
    visitorEmail,
    visitorPhone,
    redeemedCoupons,
    racesOfInterest,
    statesOfInterest,
    visitorDataSaved,
  ]);

  const SendRDEvent = React.useCallback((race?: IRace, coupon?: ICoupon) => {
    if (!token && !visitorDataSaved) return;

    if ((race && !coupon && racesOfInterest.includes(race._id))
      || (race && coupon && redeemedCoupons.includes(coupon.id))
      || (!race && !coupon && statesOfInterest.includes(filteredState))) {
      return;
    }

    if (coupon && !redeemedCoupons.includes(coupon.id)) {
      setRedeemedCoupons((prev) => [...prev, coupon.id]);
    }

    if (race && !racesOfInterest.includes(race._id)) {
      setRacesOfInterest((prev) => [...prev, race._id]);
    }

    if (filteredState && !statesOfInterest.includes(filteredState)) {
      setStatesOfInterest((prev) => [...prev, filteredState]);
    }

    const data = {
      utm_medium: 'app-corrida-perfeita',
      utm_source: 'web',
      user_id: user.id,
      user_name: user.name || visitorUserName,
      user_email: user.email || visitorEmail,
      user_phone: user.phone || visitorPhone,
      user_state: user.address?.state || '',
      race_of_interest: race?.name,
      race_state: race ? filteredState : '',
      redeemed_coupon: coupon,
      state_of_interest: filteredState,
      user_subscription_type: user.subscription_type,
      coupon_for_member: coupon?.member,
    };

    api.post('/race_calendar_data', data);
  }, [user, filteredState, redeemedCoupons, racesOfInterest, visitorEmail, visitorPhone, visitorUserName]);

  // Effects
  const initialFilteredCity = React.useRef(filteredCity);
  const initialFilteredName = React.useRef(filteredName);
  const initialFilteredState = React.useRef(filteredState);
  const initialFilteredMaxDistance = React.useRef(filteredMaxDistance);
  const initialFilteredMinDistance = React.useRef(filteredMinDistance);
  const initialStartDate = React.useRef(startDate);
  const initialEndDate = React.useRef(endDate);
  const initialWithStructure = React.useRef(withStructure);
  const initialFilteredCoupon = React.useRef(filteredCoupon);

  React.useEffect(() => {
    setLoading(true);

    if (
      initialFilteredCity.current !== filteredCity
      || initialFilteredName.current !== filteredName
      || initialFilteredMaxDistance.current !== filteredMaxDistance
      || initialFilteredMinDistance.current !== filteredMinDistance
      || initialStartDate.current !== startDate
      || initialEndDate.current !== endDate
      || initialFilteredState.current !== filteredState
      || initialWithStructure.current !== withStructure
      || initialFilteredCoupon.current !== filteredCoupon
    ) {
      dispatch(clearRaces() as any);

      const scheduleFetch = setTimeout(() => {
        FetchCalendar();
        setLoading(false);
      }, 3000);

      // Update the initial values
      initialFilteredCity.current = filteredCity;
      initialFilteredName.current = filteredName;
      initialFilteredMaxDistance.current = filteredMaxDistance;
      initialFilteredMinDistance.current = filteredMinDistance;
      initialStartDate.current = startDate;
      initialEndDate.current = endDate;
      initialFilteredState.current = filteredState;
      initialWithStructure.current = withStructure;
      initialFilteredCoupon.current = filteredCoupon;

      return () => {
        clearTimeout(scheduleFetch);
      };
    }

    return () => { };
  }, [filteredCity, filteredName, filteredMaxDistance, filteredMinDistance, startDate, endDate, filteredState, withStructure, filteredCoupon]);

  React.useEffect(() => {
    setRacesByDate(groupRacesByDate(races));
  }, [races]);

  React.useEffect(() => {
    if (calendarStoreError) ShowGenericError();
  }, [calendarStoreError]);

  React.useEffect(() => {
    if (filteredState) {
      SendRDEvent();
    }
  }, [filteredState]);

  React.useEffect(() => {
    if (String(user.id) !== localStorage.getItem('@RaceCalendar:TrackingData:userId')) {
      FetchCalendar();
      PurgeTrackingData();
    }
  }, [user]);

  const value: RaceCalendarContextData = React.useMemo(() => ({
    data: {
      racesByDate,
    },
    filters: {
      filteredCoupon,
      filteredCity,
      filteredName,
      filteredState,
      startDate,
      endDate,
      filteredYear,
      filteredMonth,
      setFilteredName,
      setFilteredCity,
      setFilteredCoupon,
      setFilteredState,
      setStartDate,
      setEndDate,
      loading,
      setLoading,
      SetMonthFilter,
      SetYearFilter,
      displayDistances,
      SetDistancesFilter,
      withStructure,
      setWithStructure,
    },
    actions: {
      FetchCalendarNextPage,
      FetchCalendar,
      ResetFilters,
    },
    rdTracking: {
      SendRDEvent,
      visitorUserName,
      visitorEmail,
      visitorPhone,
      setVisitorUserName,
      setVisitorEmail,
      setVisitorPhone,
      visitorDataSaved,
      setVisitorDataSaved,
    },
  }), [
    filteredCity,
    filteredCoupon,
    filteredState,
    startDate,
    endDate,
    filteredMonth,
    filteredYear,
    setFilteredCity,
    setFilteredCoupon,
    setFilteredName,
    setFilteredState,
    setStartDate,
    setEndDate,
    FetchCalendarNextPage,
    FetchCalendar,
    loading,
    setLoading,
    racesByDate,
    SetMonthFilter,
    SetYearFilter,
    SendRDEvent,
    setVisitorUserName,
    setVisitorEmail,
    setVisitorPhone,
    visitorUserName,
    visitorEmail,
    visitorPhone,
    visitorDataSaved,
    setVisitorDataSaved,
    withStructure,
    setWithStructure,
  ]);

  return (
    <RaceCalendarContext.Provider value={value}>
      {children}
    </RaceCalendarContext.Provider>
  );
}

import React from 'react';
import {
  InnerContainer, Input, InputContainer, InputTitle, Label, OuterContainer,
} from './styles';
import { ParseHours, ParseMinutesOrSeconds } from '../../../utils/TimeHelpers';

export interface ITimeInputProps {
  valueInSeconds: number | undefined;
  onChange: (valueInSeconds: number) => void;
  showLabel?: boolean;
  backgroundColor?: string;
}

function TimeInput({
  valueInSeconds, onChange, showLabel = true, backgroundColor,
}: ITimeInputProps) {
  const [innerHourValue, setInnerHourValue] = React.useState<number | undefined>(undefined);
  const [innerMinuteValue, setInnerMinuteValue] = React.useState<number | undefined>(undefined);
  const [innerSecondValue, setInnerSecondValue] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (valueInSeconds === undefined) {
      setInnerHourValue(undefined);
      setInnerMinuteValue(undefined);
      setInnerSecondValue(undefined);
      return;
    }

    const hours = Math.floor(valueInSeconds / 3600);
    const minutes = Math.floor((valueInSeconds - (hours * 3600)) / 60);
    const seconds = valueInSeconds - (hours * 3600) - (minutes * 60);

    setInnerHourValue(hours);
    setInnerMinuteValue(minutes);
    setInnerSecondValue(seconds);
  }, [valueInSeconds]);

  function handleHourChange(event: React.ChangeEvent<HTMLInputElement>) {
    const parsedValue = ParseHours(event.target.value);
    setInnerHourValue(parsedValue);
  }

  function handleMinuteChange(event: React.ChangeEvent<HTMLInputElement>) {
    const parsedValue = ParseMinutesOrSeconds(event.target.value);
    setInnerMinuteValue(parsedValue);
  }

  function handleSecondChange(event: React.ChangeEvent<HTMLInputElement>) {
    const parsedValue = ParseMinutesOrSeconds(event.target.value);
    setInnerSecondValue(parsedValue);
  }

  React.useEffect(() => {
    const parsedHourValue = innerHourValue || undefined;
    const parsedMinuteValue = innerMinuteValue || undefined;
    const parsedSecondValue = innerSecondValue || undefined;

    const totalSeconds = ((parsedHourValue || 0) * 3600) + ((parsedMinuteValue || 0) * 60) + (parsedSecondValue || 0);

    onChange(totalSeconds);
  }, [innerHourValue, innerMinuteValue, innerSecondValue]);

  return (
    <OuterContainer>
      {showLabel && <Label>Tempo</Label>}
      <InnerContainer>
        <InputContainer backgroundColor={backgroundColor}>
          <Input
            type="number"
            value={innerHourValue || ''}
            onChange={handleHourChange}
            placeholder="00"
          />
          <InputTitle>
            hr
          </InputTitle>
        </InputContainer>
        <InputContainer backgroundColor={backgroundColor}>
          <Input
            type="number"
            value={innerMinuteValue || ''}
            onChange={handleMinuteChange}
            placeholder="00"
          />
          <InputTitle>
            min
          </InputTitle>
        </InputContainer>
        <InputContainer backgroundColor={backgroundColor}>
          <Input
            type="number"
            value={innerSecondValue || ''}
            onChange={handleSecondChange}
            placeholder="00"
          />
          <InputTitle>
            s
          </InputTitle>
        </InputContainer>
      </InnerContainer>
    </OuterContainer>
  );
}

export default TimeInput;

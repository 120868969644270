import styled from 'styled-components';
import theme from '../../theme';
import { ModalContent as _ModalContent } from '../Modal/Modal.styles';

export const ModalContent = styled(_ModalContent)`
  padding: 25px 50px;
  border: 1px solid #00ff40;
  box-shadow: 0px 4px 40px rgba(0, 255, 64, 0.45);
  border-radius: 10px;

  @media (max-width: 667px) {
    padding: 15px;
  }
`;

export const ModalBackDrop = styled.div`
  background: rgba(60, 69, 105, 0.5);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
`;
export const ModalContentButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const ModalContentButton = styled.button`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 250px;
  padding: 0.5rem;

  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-color: ${theme.colors.title};
  border-width: 2px;
  border-style: solid;
  background-color: ${theme.colors.primary};

  &:first-of-type ~ & {
    margin-left: 1rem;
  }
`;

export const ModalContentButtonTextCancel = styled.span`
  color: ${theme.colors.title};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const Header = styled.span`
  text-align: center;
  text-transform: uppercase;
  color: #00ff40;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
`;

export const InfoText = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const InfoText2 = styled(InfoText)`
  margin-bottom: 20px;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const Text = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

export const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

export const CouponContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
`;

export const DiscountValue = styled.span`
  text-transform: uppercase;
  color: #00ff40;
  font-size: 18px;
  font-weight: bold;
  width: 100px;
  margin-bottom: 3px;

  

  @media (max-width: 667px) {
    font-size: 14px;
    left: 0px;
    width: 30px;
  }
`;

export const CouponCodeButton = styled.div<{
  cursor?: string;
  specialCoupon?: boolean;
}>`
  display: flex;
  width: 50%;
  height: 40px;
  justify-content: center;

  border-radius: 5px;
  ${({ specialCoupon }) => (specialCoupon
    ? `
  box-shadow: 0 0 5px 2px #00ff40;
  border: 1px solid #00ff40;
  `
    : '')}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}

  @media (max-width: 667px) {
    width: 75%;
  }
`;

export const CouponText = styled.div`
  background-color: ${theme.colors.title};
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 13px;
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CopyClick = styled.div`
  background-color: ${theme.colors.cardB2};
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  color: ${theme.colors.gray};
  font-weight: bold;
  font-size: 13px;
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Site = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.colors.title};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.cardB2};
  margin: 10px 0 30px;
`;

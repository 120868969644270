import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Container, Content, Card,
  LoadingContainer,
} from './styles';
import BackArrow from '../../components/BackArrow';

import ChallengeHeader from '../../components/ChallengeHeader';
import ChallengeSubscription from './ChallengeSubscription';
import ChallengeGoal from './ChallengeGoal';
import ChallengeProgress from './ChallengeProgress';
import coreApi from '../../services/coreApi';
import { RootState } from '../../store';
import IntegrationCard from '../../components/IntegrationCard';
import Loader from '../../components/Loader';
import api from '../../services/api';
// import api from '../../services/api';

export enum ChallengeStatesTypes {
  SUBSCRIPTION = 'SUBSCRIPTION',
  WAITING_PAYMENT = 'WAYTING_PAYMENT',
  SETTING_GOAL = 'SETTING_GOAL',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export type ChallengeType = {
  _id: string,
  slug: string,
  weeks: number,
  status: string,
  user_id: number,
  started_date: Date,
}

export default function ChallengePage() {
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    coros_config, strava_config, garmin_config, polar_config,
  } = useSelector((state: RootState) => state.integration);

  const hasSomeIntegration = !!user?.s_id || !!user?.garmin_access_token || !!coros_config?.id || !!strava_config?.id || !!garmin_config?.id || !!polar_config?.id;

  const [state, setState] = useState<ChallengeStatesTypes>(ChallengeStatesTypes.SUBSCRIPTION);
  const [challenge, setChallenge] = useState<ChallengeType>();

  const [showIntegrationCard, setShowIntegrationCard] = useState(true);

  React.useEffect(() => {
    if (state === ChallengeStatesTypes.SUBSCRIPTION || state === ChallengeStatesTypes.WAITING_PAYMENT) {
      setShowIntegrationCard(false);
    }
  }, []);
  const [loading, setLoading] = useState(true);

  const renderCurrentState = () => {
    switch (state) {
      case ChallengeStatesTypes.SUBSCRIPTION:
        return <ChallengeSubscription action={() => setState(ChallengeStatesTypes.SETTING_GOAL)} />;

      case ChallengeStatesTypes.WAITING_PAYMENT:
        return <></>;

      case ChallengeStatesTypes.SETTING_GOAL:
        return (
          <ChallengeGoal
            userId={user.id}
            setChallenge={setChallenge}
            challenge={challenge}
            action={() => setState(ChallengeStatesTypes.IN_PROGRESS)}
          />
        );

      case ChallengeStatesTypes.IN_PROGRESS:
        return <ChallengeProgress challenge={challenge!} />;
      default:
        return <ChallengeSubscription action={() => setState(ChallengeStatesTypes.SETTING_GOAL)} />;
    }
  };

  const fetchChallenge = async () => {
    setLoading(true);
    try {
      if (user.id) {
        await api.get(`challenge/verify/${user.id}/serra-gaucha`);
        const response = (await (coreApi.get(`challenge/serra-gaucha/${user.id}`)));
        const { data } = response;
        if (data) {
          setChallenge(data);
          setState(data.status);
        } else {
          setState(ChallengeStatesTypes.SUBSCRIPTION);
        }
      }
      setLoading(false);
    } catch (e) {
      toast.error('Erro ao carregar o desafio');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChallenge();
  }, []);

  return (
    <Container>
      <BackArrow text="Desafio Serra Gaúcha" bigger backTo="/club/desafios" />
      <Content>
        {loading
          && (
            <LoadingContainer>
              <Loader color="white" />
            </LoadingContainer>
          )}
        {!loading && (
          <Card>
            <ChallengeHeader status={state} edit={() => setState(ChallengeStatesTypes.SETTING_GOAL)} />
            {renderCurrentState()}
          </Card>
        )}
      </Content>
      {showIntegrationCard && <IntegrationCard action={() => setShowIntegrationCard(false)} isIntegrationActive={hasSomeIntegration} />}
    </Container>
  );
}

import styled from 'styled-components';

import theme, { appTheme } from '../../theme';

export const Card = styled.section`
  display: flex;
  border-radius: 10px;
  background: ${theme.colors.cardB2};
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
  padding: 15px;

  @media (max-width: 667px) {
    padding: 12px;
  }
`;

export const InfoContainer = styled.div`
  width: 60%;

  @media (max-width: 667px) {
    flex: unset;
    width: 80%;
  }
`;

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 18px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const RaceText = styled.span`
  color: ${theme.colors.title};
  margin-bottom: 3px;
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const LinkButtonText = styled.p`
  color: ${appTheme.title};
  margin-top: 3px;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
`;

export const RaceInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 667px) {
    margin-left: 10px;
  }
`;

export const InformContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  max-width: 130px;

  svg {
    height: 40px !important;
    width: 40px !important;
  }
`;

export const Inform = styled.div`
  color: #00ff40;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const RaceInfo = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const CouponButton = styled.button`
  display: flex;
  border: none;
  border-radius: 5px;
  align-items: center;
  background-color: ${theme.colors.primary};
  cursor: pointer;
  min-height: 40px;
  max-width: 193px;
  overflow: hidden;
  position: relative;
`;

export const TextIconButton = styled.span`
  justify-content: flex-end;
  color: ${theme.colors.gray};
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
`;

export const CouponCap = styled.div`
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  min-height: 40px;
  max-width: 160px;
  display: flex;
  align-items: center;
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 14px;
  padding: 6px;
  position: relative;
  z-index: 1;
  
  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const CouponCapEffect = styled.div`
  background-color: white;
  width: 200px;
  height: 185px;
  position: absolute;
  transform: rotate(75deg);
  right: 38px;
  top: -82px;
`;

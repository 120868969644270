import React from 'react';
import toast from 'react-hot-toast';
import api from '../../services/api';
import BaseModal from '../BaseModal';
import {
  ButtonContainer, CancelButton, CancelButtonText, Container, Header,
  InfoText, RemoveButton, RemoveButtonText,
} from './styles';

export interface IRemoveZoneTestModalProps {
  visible: boolean;
  close: Function;
  zoneTest: {id: number};
  refresh: Function;
}

function RemoveZoneTestModal({
  visible, close, refresh, zoneTest,
}: IRemoveZoneTestModalProps) {
  const deleteTest = async () => {
    try {
      await api.delete(`/user_zones/${zoneTest?.id}`);

      toast('Seu teste foi removido com sucesso!');

      refresh();

      close();
    } catch (error) {
      toast.error('Ocorreu algum problema ao remover seu teste, tente novamente!');
    }
  };

  return (
    <BaseModal
      isVisible={visible}
    >
      <Container>
        <Header>Apagar registro</Header>
        <InfoText>
          Tem certeza que deseja remover o teste vigente? Ao fazer isso,
          o teste mais recente registrado será usado para calcular suas zonas.
        </InfoText>
        <ButtonContainer>
          <CancelButton onClick={() => close()}>
            <CancelButtonText>Cancelar</CancelButtonText>
          </CancelButton>
          <RemoveButton onClick={() => deleteTest()}>
            <RemoveButtonText>Apagar</RemoveButtonText>
          </RemoveButton>
        </ButtonContainer>
      </Container>
    </BaseModal>
  );
}

export default RemoveZoneTestModal;

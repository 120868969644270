import styled from 'styled-components';
import InputMask from 'react-input-mask';
import theme from '../../theme';

export const Card = styled.section`
  display: block;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 5px;
  padding: 10px;
  background-color: ${theme.colors.primary3};
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
`;

export const CardTitle = styled.section`
  color: ${theme.colors.gray};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const InputLabel = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;

export const Input = styled.input`
  color: ${theme.colors.gray};
  font-size: 13px;
  margin-bottom: 10px;
  background-color: #2f2f58;
  border-radius: 5px;
  height: 35px;
  padding: 0;
  padding-left: 5px;
  border: none;
  display: block;
  width: 100%;
`;

export const TInput = styled(InputMask)`
  color: ${theme.colors.gray};
  font-size: 13px;
  margin-bottom: 10px;
  background-color: #2f2f58;
  border-radius: 5px;
  height: 35px;
  padding: 0;
  padding-left: 5px;
  border: none;
  display: block;
  width: 100%;
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SaveButton = styled.button`
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 10px;
  background-color: ${theme.colors.title};
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  
`;

export const SaveButtonTitle = styled.span`
  color: #2f2f58;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const RemoveContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 40px;
  background-color: ${theme.colors.primary2};
  height: 100px;
`;

export const RemoveAccountButton = styled.button`


  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: underline;
  text-decoration-color: red;
  font-size: 13px;
  color: red;
  margin-top: 10px;
  border: none;
  background-color: ${theme.colors.primary2};
  cursor: pointer;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const MessageLabel = styled.span`
color: ${theme.colors.gray};
font-size: 12px;
margin-bottom: 20px;
`;

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  background: ${theme.colors.cardB1};
  flex: 1;

`;

export const ItemsContainer = styled.div`
  padding: 5px;
  margin: 5px;

  :hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  padding: 13px;
  background-color: ${theme.colors.cardB3};
  border-radius: 10px;
  margin-bottom: 8px;
`;

export const ItemTitle = styled.p`
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
`;

export const ItemTitleUnderline = styled.span`
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;
  font-size: 15px;
`;

export const ItemDesc = styled.p`
  color: white;
  font-size: 13px;
`;

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  background: ${appTheme.cardB1};
  flex: 1;
  padding: 3%;

  button {
    border: unset;
  }
`;

export const NewTestHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NewTestButton = styled.button`
  background-color: ${appTheme.title};
  border-radius: 5px;
  align-items: center;
  padding: 10px;
  max-width: fit-content;
  cursor: pointer;
`;

export const NewTextButtonText = styled.p`
  color: ${appTheme.background};
  font-weight: 500;
  text-transform: uppercase;
`;

export const NewTestContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: unset;
`;

export const LinkButtonText = styled.p`
  text-decoration: underline;
  color: ${appTheme.title};
  font-size: 13px;
  margin-left: 5px;
  text-decoration-color: white;
`;

export const ResultsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const ResultsHeader = styled.div`
  width: 100%;
  background-color: ${appTheme.cardB2};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 1px;
`;

interface HeaderContainerProps {
  width: number;
  alignment?: 'flex-start'| 'center' | 'flex-end';
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  width: ${(props) => `${props.width}%`};
  align-items: ${(props) => (props.alignment ? props.alignment : 'flex-start')};
`;

export const HeaderText = styled.p`
  color: ${appTheme.title};
  text-transform: uppercase;
  font-weight: 600;
`;

export const ResultsValues = styled.div`
  width: 100%;
  background-color: ${appTheme.cardB2};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

interface ValuesContainerProps {
  width: number;
  alignment?: 'flex-start'| 'center' | 'flex-end';
}

export const ValuesContainer = styled.div<ValuesContainerProps>`
  display: flex;
  width: ${(props) => `${props.width}%`};
  justify-content: ${(props) => (props.alignment ? props.alignment : 'flex-start')};
  align-items: center;
`;

export const ValuesText = styled.p`
  color: ${appTheme.title};
  font-size: 13px;
  margin-right: 5px;
`;

export const IconButton = styled.button`
  margin-left: 8px;
  cursor: pointer;
  background-color: unset;
`;

export const ResultsTitle = styled.p`
  color: ${appTheme.title};
  margin-left: 5px;
  margin-bottom: 10px;
`;

export const TextHeader = styled.p`
  color: ${appTheme.title};
`;

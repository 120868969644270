import styled from 'styled-components';
import theme from '../../theme';

interface TabTitleProps {
  selected: boolean
}

export const Container = styled.div`
    display: flex;
    padding: 2%;
    background: ${theme.colors.primary2};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Title = styled.span`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-family: 'Lato';
  font-style: normal;
  font-size: 15px;
  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const Tabs = styled.div`
    display: flex;  
    justify-content: space-between;
    width: 100%;
`;

export const ContainerTab = styled.div`
    position: relative;
    margin-bottom: 19px;
    
`;

export const Tab = styled.button<TabTitleProps>`
    position: relative;
    justify-content: center;
    min-width: 90px;
    padding: 10px;
    display: flex;
    flex-direction: column; 
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    background-color: ${theme.colors.cardB2};
    ${(props) => (props.selected
    ? 'border: solid 1px #1B63F2;'
    : 'border: solid 1px transparent;')}
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);

     @media (min-width: 670px) {
     min-width: 156px;
     height: 110px;
  }
`;

export const TabTitle = styled.span`
    font-size: 15px;
    color: ${theme.colors.titleCard};
     @media (max-width: 670px) {
    font-size: 10px ;
  }
`;

export const TabTitleSub = styled.span`
    font-size: 14px;
    color: ${theme.colors.title};
     @media (max-width: 670px) {
    font-size: 9px ;
  }
`;

export const TabValue = styled.span`
    font-size: 30px;
    color: ${theme.colors.text};
    font-weight: 900;
     @media (max-width: 670px) {
    font-size: 15px ;
  }
`;

export const MainContainer = styled.div`
    width: 100%;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
`;

export const ContainerSVG = styled.div`
    position: absolute;
    bottom: -26px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 9;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: block;
  background: ${theme.colors.cardB1};

  padding-bottom: 0px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 13px;

  svg {
    cursor: pointer;
  }

  @media (max-width: 667px) {
    margin-bottom: 5px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const Title = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  padding-right: 5px;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: ${theme.colors.primary2};
  border: none;
  padding-top: 5px;
  margin-right: 0.2rem;
  cursor: pointer;
`;

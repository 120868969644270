import React, { useEffect, useState } from 'react';

import { ContainerModal, Content, ModalBase } from './styles';

interface CounterModalI {
  isVisible: boolean,
  title: string,
  onFinishCount: () => void
}

function CounterModal({
  isVisible, title, onFinishCount,
} : CounterModalI) {
  const [time, setTime] = useState<number>(3);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  const clearCount = () => {
    clearInterval(intervalId!);
  };

  const playCount = () => {
    let count = 3;
    const interval = setInterval(() => {
      count--;
      setTime(count);
      if (count === 0) {
        clearCount();
        onFinishCount();
      }
    }, 1000);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (isVisible) {
      setTime(3);
      playCount();
    } else {
      clearCount();
    }
  }, [isVisible]);

  return (
    <>
      {
        isVisible
        && (
          <ModalBase data-testid="counter-modal">
            <ContainerModal>
              <Content>
                <h2>
                  PREPARAR!
                </h2>
                <p>
                  {title}
                </p>
                <p>{time}</p>
              </Content>
            </ContainerModal>
          </ModalBase>
        )
      }
    </>
  );
}

export default CounterModal;

import React from 'react';
import { useSelector } from 'react-redux';
import { BsCheckCircleFill } from 'react-icons/bs';
import { format } from 'date-fns';
import { RootState } from '../../store';
import {
  Card,
  InfoContainer,
  Title,
  RaceText,
  CouponButton,
  TextIconButton,
  CouponCap,
  Container,
  Inform,
  InformContent,
  RaceInfo,
  RaceInfoContainer,
  CouponCapEffect,
  LinkButtonText,
} from './styles';
import RaceCouponModal from '../RaceCouponModal';
import RaceWithoutCouponModal from '../RaceWithoutCouponModal';
import RaceModalLogin from '../LoginRaceCouponModal';
import RaceCalendarContext from '../../pages/RaceCalendar/Context/RaceCalendarContext';
import { ICoupon, IRace } from '../../store/RaceCalendar.store';

export interface StateCalendarCardProps {
  race: IRace;
  city: string;
  state: string;
  kilometers: string;
  structure: boolean;
  coupons: ICoupon[];
  url?: string;
}

export default function StateCalendarCard({
  race,
  city,
  state,
  kilometers,
  structure,
  coupons,
  url,
}: StateCalendarCardProps) {
  const token = useSelector((state: RootState) => state.auth.token);
  const { visitorDataSaved, SendRDEvent } = React.useContext(RaceCalendarContext).rdTracking;
  const [showCouponModal, setShowCouponModal] = React.useState(false);

  const getGoogleUrl = () => {
    const name = race.name.replaceAll(' ', '+');
    const local = city.replaceAll(' ', '+');
    const data = format(new Date(race.date), 'dd/MM/yyyy');
    return `https://www.google.com.br/search?q=${name}+${local}+inscricao+${data}`;
  };

  const goUrl = () => {
    if (url) {
      window.open(url, '_blank')?.focus();
    } else {
      const url = getGoogleUrl();

      window.open(url, '_blank')?.focus();
    }
  };

  const toggleCouponModal = () => {
    setShowCouponModal((isOpen) => !isOpen);
    SendRDEvent(race);
  };
  return (
    <>
      <Card>
        <InfoContainer>
          <Title>{race.name}</Title>
          <RaceInfo>
            <RaceInfoContainer>
              <RaceText>{`${city}/${state}`}</RaceText>
              <RaceText>{kilometers}</RaceText>
              {url && <LinkButtonText onClick={() => goUrl()}>Ir para o site da prova</LinkButtonText>}
              {!url && <LinkButtonText onClick={() => goUrl()}>Localize mais informações</LinkButtonText>}
            </RaceInfoContainer>
            {structure === true ? (
              <InformContent>
                <BsCheckCircleFill size={28} color="#00FF40" />
                <Inform>{!token ? 'Presença Corrida Perfeita' : 'Tenda do CP na prova!'}</Inform>
              </InformContent>
            ) : null}
          </RaceInfo>
        </InfoContainer>
        <Container>
          {coupons.length > 0 && (
            <CouponButton onClick={toggleCouponModal}>
              <CouponCap>VERIFICAR CUPOM DE DESCONTO</CouponCap>
              <CouponCapEffect />
              <TextIconButton>TA10</TextIconButton>
            </CouponButton>
          )}
        </Container>
      </Card>

      {(showCouponModal && !visitorDataSaved && !token && (
        <>
          <RaceModalLogin
            isOpen={showCouponModal}
            setIsVisible={setShowCouponModal}
            race={race}
          />
        </>
      ))
        || (
          <>
            {(coupons.length > 0 && (
              <>
                <RaceCouponModal
                  isOpen={showCouponModal}
                  toggle={toggleCouponModal}
                  coupons={coupons}
                  race={race}
                  localOfRaces={`${city}/${state}`}
                  kilometers={kilometers}
                />
              </>
            ))
              || (
                <>
                  <RaceWithoutCouponModal
                    isOpen={showCouponModal}
                    toggle={toggleCouponModal}
                    race={race}
                    localOfRaces={`${city}/${state}`}
                    kilometers={kilometers}
                  />
                </>
              )}
          </>
        )}
    </>
  );
}

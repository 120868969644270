import React from 'react';

import { HiChevronRight } from 'react-icons/hi';
import theme from '../../theme';
import {
  Label, NextExercise,
} from './styles';

function NextTrainingButton({ currentActivity, nextActivity, onPress } : any) {
  const formatLabel = () => {
    let label = null;
    if (!nextActivity) {
      label = 'FINALIZAR SÉRIE';
    } else if (nextActivity?.repetition) {
      label = `PRÓXIMO: ${nextActivity?.repetition!}X ${nextActivity?.name?.toUpperCase()}`;
    } else if (nextActivity?.duration) {
      label = `PRÓXIMO: ${Math.round(nextActivity?.duration * 60)}SEG ${nextActivity?.name?.toUpperCase()}`;
    }

    return label;
  };

  return (
    <>
      {currentActivity?.repetition
        ? (
          <NextExercise>
            <button type="button" onClick={onPress}>
              {formatLabel()}
              <HiChevronRight color={theme.colors.title} size={28} />
            </button>
          </NextExercise>
        ) : (
          <Label>{formatLabel()}</Label>
        )}
    </>
  );
}

export default NextTrainingButton;

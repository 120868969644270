import React from 'react';
import Spinner from './styles';

export interface LoaderPropsI extends React.HTMLProps<HTMLDivElement> {
  color?: string
}

function Loader({ color }: LoaderPropsI) {
  return (
    <Spinner color={color} />
  );
}

export default Loader;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressProfile from '../../components/ProgressProfile';
import RegularityLevel from '../../components/RegularityLevel';
import RegularityLevelProgress from '../../components/RegularityLevelProgress';
import WeekCardProgress from '../../components/WeekCardProgress';
import ProgressAccumulatedDistance from '../../components/ProgressAccumulatedDistance';
import useNavbar from '../../hooks/useNavbar';
import {
  Button,
  Container, ContainerProfile, Separator, TextButton,
} from './styles';
import ProgressCalendar from '../../components/ProgressCalendar';
import api from '../../services/api';

export default function Progress() {
  const { setSelected } = useNavbar();
  const [buttonTitle, setButtonTitle] = useState('');
  const [available, setAvailable] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    setSelected('progress');
  }, []);

  const getRetrospectiveProps = () => {
    const today = new Date();
    const url = `month_retrospective/props?full_year=${today.getFullYear()}&month_number=${today.getMonth()}`;

    api.get(url)
      .then((response) => {
        if (response.data?.button_title) setButtonTitle(response.data.button_title);
        if (response.data?.available) setAvailable(response.data.available);
      });
  };

  useEffect(() => {
    getRetrospectiveProps();
  }, []);

  return (
    <Container>
      <ContainerProfile>
        <ProgressProfile />
        <WeekCardProgress />
      </ContainerProfile>

      <RegularityLevel />
      <RegularityLevelProgress />

      {
        available
        && (
          <Button onClick={() => navigation('/retrospective')}>
            <TextButton>{buttonTitle || ''}</TextButton>
          </Button>
        )
      }

      <Separator />

      <ProgressAccumulatedDistance />

      <Separator />

      <ProgressCalendar />
    </Container>
  );
}

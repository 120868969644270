import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  width: 100%;
`;

export const Content = styled.section`
  flex: 1;
  background: ${theme.colors.cardB1};
  padding: 1%;
`;

import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import BackArrow from '../../components/BackArrow';
import {
  Container, HeaderContainer, ButtonContainer, Button, TextButton, ModalText, ItemsContainer,
} from './styles';
import BaseModal from '../../components/BaseModal';
import IndicationCoupon from '../../components/IndicationCoupon';
import PointsSummary from '../../components/PointsSummary';
import RedeemablePrizes from '../../components/RedeemablePrizes';
import IndicationOnboard from '../../components/IndicationOnboard';
import api from '../../services/api';
import RedeemProductContext from '../RedeemProduct/RedeemProductContext';

const fetchUserCouponData = async () => {
  const response = await api.get('/referrals/get_code');
  return response.data;
};

const createCouponCode = async () => {
  try {
    const response = await api.get('/referrals/create_code');
    return response.data[0].code;
  } catch (error) {
    return toast.error('Houve um erro ao gerar seu cupom.');
  }
};

export default function IndicationCentral() {
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [indicationModalVisible, setIndicationModalVisible] = useState(false);
  const { coupon: userCoupon, setCoupon: setUserCoupon } = React.useContext(RedeemProductContext).userData;

  function closeModal() {
    setIndicationModalVisible(false);
  }

  const checkIndicationOnboard = React.useCallback(async () => {
    if (!userCoupon) {
      let userHasCoupon;

      try {
        const userCouponData = await fetchUserCouponData();
        userHasCoupon = userCouponData.length;

        if (userHasCoupon) {
          setUserCoupon(userCouponData[0].code);
        }
      } catch {
        setIndicationModalVisible(true);
        setUserCoupon(await createCouponCode());
      }
    }
  }, [userCoupon]);

  useEffect(() => {
    checkIndicationOnboard();
  }, []);

  return (
    <Container data-testid="indication-central">
      <IndicationOnboard visible={indicationModalVisible} onClose={closeModal} />
      <BaseModal isVisible={infoModal} setIsVisible={setInfoModal} title="VEJA COMO FUNCIONA">
        <ModalText>Nosso programa de indicações te dá a oportunidade de compartilhar sua experiência positiva no Corrida Perfeita e ainda ganhar prêmios por isso.</ModalText>
        <br />
        <ModalText>
          Para participar, basta compartilhar seu código de cupom único com as pessoas que você indicar nossos treinos. Quando alguém usar o seu cupom para se inscrever no
          Corrida Perfeita, eles ganham um desconto especial na adesão, enquanto você ganha indicações. Essas indicações podem ser trocadas por diversos prêmios,
          incluindo descontos na adesão, produtos Corrida Perfeita, serviços e muito mais.
        </ModalText>
        <br />
        <ModalText>
          O acompanhamento das indicações disponíveis e prêmios resgatáveis é feito diretamente pela nossa plataforma. Vale ressaltar que as indicações não ficam disponíveis
          imediatamente após a adesão do seu indicado. Elas só estarão disponíveis quando o período de garantia tiver passado. Se o indicado não cancelar o serviço durante esse
          período, as indicações ficarão automaticamente disponíveis para resgate em seu extrato.
        </ModalText>
        <br />
        <ModalText>
          Não perca a chance de compartilhar a experiência do Corrida Perfeita com as pessoas que você conhece e ainda ganhar prêmios por isso. Comece a falar do seu cupom de
          desconto agora com pessoas conhecidas e com potencial de treinarem corrida conosco!
        </ModalText>
        <br />
      </BaseModal>
      <HeaderContainer>
        <BackArrow text="Indique e ganhe" bigger backTo="/home" />
        <ButtonContainer>
          <Button onClick={() => setInfoModal(true)}>
            <TextButton>Veja como funciona!</TextButton>
          </Button>
        </ButtonContainer>
      </HeaderContainer>
      <ItemsContainer>
        {userCoupon && <IndicationCoupon userCoupon={userCoupon} />}
        <PointsSummary />
        <RedeemablePrizes />
      </ItemsContainer>
    </Container>
  );
}

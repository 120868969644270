import styled from 'styled-components';
import theme from '../../theme';
import { ModalContent as _ModalContent } from '../Modal/Modal.styles';

export const ModalContent = styled(_ModalContent)`
  padding: 1rem;
`;

export const ModalContentButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  
`;

export const ModalContentButton = styled.button`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 90px;
  padding: 0.5rem;

  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-color: ${theme.colors.title};
  border-width: 2px;
  border-style: solid;
  background-color: ${theme.colors.primary};

  &:first-of-type ~ & {
    margin-left: 1rem;
  }
`;

export const ModalContentButtonTextCancel = styled.span`
  color: ${theme.colors.title};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const Header = styled.span`
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
`;

export const InfoText = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 13px;
  margin-bottom: 10px;
`;

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../BaseModal';
import { BannerType } from '../../types/Banner';
import {
  ModalBackground, ModalContent, ModalContentBody, ModalContentButtonContainer, ModalContentSubTitleContainer, ModalContentText, ModalContentTitle, OutlineButton, OutlineButtonText,
} from './styles';
import { RootState } from '../../store';
import coreApi from '../../services/coreApi';
import { pageRoute } from '../HomeBanner/utils';

interface Props {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>;
  clearBanner: () => void
  banner: BannerType
}

function InfoModal({
  showModal, setShowModal, banner, clearBanner,
}: Props) {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const markAsViewed = async () => {
    await coreApi.post('/home-banner/viewed', { banner_id: banner._id, user_id: user.id })
      .catch(() => toast.error('Ocorreu um erro, tente novamente mais tarde'));
  };

  const handleCloseButton = () => {
    markAsViewed();
    clearBanner();
  };

  const handleAction = () => {
    if (banner.banner_confirm_button_type === 'URL') {
      window.location.href = banner.banner_confirm_button_action;
    } else {
      navigate(pageRoute[banner.banner_confirm_button_action]);
    }
  };

  return (
    <BaseModal isVisible={showModal} setIsVisible={setShowModal}>
      <ModalBackground>
        <ModalContent>
          <ModalContentBody>
            {
              !!banner.close_modal_title
            && (
              <ModalContentTitle>
                {banner.close_modal_title}
              </ModalContentTitle>
            )
            }

            <ModalContentSubTitleContainer>
              {
                !!banner.close_modal_description
              && (
                <ModalContentText>
                  {banner.close_modal_description}
                </ModalContentText>
              )
              }
            </ModalContentSubTitleContainer>
            <ModalContentButtonContainer>
              {!!banner.close_modal_close_button_text && (
                <OutlineButton onClick={handleAction} color="white">
                  <OutlineButtonText color="white">{banner?.close_modal_confirm_button_text}</OutlineButtonText>
                </OutlineButton>
              )}
              {!!banner.close_modal_close_button_text && (
                <OutlineButton onClick={handleCloseButton} color="white" inverse>
                  <OutlineButtonText color="white" inverse>{banner?.close_modal_close_button_text}</OutlineButtonText>
                </OutlineButton>
              )}
            </ModalContentButtonContainer>
          </ModalContentBody>
        </ModalContent>
      </ModalBackground>
    </BaseModal>
  );
}
export default InfoModal;

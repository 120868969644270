/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StyledThumb } from '../../../components/RangeSlider/styles';

function CustomThumb(props: any, state: any) {
  return (
    <StyledThumb {...props} index={state.index}>
      {state.valueNow === 42 ? '42+' : state.valueNow}
    </StyledThumb>
  );
}

export default CustomThumb;

import React from 'react';
import { Container, Menu, MenuButton } from './styles';
import homeIcon from '../../assets/home-icon.svg';
import homeIconActive from '../../assets/home-icon-active.svg';
import trainingIcon from '../../assets/training-icon.svg';
import trainingIconActive from '../../assets/training-icon-active.svg';
import progressIcon from '../../assets/progress-icon.svg';
import progressIconActive from '../../assets/progress-icon-active.svg';
import moreIcon from '../../assets/more-icon.svg';
import moreIconActive from '../../assets/more-icon-active.svg';
import useNavbar from '../../hooks/useNavbar';
import { appTheme } from '../../theme';

export default function Navbar() {
  const { selected } = useNavbar();

  return (
    <Container>
      <Menu>
        <MenuButton to="/home" style={{ color: selected === 'home' ? 'white' : appTheme.gray }}>
          <img src={selected === 'home' ? homeIconActive : homeIcon} alt="Ir para home" />
          Início
        </MenuButton>

        <MenuButton to="/training" style={{ color: selected === 'training' ? 'white' : appTheme.gray }}>
          <img src={selected === 'training' ? trainingIconActive : trainingIcon} alt="Ir para treino" />
          Treino
        </MenuButton>

        <MenuButton to="/progress" style={{ color: selected === 'progress' ? 'white' : appTheme.gray }}>
          <img src={selected === 'progress' ? progressIconActive : progressIcon} alt="Ir para progresso" />
          Progresso
        </MenuButton>

        <MenuButton to="/club" style={{ color: selected === 'club' ? 'white' : appTheme.gray }}>
          <img src={selected === 'club' ? moreIconActive : moreIcon} alt="Ir para mais" />
          Mais
        </MenuButton>
      </Menu>
    </Container>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

export const ModalBase = styled.div`
    background: rgba(255, 255, 255, 0.3);
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    left: 0;
`;

export const ContainerModal = styled.div`
    background-color: ${theme.colors.cardB1};
    height: '30%';
    display: flex;
    flex-direction: column;    
    justify-content: center;
    padding: 20px;
    width: 400px;
    max-width: 100%;
    @media (min-width: 768px) { 
        padding: 40px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;    
    margin-bottom: 15px;
    
    h2{
        color: ${theme.colors.title};
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;      
        @media (max-width: 768px) { 
            font-size: 12px;
        }
    }
    p{
        color: ${theme.colors.title};
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        margin-bottom: 15px;
        @media (max-width: 768px) { 
            font-size: 18px;
        }

        &:last-of-type{
            font-size:30px;
        }
    }
    h3{
        color: ${theme.colors.title};
        font-size: 20px;
        font-weight: bold;        
        @media (max-width: 768px) { 
            font-size: 24px;
        }
    }
`;

import React from 'react';
import ListItem from '../ListItem';
import { ListContainer } from './styles';
import { IZone } from '..';

import { appTheme } from '../../../theme';

const defaultZones = [
  {
    id: 0,
    name: 'Zona 1',
    video: 'https://player.vimeo.com/video/390965364',
    description: 'Esforço leve',
    color: appTheme.zone1,
  },
  {
    id: 1,
    name: 'Zona 2',
    video: 'https://player.vimeo.com/video/390966046',
    description: 'Esforço moderado',
    color: appTheme.zone2,
  },
  {
    id: 2,
    name: 'Zona 3',
    video: 'https://player.vimeo.com/video/390966447',
    description: 'Um pouco difícil',
    color: appTheme.zone3,
  },
  {
    id: 3,
    name: 'Zona 4',
    video: 'https://player.vimeo.com/video/390966994',
    description: 'Difícil',
    color: appTheme.zone4,
  },
  {
    id: 4,
    name: 'Zona 5A',
    video: 'https://player.vimeo.com/video/390967333',
    description: 'Muito difícil',
    color: appTheme.zone5a,
  },
  {
    id: 5,
    name: 'Zona 5B',
    video: 'https://player.vimeo.com/video/390967333',
    description: 'Extremamente difícil',
    color: appTheme.zone5b,
  },
  {
    id: 6,
    name: 'Zona 5C',
    video: 'https://player.vimeo.com/video/390967333',
    description: 'Limite máximo de esforço',
    color: appTheme.zone5c,
  },
];

export interface ListProps {
  zones?: Array<IZone>;
  pace: boolean;
}

function List({ zones, pace }: ListProps) {
  return (
    <>
      <ListContainer>
        {zones
          ? zones.map((zone, index) => (
            <ListItem
              zone={zone}
              key={zone.id}
              index={index}
              color={defaultZones[zone.order - 1].color}
              pace={pace}
            />
          ))
          : defaultZones.map((zone, index) => (
            <ListItem zone={zone} key={zone.id} index={index} pace={pace} color={zone.color} />))}
      </ListContainer>
    </>
  );
}

export default List;

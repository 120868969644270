import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  width: 100%;
  background-color: ${appTheme.cardB1};
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  background: ${appTheme.grayL4};
  margin: 5px;
  border-radius: 30px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActivityContainer = styled.div`
  flex: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${appTheme.cardB2};
  flex-direction: column;
  margin-top: 10px;
  padding-top: 8px;
  margin-bottom: 10px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const UserContainer = styled.div`
  flex-direction: column;
`;

export const UserName = styled.p`
  color: ${appTheme.title};
`;

export const DateText = styled.p`
  color: ${appTheme.text};
  font-size: 11px;
`;

export const ActivityTitle = styled.p`
  color: ${appTheme.title};
  text-transform: uppercase;
  font-weight: 600; 
  width: 70%;
  padding-left: 5px;
`;

export const NavigationTitle = styled.p`
  color: ${appTheme.title};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600; 
  margin-left: 5px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${appTheme.cardB2};
  padding: 5px;
  align-items: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
`;

export const IconButton = styled.button`
  background-color: unset;
  padding: 5px;
  cursor: pointer;
  border: unset;
`;

export const InfoContainer = styled.div`
  background-color: ${appTheme.cardB2};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Separator = styled.div`
  background-color: ${appTheme.background};
  width: 100%;
  height: 2px;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding: 10px;
`;

export const InfoText = styled.p`
  color: ${appTheme.title};
`;

export const AttachShoesInfoButton = styled.button`
  color: ${appTheme.zone4};
  background-color: ${appTheme.background};
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const DetachShoesInfoButton = styled.button`
  color: ${appTheme.title};
  background-color: ${appTheme.background};
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetachShoesInfoButtonTitle = styled.span`
  margin-left: 5px;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 410px;
  background-color: ${appTheme.cardB2};
`;

export const CloseContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 5px;
  padding-top: 3px;
`;

export const TrainingContainer = styled.div`
  padding: 5px;
  margin-bottom: 20px;
`;

export const TrainingLabel = styled.p`
  color: ${appTheme.title};
  font-weight: 600;
  margin-bottom: 10px;
  max-width: 40%;
`;

interface AssociationContainerProps {
  multiply? : number | null;
}

export const AssociationContainer = styled.div<AssociationContainerProps>`
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items:  center;
  background-color: ${appTheme.cardB2};
  padding-left: 10px;
  padding-right: 10px;
`;

export const DropdownContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

export const OutContainer = styled.div`
  border-radius: 10px;
  background-color: ${appTheme.cardB2};
`;

export const AssociationLabel = styled.p`
  color: ${appTheme.title};
  align-self: center;
`;

export const AnalysisButton = styled.button`
  width: 175px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: solid 2px;
  border-color: ${appTheme.title};
  background-color: unset;
  cursor: pointer;
`;

export const AnalysisTextButton = styled.p`
  color: ${appTheme.title};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
`;

export const ExportContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ExportButton = styled.button`
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
`;

export const ExportButtonTitle = styled.span`
  font-size: 16px;
  color: ${appTheme.title};
  margin-left: 5px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import React, {
  useCallback,
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';

import api from '../services/api';

import { formatDuration } from '../utils';

import { getActivities, resetPage } from '../store/Activities.store';
import { RootState } from '../store';

export interface IRegisterActivityControl {
  visible: boolean;
  close: Function;
  edit?: boolean;
  refresh?: Function;
  activity?: any;
  setActivity?: Function;
  trainingId?: number | null;
  clearStatesOnClose?: boolean;
  _type?: string;
  _title?: string;
  _date?: string;
  submitCallback?: () => void;
}

interface IDistance {
  kilometers: number;
  meters: number;
}

interface IDuration {
  hours: number;
  minutes: number;
  seconds: number;
}

interface IPace {
  minutes: number;
  seconds: number;
}

export interface IRegisterFormData {
  altimetry: string;
  average_cadence: string;
  average_heartrate: string;
  average_speed: string;
  calorie: string;
  comments: string;
  distance: string;
  duration: string;
  max_heartrate: string;
  pace: string;
  start_date: string;
  start_hour: string;
  title: string;
  type: string;
}

const types = [
  { label: 'Corrida', value: 'Run' },
  { label: 'Bicicleta', value: 'Ride' },
  { label: 'Natação', value: 'Swim' },
  { label: 'Força', value: 'WeightTraining' },
  { label: 'Educativo', value: 'Drills' },
  { label: 'Caminhada', value: 'Walk' },
  { label: 'Treino', value: 'Workout' },
  { label: 'Outros', value: 'Others' },
];

export default function useRegisterActivityController(
  {
    close,
    refresh,
    activity,
    edit = false,
    setActivity,
    trainingId = null,
    clearStatesOnClose = true,
    _type = 'Run',
    _title,
    _date,
    submitCallback,
  }: IRegisterActivityControl,
) {
  const auth = useSelector((state : RootState) => state.auth);
  const { user } = auth;
  const dispatch = useDispatch();

  const header = edit ? 'Editar Atividade' : 'Registrar Atividade';
  const buttonText = edit ? 'Salvar' : 'Salvar';

  const [loading, setLoading] = useState<boolean>(false);

  // #region Form inputs states:
  const [activityType, setActivityType] = useState<any>(_type);
  const [title, setTitle] = useState<string>(_title || '');
  const [date, setDate] = useState(_date ? new Date(_date) : new Date());
  const [time, setTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [duration, setDuration] = useState<IDuration>();
  const [distance, setDistance] = useState<IDistance>({
    kilometers: 0,
    meters: 0,
  });
  const [distanceString, setDistanceString] = useState('');
  const [pace, setPace] = useState<IPace>({ minutes: 0, seconds: 0 });
  const [avgSpeed, setAvgSpeed] = useState<string>('');
  const [avgHeartRate, setAvgHeartRate] = useState<string>('');
  const [maxHeartRate, setMaxHeartRate] = useState<string>('');
  const [elevation, setElevation] = useState<string>('');
  const [cadence, setCadence] = useState<string>('');
  const [calories, setCalories] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  // #endregion

  // #region Form error states:
  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [durationError, setDurationError] = useState<boolean>(false);
  // #endregion

  // #region Validation:
  function validateHhMm(value: string) {
    return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value);
  }

  function validDuration(duration: IDuration): boolean {
    return ((!!duration.hours || !!duration.minutes || !!duration.seconds) && duration.minutes <= 59 && duration.seconds <= 59);
  }

  function validDistance(distance: IDistance): boolean {
    return (!!distance.kilometers || !!distance.meters);
  }

  const formIsValid = useMemo(() => {
    if (!date) {
      setDateError(true);
      return false;
    }
    if (!time || !validateHhMm(time)) {
      setTimeError(true);
      return false;
    }
    if (duration && !validDuration(duration)) {
      setDurationError(true);
      return false;
    }
    return true;
  }, [date, time, duration]);

  // #endregion

  // #region Data manipulation:
  function sanitizeMaskedString(value: string) {
    let stringValue = value;
    stringValue = stringValue.replaceAll(',', '.');
    stringValue = stringValue.replaceAll('_', '');
    stringValue = stringValue.replaceAll('', '');
    stringValue = stringValue.replaceAll('BPM', '');
    stringValue = stringValue.replaceAll('Kcal', '');
    stringValue = stringValue.replaceAll('km/h', '');
    stringValue = stringValue.replaceAll('m', '');
    stringValue = stringValue.replaceAll('m', '');

    return stringValue;
  }

  const toNumber = (text: string) => {
    const number = Number(text);

    if (number) return number;
    return null;
  };

  const toString = (number: any) => {
    if (number) return `${number}`;
    return '';
  };

  const getDateTimeFormated = (date: string, time: string) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const [_, day, month, year] = regex.exec(date) ?? [];

    // eslint-disable-next-line radix
    const cloneDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    cloneDate.setHours(parseInt(time.slice(0, 2), 10));

    const formatedData = format(cloneDate, "yyyy-MM-dd'T'HH:mm:ss");

    return formatedData;
  };
  // #endregion

  // #region Form behavior:
  const applyPace = (duration: IDuration, distance: IDistance) => {
    const parsedDistance = parseFloat(`${distance?.kilometers}.${distance?.meters}`);
    const parsedDuration = (parseFloat(`${duration?.hours}`) * 60) + parseFloat(`${duration?.minutes}`) + (parseFloat(`${duration?.seconds}`) / 60);
    const paceCalculated = parsedDuration / parsedDistance;
    const newPace = formatDuration(paceCalculated, 'pace');
    const paceValues = newPace?.toString()?.split(':');

    setPace({ minutes: parseFloat(paceValues[0]!), seconds: parseFloat(paceValues[1]!) });
  };

  const clearAllStates = () => {
    setTime(format(new Date(), 'HH:mm'));
    setActivityType('Run');
    setDate(new Date());
    setDuration({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    setDistance({
      kilometers: 0,
      meters: 0,
    });
    setPace({ minutes: 0, seconds: 0 });
    setAvgSpeed('');
    setAvgHeartRate('');
    setMaxHeartRate('');
    setElevation('');
    setCadence('');
    setCalories('');
    setComments('');
    setTitle('');
    setTimeError(false);
    setDateError(false);
    setDurationError(false);
  };

  const closeModal = () => {
    if (clearStatesOnClose) clearAllStates();
    close();
  };

  const _setTime = (valueTime: string) => {
    setTime(valueTime);
    setTimeError(false);
  };
  // #endregion

  // #region Duration:
  function stringDurationToIDuration(value: string): IDuration {
    const stringDurationParts = value.replaceAll('_', '0').split(':');

    const durationIDuration: IDuration = {
      hours: parseInt(stringDurationParts[0], 10),
      minutes: parseInt(stringDurationParts[1], 10),
      seconds: parseInt(stringDurationParts[2], 10),
    };

    return durationIDuration;
  }

  const calcDuration = (duration: IDuration) => {
    const { hours, minutes, seconds } = duration;
    const formatedDuration = ((hours * 60) + minutes + (seconds ? seconds / 60 : 0)).toFixed(2);
    return formatedDuration;
  };

  const _setDuration = (valueDuration: string) => {
    setDuration(stringDurationToIDuration(valueDuration));
    setDurationError(false);
  };

  function durationInstanceToString(duration: IDuration | undefined): string {
    if (!duration) return '';

    const durationStringParts = Object.values(duration)
      .map((durationValue) => durationValue.toString().padStart(2, '0'));
    return `${durationStringParts[0]}:${durationStringParts[1]}:${durationStringParts[2]}`;
  }

  const setDurationAndApplyPace = (value: string) => {
    _setDuration(value);

    const durationInstance = stringDurationToIDuration(value);

    if (validDistance(distance) && validDuration(durationInstance)) {
      applyPace(durationInstance, distance);
    }
  };
  // #endregion

  // #region Distance:
  const distanceToObj = (activityDistance: Number) => {
    if (activityDistance) {
      const [kilometers, meters] = activityDistance.toString()
        .split('.').map((num) => parseInt(num, 10));

      return {
        kilometers: kilometers || 0,
        meters: meters || 0,
      };
    }
    return null;
  };

  const formatDistance = useCallback(() => {
    if (distance) {
      return toNumber(`${distance.kilometers}.${distance.meters}`);
    }
    return null;
  }, [distance]);

  function stringDistanceToIDistance(value: string): IDistance {
    const splitedStrings = value.split('.');

    const distanceInstance: IDistance = {
      kilometers: parseInt(splitedStrings[0], 10) || 0,
      meters: parseInt(splitedStrings[1], 10) || 0,
    };

    return distanceInstance;
  }
  // #endregion

  // #region Pace:
  function sanitizePaceMasked(value: string): string {
    const stringPace = value;
    stringPace.replaceAll('/km', '');
    stringPace.replaceAll('_', '');

    return stringPace;
  }

  function stringPaceToIPace(value: string): IPace {
    const stringPaceParts = sanitizePaceMasked(value).split(':');

    const paceIPace: IPace = {
      minutes: parseInt(stringPaceParts[0], 10),
      seconds: parseInt(stringPaceParts[1], 10),
    };

    return paceIPace;
  }

  function paceInstanceToString(pace: IPace): string {
    const paceStringParts = Object.values(pace)
      .map((paceValue) => paceValue.toString().padStart(2, '0'));

    return `${paceStringParts[0]}:${paceStringParts[1]}`;
  }

  const formatPace = (pace: IPace) => (pace.minutes + (pace.seconds / 60)).toFixed(2);

  const setPaceAndApplyDistance = (value: string) => {
    const paceInstance = stringPaceToIPace(value);
    setPace(paceInstance);

    if (duration && (!!duration.hours || !!duration.minutes || !!duration.seconds)) {
      const parsedDuration = (parseFloat(`${duration?.hours}`) * 60) + parseFloat(`${duration?.minutes}`) + (parseFloat(`${duration?.seconds}`) / 60);
      const parsedPace = parseFloat(`${paceInstance?.minutes}`) + (parseFloat(`${paceInstance?.seconds}`) / 60);
      const calculatedDistance = (parsedDuration / parsedPace)?.toFixed(1);
      const distanceValues = calculatedDistance?.split('.');

      setDistance({ kilometers: parseFloat(distanceValues[0]!), meters: parseFloat(distanceValues[1]!) });
    }
  };

  const setDistanceAndApplyPace = (value: string) => {
    const treatedString = value.split('.');

    if (treatedString.length > 1) {
      setDistanceString(`${treatedString[0]}.${treatedString[1].slice(0, 3)}`);
    } else {
      setDistanceString(value);
    }

    const distanceInstance = stringDistanceToIDistance(value);
    setDistance(stringDistanceToIDistance(value));

    if (duration && validDistance(distanceInstance) && validDuration(duration)) {
      applyPace(duration, distanceInstance);
    }
  };
  // #endregion

  // #region Activities:
  const loadActivity = () => {
    if (activity) {
      const activityDate = new Date(activity.start_date);
      const activityTime = `${activityDate.getHours().toString().padStart(2, '0')}:${activityDate.getMinutes().toString().padStart(2, '0')}`;
      const durationTime = activity.duration ? formatDuration(activity.duration, 'edit') : null;
      const activityDistance = activity.distance ? distanceToObj(activity.distance) : null;
      const activityPace = activity.pace ? formatDuration(activity.pace, 'editPace') : null;
      setTitle(activity.title);
      setActivityType(activity.type);
      setDate(activityDate);
      setTime(activityTime);
      setDuration(() => {
        if (typeof durationTime === 'string') {
          return stringDurationToIDuration(durationTime);
        }
        return (
          {
            hours: durationTime?.hours || 0,
            minutes: durationTime?.minutes || 0,
            seconds: durationTime?.seconds || 0,
          }
        );
      });
      setDistance({
        kilometers: activityDistance?.kilometers || 0,
        meters: activityDistance?.meters || 0,
      });
      setPace(() => {
        if (typeof activityPace === 'string') {
          return stringPaceToIPace(activityPace);
        }
        return (
          {
            minutes: activityPace?.minutes || 0,
            seconds: activityPace?.seconds || 0,
          }
        );
      });
      setCalories(toString(activity.calorie));
      setElevation(toString(activity.altimetry));
      setAvgSpeed(toString(activity.average_speed));
      setAvgHeartRate(toString(activity.average_heartrate));
      setMaxHeartRate(toString(activity.max_heartrate));
      setComments(activity.comments);
      setCadence(toString(activity.average_cadence));
    }
  };

  const sanitizeFormData = (formData: IRegisterFormData) => {
    if (formIsValid) {
      const newActivity = {
        ...formData,
        user_id: user.id,
        distance: Number(formData.distance?.replace(' km', '') || 0),
        pace: formatPace(
          {
            minutes: Number(formData.pace?.replace(' /Km', '').split(':')[0] || 0),
            seconds: Number(formData.pace?.replace(' /Km', '').split(':')[1]) || 0,
          },
        ),
        duration: calcDuration(
          {
            hours: Number(formData.duration.split(':')[0]),
            minutes: Number(formData.duration.split(':')[1]),
            seconds: Number(formData.duration.split(':')[2]),
          },
        ),
        start_date: getDateTimeFormated(formData.start_date, formData.start_hour),
        max_speed: null,
        altimetry: Number(formData.altimetry) || 0,
        average_heartrate: Number(formData.average_heartrate) || 0,
        max_heartrate: Number(formData.max_heartrate) || 0,
        average_speed: Number(formData.average_speed) || 0,
        average_cadence: Number(formData.average_cadence) || 0,
        calorie: Number(formData.calorie) || 0,
        training_id: trainingId,
      };
      return newActivity;
    }
    return null;
  };

  const updateActivityList = () => {
    dispatch(resetPage());
    dispatch(getActivities(user.id) as any);
  };
  // #endregion

  // #region Other behaviors:
  useEffect(() => {
    if (activity) loadActivity();
  }, []);

  useLayoutEffect(() => {
    setActivityType(_type);
  }, [_type]);

  const send = useCallback((formData: IRegisterFormData) => {
    if (loading) return;
    setLoading(true);
    if (edit) {
      const updatedActivity = sanitizeFormData(formData);
      if (updatedActivity) {
        api.put(`user_activities/${activity.id}`, updatedActivity)
          .then((response) => {
            if (setActivity) setActivity(response.data);
            setLoading(false);
            close();
            updateActivityList();
          })
          .catch(() => {
            toast.error('Erro ao atualizar a atividade, tente novamente.');
            setLoading(false);
          });
      }
    } else {
      const newActivity = sanitizeFormData(formData);

      if (newActivity) {
        api.post('user_activities', newActivity)
          .then(() => {
            setLoading(false);
            setTimeout(() => closeModal(), 500);
            if (refresh) refresh();
            updateActivityList();
            submitCallback?.();
          })
          .catch(() => {
            toast.error('Erro ao criar a atividade, tente novamente.');
            setLoading(false);
          });
      }
    }

    setLoading(false);
  }, [loading, edit, sanitizeFormData, setActivity, close, refresh]);
  // #endregion

  return {
    loading,
    setLoading,
    activityType,
    setActivityType,
    title,
    setTitle,
    date,
    setDate,
    time,
    setTime,
    duration,
    setDuration,
    setDistance,
    pace,
    setPace,
    avgSpeed,
    setAvgSpeed,
    avgHeartRate,
    setAvgHeartRate,
    maxHeartRate,
    setMaxHeartRate,
    elevation,
    setElevation,
    cadence,
    setCadence,
    calories,
    setCalories,
    comments,
    setComments,
    dateError,
    setDateError,
    timeError,
    setTimeError,
    durationError,
    setDurationError,
    stringDurationToIDuration,
    sanitizePaceMasked,
    stringPaceToIPace,
    paceInstanceToString,
    header,
    buttonText,
    types,
    clearAllStates,
    closeModal,
    sanitizeMaskedString,
    toNumber,
    toString,
    distanceToObj,
    getDateTimeFormated,
    formatPace,
    formatDistance,
    calcDuration,
    _setTime,
    _setDuration,
    validDistance,
    validDuration,
    validateHhMm,
    formIsValid,
    loadActivity,
    sanitizeFormData,
    updateActivityList,
    send,
    applyPace,
    stringDistanceToIDistance,
    setDistanceAndApplyPace,
    durationInstanceToString,
    setDurationAndApplyPace,
    setPaceAndApplyDistance,
    distanceString,
  };
}

import FortificationCover from '../assets/Training/explorar-fortalecimento.jpg';
import LiveCover from '../assets/Training/explorar-ao-vivo.jpg';
import EducationalCover from '../assets/Training/explorar-educativos.jpg';
import StretchingCover from '../assets/Training/explorar-alongamento.jpg';
import CardioCover from '../assets/Training/explorar-funcional-cardio.jpg';
import freeUserRedirectLinks from './freeUserRedirectLinks';

export const avaliableExercisesCategories = [
  {
    id: 0,
    source: FortificationCover,
    title: 'TREINOS DE FORTALECIMENTO',
    description: 'Séries para você ter músculos bem preparados para a corrida.',
    type: 'WeightTraining',
    blockForFree: false,
    freeUserRedirect: freeUserRedirectLinks.weightTraining,
  },
  {
    id: 1,
    source: EducationalCover,
    title: 'TREINOS EDUCATIVOS',
    description: 'Os exercícios que vão melhorar sua maneira de correr.',
    type: 'Drills',
    blockForFree: false,
    freeUserRedirect: freeUserRedirectLinks.drills,
  },
  {
    id: 2,
    source: LiveCover,
    title: 'TREINOS COLETIVOS AO VIVO',
    description: 'Faça nossos exercícios ao vivo, com acompanhamento em tempo real do treinador.',
    type: 'LIVE_LECTURE',
    blockForFree: true,
    freeUserRedirect: freeUserRedirectLinks.liveLecture,
  },
  {
    id: 3,
    source: StretchingCover,
    title: 'TREINOS COMPLEMENTARES',
    description: 'Alongamento, Fortalecimento para pés, Recuperação...',
    type: 'Others',
    blockForFree: true,
    freeUserRedirect: freeUserRedirectLinks.others,
  },
  {
    id: 4,
    source: CardioCover,
    title: 'FUNCIONAIS CARDIO',
    description: 'Séries sem equipamentos, para você fazer nos dias em que não puder correr.',
    type: 'Cardio',
    blockForFree: true,
    freeUserRedirect: freeUserRedirectLinks.cardio,
  },
];

export const OnboardSlides = {
  WeightTraining: {
    title: 'FORTALECIMENTO',
    link: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001152879-como-fazer-as-s%C3%A9ries-de-fortalecimento-do-corrida-perfeita',
    pages: [
      [
        'Aqui você acessa as séries de exercícios de fortalecimento específicos para a corrida do nosso método.',
        'Você incluirá em sua rotina exercícios simples de serem realizados e que são voltados para: a melhora dos movimentos e postura na corrida, para mais desempenho, para menos desgaste e menos lesões e dores.',
        'Antes de conhecer ou executar as séries de fortalecimento, é muito importante que você confira nossas orientações de execução, para que você não venha a ter problemas.',
      ],
      [
        'Importante: Antes de iniciar alguma das séries de fortalecimento que serão abertas a seguir, certifique-se de que conferiu as importantes orientações de execução. ',
        'Caso já tenha conferido, apenas finalize',
      ],
    ],
  },
  Cardio: {
    title: 'TREINOS FUNCIONAIS CARDIO',
    link: null,
    pages: [
      [
        'Aqui oferecemos sessões de treinos funcionais com mais intensidade, que podem ser usados para substituir uma corrida, quando você não puder correr.',
        'Nos passos seguintes, temos rápidas orientações sobre a execução desses treinos.',
      ],
      [
        'Recomendamos começar pelas sessões de nível inicial, e conforme sua percepção de esforço, ou necessidade, avance ou varie os níveis.',
        'Numa substituição eventual de um treino de corrida por nós prescrito, recomendamos a seguinte estratégia:',
        '- TREINOS LONGOS: Substituir por 2 (duas) sessões seguidas de um nível inicial.',
        '- TREINOS INTERVALADOS: Substituir por 1 (uma) ou 2 (duas) sessões seguidas de nível intermediário ou avançado.',
        '- TREINOS CONTÍNUOS MAIS CURTOS: Substituir por 1(uma) sessão de qualquer nível.',
        'Em caso de dúvidas, entre em contato conosco nas opções de atendimento e ajuda.',
      ],
    ],
  },
  Drills: {
    title: 'EXERCÍCIOS EDUCATIVOS',
    link: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001152887-como-fazer-as-s%C3%A9ries-de-exerc%C3%ADcios-educativos-do-corrida-perfeita',
    pages: [
      [
        'Aqui você acessa as séries de exercícios educativos de corrida do nosso método.',
        'Eles vão atuar diretamente na melhora natural de sua técnica e postura durante a corrida. Inclua-os em sua rotina antes de começar seus treinos de corrida. Se possível, faça antes de todo treino de corrida.',
        'Antes de conhecer ou executar as séries de educativos, é muito importante que você confira nossas orientações de execução, para que você não venha a ter problemas.',
      ],
      [
        'Importante: Antes de iniciar alguma das séries de educatios que serão abertas a seguir, certifique-se de que conferiu as importantes orientações de execução.',
        'Caso já tenha conferido, apenas finalize.',
      ],
    ],
  },
  Others:
  {
    title: '',
    link: '',
    pages: [
      [
        '',
      ],
      [
        '',
      ],
    ],
  },
};

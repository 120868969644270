import styled from 'styled-components';

export const Title = styled.p`
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
`;

export const SelectedText = styled.p`
    margin-bottom: 10px;
    color: #fff;
    width: 100%;
`;

export const Content = styled.div`
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

export const DescriptionContainer = styled.div`
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const Description = styled.p`
    color: #fff;
`;

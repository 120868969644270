import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api2 } from '../services/api';
import { RootState } from '.';

export enum DiscountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
  HIDDEN = 'HIDDEN',
}
export interface ICoupon {
  id: string;
  active: boolean;
  code: string;
  discount: number;
  discountType: DiscountType;
  member: boolean;
  expirationDate?: Date;
}
export interface IRace {
  _id: string;
  name: string;
  date: Date;
  distance: string[];
  city: string;
  state: string;
  url?: string;
  coupons: ICoupon[];
  structure: boolean;
}

export interface IRaceFilters {
  has_coupon?: boolean;
  city?: string;
  name?: string;
  state?: string;
  start_date?: string;
  end_date?: string;
  max_distance?: number;
  min_distance?: number;
  skip: number;
  has_structure?: boolean;
}

interface RaceState {
  races: IRace[];
  totalRaces: number;
  stateCounts: Record<string, number>;
  loading: boolean;
  error: boolean;
}

const initialState: RaceState = {
  races: [],
  totalRaces: 0,
  stateCounts: {},
  loading: false,
  error: false,
};

const raceCalendar = createSlice({
  name: 'raceCalendar',
  initialState,
  reducers: {
    loadStart: (state) => {
      state.loading = true;
    },
    loadSuccess: (state, action: PayloadAction<{ type: 'races' | 'stateCounts'; data: { total: number, data: IRace[] } | Record<string, number>; append?: boolean }>) => {
      if (action.payload.type === 'races') {
        state.totalRaces = action.payload.data.total;
        const newRaces = action.payload.data.data as IRace[];
        if (action.payload.append) {
          state.races = [...state.races, ...newRaces];
        } else {
          state.races = newRaces;
        }
      } else if (action.payload.type === 'stateCounts') {
        state.stateCounts = action.payload.data as Record<string, number>;
      }
      state.loading = false;
    },
    loadFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    resetRaceData: (state) => {
      state.races = [];
      state.totalRaces = 0;
    },
  },
});

export const {
  loadStart, loadSuccess, loadFailure, resetRaceData,
} = raceCalendar.actions;

export default raceCalendar.reducer;

export const loadRaces = (params: IRaceFilters) => (
  (dispatch: (action: any) => void, getState: () => RootState) => {
    const { token } = getState().auth;

    dispatch(loadStart());

    api2
      .get('race_calendar/all', { params, headers: { Authorization: token || '' } })
      .then((response) => {
        dispatch(loadSuccess({ type: 'races', data: response.data, append: params.skip > 0 }));
      })
      .catch(() => {
        dispatch(loadFailure());
      });
  }
);

export const loadStateRaceCounts = (params: IRaceFilters) => (
  dispatch: (action: any) => void,
) => {
  dispatch(loadStart());

  api2
    .get('race_calendar/state_counts', { params })
    .then((response) => {
      dispatch(loadSuccess({ type: 'stateCounts', data: response.data }));
    })
    .catch(() => {
      dispatch(loadFailure());
    });
};

export const clearRaces = () => (
  dispatch: (action: any) => void,
) => {
  dispatch(resetRaceData());
};

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  background: ${theme.colors.cardB1};
  display:flex;
  flex-direction: column;
`;

export const ItemsContainer = styled.div`
  padding: 5px;
`;
export const TextDescription = styled.div`
  font-size: 12px;
  color: ${theme.colors.title};
  padding-left: 20px;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  width: 100%;
  margin-bottom:10px ;
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
`;
export const Header = styled.div`
  align-items: center;
  align-self: center;
`;
export const HeaderText = styled.div`
  font-size: 14px;
  color: ${theme.colors.title};
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 3px;
  @media (max-width: 667px) {
        font-size: 12px;
    }
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  background-color: ${theme.colors.cardB2};
  border: none;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-self: center;
  padding-top: 10px ;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
`;
export const Button = styled.button`
  background-color: ${theme.colors.cardB2};
  border: none;
  margin-bottom: 5px;
  align-items: center;
`;
export const TextButton = styled.div`
  margin-bottom: 3px;
  color: ${theme.colors.gray};
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
`;
export const BalanceAvailable = styled.div`
 display: flex;
`;
export const BalanceText = styled.div`
  font-weight: 700;
  font-size: 14px;
  @media (max-width: 667px) {
       font-size: 12px;
    }
`;
export const ModalText = styled.p`
  padding: 10px;
  color: ${theme.colors.title};
  font-size: 14px;
`;

import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { appTheme } from '../../../../theme';
import {
  Container, Description, Item,
  Label, Radio, RadioContainer, Title,
  LabelContainer, List, RadioInner,
} from './styles';

export interface ISetTrainingTypeScreenProps {
  step: IStep,
  trainingType: string | null,
  onClick: (value: string) => void,
  setButtonDisabled: (bool: boolean) => void,
}

function SetTrainingTypeScreen({
  step, trainingType, onClick, setButtonDisabled,
}: ISetTrainingTypeScreenProps) {
  useEffect(() => {
    if (trainingType) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [trainingType]);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <List>
        {
          step.data.map((item: IStepData) => (
            <Item
              key={item.label}
              onClick={() => onClick(item.value as string)}
              style={{ borderColor: `${trainingType === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: trainingType === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: trainingType === item.value ? appTheme.title : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{(item.label as string).toUpperCase()}</Label>
              </LabelContainer>
            </Item>
          ))
        }
      </List>
    </Container>
  );
}

export default SetTrainingTypeScreen;

import styled from 'styled-components';
import theme, { appTheme } from '../../../theme';

export const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${theme.colors.title};
`;

export const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
`;

export const Label = styled.label`
    margin-bottom: 20px;
`;

export const Input = styled.input`
    flex: 1;
    background-color: ${appTheme.cardB3};
    color: ${appTheme.title};
    padding: 10px;
    border: none;
    height: 100%;
    font-size: 18px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    min-width: 0px;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const PerKMContainer = styled.div`
    flex: 1;
    height: 100%;
    background-color: ${theme.colors.cardB4};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const PerKMText = styled.p`
    text-align: center;
    color: ${appTheme.text};
    font-size: 18px;

    @media (max-width: 667px) {
        font-size: 14px;
    }
`;

import React from 'react';

import ModalButton from '../ModalButton';
import { Container, Title } from './styles';

interface PropsI {
  onPressSkip: () => void
  onPressBack: () => void
}

function SkipSerieConfirmationModal({
  onPressSkip,
  onPressBack,
}: PropsI) {
  return (
    <Container data-testid="skip-confirmation">
      <Title>DESEJA MESMO PULAR ESTA SÉRIE DE EXERCÍCIOS ?</Title>
      <div>
        <ModalButton
          onPress={onPressSkip}
          secondary
          title="PULAR SÉRIE"
        />
        <ModalButton
          onPress={onPressBack}
          secondary
          title="VOLTAR"
        />
      </div>
    </Container>
  );
}

export default React.memo(SkipSerieConfirmationModal);

import React, { useRef } from 'react';
import { ChartContainer, ContainerStyle } from '../styles';

export default function ChartSkeleton() {
  const containerRef = useRef(null);
  return (
    <ContainerStyle>
      <ChartContainer ref={containerRef} />
    </ContainerStyle>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';

const store = createSlice({
  name: 'sharedNavigationProps',
  initialState: {
    trainingScreenTab: 0,
  },
  reducers: {
    setTab: (state, action) => {
      state.trainingScreenTab = action.payload.data;
    },
  },
});

export const {
  setTab,
} = store.actions;

export default store.reducer;

export const putTab = (data: number) : AppThunk => (
  (dispatch) => {
    dispatch(setTab({ data }));
  }
);

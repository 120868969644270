import styled from 'styled-components';
import theme from '../../theme';
import { ModalContent as _ModalContent } from '../Modal/Modal.styles';

export const ModalContent = styled(_ModalContent)`
  padding: 25px;
  border-radius: 10px;
`;

export const ModalBackDrop = styled.div`
  background: rgba(60, 69, 105, 0.5);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
`;
export const ModalContentButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const ModalContentButton = styled.button`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-color: ${theme.colors.title};
  border-width: 2px;
  border-style: solid;
  background-color: ${theme.colors.primary};

  &:first-of-type ~ & {
    margin-left: 1rem;
  }
`;

export const ModalContentButtonTextCancel = styled.span`
  color: ${theme.colors.title};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const Header = styled.span`
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
`;

export const InfoText = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const Text = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Site = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-top: 10px;
cursor: pointer;
text-decoration: underline;
color: ${theme.colors.title};
`;

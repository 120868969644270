import styled from 'styled-components';
import theme from '../../../theme';

export const DateTextMonth = styled.span`
  color: ${theme.colors.titleCard};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;  
`;

export const DateTextMonthDay = styled.span`
  color: ${theme.colors.titleCard};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
`;

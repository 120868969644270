import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

const rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export interface ISpinner {
  color?: string;
}

const Spinner = styled.div<ISpinner>`
    margin: auto;
    border: 3px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    width: 30px;
    border-left-color: ${(props) => (props.color ? props.color : theme.colors.primary)};
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;
`;

export default Spinner;

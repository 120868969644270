import React from 'react';
import {
  InfoText, Header,
  ModalContentButtonTextCancel,
  ModalContent,
  ModalContentButtonContainer,
  ModalContentButton,
  ModalBackDrop,
  Text,
  SiteContainer,
  Site,
} from './styles';
import {
  ModalDialog,
  ModalContainer,
} from '../Modal/Modal.styles';
import { IRace } from '../../store/RaceCalendar.store';

export interface IRaceWithoutCouponModalProps {
  race: IRace;
  localOfRaces: string;
  isOpen: any;
  toggle: any;
  kilometers: string;
  raceUrl?: string;
}

export default function RaceWithoutCouponModal({
  isOpen, toggle, race, localOfRaces, kilometers, raceUrl,
}: IRaceWithoutCouponModalProps) {
  return (
    isOpen && (

      <>
        <ModalContainer>
          <ModalDialog>
            <ModalContent>
              <Header>Não existem cupons para esta prova</Header>
              <InfoText>
                {race.name}
              </InfoText>
              <Text>
                {localOfRaces}
              </Text>
              <Text>
                {kilometers}
              </Text>
              <SiteContainer>
                {raceUrl && (
                  <Site onClick={() => window.open(raceUrl, '_blank')}>
                    IR PARA O SITE DA PROVA
                  </Site>
                )}
              </SiteContainer>
              <ModalContentButtonContainer>
                <ModalContentButton onClick={toggle}>
                  <ModalContentButtonTextCancel>
                    Veja outras provas em
                    {' '}
                    {localOfRaces}
                    {' '}
                    com cupom de desconto
                  </ModalContentButtonTextCancel>
                </ModalContentButton>
              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

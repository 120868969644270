import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';

import api from '../services/api';

interface IBill {
  id: number;
  amount: string;
  bill_affiliates: any[];
  billing_at: string | null;
  charges: any[];
  created_at: string;
  due_at: string | null;
  installments: number;
  status: string;
  updated_at: string | null;
  url: string | null;
}

interface IPricingSchema {
  id: number;
  created_at: string;
  price: string;
  schema_type: string;
  short_format: string;
}

interface ISubscriptionProductItem {
  id: number;
  created_at: string;
  cycles: number | null;
  discounts: any[];
  quantity: number;
  status: string;
  updated_at: string | null;
  uses: number;
  product: {
    id: number;
    code: number | null;
    name: string;
  },
  pricing_schema: IPricingSchema;
}
interface Isubscription {
  id: number;
  plan: { name: string } | null;
  bills: IBill[];
  product_items: ISubscriptionProductItem[];
  status: string;
  start_at: string;
  current_period: {
    end_at: string
  };
  next_billing_at: string;
  payment_method: { public_name: string, code: string };
  interval_count: number;
  interval?: string;
  installments: number;
  subscription_type: string | null;
}

const subscription = createSlice({
  name: 'subscription',
  initialState: {
    subscription: null as Isubscription | null,
    loading: true,
    error: null,
  },
  reducers: {
    getSubscriptionStart: (state) => {
      state.subscription = null;
      state.loading = true;
      state.error = null;
    },
    getSubscriptionSuccess: (state, action) => {
      state.subscription = action.payload;
      state.loading = false;
      state.error = null;
    },
    getSubscriptionFailure: (state, action) => {
      state.subscription = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSubscriptionStart,
  getSubscriptionSuccess,
  getSubscriptionFailure,
} = subscription.actions;

export default subscription.reducer;

export const requestSubscription = (): AppThunk => async (dispatch, getState) => {
  dispatch(getSubscriptionStart());

  try {
    const response = await api.get(
      `subscriptions?userId=${getState().auth.user.id}`,
    );

    if (response.data.length > 0) {
      dispatch(getSubscriptionSuccess(response.data[0]));
    } else {
      throw new Error('Sem assinatura corrente');
    }
  } catch (error: any) {
    dispatch(getSubscriptionFailure(error?.message ?? JSON.stringify(error)));
  }
};

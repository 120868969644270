import styled from 'styled-components';
import THEME from '../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
`;

export const ItemTitle = styled.p`
    color: ${THEME.colors.title};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;

export const InputLabel = styled.label`
    color: ${THEME.colors.gray};
    text-align: center;
`;

export const Input = styled.input`
    background-color: ${THEME.colors.cardB3};
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 95%;
    margin-top: 10px;
    color: ${THEME.colors.title};
`;

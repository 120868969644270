import styled from 'styled-components';
import theme from '../../theme';

export const Title = styled.p`
  color: ${theme.colors.iconsOff};
  font-size: 14px;
`;

export const SubTitle = styled.p`
  color: ${theme.colors.iconsOff};
  font-size: 12px;
`;

export const FormGroup = styled.div`
  display: flex;  
  width: 80%;
  justify-content: space-between; 
  align-items: center;
  margin: 0 auto;
  margin-top: 4%;  
`;

export const Form = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormTitle = styled.p`
  color: ${theme.colors.title};
`;

export const Input = styled.input`
  padding: 0 10px;
  color: ${theme.colors.title};
  background: ${theme.colors.cardB2};
  height: 40px;
  border-radius: 6px;
  width: 100%;
  margin-top: 2%;
  text-align: center;
  border: none;
  &::placeholder{
    color: ${theme.colors.title};
  }
`;

export const Header = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top-width: 4px;
  border-color: ${theme.colors.background};
  padding: 4% 0;
  text-align: center;
`;

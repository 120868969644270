import styled from 'styled-components';
import theme from '../../theme';
import Objective from '../../assets/objective/objective.png';

export const Container = styled.div`
  border-radius: 10px;
  padding: 22px 25px;
  margin-bottom : 25px ;
  background-image: url(${Objective});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  cursor: pointer;

  @media (max-width: 667px) {
    margin-bottom: 8px;
    padding: 8px;
  }
`;

export const HeaderContainer = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: 667px) {
    margin-right: 0;
  }
`;

export const HeaderText = styled.div`
p{
  color: ${theme.colors.title};
  font-size: 16px;
  margin-bottom: 18px;

  @media (max-width: 667px) {
    font-size: 12px;
    margin-bottom: 8px;
  }
}
`;

export const PlanText = styled.div`
h3{
  color: ${theme.colors.notifications};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
  text-transform: uppercase;
  margin-bottom: 18px;

  @media (max-width: 667px) {
    font-size: 12px;
    margin-bottom: 8px;
  }
}
`;

export const TextInfo = styled.div`
p{
  color: ${theme.colors.text};
  font-size: 15px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
}
`;

import styled from 'styled-components';
import { appTheme } from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${appTheme.background};
  width: 100%;
  height: 280px;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.p`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-weight: 600;
  margin-top: -60px;
  margin-bottom: 30px;
  font-size: 16px;
`;

export const ConfirmText = styled.p`
  color: ${appTheme.title};
  margin-bottom: 25px;
`;

export const RemoveButton = styled.button`
  background-color: unset;
  cursor: pointer;
  width: 150px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  border: 2px solid;
  border-color: ${appTheme.redAlert};
  margin-bottom: 10px;
`;

export const RemoveText = styled.p`
  color: ${appTheme.redAlert};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CancelButton = styled.button`
  background-color: unset;
  cursor: pointer;
  width: 150px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 2px solid;
  border-color: ${appTheme.title};
`;

export const CancelText = styled.p`
  color: ${appTheme.title};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

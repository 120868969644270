import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineEmail } from 'react-icons/md';
import theme from '../../theme';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import {
  Container, Card, Logo, Title, Form, Input, InputGroup, SubmitButton, CancellButton,
} from './styles';
import MessageModal from '../../components/MessageModal';

export default function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageModal, setMessageModal] = useState({
    title: '', message: '', showOKButton: true,
  });
  const navigate = useNavigate();

  const closeMessageModal = async () => {
    if (success) {
      setShowMessageModal(false);
      navigate('/login');
    } else {
      setShowMessageModal(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (email && email.length > 0) {
      const requestPassRecovery = { email };

      await Promise.all([api.post('/passwords', requestPassRecovery)]).then(async ([passRecoveryResp]) => {
        const { data } = passRecoveryResp;

        const message = { title: '', message: '', showOKButton: true };

        if (data.status === 200) {
          message.title = data.message.title;
          message.message = data.message.msg;
          setSuccess(true);
        } else {
          message.title = data.error.title;
          message.message = data.error.msg;
        }

        setMessageModal(message);
        setShowMessageModal(true);
      }).catch(async () => {
        setMessageModal({
          title: 'Ops', message: 'O endereço de e-mail não foi localizado. Tente novamente ou peça ajuda.', showOKButton: true,
        });
        setShowMessageModal(true);
      });
    } else {
      setMessageModal({ title: 'Ops!', message: 'Por favor, insira um email válido', showOKButton: true });
      setShowMessageModal(true);
    }
  };

  return (
    <Container data-testid="password">
      <Card>
        <Logo src={logo} alt="logo" />

        <Title>Recuperação de Senha </Title>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <MdOutlineEmail size={20} color={theme.colors.black} />
            <Input
              type="email"
              placeholder="Digite seu e-mail"
              name="email"
              required
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>

          <SubmitButton onClick={handleSubmit}>
            Receber e-mail de recuperação
          </SubmitButton>
        </Form>
        <CancellButton to="/login">CANCELAR</CancellButton>
      </Card>
      {showMessageModal
      && (
        <MessageModal
          isModalOpen
          closeFunc={closeMessageModal}
          title={messageModal.title}
          message={messageModal.message}
          isVisible={showMessageModal}
          showOKButton={messageModal.showOKButton}
          toggle={() => setShowMessageModal(false)}
        />
      )}
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdAddCircleOutline } from 'react-icons/md';
import { BsArrowClockwise, BsChevronLeft } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroller';
import RegisterActivity from '../../components/RegisterActivity';
import { RootState } from '../../store';

import { getActivities, resetPage } from '../../store/Activities.store';
import {
  Container, Header, Content, Title, Button,
} from './style';
import ActivityListItem from '../../components/ActivityListItem';
import ActivitiesListSkeleton from '../../components/ActivitiesListSkeleton';

export default function ActivityList() {
  const auth = useSelector((state: RootState) => state.auth);
  const { loading, activities, hasMore } = useSelector((state: RootState) => state.activities);
  const { user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const activityRequest = async () => {
    dispatch(getActivities(user?.id) as any);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const refresh = () => {
    dispatch(resetPage());
    activityRequest();
  };

  useEffect(() => {
    activityRequest();
  }, []);

  return (
    <Container data-testid="activity-list">
      <RegisterActivity visible={modalVisible} close={closeModal} refresh={refresh} />
      <Header>
        <Content>
          <Button type="button" onClick={() => navigate('/home')}>
            <BsChevronLeft color="white" size={24} />
          </Button>
          <Title onClick={() => navigate('/home')}>ATIVIDADES</Title>
          <BsArrowClockwise color="white" onClick={refresh} size={20} />
        </Content>

        <MdAddCircleOutline onClick={() => setModalVisible(true)} color="white" size={24} />
      </Header>
      {loading ? (
        <>
          <ActivitiesListSkeleton />
        </>
      ) : (
        <InfiniteScroll pageStart={0} loadMore={activityRequest} hasMore={hasMore} loader={<ActivitiesListSkeleton key={0} />}>
          {activities.map((activity) => (
            <ActivityListItem key={activity?.id} activity={activity!} />
          ))}
        </InfiniteScroll>
      )}

    </Container>

  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  Container, Title, Text, Button, ButtonText, InfoContainer, CloseButton,
} from './styles';
import { BannerType } from '../../types/Banner';
import { pageRoute } from './utils';
import coreApi from '../../services/coreApi';
import { RootState } from '../../store';

interface Props {
  banner: BannerType
  setInfoModalVisible: Dispatch<SetStateAction<boolean>>;
  clearBanner: () => void
}

function HomeBanner({ banner, setInfoModalVisible, clearBanner }: Props) {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const markAsViewed = async () => {
    await coreApi.post('/home-banner/viewed', { banner_id: banner._id, user_id: user.id })
      .catch(() => toast.error('Ocorreu um erro, tente novamente mais tarde'));
  };

  const handleCloseButton = () => {
    if (banner.close_modal_title) {
      setInfoModalVisible(true);
    } else {
      markAsViewed();
      clearBanner();
    }
  };

  const handleAction = () => {
    if (banner.banner_confirm_button_type === 'URL') {
      window.location.href = banner.banner_confirm_button_action;
    } else {
      navigate(pageRoute[banner.banner_confirm_button_action]);
    }
  };

  return (
    <>
      {banner && (
        <Container color={banner.background_color} image={banner.image}>
          <InfoContainer>
            <Title color={banner.title_color}>{banner.title}</Title>
            <Text color={banner.description_text_color}>{banner.description}</Text>
            <Button color={banner.button_color} onClick={handleAction}>
              <ButtonText color={banner.button_text_color}>{banner.button_text}</ButtonText>
            </Button>
          </InfoContainer>
          <CloseButton onClick={handleCloseButton}>
            <MdClose style={{ fontSize: 20, color: banner.close_button_color }} />
          </CloseButton>
        </Container>
      )}
    </>
  );
}

export default HomeBanner;

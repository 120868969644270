import React from 'react';
import ContentLoader from 'react-content-loader';
import { appTheme } from '../../theme';

function ClassesListSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={184}
      viewBox="0 0 476 184"
      backgroundColor={appTheme.background}
      foregroundColor={appTheme.cardB3}
    >
      <rect x="22" y="41" rx="5" ry="5" width="185" height="15" />
      <rect x="22" y="62" rx="5" ry="5" width="89" height="13" />
      <rect x="23" y="114" rx="5" ry="5" width="120" height="10" />
      <rect x="22" y="94" rx="5" ry="5" width="135" height="10" />
      <rect x="22" y="134" rx="5" ry="5" width="165" height="10" />
    </ContentLoader>
  );
}

export default ClassesListSkeleton;

import React, {
  useRef, useState,
} from 'react';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { api2 } from '../../services/api';
import {
  Input, BrandsList, BrandsListItem, EmptySearch,
} from './styles';
import { IShoesBrand } from '../../store/Activities.store';

interface IProps {
  setBrand: (value: IShoesBrand) => void;
  setBrandName: (value: string) => void;
  brandName: string;
}

export default function SearchBrandInput({ setBrand, setBrandName, brandName }: IProps) {
  const [showBrandsList, setShowBrandsList] = useState(false);
  const [brands, setBrands] = useState<IShoesBrand[]>([]);

  const handleSearchBrand = (text: string) => {
    api2.get(`shoes/brands?name=${text}`)
      .then((response) => {
        setBrands(response.data.data);
        setShowBrandsList(true);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao buscar a marca');
      });
  };

  const delayedQuery = useRef(
    debounce((e: string) => {
      if (e) handleSearchBrand(e);
    }, 1000),
  ).current;

  return (
    <>
      <Input
        type="text"
        placeholder="Digite a marca"
        onChange={(e) => {
          setBrandName(e.target.value);
          delayedQuery(e.target.value);
        }}
        value={brandName}
      />

      {
        brands.length > 0
        && showBrandsList
        && (
          <BrandsList>
            {
              brands.map((b) => (
                <BrandsListItem
                  type="button"
                  key={b.id}
                  onClick={() => {
                    setBrandName(b?.name);
                    setBrand(b);
                    setShowBrandsList(false);
                  }}
                >
                  {b?.name}
                </BrandsListItem>
              ))
            }
          </BrandsList>
        )
      }

      {
        brands.length === 0
        && showBrandsList
        && <EmptySearch>Nenhuma marca encontrada</EmptySearch>
      }
    </>
  );
}

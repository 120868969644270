import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.section`
  display: block;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 5px;
  padding: 10px;
  background-color: ${theme.colors.primary};
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
`;

export const CardTitle = styled.section`
  color: ${theme.colors.gray};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 55px;
  margin-right: 60px;
  margin-bottom: 50px;

  border-radius: 10px;
  background-color: ${theme.colors.primary};

  align-items: center;
  color: ${theme.colors.title};

  h5 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: lighter;
  }
  p {
    
    font-size: 13px;
    align-items: center;
    text-align: center;

    font-style: normal;

    margin-bottom: 20px;
  }
`;

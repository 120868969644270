import React, { useEffect, useRef, useState } from 'react';
import {
  VictoryAxis, VictoryChart, VictoryLine,
} from 'victory';
import { monthChartTheme } from '../chartTheme';
import { ChartContainer } from '../styles';
import ChartSkeleton from '../ChartSkeleton';
import { ChartData } from '../../ProgressAccumulatedDistance';
import { DateTextMonth, DateTextMonthDay } from './styles';

interface MonthChartProps {
  data: ChartData
  loading: boolean
}

function useComponentWidth(ref: any) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect();
        setWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return width;
}

export default function MonthChart({ data, loading } :MonthChartProps) {
  const monthNumber = new Date()?.getMonth() + 1;
  const monthLabel = monthNumber?.toString()?.padStart(2, '0');
  const day = new Date()?.getDate();
  const dataNow = new Date();
  const mes = dataNow.toLocaleString('pt-BR', { month: 'long' });
  const ano = dataNow.getFullYear();
  const containerRef = useRef(null);
  const containerWidth = useComponentWidth(containerRef);
  const chartWidth = containerWidth * 0.80;

  return (
    <>
      {
        loading
          ? <ChartSkeleton />
          : (
            <ChartContainer ref={containerRef}>
              {!loading && (
                <DateTextMonth>
                  <DateTextMonthDay>
                    {`${mes}`}
                    &nbsp;
                  </DateTextMonthDay>
                  de &nbsp;
                  {`${ano}`}
                </DateTextMonth>
              )}
              <VictoryChart
                theme={monthChartTheme}
                width={chartWidth}
                height={122}
                padding={{
                  left: 15, bottom: 18, right: 18, top: 10,
                }}
              >
                <VictoryAxis
                  style={{
                    grid: { stroke: ({ tick }: any) => (tick === day! ? 'white' : 'none'), strokeDasharray: '4' },
                    tickLabels: { fontSize: 12 },
                    axisLabel: { fontSize: 20, padding: 30 },
                  }}
                  tickValues={data?.data.map((date: any) => date?.x)}
                  tickFormat={(x) => {
                    if (x === 1 || x === day!) {
                      if (x < 10) {
                        return `0${x}/${monthLabel || ''}`;
                      }
                      return `${x}/${monthLabel || ''}`;
                    }
                    return '';
                  }}
                />
                <VictoryLine
                  domain={{ y: [0, data?.total * 1.5 || 200], x: [1, data?.data.length] }}
                  style={{
                    data: { stroke: 'green', strokeWidth: 3 },
                  }}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  data={data?.data}
                />
              </VictoryChart>
            </ChartContainer>
          )
      }
    </>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

interface ButtonI {
  primary?: boolean
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${theme.colors.cardB1};
    padding: 10px;
    position: relative;
`;

export const Cover = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${theme.colors.cardB2};
    margin: 1%;
    border-radius: 10px;    
`;

export const List = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;

    .titleAccordion{
        background-position: left 16px;
        background-repeat: no-repeat;
        background-size: 15px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4%;
    min-height: 140px;
    margin-bottom: 4%;
    position: sticky;
    bottom: 0;
    background-color: rgba(13, 13, 61, .8);
`;

export const Buttom = styled.button<ButtonI>`
    margin: 2%;
    border-radius: 5px;
    padding: 4% 12%;   
    border-width: ${(props) => (props.primary ? '0px' : '1px')};
    border-color: ${theme.colors.title};
    background: ${(props) => (props.primary ? theme.colors.graph : 'transparent')};
    color: ${theme.colors.title};
    font-weight: bold;
    cursor: pointer;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 4%;
    max-width: 90%;
    align-self: flex-end;
    margin-right: 2%;
    span{
        color: ${theme.colors.title};
        font-size: 16px;
        cursor: pointer;
    }
`;

export const InfoText = styled.h2`
    color: ${theme.colors.title};
    font-size: 10px;
    margin-right: 2%;
    font-weight: 500;
    @media (min-width: 768px) {
        font-size: 14px;
    }
`;

export const ClickableItem = styled.div`
    width: 100%;
`;

export const Shared = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CardExercise = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 13px;
  position: relative;
  cursor: pointer;
  &:last-of-type{
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    min-height: 184px;
    margin-bottom: 19px;
  }

`;

export const BorderCard = styled.div`
    position: absolute;
    width: 10px;    
    height: 100%;
    left: 0;
    top: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  z-index: 6; 
  padding: 10px;
`;

export const TitleContainer = styled.div`
  width: 100%; 
  h2{
    color: ${theme.colors.title};
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 7px;
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: 17px;
        margin-bottom: 11px;
    }
    span{
        display: block;
        color: ${theme.colors.notifications};
    }
  }  
`;

export const OptionsContainer = styled.div`
  width: 100%;   
  p{
    color: ${theme.colors.text};
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    @media (min-width: 768px) {
        font-size: 14px;
    }
    &:first-of-type{
        text-transform: capitalize;
    }
    svg{
        margin-right: 5px;
    }
  } 
`;

export const ListContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    margin-bottom: 16px;

    &.colapsed{
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
`;

export const Thumb = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.title};

    img{
        width: 30px;
        height: 30px;
        border-radius: 5px;
    }

    p{
        font-size: 10px;
        margin: 0px;
        @media(min-width: 768px){
            font-size: 14px;
        }
        span{
            color: ${theme.colors.iconsOff};
            margin: 0 8px;
        }
    }
`;

export const InfoExercise = styled.div` 
    color: ${theme.colors.iconsOff};
    cursor: pointer;
    p{
        font-size: 8px;     
        margin: 0 ;   
        @media(min-width: 768px){
            font-size: 12px;
        }
        span{
            color: ${theme.colors.iconsOff};
            text-decoration: underline;
            margin: 0 8px;
        }
    }
`;

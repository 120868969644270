import React, { useState } from 'react';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import { Container, Image, ArrowButton } from './styles';

interface CarouselProps {
  images: { url: string }[];
}

export default function CarouselProduct({ images }: CarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const onFirstImage = currentIndex === 0;
  const onLastImage = currentIndex === images.length - 1;

  return (
    <Container>
      <ArrowButton data-testid="prev-button" disabled={onFirstImage} onClick={handlePrev}>
        <MdOutlineArrowBackIos size={25} color={onFirstImage ? 'gray' : 'white'} />
      </ArrowButton>

      <Image src={images[currentIndex].url} />

      <ArrowButton data-testid="next-button" disabled={onLastImage} onClick={handleNext}>
        <MdOutlineArrowForwardIos size={25} color={onLastImage ? 'gray' : 'white'} />
      </ArrowButton>
    </Container>
  );
}

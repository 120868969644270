import styled from 'styled-components';
import theme from '../../theme';

interface ChartBarI {
  height: number,
  condition: boolean
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 20%;
`;

export const Label = styled.p`
    color: ${theme.colors.text};
    font-size: 10px;
`;

export const ChartContainer = styled.div`
    display: flex;
    align-items: flex-end ;
    min-height: 20px;
`;

export const ChartBar = styled.div<ChartBarI>`
    display: flex;    
    width: 15px;
    height: ${(props) => props.height}px;
    background: ${(props) => (props.condition ? theme.colors.title : theme.colors.iconsOff)};
`;

export const SpeedLabel = styled.p`
    color: ${theme.colors.text};
    font-size: 12px;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`   
    display: flex;
    flex-direction: row;
    align-items: center; 
    padding: 10px;
`;

export const ContainerInfo = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TextName = styled.span`  
    text-transform: uppercase;
    color: ${theme.colors.title};
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 10px;
    @media (max-width: 667px) {
    font-size: 12px;
  }
`;
export const TextTitle = styled.span`      
    color: ${theme.colors.gray};
    font-size: 13px; 
    margin-left: 10px;
    @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const AvatarButton = styled.button`      
    background-color: ${theme.colors.primary2};
    border: none;
    
`;

export const Avatar = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 70px;
  @media (max-width: 667px) {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
`;

export const AvatarDefault = styled.img.attrs(() => ({
  src: 'https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/medium-bee27e393b8559be0995b6573bcfde897d6af934dac8f392a6229295290e16dd.png',
}))`
    width: 140px;
    height: 140px;
    border-radius: 70px;
    background-color: ${theme.colors.title};
    @media (max-width: 667px) {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
`;

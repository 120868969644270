import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.cardB1};
`;

export const Text = styled.h4`
  color: white;
  font-weight: bold;
`;

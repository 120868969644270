import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  padding: 8px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 100%;

  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ItemsList = styled.section`
    width: 100%;    
    background: ${theme.colors.primary2};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    cursor: pointer;

    @media (max-width: 667px) {
        grid-gap: 8px;
    }
`;

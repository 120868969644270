import styled from 'styled-components';

interface IButton {
  borderColor: string
  backgroundColor: string
  textColor: string
}

export const GenericButton = styled.button<IButton>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 45px;
    cursor: pointer;
    border: 2px solid ${(props) => props.borderColor};
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    border-radius: 5px;
    font-size: 12pt;
`;

export const GenericLinkButton = styled.a<IButton>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 45px;
    text-decoration: none;
    border: 2px solid ${(props) => props.borderColor};
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    border-radius: 5px;
    font-size: 12pt;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const FooterContainer = styled.footer<{onClick?: any}>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  height: 80px;

  p {
    ${({ onClick }) => onClick && `
      cursor: pointer;
      text-decoration: underline;
    `}
  }
`;

export const FooterText = styled.p`
  margin: 0;
  font-size: 14px;
  width: fit-content;
  text-transform: uppercase;
  color: ${theme.colors.title};
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.cardB1};
`;

export const Info = styled.p`
  margin: 0 16px;
  color: ${theme.colors.title};  
  font-size: 16px;
  padding: 8px;
  
  @media (max-width: 667px) {
    margin: 8px 0;
    font-size: 12px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

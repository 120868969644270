import React from 'react';
import {
  SwitchRoot, SwitchBaseRoot, SwitchThumb, SwitchRipple, SwitchBaseInput, SwitchTrack, SwitchLabel, SwitchContainer,
} from './styles';

export default function Switch({ isEnabled, toggle, labelEnabled = false }: { isEnabled: boolean, toggle: any, labelEnabled?: boolean}) {
  return (
    <SwitchContainer>
      {labelEnabled && <SwitchLabel>{isEnabled ? 'Ligado' : 'Desligado'}</SwitchLabel>}

      <SwitchRoot>
        <SwitchBaseRoot className={isEnabled ? 'active' : ''}>
          <SwitchBaseInput type="checkbox" checked={isEnabled} onChange={toggle} />
          <SwitchThumb className={isEnabled ? 'active' : ''} />
          <SwitchRipple className={isEnabled ? 'active' : ''} />
        </SwitchBaseRoot>
        <SwitchTrack className={isEnabled ? 'active' : ''} />
      </SwitchRoot>
    </SwitchContainer>
  );
}

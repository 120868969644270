import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Title, TitleBlock } from './styles';
import PredictionInputScreen from './screens/PredictionInputScreen';
import { RootState } from '../../store';
import PredictionDataScreen from './screens/PredictionDataScreen';
import PredictionDisclaimerModal from '../../components/PredictionDisclaimerModal';
import BackArrow from '../../components/BackArrow';

export default function Prediction() {
  const { userPredictedData } = useSelector((state: RootState) => state.prediction);
  const [disclaimerAccepted, setDisclaimerAccepted] = React.useState(false);
  const token = useSelector((state: RootState) => state.auth.token);

  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          data-react-helmet="true"
          content="Previsão de desempenho: Saiba agora uma previsão de quanto tempo vai ser sua maratona, meia maratona e 10k. Tudo isso na nossa calculadora de desempenho."
        />
        <title>
          Previsão de desempenho
        </title>
      </Helmet>
      {!(userPredictedData[10].time && disclaimerAccepted) && (
        <TitleBlock>
          {token && (<BackArrow text="" bigger />)}
          <Title>
            Previsão de desempenho
          </Title>
        </TitleBlock>
      )}

      <PredictionDisclaimerModal
        isVisible={(!disclaimerAccepted && !!userPredictedData[10].time)}
        dismissCallback={() => setDisclaimerAccepted(true)}
      />

      {(userPredictedData[10].time && disclaimerAccepted && (
        <PredictionDataScreen />
      ))
        || (
          <PredictionInputScreen />
        )}
    </Container>
  );
}

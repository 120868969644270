import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import theme from '../../theme';
import { ModulesI } from '../../types/Class';

import ClassesLengthBadge from '../ClassesLengthBadge';
import ClassProgress from '../ClassesProgress';
import {
  Container,
  ContentImage,
  ContentView,
  Description, Item, ItemContent, Title,
} from './styles';

interface ClassListItemI {
  onPress: () => void
  cover: string
  name: string
  description: string
  executed_percent: number
  courseModules: ModulesI[]
}

function ClassListItem({
  onPress, cover, name, description, courseModules, executed_percent,
}: ClassListItemI) {
  return (
    <Container
      onClick={onPress}
      data-testid="class-list-item"
    >
      <Item>
        <ContentImage
          style={{ backgroundImage: `url(${cover})` }}
        >
          <ItemContent>
            <ContentView>
              <Title>{name}</Title>
              <ClassesLengthBadge length={courseModules.length} />
            </ContentView>
            <Description>{description}</Description>
            <ClassProgress percentage={executed_percent} />
          </ItemContent>
          <BsChevronRight color={theme.colors.title} size={36} />
        </ContentImage>
      </Item>
    </Container>
  );
}

export default ClassListItem;

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display:flex; 
  margin-bottom: 16px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Image = styled.img`
  border-radius: 5px;
  object-fit: contain; 
  max-width: 80%;
  max-height: 100%;
`;

export const ArrowButton = styled.button`
  height: 100%;
  width: 55px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary};
  border: none;
  cursor: pointer;

`;

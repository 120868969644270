import * as React from 'react';

import {
  TextArea, InputContainer, Label, ErrorMessage,
} from '../FormInput/styles';

export interface ITextAreaInput extends React.TextareaHTMLAttributes<HTMLTextAreaElement>{
  label: string;
  inputValue: string;
  error?: boolean;
  errorMessage?: string;
}

export default function TextAreaInput({
  label, inputValue, error, errorMessage, ...rest
}: ITextAreaInput) {
  return (
    <InputContainer data-testid="outer-textarea-container">
      <Label>{label}</Label>
      <TextArea
        value={inputValue || ''}
        {...rest}
      />
      {error && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
}

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NavigationContainer = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const ActivityContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${appTheme.cardB2};
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin-bottom: 10px;
`;

export const DateText = styled.p`
  color: ${appTheme.text};
  font-size: 12px;
  padding-left: 5px;
`;

export const ActivityTitle = styled.p`
  color: ${appTheme.title};
  text-transform: uppercase;
  font-weight: 600; 
  width:90%;
  padding-left: 5px;
`;

export const NavigationTitle = styled.p`
  color: ${appTheme.title};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600; 
  margin-left: 5px;
  margin-bottom: 5px;
`;

export const HeaderContainer = styled.div`
  background-color: ${appTheme.cardB2};
  padding: 8px;
  justify-content: center;
`;

export const IconsContainer = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
`;

export const IconButton = styled.button`
  padding: 5px;
`;

export const InfoContainer = styled.div`
  background-color: ${appTheme.cardB2};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Separator = styled.div`
  background-color: ${appTheme.background};
  width: 100%;
  height: 2px;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding: 10px;
`;

export const InfoText = styled.p`
  color: ${appTheme.title};
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 410px;
  background-color: ${appTheme.cardB2};
`;

export const CloseContainer = styled.div`
  flex-direction: row;
  width: 100%;
  padding-right: 5px;
  padding-top: 3px;
`;

export const TrainingContainer = styled.div`
  padding: 5px;
  margin-bottom: 20px;
`;

export const TrainingLabel = styled.p`
  color: ${appTheme.title};
  font-weight: 600;
  margin-bottom: 10px;
  max-width: 40%;
`;

interface AssociationContainerProps {
  resize?: boolean;
  multiply? : number | null;
}

export const AssociationContainer = styled.div<AssociationContainerProps>`
  border-radius: 10px;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  height: ${(props) => (props.resize && props.multiply ? `${(55 + (40 * props.multiply))}px` : 'null')};
  align-items:  ${(props) => (props.resize ? 'flex-start' : 'center')};
  background-color: ${appTheme.cardB2};
  padding-left: 10px;
  padding-right: 10px;
`;

export const DropdownContainer = styled.div`
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const OutContainer = styled.div`
  border-radius: 10px;
  background-color: ${appTheme.cardB2};
`;

export const AssociationLabel = styled.p`
  color: ${appTheme.title};
  align-self: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

export const Button = styled.button`
  width: 75%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid ${appTheme.title};
  background-color: ${appTheme.background};
  cursor: pointer;
`;

export const TextButton = styled.p`
  color: ${appTheme.title};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const NullStateContainer = styled.div`
  color: ${appTheme.background};
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

export const NullStateText = styled.p`
  color: white;
  font-size: 16;
  text-align: center;
`;

export const LoadingContainer = styled.div`
  color: ${appTheme.background};
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { appTheme } from '../../../../theme';
import {
  Container, Fields, Label, LabelText,
  Separator, SeparatorTitle,
  SeparatorWithoutLeftMargin,
} from './styles';
import { formatDuration } from './utils';
import FormInput from '../../../FormInput';
import { IStepData } from '../..';
import { User } from '../../../../types/User';

export interface IRequestPlanSetRecordProps {
  opt: IStepData,
  getKmDate: (type: number) => string | null,
  getKmTime: (type: number) => string | null,
  onChangeDate: (index: any, date: string) => void,
  onChangeTime: (index: any, time: string) => void,
  profile: User,

}

function RequestPlanSetRecord({
  opt, getKmDate, getKmTime, onChangeDate, onChangeTime, profile,
}: IRequestPlanSetRecordProps) {
  const optTime: number | null = profile[`km${opt.itemDistance}_time` as keyof User];
  const optDate: string | null = profile[`km${opt.itemDistance}_date` as keyof User];

  useEffect(() => {
    if (getKmTime(opt.itemDistance as number)) {
      onChangeTime(opt.itemDistance, getKmTime(opt.itemDistance as number) || '');
    } else {
      onChangeTime(opt.itemDistance, optTime ? formatDuration(optTime, 'time') : '');
    }

    if (getKmDate(opt.itemDistance as number)) {
      onChangeDate(opt.itemDistance, format(new Date(getKmDate(opt.itemDistance as number) || ''), 'yyyy-MM-dd'));
    } else if (optDate) {
      onChangeDate(opt.itemDistance, format(new Date(optDate), 'yyyy-MM-dd'));
    }
  }, []);

  return (
    <Container>
      <Label>
        <LabelText>{opt.label}</LabelText>
      </Label>

      <Fields>
        <SeparatorWithoutLeftMargin>
          <SeparatorTitle>Tempo obtido</SeparatorTitle>
          <FormInput
            style={{
              backgroundColor: appTheme.cardB3,
              color: appTheme.gray,
              padding: 5,
              borderRadius: 5,
              height: 30,
            }}
            step={1}
            type="time"
            inputValue={getKmTime(opt.itemDistance as number) || ''}
            onChange={(e) => onChangeTime(opt.itemDistance, e.target.value)}
            placeholder="00:00:00"

          />

        </SeparatorWithoutLeftMargin>

        <Separator>
          <SeparatorTitle>Quando foi obtido</SeparatorTitle>

          <FormInput
            style={{
              backgroundColor: appTheme.cardB3,
              color: appTheme.gray,
              padding: 5,
              borderRadius: 5,
              height: 30,
            }}
            placeholder="00/00/0000"
            max={format(new Date(), 'yyyy-MM-dd')}
            inputValue={getKmDate(opt.itemDistance as number) || ''}
            type="date"
            onChange={(e) => onChangeDate(opt.itemDistance, e.target.value)}
          />
        </Separator>
      </Fields>
    </Container>
  );
}

export default RequestPlanSetRecord;

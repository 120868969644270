import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Container, Content } from './styles';
import BackArrow from '../../components/BackArrow';
import ChallengeCardList from '../../components/ChallengeCardList';

import SerraGaucha from '../../assets/challenges/challenge-serra-gaucha.png';
import SerraGauchaMedal from '../../assets/challenges/medal-serra-gaucha.png';
import { loginWithToken } from '../../store/Auth.store';

export default function Challenges() {
  const [searchParms] = useSearchParams();
  const dispatch = useDispatch();

  const token = searchParms.get('token');
  const err = searchParms.get('err');

  const _loginWithToken = async () => {
    if (err) {
      window.location.href = '/login';
      return;
    }
    if (token) {
      const data = JSON.parse(atob(token.split('.')[1]));
      await dispatch(loginWithToken(data.uid, token) as any);
    }
  };

  useEffect(() => {
    _loginWithToken();
  }, [token]);

  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const { id } = user;

  const challenges = [{
    title: 'desafio serra gaúcha',
    info: 'Se aventure por meio de cenários incríveis da Serra Gaúcha, saindo do Vale dos Vinhedos e chegando na icônica região de Gramado e Canela.',
    image: SerraGaucha,
    medal: SerraGauchaMedal,
    distance: 135,
  }];

  return (
    <Container>
      {id
        && (
          <BackArrow text="Desafios" bigger backTo="/club" />
        )}
      <Content>
        {challenges.map(({
          image, medal, title, info, distance,
        }, index) => (
          <ChallengeCardList
            key={index}
            action={() => navigate('serra-gaucha')}
            image={image}
            medal={medal}
            title={title}
            info={info}
            distance={distance}
          />
        ))}
      </Content>
    </Container>
  );
}

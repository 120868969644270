import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin-top: 45px;
  margin-bottom: 45px;
  background-color: ${theme.colors.primary};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  align-items: center;
  background-color: ${theme.colors.cardB1};
  padding: 8px;
  margin-bottom: 18px;
`;

export const Title = styled.h3`
  color: white;
  text-transform: uppercase;
`;

export const Price = styled.h4`
  margin-top: 4px;
  margin-bottom: 2px;
  color: ${theme.colors.notifications};
`;

export const Parcel = styled.h3`
  color: ${theme.colors.notifications};
`;

export const Info = styled.span`
  font-size: 11px;
  color: white;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 65%;
  margin: 8px;
`;

export const Content = styled.p`
  color: white;
  margin-left: 8px;
  font-size: 14px;
`;

import * as React from 'react';
import {
  MdPlayCircleOutline, MdAddCircleOutline, MdInfoOutline, MdDeleteOutline,
} from 'react-icons/md';
import { isBefore } from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { formatDuration, formatTrainingIcon, TrainingType } from '../../../../../../utils';

import { ITrainingOfDay } from '../../../../../../types/TrainingPlan';

import {
  Container, EventCardHeader, Title, SubTitle, EventCardFooter,
  TrainingSubDescription, TrainingSubDescriptionText,
  EventCardBody, TrainingGroupContainer, TrainingGroup,
  TrainingGroupContent, TrainingGroupCount, TrainingGroupText,
  DescriptionModal, Button,
} from './styles';

import TrainingStatus from '../../../../../TrainingStatus';
import BaseModal from '../../../../../BaseModal';
import RegisterActivity from '../../../../../RegisterActivity';
import UnlinkActivityModal from '../../../../../UnlinkActivityModal';

export interface IEventCardProps {
  trainingOfDay: ITrainingOfDay;
  handleOpenActivityDetailsModal?: (tId: number) => void;
}

export default function EventCard({ trainingOfDay, handleOpenActivityDetailsModal }: IEventCardProps) {
  const navigate = useNavigate();

  const [descriptionModalActive, setDescriptionModalActive] = React.useState(false);
  const [registerModalVisible, setRegisterModalVisible] = React.useState(false);
  const [unlinkModalVisible, setUnlinkModalVisible] = React.useState(false);

  function goToTraining() {
    navigate({
      pathname: `/exerciseList/${trainingOfDay.type}`,
      search: `?${createSearchParams({
        trainingToAttachId: trainingOfDay?.id.toString(),
      })}`,
    });
  }

  const handleOpenActivityDetails = () => {
    if (trainingOfDay?.activityOfTraining) {
      handleOpenActivityDetailsModal!(trainingOfDay?.id);
    }
  };

  return (
    <>
      <Container key={trainingOfDay?.id}>
        <EventCardHeader>
          {formatTrainingIcon(trainingOfDay?.type.toUpperCase())}

          <div onClick={handleOpenActivityDetails} aria-hidden>
            <Title>
              <p>
                {trainingOfDay?.name || ''}
              </p>

              {
                (!!trainingOfDay?.status || isBefore(new Date(trainingOfDay.date), new Date()))
                && (
                  <TrainingStatus status={trainingOfDay?.status} small />
                )
              }
            </Title>
            <SubTitle>
              {
                trainingOfDay?.type.toUpperCase() !== TrainingType.RUN
                && (
                  <TrainingSubDescription>
                    <TrainingSubDescriptionText>
                      {
                        trainingOfDay?.equipments
                          ? trainingOfDay?.equipments
                          : 'Apenas peso do corpo'
                      }
                    </TrainingSubDescriptionText>
                  </TrainingSubDescription>
                )
              }

              {
                trainingOfDay?.type.toUpperCase() === TrainingType.RUN
                && (
                  <TrainingSubDescription>
                    {
                      trainingOfDay?.activityType
                        === TrainingType.DURATION
                        ? (
                          <TrainingSubDescriptionText>
                            {
                              trainingOfDay?.duration
                                ? `${trainingOfDay?.duration}`
                                : '--:--'
                            }
                          </TrainingSubDescriptionText>
                        )
                        : (
                          <TrainingSubDescriptionText>
                            {!!trainingOfDay?.distance && `${trainingOfDay?.distance} km`}
                          </TrainingSubDescriptionText>
                        )
                    }
                  </TrainingSubDescription>
                )
              }
            </SubTitle>
          </div>

          {
            !!trainingOfDay?.description
            && (
              <>
                <MdInfoOutline name="info-outline" size={20} color="#fff" onClick={() => setDescriptionModalActive(true)} />
                <BaseModal title="" isVisible={descriptionModalActive} setIsVisible={setDescriptionModalActive}>
                  <DescriptionModal>
                    <h1>{trainingOfDay.name}</h1>
                    <p>{trainingOfDay.description}</p>
                    <Button onClick={() => setDescriptionModalActive(false)}>Ok</Button>
                  </DescriptionModal>
                </BaseModal>
              </>
            )
          }
        </EventCardHeader>

        <EventCardBody>
          {
            trainingOfDay?.type.toUpperCase() === TrainingType.RUN
            && !!trainingOfDay?.trainingGroups
            && trainingOfDay?.trainingGroups?.map((item, tgIDX) => (
              <TrainingGroupContainer key={item?.id}>
                <TrainingGroup>
                  <>
                    <TrainingGroupContent>
                      <TrainingGroupCount>
                        {`${tgIDX + 1}.`}
                      </TrainingGroupCount>
                      <TrainingGroupText>
                        {(item?.repetition && item?.repetition > 1) ? `${item?.name} | Repetir ${item?.repetition} vezes` : `${item?.name}`}
                      </TrainingGroupText>
                    </TrainingGroupContent>
                    <ol>
                      {

                        item.trainingActivities.map((trainingActivitie) => (
                          <li key={trainingActivitie.id}>
                            <span>
                              {trainingActivitie?.activityType
                                !== TrainingType.DURATION
                                && trainingActivitie.distance ? `${trainingActivitie.distance} km` : ''}
                              {trainingActivitie?.activityType
                                === TrainingType.DURATION
                                && trainingActivitie.duration ? `${formatDuration(trainingActivitie.duration, 'time')}` : ''}
                              {trainingActivitie.zone ? ` @ ${trainingActivitie.zone}` : ''}
                            </span>
                          </li>
                        ))
                      }

                    </ol>
                  </>
                </TrainingGroup>
              </TrainingGroupContainer>
            ))
          }
        </EventCardBody>
        <EventCardFooter>
          {
            !trainingOfDay?.activityOfTraining
            && trainingOfDay?.type.toUpperCase() !== 'RUN'
            && !trainingOfDay?.status
            && (
              <div role="presentation" onClick={() => goToTraining()}>
                <MdPlayCircleOutline size={10} />
                Realizar série
              </div>
            )
          }
          {
            !trainingOfDay?.activityOfTraining
            && !trainingOfDay?.status
            && (
              <div role="presentation" onClick={() => setRegisterModalVisible(true)}>
                <MdAddCircleOutline size={10} />
                Registrar atividade
              </div>
            )
          }

          {
            trainingOfDay?.status
            && (
              <div
                className="underline"
                role="presentation"
                onClick={() => setUnlinkModalVisible(true)}
              >
                <MdDeleteOutline size={10} />
                Desvincular atividade
              </div>
            )
          }
        </EventCardFooter>
      </Container>
      <RegisterActivity
        visible={registerModalVisible}
        close={() => setRegisterModalVisible(false)}
        _title={trainingOfDay.name}
        _date={trainingOfDay.date}
        _type={trainingOfDay.type}
        clearStatesOnClose={false}
        trainingId={trainingOfDay.id}
        submitCallback={() => window.location.reload()}
      />
      <UnlinkActivityModal
        setShowModal={setUnlinkModalVisible}
        showModal={unlinkModalVisible}
        callback={() => window.location.reload()}
        activityId={trainingOfDay?.activityOfTraining?.id}
        trainingId={trainingOfDay?.id}
        trainingDate={trainingOfDay?.date}
        trainingName={trainingOfDay?.name}
      />
    </>
  );
}

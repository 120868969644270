import React from 'react';

import {
  Title,
  SubTitle,
  FormGroup,
  FormTitle,
  Input,
  Form,
  Header,
} from './styles';

interface ExerciseMetricsI {
  heartRate: string
  maxHeartRate: string
  calories: string
  setHeartRate: any
  setMaxHeartRate: any
  setCalories: any
}

function ExerciseMetrics({
  heartRate,
  maxHeartRate,
  calories,
  setHeartRate,
  setMaxHeartRate,
  setCalories,
}: ExerciseMetricsI) {
  return (
    <Header data-testid="exercise-metrics">
      <Title>PREENCHA SUAS MÉTRICAS</Title>
      <SubTitle>(OPCIONAL)</SubTitle>
      <FormGroup>
        <Form>
          <FormTitle>FC Média</FormTitle>
          <Input
            type="text"
            style={{ height: 30, fontSize: 14, padding: 0 }}
            placeholder="0 BPM"
            value={heartRate}
            onChange={(e) => setHeartRate(e.target.value)}
          />
        </Form>
        <Form>
          <FormTitle>FC Máxima</FormTitle>
          <Input
            type="text"
            style={{ height: 30, fontSize: 14, padding: 0 }}
            placeholder="0 BPM"
            value={maxHeartRate}
            onChange={(e) => setMaxHeartRate(e.target.value)}
          />
        </Form>
        <Form>
          <FormTitle>Calorias</FormTitle>
          <Input
            type="text"
            style={{ height: 30, fontSize: 14, padding: 0 }}
            placeholder="0 kcal"
            value={calories}
            onChange={(e) => setCalories(e.target.value)}
          />
        </Form>
      </FormGroup>
    </Header>
  );
}

export default ExerciseMetrics;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ActiveTrainingPlanModal from './ActiveTrainingPlanModal';
import {
  Container,
  HeaderContainer,
  PlanText,
  TextInfo,
  ButtonOpenModalTitle,
} from './styles';

export default function CurrentPlanRequest() {
  const [showActiveTrainingPlanModal, setShowActiveTrainingPlanModal] = useState(false);

  const trainingPlanName = useSelector((state: RootState) => state.trainingPlan.name);
  const trainingPlanEndDate = useSelector((state: RootState) => state.trainingPlan.endDate);
  const trainingPlanId = useSelector((state: RootState) => state.trainingPlan.planId);

  return (
    <>
      <ActiveTrainingPlanModal
        showModal={showActiveTrainingPlanModal}
        setShowModal={setShowActiveTrainingPlanModal}
        trainingPlanName={trainingPlanName!}
        trainingPlanEndDate={trainingPlanEndDate!}
        hasTrainingPlan={!!trainingPlanId}
        closeWhenCancel
      />

      <Container data-testid="current-plan-request-container">
        <HeaderContainer>
          <PlanText>
            <h3>TREINO SOLICITADO</h3>
          </PlanText>
          <TextInfo>
            <p>
              Seu plano de treinos está sendo preparado por nossos treinadores.
            </p>
          </TextInfo>
          <ButtonOpenModalTitle
            onClick={() => setShowActiveTrainingPlanModal(true)}
          >
            Ver detalhes
          </ButtonOpenModalTitle>
        </HeaderContainer>
      </Container>
    </>
  );
}

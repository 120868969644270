import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { appTheme } from '../../../../theme';
import {
  Container, Description, Item, ItemDescription,
  Label, LabelContainer, List, Radio,
  RadioContainer, RadioInner, Title,
} from './styles';

export interface ISetSelectedWeekScreenProps {
  step: IStep,
  selectedWeek: number | null,
  clickCallBack: (value: string | number) => void,
  setButtonDisabled: (bool: boolean) => void,
  description?: string | null,
}

function SetSelectedWeekScreen({
  step, selectedWeek, clickCallBack, setButtonDisabled, description = null,
}: ISetSelectedWeekScreenProps) {
  useEffect(() => {
    if (selectedWeek) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [selectedWeek]);

  return (
    <Container>
      <Title>{step.title}</Title>

      {
        description
        && <Description>{description}</Description>
      }

      <List>
        {
          step.data.map((item: IStepData) => (
            <Item
              key={item.label}
              onClick={() => clickCallBack(item.value as string)}
              style={{ borderColor: `${selectedWeek === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer hasItemDescription={!!item.description}>
                <Radio
                  style={{
                    backgroundColor: selectedWeek === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: selectedWeek === item.value ? appTheme.title : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer hasItemDescription={!!item.description}>
                <Label>{(item.label as string).toUpperCase()}</Label>
                {item.description && <ItemDescription>{item.description}</ItemDescription>}
              </LabelContainer>
            </Item>
          ))
        }
      </List>
    </Container>
  );
}

export default SetSelectedWeekScreen;

import React from 'react';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import BaseModal from '../BaseModal';
import { IProps } from './contracts';
import {
  ModalBackground,
  ModalContent,
  ModalContentTitle,
  ModalContentSubTitle,
  ModalContentButton,
  ModalContentButtonText,
  ModalContentButtonsContainer,
} from './styles';
import api from '../../services/api';
import { appTheme } from '../../theme';

function UnlinkActivityModal({
  showModal, setShowModal, trainingName, trainingDate,
  trainingId, activityId, activityTitle, callback,
}: IProps) {
  const unlinkActivity = async () => {
    try {
      await api.put(`user_activities/${activityId}`, { training_id: null, visible: true, title: activityTitle });

      await api.put(`trainings/${trainingId}`, { executed_status: null });

      callback();

      setShowModal(false);
    } catch (error) {
      toast.error('Ocorreu um erro ao desvincular a atividade!');
    }
  };

  return (
    <BaseModal
      title=""
      setIsVisible={setShowModal}
      isVisible={showModal}
    >
      <ModalBackground>
        <ModalContent>
          <ModalContentTitle>DESEJA DESVINCULAR A ATIVIDADE?</ModalContentTitle>

          <ModalContentSubTitle>
            {trainingName && trainingDate ? `${format(new Date(trainingDate), 'dd/MM')} - ${trainingName}` : 'n/a'}
          </ModalContentSubTitle>

          <ModalContentButtonsContainer>
            <ModalContentButton color={appTheme.redAlert} onClick={unlinkActivity}>
              <ModalContentButtonText color={appTheme.redAlert}>DESVINCULAR</ModalContentButtonText>
            </ModalContentButton>
            <ModalContentButton color={appTheme.title} onClick={() => setShowModal(false)}>
              <ModalContentButtonText color={appTheme.title}>CANCELAR</ModalContentButtonText>
            </ModalContentButton>
          </ModalContentButtonsContainer>
        </ModalContent>
      </ModalBackground>
    </BaseModal>
  );
}

export default UnlinkActivityModal;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background: ${theme.colors.cardB1};
  height: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2%;
  min-height: 200px;
`;

interface Props {
  hasTutoLink: boolean
}
export const Content = styled.div<Props>`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.hasTutoLink ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: 2%;
`;

export const IntrusctionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
`;

export const InstructionsText = styled.p`
  font-size: 12px;
  text-decoration-line: underline;
  margin-left: 4%;
  color: ${theme.colors.title};
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const StatusText = styled.h3`
  font-size: 16px;
  margin-left: 4%;
  color: ${theme.colors.title};
  font-weight: 700;
`;

export const SkipSerieButton = styled.div`
  width: 100%;
  height: 20%;
  border-top-width: 2px;
  border-color: ${theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  cursor: pointer;
`;

export const SkipSerieText = styled.p`
  color: ${theme.colors.title};
  font-weight: 600;
`;

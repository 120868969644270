import React, {
  useEffect, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { format, addSeconds } from 'date-fns';

import { AiOutlineInfo } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  ButtomInfo,
  ConainerLoader,
  Container, ContainerNumber, ContentImage, ModalManagement, ModalManagementContent, TimerContainer, Title,
} from './styles';

import { RootState } from '../../store';
import ExerciseInitModal from '../../components/ExerciseInitModal';
import NextTrainingButton from '../../components/NextTrainingButton';
import PauseButton from '../../components/PauseButton';
import ExerciseOnProgressPanel from '../../components/ExerciseOnProgressPanel';
import ExerciseInfoModal from '../../components/ExerciseInfoModal';
import SkipSerieConfirmationModal from '../../components/SkipSerieConfirmationModal';
import AbandonExerciseModal from '../../components/AbandonExerciseModal';
import AbandonExerciseModalConfirm from '../../components/AbandonExerciseModalConfirm';
import api from '../../services/api';
import ExercisePauseModal from '../../components/ExercisePauseModal';
import FinishExercise from '../../components/FinishExercise';
import FinishExerciseSerie from '../../components/FinishExerciseSeries';
import ExercisePanelSpeed from '../../components/ExercisePanelIntensity';
import Loader from '../../components/Loader';
import { useQuery } from '../../utils';

interface ToSaveBasicInfoI {
  id: number
  runtime: number
  executed_status: string
}
interface ToSaveGroups extends ToSaveBasicInfoI {
  trainingActivities: Array<ToSaveBasicInfoI>
}
interface ToSaveExerciseI extends ToSaveBasicInfoI {
  trainingGroups: Array<ToSaveGroups>
}

enum ModalManagementEnum {
  Init,
  Pause,
  SkipConfirm,
  Abandon,
  AbandonConfirm,
  Info
}

function ExerciseExecution() {
  const query = useQuery();
  const trainingToAttachId = query.get('trainingToAttachId');
  const exerciseL = useSelector((state: RootState) => state.exercisesList.exercisesList);
  const [isVisible, setIsVisible] = useState(false);
  const [isStart, setIsStart] = useState(false);

  const [loaderImg, setLoaderImg] = useState(false);

  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [activityIndex, setActivityIndex] = useState<number>(0);
  const [currentActivity, setCurrentActivity] = useState(exerciseL?.trainingGroups[0]?.trainingActivities[0]!);
  const [nextActivity, setNextActivity] = useState(exerciseL?.trainingGroups[0]?.trainingActivities[1]!);
  const [nextGroup, setNextGroup] = useState(exerciseL?.trainingGroups[1]!);
  const [groupsResult, setGroupsResult] = useState<Array<number>>([]);
  const [currentGroup, setCurrentGroup] = useState(exerciseL?.trainingGroups[0]!);

  const [groupRuntime, setGroupRuntime] = useState<number>(0);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [finishedSerie, setFinishedSerie] = useState<boolean>(false);
  const [finishedSerieName, setFinishedSerieName] = useState<string>();
  const [trainingFinished, setTrainingFinished] = useState<boolean>(false);
  const [trainingToSave, setTrainingToSave] = useState<ToSaveExerciseI | undefined>();
  const [isAbandon, setIsAbandon] = useState<boolean>(false);
  const [activityRuntime, setActivityRuntime] = useState<number>(0);

  const [fullTime, setFullTime] = useState<any>({ time: 0, display: '00:00' });
  const [saving, setSaving] = useState<boolean>(false);
  const [started, setStarted] = useState(false);
  const [auxTime, setAuxTime] = useState<any>();
  const [modalSelected, setModalSelected] = useState<ModalManagementEnum>(
    ModalManagementEnum.Pause,
  );
  const intervalRef = useRef<NodeJS.Timeout>();
  const currentExercise = useSelector((state: RootState) => state.exercises.inProgressExercise);
  const userId = useSelector((state: RootState) => state.auth.user.id);

  const [heartRate, setHeartRate] = useState<string>('');
  const [maxHeartRate, setMaxHeartRate] = useState<string>('');
  const [calories, setCalories] = useState<string>('');

  const [videotitle, setVideoTitle] = useState<string | null>();
  const [videoUri, setVideoUri] = useState<string | null>();
  const [isVisibleModInfo, setIsVisibleModInfo] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (exerciseL.active === false || !currentExercise!.trainingGroups) {
      navigate('/training');
    } else {
      setIsVisible(true);
      if (currentActivity?.duration != null) {
        setAuxTime(currentActivity?.duration || 1 * 60);
      }

      const toSaveDraft = {
        id: trainingToAttachId ? parseInt(trainingToAttachId, 10) : currentExercise?.id!,
        runtime: 0,
        executed_status: 'EXECUTED_HIGH',
        trainingGroups: currentExercise!.trainingGroups.map((group) => ({
          id: group.id,
          runtime: 0,
          executed_status: 'EXECUTED_HIGH',
          trainingActivities: group!.trainingActivities!.map((act) => ({
            id: act.id,
            runtime: 0,
            executed_status: 'EXECUTED_HIGH',
          })),
        })),
      };

      setTrainingToSave(toSaveDraft);
    }
  }, []);

  useEffect(() => {
    setCurrentActivity(exerciseL
      ?.trainingGroups[groupIndex]?.trainingActivities[activityIndex]!);
    setNextActivity(exerciseL
      ?.trainingGroups[groupIndex]?.trainingActivities[activityIndex + 1]!);
  }, [activityIndex, currentGroup]);

  useEffect(() => {
    setActivityIndex(0);
    setCurrentGroup(exerciseL?.trainingGroups[groupIndex]!);
    setNextGroup(exerciseL?.trainingGroups[groupIndex + 1]!);
  }, [groupIndex]);

  useEffect(() => {
    setTimeout(() => {
      setModalSelected(ModalManagementEnum.Init);
      setModalOpen(true);
    }, 200);
  }, []);

  useEffect(() => {
    setActivityRuntime((prev) => prev + 1);
    setGroupRuntime((prev) => prev + 1);
  }, [fullTime]);

  const playCounter = () => {
    let counter = fullTime.time;
    const date = new Date(0);
    addSeconds(date, counter);
    intervalRef.current = setInterval(() => {
      counter++;
      setFullTime({ time: counter, display: format(addSeconds(date, counter), 'mm:ss') });
    }, 1000);
  };

  const pauseCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const backToCover = () => {
    setModalOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, 100);
  };

  const pauseExercise = () => {
    setModalSelected(ModalManagementEnum.Pause);
    setModalOpen(true);
    pauseCounter();
  };

  const unpauseExercise = () => {
    setModalOpen(false);
    playCounter();
  };

  const handleNextGroup = () => {
    const toSaveDraft = trainingToSave;
    toSaveDraft!.trainingGroups[groupIndex]!.runtime! = groupRuntime;
    setTrainingToSave(toSaveDraft);
    setGroupRuntime(0);
    if (nextGroup) {
      setActivityIndex(0);
      setGroupIndex((prev) => prev + 1);
    } else {
      pauseCounter();
      toSaveDraft!.runtime = fullTime.time;
      toSaveDraft!.executed_status = 'EXECUTED_LOW';
      setTrainingToSave(toSaveDraft);
      setTrainingFinished(true);
    }
  };

  const skipSerie = () => {
    pauseCounter();
    setFinishedSerieName(currentGroup.name);
    handleNextGroup();
    setFinishedSerie(true);
    setModalOpen(false);
  };

  function continueExercise() {
    if (currentActivity.duration != null) {
      setAuxTime(Math.round(currentActivity.duration * 60));
    }
    setFinishedSerie(false);
    if (isAbandon) setIsAbandon(false);
    playCounter();
  }

  const initExercise = () => {
    setModalOpen(false);
    setStarted(true);
    playCounter();
  };

  const handleNextActivity = () => {
    setLoaderImg(true);
    const toSaveDraft = trainingToSave;

    if (toSaveDraft) {
      toSaveDraft!.trainingGroups[groupIndex]!.trainingActivities[activityIndex]!.runtime! = activityRuntime;
      setTrainingToSave(toSaveDraft);
      setLoaderImg(false);
    }
    setActivityRuntime(0);
    if (nextActivity) {
      setActivityIndex((prev) => prev + 1);
      setLoaderImg(false);
    } else {
      pauseCounter();
      setFinishedSerieName(currentGroup.name);
      setGroupsResult([...groupsResult, currentGroup.id]);
      handleNextGroup();
      setFinishedSerie(true);
      setLoaderImg(false);
    }
  };

  const exitExercise = () => {
    setModalOpen(false);
    navigate('/Home');
  };

  const saveExercise = async () => {
    setSaving(true);

    if (currentExercise) {
      const trainingToUpdate = {
        training: { ...trainingToSave, runtime: fullTime.time },
        from: trainingToAttachId ? 'EXPLORER' : currentExercise?.from || '',
        trainingStatus: 'EXECUTED_LOW',
        userActivity: {
          title: exerciseL.title,
          type: currentExercise.type,
          start_date: currentExercise.start_date,
          user_id: userId,
          calorie: calories,
          heart_rate: heartRate,
          max_heartrate: maxHeartRate,
        },
      };

      let url = `/trainings/${trainingToUpdate!.training!.id}/update-metrics`;

      if (trainingToAttachId) {
        url = `${url}?associateScheduledTraining=true`;
      }

      try {
        await api.post(url, trainingToUpdate);

        toast.success('Treino Salvo Com Sucesso!');
      } catch (err) {
        toast.error('Erro, Tente novamente!');
      }
    }

    exitExercise();

    setSaving(false);
  };

  const initVideo = (vtitle: any, uri: any) => {
    setVideoTitle(vtitle);
    setVideoUri(uri);
    setIsVisibleModInfo(true);
  };

  const openInfo = () => {
    pauseCounter();
    pauseExercise();
    initVideo(currentActivity.name, currentActivity.tuto_link);
  };

  const modalsManagement = () => {
    switch (modalSelected) {
      case ModalManagementEnum.Init:
        return (
          <ExerciseInitModal
            onPressInit={initExercise}
            onPressBack={backToCover}
          />
        );
      case ModalManagementEnum.SkipConfirm:
        return (
          <SkipSerieConfirmationModal
            onPressBack={() => setModalSelected(ModalManagementEnum.Pause)}
            onPressSkip={skipSerie}
          />
        );
      case ModalManagementEnum.Abandon:
        return (
          <AbandonExerciseModal
            onPressBack={() => setModalSelected(ModalManagementEnum.Pause)}
            onPressAbandon={() => setModalSelected(ModalManagementEnum.AbandonConfirm)}
          />
        );
      case ModalManagementEnum.AbandonConfirm:
        return (
          <AbandonExerciseModalConfirm
            saving={saving}
            onPressBack={() => setModalSelected(ModalManagementEnum.Abandon)}
            onPressExit={exitExercise}
            onPressSave={saveExercise}
          />
        );
      case ModalManagementEnum.Info:
        return currentActivity.tuto_link
          ? (
            <ExerciseInfoModal
              isModal={false}
              title={currentActivity.name}
              videoUri={currentActivity.tuto_link}
              close={() => {
                setModalSelected(ModalManagementEnum.Pause);
              }}
            />
          ) : <></>;
      default:
        return (
          <ExercisePauseModal
            hasTutoLink={!!currentActivity.tuto_link}
            onPressContinue={unpauseExercise}
            onPressAbandon={() => setModalSelected(ModalManagementEnum.Abandon)}
            onPressNextSerie={() => setModalSelected(ModalManagementEnum.SkipConfirm)}
            openInfo={() => setModalSelected(ModalManagementEnum.Info)}
          />
        );
    }
  };

  return (
    <Container
      finishColor={finishedSerie || trainingFinished}
    >

      {
        trainingFinished
          ? (
            <FinishExercise
              saving={saving}
              currentExercise={currentExercise!}
              time={fullTime.display}
              heartRate={heartRate}
              maxHeartRate={maxHeartRate}
              calories={calories}
              setHeartRate={setHeartRate}
              setMaxHeartRate={setMaxHeartRate}
              setCalories={setCalories}
              saveExercise={saveExercise}
            />
          )
          : finishedSerie
            ? (
              <FinishExerciseSerie
                title={finishedSerieName || ''}
                time={fullTime.display}
                isAbandon={isAbandon}
                saving={saving}
                heartRate={heartRate}
                maxHeartRate={maxHeartRate}
                calories={calories}
                setHeartRate={setHeartRate}
                setMaxHeartRate={setMaxHeartRate}
                setCalories={setCalories}
                continueExercise={continueExercise}
                setIsAbandon={setIsAbandon}
                saveExercise={saveExercise}
                exitExercise={exitExercise}
              />
            )
            : (
              <>
                <ContentImage>
                  {
                    loaderImg
                      ? (
                        <ConainerLoader>
                          <Loader />
                        </ConainerLoader>
                      )
                      : (
                        <>
                          {
                            currentActivity?.media_link
                            && <img src={currentActivity?.media_link} alt="Exercício" />
                          }
                        </>
                      )
                  }

                  <Title>
                    <h2>{currentActivity?.name}</h2>
                    <ExercisePanelSpeed
                      speed={currentActivity?.execution_speed || 0}
                    />
                  </Title>
                  <ButtomInfo
                    type="button"
                    onClick={() => openInfo()}
                  >
                    <AiOutlineInfo />
                  </ButtomInfo>
                  <PauseButton
                    onPress={pauseExercise}
                  />
                </ContentImage>

                {
                  isStart
                    && (
                      <>
                        {
                          currentActivity?.repetition
                            ? (
                              <>
                                <ExerciseOnProgressPanel
                                  trainingCompleted={handleNextActivity}
                                  exercise={currentActivity}
                                  time={fullTime.time}
                                  playTime={playCounter}
                                  auxTime={auxTime}
                                  setAuxTime={setAuxTime}
                                  started={started}
                                  pauseTime={pauseCounter}
                                />
                                <ContainerNumber>
                                  {currentActivity?.repetition}
                                  x
                                </ContainerNumber>
                              </>
                            )
                            : (
                              <>
                                <ExerciseOnProgressPanel
                                  trainingCompleted={handleNextActivity}
                                  exercise={currentActivity}
                                  time={fullTime.time}
                                  playTime={playCounter}
                                  auxTime={auxTime}
                                  setAuxTime={setAuxTime}
                                  started={started}
                                  pauseTime={pauseCounter}
                                />
                                <TimerContainer>
                                  <h3>
                                    {currentActivity?.repetition
                                      ? (
                                        <>
                                          {`${currentActivity?.repetition}X`}
                                        </>
                                      )
                                      : (
                                        <>
                                          {auxTime}
                                          <span> SEG</span>
                                        </>
                                      )}
                                  </h3>
                                </TimerContainer>
                              </>

                            )
                        }
                      </>
                    )
                }

                <NextTrainingButton
                  currentActivity={currentActivity}
                  nextActivity={nextActivity}
                  onPress={handleNextActivity}
                />

                <ExerciseInitModal
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  setIsStart={setIsStart}
                  onPressInit={initExercise}
                />

                <ExerciseInfoModal
                  setIsVisible={setIsVisibleModInfo}
                  isVisible={isVisibleModInfo}
                  title={videotitle}
                  videoUri={videoUri}
                />

                {
                  modalOpen
                    && (
                      <ModalManagement>
                        <ModalManagementContent>
                          {modalsManagement()}
                        </ModalManagementContent>
                      </ModalManagement>
                    )
                }

              </>
            )
      }

    </Container>

  );
}

export default ExerciseExecution;

import React, { useState } from 'react';
import { MdModeEdit, MdInfo } from 'react-icons/md';
import {
  Container, Distance, Header, Label, Medal, HeaderContainer,
  InfoContainer,
  EditContainer,
  EditText,
  TitleContainer,
  IconButton,
} from './styles';

import SerraGauchaMedal from '../../assets/challenges/medal-serra-gaucha-long.png';
import { ChallengeStatesTypes } from '../../pages/ChallengePage';
import ChallengeInfoModal from '../ChallengeInfoModal';

interface Props {
  edit: () => void
  status: ChallengeStatesTypes
}

export default function ChallengeHeader({ edit, status }: Props) {
  const [infoModal, setInfoModal] = useState(false);
  return (
    <>
      <ChallengeInfoModal isVisible={infoModal} setVisible={setInfoModal} />
      <Container>

        <Medal src={SerraGauchaMedal} />
        <HeaderContainer>
          <TitleContainer>
            <Header>desafio serra gaúcha</Header>
            <IconButton onClick={() => setInfoModal(true)}>
              <MdInfo color="white" size={22} />
            </IconButton>
          </TitleContainer>
          <InfoContainer>
            <div>
              <Label>Distância</Label>
              <Distance>135km</Distance>
            </div>
            {status === 'IN_PROGRESS' && (
              <EditContainer onClick={edit}>
                <MdModeEdit color="white" />
                <EditText>Editar meta de tempo</EditText>
              </EditContainer>
            )}
          </InfoContainer>
        </HeaderContainer>
      </Container>
    </>
  );
}

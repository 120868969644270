import styled from 'styled-components';
import theme from '../../theme';
import { ModalContent as _ModalContent } from '../Modal/Modal.styles';

export const ModalContent = styled(_ModalContent)`
    padding: 1rem;
`;

export const ModalContentTitle = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
`;

export const ModalContentSubTitleContainer = styled.section`
  margin-top: 15px;
`;

export const ModalContentSubTitle = styled.div`
  color: ${theme.colors.title};
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
`;
export const ModalContentButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;

`;

export const ModalContentButton = styled.button<{color: string}>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  padding: .5rem;

  border-radius: 5px;
  margin-top: 10px;
  border-color: ${theme.colors.title};
  border-width: 1px;
  border-style: solid;
  background-color: ${({ color }) => (color === 'primary' ? theme.colors.primary : theme.colors.title)};

  &:first-of-type ~ & {
    margin-left: 1rem;
  }
`;

export const ModalContentButtonText = styled.span`
  color: ${theme.colors.primary};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
 
`;

export const ModalContentButtonTextCancel = styled.span`
  color: ${theme.colors.title};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
 
`;

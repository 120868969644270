import * as React from 'react';
// @ts-ignore
import InputMask from 'react-input-mask';

import {
  Input, InputContainer, Label, ErrorMessage,
} from '../FormInput/styles';

export interface IMaskedInput extends React.InputHTMLAttributes<HTMLInputElement>{
  label?: string;
  inputValue: string;
  mask: string;
  error?: boolean;
  errorMessage?: string;
  customInputContainerStyle?: React.CSSProperties;
}

export default function MaskedInput({
  label, mask, inputValue, error, errorMessage, onChange, customInputContainerStyle, ...rest
}: IMaskedInput) {
  return (
    <InputContainer data-testid="outer-maskedinput-container">
      {label && (<Label>{label}</Label>)}
      <InputMask
        mask={mask}
        value={inputValue}
        onChange={onChange}
      >
        {/*
        // @ts-ignore */}
        {() => (
          <Input
            value={inputValue}
            style={customInputContainerStyle}
            {...rest}
          />
        )}
      </InputMask>
      {error && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
}

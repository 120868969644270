import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth } from 'date-fns';
import {
  Container, ContainerSVG, ContentCalendar, OuterSwitchContainer, SwitchButtonsContainer, SwitchCurrentCalendarButton, SwitchCurrentCalendarButtonText, Title,
} from './styles';
import { RootState } from '../../store';
import { CalendarLoadingState, getTrainingsByDateAndUser } from '../../store/CalendarProgress.store';
import MonthCalendar from './MonthCalendar';
import WeekCalendar from './WeekCalendar';
import WeekRegularityCards from './WeekCalendar/Cards';
import PolygonIcon from '../../assets/progress/polygon.svg';
import SkeletonGeneric from '../SkeletonGeneric';

const smallLoadingSkeletonStyle = {
  width: '100%', height: '55px', borderRadius: '10px', margin: '0 10px',
};
const bigLoadingSkeletonStyle = {
  width: '100%', height: '300px', marginTop: 20, marginBottom: 20, borderRadius: '10px',
};

function ProgressCalendar() {
  const userId = useSelector((state: RootState) => state.auth.user.id);
  const { loading: calendarDataLoading, firstLoad: calendarFirstLoading } = useSelector((state: RootState) => state.calendarData);
  const dispatch = useDispatch();

  const [currentAtiveCalendar, setCurrentAtiveCalendar] = React.useState<'month' | 'week'>('week');

  const weekCalendarActive = React.useMemo(() => currentAtiveCalendar === 'week', [currentAtiveCalendar]);
  const monthCalendarActive = React.useMemo(() => currentAtiveCalendar === 'month', [currentAtiveCalendar]);

  useEffect(() => {
    const dateString = new Date().toISOString();
    const lastMonthDay = endOfMonth(new Date(dateString)).toISOString();
    const currentDate = new Date(dateString);
    const firstMonthDayDate = new Date(currentDate.setDate(1));
    const firstMonthDay = firstMonthDayDate.toISOString();

    dispatch(
      getTrainingsByDateAndUser({ userId, start_date: firstMonthDay, end_date: lastMonthDay }) as any,
    );
  }, []);

  return (
    <Container>
      <Title>
        Suas atividades
      </Title>

      <SwitchButtonsContainer>
        {(calendarDataLoading === CalendarLoadingState.loading && calendarFirstLoading && (
          <>
            <SkeletonGeneric customContainerStyle={smallLoadingSkeletonStyle} />
            <SkeletonGeneric customContainerStyle={smallLoadingSkeletonStyle} />
          </>
        )) || (
          <>
            <OuterSwitchContainer>
              <SwitchCurrentCalendarButton onClick={() => setCurrentAtiveCalendar('week')} active={weekCalendarActive}>
                <SwitchCurrentCalendarButtonText active={weekCalendarActive}>
                  Nesta semana
                </SwitchCurrentCalendarButtonText>
              </SwitchCurrentCalendarButton>
              {currentAtiveCalendar === 'week' && (
                <ContainerSVG>
                  <img alt="Alça da aba" src={PolygonIcon} style={{ height: 24, width: 36 }} />
                </ContainerSVG>
              )}
            </OuterSwitchContainer>

            <OuterSwitchContainer>
              <SwitchCurrentCalendarButton onClick={() => setCurrentAtiveCalendar('month')} active={monthCalendarActive}>
                <SwitchCurrentCalendarButtonText active={monthCalendarActive}>
                  Neste mês
                </SwitchCurrentCalendarButtonText>
              </SwitchCurrentCalendarButton>
              {currentAtiveCalendar === 'month' && (
                <ContainerSVG>
                  <img alt="Alça da aba" src={PolygonIcon} style={{ height: 24, width: 36 }} />
                </ContainerSVG>
              )}
            </OuterSwitchContainer>

          </>
        )}

      </SwitchButtonsContainer>

      {(calendarDataLoading === CalendarLoadingState.loading && calendarFirstLoading && (
        <SkeletonGeneric customContainerStyle={bigLoadingSkeletonStyle} />
      )) || (
        <ContentCalendar>
          {currentAtiveCalendar === 'month' ? <MonthCalendar />
            : (
              <>
                <WeekRegularityCards />
                <WeekCalendar />
              </>
            )}
        </ContentCalendar>
      )}

    </Container>
  );
}

export default ProgressCalendar;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { BsArrowClockwise } from 'react-icons/bs';
import WeekChart from '../Chart/WeekChart';
import MonthChart from '../Chart/MonthChart';
import YearChart from '../Chart/YearChart';
import api from '../../services/api';
import { RootState } from '../../store';
import {
  Container, ContainerSVG, ContainerTab, MainContainer, Tab, Tabs, TabTitle, TabTitleSub, TabValue, TextContainer, Title,
} from './styles';
import { putTab } from '../../store/SharedNavigationProps.store';
import Polygon from '../../assets/progress/polygon.svg';
import ChartCardSkeleton from '../Chart/ChartCardSkeleton';

type tabOptionsType = {
  [key: string]: JSX.Element
}

export type ChartInfo = {
  x: number
  y: number
  pace?: number
  color?: string
  value?: number
  week?: {
    start: string
    end: string
  }
}
export interface ChartData {
  data: Array<ChartInfo>
  total: number
}

const initialData = {
  data: [{ x: 0, y: 0 }],
  total: 0,
};

export default function ProgressAccumulatedDistance() {
  const selectedTab = useSelector((state: RootState) => state.sharedNavigationProps.trainingScreenTab);
  const [weekData, setWeekData] = useState<ChartData>(initialData);
  const [monthData, setMonthData] = useState<ChartData>(initialData);
  const [yearData, setYearData] = useState<ChartData>(initialData);

  const [weekLoading, setWeekLoading] = useState(true);
  const [monthLoading, setMonthLoading] = useState(true);
  const [yearLoading, setYearLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const TABS = [
    {
      name: 'Nesta semana',
      id: 0,
      value: parseFloat(weekData.total.toFixed(2)).toString(),
    }, {
      name: 'Neste mês',
      id: 1,
      value: parseFloat(monthData.total.toFixed(2)).toString(),
    }, {
      name: 'Útimos 12 meses',
      id: 2,
      value: parseFloat(yearData.total.toFixed(2)).toString(),
    },
  ];

  const tabOptions: tabOptionsType = {
    0: <WeekChart key={JSON.stringify(weekData)} data={weekData} loading={weekLoading} />,
    1: <MonthChart key={JSON.stringify(monthData)} data={monthData} loading={monthLoading} />,
    2: <YearChart key={JSON.stringify(yearData)} data={yearData} loading={yearLoading} />,
  };

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const handleSelectTab = (id: number) => {
    dispatch(putTab(id) as any);
  };

  const getWeekData = async () => {
    await api.get(`week_progress?userId=${user?.id}`)
      .then((response) => {
        setWeekData(response?.data);
        setWeekLoading(false);
      });
  };

  const getMonthData = async () => {
    await api.get(`month_progress?userId=${user?.id}`)
      .then((response) => {
        setMonthData(response?.data);
        setMonthLoading(false);
      });
  };

  const getYearData = async () => {
    api.get(`recent_month_progress?userId=${user?.id}`)
      .then((response) => {
        setYearData(response?.data);
        setYearLoading(false);
      });
  };

  const getChartsData = async () => {
    try {
      await Promise.all([
        getWeekData(),
        getMonthData(),
        getYearData(),
      ]);
      setRefreshing(false);
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar carregar os gráficos, tente novamente.');
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setWeekLoading(true);
    setYearLoading(true);
    setMonthLoading(true);

    getChartsData();
  }, []);

  useEffect(() => {
    getChartsData();
  }, []);

  return (
    <Container data-testid="accumulated-distance">
      <TextContainer>
        <Title>
          Distância acumulada
        </Title>
        {!refreshing && (
          <BsArrowClockwise
            color="gray"
            onClick={onRefresh}
            size={20}
            style={{ paddingLeft: 5, cursor: 'pointer' }}
          />
        )}
      </TextContainer>
      <Tabs>
        {
          TABS.map((tab) => {
            const { id, name, value } = tab;
            const tabSelected = selectedTab === id;
            return (
              <ContainerTab key={id}>
                {
                  yearLoading
                    ? <ChartCardSkeleton />
                    : (
                      <Tab onClick={() => handleSelectTab(id)} selected={tabSelected} key={id}>
                        <TabTitle>
                          {name}
                        </TabTitle>
                        <TabValue>
                          {value.toString().replace('.', ',')}
                        </TabValue>
                        <TabTitleSub>
                          KMs
                        </TabTitleSub>
                      </Tab>
                    )
                }

                {
                  yearLoading
                    ? <></>
                    : (
                      <>
                        {
                          tabSelected
                            ? (
                              <ContainerSVG>
                                <img src={Polygon} alt="polygon.svg" style={{ height: 24, width: 33 }} />
                              </ContainerSVG>
                            )
                            : <></>
                        }
                      </>
                    )
                }

              </ContainerTab>

            );
          })
        }
      </Tabs>
      <MainContainer>
        {
          tabOptions[selectedTab]
        }

      </MainContainer>

    </Container>
  );
}

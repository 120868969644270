import styled from 'styled-components';

interface ButtonPropsI {
  isDisabled: boolean
}

export const Container = styled.button<ButtonPropsI>`
  cursor: pointer;
    width: 50%;
    height: 50px;
    background-color: #00AB2B;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)}
`;

export const Title = styled.p`
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
`;

import React, {
  useEffect,
  useRef, useState,
} from 'react';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { api2 } from '../../services/api';
import {
  Container, InputGroup, Input, ShoesList, ShoesListItem, EmptySearch,
} from './styles';
import { IShoes } from '../../store/Activities.store';
import { IProps } from './contracts';
import { getGenre } from '../../utils';
import { RootState } from '../../store';

export default function SelectShoes({ shoes, cb }:IProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  const [showShoesList, setShowShoesList] = useState(false);
  const [shoesName, setShoesName] = useState('');
  const [localShoes, setLocalShoes] = useState<IShoes[]>([]);

  useEffect(() => {
    setLocalShoes(shoes);
  }, []);

  const handleSearchShoes = (text: string) => {
    api2.get(`shoes?name=${text}`)
      .then((response) => {
        setLocalShoes(response.data.data);
        setShowShoesList(true);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao buscar o tênis');
      });
  };

  const delayedQuery = useRef(
    debounce((e: string) => {
      if (e) handleSearchShoes(e);
    }, 1000),
  ).current;

  return (
    <Container>
      <InputGroup>
        <Input
          type="text"
          placeholder="Selecione um tênis"
          onChange={(e) => {
            setShoesName(e.target.value);
            delayedQuery(e.target.value);
          }}
          value={shoesName}
          onClick={() => setShowShoesList(true)}
        />

        {
          localShoes.length > 0
          && showShoesList
              && (
                <ShoesList>
                  <ShoesListItem
                    type="button"
                    onClick={() => {
                      setShoesName('Nenhum, corri descalço');
                      setShowShoesList(false);
                      cb!('barefoot');
                    }}
                  >
                    Nenhum, corri
                    {' '}
                    {getGenre(user?.genre!) === 'female' ? 'descalça' : 'descalço'}
                  </ShoesListItem>

                  {
                    localShoes.map((s) => (
                      <ShoesListItem
                        type="button"
                        key={s?.id}
                        onClick={() => {
                          setShoesName(`${s?.shoesBrand?.name} ${s?.name} - ${s?.distance_total?.toFixed(2)}km`);
                          setShowShoesList(false);
                          cb!(s?.id);
                        }}
                      >
                        <span>
                          {s?.shoesBrand?.name}
                          {' '}
                          {s?.name}
                        </span>

                        <span>
                          {s?.distance_total?.toFixed(2)}
                          km
                        </span>
                      </ShoesListItem>
                    ))
                  }
                </ShoesList>
              )
        }

        {
          localShoes.length === 0
              && showShoesList
              && <EmptySearch>Nenhum tênis encontrado</EmptySearch>
        }
      </InputGroup>
    </Container>
  );
}

import React from 'react';
import {
  FaCheck, FaExclamation, FaTimes, FaRegCircle,
} from 'react-icons/fa';

import { appTheme } from '../../theme';
import Background from './styles';

import { executedStatus } from '../../types/Training';

export interface TrainingStatusProps {
  status: executedStatus | string | null;
  small?: Boolean;
  further?: boolean;
}

export default function TrainingStatus({ status, small, further }: TrainingStatusProps) {
  let bgColor = appTheme.grayD2;
  const icColor = appTheme.cardB1;
  let Icon = FaCheck;

  switch (status) {
    case executedStatus.EXECUTED_HIGH: {
      bgColor = appTheme.green;
      Icon = FaCheck;
      break;
    }
    case executedStatus.EXECUTED_OVER: {
      bgColor = appTheme.green;
      Icon = FaExclamation;
      break;
    }
    case executedStatus.EXECUTED_PARTIAL: {
      bgColor = appTheme.secondaryD1;
      Icon = FaCheck;
      break;
    }
    case executedStatus.EXECUTED_MEDIUM: {
      bgColor = appTheme.green;
      Icon = FaCheck;
      break;
    }
    case executedStatus.EXECUTED_LOW: {
      bgColor = appTheme.secondaryD1;
      Icon = FaCheck;
      break;
    }
    default: {
      bgColor = appTheme.grayD2;
      Icon = FaTimes;
      break;
    }
  }

  const componentSize = small ? 18 : 40;
  const iconSize = small ? 12 : 17;

  const emptyComponentSize = small ? 20 : 42;
  const emptyIconSize = small ? 17 : 20;

  return (
    <Background further={further} size={further ? emptyComponentSize : componentSize} bgColor={further ? 'transparent' : bgColor} data-testid="training-status">
      {further && <FaRegCircle color={bgColor} size={emptyIconSize} />}

      {!further && <Icon color={icColor} size={iconSize} />}
    </Background>
  );
}

import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  Container,
  Content,
  Text,
  FiltersContainer,
  ErrorMessage,
  FilterContent,
  FilterText,
  TimelineContainer,
  TimelineContent,
  TimelineMonth,
  MonthLabel,
  Events,
  DayLabel,
  DateContainer,
  Input,
  StyledControl,
  StyledIndicatorSeparator,
  customSelectStyles,
  StyledIndicatorPlaceholder,
  FilterContent2,
  StateTitle,
  TitleBlock,
  InputContainer,
  Disclaimer,
  DisclaimerContent,
} from './styles';
import StateCalendarCard from '../../../components/StateCalendarCard';
import Loader from '../../../components/Loader';
import RaceCalendarContext from '../Context/RaceCalendarContext';
import RangeSlider from '../../../components/RangeSlider';
import { RootState } from '../../../store';
import { stateNames } from '..';
import BackArrow from '../../../components/BackArrow';
import CustomThumb from './customThumb';
import FloatingFooter from '../../../components/FloatingFooter';
import coreApi from '../../../services/coreApi';
import { AdBanner } from '../types';
import { Card } from '../styles';
import { acronymToNormalizedState } from '../../../services/utils';

const monthOptions = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
].map((month, index) => ({
  value: index,
  label: month,
}));

const stateToAcronym: Record<string, string> = {
  acre: 'AC',
  alagoas: 'AL',
  amapa: 'AP',
  amazonas: 'AM',
  bahia: 'BA',
  ceara: 'CE',
  'distrito-federal': 'DF',
  'espirito-santo': 'ES',
  goias: 'GO',
  maranhao: 'MA',
  'mato-grosso': 'MT',
  'mato-grosso-do-sul': 'MS',
  'minas-gerais': 'MG',
  para: 'PA',
  paraiba: 'PB',
  parana: 'PR',
  pernambuco: 'PE',
  piaui: 'PI',
  'rio-de-janeiro': 'RJ',
  'rio-grande-do-norte': 'RN',
  'rio-grande-do-sul': 'RS',
  rondonia: 'RO',
  roraima: 'RR',
  'santa-catarina': 'SC',
  'sao-paulo': 'SP',
  sergipe: 'SE',
  tocantins: 'TO',
};

export default function StateCalendar() {
  const { state, city, coupon } = useParams();

  const navigate = useNavigate();

  const {
    races, totalRaces, error: storeError, loading: storeLoading,
  } = useSelector((state: RootState) => state.raceCalendar);

  const { token } = useSelector((state: RootState) => state.auth);

  const {
    filteredCoupon,
    setFilteredCoupon,
    filteredName,
    setFilteredName,
    filteredCity,
    setFilteredCity,
    filteredYear,
    SetYearFilter,
    filteredMonth,
    SetMonthFilter,
    filteredState,
    setFilteredState,
    SetDistancesFilter,
    displayDistances,
    loading: providerLoading,
    withStructure,
    setWithStructure,
  } = React.useContext(RaceCalendarContext).filters;

  const {
    FetchCalendarNextPage,
  } = React.useContext(RaceCalendarContext).actions;

  const { racesByDate } = React.useContext(RaceCalendarContext).data;

  const [adBanner, setAdbanner] = useState<AdBanner>();

  React.useEffect(() => {
    if (city) setFilteredCity(city.replaceAll('-', ' '));

    if (coupon) setFilteredCoupon(coupon.replaceAll('-', ' '));

    if (state) {
      if (state.length === 2) {
        setFilteredState(state.toUpperCase());
      } else {
        setFilteredState(stateToAcronym[state]);
      }
    }

    if ((!state) || (!state && !city)) navigate('/corridaderua');
  }, []);

  React.useEffect(() => {
    if (state) {
      const _state = state.length === 2 ? acronymToNormalizedState(state) : state;

      let url = `advertising/location/${_state}`;

      if (token) url += '/auth';

      coreApi.get(url)
        .then((response) => {
          setAdbanner(response.data);
        });
    }
  }, []);

  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          data-react-helmet="true"
          content={`Encontre o calendário completo de corridas de rua em ${state && filteredState ? (stateNames[filteredState.toUpperCase()] ?? '') : ''} no Calendário Corrida Perfeita. Planeje seu próximo desafio, acompanhe datas e locais das corridas e inscreva-se para participar. Tudo que você precisa para sua próxima corrida está aqui. Confira agora!`}
        />
        <title>
          Calendário Corrida de Rua
          {` ${state && filteredState ? (stateNames[filteredState.toUpperCase()] ?? '') : ''}`}
        </title>
      </Helmet>
      <TitleBlock>
        <BackArrow text="" bigger backTo="/corridaderua" />
        <StateTitle>
          {`Corridas de rua ${state && filteredState ? (stateNames[filteredState.toUpperCase()] ?? '') : ''}`}
        </StateTitle>
      </TitleBlock>

      <Content>
        <Text>
          {`Aqui você encontra as melhores provas de corrida de rua em ${state && filteredState ? (stateNames[filteredState.toUpperCase()] ?? '') : ''}. Provas de 5K / 10K / 21K / Maratonas e mais.`}
        </Text>
        {adBanner && <a href={adBanner.action_url}><Card alt={adBanner.title} src={adBanner.image} /></a>}
      </Content>
      <Content>
        <Text>
          Filtrar por:
        </Text>

        <div>
          <FilterContent flex={10}>
            <FilterText>Nome</FilterText>
            <Input
              value={filteredName}
              onChange={(e) => setFilteredName(e.target.value)}
              placeholder="Nome"
            />
          </FilterContent>

          <FiltersContainer>
            <FilterContent flex={1}>
              <FilterText>Cidade</FilterText>
              <Input
                value={filteredCity}
                onChange={(e) => setFilteredCity(e.target.value)}
                placeholder="Todas"
              />
            </FilterContent>

            <FilterContent flex={0.5}>
              <FilterText>Ano</FilterText>
              <Input
                value={filteredYear}
                onChange={(e) => SetYearFilter(Number(e.target.value) > 2030 ? '2030' : e.target.value)}
                placeholder={new Date().getFullYear().toString()}
                type="number"
              />
            </FilterContent>

            <FilterContent flex={1}>
              <FilterText>Mês</FilterText>
              <Select
                options={monthOptions}
                value={filteredMonth === undefined ? null : { value: filteredMonth, label: monthOptions[filteredMonth].label }}
                onChange={(e) => {
                  const event = e as { value?: string | number };
                  if (Object.prototype.hasOwnProperty.call(event, 'value')) {
                    SetMonthFilter(Number(event.value));
                  }
                }}
                placeholder="Atual e próximos"
                isClearable={false}
                styles={customSelectStyles}
                components={{
                  Control: StyledControl,
                  IndicatorSeparator: StyledIndicatorSeparator,
                  Placeholder: StyledIndicatorPlaceholder,
                }}
              />
            </FilterContent>
          </FiltersContainer>

          <FiltersContainer>
            <FilterContent flex={0.65}>
              <InputContainer onClick={() => setFilteredCoupon((prev) => (prev === 'coupon' ? 'not_coupon' : 'coupon'))}>
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  name="filteredCoupon"
                  id="filteredCoupon"
                  checked={filteredCoupon === 'coupon'}
                  onChange={(e) => setFilteredCoupon(e.target.checked ? 'coupon' : 'not_coupon')}
                />
                <FilterText>Com cupom de desconto</FilterText>
              </InputContainer>
            </FilterContent>

            <FilterContent flex={1}>
              <InputContainer onClick={() => setWithStructure((prev) => !prev)}>
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  name="withStructure"
                  id="withStructure"
                  checked={withStructure}
                  onChange={(e) => setWithStructure(e.target.checked)}
                />
                <FilterText>Com estrutra Corrida Perfeita no evento</FilterText>
              </InputContainer>
            </FilterContent>
          </FiltersContainer>

          <DisclaimerContent>
            <Disclaimer>*Datas, locais, percursos e estruturas estão sujeitos a alterações sem aviso prévio.</Disclaimer>
          </DisclaimerContent>

          <FilterContent2>
            <FilterText>Distância KM</FilterText>
            <RangeSlider
              min={1}
              max={42}
              values={displayDistances}
              callback={(e) => SetDistancesFilter(e)}
              customThumb={CustomThumb}
            />
          </FilterContent2>
        </div>
      </Content>

      {!providerLoading && !storeLoading && !storeError && races.length === 0 && (filteredCity || displayDistances || filteredMonth || filteredYear !== new Date().getFullYear().toString()) && (
        <ErrorMessage>Nenhum evento encontrado.</ErrorMessage>
      )}

      {
        !providerLoading && !storeLoading && storeError && (
          <ErrorMessage>Houve um erro. Por favor, tente atualizar a página.</ErrorMessage>
        )
      }

      <InfiniteScroll
        style={{ overflow: 'inherit' }}
        dataLength={races.length}
        next={FetchCalendarNextPage}
        hasMore={totalRaces - races.length !== 0}
        loader={(
          <div />
        )}
        scrollThreshold={0.9}
        endMessage={(
          <p style={{ textAlign: 'center', color: 'white' }}>
            {!providerLoading && races.length > 0 && (<b>Todos os eventos foram carregados.</b>)}
          </p>
        )}
      >
        <TimelineContainer>

          {racesByDate.map((group) => (
            <div key={group.month}>
              <TimelineMonth>
                {group.raceDays.map((raceDay) => (
                  <div key={raceDay.day}>
                    <DateContainer>
                      <MonthLabel>{group.month}</MonthLabel>
                      <DayLabel>
                        {raceDay.day}
                      </DayLabel>
                    </DateContainer>
                    <TimelineContent>
                      <Events>
                        {
                          raceDay.races.map((race) => (
                            <StateCalendarCard
                              key={race._id}
                              race={race}
                              city={race.city}
                              state={race.state}
                              kilometers={race.distance.map((dist: string) => `${dist}km`).join(', ')}
                              structure={race.structure}
                              coupons={race.coupons}
                              url={race.url}
                            />
                          ))
                        }
                      </Events>
                    </TimelineContent>
                  </div>
                ))}
              </TimelineMonth>
            </div>
          ))}

        </TimelineContainer>
        {providerLoading && <Loader color="white" />}
      </InfiniteScroll>

      <FloatingFooter
        text="Sentiu falta de alguma prova? Clique aqui e envie a sua sugestão de corrida"
        clickCallback={() => window.open(' https://www.corridaperfeita.com/sugestao-de-prova/', '_blank')}
      />
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import {
  ImRadioChecked, ImRadioUnchecked, ImStarEmpty, ImStarFull,
} from 'react-icons/im';
import InfiniteScroll from 'react-infinite-scroller';
import BackArrow from '../BackArrow';
import Loader from '../Loader';
import RecordedClassItem from '../RecordedClassCard';
import api from '../../services/api';
import {
  Container, FilterContainer, FilterLabel, IconButton, IconButtonText,
} from './style';

export interface IRecordedLiveLectureDetails {
  id: number;
  title: string;
  subtitle: string | null;
  name: string;
  description: string | null;
  level: string;
  when_to_do: string | null;
  equipments: string;
  day: string | null;
  zoom_link: string | null;
  record_link: string;
  clone_type: string;
  executed_status: string | null;
  executed_at: string | null;
  date: string;
  user_id: number;
  created_at: string;
  updated_at: string;
  obj_type: string;
  audio_link: string | null;
  favorited: boolean;
}

export default function RecordedClasses() {
  const [allClasses, setAllClasses] = useState(true);
  const [favoritesClasses, setFavoritesClasses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState<IRecordedLiveLectureDetails[]>([]);
  const [page, setPage] = useState<number>(1);
  const [newPageLoading, setNewPageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const filterAllClasses = () => {
    if (allClasses) return;
    setFavoritesClasses(false);
    setAllClasses(true);
  };

  const filterFavoritesClasses = () => {
    if (favoritesClasses) return;
    setAllClasses(false);
    setFavoritesClasses(true);
  };

  const resetState = () => {
    setPage(1);
    setClasses([]);
    setHasMore(true);
  };

  const getRecordedClasses = () => {
    setLoading(true);

    resetState();

    let url = 'live_lectures?page=1&limit=10&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LECTURE';
    if (favoritesClasses) url += '&onlyFavorites=true';

    api.get(url)
      .then((response) => {
        if ((response.data?.data?.length ?? 0) === 0) {
          setHasMore(false);
        } else {
          setClasses(response.data.data);
        }

        setPage((page) => page + 1);
      })
      .catch(() => {
        setHasMore(false);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRecordedClasses();
  }, [allClasses, favoritesClasses]);

  function getNewPage() {
    if (newPageLoading) return;

    setNewPageLoading(true);

    let url = `live_lectures?page=${page}&limit=10&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LECTURE`;
    if (favoritesClasses) url += '&onlyFavorites=true';

    api.get(url)
      .then((response) => {
        if ((response.data?.data?.length ?? 0) === 0) {
          setHasMore(false);
        } else {
          const { data: newClasses } = response.data;
          setClasses(classes.concat(newClasses));
          setPage((page) => page + 1);
        }
      })
      .catch(() => {
        setHasMore(false);
      })
      .finally(() => {
        setNewPageLoading(false);
      });
  }

  return (
    <Container>
      <BackArrow text="GRAVAÇÃO DE TREINOS AO VIVO" bigger backTo="/club/live-classes" />

      <FilterContainer>
        <FilterLabel>Filtro: </FilterLabel>
        <IconButton active={allClasses} onClick={() => filterAllClasses()}>
          {allClasses && <ImRadioChecked size={10} color="white" />}
          {!allClasses && <ImRadioUnchecked size={10} color="white" />}
          <IconButtonText>Todos</IconButtonText>
        </IconButton>
        <IconButton active={favoritesClasses} onClick={() => filterFavoritesClasses()}>
          {favoritesClasses && <ImStarFull size={10} color="white" />}
          {!favoritesClasses && <ImStarEmpty size={10} color="white" />}
          <IconButtonText>Favoritos</IconButtonText>
        </IconButton>
      </FilterContainer>
      {loading
        ? <Loader />
        : (
          <InfiniteScroll
            pageStart={0}
            loadMore={getNewPage}
            hasMore={hasMore}
            loader={<Loader key={0} />}
          >
            {
              classes.map((lecture) => (
                <RecordedClassItem
                  key={lecture.id}
                  recordedClass={lecture}
                />
              ))
            }
          </InfiniteScroll>
        )}
    </Container>

  );
}

/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import { formatDuration } from '../../../utils';
import { appTheme } from '../../../theme';
import {
  Item, Laps, ItemsLine, ItemsLineTitle, ItemsLineValue, SeparatorV, ContainerCard,
  ChartContainer, LapsContainer, LapsTitle, SelectContainer,
} from './styles';
import { Activity } from '../../../store/Activities.store';
import BaseModal, { IBaseModalProps } from '../../BaseModal';
import SelectInput from '../../SelectInput';

export interface ILap {
  'moving_time': number,
  'distance': number,
  'total_elevation_gain': number,
  'average_speed': number,
  'max_speed': number,
  'average_cadence': number,
  'average_heartrate': number,
  'lap_index': number,
}

export interface IAnalysisData {
  laps: ILap[],
  analysis: {
    average_speed: number,
    max_speed: number,
  }
}

export interface AnalysisModalProps extends IBaseModalProps {
  analysisData: IAnalysisData;
  activity: Activity;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);

function AnalysisModal({
  analysisData, activity, ...modalProps
}: AnalysisModalProps) {
  const [lapsInfo, setLapsInfo] = useState('cadence');

  const best_lap = Math.min(...analysisData.laps.map((lap: any) => (lap.moving_time / lap.distance)));

  const hasSpeed = true;

  const barColors = analysisData.laps.map((lap: { average_speed: number; }) => {
    const speed = lap.average_speed;

    let color;

    if (speed > 9) {
      color = appTheme.primaryD1;
    } else if (speed > 5 && speed < 9) {
      color = appTheme.primaryL1;
    } else {
      color = appTheme.primaryGradD1;
    }

    return color;
  });

  const _data: number[] = analysisData.laps.map((lap: any) => lap.moving_time / lap.distance);

  const greaterPace = Math.max(..._data);

  const graphValues = _data.map((value: number) => (value - greaterPace) * -1 + 1);

  const tooltipLabelCallback = (data: { dataIndex: number; }) => (`Volta ${data.dataIndex + 1}, Pace: ${formatDuration(_data[data.dataIndex], 'pace')} /km`);

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: { label: tooltipLabelCallback },
      },
    },
  };

  const data = {
    labels: new Array(_data.length).fill(''),
    datasets: [
      {
        data: graphValues,
        backgroundColor: barColors,
      },
    ],
  };

  const graphOptions = [
    { label: 'BPM', value: 'bpm', key: 0 },
    { label: 'Cadência', value: 'cadence', key: 1 },
    { label: 'Ganho de elev.', value: 'elevation', key: 2 },
  ];

  return (
    <BaseModal {...modalProps} title="Análise da atividade">
      <ContainerCard>
        {hasSpeed && (
          <ChartContainer>
            <Bar
              options={options}
              data={data}
            />

          </ChartContainer>
        )}
        <SeparatorV />
        <ItemsLine>
          <ItemsLineTitle>Melhor volta</ItemsLineTitle>
          <ItemsLineValue>
            {best_lap
              ? `${formatDuration(best_lap, 'pace')} /km`
              : '--:-- /km'}
          </ItemsLineValue>
        </ItemsLine>
        <SeparatorV />
        <ItemsLine>
          <ItemsLineTitle>Ritmo médio</ItemsLineTitle>
          <ItemsLineValue>
            {activity.pace
              ? `${formatDuration(activity.pace, 'pace')} /km`
              : '-'}
            {' '}
          </ItemsLineValue>
        </ItemsLine>
        <SeparatorV />
        <ItemsLine>
          <ItemsLineTitle>Velocidade média</ItemsLineTitle>
          <ItemsLineValue>
            {analysisData.analysis.average_speed
              ? `${analysisData.analysis.average_speed.toFixed(1)} km/h`
              : '-'}
          </ItemsLineValue>
        </ItemsLine>
        <SeparatorV />
        <ItemsLine>
          <ItemsLineTitle>Velocidade máxima</ItemsLineTitle>
          <ItemsLineValue>
            {analysisData.analysis.max_speed
              ? `${analysisData.analysis.max_speed.toFixed(1)} km/h`
              : '-'}
          </ItemsLineValue>
        </ItemsLine>
        <SeparatorV />
        <LapsContainer>
          <LapsTitle>Voltas</LapsTitle>
          <SelectContainer>
            <SelectInput
              inputValue={lapsInfo}
              onChange={(e) => {
                setLapsInfo(e.target.value);
              }}
            >
              {graphOptions.map((option) => (
                <option value={option.value} key={`${option.key}`}>
                  {option.label}
                </option>
              ))}
            </SelectInput>
          </SelectContainer>
        </LapsContainer>
        {
          analysisData.laps.map((lap: ILap) => (
            <div key={`${lap.lap_index}${lap.moving_time}`}>
              <Laps>
                <Item width={10}>{lap.lap_index ? lap.lap_index.toLocaleString(undefined, { minimumIntegerDigits: 2 }) : '-'}</Item>
                <Item width={25}>{lap.distance ? `${lap.distance.toFixed(2)} km` : '-'}</Item>
                <Item width={20}>
                  {lap.moving_time ? `${formatDuration(lap.moving_time, 'pace')}`
                    : '--:--'}
                </Item>
                <Item width={25}>
                  {lap.moving_time && lap.distance
                    ? `${formatDuration(lap.moving_time / lap.distance, 'pace')} /km`
                    : '--:-- /km'}
                </Item>
                {lapsInfo === 'bpm' && (
                  <Item width={20}>
                    {lap.average_heartrate ? `${Math.round(lap.average_heartrate)} bpm`
                      : '-'}
                  </Item>
                )}
                {lapsInfo === 'cadence' && (
                  <Item width={20}>
                    {lap.average_cadence ? `${Math.round(lap.average_cadence)} ppm`
                      : '-'}
                  </Item>
                )}
                {lapsInfo === 'elevation' && (
                  <Item width={20}>
                    {lap.total_elevation_gain !== null
                      ? `${Math.round(lap.total_elevation_gain).toLocaleString(undefined, { minimumIntegerDigits: 2 })} m` : '-'}
                  </Item>
                )}
              </Laps>
              <SeparatorV key={lap.lap_index} />
            </div>
          ))
        }
      </ContainerCard>
    </BaseModal>
  );
}

export default AnalysisModal;

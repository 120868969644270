import React from 'react';
import MaskedInput from '../../MaskedInput';
import {
  InnerContainer,
  Label,
  OuterContainer,
  PerKMContainer,
  PerKMText,
} from './styles';
import { appTheme } from '../../../theme';

export interface IPaceInputProps {
  value: number | undefined;
  onChange: (seconds: number) => void;
}

function PaceInput({ value, onChange }: IPaceInputProps) {
  const [innerValue, setInnerValue] = React.useState<string | undefined>(undefined);
  function MaskedPaceToSeconds(maskedPace: string) {
    const [minutes, seconds] = maskedPace.replaceAll('_', '').replaceAll(' /Km', '').split(':');

    return Number(minutes) * 60 + Number(seconds);
  }

  function SecondsToMaskedPace(dateTime: number) {
    const minutes = Math.floor(dateTime / 60);
    const seconds = Math.floor(dateTime % 60);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  React.useEffect(() => {
    if (value === undefined) {
      setInnerValue(undefined);
      return;
    }

    const valueToDisplay = (value * 60);
    setInnerValue(valueToDisplay.toString());
  }, [value]);

  React.useEffect(() => {
    if (innerValue === undefined) return;

    const sanitizedValue = innerValue.replace(',', '.');
    const parsedValue = parseFloat(sanitizedValue) / 60;
    onChange(parsedValue);
  }, [innerValue]);

  // function handlePaceChange(event: React.ChangeEvent<HTMLInputElement>) {
  //   const sanitizedValue = event.target.value.replace(',', '.');
  //   const parsedValue = parseFloat(sanitizedValue);
  //   if (parsedValue > 999) return;

  //   setInnerValue(sanitizedValue);
  // }

  return (
    <OuterContainer>
      <Label>Pace</Label>
      <InnerContainer>
        <MaskedInput
          style={{
            flex: 1,
            backgroundColor: `${appTheme.cardB3}`,
            color: `${appTheme.title}`,
            padding: '10px',
            border: 'none',
            height: '50px',
            fontSize: '18px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '0px',
            textAlign: 'center',
            minWidth: '0px',
          }}
          name="pace"
          inputValue={value ? SecondsToMaskedPace(value) : ''}
          mask="99:99"
          onChange={(e) => onChange(MaskedPaceToSeconds(e.target.value))}
          placeholder="0:00"
        />

        <PerKMContainer>
          <PerKMText>
            min/km
          </PerKMText>
        </PerKMContainer>
      </InnerContainer>
    </OuterContainer>
  );
}

export default PaceInput;

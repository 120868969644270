import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
 display:flex;
 flex-direction: column;
`;

export const ProductTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: ${theme.colors.title};
  margin-bottom: 16px;
  text-transform: uppercase;
    @media (max-width: 667px) {
    font-size: 16px;
  }
`;

export const ProductText = styled.span`
  font-weight: 400;
  font-size: 17px;
  color: ${theme.colors.title};
  margin-bottom: 11px;
    @media (max-width: 667px) {
    font-size: 15px;
  }
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Points = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DataNames = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`;

export const TextPoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: ${theme.colors.title};
  margin-right: 30px;
  @media (max-width: 667px) {
    font-size: 13px;
     margin-right: 10px;
  }
`;

export const ValuePoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: ${theme.colors.title};
  text-decoration-line: underline;
  margin-bottom: 2px;
  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const TotalPoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  color: ${theme.colors.notifications};
  text-decoration-line: underline;
  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const ErrorText = styled.span`
  font-size: 15px;
  color: ${theme.colors.redAlert};
  margin-bottom: 7px;
    @media (max-width: 667px) {
    font-size: 12px;
  }
`;

import React from 'react';
import { toast } from 'react-hot-toast';
import { FiCopy } from 'react-icons/fi';
import {
  Container, Header, CouponText, CouponCodeButton, CopyClick,
} from './styles';

interface IIndicationCouponProps {
  userCoupon: string;
}

export default function IndicationCoupon({ userCoupon }: IIndicationCouponProps) {
  const handleCopyClick = (code: string) => {
    navigator.clipboard.writeText(code)
      .then(() => {
        toast.success('Cupom copiado com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao copiar o cupom.');
      });
  };

  return (
    <Container>
      <Header>MEU CUPOM DE INDICAÇÃO</Header>

      <CouponCodeButton>
        <CouponText>
          {userCoupon}
        </CouponText>
        <CopyClick onClick={() => handleCopyClick(userCoupon)}>
          COPIAR
          <FiCopy size={18} color="#B4B4B4" style={{ paddingLeft: 5 }} />
        </CopyClick>
      </CouponCodeButton>
    </Container>
  );
}

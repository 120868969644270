import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Button = styled.button`  
  width: 31px;
  height: 26px;  
  margin: 4px;
  margin-bottom: 16px ;
  border-radius: 4px;  
  border-width: 1px;
  border-color: #FFFFFF;
  border-style: solid; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonText = styled.div`  
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
`;

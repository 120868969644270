import styled from 'styled-components';
import THEME from '../../theme';

export const Main = styled.div`
    flex: 1;
    background: ${THEME.colors.cardB1};
    padding: 10px;
`;

export const Container = styled.div`
    background: ${THEME.colors.cardB2};
    width: 100%;
    border-radius: 10px;
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const Image = styled.img`
    min-height: 55px;
    max-height: 80px;
    max-width: 90%;
    margin-top: 25px;
    margin-bottom: 25px;
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${THEME.colors.text};
    margin-top: 25px;
    text-align: center;
`;

export const SubTitle = styled.p`
    font-size: 14px;
    color: ${THEME.colors.title};
    font-weight: bold;
`;

export const Button = styled.button`
    background-color: ${THEME.colors.title};
    border-radius: 5px;
    width: 50%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    border: none;
    cursor: pointer;
`;

export const ButtonTitle = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: ${THEME.colors.primary};
`;

export const Paragraph = styled.p`
    color: ${THEME.colors.title};
    text-align: center;
    font-size: 14px;
`;

export const BoldParagraph = styled.span`
    font-weight: bold;
`;

export const HelpLink = styled.a`
    color: ${THEME.colors.title};
    margin-top: 25px;
`;

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronLeft } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { RootState } from '../../store';
import { setCurrentClass } from '../../store/Classes.store';
import theme from '../../theme';
import { LecturesI } from '../../types/Class';
import ClassDoneIndicator from '../ClassesDoneIndicator';
import ClassesMediaContainer from '../ClassesMediaContainer';
import ClassExecutionControlPanel from '../ClassesExecutionControlPanel';
import FinishClass from '../FinishClass';
import {
  Container,
  Content,
  Title,
  TitleContainer,
  ClassContainer,
  ClassDescriptionContainer,
  LectureTitle,
  LectureDescription,
  LectureTitleContainer,
  Button,
  ButtonText,
} from './styles';
import ClassesListSkeleton from '../ClassesListSkeleton';

const RECORD_RUN_TITLE = 'Auto avaliação de corrida';
const RECORD_RUN_LINK = 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001153366-como-gravar-sua-corrida-para-avaliar-sua-t%C3%A9cnica';
const CHOICE_TENNIS_TITLE = 'Escolha do tênis';
const CHOICE_TENNIS_LINK = 'https://corridaperfeita.com/como-escolher-tenis-de-corrida/';
const COMPLETED_STATUS = 'EXECUTED_HIGH';
const UNCOMPLETED_STATUS = 'EXECUTED_LOW';

function ClassExecution() {
  const currentClass = useSelector((state: RootState) => state.classes.currentClass);
  const idClasses = useSelector((state: RootState) => state.classes.id);
  const [modulesIndex, setModulesIndex] = useState<number>(0);
  const [lecturesIndex, setLecturesIndex] = useState<number>(0);
  const [moduleFinished, setModuleFinished] = useState<boolean>(false);
  const [classFinished, setClassFinished] = useState<boolean>(false);
  const [currentLecture, setCurrentLecture] = useState<LecturesI>(
    currentClass!
      .courseModules[0]
      .courseLectures[0],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const findTraining = () => {
    // let classesModules = currentClass!.courseModules?.filter(moduleId => moduleId.id === idClasses);

    for (let i = 0; i < currentClass!.courseModules?.length; i++) {
      for (let j = 0; j < currentClass!.courseModules[i]?.courseLectures.length; j++) {
        if (currentClass!.courseModules[i]?.courseLectures[j].id === idClasses) {
          setModulesIndex(i);
          setLecturesIndex(j);
          setCurrentLecture(currentClass!.courseModules[i]?.courseLectures[j]);
          return;
        }
      }
    }
  };

  useEffect(() => {
    findTraining();
    setLoading(false);
  }, []);

  const markLectureDone = async () => {
    if (!currentClass) return;
    if (currentLecture.executed_status === COMPLETED_STATUS) return;

    setCurrentLecture({ ...currentLecture, executed_status: COMPLETED_STATUS });

    try {
      const res = await api.post(`/courses/${currentClass.id}/finish-lecture?course_lecture_id=${currentLecture.id}`);
      dispatch(setCurrentClass(res?.data));
    } catch (err) {
      setCurrentLecture({ ...currentLecture, executed_status: UNCOMPLETED_STATUS });
      toast.error('Tivemos um problema para realizar esta ação. Por favor tente mais tarde');
    }
  };

  const nextLecture = () => {
    let lIndex = lecturesIndex;
    if (currentClass!.courseModules[modulesIndex]?.courseLectures?.length - 1 === lecturesIndex) {
      if (currentClass!.courseModules?.length - 1 === modulesIndex) {
        setClassFinished(true);
      } else {
        setModuleFinished(true);
      }
    } else {
      lIndex++;
      setLecturesIndex(lIndex);
    }
    setCurrentLecture(currentClass!.courseModules[modulesIndex]?.courseLectures[lIndex]);
  };

  const previousLecture = () => {
    let lIndex = lecturesIndex;
    let mIndex = modulesIndex;
    if (lIndex > 0) {
      lIndex--;
      setLecturesIndex(lIndex);
    } else if (lIndex === 0 && mIndex > 0) {
      mIndex--;
      lIndex = currentClass!.courseModules[mIndex]?.courseLectures?.length - 1;
      setLecturesIndex(lIndex);
      setModulesIndex(mIndex);
    }
    setCurrentLecture(currentClass!.courseModules[mIndex]?.courseLectures[lIndex]);
  };

  const nextModule = () => {
    const mIndex = modulesIndex + 1;
    setModulesIndex(mIndex);
    setLecturesIndex(0);
    setCurrentLecture(currentClass!.courseModules[mIndex]?.courseLectures[0]);
    setModuleFinished(false);
  };

  if (moduleFinished) {
    return (
      <Container>
        <FinishClass
          title={currentClass!.courseModules[modulesIndex]?.name}
          continueClass
          nextModule={nextModule}
        />
      </Container>
    );
  }

  if (classFinished) {
    return (
      <Container>
        <FinishClass
          title={currentClass!.name}
          continueClass={false}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        {
          loading
            ? <ClassesListSkeleton />
            : (
              <>
                <TitleContainer onClick={() => navigate('/club/ClassesCover')}>
                  <button type="button">
                    <BsChevronLeft color={theme.colors.title} size={24} style={{ marginTop: 6 }} />
                  </button>
                  <Title>{currentClass!.name?.toUpperCase()}</Title>
                </TitleContainer>
                <ClassContainer>
                  <ClassesMediaContainer
                    url={currentLecture?.video || currentLecture?.media}
                    image={!currentLecture?.video}
                  />
                  <ClassDescriptionContainer>
                    <div>
                      <LectureTitleContainer>
                        <LectureTitle>
                          {
                            currentLecture
                              .name
                              ?.toUpperCase()
                          }
                        </LectureTitle>
                        <ClassDoneIndicator
                          finished={currentLecture.executed_status === COMPLETED_STATUS}
                          onPress={markLectureDone}
                        />
                      </LectureTitleContainer>
                      <LectureDescription>
                        {
                          currentLecture
                            .content
                        }
                      </LectureDescription>
                      {
                        currentLecture.name === RECORD_RUN_TITLE
                        && (
                          <Button onClick={() => window.open(RECORD_RUN_LINK, '_blank')}>
                            <ButtonText>
                              COMO GRAVAR SUA CORRIDA
                            </ButtonText>
                          </Button>
                        )
                      }
                      {
                        currentLecture.name === CHOICE_TENNIS_TITLE
                          && (
                            <Button onClick={() => window.open(CHOICE_TENNIS_LINK, '_blank')}>
                              <ButtonText>
                                COMO ESCOLHER SEU TÊNIS
                              </ButtonText>
                            </Button>
                          )
                      }
                    </div>
                  </ClassDescriptionContainer>
                </ClassContainer>
                <ClassExecutionControlPanel
                  isFirst={modulesIndex === 0 && lecturesIndex === 0}
                  nextLecture={nextLecture}
                  previousLecture={previousLecture}
                  name={currentLecture.name}
                />
              </>
            )
        }
      </Content>
    </Container>
  );
}
export default ClassExecution;

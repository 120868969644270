import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  min-height: 500px;  
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.colors.cardB1};
  padding: 3%;
`;

export const LogoImage = styled.img`
    height: 8%;
    width: 30%;
    margin: 3%;
`;

export const TitleContainer = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-start;
  button{
    border: none;
    background: none;
    cursor: pointer;
    svg{
        cursor: pointer;
    }
  }

`;

export const Title = styled.p`
  color: ${theme.colors.title};
  font-weight: 600;
  cursor: pointer;
`;

export const ClassContainer = styled.div`
  margin-top: 4%;
  background: ${theme.colors.cardB2};
  width: 100%;
  height: 60%;
  max-height: 80%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
`;

export const ClassDescriptionContainer = styled.div`
  height: 50%;
  padding: 3%;
  justify-content: space-between;
`;

export const LectureTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 4px;
`;

export const LectureTitle = styled.p`
  color: ${theme.colors.title};
  font-weight: 700;
  font-size: 12px;
  margin-right: 4px;
  flex: 1;
`;

export const LectureDescription = styled.p`
  color: ${theme.colors.title};
  font-size: 12px;
  margin: 6px 0;
`;

export const Button = styled.div`
  background: ${theme.colors.title};
  align-self: center;
  padding: 1%;
  padding: 2%;
  border-radius: 5px;
  margin: 2% auto;
  cursor: pointer;
  display: flex;    
  justify-content: center;
  align-items: center;
  max-width :300px ;
`;

export const ButtonText = styled.p`
  color: ${theme.colors.cardB1};
  font-weight: 500;
  
`;

import React from 'react';
import { parseISO, isBefore } from 'date-fns';

import { ICalendarDayTraining } from '../../../../../../types/TrainingPlan';
import {
  DayBox, DayBoxTitle, MonthDayContainer,
} from './styles';

import TrainingStatus from '../../../../../TrainingStatus';
import { formatDayNumberToDayNameOfWeek, formatMonthNumberToNameOfMonth } from '../../../../../../utils/DateUtils';

export interface IDayGenericProps {
  day: ICalendarDayTraining;
  handleSelectDay: (value: string) => void;
  isToday: boolean;
  isSelected: boolean;
}

function DayGeneric({
  day, handleSelectDay, isToday, isSelected,
}: IDayGenericProps) {
  const isoDate = parseISO(day?.date);
  return (
    <DayBox>
      <>
        <DayBoxTitle>
          {formatDayNumberToDayNameOfWeek(day?.date)}
        </DayBoxTitle>

        <MonthDayContainer
          data-testid="day-button"
          onClick={() => handleSelectDay(day?.date)}
          isSelected={isSelected}
          style={{ margin: 5 }}
        >
          {
            !!day?.trainingsOfDay?.length && isBefore(new Date(day.date), new Date())
            && <TrainingStatus status={day?.status} small />
          }

          {
            !!day?.trainingsOfDay?.length && !isBefore(new Date(day.date), new Date())
            && <TrainingStatus status={day?.status} small further />
          }

          {
            isToday
              ? 'HOJE'
              : isoDate?.getDate() === 1
                ? formatMonthNumberToNameOfMonth(isoDate?.getMonth())
                : isoDate.getDate()
          }
        </MonthDayContainer>
      </>
    </DayBox>
  );
}

export default React.memo(DayGeneric);

import React from 'react';
import { format, isPast, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { ICalendarDayTraining } from '../../types/TrainingPlan';

import TrainingStatus from '../TrainingStatus';

import {
  DayName, DayCard, DayIndicator, DateText,
} from './styles';

export interface DayItemProps {
  trainingDay: ICalendarDayTraining;
}

export default function DayItem({ trainingDay }: DayItemProps) {
  const today = format(new Date(), 'yyyy-MM-dd');

  const trainingDate = parseISO(trainingDay?.date);

  const day = format(trainingDate, 'eeeeee', { locale: ptBR });
  const date = format(trainingDate, 'dd/MM');

  const isToday = trainingDay?.date === today;

  return (
    <DayCard selected={isToday} data-testid="day-item">

      {
        isPast(trainingDate)
        && trainingDay?.trainingsOfDay?.length
          ? <TrainingStatus status={trainingDay?.status} />
          : <DayIndicator />
      }

      <DayName className={!isToday ? 'dimmed' : ''}>{isToday ? 'HOJE' : `${day || ''}`}</DayName>

      {isToday && <DateText>{date || ''}</DateText>}
    </DayCard>
  );
}

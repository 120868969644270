import styled from 'styled-components';
import THEME from '../../../../theme';

export const ContainerCards = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Card = styled.div`
    width: 30%;  
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 10px;
    border-radius: 10px;
    background-color: ${THEME.colors.cardB3};
    ${THEME.defaultShadow};

    @media (max-width: 667px) {
        padding: 10px 0
    }
`;

export const GraphContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardNumberContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardNumber = styled.span`    
    font-weight: 900;
    font-size: 50px;
    color: ${THEME.colors.title};
    text-align: center;

    @media (max-width: 667px) {
        font-size: 30px;
    }
`;

export const CardTitle = styled.span`
    font-size: 17px;
    color: ${THEME.colors.title};
    margin: 0 5px;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

export const CardFooter = styled.div`    
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
`;

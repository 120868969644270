import React from 'react';
import Navbar from '../Navbar';
import Header from '../Header';
import useNavbar from '../../hooks/useNavbar';
import { Background, Wrapper, BackgroundProgress } from './styles';

export default function MainLayout({ children, displayNavbar = true, displayHeader = true }: any) {
  const { selected } = useNavbar();

  return (
    <>
      {
        selected === 'progress' ? (

          <BackgroundProgress>
            {displayHeader && <Header />}
            {displayNavbar && <Navbar />}
            <Wrapper displayNavbar={displayNavbar}>
              {children}
            </Wrapper>
          </BackgroundProgress>
        )
          : (
            <Background>
              {displayHeader && <Header />}
              {displayNavbar && <Navbar />}
              <Wrapper displayNavbar={displayNavbar}>
                {children}
              </Wrapper>
            </Background>
          )

      }
    </>
  );
}

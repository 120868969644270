import styled from 'styled-components';
import theme, { appTheme } from '../../../theme';

const baseInputStyle = `
    flex: 1;
    background-color: ${appTheme.cardB3};
    color: ${appTheme.title};
    padding: 10px;
    border: none;
    height: 50px;
    font-size: 18px;
    min-width: 0px;
`;

export const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${theme.colors.title};
`;

export const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Label = styled.label`
    margin-bottom: 20px;
`;

export const Input = styled.input`
    ${baseInputStyle}
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const Select = styled.select`
    ${baseInputStyle}
    background-color: ${theme.colors.cardB4};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: ${appTheme.text};

    option {
        color: ${appTheme.title};
        background-color: ${appTheme.cardB3};
    }

    @media (max-width: 667px) {
        font-size: 14px;
    }
`;

export const DistanceMetricTag = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.cardB4};
    height: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const DistanceMetricTagText = styled.div`
    color: ${appTheme.text};
    font-size: 18px;
`;

import React, { useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import api from '../../services/api';
import theme from '../../theme';
import Modal from '../BaseModal';
import { GenericButton } from '../GenericButton/styles';
import {
  HeaderContainer, HeaderText, SubscriptionContent,
} from './styles';
import Loader from '../Loader';

interface IProps {
  show: boolean
  subscription: any
  nextBillingValue: any
  toggle: () => void
}

export default function ReactivateModal({
  show, toggle, subscription, nextBillingValue,
}: IProps) {
  const [loading, setLoading] = useState(false);
  const reactivate = async () => {
    setLoading(true);
    try {
      await api.post('/payments/vindi/reactivate_subscription');
      toast('Assinatura reativada com sucesso!');
      setLoading(false);
      toggle();
      window.location.reload();
    } catch (e) {
      toast.error('Erro ao renovar a assinatura!');
      setLoading(false);
    }
  };

  return (
    <Modal
      isVisible={show}
      setIsVisible={toggle}
    >

      <HeaderContainer>
        <HeaderText>Deseja realmente reativar sua assinatura?</HeaderText>
      </HeaderContainer>

      <SubscriptionContent>
        <h4>
          Ao reativar ela será renovada conforme as condições e na data prevista abaixo:
        </h4>
        <div>
          <h5>Meio de pagamento previsto:</h5>
          <p>{subscription?.payment_method?.public_name}</p>
        </div>
        <div>
          <h5>Valor total previsto na renovação:</h5>
          <p>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(nextBillingValue)}
            &nbsp;
            {subscription?.interval_count > 1
              ? `em ${subscription?.installments}x`
              : ''}
          </p>
        </div>
        <div>
          <h5>Data prevista</h5>
          <p>{subscription?.current_period ? format(new Date(subscription.current_period?.end_at), 'dd-MM-yyyy') : ''}</p>
        </div>
        <GenericButton
          style={{ width: '100%', marginTop: '30px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.title}
          textColor={theme.colors.title}
          type="button"
          onClick={reactivate}
        >
          {
            loading
              ? <Loader color="#fff" />
              : 'Reativar'
          }
        </GenericButton>

        <GenericButton
          style={{ width: '100%', marginTop: '10px', marginBottom: '3px' }}
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.redAlert}
          textColor={theme.colors.redAlert}
          type="button"
          onClick={toggle}
        >
          SAIR
        </GenericButton>

      </SubscriptionContent>
    </Modal>
  );
}

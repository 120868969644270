import React from 'react';
import { Oval } from 'react-loader-spinner';
import {
  Modal,
  ModalBackDrop,
  ModalContent,
  ModalDialog,
  TextCoupon,
  Text,

} from './styles';

interface OnboardModalProps {
  visible: boolean;
}

export default function CouponModal({ visible }: OnboardModalProps) {
  return (
    <>
      {visible && (
        <>
          <Modal>
            <ModalDialog>
              <ModalContent>
                <TextCoupon>
                  <Oval
                    height={80}
                    width={80}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible
                    ariaLabel="oval-loading"
                    secondaryColor="#000033"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                  />
                  <Text>
                    Gerando seu cupom.
                  </Text>
                </TextCoupon>
              </ModalContent>
            </ModalDialog>
          </Modal>
          <ModalBackDrop />
        </>
      )}
    </>
  );
}

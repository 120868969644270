import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: block;

  background-color: ${theme.colors.primary2};
`;

export const ProfileEditContainer = styled.div``;

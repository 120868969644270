import styled from 'styled-components';
import theme from '../../theme';

export const SubscriptionContainer = styled.div`
  margin-top: 1px;
  margin-left: 55px;
  margin-right: 60px;
  border-radius: 10px;
  background-color: ${theme.colors.primary2};
`;

export const Header = styled.header`
  padding-top: 20px;
  padding-bottom: 20px;
 
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  height: 12px;
  color: ${theme.colors.title};
  display: block;

  @media (max-width: 667px) {
      font-size: 12px;
      margin-bottom: 8px;
    }
`;

export const SubscriptionContent = styled.div`
  display: block;
  text-align: center;
  padding-bottom: 20px;

  p,
  h5 {
    display: inline;
    padding-top: 10px;
  }

  h5 {
    color: ${theme.colors.text};
    font-size: 14px;

    text-align: center;
    font-weight: lighter;
    line-height: 12px;

    @media (max-width: 667px) {
      font-size: 11px;
    }
  }

  p {
    color: ${theme.colors.title};
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    padding-left: 5px;

    @media (max-width: 667px) {
      font-size: 11px;
    }

    &.text-danger {
      color: #b52b12;
    }

    &.text-success {
      color: #36ba14;
    }
  }
`;

export const TableHeaderMain = styled.header`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 55px;
  margin-right: 60px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color:${theme.colors.primary};
  align-items: center;
  color: ${theme.colors.title};
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
`;

export const TableContainer = styled.div`
  margin-top: 1px;
  margin-left: 55px;
  margin-right: 60px;
  border-radius: 10px;
  padding: 5px;
  background: ${theme.colors.primary2};
  border-radius: 10px;

  @media (max-width: 727px) {
    overflow-x: scroll;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0.5rem;
    text-align: center;
  }
`;

export const TableHeader = styled.thead`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: ${theme.colors.cardB2};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

  tr {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: ${theme.colors.cardB2};
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    td {
      color: ${theme.colors.cardTxt};
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
     

      &:first-of-type {
        border-radius: 10px 0px 0px 0px;
      }

      &:last-of-type {
        border-radius: 0px 10px 0px 0px;
      }
    }
  }
`;

export const TableBody = styled.tbody`
  padding: 0 1rem;

  td {
    padding: 1rem 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: ${theme.colors.title};
    border-bottom: 1px solid rgba(180, 180, 194, 0.1); 
  }

  .text-success {
    color: #36ba14;
  }

  .text-danger {
    color: #b52b12;
  }

  .text-warning {
    color: #FBE306;
  }

  .text-secondary {
    color: #CECECE;
  }
`;

export const Loading = styled.div`
  align-items: center;
`;

export const Error = styled.div`
  margin: auto;
  font-size: medium;
  font-family:"Roboto", sans-serif;
  color: red;
  text-align: center;
`;

import React from 'react';
import { Container, Button, ButtonText } from './styles';
import BaseModal from '../../../../components/BaseModal';

interface PropsI {
  onViewMessageClick: () => void,
  onDeleteCardClick: () => void,
  onMarkAsRead: () => void,
  onMarkAllAsRead: () => void,
  viewed: boolean,
  showMenu: boolean,
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
}

function MenuNotification({
  onViewMessageClick, onDeleteCardClick, onMarkAsRead, viewed, showMenu, setShowMenu, onMarkAllAsRead,
}: PropsI) {
  function handleClick(callback: Function) {
    setShowMenu(false);
    callback();
  }

  return (
    <BaseModal isVisible={showMenu} setIsVisible={setShowMenu}>
      <Container>
        <Button onClick={() => handleClick(onViewMessageClick)} bottomBorder>
          <ButtonText>
            Ver mensagem completa
          </ButtonText>
        </Button>
        {!viewed && (
          <Button onClick={() => handleClick(onMarkAsRead)} bottomBorder>
            <ButtonText>
              Marcar como lida
            </ButtonText>
          </Button>
        )}
        <Button onClick={() => handleClick(onDeleteCardClick)} bottomBorder={!viewed}>
          <ButtonText>
            Excluir notificação
          </ButtonText>
        </Button>
        {
          !viewed && (
            <Button onClick={() => handleClick(onMarkAllAsRead)}>
              <ButtonText>
                Marcar todas como lidas
              </ButtonText>
            </Button>
          )
        }
      </Container>
    </BaseModal>
  );
}

export default MenuNotification;

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  padding: 8px;
  width: 100%;
  background-color: ${appTheme.background};
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;

  input {
    margin-bottom: 20px;
  }
`;

export const MetricContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Label = styled.p.attrs(() => ({
  numberOfLines: 1,
}))`
  color: ${appTheme.title};
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 5px;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${appTheme.title};
  border-radius: 5px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
  width:100%;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  text-transform: uppercase;
  color: ${appTheme.background};
  font-weight: 700;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin: 5px;
  font-size: 13px;
`;

import styled from 'styled-components';
import theme from '../../../theme';

export const CourseImage = styled.div`
    position: relative;
`;

export const ContainerImg = styled.div`
    position: relative;
    &::before{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(${theme.colors.cardImg});
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    img{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        max-width: 100%;
        object-fit: cover;
        min-height: 100px;

        @media (max-width: 667px) {
            min-height: 50px;
        }
    }  
`;

export const CourseImageTitle = styled.div`
    position: absolute;
    left: 15px;
    bottom: 22px;

    @media (max-width: 667px) {
        left: 8px;
        bottom: 8px;
    }
    
    h3{
        font-size: 15px;
        color: ${theme.colors.title};
        font-weight: 500;
        
        @media (max-width: 667px) {
            font-size: 10px;
        }
    }
`;

export const CourseDescription = styled.div`
    padding: 15px;
    min-height: 40px;

    @media (max-width: 667px) {
        padding: 8px;
    }
    
    p{
        font-size: 14px;
        color:${theme.colors.cardTxt};

        @media (max-width: 667px) {
            font-size: 10px;
        }
    }
`;

import styled from 'styled-components';
import theme from '../../theme';

interface ControlPanelI {
  isFirst?: boolean
}

export const ControlPanel = styled.div<ControlPanelI>`
  display: flex;  
  justify-content: ${(props) => (props.isFirst ? 'flex-end' : 'space-between')};
  margin-top: 4%;
`;

export const ControlButton = styled.div`
  border-color: ${theme.colors.title};
  border-width: 2px;
  display: flex;  
  align-items: center;
  justify-content: center;
  border-radius: 5px;  
  width: 40%;
  padding: 1%;
  cursor: pointer;
`;

export const ControlText = styled.p`
  color: ${theme.colors.title};
  font-weight: 500;
`;

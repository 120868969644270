import React from 'react';
import {
  Container, Item, ItemDesc, ItemsContainer, ItemTitle, ItemTitleUnderline,
} from './styles';
import BackArrow from '../../../components/BackArrow';

export default function FinancialService() {
  return (
    <Container>
      <BackArrow text="ATENDIMENTO FINANCEIRO" bigger backTo="/help" />
      <ItemsContainer>
        <Item onClick={() => window.open('https://ajuda.corridaperfeita.com/support/solutions/folders/47000771753')}>
          <ItemTitle>
            {'Para cancelamentos e reembolsos de sua assinatura, '}
            <ItemTitleUnderline>clique aqui</ItemTitleUnderline>
            .
          </ItemTitle>
        </Item>
        <Item onClick={() => window.open('mailto:assinatura@corridaperfeita.com')}>
          <ItemTitle>
            Para outras solicitações ou dúvidas, escreva para o e-mail
            <ItemTitleUnderline> assinatura@corridaperfeita.com</ItemTitleUnderline>
            .
          </ItemTitle>
          <ItemDesc>
            Respondemos em até 1 dia útil
          </ItemDesc>
        </Item>
      </ItemsContainer>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FaChevronDown } from 'react-icons/fa';
import {
  Container, ForgotPasswordLink, Input, InputGroup, SignInButton, InputLabel, CouponCap, CouponButton, HiddenCouponValue, RaceTitle, LoginDrawerContainer, CouponCapEffect, MaskedInput, RaceTitle2, LoginButton,
} from './styles';
import { login } from '../../store/Auth.store';
import { RootState } from '../../store';
import Loader from '../Loader';
import BaseModal from '../BaseModal';
import RaceCalendarContext from '../../pages/RaceCalendar/Context/RaceCalendarContext';
import { IRace } from '../../store/RaceCalendar.store';

function validEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

interface IErrorCallback {
  hasError: boolean;
  title: string;
  message: string;
}

export interface IRaceModalLoginProps {
  isOpen: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  race: IRace;
}

export default function RaceModalLogin({ isOpen, setIsVisible, race }: IRaceModalLoginProps) {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const [showLoginDrawer, setShowLoginDrawer] = useState(false);

  const { loading, token } = useSelector((state: RootState) => state.auth);

  const {
    setVisitorDataSaved, setVisitorUserName, setVisitorEmail, setVisitorPhone, visitorUserName, visitorEmail, visitorPhone,
  } = React.useContext(RaceCalendarContext).rdTracking;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function errorCallback({ hasError, title, message }: IErrorCallback) {
    if (hasError) {
      toast.error(`${title}: ${message}`);
    }
  }

  function handleLogin(e: any) {
    e.preventDefault();

    dispatch(login(loginEmail, loginPassword, errorCallback) as any);
  }

  function handleSendVisitorData() {
    if (!visitorUserName || !visitorEmail) return toast.error('Preencha todos os campos obrigatórios');

    if (!validEmail(visitorEmail)) return toast.error('Insira um email válido');

    return setVisitorDataSaved(true);
  }

  useEffect(() => {
    if (token) {
      navigate('/corridaderua');
    }
  }, [token]);

  return (

    <BaseModal isVisible={isOpen} setIsVisible={setIsVisible}>
      <Container>
        <RaceTitle>
          preencha os campos abaixo e verifique cupons para
          {' '}
          {race.name}
        </RaceTitle>
        <InputGroup>
          <InputLabel> Nome* </InputLabel>
          <Input
            type="text"
            name="name"
            required
            autoFocus
            value={visitorUserName}
            onChange={(e) => setVisitorUserName(e.target.value)}
          />
        </InputGroup>

        <br />
        <InputGroup>
          <InputLabel> Email* </InputLabel>
          <Input
            type="email"
            name="email"
            required
            value={visitorEmail}
            onChange={(e) => setVisitorEmail(e.target.value)}
          />
        </InputGroup>

        <br />
        <InputGroup>
          <InputLabel>WhatsApp</InputLabel>
          <MaskedInput
            type="tel"
            name="telephone"
            value={visitorPhone}
            onChange={(e) => setVisitorPhone(e.target.value.replace(/\D/g, ''))}
            maskChar={null}
            mask={visitorPhone.length > 10 ? visitorPhone.length > 11 ? '+99 (99) 9.9999-9999' : '(99) 9.9999-99999' : '(99) 9999-99999'}
          />
        </InputGroup>

        <CouponButton>
          <CouponCapEffect />
          <CouponCap onClick={handleSendVisitorData}>
            VERIFICAR CUPOM DE DESCONTO
          </CouponCap>
          <HiddenCouponValue>TA10</HiddenCouponValue>
        </CouponButton>

        <RaceTitle2>já é membro do corrida perfeita?</RaceTitle2>
        <LoginButton onClick={() => setShowLoginDrawer(!showLoginDrawer)} className={showLoginDrawer ? 'invert-arrow' : ''}>
          Fazer login
          {' '}
          <FaChevronDown />
        </LoginButton>

        <LoginDrawerContainer className={showLoginDrawer ? 'visible' : ''}>
          <InputGroup>
            <Input
              type="email"
              placeholder="Digite seu e-mail"
              name="email"
              required
              autoFocus
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
          </InputGroup>

          <br />

          <InputGroup>
            <Input
              type="password"
              placeholder="Sua senha"
              name="password"
              required
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
          </InputGroup>

          <SignInButton onClick={handleLogin}>
            {
              loading ? <Loader /> : 'Acessar'
            }
          </SignInButton>

          <ForgotPasswordLink to="/solicitar-recuperar-senha">Recuperar senha</ForgotPasswordLink>
        </LoginDrawerContainer>
      </Container>
    </BaseModal>
  );
}

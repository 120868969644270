import React from 'react';
import { useParams } from 'react-router-dom';
import CalculatorsContext from './CalculatorsContext';

import {
  CalculatorStep, CalculatorType, CalculatorsContextData, ExternalCalculatorType,
} from './types';

interface CalculatorsProviderProps {
  children: React.ReactNode;
}

export default function CalculatorsProvider({ children }: CalculatorsProviderProps) {
  // Flow data
  const [calculatorStep, setCalculatorStep] = React.useState<CalculatorStep>(CalculatorStep.Input);
  const [currentActiveCalculator, setCurrentActiveCalculator] = React.useState<CalculatorType>(CalculatorType.Pace);

  // Logic Data
  const [time, setTime] = React.useState<number | undefined>();
  const [distance, setDistance] = React.useState<number | undefined>();
  const [pace, setPace] = React.useState<number | undefined>();
  const [error, setError] = React.useState<string | undefined>();

  // Result Data
  const [resultTime, setResultTime] = React.useState<number | undefined>();
  const [resultDistance, setResultDistance] = React.useState<number | undefined>();
  const [resultPace, setResultPace] = React.useState<number | undefined>();
  const [resultVelocity, setResultVelocity] = React.useState<number | undefined>();

  // Actions
  function calculatePace(time: number, distance: number) {
    return time / distance;
  }

  function calculateTime(pace: number, distance: number) {
    return pace * distance;
  }

  function calculateDistance(pace: number, time: number) {
    return Number((time / pace).toFixed(2));
  }

  function calculateVelocity(distance: number, time: number) {
    return Number((distance / (time / 3600)).toFixed(2));
  }

  function reset(resetFlowData = true) {
    setTime(undefined);
    setDistance(undefined);
    setPace(undefined);
    setError(undefined);
    setResultTime(undefined);
    setResultDistance(undefined);
    setResultPace(undefined);
    setResultVelocity(undefined);
    if (resetFlowData) {
      setCalculatorStep(CalculatorStep.Input);
      setCurrentActiveCalculator(CalculatorType.Pace);
    }
  }

  function copyInputDataToResultData() {
    setResultTime(time);
    setResultDistance(distance);
    setResultPace(pace);
  }

  const calculate = React.useCallback(() => {
    setError(undefined);

    copyInputDataToResultData();

    if (time && distance) {
      setCalculatorStep(CalculatorStep.Result);
      return setResultPace(calculatePace(time, distance));
    }

    if (distance && pace) {
      setCalculatorStep(CalculatorStep.Result);
      return setResultTime(calculateTime(pace, distance));
    }

    if (time && pace) {
      setCalculatorStep(CalculatorStep.Result);
      return setResultDistance(calculateDistance(pace, time));
    }

    return setError('Preencha os dois campos para calcular');
  }, [time, distance, pace]);

  // Effects
  React.useEffect(() => {
    if (resultDistance && resultTime) {
      setResultVelocity(calculateVelocity(resultDistance, resultTime));
    }
  }, [resultTime, resultDistance, resultPace]);

  const { activeCalculator } = useParams();

  React.useEffect(() => {
    if (activeCalculator) {
      const activeCalculatorCapitalized = activeCalculator.charAt(0).toUpperCase() + activeCalculator.slice(1);

      const parsedExternalCalculatorType = ExternalCalculatorType[activeCalculatorCapitalized as keyof typeof ExternalCalculatorType];

      if (Object.values(CalculatorType).includes(parsedExternalCalculatorType)) {
        setCurrentActiveCalculator(parsedExternalCalculatorType);
      }
    }
  }, [activeCalculator]);

  React.useEffect(() => {
    reset(false);
  }, [currentActiveCalculator]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value : CalculatorsContextData = {
    flowData: {
      calculatorStep,
      currentActiveCalculator,
    },
    logicData: {
      time,
      distance,
      pace,
      error,
    },
    resultData: {
      resultTime,
      resultDistance,
      resultPace,
      resultVelocity,
    },
    actions: {
      setCurrentActiveCalculator,
      setTime,
      setDistance,
      setPace,
      calculate,
      reset,
    },
  };

  return (
    <CalculatorsContext.Provider value={value}>
      {children}
    </CalculatorsContext.Provider>
  );
}

import * as React from 'react';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { MdPlayCircleOutline, MdAddCircleOutline, MdDeleteOutline } from 'react-icons/md';
import { isBefore } from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  Container, CardHeader, CardBody, CardFooter, StatusIcon, CardHeaderTitle,
} from './style';
import TrainingStatus from '../../../TrainingStatus';
import { formatDuration, formatTrainingIcon, TrainingType } from '../../../../utils';
import { ITrainingOfDay } from '../../../../types/TrainingPlan';
import RegisterActivity from '../../../RegisterActivity';
import UnlinkActivityModal from '../../../UnlinkActivityModal';

export interface ICustomEventCardProps {
  eventData: MbscCalendarEventData;
  handleOpenActivityDetailsModal: (tId: number) => void;
}

export default function CustomEventCard({ eventData, handleOpenActivityDetailsModal }: ICustomEventCardProps) {
  const navigate = useNavigate();

  const resourcesData: ITrainingOfDay = React.useMemo(() => JSON.parse(eventData.original?.resource as string || '{}'), [eventData.original?.resource]);

  const [registerModalVisible, setRegisterModalVisible] = React.useState(false);
  const [unlinkModalVisible, setUnlinkModalVisible] = React.useState(false);

  const showRunDistance = React.useMemo(
    () => (
      resourcesData.type.toUpperCase() === TrainingType.RUN && resourcesData.distance
    ),
    [resourcesData],
  );

  const showDescription = React.useMemo(
    () => (resourcesData.type.toUpperCase() !== TrainingType.RUN && resourcesData.description),
    [resourcesData],
  );

  const showRunTraining = React.useMemo(() => (resourcesData.trainingGroups.length > 0
    && resourcesData.type.toUpperCase() === TrainingType.RUN), [resourcesData.trainingGroups]);

  function goToTraining() {
    navigate({
      pathname: `/exerciseList/${resourcesData.type}`,
      search: `?${createSearchParams({
        trainingToAttachId: resourcesData.id.toString(),
      })}`,
    });
  }

  const handleOpenActivityDetails = () => {
    if (resourcesData?.activityOfTraining) {
      handleOpenActivityDetailsModal(resourcesData?.id);
    }
  };

  return (
    <>
      <Container>
        <CardHeader hasActivity={!!resourcesData?.activityOfTraining} onClick={handleOpenActivityDetails}>
          {(resourcesData.status || isBefore(new Date(resourcesData.date), new Date()))
            && (
              <StatusIcon>
                <TrainingStatus status={resourcesData.status} small />
              </StatusIcon>
            )}

          {
            resourcesData?.trainingGroups.length > 0
            && !isBefore(new Date(resourcesData.date), new Date())
            && (
              <StatusIcon>
                <TrainingStatus status={resourcesData.status} small further />
              </StatusIcon>
            )
          }

          <CardHeaderTitle>
            {formatTrainingIcon(resourcesData.type.toUpperCase())}
            <p>{resourcesData.name}</p>
          </CardHeaderTitle>
        </CardHeader>

        <CardBody>
          {
            (resourcesData.activityOfTraining
              && resourcesData.type.toUpperCase() === TrainingType.RUN)
            && (
              <div className={`dimmed-distance ${resourcesData.status}`}>
                {`Executado ${resourcesData.activityOfTraining.distance} Km`}
              </div>
            )
          }
          {showRunDistance
            && (
              <div className="dimmed-distance">
                {`Planejado ${resourcesData.distance} Km`}
              </div>
            )}

          {showDescription
            && (
              <div>
                {resourcesData.description}
              </div>
            )}

          {!showDescription && !showRunTraining && resourcesData.type.toUpperCase() !== TrainingType.RUN
            && (
              <div className="dimmed-distance">
                {
                  resourcesData.equipments
                    ? resourcesData.equipments
                    : 'Apenas peso do corpo'
                }
              </div>
            )}

          {showRunTraining
            && (
              <div>
                <ol>
                  {
                    resourcesData.trainingGroups.map((trainingGroup) => (
                      <li key={trainingGroup.id}>
                        {trainingGroup.repetition && trainingGroup.repetition > 1 ? `${trainingGroup.name} | Repetir ${trainingGroup.repetition} X`
                          : `${trainingGroup.name}`}
                        <div>
                          {trainingGroup.trainingActivities.map((trainingActivitie) => {
                            if (trainingActivitie.distance) {
                              return (
                                <div key={trainingActivitie.id}>
                                  <span>
                                    {trainingActivitie.distance ? `${trainingActivitie.distance} km` : ''}
                                    {trainingActivitie.zone ? ` @ ${trainingActivitie.zone}` : ''}
                                  </span>
                                </div>
                              );
                            } if (trainingActivitie.duration) {
                              return (
                                <div key={trainingActivitie.id}>
                                  <span>
                                    {trainingActivitie.duration ? `${formatDuration(trainingActivitie.duration, 'time')}` : ''}
                                    {trainingActivitie.zone ? ` @ ${trainingActivitie.zone}` : ''}
                                  </span>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </li>
                    ))
                  }
                </ol>
              </div>
            )}
        </CardBody>

        <CardFooter>
          {
            !resourcesData.activityOfTraining
            && resourcesData.type.toUpperCase() !== TrainingType.RUN
            && !resourcesData.status
            && (
              <div role="presentation" onClick={() => goToTraining()}>
                <MdPlayCircleOutline />
                Realizar série
              </div>
            )
          }
          {
            !resourcesData.activityOfTraining
            && !resourcesData.status
            && (
              <div role="presentation" onClick={() => setRegisterModalVisible(true)}>
                <MdAddCircleOutline />
                Registrar atividade
              </div>
            )
          }
          {
            resourcesData.status
            && (
              <div
                role="presentation"
                onClick={() => setUnlinkModalVisible(true)}
              >
                <MdDeleteOutline />
                Desvincular atividade
              </div>
            )

          }

        </CardFooter>
      </Container>

      <RegisterActivity
        visible={registerModalVisible}
        close={() => setRegisterModalVisible(false)}
        _title={resourcesData.name}
        _date={resourcesData.date}
        _type={resourcesData.type}
        clearStatesOnClose={false}
        trainingId={resourcesData.id}
        submitCallback={() => window.location.reload()}
      />

      <UnlinkActivityModal
        setShowModal={setUnlinkModalVisible}
        showModal={unlinkModalVisible}
        callback={() => window.location.reload()}
        activityId={resourcesData?.activityOfTraining?.id}
        trainingId={resourcesData?.id}
        trainingDate={resourcesData.date}
        trainingName={resourcesData.name}
      />
    </>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: block;
  
  background: ${theme.colors.cardB1};
  padding: 1%;
`;

export const HeaderContainer = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const LinkTextButton = styled.span`
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 5px;
  max-width: 25%;
  cursor: pointer;
`;

export const LinkText = styled.span`
  text-decoration: underline;
  color: ${theme.colors.title};
  margin-left: 3px;
  font-size: 11px;
`;

export const ItemsContainer = styled.div``;

export const TextInfoContainer = styled.span`
  display: flex;
  justify-content: center;
  width: 90%;
  align-items: center;
  align-self: center;
  margin-top: 20px;
`;

export const TextInfo = styled.span`
  display: flex;
  color: ${theme.colors.title};
  align-self: center;
  font-size: 12px;
  text-align: center;
`;

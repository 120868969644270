import React from 'react';
import ContentLoader from 'react-content-loader';
import { appTheme } from '../../../../theme';

function Skeleton() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      backgroundColor={appTheme.background}
      foregroundColor={appTheme.cardB3}
      style={{ marginBottom: '5px' }}
    >
      <rect width="100%" height="200" />
    </ContentLoader>
  );
}

export default Skeleton;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export const Container = styled.section`
    display: flex;
    width: 100%;
    padding-left: 6%;
    padding-bottom: 6%;
    padding-right: 6%;
    flex-direction: column;
    background-color: ${theme.colors.primary2};
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    @media (max-width: 667px) {
      padding: 0 10px 10px 10px;
    }
`;

export const ContainerProfile = styled.section`  
    width: 100%;
    padding: 15px 10px 15px 0px;
    background-color: ${theme.colors.primary2};
    display: flex;
    justify-content: space-between;
`;

export const Separator = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${appTheme.background};
    margin: 20px 0;
`;

export const Button = styled.button`
  border-radius: 10px;
  width: 95%;
  background-color: ${appTheme.notifications};
  padding: 13px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
`;

export const TextButton = styled.p`
  color:  ${appTheme.background};
  font-weight: 900;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
`;

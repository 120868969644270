import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import store, { persistor } from './store';
import Routes from './routes';
import GlobalStyle from './global';
import { NavbarProvider } from './context/NavbarContext';
import api, { api2 } from './services/api';
import coreApi from './services/coreApi';

declare global {
  interface Window {
    gtag: any;
  }
}

export default function App() {
  const { token } = store.getState().auth;

  const location = useLocation();

  React.useEffect(() => {
    // Google Analytics
    if (typeof window.gtag !== 'undefined') {
      window.gtag(
        'set',
        'page',
        location.pathname + location.search,
      );

      window.gtag('send', 'pageview');
    }
  }, [location]);

  useEffect(() => {
    if (token) {
      api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      api.post('web/log_user').then();
      api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
      coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
  }, [token]);

  return (
    <GoogleOAuthProvider clientId="819167375724-1b788vfpcc1ci67fpabm3fb0npaomv7p.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <NavbarProvider>
            <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=UA-78078589-1" />
              <script>
                {`
                   window.dataLayer = window.dataLayer || [];
                   function gtag() { dataLayer.push(arguments); }
                   gtag('js', new Date());
               
                   gtag('config', 'UA-78078589-1');
                   `}
              </script>
              <meta name="apple-itunes-app" content="app-id=1615429884" />
              <meta name="google-play-app" content="app-id=com.corridaperfeita.app" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <meta name="theme-color" content="#000033" />
              <meta name="description" content="Plataforma Corrida Perfeita" />

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
              <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap" rel="stylesheet" />

              <title>Corrida Perfeita</title>
            </Helmet>
            <Routes />
            <Toaster
              position="top-right"
            />
            <GlobalStyle />
          </NavbarProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

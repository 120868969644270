import styled from 'styled-components';
import THEME from '../../../theme';

export const ItemTitle = styled.p`
    color: ${THEME.colors.title};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
`;

export const ModalContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;

export const ModalButtonsContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
`;

export const ItemInfo = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const ItemSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${THEME.colors.cardB2};
`;

export const ItemText = styled.p`
    color: ${THEME.colors.title};
`;

export const ItemTextDimmed = styled.p`
    color: ${THEME.colors.gray};
    margin-right: 5px;
`;

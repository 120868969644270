import styled from 'styled-components';
import theme from '../../theme';

export const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    padding: 10px;
    border-radius: 10px;    
    background-color: ${theme.colors.cardB2};
`;

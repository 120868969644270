import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getClasses } from '../../store/Classes.store';
import Loader from '../Loader';
import CourseCard from './CourseCard';
import {
  Container,
  NoCardText,
  NoCourseCard,
  Titles,
} from './styles';
import Carousel from '../Carousel';

function CourseCarousel() {
  const [loading, setLoading] = useState<boolean>(true);
  const [noResult, setNoResult] = useState<boolean>(false);

  const classes = useSelector((state: RootState) => state.classes.ClassList);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getClasses());
  }, []);

  useEffect(() => {
    if (classes?.length > 0) {
      setLoading(false);
    } else {
      setNoResult(true);
      setLoading(false);
    }
  }, [classes]);

  return (
    <Container data-testid="courses-carousel-container">
      <Titles>
        AULAS PARA VOCÊ
      </Titles>
      <Carousel>
        {loading ? (
          <Loader />
        ) : (
          <>
            {classes?.map((item, index) => (
              index < 3
              && <CourseCard key={item?.id} item={item!} />
            ))}
          </>
        )}
        {noResult && !loading && classes?.length === 0 && (
          <NoCourseCard>
            <NoCardText>Nenhuma aula disponível no momento</NoCardText>
          </NoCourseCard>
        )}
      </Carousel>
      <Carousel>
        {loading ? (
          <Loader />
        ) : (
          <>
            {classes?.map((item, index) => (
              index >= 3
              && <CourseCard key={item?.id} item={item!} />
            ))}
          </>
        )}
      </Carousel>
    </Container>
  );
}

export default CourseCarousel;

import styled from 'styled-components';
import { ButtonProps, ButtonTextProps } from './contracts';

export const ModalBackground = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        background-color: transparent;
        cursor: pointer;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-height: 85%;
    background-color: #000033;
    padding: 5px;
`;

export const ModalContentChild = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
`;

export const ModalContentHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

export const ModalContentBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #0D0D3D;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
`;

export const ModalContentTitle = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: bold;
`;

export const ModalContentSubTitleContainer = styled.div`
    margin-top: 15px;
`;

export const ModalContentSubTitle = styled.p`
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
`;

export const ModalContentButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 70%;
`;

export const ModalContentButton = styled.button<ButtonProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: ${(props) => (props.buttonType === 'back' ? '#000033' : '#fff')};
    border: 1px solid #fff;
`;

export const ModalContentButtonText = styled.p<ButtonTextProps>`
    color: ${(props) => (props.buttonType === 'back' ? '#fff' : '#000')};
    font-size: 14px;
`;

export const Delimiter = styled.div`
    width: 100%;
    background-color: #2f2f58;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 10px;
`;

export const TrainingPlanRequestContainer = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const TrainingPlanRequestContainerTitle = styled.p`
    color: #fff;
    font-size: 12px;
    text-align: center;
`;

export const TrainingPlanRequestDetails = styled.div`
    background-color: #0D0D3D;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px;
`;

export const TrainingPlanRequestDetailsItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
`;

export const TrainingPlanRequestDetailsItemPrimaryText = styled.p`
    color: #fff;
    font-size: 10px;
    width: 50%;
`;

export const TrainingPlanRequestDetailsItemSecondaryText = styled.p`
    color: #c3c3c3;
    font-size: 10px;
    width: 50%;
`;

export const TrainingPlanRequestDetailsItemDelimiter = styled.div`
    width: 100%;
    background-color: #2f2f58;
    height: 1px;
`;

export const TrainingPlanRequestContainerDescription = styled.p`
    color: #fff;
    font-size: 10px;
    text-align: center;
    margin-top: 20px;
`;

export const TrainingPlanRequestButton = styled.button<ButtonProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50px;
    border: 1px solid ${(props) => props.color};
    border-radius: 5px;
    margin-top: 20px;
`;

export const TrainingPlanRequestButtonText = styled.p<ButtonTextProps>`
    color: ${(props) => props.color};
    font-size: 14px;
    text-align: center;
`;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface DotI {
  selected: boolean
}

export const Modal = styled.div`  
    background-color: ${appTheme.whiteBG};
    z-index: 999;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0; 
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    background: ${theme.colors.cardB1};
    flex-direction: column;
    display: flex;    
    align-items: center;
    max-width: 600px;    
    padding: 2rem;
`;

export const LogoContainer = styled.div`
    width: 30%;
    height: 10%;
    margin: 8%;
    img{
        max-width: 100%;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;    
    overflow: hidden;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

export const Progress = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Dot = styled.div<DotI>`
    background: ${(props) => (props.selected ? theme.colors.title : theme.colors.iconsOff)};
    height: 14px;
    width: 14px;
    margin: 4px;
    border-radius: 7px;
`;

export const Title = styled.h1`
  color: ${theme.colors.title};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8%;
`;

export const Paragraph = styled.p`
    color: ${theme.colors.text};
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-bottom: 3%;
`;

export const ControlButton = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    padding: 4%;
    min-height: 50px;
    color: ${theme.colors.title}
`;

export const InstructionButton = styled.button`
    cursor: pointer;
    background: ${theme.colors.title};
    padding: 14px 28px;
    border-radius: 8px;
    margin-top: 3%;
    color: ${theme.colors.cardB1};
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;
    @media (min-width: 768px) {
        font-size: 14px;
    }
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.div`
  width: 100%;
  background-color: ${theme.colors.cardB2};
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  flex-direction: column;

  @media (max-width: 667px) {
    padding: 8px;
    margin-bottom: 8px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  h2{
    color: ${theme.colors.title};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 13px;

    @media (max-width: 667px) {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button{    
    color: ${theme.colors.title};
    text-decoration: underline;
    margin: 5px;    
    font-size: 12px;
    text-decoration-color: ${theme.colors.title};
    cursor: pointer;
    border: none;
    background: none;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  background-color: ${theme.colors.cardB3};
  padding: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  margin: 3px;
  p{
    color: ${theme.colors.title};
    font-size: 13px;
    margin-left: 5px;
  }
`;

export const LinkText = styled.div`
  color: ${theme.colors.title};
  text-decoration: underline;
  
  font-size: 13px;
  margin: 5px; 
  text-decoration-color: white;
  cursor: pointer;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

import styled from 'styled-components';
import theme, { appTheme } from '../../../theme';

export const Container = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    flex-direction: row;
    background-color: ${appTheme.cardB3};
    border-radius: 10px;
    justify-content: space-between;
    box-shadow: ${theme.defaultShadow};
`;

export const Title = styled.h1`
    text-transform: uppercase;
    color: ${appTheme.title};
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 15px;
`;

export const ContentCalendar = styled.div`    
    background-color: ${appTheme.cardB2};
    width: 100%;
    padding: 10px;
    border-radius: 10px;    
    margin-top: 20px;
    margin-bottom: 10px;
    box-shadow: ${theme.defaultShadow};
`;

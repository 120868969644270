import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  display: block;
  background: ${theme.colors.cardB1};

  padding-bottom: 0px;
`;

export const FilterContainer = styled.div`
  display:flex;
  margin: 8px;
  padding-top: 5px;
  align-items: center;
 

`;

export const FilterLabel = styled.span`
  color: ${theme.colors.title};
  margin-right: 10px;
`;

interface IconButtonProps {
  active: boolean;
}

export const IconButton = styled.span<IconButtonProps>`
  border: ${({ active }) => (active ? '1px solid white' : '1px solid transparent')};
  background-color: ${theme.colors.cardB2};
  padding: 5px;
  border-radius: 5px;
  margin-right: 8px;
  align-items: center;
  cursor: pointer;
`;

export const IconButtonText = styled.span`
  color: ${theme.colors.title};
  font-weight: 500;
  margin-left: 5px;
`;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  Modal,
  Container,
  Content,
  ControlButton,
  Dot,
  Footer,
  InstructionButton,
  Paragraph,
  Progress,
  Title,
} from './styles';

interface SlideOnboardI {
  isVisible: boolean
  data?: {
    title: string,
    link: string | null,
    pages: Array<Array<string>>
  }
  finishSlide: () => void
  isVideo?: boolean
  hiddenFooter?: boolean
}

function SlideOnboard({
  isVisible,
  data,
  finishSlide,
  isVideo,
  hiddenFooter,
}: SlideOnboardI) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [uri, setUri] = useState<string | undefined>();

  const getVideo = async () => {
    try {
      const res = await axios.get(`${data?.link}/config`);

      const url = res?.data?.request?.files?.hls?.cdns[res?.data?.request?.files?.hls?.default_cdn];

      const videoUrl = url?.split('?')[0];

      setUri(videoUrl);
    } catch (err) {
      toast.error('Erro de conexão. Tente novamente mais tarde!');
    }
  };

  useEffect(() => {
    if (data?.link && isVideo) getVideo();
  }, [data?.link, isVideo]);

  return (
    <div>
      {
        isVisible && (
          <Modal>
            <Container>
              <Content>
                <Title>{data?.title}</Title>

                {
                  data?.pages[currentPage].map((item) => (
                    <Paragraph key={item}>{item}</Paragraph>
                  ))
                }

                {
                  data?.link
            && (
              <div>
                {
                  isVideo && uri
                    ? (
                      <iframe className="embed-responsive-item" title="video" src={uri} allow="fullscreen" />
                    )
                    : (

                      <a
                        href="https://ajuda.corridaperfeita.com/support/solutions/articles/47001152887-como-fazer-as-s%C3%A9ries-de-exerc%C3%ADcios-educativos-do-corrida-perfeita"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstructionButton>VER ORIENTAÇÔES</InstructionButton>
                      </a>

                    )
                }
              </div>
            )
                }
              </Content>
              {
                !hiddenFooter
                  ? (
                    <Footer>
                      <ControlButton onClick={() => {
                        if (currentPage - 1 >= 0) {
                          setCurrentPage(currentPage - 1);
                        } else {
                          setCurrentPage(data?.pages.length || 1 - 1);
                        }
                      }}
                      >
                        {currentPage === 0 ? 'Pular' : 'Anterior'}
                      </ControlButton>
                      <Progress>
                        {
                          data?.pages.map((_, index) => (
                            <Dot key={`${index + (data?.pages.length || 0)}`} selected={index === currentPage} />
                          ))
                        }
                      </Progress>
                      <ControlButton onClick={() => {
                        if (currentPage + 1 < (data?.pages.length || 1)) {
                          setCurrentPage(currentPage + 1);
                        } else {
                          finishSlide();
                        }
                      }}
                      >
                        {currentPage === (data?.pages.length || 1) - 1 ? 'Finalizar' : 'Próximo'}
                      </ControlButton>
                    </Footer>
                  )
                  : (
                    <InstructionButton onClick={finishSlide} style={{ marginBottom: 50 }}>
                      OK
                    </InstructionButton>
                  )
              }
            </Container>
          </Modal>
        )
      }
    </div>
  );
}

export default SlideOnboard;

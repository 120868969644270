import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  background-color: ${theme.colors.primary};
  margin: 1px 0;
  padding: 16px;
`;

export const CancelModalContainer = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${theme.colors.primary};
  align-items: center;
  padding: 25px 16px;
`;

export const CancelModalBackdrop = styled.div`
  background: ${theme.colors.primary};
  opacity: 0.8;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${theme.colors.cardB2};
  padding-top: 5px;
`;

export const HeaderTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-bottom: 8px;
    @media (max-width: 667px) {
    font-size: 15px;
  }
`;

const descriptionBaseStyle = {
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '17px',
  color: theme.colors.title,
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.cardB2,
};

export const HeaderDescription = styled.span`
  ${descriptionBaseStyle}
  font-size: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
    @media (max-width: 667px) {
    font-size: 15px;
  }
`;

export const ModalDescription = styled.span`
  ${descriptionBaseStyle}
  font-size: 13px;
  text-align: center;
`;

export const ContentProduct = styled.div`
`;

export const ProductTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: ${theme.colors.title};
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const ProductText = styled.span`
  font-weight: 400;
  font-size: 17px;
  color: ${theme.colors.title};
  margin-bottom: 11px;
`;

export const GenderContent = styled.div`
  display: flex;
`;

export const BtnClose = styled.button`
  border: none;
  background-color: ${theme.colors.primary};
  cursor: pointer;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Points = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DataNames = styled.div`
  margin: auto;
`;

export const DataValues = styled.div`
  align-items: flex-end;
  margin-left: auto;
`;

export const TextPoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: ${theme.colors.title};
  margin-right: 30px;
`;

export const ValuePoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: ${theme.colors.title};
  text-decoration-line: underline;
  margin-bottom: 2px;
`;

export const TotalPoints = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  color: ${theme.colors.notifications};
  text-decoration-line: underline;
`;

interface IButtonsContainerProps {
  centered?: boolean;
}

export const ButtonsContainer = styled.div<IButtonsContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  width: 100%;
  padding: 16px 0;
  background-color: rgba(0, 0, 51, 0.8);
`;

interface IButtonProps {
  dangerVariation?: boolean;
  whiteVariation?: boolean;
  small?: boolean;
}

export const Button = styled.button<IButtonProps>`
  flex: ${(props) => (props.small ? '0.5' : '1')};
  height: 40px;  
  text-align: center;
  border-width: 1px;
  border-radius: 4px;
  margin: 0 10px;
  justify-content: center;
  cursor: pointer;
  
  ${(props) => (props.dangerVariation ? `
    border-color: ${theme.colors.redAlert}; 
    background-color: ${theme.colors.primary} 
  `
    : props.whiteVariation ? `
    background-color: ${theme.colors.title};
  ` : `
    border-color: ${theme.colors.title};
    background-color: ${theme.colors.primary};
  `)}
`;

export const ButtonText = styled.span<IButtonProps>`
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 8px;

  ${(props) => (props.dangerVariation ? `
    color: ${theme.colors.redAlert};  
  `
    : props.whiteVariation ? `
    color: ${theme.colors.cardB1};
  ` : `
    color: ${theme.colors.title};
  `)}
`;

export const ErrorText = styled.span`
  font-size: 15px;
  color: ${theme.colors.redAlert};
  margin-bottom: 7px;
`;

export const SelectedProductDataName = styled.span`
  color: ${theme.colors.title};
  font-size: 21px;
`;

export const SelectedProductDataValue = styled.span`
  color: ${theme.colors.title};
  font-size: 20px;
  font-weight: 700;
`;

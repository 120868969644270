import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface ButtonI {
  primary?: boolean
}

export const Container = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  background-color: ${appTheme.whiteBG};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
`;

export const Content = styled.div`
  background: ${theme.colors.cardB1};
  padding: 4%;
  iframe{
    height: 50vh;
    width: 87vh;
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 768px) {
        height: 57vw;
        width: 100vw;
    }
  }
`;

export const TitleContainer = styled.div`
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

export const Title = styled.h2`
    width: 100%;
    color: ${theme.colors.title};
    font-weight: bold;
    font-size: 18px;
    text-align: center;  
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4%;
    min-height: 140px;
    margin-bottom: 4%;
`;

export const Buttom = styled.button<ButtonI>`
    display: block;
    margin: 15px;
    border-radius: 5px;
    padding: 13px 35px;
    border-width: ${(props) => (props.primary ? '0px' : '1px')};
    border-color: ${theme.colors.title};
    background: ${(props) => (props.primary ? theme.colors.graph : 'transparent')};
    color: ${theme.colors.title};
    font-weight: bold;
    cursor: pointer;
`;

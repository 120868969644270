import React, { useState } from 'react';
import { addSeconds, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiFillLock } from 'react-icons/ai';
import Accordion from '../Accordion';
import BackArrow from '../BackArrow';
import ClassesLengthBadge from '../ClassesLengthBadge';
import ClassProgress from '../ClassesProgress';
import { RootState } from '../../store';
import { ModulesI } from '../../types/Class';
import FreeUserModal from '../FreeUserModal';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';
import { setIdClass } from '../../store/Classes.store';
import {
  Container,
  Content,
  Cover,
  Description,
  Info,
  AccordionContainer, ContentImage, ListContainer, Thumb, ContainerImgThumb,
} from './styles';
import icoCheck1 from '../../assets/Training/check_circle1.svg';
import icoCheck2 from '../../assets/Training/check_circle2.svg';
import theme from '../../theme';

function ClassesCover() {
  const currentClass = useSelector((state: RootState) => state.classes.currentClass);
  const user = useSelector((state: RootState) => state.auth.user);
  const { isFree } = user;
  const [freeModal, setFreeModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    name,
    cover,
    description,
    executed_percent,
    courseModules,
  } = currentClass!;

  const freeClasses = [
    'Orientações iniciais importantes',
    'A postura na corrida',
    'Princípio do desequilíbrio',
    'A passada na corrida',
  ];

  const openClass = (activitie: ModulesI) => {
    // console.log('isFree', isFree);
    const isBlockForFree = !freeClasses.includes(activitie.name);
    if (isFree && isBlockForFree) {
      setFreeModal(true);
    } else {
      navigate('/club/ClassExecution');
    }
    dispatch(setIdClass(activitie.id));
  };

  return (
    <Container>
      <BackArrow text={name?.toUpperCase()} bigger backTo="/club/exclusive-classes" />
      <FreeUserModal
        isModalOpen
        isVisible={freeModal}
        text="Esta série de aulas está disponível apenas para membros do Corrida Perfeita."
        link={freeUserRedirectLinks.classes}
        toggle={() => setFreeModal(false)}
      />
      <Content>
        <Cover>
          <ContentImage
            style={{ backgroundImage: `url(${cover})` }}
          >
            <Info>
              <Description>{description}</Description>
              <ClassesLengthBadge length={courseModules.length} />
              <ClassProgress percentage={executed_percent} />
            </Info>
          </ContentImage>
        </Cover>
        <AccordionContainer>
          {
            courseModules?.map((item: any) => (
              <Accordion
                key={item.id}
                title={item.name}
              >
                {
                  item?.courseLectures.map((activitie: any) => (
                    <ListContainer
                      key={activitie.id}
                      onClick={() => openClass(activitie)}
                    >
                      <Thumb>
                        <ContainerImgThumb>
                          <img src={cover} alt="" />
                        </ContainerImgThumb>
                        <p>
                          {activitie.name}
                          <br />
                          <span>{format(addSeconds(new Date(0), activitie.duration), 'mm:ss')}</span>
                        </p>
                      </Thumb>

                      <span>
                        {
                          isFree && !freeClasses.includes(activitie.name)
                            ? <AiFillLock color={theme.colors.title} size="18" />
                            : <img src={activitie.executed_status === null ? icoCheck1 : icoCheck2} alt="Status" />
                        }
                      </span>

                    </ListContainer>
                  ))
                }
              </Accordion>
            ))
          }
        </AccordionContainer>
      </Content>

    </Container>
  );
}

export default ClassesCover;

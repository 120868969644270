import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Content,
  Title,
  TitleContainer,
  ButtonsContainer,
  Buttom,
} from './styles';

function ExerciseInitModal({
  setIsVisible, isVisible, setIsStart, onPressInit,
} : any) {
  const navigate = useNavigate();

  function startOn() {
    setIsStart(true);
    setIsVisible(false);
    onPressInit();
  }

  return (
    <>
      {
        isVisible
        && (
          <Container data-testid="counter-modalinit">
            <Content>
              <TitleContainer>
                <Title>
                  TUDO PRONTO PARA COMEÇAR?
                </Title>
              </TitleContainer>

              <ButtonsContainer>
                <Buttom
                  primary
                  onClick={() => { startOn(); }}
                >
                  INICIAR
                </Buttom>
                <Buttom onClick={() => navigate(-1)}>
                  VOLTAR
                </Buttom>
              </ButtonsContainer>

            </Content>
          </Container>
        )
      }
    </>
  );
}

export default ExerciseInitModal;

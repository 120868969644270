import styled from 'styled-components';
import { appTheme } from '../../theme';

export const ZoneListContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 15px 4px;

  @media (max-width: 667px) {
    padding: 0px 8px 0px;
  }
`;

export const InfoButton = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: unset;
  cursor: pointer;
  border: unset;

  svg {
    margin-right: 4px;
  }
`;

export const InfoButtonText = styled.p`
  color: ${appTheme.title};
  font-size: 14px;
  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const MetricButtonsContainer = styled.div`
  display: flex;
`;

interface MetricButtonProps {
  active?: boolean;
}

export const MetricButton = styled.button<MetricButtonProps>`
  cursor: pointer;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${(props) => (props.active ? appTheme.iconsOn : appTheme.text)};
  margin-left: 5px;
  background-color: unset;
`;

export const MetricButtonText = styled.p<MetricButtonProps>`
  font-weight: bold;
  color: ${(props) => (props.active ? appTheme.iconsOn : appTheme.text)};
`;

export const UpdateZoneButton = styled.button`
  cursor: pointer;
  margin: 0 10px 15px auto;
  align-self: flex-end;
  justify-content: center;
  background-color: unset;
  border: unset;
`;

export const UpdateZoneText = styled.p`
  color: ${appTheme.title};
  text-decoration-line: underline;
  font-size: 14px;
  font-weight: bold;
`;

export const NewZoneButton = styled.button`
  cursor: pointer;
  background-color: ${appTheme.title};
  border-radius: 10px;
  align-items: center;
  align-self: center;
  padding: 10px;
  max-width: 60%;
  border: unset;

  @media (max-width: 667px) {
    font-size: 9px;
  }
`;

export const NewZoneButtonText = styled.p`
  color: ${appTheme.background};
  text-align: center; 
  text-transform: uppercase;
  font-weight: 500;
`;

export const PersonalContainer = styled.div` 
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  margin-bottom: 8%;
`;

export const PersonalTextInfo = styled.p`
  color: ${appTheme.title};
  text-align: center;
`;

export const ListFooter = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
`;

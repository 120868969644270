import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { IoAccessibilitySharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { formatActivityTypeIcon, formatDistance } from '../../../utils';
import TrainingStatus from '../../TrainingStatus';
import { RootState } from '../../../store';
import {
  ActivityInfoContainer, ActivityInfoText, AssociationContainer, DescContainer,
  DescriptionContainer, DescriptionText, DescText, LineContainer, NameContainer,
  OutContainer, RunDescContainer, RunDescText, RunDescWidthContainer, TrainingAssociatedContainer,
  TrainingDescriptionContainer, TrainingInfo, TrainingMetric, TrainingName, UnlinkButton,
  UnlinkContainer, UnlinkText, ZoneText,
} from './styles';
import { ExerciseI } from '../../../types/Exercise';

export interface TrainingDetailContainerProps {
  training: ExerciseI;
  disassociate?: Function;
  scroll?: Function;
}

function TrainingDetailContainer({
  training, disassociate, scroll,
}: TrainingDetailContainerProps) {
  const auth = useSelector((state : RootState) => state.auth);
  const { user } = auth;

  const [trainingDesc, setTrainingDesc] = useState<boolean>(false);

  const toggleDesc = () => {
    setTrainingDesc(!trainingDesc);
    if (scroll) scroll();
  };

  const formatZone = (zone: string) => {
    if (zone) {
      switch (zone) {
        case 'ZONA-1':
          return 'Zona 1 (Leve)';
        case 'ZONA-2':
          return 'Zona 2 (Moderado)';
        case 'ZONA-3':
          return 'Zona 3 (Pouco Difícil)';
        case 'ZONA-4':
          return 'Zona 4 (Difícil)';
        case 'ZONA-5a':
          return 'Zona 5a (Muito Difícil)';
        case 'ZONA-5b':
          return 'Zona 5b (Extremamente Difícil)';
        case 'ZONA-5c':
          return 'Zona 5c (Limite Máximo)';

        default:
          return 'Zona';
      }
    }
    return null;
  };

  const zones = {
    'ZONA-1': 1,
    'ZONA-2': 2,
    'ZONA-3': 3,
    'ZONA-4': 4,
    'ZONA-5a': 5,
    'ZONA-5b': 6,
    'ZONA-5c': 7,
  };

  const formatUserZone = (zone: string) => {
    if (user && user.userZone && zone) {
      const zoneId = (zones as any)[zone];

      const currZoneArray = user.userZone.zones.filter(
        (item: any) => item.order === zoneId,
      );

      if (currZoneArray && currZoneArray[0]) {
        const currZone = currZoneArray[0];

        const isBpm = currZone.start_pace || currZone.end_pace;
        if (currZone.order === 1) {
          return !isBpm
            ? `(Abaixo de ${currZone.end_bpm} bpm)`
            : `(Acima de ${currZone.end_pace} min/km)`;
        }

        if (currZone.order === 7) {
          return !isBpm
            ? `(Acima de ${currZone.start_bpm} bpm)`
            : `(Abaixo de ${currZone.start_pace} min/km)`;
        }

        return !isBpm
          ? `(${currZone.start_bpm} a ${currZone.end_bpm} bpm)`
          : `(${currZone.start_pace} a ${currZone.end_pace} min/km)`;
      }
    }

    return '';
  };

  const drawActivityInfos = (act: any, groupItem: any, actIdx: any) => {
    const zoneFormated = formatZone(act.zone);

    let description = '';

    if (groupItem.repetition && groupItem.repetition > 1) {
      if (act.activity_type === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`;
      }
      if (act.activity_type === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`;
      }
    } else {
      if (act.activity_type === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`;
      }
      if (act.activity_type === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`;
      }
    }

    return (
      <ActivityInfoContainer key={`${act.name}`}>
        {groupItem.repetition && groupItem.repetition > 1 ? (
          <ActivityInfoContainer>
            <DescriptionContainer>
              <DescriptionText>
                {actIdx + 1}
                .
              </DescriptionText>
              <DescriptionText>{act.name}</DescriptionText>
            </DescriptionContainer>
            <ActivityInfoContainer>
              <ActivityInfoText margin={40}>
                {description}
              </ActivityInfoText>
              <ZoneText margin={40}>
                {`${formatUserZone(act.zone)}`}
              </ZoneText>

            </ActivityInfoContainer>
          </ActivityInfoContainer>
        ) : (
          <ActivityInfoContainer>
            <DescriptionContainer>
              <DescriptionText>
                {actIdx + 1}
                .
                {' '}
              </DescriptionText>
              <ActivityInfoContainer>
                <ActivityInfoText>
                  {description}
                </ActivityInfoText>
                <ZoneText>
                  {`${formatUserZone(act.zone)}`}
                </ZoneText>
              </ActivityInfoContainer>
            </DescriptionContainer>
          </ActivityInfoContainer>
        )}
      </ActivityInfoContainer>
    );
  };

  return (
    <OutContainer>
      <AssociationContainer>
        <TrainingAssociatedContainer>
          <img src={formatActivityTypeIcon(training.type)} alt="Tipo treino" />
          <TrainingInfo>
            <NameContainer onClick={() => toggleDesc()}>
              <TrainingName>{training.name}</TrainingName>
              { trainingDesc
                ? <FaChevronUp size={13} color="white" style={{ marginLeft: 5 }} />
                : <FaChevronDown size={13} color="white" style={{ marginLeft: 5 }} />}
            </NameContainer>
            {training.distance
                  && (<TrainingMetric>{formatDistance(training.distance)}</TrainingMetric>)}
          </TrainingInfo>
        </TrainingAssociatedContainer>
        <UnlinkContainer>
          {training.executed_status
                && <TrainingStatus status={training.executed_status} small />}
          {disassociate && (
            <UnlinkButton onClick={() => disassociate()}>
              <UnlinkText>
                (x) Desvincular atividade
              </UnlinkText>
            </UnlinkButton>
          )}
        </UnlinkContainer>
      </AssociationContainer>
      {trainingDesc
        && (
          <TrainingDescriptionContainer>
            {training.type !== 'Run'
          && (
            <DescContainer>
              <IoAccessibilitySharp size={20} color="white" />
              <DescText>
                {training && !!training.equipments ? training.equipments : 'Apenas peso do corpo'}
              </DescText>
            </DescContainer>
          )}
            { training && training.type === 'Run' && training.trainingGroups.map((item: any, tgIDX: any) => (
              <RunDescContainer key={`${item.name}`}>
                <RunDescWidthContainer>
                  <LineContainer>
                    <RunDescText>
                      {tgIDX + 1}
                      .
                      {' '}
                    </RunDescText>
                    <RunDescText>
                      {item.repetition > 1 ? `${item.name} | Repetir ${item.repetition} vezes` : `${item.name}`}
                    </RunDescText>
                  </LineContainer>
                  {item.trainingActivities?.map(
                    (act: any, aIdx: any) => drawActivityInfos(act, item, aIdx),
                  )}
                </RunDescWidthContainer>
              </RunDescContainer>
            ))}
          </TrainingDescriptionContainer>
        )}
    </OutContainer>
  );
}
export default TrainingDetailContainer;

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import BackArrow from '../../components/BackArrow';
import {
  Container, CouponContainer, Text, TextContainer, TextInfo,
} from './sytles';
import BenefitCard from '../../components/BenefitCard';
import { api2 } from '../../services/api';
import { hasAccess } from '../../services/utils';
import { RootState } from '../../store';

export type Benefit = {
  title: string
  description: string
  coupon?: string
  action_url?: string
  image: string
}

export default function Benefits() {
  const { user } = useSelector((state: RootState) => state.auth);

  const [benefits, setBenefits] = useState<Array<Benefit>>([]);
  const [loading, setLoading] = useState(false);

  const fetchBenefits = async () => {
    setLoading(true);
    api2.get('benefit')
      .then((response) => {
        if (hasAccess(user, ['ESSENTIAL', 'PERSONAL', 'BASIC', 'START'])) {
          setBenefits(response.data);
        }
      })
      .catch(() => {
        toast.error('Erro ao carregar os benefícios');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBenefits();
  }, []);

  return (
    <Container>
      <BackArrow text="BENEFÍCIOS" bigger />
      <TextContainer>
        <TextInfo>
          Benefícios e descontos exclusivos, reservados apenas para membros do Corrida Perfeita.
        </TextInfo>
      </TextContainer>
      {!loading && benefits.length > 0 && benefits.map((benefit) => <BenefitCard benefit={benefit} />)}
      {loading && <CouponContainer />}
      {!loading && benefits.length === 0 && <CouponContainer><Text>Não há benefícios neste momento</Text></CouponContainer>}
    </Container>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

export const ContainerTabs = styled.div`
  width: 100%;
  .tabs-component [role="tablist"] {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
    position: relative;
    padding: 0 16px;
    margin-bottom: 25px;

    @media (max-width: 667px) {
      margin-bottom: 8px;
      padding: 0 8px;
    }
    
    button{
      display: flex;
      align-items: center;
      background: none;
      color:${theme.colors.title};
      border-color: transparent;
      cursor: pointer;
      margin: 0 1px;
      outline: none;        
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      transition: 0.7s;
      padding-bottom: 5px;
      opacity: .5;

      @media (max-width: 667px) {
        font-size: 10px;
      }

      &.active{
        border-bottom: solid 1px ${theme.colors.title};
        opacity: 1;
      }

      img {
        margin-right: 8px;
        height: 25px;
        width: 25px;

        @media (max-width: 667px) {
          margin-right: 3px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
    background: ${theme.colors.cardB1};
    padding: 10px;
    min-height: 130px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 4%;    
    align-self: flex-end;
    margin-right: 2%;
    span{
        color: ${theme.colors.title};
        font-size: 16px;
        cursor: pointer;
    }
`;

export const InfoText = styled.h2`
    display: flex;
    align-items: center;
    color: ${theme.colors.title};
    font-size: 10px;
    margin-right: 2%;
    font-weight: 500;
    @media (min-width: 768px) {
        font-size: 14px;
    }
    span{
        margin-left: 5px;
    }
`;

export const List = styled.div`
    background: ${theme.colors.cardB2};
    border-radius: 10px;
    padding: 12px;   
    min-width : 130px ;
`;

export const CardExercise = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 13px;
  position: relative;
  cursor: pointer;
  &:last-of-type{
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    min-height: 184px;
    margin-bottom: 19px;
  }
`;

export const BorderCard = styled.div`
    position: absolute;
    width: 10px;    
    height: 100%;
    left: 0;
    top: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  z-index: 6; 
  padding: 10px;
`;

export const TitleContainer = styled.div`
  width: 100%; 
  h2{
    color: ${theme.colors.title};
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 7px;
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: 17px;
        margin-bottom: 11px;
    }
    span{
        display: block;
        color: ${theme.colors.notifications};
    }
  }  
`;

export const IconContainer = styled.div`
  padding-top: 2px;
  margin-right: 5px;
  color: ${theme.colors.title};
  font-size: 14px;
`;

export const OptionsContainer = styled.div`
  width: 100%;   
  p{
    color: ${theme.colors.text};
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    @media (min-width: 768px) {
        font-size: 14px;
    }
    &:first-of-type{
        text-transform: capitalize;
        img{
            margin-right: 8px;
        }
    }
    svg, img{
        margin-right: 5px;        
    }
  } 
`;
export const Select = styled.select`
  margin-bottom: 10px;
  background-color: #2f2f58;
  border-radius: 5px;
  height: 30px;
  border: none;
  color: ${theme.colors.gray};
  cursor: pointer;
`;

export const SelectText = styled.div`
    color: ${theme.colors.title};
    font-size: 13px;
    margin-right: 2%;
    font-weight: 500;
`;

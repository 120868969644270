import styled from 'styled-components';
import THEME from '../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 70%;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;

export const ShoesList = styled.div`
    width: 95%; 
    background-color: ${THEME.colors.cardB3};
    margin-top: 5px;
    border-radius: 5px; 
    display: flex; 
    flex-direction: column;
`;

export const ShoesListItem = styled.button`
    color: ${THEME.colors.title};
    cursor: pointer; 
    border: none; 
    background-color: transparent; 
    text-align: justify;
    padding: 5px;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        background-color: ${THEME.colors.primary};
    }
`;

export const Input = styled.input`
    background-color: ${THEME.colors.cardB3};
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 95%;
    margin-top: 10px;
    color: ${THEME.colors.title};
`;

export const EmptySearch = styled.div`
    color: ${THEME.colors.title};
    text-align: justify;
    width: 95%;
    margin-top: 5px;
    font-size: 14px;
`;

import styled from 'styled-components';

export const Title = styled.h3`
    margin-bottom: 20px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
`;

import theme from '../../theme';

export const chartTheme = {
  axis: {
    style: {
      grid: {
        stroke: 'none',
      },
      axis: {
        stroke: theme.colors.primary,
      },
      ticks: {
        stroke: 'white',
        fill: 'white',
      },
      tickLabels: {
        fill: 'white',
        padding: 5,
      },
    },
  },
};

export const monthChartTheme = {
  axis: {
    style: {
      grid: {
        stroke: 'none',
      },
      axis: {
        stroke: 'none',
      },
      ticks: {
        stroke: 'none',
        fill: 'none',
      },
      tickLabels: {
        fill: 'white',
        padding: 5,
      },
    },
  },
};

export const yearChartTheme = {
  axis: {
    style: {
      grid: {
        stroke: 'none',
      },
      axis: {
        stroke: 'none',
      },
      ticks: {
        stroke: 'none',
        fill: 'none',
      },
      tickLabels: {
        fill: 'white',
        padding: 5,
      },
    },
  },
};

import React, { useCallback, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { appTheme } from '../../theme';
import {
  Chevron, FlexContainer,
  Container, Content, ContentWrapper, Title,
} from './styles';

interface AccordionProps {
  title: string;
  children?: React.ReactNode;
  style?: any;
  showCompletitionIcon?: boolean;
  handleExpandCallback?: Function;
}

function Accordion({
  title, children, style, showCompletitionIcon, handleExpandCallback,
}: AccordionProps): JSX.Element {
  const [isExpanded, setExpand] = useState<boolean>();
  const contentRef: React.MutableRefObject<any> = useRef();

  const contentHeight = isExpanded ? contentRef.current.scrollHeight : 0;

  const handleExpandToggle = useCallback(() => {
    if (!children) return;
    if (handleExpandCallback) {
      handleExpandCallback();
      return;
    }
    setExpand(!isExpanded);
  }, [isExpanded]);

  return (
    <Container>
      <FlexContainer>
        {showCompletitionIcon && (
          <FaCheckCircle color={appTheme.iconsOff} size={15} />
        )}
        <Title pointer={!!children} className="titleAccordion" onClick={handleExpandToggle} style={style}>
          {title}
          {children && <Chevron direction={isExpanded ? 'top' : 'bottom'} />}
        </Title>
      </FlexContainer>
      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  );
}

export default Accordion;

import React from 'react';
import { AiFillLock } from 'react-icons/ai';
import {
  Button, ButtonText, Container, IconContainer, Text,
} from './style';
import theme from '../../theme';

interface FreeUserModalProps {
  link: string;
  text: string;
}

export default function FreeUserCard({ text, link }: FreeUserModalProps) {
  return (
    <Container>
      <IconContainer>
        <AiFillLock size={25} color={theme.colors.title} />
      </IconContainer>
      <Text>{text}</Text>
      <Button onClick={() => window.open(link)}>
        <ButtonText>Quero fazer parte</ButtonText>
      </Button>
    </Container>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

export const SwitchRoot = styled.span`
  display: inline-flex;
  flex-shrink: 0;

  width: 58px;
  height: 38px;
  padding: 12px;

  position: relative;
  z-index: 0;

  overflow: hidden;
  box-sizing: border-box;
  vertical-align: middle;
`;

export const SwitchBaseRoot = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  box-sizing: border-box;
  outline: 0;

  border: 0;
  border-radius: 50%;
  margin: 0;
  padding: 9px;

  cursor: pointer;
  user-select: none;
  text-decoration: none;

  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  background-color: transparent;
  color: ${theme.colors.primary};

  &.active {
    transform: translateX(20px);
    color: ${theme.colors.title};
  }
`;

export const SwitchTrack = styled.span`
  height: 100%;
  width: 100%;
  border-radius: 7px;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  opacity: 0.38;
  background-color: ${theme.colors.gray};

  &.active {
    opacity: 0.5;
    background-color: ${theme.colors.title};
  }
`;

export const SwitchBaseInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: inherit;

  left: -100%;
  width: 300%;
`;

export const SwitchThumb = styled.span`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: currentColor;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &.active {
    color: ${theme.colors.title};
  }
`;

export const SwitchRipple = styled.span`
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;

  &.active {
    color: ${theme.colors.title};
  }
`;

export const SwitchLabel = styled.span`
  color: ${theme.colors.title};
  font-size: 10pt;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import styled from 'styled-components';
import Theme, { appTheme } from '../../theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
`;

export const Button = styled.button`
  border-radius: 10px;
  width: 50%;
  background-color: ${appTheme.secondary};
  padding: 10px;
  cursor: pointer;
  border: none;
`;

export const TextButton = styled.p`
  color: ${Theme.colors.cardB4};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
`;
export const IconButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NoDataText = styled.p`
  color: ${appTheme.text};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
`;

export const RetroImageContainer = styled.img`
  width: 100%;
`;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import badgeIconSrc from '../../../assets/club/badgeIcon.svg';
import trophyIconSrc from '../../../assets/club/trophyIcon.svg';
import logoSrc from '../../../assets/logo.png';
import {
  ResultContainer,
  ResultData,
  ResultDistance,
  ResultTitle,
  ResultValue,
  BadgeContainer,
  BadgeTextContent,
  BadgeDistanceText,
  BadgeTimeText,
  BadgeContent,
  BadgeInnerContent,
  ResultValueShare,
  HeaderTextShare,
  Logo,
  PredictionDataContainer,
  ShareBtnText,
  ResultRow,
  WhiteActionButton,
  BadgeIcon,
  TrophyIcon,
} from '../styles';
import { IRunData, IUserPredictedData, clearData } from '../../../store/Prediction.store';
import { RootState } from '../../../store';
import PredictionDisclaimerModal from '../../../components/PredictionDisclaimerModal';
import { SecondsToMaskedTime } from '../../../utils/TimeHelpers';

export default function PredictionDataScreen() {
  const dispatch = useDispatch();

  const { userPredictedData, userRunData } = useSelector((state: RootState) => state.prediction);
  const [showDisclaimerModal, setShowDisclaimerModal] = React.useState(false);

  return (
    <>
      <PredictionDisclaimerModal
        isVisible={showDisclaimerModal}
        dismissCallback={() => setShowDisclaimerModal(false)}
      />
      <PredictionDataContainer>
        <Logo src={logoSrc} alt="CP Logo" />

        <HeaderTextShare>Confira meus melhores tempos nas distâncias:</HeaderTextShare>

        <BadgeContainer>
          <BadgeContent>
            <BadgeIcon src={badgeIconSrc} />
            <BadgeInnerContent>
              <TrophyIcon src={trophyIconSrc} />
              <BadgeTextContent>
                <BadgeDistanceText>1KM</BadgeDistanceText>
                <BadgeTimeText>{SecondsToMaskedTime(userRunData['1KmTime'] as number)}</BadgeTimeText>
              </BadgeTextContent>
            </BadgeInnerContent>
          </BadgeContent>

          <BadgeContent>
            <BadgeIcon src={badgeIconSrc} />
            <BadgeInnerContent>
              <TrophyIcon src={trophyIconSrc} />
              <BadgeTextContent>
                <BadgeDistanceText>5KM</BadgeDistanceText>
                <BadgeTimeText>{SecondsToMaskedTime(userRunData['5KmTime'] as number)}</BadgeTimeText>
              </BadgeTextContent>
            </BadgeInnerContent>
          </BadgeContent>

        </BadgeContainer>

        <HeaderTextShare>com base nessas marcas, estas são as minhas previsões para:</HeaderTextShare>

        {Object.keys(userPredictedData).map((predictionKey: string) => {
          const { pace, speed, time } = userPredictedData[predictionKey as keyof IUserPredictedData] as IRunData;
          const formattedResultTime = time ? SecondsToMaskedTime(time as number) : 'N/A';
          const formattedResultPace = pace ? `${Math.floor(pace / 60)}:${Math.floor(pace % 60).toString().padStart(2, '0')} /Km` : 'N/A';

          return (
            <>
              <ResultContainer>
                <ResultDistance>
                  <ResultValueShare>
                    {predictionKey}
                    KM
                  </ResultValueShare>
                </ResultDistance>

                <ResultRow>
                  <ResultData>
                    <ResultTitle>
                      Tempo
                    </ResultTitle>
                    <ResultValue>
                      {formattedResultTime}
                    </ResultValue>
                  </ResultData>

                  <ResultData>
                    <ResultTitle>
                      Pace
                    </ResultTitle>
                    <ResultValue>
                      {formattedResultPace}
                    </ResultValue>
                  </ResultData>

                  <ResultData>
                    <ResultTitle>
                      Velocidade
                    </ResultTitle>
                    <ResultValue>
                      {speed}
                      km/h
                    </ResultValue>
                  </ResultData>
                </ResultRow>
              </ResultContainer>
            </>
          );
        })}
      </PredictionDataContainer>

      <WhiteActionButton
        onClick={() => dispatch(clearData() as any)}
      >
        <ShareBtnText>Calcular novamente</ShareBtnText>
      </WhiteActionButton>
    </>
  );
}

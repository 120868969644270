import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.cardB3};
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;

  @media (max-width: 667px) {
    padding: 4px;
    margin-bottom: 5px;
  }

  img{
    width: 20px;
    height: 20px;
    margin-right: 5px;

    @media (max-width: 667px) {
      width: 15px;
      height: 15px;
    }
  }
`;

export const TrainingName = styled.p`
  color: ${theme.colors.text};
  font-size: 14px;
  font-weight: bold;
  
  @media (max-width: 667px) {
    font-size: 8px;
  }
`;

export const TrainingInfo = styled.p`
  color: ${theme.colors.text};
  font-size: 13px;

  @media (max-width: 667px) {
    font-size: 8px;
    
  }
`;

export const TrainingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 3px 3px;
`;

export const StatusTrainingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isBefore } from 'date-fns';
import { FaFileInvoice } from 'react-icons/fa';
import { RootState } from '../../store/index';
import Pagination from '../Pagination';
import Loader from '../Loader';
import { requestSubscription } from '../../store/Subscription.store';
import { formatSubscriptionStatus, formatBillStatus } from '../../services/utils';
import {
  SubscriptionContainer,
  Header,
  SubscriptionContent,
  TableHeaderMain,
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  Loading,
  Error,
} from './style';
import theme, { appTheme } from '../../theme';
import ReactivateModal from '../ReactivateModal';
import { GenericButton } from '../GenericButton/styles';

export default function Subscription() {
  const [nextBillingValue, setNextBillingValue] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [billsPerPage] = useState(3);
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.subscription.loading);
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription,
  );
  const error = useSelector((state: RootState) => state.subscription.error);

  useEffect(() => {
    dispatch(requestSubscription() as any);
  }, [user]);

  useEffect(() => {
    if (subscription != null && subscription?.product_items && subscription?.product_items?.length > 0) {
      const totalPrice = subscription.product_items.reduce(
        (acc: any, productItem: any) => acc + parseFloat(productItem.pricing_schema?.price),
        0,
      );

      let totalDiscount = 0;

      const diffOfTodayFromStartAt = subscription.bills?.reduce(
        (acc: any, bill: any) => {
          if (bill.charges?.length > 0) {
            if (bill.charges[0].last_transaction?.status === 'success') {
              // eslint-disable-next-line
              return (acc += 1);
            }
          }
          return acc;
        },
        0,
      );

      for (let index = 0; index < subscription.product_items.length; index++) {
        const product_item = subscription.product_items[index];

        if (product_item.discounts?.length > 0) {
          for (
            let index2 = 0;
            index2 < product_item.discounts.length;
            index2++
          ) {
            const discount = product_item.discounts[index2];

            if (
              discount.cycles > 1
              && diffOfTodayFromStartAt < discount.cycles
            ) {
              if (discount.discount_type === 'amount') {
                totalDiscount += parseFloat(discount.amount);
              } else if (discount.discount_type === 'percentage') {
                const totalProductItemPercentageDiscount = (parseFloat(product_item.pricing_schema?.price) / 100)
                  * parseFloat(discount.percentage);

                totalDiscount += totalProductItemPercentageDiscount;
              }
            }
          }
        }
      }

      setNextBillingValue(totalPrice - totalDiscount);
    }
  }, [subscription]);

  const indexOfLastBills = currentPage * billsPerPage;
  const indexOfFirstBills = indexOfLastBills - billsPerPage;
  const currentBills = subscription?.bills?.slice(
    indexOfFirstBills,
    indexOfLastBills,
  );

  const [showReactivateFormModal, setShowReactivateFormModal] = useState(false);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const toggle = () => {
    setShowReactivateFormModal(!showReactivateFormModal);
  };

  return (
    <div data-testid="subscription-details">
      <div>
        <ReactivateModal
          show={showReactivateFormModal}
          subscription={subscription}
          nextBillingValue={nextBillingValue}
          toggle={() => setShowReactivateFormModal(!showReactivateFormModal)}
        />
      </div>

      <div>
        {loading ? (
          <Loading>
            <Loader color={theme.colors.title} />
          </Loading>
        ) : error != null ? (
          <Error>Erro. Por favor, tente novamente.</Error>
        ) : (subscription && subscription.plan) ? (
          <SubscriptionContainer>
            <Header>{subscription.plan?.name}</Header>
            <SubscriptionContent>
              <div>
                <h5>Status:</h5>
                <p
                  className={
                    subscription.status === 'active'
                      ? 'text-success'
                      : 'text-danger'
                  }
                >
                  {formatSubscriptionStatus(subscription.status)}
                </p>
              </div>
              <div>
                <h5>Início da assinatura:</h5>
                <p>
                  {format(new Date(subscription.start_at), 'dd-MM-yyyy')}
                </p>
                {subscription.status !== 'active' && (
                  <>
                    <div>
                      <h5>Data de encerramento:</h5>
                      <p>
                        {subscription?.current_period ? format(new Date(subscription.current_period?.end_at), 'dd-MM-yyyy') : '--'}
                      </p>
                    </div>
                  </>
                )}
              </div>
              {subscription.status === 'active' && (
                <>
                  <div>
                    <h5>Previsão de renovação:</h5>
                    <p>
                      {subscription?.next_billing_at ? format(new Date(subscription.next_billing_at), 'dd-MM-yyyy') : '--'}
                    </p>
                  </div>
                  <div>
                    <h5>Meio de pagamento previsto:</h5>
                    <p>{subscription.payment_method?.public_name}</p>
                  </div>
                  <div>
                    <h5>Valor total previsto na renovação:</h5>
                    <p>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(nextBillingValue)}
                      &nbsp;
                      {subscription.interval_count > 1
                        ? `em ${subscription.installments}x`
                        : ''}
                    </p>
                  </div>
                </>
              )}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {
                  subscription?.status === 'canceled'
                  && subscription?.current_period
                  && isBefore(new Date(), new Date(subscription.current_period?.end_at))
                  && subscription?.bills?.length > 0
                  && subscription.bills[0].status === 'paid'
                  && (
                    <GenericButton
                      style={{ marginTop: '15px', padding: '10px', fontSize: '14px' }}
                      backgroundColor={theme.colors.primary}
                      borderColor={appTheme.successBtn}
                      textColor={appTheme.successBtn}
                      type="button"
                      onClick={toggle}
                    >
                      Reativar
                    </GenericButton>
                  )
                }
              </div>

            </SubscriptionContent>
          </SubscriptionContainer>
        ) : (
          <div />
        )}
      </div>

      <TableHeaderMain>Faturas mais recentes</TableHeaderMain>
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              <td>#</td>
              <td>Data</td>
              <td>Plano</td>
              <td>Valor </td>
              <td>Forma</td>
              <td>Status</td>
              <td>Opções</td>
            </tr>
          </TableHeader>
          <TableBody>
            {currentBills?.map((bill) => (
              <tr key={bill.id}>
                <td>{bill.id}</td>
                <td>
                  {bill.created_at
                    ? format(new Date(bill.created_at), 'dd-MM-yyyy')
                    : '--'}
                </td>
                <td>{subscription?.plan?.name}</td>
                <td>
                  {bill.charges[0] && new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(bill.charges[0]?.amount)}
                </td>
                <td>
                  {bill.charges[0]?.payment_method?.public_name}
                  {bill.charges[0]?.payment_method?.code === 'bank_slip'
                    && bill.charges[0]?.print_url
                    && (
                      <a
                        href={bill.charges[0].print_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: 5 }}
                      >
                        <FaFileInvoice color="white" size={16} />
                      </a>
                    )}
                </td>
                <td>
                  {
                    formatBillStatus[bill.status]
                      ? <span className={formatBillStatus[bill.status].className}>{formatBillStatus[bill.status].title}</span>
                      : <span className="text-secondary">--</span>
                  }
                </td>
                <td>
                  {
                    bill.status !== 'canceled'
                    && bill.url && (
                      <a
                        href={bill.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                      >
                        Abrir Fatura
                      </a>
                    )
                  }
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
        <Pagination
          billsPerPage={billsPerPage}
          totalBills={subscription?.bills?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </TableContainer>
    </div>
  );
}

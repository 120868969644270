import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth } from 'date-fns';
import Day from './Day';
import { Container, ContentCalendar } from './styles';
import { RootState } from '../../../store';
import { getTrainingsByDateAndUser } from '../../../store/CalendarProgress.store';

import Cards from './Cards';

type DateData = {
  year: number;
  month: number;
  day: number;
  timestamp: number;
  dateString: string;
};

export default function MonthCalendar() {
  const userId = useSelector((state: RootState) => state.auth.user.id);
  const dispatch = useDispatch();

  function monthChangeHandler(dateData?: DateData) {
    const dateString = dateData ? dateData.dateString : new Date().toISOString();
    const lastMonthDay = endOfMonth(new Date(dateString)).toISOString();
    const currentDate = new Date(dateString);
    const firstMonthDayDate = new Date(currentDate.setDate(1));
    const firstMonthDay = firstMonthDayDate.toISOString();

    dispatch(
      getTrainingsByDateAndUser({ userId, start_date: firstMonthDay, end_date: lastMonthDay }) as any,
    );
  }

  useEffect(() => {
    monthChangeHandler({
      dateString: new Date().toISOString(),
      timestamp: new Date().getTime(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
    });
  }, []);

  return (
    <Container data-testid="progress-calendar">
      <Cards />
      <ContentCalendar>
        <Calendar
          prevLabel={<MdOutlineNavigateBefore color="#FFFFFF" size={38} />}
          nextLabel={<MdOutlineNavigateNext color="#FFFFFF" size={38} />}
          next2Label={null}
          prev2Label={null}
          showNeighboringMonth={false}
          // eslint-disable-next-line
          tileContent={({ date }) => <Day date={date} />}
          formatDay={() => ''}
          onActiveStartDateChange={
            ({
              activeStartDate,
            }) => monthChangeHandler(
              {
                dateString: new Date(activeStartDate).toISOString(),
                timestamp: new Date(activeStartDate).getTime(),
                year: new Date(activeStartDate).getFullYear(),
                month: new Date(activeStartDate).getMonth(),
                day: new Date(activeStartDate).getDate(),
              },
            )
          }
        />
      </ContentCalendar>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { ItemTitle, ModalContainer, ModalButtonsContainer } from './styles';
import { IEditShoesModalProps } from '../contracts';
import BaseModal from '../../BaseModal';
import { GenericButton } from '../../GenericButton/styles';
import theme from '../../../theme';
import Loader from '../../Loader';
import { IShoesBrand } from '../../../store/Activities.store';
import { Input, InputGroup, InputLabel } from '../../RegisterShoes/styles';
import SearchBrandInput from '../../SearchBrandInput';

export default function EditShoesModal({
  visible, setVisible, shoes, update, loading, backToDetails,
}: IEditShoesModalProps) {
  const [brand, setBrand] = useState<IShoesBrand | null>(null);
  const [name, setName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [distance, setDistance] = useState('');
  const [color, setColor] = useState('');
  const [active, setActive] = useState(false);

  useEffect(() => {
    setBrand(shoes?.shoesBrand || null);
    setBrandName(shoes?.shoesBrand?.name || '');
    setName(shoes?.name || '');
    setColor(shoes?.color || '');
    setDistance(shoes?.distance_total ? `${shoes?.distance_total}` : '');
  }, [shoes]);

  const handleUpdate = () => {
    if (!active) return;

    const distanceTotal = distance ? parseFloat(distance.replace(',', '.')) : 0;
    const newColor = color || null;

    update({
      name,
      shoes_brand_id: brand?.id!,
      color: newColor!,
      distance_total: distanceTotal!,
    });
  };

  useEffect(() => {
    setActive(!!name && !!brand);
  }, [name, brand]);

  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          EDITAR TÊNIS
        </ItemTitle>

        <InputGroup>
          <InputLabel>Marca</InputLabel>

          <SearchBrandInput
            setBrand={setBrand}
            setBrandName={setBrandName}
            brandName={brandName}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Modelo</InputLabel>

          <Input
            type="text"
            placeholder="Digite o modelo"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Cor</InputLabel>

          <Input
            type="text"
            placeholder="Digite a cor (Opcional)"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>KMs</InputLabel>

          <Input
            type="number"
            placeholder="0 km (Opcional)"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
        </InputGroup>

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={active ? theme.colors.title : theme.colors.cardB2}
            textColor={theme.colors.primary}
            borderColor={active ? theme.colors.title : theme.colors.cardB2}
            style={{
              width: '100%',
            }}
            onClick={handleUpdate}
          >
            {loading ? <Loader color={theme.colors.primary} /> : 'SALVAR'}
          </GenericButton>
          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            onClick={backToDetails}
          >
            VOLTAR
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

import React from 'react';
import BackArrow from '../BackArrow';
import {
  Button, ButtonText, Content, Container, TextContainer, TextInfo,
} from './style';

export default function Cashback() {
  return (
    <Container data-testid="cashback-container">
      <BackArrow text="CASHBACK E DESCONTOS EXCLUSIVOS" bigger backTo="/club" />
      <Content>
        <TextContainer>
          <TextInfo>
            Em parceria com a plataforma Lecupon, você tem acesso a uma app exclusiva que oferece grandes descontos e cashback em milhares de estabelecimentos online e físicos pelo Brasil.

            <br />
            <br />

            O cashback (dinheiro de volta de compras) poderá ser usado para abatimento do pagamento de sua adesão ao Corrida Perfeita.
            <br />
            <br />

            Temos benefícios em lojas esportivas, grande varejistas, farmácias, postos de gasolina, restaurantes e muito mais...
            <br />
          </TextInfo>
        </TextContainer>
        <Button onClick={() => window.open('https://corridaperfeita.typeform.com/to/rVoJ0ach')}>
          <ButtonText>solicitar acesso</ButtonText>
        </Button>
      </Content>
    </Container>
  );
}

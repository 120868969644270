import React from 'react';
import {
  InfoText, Header,
  ModalContentButtonTextCancel,
  ModalContent,
  ModalContentButtonContainer,
  ModalContentButton,
} from './style';
import {
  ModalDialog,
  ModalBackDrop,
  ModalContainer,
} from '../Modal/Modal.styles';

export default function LiveClassInfoModal({
  isOpen, toggle, cancelText = 'ok',
}: any) {
  return (
    isOpen && (
      <>
        <ModalContainer data-testid="modal-live-class-container">
          <ModalDialog>
            <ModalContent>
              <Header>Como funciona</Header>
              <InfoText>
                Os nossos treinos funcionais, coletivos e ao vivo, são uma oportunidade
                de você complementar seus treinos conosco de uma maneira diferente,
                com mais motivação e participando de um momento único com os membros
                e com o professor.
              </InfoText>
              <InfoText>
                Você pode fazer o treino ao vivo, ou depois, assistindo à gravação, que fica
                disponível aos membros do Corrida Perfeita por pelo menos 30 dias depois
                da realização.
              </InfoText>
              <Header>Como acessar o treino ao vivo</Header>
              <InfoText>
                Na sala virtual do sistema Zoom, onde você pode ter o bônus da execução de seu
                treino avaliada ao vivo pelo professor, desde que ligue sua câmera. O sistema Zoom
                pode ser acessado tanto por celular quanto por computador.
              </InfoText>
              <InfoText>
                O acesso a sala com professor irá pedir a instalação do aplicativo do Zoom.
                Não há senha para entrar na sala. Se essa senha for pedida, feche o Zoom e
                clique novamente no link.
              </InfoText>
              <ModalContentButtonContainer>
                <ModalContentButton onClick={toggle}>
                  <ModalContentButtonTextCancel>{cancelText}</ModalContentButtonTextCancel>
                </ModalContentButton>

              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

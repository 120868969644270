import styled from 'styled-components';
import theme, { appTheme } from '../../../theme';
import { IWrapperUnderline, IIconRadiusWrapper } from '../contracts';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.span`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-style: normal;
  font-size: 15px;
  @media (max-width: 667px) {
    font-size: 12px;
  }
`;
export const DateDay = styled.span`
  color: ${theme.colors.title};
  text-decoration: underline;
  font-size: 9px;
`;

export const CalendarContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const ContentCalendar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.cardB2};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);

  .react-calendar__tile {
    min-height: 42px;
    background-color: ${theme.colors.cardB2};
    border: 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    color: ${theme.colors.title};
    padding-top: 10px;
    abbr[title] {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: center;
  }
  .react-calendar__navigation__label {
    min-height: 40px;
    border: none;
    background-color: ${theme.colors.cardB2};
    font-size: 22px;
    color: #878b99;
    text-transform: capitalize;
    pointer-events: none;
    flex-grow: unset !important;
    @media (max-width: 667px) {
      font-size: 15px;
    }
  }

  .react-calendar__navigation__arrow {
    min-height: 40px;
    border: none;
    background-color: ${theme.colors.cardB2};
    min-width: 30px;
  }
`;

export const DayBox = styled.div`
  flex-direction: column;
  align-items: center;
`;

export const DayBoxTitle = styled.span`
  color: ${theme.colors.title};
`;

export const DayContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 15px;
  padding: 2px;
`;

export const DayContainerNumber = styled.span`
  color: ${appTheme.iconsOff};
  font-size: 12px;
`;

export const DayData = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${theme.colors.title};
  text-decoration: underline;
`;

export const CalendarOptions = styled.div`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  padding: 5px;
`;

export const CalendarOptionsButton = styled.button`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CalendarOptionsButtonText = styled.span`
  color: ${theme.colors.title};
  font-size: 10px;
`;

export const ButtonTextWrapperUnderline = styled.div<IWrapperUnderline>`
  border-bottom-width: 1px;
  border-bottom-color: #c3c3c3;
  margin-left: ${(props) => props.marginLeft}px;
`;

export const DayTrainingsContainer = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
`;

export const EmptyDayTrainingsContainer = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
`;

export const EmptyDayTrainingsContainerText = styled.div`
  font-size: 13px;
  color: ${theme.colors.title};
`;

export const DayTrainingItem = styled.div`
  width: 100%;
  min-height: 100px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #2f2f58;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
`;

export const EmptyDayTrainingItem = styled.div`
  width: 100%;
  min-height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2f2f58;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
`;

export const DayTrainingItemHeaderContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const DayTrainingItemHeader = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DayTrainingItemHeaderTitle = styled.div`
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
`;

export const DayTrainingItemHeaderTitlePrimaryContainer = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DayTrainingItemHeaderTitlePrimary = styled.span`
  color: ${theme.colors.title};
  font-size: 13px;
  margin-right: 5px;
  text-transform: uppercase;
`;

export const DayTrainingItemHeaderTitleSecondary = styled.span`
  color: ${appTheme.iconsOff};
  font-size: 10px;
`;

export const DayTrainingItemDetails = styled.div`
  flex-direction: column;
  margin-top: 10px;
`;

export const DayTrainingItemDetailsButton = styled.button`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
`;

export const DayTrainingItemDetailsButtonText = styled.span`
  color: ${theme.colors.title};
  font-size: 11px;
`;

export const DayTrainingItemDetailsSimulateIcon = styled.span`
  color: ${theme.colors.title};
  font-size: 10px;
`;

export const DayTrainingItemDetailsSteps = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

export const IconRadiusWrapper = styled.div<IIconRadiusWrapper>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
`;

export const TrainingSubDescription = styled.div`
  align-items: center;
`;

export const TrainingSubDescriptionText = styled.span`
  font-size: 11px;
  color: #c3c3c3;
`;

export const TrainingGroupContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const TrainingGroup = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const TrainingGroupContent = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const TrainingGroupCount = styled.span`
  color: #c3c3c3;
  font-size: 11px;
  margin-right: 5px;
`;

export const TrainingGroupText = styled.span`
  color: #c3c3c3;
  font-size: 11px;
`;

export const NoTrainingPlanContent = styled.div`
  width: 100%;
`;

export const PersonalContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  margin-bottom: 8%;
  width: 80%;
`;

export const PersonalTextInfo = styled.span`
  color: ${theme.colors.title};
  text-align: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiFillLock } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import theme from '../../theme';
import { Avatar, ItemContainer, ItemTitle } from './style';
import FreeUserModal from '../FreeUserModal';
import { hasAccess } from '../../services/utils';
import { User } from '../../types/User';

export interface IItemDetails {
  title: string;
  Icon: string;
  screen: string;
  blockFree?: boolean;
  link?: string;
  directLink?: string;
  coach?: User;
  showAvatar?: boolean;
}

export default function Item({
  title,
  Icon,
  screen,
  blockFree,
  link,
  directLink,
  coach,
  showAvatar,
}: IItemDetails) {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const [freeModal, setFreeModal] = useState(false);
  const toggleModal = () => {
    setFreeModal((isOpen) => !isOpen);
  };

  const avatar = coach?.s_photo ? coach?.s_photo : '';

  const navigate = useNavigate();

  const handleClick = () => {
    if (!freeModal) {
      if (hasAccess(user, ['FREE']) && blockFree) {
        setFreeModal(true);
        return;
      }
      if (directLink) {
        window.open(directLink);
        return;
      }
      if (screen) {
        navigate(screen);
      }
    }
  };

  return (
    <ItemContainer onClick={handleClick}>
      {hasAccess(user, ['FREE']) && (
        <FreeUserModal
          isModalOpen
          isVisible={freeModal}
          text="Este benefício está disponível apenas para membros do Corrida Perfeita."
          link={link || 'treineconosco.corridaperfeita.com/'}
          toggle={toggleModal}
        />
      )}
      {(showAvatar && coach?.id
        ? <Avatar src={avatar} alt="" />
        : <img src={Icon} alt="Ícone" />
      )}
      <ItemTitle>
        {(hasAccess(user, ['FREE']) && blockFree) && (
          <>
            <AiFillLock size={10} color={theme.colors.title} />
          </>
        )}
        {title}
      </ItemTitle>
    </ItemContainer>
  );
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  ItemTitle, ModalContainer, ModalButtonsContainer, ItemDescription,
} from './styles';
import BaseModal from '../BaseModal';
import { GenericButton } from '../GenericButton/styles';
import theme from '../../theme';
import Loader from '../Loader';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleDeactivate: () => void;
  loading: boolean;
}

export default function DeactivateControlKmsModal({
  visible, setVisible, handleDeactivate, loading,
}: IProps) {
  const [step, setStep] = useState(1);

  const handleCallback = () => {
    handleDeactivate();
    setStep(1);
  };

  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          {step === 1 && 'CONTROLE OS KMS DE SEUS TÊNIS'}
          {step === 2 && 'TEM CERTEZA QUE DESEJA DESATIVAR O CONTROLE DE KMS DOS SEUS TÊNIS?'}
        </ItemTitle>

        <ItemDescription>
          {step === 1 && 'Vincule seus tênis às atividades de corrida para contabilizar o uso em kms.'}
        </ItemDescription>

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.redAlert}
            borderColor={theme.colors.redAlert}
            style={{
              width: '50%',
              marginBottom: '10px',
            }}
            onClick={step === 2 ? handleCallback : () => setStep((prev) => prev + 1)}
          >
            {
              loading
                ? <Loader color={theme.colors.title} />
                : (
                  <>
                    {step === 1 && 'NÃO QUERO CONTABILIZAR'}
                    {step === 2 && 'DESATIVAR FUNÇÃO'}
                  </>
                )
            }
          </GenericButton>

          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '50%',
            }}
            onClick={step > 1 ? () => setStep((prev) => prev - 1) : () => setVisible(false)}
          >
            VOLTAR
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

import styled from 'styled-components/';
import theme, { appTheme } from '../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    text-align: center;
    padding: 7px 7px;
    border-radius: 10px;
    background-color: ${theme.colors.primary3};
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
`;

export const TextContainer = styled.span`
    font-size: 13px;
    line-height: 16px;
    color: ${theme.colors.text};
    text-align: center;
`;

export const TextNumberContainer = styled.span`
    font-size: 30px;
    font-weight: 900;
    color: ${theme.colors.title};
    line-height: 34px;
`;

export const Separator = styled.div`
    width: 100%;
    height: 1.5px;
    background-color: ${appTheme.background};
    margin: 5px 0;
`;

export const DimmedText = styled.p`
    font-size: 10px;
    color: ${appTheme.gray};
    text-align: center;
`;

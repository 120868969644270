import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  border-radius: 10px;
  width: 100%;
  height: 160px;
  cursor: pointer;
`;

export const ImageButton = styled.section<{image: string}>`
  display: flex;
  height: 160px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 667px) {
    height: 180px;
  }
`;

export const Title = styled.span`
  display: flex;
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 15px;
  color: ${theme.colors.title};
  font-weight: 500;

  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const InfoContainer = styled.div`
  width: 78%;
  padding-left: 5px;
  cursor: pointer;
`;

export const Distance = styled.span`
  display:flex;
  font-size: 13px;
  color: ${theme.colors.text};
  flex-direction: column;
  padding-bottom: 5px;
`;

export const Info = styled.span`
  display:flex;
  font-size: 13px;
  color: ${theme.colors.text};
  flex-direction: column;
  width: 90%;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const IconContainer = styled.span`
cursor: pointer;
`;

export const Medal = styled.img`
  height: 100%;

  @media (max-width: 667px) {
    height: 120px;
  }
`;

import * as React from 'react';

import {
  Select, InputContainer, Label, ErrorMessage,
} from '../FormInput/styles';

export interface ISelectInput extends React.SelectHTMLAttributes<HTMLSelectElement>{
  label?: string;
  inputValue: string;
  error?: boolean;
  errorMessage?: string;
}

export default function SelectInput({
  label, inputValue, error, errorMessage, children, ...rest
}: ISelectInput) {
  return (
    <InputContainer data-testid="outer-select-container">
      {label && <Label>{label}</Label>}
      <Select
        value={inputValue}
        {...rest}
      >
        {children}
      </Select>
      {error && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Button, ButtonTitle, Container, Image, Main, SubTitle, Description, Paragraph, BoldParagraph,
  HelpLink,
} from './styles';
import IntegrationConfirmModal from '../IntegrationConfirmModal';
import { otherIntegrations, partnerIntegrations } from '../Integration/integrations';

interface IProps {
  provider: string;
}

export default function IntegrationDetails({
  provider,
}: IProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<typeof partnerIntegrations[0]>();

  useEffect(() => {
    setSelectedIntegration(partnerIntegrations.find((integration) => integration.type === provider) || otherIntegrations.find((integration) => integration.type === provider));
  }, [provider]);

  return (
    <Main>
      <Container>
        {
          !!selectedIntegration
          && (
            <>
              <Image src={selectedIntegration?.image} />

              <Description>{selectedIntegration?.description}</Description>

              <HelpLink target="_blank" href={selectedIntegration?.helpLink!} rel="noreferrer">
                <SubTitle>Como fazer a integração?</SubTitle>
              </HelpLink>

              <Button onClick={() => setIsVisible(true)}>
                <ButtonTitle>INTEGRAR</ButtonTitle>
              </Button>

              <IntegrationConfirmModal
                isOpen={isVisible}
                confirm={() => {
                  setIsVisible(false);
                  selectedIntegration?.addIntegration?.(undefined, provider);
                }}
                cancel={() => {
                  setIsVisible(false);
                }}
              >
                <>
                  {
                    selectedIntegration?.paragraphs?.map((text, index) => (text?.includes('Atenção')
                      ? (
                        <Paragraph key={index}>
                          <BoldParagraph>Atenção:</BoldParagraph>
                          {text?.replace('Atenção:', '')}
                        </Paragraph>
                      )
                      : <Paragraph key={index}>{text!}</Paragraph>))
                  }
                </>
              </IntegrationConfirmModal>
            </>
          )
        }

        {!selectedIntegration && <ButtonTitle>Integração não encontrada</ButtonTitle>}
      </Container>
    </Main>
  );
}

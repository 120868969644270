import React from 'react';
import { Container, Label } from './styles';

interface ClassesLengthBadgeI {
  length: number | undefined
}

function ClassesLengthBadge({ length }: ClassesLengthBadgeI) {
  return (
    <Container data-testid="class-length-badge">
      <Label><span>{`${length || 0} Aulas`}</span></Label>
    </Container>
  );
}

export default ClassesLengthBadge;

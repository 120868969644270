import toast from 'react-hot-toast';
import coreApi from '../../services/coreApi';
import { STRAVA_URL } from '../../services/api';
import store from '../../store';
import {
  // removeCoros,
  removeGarmin,
  removePolar,
  removeStrava,
} from '../../store/Integration.store';
import { throughStravaProviders } from '../../utils';

const addStravaIntegration = (dismissIntegration?: () => void, provider: string = 'strava') => {
  try {
    const { host } = window.location;

    const STRAVA_URI = `${STRAVA_URL}?client_id=41754&response_type=code&approval_prompt=force&scope=activity:read_all&redirect_uri=${window.location.protocol}//${host}/profile/edit?provider=${provider}`;

    window.location.href = STRAVA_URI;
  } catch (err) {
    dismissIntegration?.();
    toast.error('Ocorreu um erro ao integrar com a Strava, tente novamente.');
  }
};

const addGarminIntegration = async (dismissIntegration?: () => void) => {
  try {
    const { hostname } = window.location;
    const response = await coreApi.get(`garmin/get_request_token_url?hostname=${hostname}`);

    if (response.data && response.data.url) {
      const responseURL = new URL(response.data.url);
      const redirectURL = new URL(responseURL.searchParams.get('oauth_callback') ?? '');

      redirectURL.pathname = redirectURL.pathname.replace('/perfil', '/profile/edit');
      redirectURL.searchParams.set('provider', 'garmin');

      redirectURL.host = window.location.host;
      responseURL.searchParams.set('oauth_callback', redirectURL.toString());

      window.location.href = responseURL.toString();
    } else {
      dismissIntegration?.();
      toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.');
    }
  } catch (err) {
    dismissIntegration?.();
    toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.');
  }
};

// const addCorosIntegration = async (dismissIntegration?: () => void) => {
//   try {
//     const { host } = window.location;

//     const { data } = await coreApi.get(`coros/oauth_request_code_url?redirect_uri=${window.location.protocol}//${host}/profile/edit?provider=coros`);
//     const { url } = data;

//     window.location.href = url;
//   } catch (err) {
//     dismissIntegration?.();
//     toast.error('Ocorreu um erro ao integrar com a Coros, tente novamente.');
//   }
// };

const addPolarIntegration = async (dismissIntegration?: () => void) => {
  try {
    const { protocol, host } = window.location;

    const { data } = await coreApi.get(`polar/oauth_request_code_url?redirect_uri=${protocol}//${host}/profile/edit?provider=polar`);
    const { url } = data;

    window.location.href = url;
  } catch (err) {
    dismissIntegration?.();
    toast.error('Ocorreu um erro ao integrar com a Polar, tente novamente.');
  }
};

const removeStravaIntegration = () => {
  store.dispatch(removeStrava() as any);
};

const removeGarminIntegration = () => {
  store.dispatch(removeGarmin() as any);
};

// const removeCorosIntegration = () => {
//   store.dispatch(removeCoros() as any);
// };

const removePolarIntegration = () => {
  store.dispatch(removePolar() as any);
};

export const partnerIntegrations = [
  {
    title: 'GARMIN',
    id: 1,
    description: 'Receba no Corrida Perfeita atividades feitas em seu Garmin, bem como envie para seu relógio os treinos planejados pelo Corrida Perfeita.',
    enabled: false,
    paragraphs: [
      'A nossa integração com a Garmin permite:',
      '1- Transferir para o Corrida Perfeita os treinos realizados em seu Garmin.',
      '2 - Enviar os treinos de corrida planejados no Corrida Perfeita, direto para seu Garmin Connect.',
      'Para ativar as duas possibilidades acima, marque todas as opções na tela de integração no site da Garmin.',
      'Atenção: Caso receba os treinos direto da Garmin, evite deixar ativada a integração com o Strava, para não haver duplicidade de registros aqui no Corrida Perfeita.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/garmin-logo.png',
    addIntegration: addGarminIntegration,
    conversion: 'integracao-garmin',
    type: 'garmin',
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001136841-como-integrar-o-corrida-perfeita-com-o-seu-garmin-connect',
    removeIntegration: removeGarminIntegration,
  },
  {
    title: 'STRAVA',
    id: 2,
    description: 'Receba no Corrida Perfeita atividades salvas no Strava, inclusive vindas de seu relógio.',
    enabled: false,
    paragraphs: [
      'Esta integração permite registrar no Corrida Perfeita todos os treinos realizados ou registrados pelo app Strava.',
      'Isso inclui tanto os treinos registrados pelo GPS do Strava, quanto os treinos que o Strava recebe do seu relógio Polar, Apple Watch, TomTom, Amazfit, Samsung, dentre outros.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/strava-logo.png',
    addIntegration: addStravaIntegration,
    conversion: 'integracao-strava',
    type: 'strava',
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001203042-como-integrar-o-strava-com-o-corrida-perfeita',
    removeIntegration: removeStravaIntegration,
  },
  // {
  //   title: 'COROS',
  //   id: 3,
  //   description: 'Receba no Corrida Perfeita atividades feitas em seu Coros, bem como envie para seu relógio os treinos planejados pelo Corrida Perfeita.',
  //   enabled: false,
  //   paragraphs: [
  //     'A nossa integração com a Coros permite:',
  //     '1- Transferir para o Corrida Perfeita os treinos realizados em seu Coros.',
  //     '2 - Enviar os treinos de corrida planejados no Corrida Perfeita, direto para seu Coros Training Hub.',
  //     'Para ativar as duas possibilidades acima, marque todas as opções na tela de integração no site da Coros.',
  //     'Atenção: Caso receba os treinos direto da Coros, evite deixar ativada a integração com o Strava ou Garmin, para não haver duplicidade de registros aqui no Corrida Perfeita.',
  //   ],
  //   image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/coros-logo.png',
  //   addIntegration: addCorosIntegration,
  //   conversion: 'integracao-coros',
  //   type: 'coros',
  //   helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001136841-como-integrar-o-corrida-perfeita-com-o-seu-garmin-connect',
  //   removeIntegration: removeCorosIntegration,
  // },
  {
    title: 'POLAR',
    id: 4,
    description: 'Receba no Corrida Perfeita atividades feitas em seu Polar.',
    enabled: false,
    paragraphs: [
      'A nossa integração com a Polar permite:',
      '1- Transferir para o Corrida Perfeita os treinos realizados em seu Polar.',
      'Marque todas as opções na tela de integração no site da Polar.',
      'Atenção: Caso receba os treinos direto da Polar, evite deixar ativada a integração com o Strava ou Garmin ou Coros, para não haver duplicidade de registros aqui no Corrida Perfeita.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/polar-logo.png',
    addIntegration: addPolarIntegration,
    conversion: 'integracao-polar',
    type: throughStravaProviders.Polar,
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001136842-como-integrar-o-aplicativo-com-o-polar',
    removeIntegration: removePolarIntegration,
  },
];

export const otherIntegrations = [
  {
    title: 'AMAZFIT',
    id: 5,
    description: 'Receba no Corrida Perfeita atividades vindas de seu relógio, através do parceiro Strava.',
    enabled: false,
    paragraphs: [
      'Esta integração permite a sincronização no Corrida Perfeita dos treinos realizados em seu equipamento AMAZFIT que sejam registrados automaticamente no app Strava.',
      'Logo, para que essa integração funcione, sua conta AMAZFIT precisa estar integrada à conta Strava. Para saber como fazer todo o processo, clique aqui antes de continuar.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/amazfit-logo.png',
    addIntegration: addStravaIntegration,
    conversion: 'integracao-strava-amazfit',
    type: throughStravaProviders.Amazfit,
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001136843-como-integrar-seu-rel%C3%B3gio-amazfit-com-o-corrida-perfeita',
    removeIntegration: removeStravaIntegration,
  },
  {
    title: 'SAMSUNG',
    id: 6,
    description: 'Receba no Corrida Perfeita atividades vindas de seu relógio, através do parceiro Strava.',
    enabled: false,
    paragraphs: [
      'Esta integração permite a sincronização no Corrida Perfeita dos treinos realizados em seu equipamento SAMSUNG que sejam registrados automaticamente no app Strava.',
      'Logo, para que essa integração funcione, sua conta SAMSUNG precisa estar integrada à conta Strava. Para saber como fazer todo o processo, clique aqui antes de continuar.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/samsung-logo.png',
    addIntegration: addStravaIntegration,
    conversion: 'integracao-strava-samsung',
    type: throughStravaProviders.Samsung,
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001203042-como-integrar-o-strava-com-o-corrida-perfeita',
    removeIntegration: removeStravaIntegration,
  },
  {
    title: 'APPLE',
    id: 7,
    description: 'Receba no Corrida Perfeita atividades vindas de seu relógio, através do parceiro Strava.',
    enabled: false,
    paragraphs: [
      'Esta integração permite a sincronização no Corrida Perfeita dos treinos realizados em seu equipamento APPLE que sejam registrados automaticamente no app Strava.',
      'Logo, para que essa integração funcione, sua conta APPLE precisa estar integrada à conta Strava. Para saber como fazer todo o processo, clique aqui antes de continuar.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/apple-logo.png',
    addIntegration: addStravaIntegration,
    conversion: 'integracao-strava-apple',
    type: throughStravaProviders.Apple,
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001189782-como-integrar-seu-apple-watch-ao-corrida-perfeita',
    removeIntegration: removeStravaIntegration,
  },
  {
    title: 'OUTRO',
    id: 8,
    description: 'Receba no Corrida Perfeita atividades vindas de seu relógio, através do parceiro Strava.',
    enabled: false,
    paragraphs: [
      'Esta integração permite a sincronização no Corrida Perfeita dos treinos realizados em seu relógio que sejam registrados automaticamente no app Strava.',
      'Logo, para que essa integração funcione, seu relógio precisa estar integrado à conta Strava. Para saber como fazer todo o processo, clique aqui antes de continuar.',
    ],
    image: 'https://cp-integration-logos.s3.sa-east-1.amazonaws.com/generic-logo.png',
    addIntegration: addStravaIntegration,
    conversion: 'integracao-strava-outro',
    type: throughStravaProviders.Other,
    helpLink: 'https://ajuda.corridaperfeita.com/support/solutions/articles/47001203042-como-integrar-o-strava-com-o-corrida-perfeita',
    removeIntegration: removeStravaIntegration,
  },
];

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  background: ${theme.colors.primary2};
  width: 100%;
  padding: 15px 12px 0px 10px;
 
`;

export const Content = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const TextInfo = styled.button`  
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  margin-left: 8px;
  background-color: ${theme.colors.primary2};
  
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-family: 'Lato';
  font-style: normal;
  font-size: 15px;

   @media (max-width: 667px) {
    font-size: 12px;
  }
  
`;

export const Text = styled.span`
  margin-left: 2px;
  cursor: pointer;
  color: ${theme.colors.text};
  text-decoration: underline;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

import React from 'react';

export interface CalculatorsContextData {
  flowData: {
    calculatorStep: CalculatorStep,
    currentActiveCalculator: CalculatorType,
  },
  logicData: {
    time: number | undefined,
    distance : number | undefined,
    pace: number | undefined,
    error: string | undefined,
  },
  resultData: {
    resultTime: number | undefined,
    resultDistance: number | undefined,
    resultPace: number | undefined,
    resultVelocity: number | undefined,
  },
  actions: {
    setCurrentActiveCalculator: React.Dispatch<React.SetStateAction<CalculatorType>>,
    setTime: React.Dispatch<React.SetStateAction<number | undefined>>,
    setDistance: React.Dispatch<React.SetStateAction<number | undefined>>,
    setPace: React.Dispatch<React.SetStateAction<number | undefined>>,
    calculate: () => void,
    reset: () => void,
  },
}

export enum CalculatorStep {
  Input = 'Input',
  Result = 'Result',
}

export enum CalculatorType {
  Time = 'Time',
  Pace = 'Pace',
  Distance = 'Distance',
}

export const ExternalCalculatorType = {
  Time: CalculatorType.Time,
  Pace: CalculatorType.Pace,
  Distance: CalculatorType.Distance,
  Tempo: CalculatorType.Time,
  Ritmo: CalculatorType.Pace,
  Distancia: CalculatorType.Distance,
};

import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import api from '../services/api';

export interface IShoesBrand {
  id: number;
  name: string;
}
export interface IShoes {
  id: number;
  name: string;
  distance_total: number;
  shoesBrand: IShoesBrand;
  color: string;
  status: string;
  shoes_brand_id: number;
}

export interface Activity {
  id: number,
  title: string,
  distance: number | null,
  pace: number | null,
  duration: number | string,
  heart_rate: number | null,
  altimetry: number | null,
  calorie: number | null,
  visible: number,
  user_id: number,
  created_at: string,
  updated_at: string,
  platform_type: string | null,
  type: string,
  start_date: string,
  summary_polyline: string | null,
  max_speed: number | null,
  average_speed: number | null,
  average_cadence: number | null,
  average_heartrate: number | null,
  max_heartrate: number | null,
  training_id: number | null,
  comments: number | null,
  shoes?: IShoes | null,
  barefoot?: boolean,
}

interface Activities {
  loading: boolean;
  activities: Array<Activity>;
  page: number;
  hasMore: boolean,
  currentEditActivity: Activity | null;
}

const activities = createSlice({
  name: 'activities',
  initialState: {
    activities: [],
    loading: false,
    page: 1,
    hasMore: true,
    currentEditActivity: null,
  } as Activities,
  reducers: {
    activitiesRequest: (state) => {
      state.loading = true;
    },
    activitiesRequestSucess: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        state.hasMore = false;
      }

      if (state.page === 1) {
        state.activities = action.payload;
      } else {
        const newActivities = [...state.activities, ...action.payload];
        const ids = newActivities.map((activity) => activity.id);
        state.activities = newActivities
          .filter(({ id }, index) => !ids.includes(id, index + 1));
      }
      state.loading = false;
    },
    activitiesRequestFail: (state) => {
      state.loading = false;
      state.hasMore = false;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    resetPage: (state) => {
      state.page = 1;
      state.activities = [];
      state.hasMore = true;
    },
    setCurrentEditActivity: (state, action) => {
      state.currentEditActivity = action.payload;
    },
  },
});

export const {
  activitiesRequest, activitiesRequestSucess, activitiesRequestFail,
  incrementPage, resetPage, setCurrentEditActivity,
} = activities.actions;
export default activities.reducer;

export const getActivities = (userId: number | string): AppThunk => (
  async (dispatch, getState) => {
    const { page } = getState().activities;
    if (page === 1) dispatch(activitiesRequest());
    await api.get(`user_activities?user_id=${userId}&page=${page}`)
      .then((response) => {
        const activities = response.data.data;
        dispatch(activitiesRequestSucess(activities));
        dispatch(incrementPage());
      })
      .catch(() => {
        dispatch(activitiesRequestFail());
      });
  }
);

export const refreshActivities = (userId: number | string) : AppThunk => (
  async (dispatch) => {
    dispatch(resetPage());
    dispatch(getActivities(userId));
  }
);

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background: ${theme.colors.cardB1};
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6%;
  min-height: 220px;
`;

export const Title = styled.h2`
    color: ${theme.colors.title};
    font-weight: 600;
    margin-bottom: 8%;
`;

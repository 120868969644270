import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
    display: flex;
    padding: 2%;
    background: ${theme.colors.primary};
`;

export const Banner = styled.div`
    background-color: ${theme.colors.cardB2};
    padding: 10px;
    border-radius: 5px;
`;

export const Title = styled.span`
    color: ${theme.colors.title};
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: 600;
`;

export const ChartInfo = styled.span`
    color: ${theme.colors.title};
    font-size: 14px;
`;

export const ChartContainer = styled.div`
    background-color: ${theme.colors.cardB2};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border: 1px solid #1B63F2;
    padding: 10px;
    min-height: 100px;
`;

export const ContainerStyle = styled.div`
    width: 90px;
  @media (min-width: 667px) {
    width: 156px;  
  }
`;

import React from 'react';
import ModalButton from '../ModalButton';
import { Container, Title } from './styles';

interface PropsI {
  saving: boolean
  onPressSave: () => void
  onPressExit: () => void
  onPressBack: () => void
}

function AbandonExerciseModalConfirm({
  saving, onPressSave, onPressExit, onPressBack,
}: PropsI) {
  return (
    <Container data-testid="abandon-exercise-modal-confirm">
      <Title>ABANDONAR TREINO</Title>
      <div>
        <ModalButton
          disabled={saving}
          onPress={onPressSave}
          secondary
          title="SALVAR E SAIR"
        />
        <ModalButton
          disabled={saving}
          onPress={onPressExit}
          secondary
          title="SAIR"
        />
        <ModalButton
          disabled={saving}
          onPress={onPressBack}
          secondary
          title="VOLTAR"
        />
      </div>
    </Container>
  );
}
export default AbandonExerciseModalConfirm;

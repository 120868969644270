import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.header`
    background-color: ${theme.colors.primary};
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
`;

export const Main = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 667px) {
        max-width: 320px;
    }
`;

export const Brand = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Menu = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Logo = styled.img`
    height: 40px;
    margin-right: 20px;

    @media (max-width: 667px) {
        height: 35px;
    }
`;

export const MenuButton = styled.button`
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 20px;

    @media (max-width: 667px) {
            svg {
            height: 17px;
        }
    } 
    position: relative;
`;

export const Title = styled.span`
    font-size: 12pt;
    color: ${theme.colors.title};
    font-weight: bold;
`;

export const Dot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.secondary};
    margin-left: 10px;
    margin-top: -6px;
`;

export const LinkButton = styled(Link)`
    text-decoration: none;

    @media (max-width: 667px) {
        span {
            font-size: 11px;
        }
    }

    span {
        color: white;
        font-weight: bold;
    }
`;

import React from 'react';
import {
  Container,
  ProductTitle,
  ProductText,
  ErrorText,
  Quantity,
  Points,
  DataNames,
  DataValues,
  TotalPoints,
  TextPoints,
  ValuePoints,
} from './styles';
import RedeemProductContext from '../../../pages/RedeemProduct/RedeemProductContext';
import CarouselProduct from '../CarouselProduct';
import GenderButtons from '../GenderButtons';
import SizeButtons from '../SizeButtons';
import ProductCounter from '../ProductCounter';

export default function SelectedProductDetails() {
  const {
    selectedProduct,
    productDetails,
  } = React.useContext(RedeemProductContext);

  const {
    productGender,
    setProductGender,
    productSize,
    setProductSize,
    productQuantity,
    setProductQuantity,
  } = productDetails;

  const {
    errorProductGender,
    errorProductSize,
    errorProductQuantity,
  } = productDetails.errors;

  const { userConfirmedPoints } = React.useContext(RedeemProductContext).userData;

  return (
    <Container>
      <ProductTitle>{selectedProduct && selectedProduct.name}</ProductTitle>

      {selectedProduct && selectedProduct.images && (
        <CarouselProduct images={selectedProduct.images} />
      )}

      {selectedProduct && selectedProduct.genders && selectedProduct.reward_type !== 'SERVICE' && (
        <>
          <ProductText>Selecione a opção de Gênero:</ProductText>
          <GenderButtons
            genderString={selectedProduct.genders}
            selectedGender={productGender}
            setSelectedGender={setProductGender}
          />
          <ErrorText>{errorProductGender}</ErrorText>
        </>
      )}

      {selectedProduct && selectedProduct.sizes && selectedProduct.reward_type !== 'SERVICE' && (
        <>
          <ProductText>Selecione a opção de Tamanho:</ProductText>
          <SizeButtons
            sizesString={selectedProduct.sizes}
            selectedSize={productSize}
            setSelectedSize={setProductSize}
          />
          <ErrorText>{errorProductSize}</ErrorText>
        </>
      )}

      <ProductText>Selecione a quantidade:</ProductText>
      <Quantity>
        <ProductCounter
          number={productQuantity}
          setValue={setProductQuantity}
        />
        <Points>
          <DataNames>
            <TextPoints>Indicações disponíveis:</TextPoints>
            <TextPoints>Total do resgate:</TextPoints>
          </DataNames>
          <DataValues>
            <ValuePoints>
              {userConfirmedPoints}
              {' '}
              indicações
            </ValuePoints>
            <TotalPoints>
              {(selectedProduct?.required_points_quantity || 1) * productQuantity}
              {' '}
              indicações
            </TotalPoints>
          </DataValues>
        </Points>
      </Quantity>
      <ErrorText>{errorProductQuantity}</ErrorText>
    </Container>
  );
}

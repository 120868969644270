import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.nav`
    background-color: ${theme.colors.primary3};
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 60px;
    z-index: 10;

    img {
        height: 30px;
        width: 30px;
    }

    @media (max-width: 667px) {
        height: 35px;

        img {
            height: 18px;
            width: 18px;
        }
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 18px;

    @media (max-width: 667px) {
        max-width: 320px;
        padding: 13px;
    } 
`;

export const MenuButton = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 13px;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { AppThunk } from '.';
import { api2 } from '../services/api';

interface IPermission {
  identifier: string;
}

const store = createSlice({
  name: 'permissions',
  initialState: {
    items: [] as IPermission[],
    permissionsLoaded: false,
  },
  reducers: {
    setPermissions: (state, action) => {
      state.items = action.payload;
    },
    setPermissionsLoaded: (state, action) => {
      state.permissionsLoaded = action.payload;
    },
  },
});

export const {
  setPermissions,
  setPermissionsLoaded,
} = store.actions;

export default store.reducer;

export const loadPermissions = (): AppThunk => (
  async (dispatch, getState) => {
    const platform = 'WEB';

    if (getState().permissions.permissionsLoaded) {
      return;
    }

    try {
      api2.get(`features_permissions/${platform}`)
        .then((response) => {
          dispatch(setPermissions(response.data));
        });

      dispatch(setPermissionsLoaded(true));
    } catch (err) {
      toast.error('Erro ao carregar permissões do usuário');
    }
  }
);

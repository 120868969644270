import styled from 'styled-components';
import theme, { appTheme } from '../../../theme';

export const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${theme.colors.title};
`;

export const InnerContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InputContainer = styled.div<{backgroundColor?: string}>`
    flex: 0.325;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor || appTheme.cardB3};

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const InputTitle = styled.span`
    color: ${appTheme.text};
    height: 50%;
`;

export const Label = styled.label`
    margin-bottom: 20px;
`;

export const Input = styled.input`
    width: 30px;
    background-color: transparent;
    color: ${appTheme.title};
    border: none;
    height: 50px;
    font-size: 18px;
    text-align: center;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

import styled from 'styled-components';
import theme from '../../theme';

interface AbandonI {
  isAbandon: boolean
}

export const Container = styled.div`
    height: 100%;
    width: 100%;
`;

export const Header = styled.div`
    border-bottom-width: 2px;
    border-color: ${theme.colors.cardB1};
    display: flex;
    flex-direction:column;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
`;

export const CongratsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DefaultCongratsText = styled.p`
    color: ${theme.colors.title};
    font-weight: 800;
    margin-bottom: 1rem;
`;

export const CongratsTitle = styled(DefaultCongratsText)`
    font-size: 30px;
`;

export const TimerContainer = styled.div`
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
`;

export const TimerLabel = styled.p`
    color: ${theme.colors.title};
    font-weight: bold;
    font-size: 30px;
    margin-left: 5px;
    margin-bottom: 1rem;
`;

export const FinishSerieActionContainer = styled.div<AbandonI>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: ${(props) => (props.isAbandon ? 2 : 1)}
`;

export const Scroll = styled.div`
    display: flex;  
    flex-direction: column;
    align-items: 'center';
    justify-content: 'flex-start';
    padding-bottom: '15%';
    width: 100%;
`;

export const UnderlinedButton = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
  margin-top: 8%;
`;

export const UnderlinedButtonText = styled.p`
  color: red;
  font-weight: 500;
  font-size: 12px;
  border-bottom-width: 1px;
  border-color: red;
  margin-bottom: 1rem;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
`;

import React from 'react';

import { addDays, getDay, subDays } from 'date-fns';
import reqDistaceImg from '../../assets/training-plan-requests/distancia.jpg';
import reqEmagImg from '../../assets/training-plan-requests/emagrecimento.jpg';
import reqInitationImg from '../../assets/training-plan-requests/iniciacao.jpg';
import reqKeepFitImg from '../../assets/training-plan-requests/manterforma.jpg';
import reqAltImg from '../../assets/training-plan-requests/alternativo.jpg';

import { ReactComponent as ReqDistaceIcon } from '../../assets/training-plan-requests/distancia.svg';
import { ReactComponent as ReqEmagIcon } from '../../assets/training-plan-requests/emagrecimento.svg';
import { ReactComponent as ReqInitationIcon } from '../../assets/training-plan-requests/iniciacao.svg';
import { ReactComponent as ReqKeepFitIcon } from '../../assets/training-plan-requests/manter-forma.svg';
import { ReactComponent as ReqAltIcon } from '../../assets/training-plan-requests/alternativo.svg';

export function formatTrainingObjectiveType(text: string) {
  switch (text) {
    case 'Quero apenas manter minha forma':
      return 'Sem objetivo definido';
    case 'Tenho um objetivo próximo com data marcada':
      return 'Com objetivo definido';
    default:
      return '';
  }
}

export function formatUserTrainingLevel(value?: string) {
  if (value === '+ 1h por semana' || value === '+ 2h por semana' || value === '+ 3h por semana' || value === '+ 4h por semana' || value === '+ 3 atividades por semana') {
    return 3;
  } if (value === '30min a 1h total por semana' || value === '1h a 2h total por semana' || value === '1h30 a 3h total por semana' || value === '3h a 4h total por semana' || value === '2 atividades por semana') {
    return 2;
  } if (value === 'zero ou quase nada' || value === '- 1h30 ou estou sem correr' || value === '- 3h ou estou sem correr' || value === 'zero ou 1 atividade por semana') {
    return 1;
  }

  return 1;
}

export function subtractMinTargetDate(date: Date) {
  const weekDay = date.getTime();
  let finalDate = date;

  if (getDay(weekDay) === 1) {
    finalDate = subDays(date, 2);
  } else if (getDay(weekDay) === 2) {
    finalDate = subDays(date, 3);
  }

  return finalDate;
}

export function sumMaxTargetDate(date: Date) {
  const weekDay = date.getTime();
  let finalDate = date;

  if (getDay(weekDay) === 3) {
    finalDate = addDays(date, 4);
  } else if (getDay(weekDay) === 4) {
    finalDate = addDays(date, 3);
  } else if (getDay(weekDay) === 5) {
    finalDate = addDays(date, 2);
  } else if (getDay(weekDay) === 6) {
    finalDate = addDays(date, 1);
  }

  return finalDate;
}

export function formatTitleAlternative(type: string) {
  switch (type) {
    case 'CARDIO':
      return 'Funcional cardio';
    case 'BIKE':
      return 'Bicicleta';
    case 'ROPE_JUMP':
      return 'Pulo de corda';
    default:
      return '';
  }
}

export const chooseTotalTime = (item: string) => {
  let durationHour: string | number = item.split(':')[0];
  let durationMin: string | number = item.split(':')[1];
  let durationSec: string | number = item.split(':')[2];

  durationHour = parseFloat(durationHour);
  durationMin = parseFloat(durationMin);
  durationSec = parseFloat(durationSec);

  const duration = (
    (durationHour * 60) + durationMin + (durationSec ? durationSec / 60 : 0)
  ).toFixed(2);

  return duration;
};

export const formatTrainingRequestType = (type: string) => {
  switch (type) {
    case 'DISTANCE':
      return reqDistaceImg;
    case 'KEEP_FIT':
      return reqKeepFitImg;
    case 'RETURN_INITIATION':
      return reqInitationImg;
    case 'SLIMMING':
      return reqEmagImg;
    case 'ALTERNATIVE':
      return reqAltImg;
    default:
      return '';
  }
};

export const formatTrainingRequestIconType = (type: string) => {
  switch (type) {
    case 'DISTANCE':
      return (
        <ReqDistaceIcon />
      );
    case 'KEEP_FIT':
      return (
        <ReqKeepFitIcon />
      );
    case 'RETURN_INITIATION':
      return (
        <ReqInitationIcon />
      );
    case 'SLIMMING':
      return (
        <ReqEmagIcon />
      );
    case 'ALTERNATIVE':
      return (
        <ReqAltIcon />
      );
    default:
      return null;
  }
};

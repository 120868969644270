import styled from 'styled-components';
import { appTheme } from '../../../theme';

interface ItemProps {
  width?: number;
}

export const Item = styled.p<ItemProps>`
  color: ${appTheme.title};
  text-align: center;
  width: ${(props) => (props.width ? `${props.width}%` : null)};
`;

export const ChartContainer = styled.div`
  padding: 5px;
  display: flex;
  background-color: white;
  width: 100%;
  border-radius: 5px;
`;

export const LapsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Laps = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const ContainerCard = styled.div`
  background: ${appTheme.cardB1};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-top: 5px;
  border-radius: 5px;
  max-height: 90%;
`;

export const SelectContainer = styled.div`
  width: 25%;
  margin-bottom: 20px;
`;

export const ItemsLine = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
`;

export const ItemsLineTitle = styled.p`
  margin-right: 10px;
  color: ${appTheme.title};
`;

export const ItemsLineValue = styled.p`
  color: ${appTheme.title};
`;

export const SeparatorV = styled.div`
  height: 2px;
  background: ${appTheme.background};
  margin: 8px;
`;

export const LapsTitle = styled.p`
  font-size: 16px;
  color: ${appTheme.title};
  font-weight: 500;
  margin-left: 10px;
`;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, StatusTrainingContainer, TrainingInfo, TrainingInfoContainer,
  TrainingName,
} from './styles';
import TrainingStatus from '../TrainingStatus';
import { formatActivityTypeIcon } from '../../utils';

export interface TrainingItemProps {
  training: any;
}

function TrainingItem({ training } : TrainingItemProps) {
  const navigate = useNavigate();
  return (
    <Container data-testid="training-item-container" onClick={() => navigate('/training')}>
      <img src={formatActivityTypeIcon(training?.type || '')} alt="Treino de Hoje" />
      <TrainingInfoContainer>
        <TrainingName>{training?.name || ''}</TrainingName>
        {!!training?.distance && <TrainingInfo>{`${training?.distance} Km`}</TrainingInfo>}
      </TrainingInfoContainer>
      <StatusTrainingContainer>
        {!!training?.executed_status
       && (<TrainingStatus status={training?.executed_status} small />)}
      </StatusTrainingContainer>
    </Container>

  );
}

export default TrainingItem;

import styled from 'styled-components';
import theme from '../../theme';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.cardB1};
  padding-bottom: 100px;
`;

export const Logo = styled.img`
  width: 95px;
  height: 45px;
  margin: 20px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 10px 50px 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.cardB2};
  margin-bottom: 30px;
`;

export const BadgeGreeting = styled.p`
  color: ${theme.colors.secondary};
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;

  @media (max-width: 667px) {
    font-size: 11px;
  }
`;

export const BadgeDescription = styled.p`
  color: ${theme.colors.title};
  width: 75%;
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 667px) {
    width: 80%;
    font-size: 12px;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  margin-top: 50px;
`;

export const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: ${theme.colors.title};
  border-radius: 5px;
  width: 70%;
  border: none;
  cursor: pointer;
`;

export const CloseButtonText = styled.p`
  color: ${theme.colors.cardB1};
  font-size: 11px;
`;

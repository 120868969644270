import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAddCircleOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store';
import Loader from '../Loader';
import ActivityItem from '../ActivityItem';
import RegisterActivity from '../RegisterActivity';

import {
  ActivityText,
  Card, ContainerItem, HeaderContainer, HeaderText,
  SyncActivitiesContainer, LinkContainer, LinkText,
  SyncActivitiesText,
} from './styles';

import { Activity, getActivities, resetPage } from '../../store/Activities.store';

export interface IRecentActivities {
  currentActivities?: Activity[]
}

function RecentActivities({ currentActivities }: IRecentActivities) {
  const auth = useSelector((state: RootState) => state.auth);
  const { loading, activities: storeActivities } = useSelector((state: RootState) => state.activities);
  const { user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const activityRequest = async () => {
    dispatch(getActivities(user?.id) as any);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const refresh = () => {
    dispatch(resetPage());
    activityRequest();
  };

  useEffect(() => {
    if (!currentActivities) {
      activityRequest();
    }
  }, []);

  const activitiesToShow = useMemo(() => {
    if (currentActivities) return currentActivities;
    return storeActivities;
  }, [currentActivities, storeActivities]);

  return (
    <Card data-testid="recent-activities">
      <RegisterActivity
        visible={modalVisible}
        close={closeModal}
        refresh={refresh}
      />

      <HeaderContainer>
        <HeaderText>ATIVIDADES RECENTES</HeaderText>
        <MdAddCircleOutline
          onClick={() => setModalVisible(true)}
          color="white"
          style={{ width: 20, height: 20 }}
        />
      </HeaderContainer>

      {
        !loading ? (
          <>
            {
              activitiesToShow?.length > 0
              && activitiesToShow?.slice(0, 3)
                .map((activity) => <ActivityItem key={activity?.id} activity={activity!} />)
            }

            {
              !activitiesToShow?.length
              && (
                <ContainerItem>
                  <ActivityText>Você não possui atividades registradas</ActivityText>
                </ContainerItem>
              )
            }
          </>
        )
          : (
            <SyncActivitiesContainer>
              <SyncActivitiesText>Sincronizando suas atividades...</SyncActivitiesText>
              <Loader color="white" />
            </SyncActivitiesContainer>
          )
      }

      {
        !loading
        && (
          <LinkContainer>
            {
              activitiesToShow?.length > 0
              && (
                <LinkText onClick={() => navigate('/activity-list')}>
                  Ver todos
                </LinkText>
              )
            }
          </LinkContainer>
        )
      }
    </Card>
  );
}

export default RecentActivities;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { requestTrainings } from '../../store/TrainingDay.store';
import TrainingItem from '../TrainingItem';
import {
  Card, ContainerItem, HeaderContainer, LinkContainer, LinkText,
} from './styles';

export default function TodayTraining() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, trainingDay: trainings } = useSelector((state: RootState) => state.trainingDay);
  const trainingPlanLoading = useSelector((state: RootState) => state.trainingPlan.loading);

  useEffect(() => {
    if (!trainingPlanLoading) {
      dispatch(requestTrainings() as any);
    }
  }, [trainingPlanLoading]);

  // TODO melhorar esse tratamento, se necessario
  // useEffect(() => {
  //   console.log(error);
  //   if (error != null) {
  //       toast.error('Ocorreu um erro ao carregar seu treino de hoje');
  //   }
  // }, [JSON.stringify(error)]);

  return (
    <Card data-testid="today-training-container">
      <HeaderContainer>
        <h2>TREINO DE HOJE</h2>
      </HeaderContainer>

      {!loading
        && (
          <>
            {trainings?.map((training, index) => <TrainingItem key={index} training={training} />)}
            {trainings?.length === 0
              && (
                <ContainerItem>
                  <p>Você não possui treinos planejados para hoje.</p>
                </ContainerItem>
              )}
          </>
        )}

      <LinkContainer>
        {
          trainings?.length > 0
          && (
            <LinkText onClick={() => navigate('/training')}>
              Ver todos
            </LinkText>
          )
        }
      </LinkContainer>
    </Card>
  );
}

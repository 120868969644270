import styled from 'styled-components';
import { appTheme } from '../../../../theme';

interface ICardHeader {
  hasActivity?: boolean;
}

export const Container = styled.div`
    padding: 5px;
    margin-bottom: 16px;
    border-radius: 5px;
    background-color: ${appTheme.cardB3};

    .mbsc-material.mbsc-calendar-label-background {
        background-color: inherit;
        background: unset;
    }
`;

export const CardHeader = styled.div<ICardHeader>`
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 2px solid ${appTheme.cardB2};
    margin-bottom: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 700;
    font-size: 10px;
    line-height: 14px;

    text-transform: uppercase;
    
    cursor: ${(props) => (props?.hasActivity ? 'pointer' : 'default')};

    img {
        margin-right: 8px;
    }
`;

export const CardHeaderTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const CardBody = styled.div`
    margin-bottom: 20px;

    li {
        margin-left: 10px;

        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
    }

    li ol {
        margin-bottom: 15px;
    }

    .dimmed-distance {
        margin-bottom: 15px;
        color:  ${appTheme.text};
        
        &.EXECUTED_OVER {
            color: ${appTheme.green}
        }
        &.EXECUTED_HIGH {
            color: ${appTheme.green}
        }
        &.EXECUTED_MEDIUM {
            color: ${appTheme.green}
        }
        &.EXECUTED_PARTIAL {
            color: ${appTheme.secondaryD1}
        }
        &.EXECUTED_LOW {
            color: ${appTheme.secondaryD1}
        }
    }
`;

export const CardFooter = styled.div`
    text-decoration: underline;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    

    div {
        display: flex;
        align-items: center;
        cursor: pointer !important;
        margin-bottom: 8px;

        svg {
            margin-right: 3px;
            font-size: 13px;
        }
    }
`;

export const StatusIcon = styled.div`
    margin-bottom: 3px;
`;

import React from 'react';
import {
  CalendarPrev, CalendarNext, CalendarNav, CalendarToday,
} from '@mobiscroll/react';
import { MdInfoOutline } from 'react-icons/md';
import {
  CalendarCustomHeader, ActivePlanButton, CustomNav, CustomTodayButton,
} from './style';

export interface CustomHeaderProps {
  planRequest?: any
}

export default function CustomHeader({ planRequest } : CustomHeaderProps) {
  return (
    <CalendarCustomHeader>
      <ActivePlanButton id="active-plan-button">
        <MdInfoOutline />
        {planRequest}
      </ActivePlanButton>

      <CustomNav>
        <CalendarPrev />
        <CalendarNav />
        <CalendarNext />
      </CustomNav>

      <CustomTodayButton>
        <CalendarToday />
      </CustomTodayButton>

    </CalendarCustomHeader>
  );
}

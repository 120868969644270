/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import DistanceIcon from '../../../assets/club/distance.svg';
import {
  ContentContainer,
  FormContainer,
  WhiteActionButton,
  WhiteButtonTitle,
  FooterButtonsContainer,
  TimeInputContainer,
  HeaderText,
  ContentContainerText,
  ErrorText,
  TimeInputTitle,
  TimeInputSubTitle,
  FooterMessage,
  TimeInputContent,
} from '../styles';
import TimeInput from '../../../components/SpecificInputs/TimeInput';
import { calculateAndSendData, setUser1KmTime, setUser5KmTime } from '../../../store/Prediction.store';
import { RootState } from '../../../store';
import THEME from '../../../theme';

export default function PredictionInputScreen() {
  const dispatch = useDispatch();
  const { apiError, userRunData, loading } = useSelector((state: RootState) => state.prediction);

  return (
    <>
      <ContentContainer>
        <img src={DistanceIcon} alt="Ícone Distância" style={{ height: 60, width: 60 }} />
        <HeaderText>Previsão de desempenho</HeaderText>
        <ContentContainerText>
          Calcule a previsão de tempo para as distâncias de 10km, 15km, Meia Maratona e Maratona baseadas na sua performance atual preenchendo os campos abaixo:
        </ContentContainerText>

        <FormContainer>
          <TimeInputContainer>
            <TimeInputContent>
              <TimeInputTitle>
                5KM
              </TimeInputTitle>

              <TimeInputSubTitle>
                Informe seu tempo atual:
              </TimeInputSubTitle>

              <TimeInputSubTitle>
                {' '}
              </TimeInputSubTitle>
            </TimeInputContent>

            <TimeInput
              valueInSeconds={userRunData['5KmTime']}
              onChange={(time) => dispatch(setUser5KmTime(time) as any)}
              showLabel={false}
              backgroundColor={THEME.colors.cardB2}
            />
          </TimeInputContainer>

          <TimeInputContainer>
            <TimeInputContent>
              <TimeInputTitle>
                1KM
              </TimeInputTitle>

              <TimeInputSubTitle>
                Informe seu tempo atual:
              </TimeInputSubTitle>

              <TimeInputSubTitle>
                (opcional)
              </TimeInputSubTitle>
            </TimeInputContent>

            <TimeInput
              valueInSeconds={userRunData['1KmTime']}
              onChange={(time) => dispatch(setUser1KmTime(time) as any)}
              showLabel={false}
              backgroundColor={THEME.colors.cardB2}
            />
          </TimeInputContainer>
        </FormContainer>

        {apiError && (
          <ErrorText>
            {apiError}
          </ErrorText>
        )}

        <FooterButtonsContainer>
          <WhiteActionButton
            disabled={loading}
            onClick={() => dispatch(calculateAndSendData() as any)}
          >
            <WhiteButtonTitle>{loading ? (<Loader color={THEME.colors.cardB1} />) : ('Calcular')}</WhiteButtonTitle>
          </WhiteActionButton>
        </FooterButtonsContainer>
      </ContentContainer>
      <FooterMessage>Método de cálculo desenvolvido pela equipe &quot;Sistema Força e Endurance&quot;.</FooterMessage>
    </>
  );
}

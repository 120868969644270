import styled from 'styled-components';
import { appTheme } from '../../theme';

interface DayCardProps {
  selected: boolean
}

export const DayCard = styled.div<DayCardProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.selected ? appTheme.background : 'transparent')};
  align-items: center;
  border-radius: 10px;
  padding: 10px 12px;
  height: 100%;
  cursor: pointer;

  .dimmed {
    color: ${appTheme.iconsOff};
  }

  @media (max-width: 667px) {
    padding: 3px 2px;
  }
`;

export const DayIndicator = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border-color: ${appTheme.iconsOff};
  background-color: transparent;
  border-style: solid;
  border-width: 1px;

  @media (max-width: 667px) {
    width: 15px;
    height: 15px;
  }
`;

export const DayName = styled.div`
  color: ${appTheme.title};
  margin: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 667px) {
    font-size: 12px;
    line-height: 14px;
    margin: 3px;
  }
`;

export const DateText = styled.div`
  color: white;
  font-size: 10px;
  margin-bottom: 3px;
`;

import React from 'react';
import ReactSlider from 'react-slider';
import Thumb from './Thumb';
import Track from './Track';

export interface IRangeSliderProps {
  values: [number, number];
  callback: (numberArray: [number, number]) => void;
  min: number;
  max: number;
  customThumb?: (props: any, state: any) => JSX.Element;
}

function RangeSlider({
  values, callback, min, max, customThumb,
}: IRangeSliderProps) {
  return (
    <>
      <ReactSlider
        renderTrack={Track}
        renderThumb={customThumb || Thumb}
        min={min}
        max={max}
        step={1}
        pearling
        onChange={callback}
        value={values}
      />
    </>
  );
}

export default RangeSlider;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  padding: 10px;
  background-color: ${theme.colors.cardB3};
  border-radius: 10px;
  width: 100%;
  height: 73px;
  cursor: pointer;
`;
export const InfoContainer = styled.div`
  flex-direction: column;
  height: 100%;
  width: 85%;
  justify-content: space-between;
  margin-top: 5px;
`;

export const Header = styled.span`
  text-transform: uppercase;
  color: ${theme.colors.title};
  font-size: 15px;
  font-weight: 500;
  width: 80%;
`;

export const CardInfo = styled.div`
  color: ${theme.colors.gray};
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 13px;
`;

export const ImageSelectionArea = styled.div`
  color: ${theme.colors.title};

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }
`;

import React from 'react';
import {
  AuxDescriptionText, Container, Description, Title,
} from './styles';
import DistanceInput from '../../../SpecificInputs/DistanceInput';

function SetLastRecordScreen({
  step,
  setLastRecord,
  lastRecord,
  selectedDistance,
  requiredLastRecordDistance,
  distance,
}: any) {
  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <DistanceInput
        kmValue={lastRecord}
        onChange={(value: number) => setLastRecord(value)}
        justKilometers
      />

      {distance > 5 && (
        <AuxDescriptionText>
          {`*Para realizar um treino para ${selectedDistance}km de forma segura, é importante que você já tenha completado ao menos ${requiredLastRecordDistance}km nos últimos 30 dias, além de experiência na distância. Por isso o preenchimento do campo acima é obrigatório.`}
        </AuxDescriptionText>
      )}
    </Container>
  );
}

export default SetLastRecordScreen;

/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';
import { appTheme } from '../../theme';

const shine = keyframes`
    to {
      background-position-x: -200%;
    }
`;

export const Container = styled.div`
    width: 100%;

    .loading-skeleton {
        background: #eee;
        background: linear-gradient(110deg, ${appTheme.cardB3} 8%, ${appTheme.cardB2} 18%, ${appTheme.cardB3} 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s ${shine} linear infinite;

        height: 130px;
        width: 90%;
        margin-bottom: 20px;
    }

    .skeleton-small {
        height: 60px;
    }

    .skeleton-big {
        height: 160px;
    }

    .empty-state {
        font-size: 80px;
        color: ${appTheme.cardB1};
        height: 100%;
        width: 100%;
        line-height:500%
        /* position: absolute;
        transform: translateY(40%); */
    }
`;

export const CalendarContainer = styled.div`
    margin: 0 -200px;

    .mbsc-material {
        color: ${appTheme.title};
    }
    
    // Calendar Body
    .mbsc-eventcalendar {
        border-radius: 10px;
        background-color: transparent;
    }

    .mbsc-scrollview-scroll.mbsc-ltr {
        display: grid;
    }

    .mbsc-calendar-slide.mbsc-material.mbsc-ltr {
        position: fixed;
    }

    .mbsc-calendar-slide.mbsc-calendar-slide-active.mbsc-material.mbsc-ltr{
        grid-area: 2 / 2 / 1 / 1;
        position: initial;
    }

    .mbsc-material.mbsc-calendar-slide {
        background-color: ${appTheme.cardB2};
    }

    .mbsc-calendar-week-day {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        background-color: ${appTheme.iconsOff};
        color: ${appTheme.cardB1};
    }

    .mbsc-eventcalendar, .mbsc-calendar-wrapper, .mbsc-calendar-body, .mbsc-calendar-body-inner, .mbsc-calendar-scroll-wrapper {
        overflow: unset;
        z-index: 0;
    }

    .mbsc-calendar-scroll-wrapper {
        overflow-x: clip;
    }

    .mbsc-eventcalendar,
    .mbsc-calendar-wrapper,
    .mbsc-calendar-body,
    .mbsc-calendar-body-inner,
    .mbsc-calendar-picker,
    .mbsc-calendar-scroll-wrapper,
    .mbsc-calendar-scroll-wrapper>div,
    .mbsc-calendar-scroll-wrapper>div>div,
    .mbsc-calendar-slide,
    .mbsc-calendar-table,
    .mbsc-material.mbsc-calendar-cell-inner,
    .mbsc-calendar-day-inner,
    .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-text,
    .mbsc-calendar-text
    {
        height: unset;
    }

    .mbsc-calendar-labels {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: initial;
    }

    // Header
    .mbsc-material.mbsc-calendar-controls{
        border-radius: 10px 10px 0 0;
        background-color: ${appTheme.cardB3};
        padding: 15px 25px;
    }
    
    .mbsc-material.mbsc-button {
        color: inherit;
        padding: 0;
        margin: 0;
    }

    .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }

    .mbsc-material.mbsc-icon-button {
        width: auto;
    }

    // Day Cell
    .mbsc-calendar-day.mbsc-calendar-day-labels {
        min-height: 50vh;
        border-left: ${appTheme.background} 1px solid;
    }

    .mbsc-calendar-cell {
        cursor: default;
        border: none;

        ::after {
            border-left-style: none !important;
        }
    }

    .mbsc-calendar-cell, .mbsc-calendar-text {
       
        white-space: unset;
    }

    .mbsc-calendar-day-inner{
        height: 100%;
    }

    .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        height: 100%;
    }

    .mbsc-calendar-label-background, .mbsc-calendar-text:before {
        position: initial;
    }

    .mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
        background: unset;
        border: none;
    }

    // Event Card
    .mbsc-material.mbsc-range-day .mbsc-calendar-cell-inner, .mbsc-material.mbsc-selected .mbsc-calendar-cell-inner {
        background: unset;
    }

    .mbsc-calendar-label-text {
        z-index: unset;
    }

    // Mini Calendar
    .mbsc-popup-content {
        background-color: white;
    }
`;

export const ActivePlanModal = styled.div`
    color: ${appTheme.title};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 34px;

    h1 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    p {
        font-size: 13px;
        line-height: 20px;
    }
`;

export const Button = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: ${appTheme.title};
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    width: fit-content;

    color: ${appTheme.cardB3};
    font-size: 14px;
    font-weight: bold;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

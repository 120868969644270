import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import alert from 'react-hot-toast';
import RedeemProductContext from './RedeemProductContext';
import { IRewardProduct, RedeemProductContextData } from './types';
import api from '../../services/api';

interface RedeemProductProviderProps {
  children: React.ReactNode;
}

export default function RedeemProductProvider({ children }: RedeemProductProviderProps) {
  const navigate = useNavigate();

  // User data:
  const [userConfirmedPoints, setUserConfirmedPoints] = useState<number>(0);
  const [userPendingPoints, setUserPendingPoints] = useState<number>(0);
  const [coupon, setCoupon] = useState('');

  // Flow data:
  const [selectedProduct, setSelectedProduct] = useState<IRewardProduct | undefined>(undefined);
  const [redeemStep, setRedeemStep] = useState<number>(0);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
  const [showRedeemSucceed, setShowRedeemSucceed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Product details data:
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [productGender, setProductGender] = useState<string | undefined>(undefined);
  const [productSize, setProductSize] = useState<string | undefined>(undefined);
  // Product details errors:
  const [errorProductSize, setErrorProductSize] = useState<string | undefined>(undefined);
  const [errorProductGender, setErrorProductGender] = useState<string | undefined>(undefined);
  const [errorProductQuantity, setErrorProductQuantity] = useState<string | undefined>(undefined);

  // Address data:
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [hasNumber, setHasNumber] = React.useState(true);
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [cep, setCep] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  // Address errors:
  const [errorMessage, setErrorMessage] = useState('');
  const [addressError, setAddressError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [neighborhoodError, setNeighborhoodError] = useState<boolean>(false);
  const [cepError, setCepError] = useState<boolean>(false);
  const [countryError, setCountryError] = useState<boolean>(false);
  const [invalidCountryError, setInvalidCountryError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [stateError, setStateError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  // const [invalidCep, setInvalidCep] = useState<boolean>(false);

  function resetFlowData() {
    setSelectedProduct(undefined);
    setRedeemStep(0);
    setShowCancelConfirmation(false);
    setShowRedeemSucceed(false);
  }

  function resetProductDetailsData() {
    setProductQuantity(1);
    setProductGender(undefined);
    setProductSize(undefined);
  }

  function resetProductDetailsErrors() {
    setErrorProductGender(undefined);
    setErrorProductSize(undefined);
    setErrorProductQuantity(undefined);
  }

  function resetAddressData() {
    setAddress('');
    setNumber('');
    setHasNumber(true);
    setComplement('');
    setNeighborhood('');
    setCep('');
    setCountry('');
    setCity('');
    setState('');
    setPhone('');
  }

  function resetAddressErrors() {
    setErrorMessage('');
    setAddressError(false);
    setNumberError(false);
    setNeighborhoodError(false);
    setCepError(false);
    setCountryError(false);
    setCityError(false);
    setStateError(false);
    setPhoneError(false);
    // setInvalidCep(false);
    setInvalidCountryError(false);
  }

  const checkProductSelectedDetails = React.useCallback(() => {
    let isValid = true;
    resetProductDetailsErrors();

    if (selectedProduct?.genders && !productGender) {
      setErrorProductGender('Selecione uma opção de gênero.');
      isValid = false;
    }

    if (selectedProduct?.sizes && !productSize) {
      setErrorProductSize('Selecione uma opção de tamanho.');
      isValid = false;
    }

    if (!productQuantity) {
      setErrorProductQuantity('Selecione uma opção de quantidade.');
      isValid = false;
    }

    if (productQuantity * (selectedProduct?.required_points_quantity || 1) > userConfirmedPoints) {
      setErrorProductQuantity('Você não tem indicações o suficiente para essa quantidade.');
      isValid = false;
    }

    if (isValid) setRedeemStep(1);

    return isValid;
  }, [selectedProduct, productGender, productSize, productQuantity]);

  // const checkAddressData = React.useCallback(() => {
  //   let isValid = true;

  //   resetAddressErrors();

  //   if (phone.replace(/\D/g, '').length < 10) {
  //     setErrorMessage('Lembre-se de incluir o DDD no campo do telefone.');
  //     setPhoneError(true);
  //     isValid = false;
  //   }

  //   if (
  //     !address
  //     || (!number && hasNumber)
  //     || !neighborhood
  //     || !cep
  //     || !country
  //     || !city
  //     || !state
  //     || !phone
  //   ) {
  //     setErrorMessage('*Preencha todos os campos obrigatórios.');

  //     if (!address) setAddressError(true);
  //     if (!number && hasNumber) setNumberError(true);
  //     if (!neighborhood) setNeighborhoodError(true);
  //     if (!cep) setCepError(true);
  //     if (!country) setCountryError(true);
  //     if (!city) setCityError(true);
  //     if (!state) setStateError(true);
  //     if (!phone) setPhoneError(true);

  //     isValid = false;
  //   }

  //   if (invalidCep) {
  //     setErrorMessage('CEP inválido.');
  //     setCepError(true);
  //     isValid = false;
  //   }

  //   if (country !== '' && country.toLowerCase() !== 'brasil') {
  //     setInvalidCountryError(true);
  //     isValid = false;
  //   }

  //   return isValid;
  // }, [
  //   address,
  //   number,
  //   hasNumber,
  //   complement,
  //   neighborhood,
  //   cep,
  //   country,
  //   city,
  //   state,
  //   phone,
  //   invalidCep,
  // ]);

  const submitRedeem = React.useCallback(() => {
    setLoading(true);

    api
      .post(
        'referrals/create_request',
        {
          phone,
          address: {
            street: address,
            number: hasNumber ? number : 's/n',
            district: neighborhood,
            city,
            state,
            complement,
            country,
            zipcode: cep,
          },
          items: [
            {
              referral_reward_id: selectedProduct?.id,
              quantity: productQuantity,
              gender: productGender,
              size: productSize,
            },
          ],
        },
      )
      .then(() => {
        setShowRedeemSucceed(true);
        setUserConfirmedPoints(userConfirmedPoints - (productQuantity * (selectedProduct?.required_points_quantity || 1)));
        setLoading(false);
      })
      .catch(() => {
        alert('Erro ao enviar seu pedido, tente novamente mais tarde.');
        setLoading(false);
      });
  }, [
    phone,
    address,
    number,
    hasNumber,
    complement,
    neighborhood,
    cep,
    country,
    city,
    state,
    productQuantity,
    productGender,
    productSize,
    selectedProduct,
    userConfirmedPoints,
  ]);

  function resetAll() {
    resetFlowData();
    resetProductDetailsData();
    resetProductDetailsErrors();
    resetAddressData();
    resetAddressErrors();
  }

  function exitFlow() {
    resetAll();

    navigate('/indication');
  }

  const stepAhead = React.useCallback(() => {
    if (redeemStep === 0 && checkProductSelectedDetails()) {
      // setRedeemStep(1);
      submitRedeem();
    }

    // if (redeemStep === 1 && checkAddressData()) {
    //   submitRedeem();
    // }
  }, [redeemStep, checkProductSelectedDetails]);

  const stepBack = React.useCallback(() => {
    if (redeemStep === 0) {
      setShowCancelConfirmation(true);
    }

    if (redeemStep === 1) {
      setRedeemStep(0);
    }
  }, [redeemStep]);

  const value: RedeemProductContextData = useMemo(() => ({
    selectedProduct,
    setSelectedProduct,
    redeemStep,
    showCancelConfirmation,
    setShowCancelConfirmation,
    showRedeemSucceed,
    setShowRedeemSucceed,
    stepAhead,
    stepBack,
    exitFlow,
    resetAll,
    loading,
    setLoading,
    userData: {
      userConfirmedPoints,
      setUserConfirmedPoints,
      userPendingPoints,
      setUserPendingPoints,
      coupon,
      setCoupon,
    },
    productDetails: {
      productQuantity,
      setProductQuantity,
      productGender,
      setProductGender,
      productSize,
      setProductSize,
      errors: {
        errorProductSize,
        setErrorProductSize,
        errorProductGender,
        setErrorProductGender,
        errorProductQuantity,
        setErrorProductQuantity,
      },
    },
  }), [
    selectedProduct,
    setSelectedProduct,
    redeemStep,
    showCancelConfirmation,
    setShowCancelConfirmation,
    showRedeemSucceed,
    setShowRedeemSucceed,
    stepAhead,
    stepBack,
    exitFlow,
    loading,
    setLoading,
    userConfirmedPoints,
    setUserConfirmedPoints,
    userPendingPoints,
    setUserPendingPoints,
    productQuantity,
    setProductQuantity,
    productGender,
    setProductGender,
    productSize,
    setProductSize,
    errorProductSize,
    setErrorProductSize,
    errorProductGender,
    setErrorProductGender,
    errorProductQuantity,
    setErrorProductQuantity,
    address,
    setAddress,
    number,
    setNumber,
    complement,
    setComplement,
    city,
    setCity,
    neighborhood,
    setNeighborhood,
    cep,
    setCep,
    country,
    setCountry,
    state,
    setState,
    phone,
    setPhone,
    errorMessage,
    setErrorMessage,
    addressError,
    setAddressError,
    numberError,
    setNumberError,
    neighborhoodError,
    setNeighborhoodError,
    cepError,
    setCepError,
    countryError,
    setCountryError,
    invalidCountryError,
    setInvalidCountryError,
    cityError,
    setCityError,
    stateError,
    setStateError,
    phoneError,
    setPhoneError,
    // setInvalidCep,
  ]);

  return (
    <RedeemProductContext.Provider value={value}>
      {children}
    </RedeemProductContext.Provider>
  );
}

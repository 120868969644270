import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  margin-top: 15px;
`;

export const Header = styled.div`
  color: ${theme.colors.title};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const CardContainer = styled.div`
    width: 100%; 
    background: ${theme.colors.cardB2};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  
     @media (max-width: 667px) {
        grid-gap: 5px;
    }
    overflow-y: scroll;
    ::-webkit-scrollbar {
  width: 0.1em; 
}

::-webkit-scrollbar-track {
  background-color: transparent; 
}

::-webkit-scrollbar-thumb {
  background-color: transparent; 
}
`;

export const FillerCard = styled.div`
  margin: 8px;
  background-color: 'transparent';
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
    background-color: ${theme.colors.cardB2};   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:10px;
    padding: 10px;
    width: 100%;
    margin-bottom: 25px;
`;

export const ContainerImg = styled.div`
    text-align: center;
    img{
        margin-top: 5px;
        width: 30px;
        height: 30px;
        max-width: 100%;
    }
`;

export const Header = styled.h3`
  color: ${theme.colors.notifications};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
`;

export const InfoContainer = styled.div` 
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const TextInfo = styled.p`
  color: ${theme.colors.title};
  font-size: 10px;
  text-align: center;
  font-weight: 500;
`;

export const Button = styled.button`
  background-color: ${theme.colors.graph};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border: none;
`;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { loginWithToken } from '../../store/Auth.store';

import { Container, Text } from './styles';

export default function LoginRedirect() {
  const [searchParms] = useSearchParams();
  const dispatch = useDispatch();

  const token = searchParms.get('token');
  const redirectUrl = searchParms.get('redirect_url');
  const err = searchParms.get('err');

  const _loginWithToken = async () => {
    if (err) {
      window.location.href = '/login';
      return;
    }
    if (token) {
      const data = JSON.parse(atob(token.split('.')[1]));

      await dispatch(loginWithToken(data.uid, token) as any);
      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }

      window.location.href = '/home';
    }
  };

  useEffect(() => {
    _loginWithToken();
  }, [token]);

  return (
    <Container>
      <Text>{`Redirecionando para ${redirectUrl ?? 'o Corrida Perfeita'}`}</Text>
    </Container>
  );
}

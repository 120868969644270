import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { ITrainingPlanRequest } from '../types/TrainingPlanRequest';

const trainingPlanRequests = createSlice({
  name: 'trainingPlanRequests',
  initialState: {
    trainingPlanRequest: {} as ITrainingPlanRequest | null,
    reload: false,
    loading: false,
    newRequest: false,
  },
  reducers: {
    setReloadFlag: (state, action) => {
      state.reload = action.payload.flag;
    },
    setTrainingPlanRequest: (state, action) => {
      state.trainingPlanRequest = action.payload.data;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.data;
    },
    setNewRequest: (state, action) => {
      state.newRequest = action.payload.data;
    },
  },
});

export const {
  setReloadFlag,
  setTrainingPlanRequest,
  setLoading,
  setNewRequest,
} = trainingPlanRequests.actions;

export default trainingPlanRequests.reducer;

export const reloadTrainingPlanRequest = (flag: boolean) : AppThunk => (
  (dispatch) => {
    dispatch(setReloadFlag({ flag }));
  }
);

export const trainingPlanRequest = (data: ITrainingPlanRequest | null) : AppThunk => (
  (dispatch) => {
    dispatch(setTrainingPlanRequest({ data }));
  }
);

export const loadingTrainingPlanRequest = (data: boolean) : AppThunk => (
  (dispatch) => {
    dispatch(setLoading({ data }));
  }
);
export const newPlanRequest = (data: boolean) : AppThunk => (
  (dispatch) => {
    dispatch(setNewRequest({ data }));
  }
);

import styled from 'styled-components';
import theme from '../../theme';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow: hidden;
  outline: 0;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
`;

export const ModalDialog = styled.div`
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  position: relative;
  width: 600px;
  margin: 60px auto;

  @media (max-width: 667px) {
    width: 320px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: ${theme.colors.title};
    font-size: 25px;
    z-index: 999;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: ${theme.colors.primary};
  background-clip: padding-box;
  outline: 0;
`;

export const ModalBackDrop = styled.div`
  opacity: 0.4;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: white;
`;

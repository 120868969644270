import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ICalendarDayTraining } from '../../types/TrainingPlan';

import {
  Container, Header, CalendarContainer,
} from './styles';

import DayItem from '../DayItem';

import { resetDay } from '../../store/TrainingPlan.store';

export interface WeekProgressProps {
  currentWeek: ICalendarDayTraining[];
  onClick?: () => void;
}

export default function WeekProgress({ currentWeek, onClick }: WeekProgressProps) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const goToTraining = () => {
    dispatch(resetDay() as any);
    navigate('/training');
  };

  return (
    <Container onClick={onClick ? () => onClick() : () => goToTraining()} data-testid="week-progress">
      <Header>PROGRESSO NA SEMANA</Header>

      <CalendarContainer>
        {
          currentWeek?.map(
            (trainingDay) => <DayItem key={trainingDay?.id} trainingDay={trainingDay} />,
          )
        }
      </CalendarContainer>
    </Container>
  );
}

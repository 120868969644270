export enum exercisesTypes {
  WeightTraining = 'WeightTraining',
  Drills = 'Drills',
  Others = 'Others',
  Cardio = 'Cardio'
}

export interface TrainingActivityI {
  id: number
  name: string
  description: string
  repetition: number
  duration: number
  execution_speed: number
  execution_equipment: number | null
  media_link: string
  thumb_link: string
  tuto_link: string
}

export interface TrainingGroupsI {
  id: number
  name: string
  description: string
  duration_total: number | null
  distance_total: number | null
  trainingActivities: Array<TrainingActivityI>
}

export interface ExerciseI {
  id: number
  name: string
  description: string
  active: boolean
  activity_type: string
  duration: number
  estimated_init: number
  estimated_end: number
  equipments: string
  type: string
  cover_link: string
  intensity: number
  level: string
  trainingGroups: Array<TrainingGroupsI>
  start_date?: string
  from?: string
  associateScheduledTraining?: boolean
  distance: number | null
  executed_status: string | null
}

import React, { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import ContentLoader from 'react-content-loader';
import api from '../../services/api';
import { Activity, setCurrentEditActivity } from '../../store/Activities.store';
import ModalBase from '../BaseModal';
import { appTheme } from '../../theme';
import {
  ActivityContainer,
  ActivityTitle,
  Button,
  ButtonContainer,
  Container,
  DateText,
  HeaderContainer,
  InfoContainer,
  InfoItem,
  InfoText,
  LoadingContainer,
  MapContainer,
  NullStateContainer,
  NullStateText,
  Separator,
  TextButton,
} from './styles';
import { formatDuration } from '../../utils';
import Loader from '../Loader';

export interface Props {
  isVisible: boolean;
  close: () => void;
  trainingId: number | null;
}

function LinkedActivityModal({
  trainingId,
  isVisible,
  close,
}: Props) {
  const { isLoaded: mapIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [coords, setCoords] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const date = activity
    ? format(parseISO(activity?.start_date), "dd MMM yyyy 'às' HH:mm", {
      locale: ptBR,
    })
    : '';

  const mapRef = useRef(null);

  const fetchLinkedActivity = () => {
    setLoading(true);

    api.get(`user_activities/linked-activity/${trainingId}`)
      .then((response) => {
        setActivity(response?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadCoords = () => {
    if (activity?.id && activity?.summary_polyline) {
      api.get(`/user_activities/coordinates/${activity.id}`)
        .then((response) => {
          setCoords(response.data);
        })
        .catch(() => {
          setCoords(null);
        });
    }
  };

  const handleSeeAll = () => {
    if (activity) {
      dispatch(setCurrentEditActivity(activity));
      navigate('/activity-detail');
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchLinkedActivity();
    }
  }, [isVisible]);

  useEffect(() => {
    if (activity?.summary_polyline) loadCoords();
    else setCoords(null);
  }, [activity]);

  return (
    <ModalBase
      isVisible={isVisible}
      setIsVisible={close}
      title="RESUMO DA ATIVIDADE"
    >
      <Container>
        {loading
          && (
            <LoadingContainer>
              <Loader color="white" />
            </LoadingContainer>
          )}

        {!loading && !!activity && (
          <>
            <ActivityContainer>
              <HeaderContainer>
                <ActivityTitle>{activity?.title}</ActivityTitle>
                <DateText>{date}</DateText>
              </HeaderContainer>
              {
                coords && (
                  (mapIsLoaded && (
                    <MapContainer ref={mapRef}>
                      <GoogleMap
                        mapContainerStyle={{
                          width: '100%',
                          height: '100%',
                        }}
                        center={{
                          lat: coords.length ? coords[0].latitude : 0,
                          lng: coords.length ? coords[0].longitude : 0,
                        }}
                        zoom={14}
                        options={{ disableDefaultUI: true }}
                      >
                        <Polyline
                          path={coords.map((coord: { latitude: any; longitude: any; }) => ({ lat: coord.latitude, lng: coord.longitude }))}
                          options={{
                            strokeColor: appTheme.primaryD3,
                            strokeOpacity: 1,
                            strokeWeight: 3,
                          }}
                        />
                      </GoogleMap>
                    </MapContainer>
                  ))
                  || (
                    <ContentLoader
                      speed={2}
                      viewBox="-35 35 570 410"
                      backgroundColor={appTheme.background}
                      foregroundColor={appTheme.cardB3}
                    >
                      <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
                      <ellipse cx="120" cy="140" rx="28" ry="28" />
                    </ContentLoader>
                  )
                )
              }
              <InfoContainer>
                <Separator />
                {activity?.distance ? (
                  <>
                    <InfoItem>
                      <InfoText>Distância</InfoText>
                      <InfoText>{`${activity?.distance} km`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.pace ? (
                  <>
                    <InfoItem>
                      <InfoText>Ritmo</InfoText>
                      <InfoText>
                        {`${formatDuration(activity?.pace, 'pace')} /km`}
                      </InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.duration ? (
                  <>
                    <InfoItem>
                      <InfoText>Tempo</InfoText>
                      <InfoText>
                        {`${formatDuration(activity?.duration as number, 'time')}`}
                      </InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.average_speed ? (
                  <>
                    <InfoItem>
                      <InfoText>Vel. Média</InfoText>
                      <InfoText>
                        {`${activity?.average_speed.toFixed(1)} km/h`}
                      </InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.max_speed ? (
                  <>
                    <InfoItem>
                      <InfoText>Vel. Máxima</InfoText>
                      <InfoText>
                        {`${activity?.max_speed.toFixed(1)} km/h`}
                      </InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.average_cadence ? (
                  <>
                    <InfoItem>
                      <InfoText>Cadência Média</InfoText>
                      <InfoText>{`${activity?.average_cadence}`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}

                {activity?.average_heartrate ? (
                  <>
                    <InfoItem>
                      <InfoText>FC Média</InfoText>
                      <InfoText>{`${activity?.average_heartrate} bpm`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.max_heartrate ? (
                  <>
                    <InfoItem>
                      <InfoText>FC Máxima</InfoText>
                      <InfoText>{`${activity?.max_heartrate} bpm`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.altimetry ? (
                  <>
                    <InfoItem>
                      <InfoText>Ganho de Elevação</InfoText>
                      <InfoText>{`${activity?.altimetry} m`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.calorie ? (
                  <>
                    <InfoItem>
                      <InfoText>Calorias</InfoText>
                      <InfoText>{`${activity?.calorie} kcal`}</InfoText>
                    </InfoItem>
                    <Separator />
                  </>
                ) : (
                  <></>
                )}
                {activity?.comments ? (
                  <>
                    <InfoItem>
                      <InfoText>Observações</InfoText>
                      <InfoText>{`${activity?.comments}`}</InfoText>
                    </InfoItem>
                  </>
                ) : (
                  <></>
                )}
              </InfoContainer>
            </ActivityContainer>

            <ButtonContainer>
              <Button onClick={handleSeeAll}>
                <TextButton>Ver Atividade Completa</TextButton>
              </Button>
            </ButtonContainer>
          </>
        )}

        {!loading && !activity && (
          <NullStateContainer>
            <NullStateText>Não foi encontrada nenhuma atividade vinculada a este treino</NullStateText>
          </NullStateContainer>
        )}
      </Container>
    </ModalBase>
  );
}

export default LinkedActivityModal;

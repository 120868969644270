import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.cardB1};
  border-radius: 5px;
  position: relative;
  
  @media (max-width: 667px) {
    max-width: 140px;   
  }
`;

export const Image = styled.img`
  @media (max-width: 667px) {
    height: 100px;
  }

  width: 100%;
  height: 180px;
  object-fit: cover;

  margin-bottom: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Title = styled.div`  
  font-weight: bold;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: ${theme.colors.title};
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  @media (max-width: 667px) {
    font-size: 13px;
  }
`;

export const Points = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFC700;
  margin-bottom: 22px;
`;

export const Button = styled.button<{ active: boolean }>`
  background-color: ${theme.colors.title};
  border-radius: 5px;
  padding: 8px 34px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 8px;
  cursor: pointer;

  ${({ active }) => !active
    && css`
      opacity: 0.3;
  `}
`;

export const ButtonText = styled.div`
  color: ${theme.colors.cardB1};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
`;

import React from 'react';
import {
  ItemTitle, ModalContainer, ModalButtonsContainer, ItemDescription,
} from './styles';
import { IStopAskShoesModalProps } from '../contracts';
import BaseModal from '../../BaseModal';
import { GenericButton } from '../../GenericButton/styles';
import theme from '../../../theme';
import Loader from '../../Loader';

export default function StopAskShoesModal({
  visible, setVisible, handleSave, loading,
}: IStopAskShoesModalProps) {
  return (
    <BaseModal
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <ModalContainer>
        <ItemTitle>
          CONTROLE OS KMS DE SEUS TÊNIS
        </ItemTitle>

        <ItemDescription>
          É necessário ativar a função para cadastrar um novo
          tênis de corrida e vincular aos seus treinos.
        </ItemDescription>

        <ModalButtonsContainer>
          <GenericButton
            backgroundColor={theme.colors.title}
            textColor={theme.colors.primary}
            borderColor={theme.colors.title}
            style={{
              width: '50%',
              marginBottom: '10px',
            }}
            onClick={handleSave}
          >
            {loading ? <Loader color={theme.colors.title} /> : 'ATIVAR E SALVAR'}
          </GenericButton>

          <GenericButton
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.title}
            borderColor={theme.colors.title}
            style={{
              width: '50%',
            }}
            onClick={() => setVisible(false)}
          >
            CANCELAR
          </GenericButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </BaseModal>
  );
}

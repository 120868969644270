import styled from 'styled-components';
import THEME, { appTheme } from '../../theme';

export const DisclaimerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 20px 50px;
  flex-direction: column;
  align-items: center;
`;

export const DisclaimerTitle = styled.p`
  color: ${THEME.colors.title};
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
  font-size: 14px;

  @media (max-width: 410px) {
    font-size: 10px;
  }
`;

export const DisclaimerText = styled.p`
  color: ${THEME.colors.title};
  font-size: 13px;
  text-align: center;
`;

export const DisclaimerDismissBtn = styled.button`
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: ${THEME.colors.title};
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  background-color: ${THEME.colors.title};
  width: 70%;
`;

export const DisclaimerDismissBtnTitle = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  color: ${appTheme.background};
`;

export const FooterMessage = styled.p`
  color: ${THEME.colors.iconsOff};
  font-size: 12px;
  text-align: center;
  margin: 25px 0 35px;
`;

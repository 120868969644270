import React from 'react';

import { Button, Label } from './styles';

interface PropsI {
  title: string
  disabled?: boolean
  secondary?: boolean
  danger?: boolean
  style?: object
  onPress: () => void
}

function ModalButton({
  title,
  secondary,
  danger,
  style,
  onPress,
  disabled,
}: PropsI) {
  return (
    <Button style={style} onClick={onPress} secondary={secondary} danger={danger} disabled={disabled}>
      <Label danger={danger}>{title}</Label>
    </Button>
  );
}

export default ModalButton;

import React, { PropsWithChildren, HTMLAttributes } from 'react';
import { Card } from './styles';

function CarouselCard({ children } : PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
  return (
    <Card>
      {children}
    </Card>
  );
}

export default CarouselCard;

import React from 'react';
import BaseModal from '../../../../components/BaseModal';
import {
  ModalContent, ModalText, ModalTitle, StyledLink,
} from './styles';

interface PropsI {
  visible: boolean,
  selectedCard: any,
  setVisible: any,
}

function ModalNotifications({ visible, setVisible, selectedCard } : PropsI) {
  const renderModalText = () => {
    if (selectedCard && selectedCard.action_url && selectedCard.action_url.trim() !== '') {
      return (
        <StyledLink href={selectedCard.action_url} target="_blank" rel="noopener noreferrer">
          <ModalText>
            {selectedCard && selectedCard.content}
          </ModalText>
        </StyledLink>
      );
    }
    return (
      <ModalText>
        {selectedCard && selectedCard.content}
      </ModalText>
    );
  };

  return (
    <BaseModal
      title=""
      setIsVisible={setVisible}
      isVisible={visible}
    >
      <ModalContent>
        <ModalContent>
          <ModalTitle>
            {selectedCard && selectedCard.title}
          </ModalTitle>
          {renderModalText()}
        </ModalContent>
      </ModalContent>
    </BaseModal>
  );
}

export default ModalNotifications;

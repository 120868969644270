import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import {
  Card, InfoContainer, Title, RaceText, IconButton, TextIconButton, IconButtonContainer,
} from './styles';
import { formatStateNameToUrl } from '../../utils';

export interface RaceCalendarCardProps {
  title: string;
  numberOfRaces: number;
}

export default function RaceCalendarCard({ title, numberOfRaces }: RaceCalendarCardProps) {
  const navigate = useNavigate();
  return (
    <Card>
      <InfoContainer>
        <Title>{title}</Title>
        <RaceText>
          {numberOfRaces}
          {' '}
          corridas
          {' '}
        </RaceText>
      </InfoContainer>
      <IconButton onClick={() => navigate(`/corridaderua/${formatStateNameToUrl(title)}`)}>
        <IconButtonContainer>
          <TextIconButton>VER CORRIDAS</TextIconButton>
          <MdOutlineKeyboardArrowRight size={15} color="white" />
        </IconButtonContainer>
      </IconButton>
    </Card>
  );
}

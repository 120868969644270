import React from 'react';
import { useNavigate } from 'react-router-dom';
import alert from 'react-hot-toast';
import ProductCard from './ProductCard';
import {
  CardContainer,
  Container, Header,
} from './styles';
import { IRewardProduct } from '../../pages/RedeemProduct/types';
import RedeemProductContext from '../../pages/RedeemProduct/RedeemProductContext';
import api from '../../services/api';

export default function RedeemablePrizes() {
  const navigate = useNavigate();
  const { setSelectedProduct, resetAll } = React.useContext(RedeemProductContext);
  const [products, setProducts] = React.useState<IRewardProduct[]>([]);
  const { userConfirmedPoints } = React.useContext(RedeemProductContext).userData;

  React.useEffect(() => {
    resetAll();

    api
      .get('referrals/user_available_rewards')
      .then((response) => {
        const sortedProducts = response.data.data.sort((a: IRewardProduct, b: IRewardProduct) => a.list_order - b.list_order);
        setProducts(sortedProducts);
      })
      .catch(() => {
        alert('Erro ao carregar prêmios, tente novamente mais tarde.');
      });
  }, []);

  const onRedeem = (product: IRewardProduct) => {
    setSelectedProduct(product);
    navigate('/RedeemProduct');
  };

  const renderProductCard = ({ item }: { item: IRewardProduct }) => (
    <ProductCard
      key={item.id}
      product={item}
      onRedeem={onRedeem}
      active={item.required_points_quantity <= userConfirmedPoints}
    />
  );

  return (
    <>
      <Container>
        <Header>Prêmios resgatáveis</Header>
        <CardContainer>
          {products.map((product) => renderProductCard({ item: product }))}
        </CardContainer>
      </Container>
    </>
  );
}

import styled from 'styled-components';
import theme from '../../theme';
import LiveClassImage from '../../assets/courses/explorar-aulas-ao-vivo.jpg';

export const Container = styled.section`
  width: 100%;
`;

export const Content = styled.section`
  flex: 1;
  background: ${theme.colors.cardB1};
  padding: 1%;
`;

export const AgendaContainer = styled.section`
  border-radius: 10px;
  width: 100%;
  height: 160px;
  cursor: pointer;
`;

export const ImageButton = styled.section`
  display: flex;
  height: 160px;
  flex: 1;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px;
  background-image: url(${LiveClassImage});
  background-repeat: no-repeat;
  background-size: cover;
  

`;

export const Title = styled.span`
  display: flex;
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 15px;
  color: ${theme.colors.title};
  font-weight: 500;
  padding-bottom: 5px;
`;

export const InfoContainer = styled.div`
  width: 78%;
  padding-left: 5px;
  cursor: pointer;
`;

export const Info = styled.span`
display:flex;
  font-size: 13px;
  color: ${theme.colors.text};
  flex-direction: column;
  width: 90%;
`;
export const LiveContainer = styled.div`
  display: inline-block;
  
  justify-content: start;
  border: '1px solid';
  border-color: red;
  margin-bottom: 5px;
`;

export const Live = styled.span`
  display: inline-block;
  border: '1px';
  border-color: red;
  border-style: solid;
  text-transform: uppercase;
  font-size: 13px;
  color: red;
  font-weight: 500;
  padding: 3px;
`;

export const ItemButton = styled.section`
display: flex;
  width: 100%;
  background-color: ${theme.colors.cardB2};
  height: 120px;
  border-radius: 10px;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const InfoContent = styled.section`
display:flex;
justify-content: space-between;
flex-direction: column;
align-items: flex-start;
`;
export const IconContainer = styled.span`
cursor: pointer;
`;

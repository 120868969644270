import React, { useEffect } from 'react';
import { BsCheckCircleFill, BsClockFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import alert from 'react-hot-toast';
import api from '../../services/api';
import {
  Container, Header, Content, PendingPoints, AvailablePoints, PointText, Text, CountContainer, ButtonContainer, Button, TextButton,
} from './styles';
import RedeemProductContext from '../../pages/RedeemProduct/RedeemProductContext';

interface UserPointsResponse {
  available: number;
  pending: number;
}

export default function PointsSummary() {
  const navigate = useNavigate();
  const {
    setUserConfirmedPoints,
    setUserPendingPoints,
    userConfirmedPoints,
    userPendingPoints,
  } = React.useContext(RedeemProductContext).userData;

  useEffect(() => {
    const fetchUserPoints = async () => {
      try {
        const response = await api.get<UserPointsResponse>('/referrals/user_points');
        if (response.data) {
          setUserConfirmedPoints(response.data.available);
          setUserPendingPoints(response.data.pending);
        } else {
          alert('Indicações indisponíveis, tente novamente!');
        }
      } catch (error) {
        alert('Indicações indisponíveis, tente novamente!');
      }
    };
    fetchUserPoints();
  }, []);

  return (
    <Container data-testid="points-summary">
      <Header>Resumo de Indicações</Header>
      <Text>Acompanhe suas indicações</Text>
      <Content>
        <AvailablePoints>
          <PointText>
            <BsCheckCircleFill style={{ color: 'green', marginRight: 5 }} size={12} />
            disponíveis
          </PointText>
          <CountContainer>{userConfirmedPoints}</CountContainer>
        </AvailablePoints>
        <PendingPoints>
          <PointText>
            <BsClockFill style={{ color: 'orange', marginRight: 5 }} size={12} />
            a confirmar
          </PointText>
          <CountContainer>{userPendingPoints}</CountContainer>
        </PendingPoints>
      </Content>
      <ButtonContainer>
        <Button onClick={() => navigate('/indication/points')}>
          <TextButton>Ver todos os detalhes</TextButton>
        </Button>
      </ButtonContainer>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';

import initialCount from '../../assets/audios/number-count.mp3';
import initialBeep from '../../assets/audios/beep-start.mp3';
import finalBeep from '../../assets/audios/beep-end.mp3';
import finalCount from '../../assets/audios/count_num.mp3';

import CounterModal from '../CounterModal';

interface ExerciseOnProgressPanelI {
  exercise: any
  started: boolean
  auxTime: any
  time: any
  trainingCompleted: () => void
  pauseTime: () => void
  playTime: () => void
  setAuxTime: Function
}

function ExerciseOnProgressPanel({
  exercise,
  started,
  auxTime,
  time,
  trainingCompleted,
  pauseTime,
  playTime,
  setAuxTime,
}: ExerciseOnProgressPanelI) {
  const [countModalVisible, setCountModalVisible] = useState<boolean>(false);

  const audioInitialCount = new Audio(initialCount);
  const audioInitialBeep = new Audio(initialBeep);
  const audioFinalBeep = new Audio(finalBeep);
  const audioFinalCount = new Audio(finalCount);

  const playInitialCount = async () => {
    audioInitialCount.play();
  };

  const playInitialBeep = async () => {
    audioInitialBeep.play();
  };

  const playFinalBeep = async () => {
    audioFinalBeep.play();
  };

  const playFinalCount = async () => {
    audioFinalCount.play();
  };

  useEffect(() => {
    setAuxTime((prev: any) => prev - 1);
  }, [time]);

  useEffect(() => {
    if (auxTime === 0 && exercise.duration) {
      playFinalBeep();
      trainingCompleted();
    } else if (auxTime === 5 && exercise.name !== 'Descanso') {
      playFinalCount();
    }
  }, [auxTime]);

  useEffect(() => {
    if (exercise?.duration && started && exercise.name !== 'Descanso') {
      pauseTime();
      playInitialCount();
      setCountModalVisible(true);
    } else if (exercise?.duration && started && exercise.name === 'Descanso') {
      pauseTime();
      playInitialBeep();
      playTime();
    }
    setAuxTime(Math.round(exercise.duration * 60));
  }, [exercise, started]);

  return (

    <CounterModal
      data-testid="exercise-onprogress"
      isVisible={countModalVisible}
      title={exercise.name}
      onFinishCount={() => {
        playInitialBeep();
        setCountModalVisible(false);
        playTime();
      }}
    />

  );
}

export default ExerciseOnProgressPanel;

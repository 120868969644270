import React from 'react';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import BaseModal, { IBaseModalProps } from '../../BaseModal';
import api from '../../../services/api';
import {
  Header, Container, ConfirmText, RemoveButton, RemoveText,
  CancelButton, CancelText, Buttons,
} from './styles';
import { Activity } from '../../../store/Activities.store';

export interface DisassociateModalProps extends IBaseModalProps {
  activity: Activity;
  callback: Function;
}

function DisassociateModal({ activity, callback, ...modalProps }: DisassociateModalProps) {
  const activityDate = format(new Date(activity.start_date), 'dd/MM');

  const updateActivity = () => {
    const updatedActivity = {
      ...activity,
      training_id: null,
      start_date: format(new Date(activity.start_date), 'yyyy-MM-dd HH:mm:ss'),
    };
    api.put(`user_activities/${activity.id}`, updatedActivity)
      .then((response) => {
        modalProps.setIsVisible?.(false);
        callback(response.data);
      })
      .catch(() => {
        toast.error('Erro ao atualizar a atividade, tente novamente.');
      });
  };

  return (
    <BaseModal {...modalProps}>
      <Container>
        <Header>Deseja desvincular a atividade?</Header>
        <ConfirmText>{`${activityDate} - ${activity.title}`}</ConfirmText>
        <Buttons>
          <RemoveButton onClick={() => updateActivity()}>
            <RemoveText>Desvincular</RemoveText>
          </RemoveButton>
          <CancelButton onClick={() => modalProps.setIsVisible?.(false)}>
            <CancelText>Cancelar</CancelText>
          </CancelButton>
        </Buttons>
      </Container>
    </BaseModal>
  );
}
export default DisassociateModal;

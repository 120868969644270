import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${appTheme.title};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Description = styled.p`
  color: ${appTheme.title};
  font-size: 14px;
  margin-bottom: 10px;
`;

export const AuxDescriptionText = styled.p`
  color: ${appTheme.title};
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
`;

import styled from 'styled-components';
import THEME from '../../theme';

export const ItemImage = styled.img`
    width: 25%;
    height: 170px;
    margin-bottom: 20px;

    @media (max-width: 667px) {
        height: 100px; 
    }
`;

export const ItemTitle = styled.p`
    color: ${THEME.colors.title};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ItemDescription = styled.p`
    color: ${THEME.colors.title};
    font-size: 14px;
    margin-bottom: 10px;
    max-width: 50%;
    text-align: center;
`;

export const ModalContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;

export const ModalButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const DismissButton = styled.button`
    color: ${THEME.colors.title};
    text-decoration: underline; 
    font-size: 12px; 
    margin-bottom: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1075;
  overflow: hidden;
  outline: 0;
  opacity: 1;
  overflow-x: hidden;
  display: block;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.1em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const ModalDialog = styled.div`
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  position: relative;
  width: auto;
  margin: 40px auto;
  max-width: 433px;
`;

interface ModalContextProps {
  height?: number;
}

export const ModalContent = styled.div<ModalContextProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000033;
  background-clip: padding-box;
  outline: 0;
`;
export const ModalBackDrop = styled.div`
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000033;
`;

interface IStyledProps {
  activePage?: number;
}

export const StyledImage = styled.div<{ imgSrc: string }>`
  height: 670px;
  width: 100%;
  background-size: cover;
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 51, 0.3) 0%, rgba(0, 0, 51, 0.6) 100%), url(${({ imgSrc }) => `"${imgSrc}"`});
  background-position: center;
`;

export const BackArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  padding-top: 20px;
  padding-left: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 5px;
  background: ${theme.colors.title};
`;

export const CloseText = styled.span`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const TitleContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: 14px;
  color: ${theme.colors.primary};
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 10px;
  padding-top: 15px;
`;

export const ContentText = styled.span`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
  color: ${theme.colors.primary};
  line-height: 20px;
`;

export const Footer = styled.div<IStyledProps>`
  display: flex;
  background-color: ${theme.colors.title};
  justify-content: ${(props) => (props.activePage === 0 ? 'flex-end' : 'space-between')};
  align-items: center;
  width: 100%;
`;

export const ControlButton = styled.button`
  background-color: ${theme.colors.title};
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
  padding-top: 15px;
  border: none;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const ControlText = styled.span`
  color: ${theme.colors.primary};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
`;

export const Container = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const ArrowTitle = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  cursor: pointer;
`;

export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  padding-top: 5px;
  margin-right: 0.2rem;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: flex;
  background: #03015B;
`;

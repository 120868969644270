import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BsClock } from 'react-icons/bs';
import { BiBody, BiInfoCircle } from 'react-icons/bi';
import { AiFillLock } from 'react-icons/ai';
import {
  loadExercises,
  getExercises,
} from '../../store/Exercises.store';
import { RootState } from '../../store';
import BackArrow from '../../components/BackArrow';
import {
  ExerciseI,
  exercisesTypes,
} from '../../types/Exercise';
import {
  Container, Info, InfoText, List, CardExercise, InfoContainer, TitleContainer, OptionsContainer, BorderCard, Select, SelectText,
} from './styles';
import theme from '../../theme';
import SlideOnboard from '../../components/SlideOnboard';
import { OnboardSlides, avaliableExercisesCategories } from '../../utils/avaliableExercisesCategories';
import nivel1 from '../../assets/Training/Nivel/Nivel1.svg';
import nivel2 from '../../assets/Training/Nivel/Nivel2.svg';
import nivel3 from '../../assets/Training/Nivel/Nivel3.svg';
import ExerciseListItemSkeleton from '../../components/ExerciseListItemSkeleton';
import { useQuery } from '../../utils';

function ExerciseList() {
  const query = useQuery();
  const trainingToAttachId = query.get('trainingToAttachId');
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { type: exerciseType } = useParams();

  const user = useSelector((state: RootState) => state.auth.user);
  const { isFree } = user;
  const [exerciseList, setExerciseList] = useState<Array<ExerciseI>>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [intensityFilter, setIntensityFilter] = useState(4);
  const options = [
    { label: 'Iniciante', value: 1 },
    { label: 'Intermediário', value: 2 },
    { label: 'Avançado', value: 3 },
    { label: 'Todos', value: 4 },
  ];

  const { courses, loading } = useSelector((state: RootState) => state.exercises);

  const [exerciseListData, setExerciseListData] = useState({
    active: true,
    type: '',
    title: '',
    description: '',
  });

  function getExerciseListData() {
    if (exerciseType) {
      const { title, description } = (avaliableExercisesCategories.find(
        (item) => item.type === exerciseType,
      ) as typeof avaliableExercisesCategories[number]);

      setExerciseListData({
        active: true,
        type: exerciseType,
        title,
        description,
      });
    } else {
      navigate('/training');
    }
  }

  useEffect(() => {
    LoadTrainingList();
  }, [exerciseListData]);

  function LoadTrainingList() {
    dispatch(loadExercises(exercisesTypes[exerciseListData.type as keyof typeof exercisesTypes]));
  }

  function updateScreenList() {
    dispatch(getExercises(exercisesTypes[exerciseListData.type as keyof typeof exercisesTypes], (exercises: Array<ExerciseI>) => {
      setExerciseList(exercises);
    }));
  }

  useEffect(() => {
    updateScreenList();
  }, [courses, exerciseListData]);

  useEffect(() => {
    if (exerciseListData.active === false) {
      navigate('/training');
    }

    getExerciseListData();
  }, []);

  function handlerItem(
    exercise: any,
  ) {
    navigate({
      pathname: `/exerciseCover/${exerciseListData.type}/${exercise.id}`,
      search: trainingToAttachId ? `?${createSearchParams({
        trainingToAttachId,
      })}` : '',
    });
  }

  const handleChangeFilter = ({ target: { value } }: any) => {
    const intensityFilter = Number(value);

    setIntensityFilter(intensityFilter);
  };

  return (
    <Container>
      <BackArrow text={exerciseListData.title} callback={() => navigate('/training')} />

      <Info>
        <InfoText
          onClick={() => setIsVisible(!isVisible)}
        >
          {exerciseListData.description}
          <span><BiInfoCircle /></span>
        </InfoText>
      </Info>
      {
        exerciseList ? (
          <List>
            {
              exerciseListData.type === exercisesTypes.WeightTraining && (
                <SelectText>
                  Nível: &nbsp;
                  <Select value={intensityFilter} onChange={handleChangeFilter}>
                    { options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>) }
                  </Select>
                </SelectText>
              )
            }
            {
              loading
          && (
            <ExerciseListItemSkeleton />
          )
            }

            {
              exerciseList
                .filter((exercise) => intensityFilter === 4 || exercise.intensity === intensityFilter)
                .map((exercise, index) => (
                  <CardExercise
                    key={index}
                    style={{ backgroundImage: `url(${exercise.cover_link})` }}
                    onClick={() => handlerItem(exercise)}

                  >
                    { exercise.intensity === 1 && <BorderCard style={{ backgroundColor: theme.colors.graph }} />}
                    { exercise.intensity === 2 && <BorderCard style={{ backgroundColor: theme.colors.notifications }} />}
                    { exercise.intensity === 3 && <BorderCard style={{ backgroundColor: theme.colors.redAlert }} />}
                    <InfoContainer>
                      <TitleContainer>
                        <h2>
                          {(isFree) && (
                            <AiFillLock />
                          )}
                          {exercise.name}
                          <span>{exercise.level}</span>
                        </h2>
                      </TitleContainer>
                      <OptionsContainer>
                        <p>
                          {exercise.intensity === 1
                            && (
                              <>
                                <img src={nivel1} alt="Level" />
                                Iniciante
                              </>
                            )}
                          {exercise.intensity === 2
                            && (
                              <>
                                <img src={nivel2} alt="Level" />
                                Intermediário
                              </>
                            )}
                          {exercise.intensity === 3
                            && (
                              <>
                                <img src={nivel3} alt="Level" />
                                Avançado
                              </>
                            )}
                        </p>
                        <p>
                          <BsClock />
                          {' '}
                          {exercise.estimated_init}
                          {' '}
                          a
                          {' '}
                          {exercise.estimated_end}
                          {' '}
                          minutos
                        </p>
                        <p>
                          <BiBody />
                          {' '}
                          {exercise.equipments}
                        </p>
                      </OptionsContainer>
                    </InfoContainer>
                  </CardExercise>
                ))
            }
          </List>
        )
          : <div />
      }
      {
        exerciseListData.type !== exercisesTypes.Others
        && (
          <SlideOnboard
            isVisible={isVisible}
            data={OnboardSlides[exerciseListData.type as keyof typeof exercisesTypes]}
            finishSlide={() => setIsVisible(false)}
          />
        )
      }
    </Container>
  );
}

export default React.memo(ExerciseList);

import React from 'react';
import { Container } from './styles';
import WeekDay from './Day';

function WeekCalendar() {
  return (
    <Container>
      {[...Array(7)].map((_, index) => (
        <WeekDay key={index} weekDayIndex={index} />
      ))}
    </Container>
  );
}

export default WeekCalendar;

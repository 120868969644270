import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container, HeaderContainer, HeaderText, PlanText, TextInfo,
} from './styles';
import { RootState } from '../../store';

function CurrentObjective() {
  const navigate = useNavigate();
  const planName = useSelector((state: RootState) => state.trainingPlan.name);
  const currentWeek = useSelector((state: RootState) => state.trainingPlan.currentWeek);
  const totalOfWeeks = useSelector((state: RootState) => state.trainingPlan.totalOfWeeks);

  return (
    <Container onClick={() => navigate('/training')}>
      <HeaderContainer>
        <HeaderText><p>Seu objetivo atual</p></HeaderText>
        <PlanText>
          <h3>{planName || '-'}</h3>
        </PlanText>
        <TextInfo>
          <p>{`Você está na semana ${currentWeek || '-'}/${totalOfWeeks || '-'} de treinos`}</p>
        </TextInfo>
      </HeaderContainer>
    </Container>
  );
}

export default CurrentObjective;

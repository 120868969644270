import React from 'react';
import { Button, ButtonText, ButtonsContainer } from './styles';

export interface IGenderButtonsProps {
  genderString: string;
  selectedGender: string | undefined;
  setSelectedGender: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function GenderButtons({ genderString, selectedGender, setSelectedGender }: IGenderButtonsProps) {
  const handleGenders = React.useCallback(() => {
    const gendersArray = genderString.split(',');
    const genders = gendersArray.map((gender) => {
      const genderObject = {
        label: gender,
        value: gender,
      };
      return genderObject;
    });
    return genders;
  }, []);

  const handleGenderSelection = (genderValue: string) => {
    setSelectedGender(genderValue);
  };

  return (
    <ButtonsContainer>
      {handleGenders().map((gender, index) => (
        <Button
          key={index}
          onClick={() => handleGenderSelection(gender.value)}
          style={{ backgroundColor: selectedGender === gender.value ? '#FFFFFF' : 'transparent' }}
        >
          <ButtonText style={{ color: selectedGender === gender.value ? '#0D0D3DFC' : '#FFFFFF' }}>
            {gender.label}
          </ButtonText>
        </Button>
      ))}
    </ButtonsContainer>
  );
}

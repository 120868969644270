import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const RowContainer = styled.div`
  display: flex;
  padding: 8px;
`;

export const MaskedInputContainer = styled.div`
  margin: 8px;
  padding-left: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

import React from 'react';
import {
  SetAlternativeTypeScreen,
  SetCurrentPracticeScreen,
  SetTrainingSessionTimeScreen,
  SetStartAtScreen,
  SetNotesScreen,
} from '../CommonScreens';
import { ITrainingRequestScreen } from '../interface';

export interface IAlternativeProps extends ITrainingRequestScreen{
  alternativeType?: string,
  setAlternativeType: React.Dispatch<React.SetStateAction<string>>,
  trainingSessionTime: string | null,
  setTrainingSessionTime: React.Dispatch<React.SetStateAction<string>>,
  currentPractice: string | undefined,
  setCurrentPractice: React.Dispatch<React.SetStateAction<string | undefined>>,
}

export default function Alternative({
  selectedSchema,
  step,
  setValueForEnableNextButton,
  currentPractice,
  setCurrentPractice,
  startAt,
  handleStartAt,
  setMHistoryNotes,
  setGeneralNotes,
  minStartAt,
  maxStartAt,
  setButtonDisabled,
  alternativeType,
  setAlternativeType,
  trainingSessionTime,
  setTrainingSessionTime,
}: IAlternativeProps) {
  const commonProps = {
    step: selectedSchema.steps[step],
    setButtonDisabled,
  };

  return (
    <>
      {selectedSchema.type === 'ALTERNATIVE' && step === 0
        && (
          <SetAlternativeTypeScreen
            {...commonProps}
            alternativeType={alternativeType}
            onClick={(value: any) => setValueForEnableNextButton(() => setAlternativeType(value))}
          />
        )}

      {selectedSchema.type === 'ALTERNATIVE' && step === 1
        && (
          <SetStartAtScreen
            {...commonProps}
            minStartAt={minStartAt}
            maxStartAt={maxStartAt}
            startAt={startAt}
            onChangeValue={(text: string) => setValueForEnableNextButton(() => handleStartAt(text))}
          />
        )}

      {selectedSchema.type === 'ALTERNATIVE' && step === 2
        && (
          <SetTrainingSessionTimeScreen
            {...commonProps}
            trainingSessionTime={trainingSessionTime}
            onClick={
              (value: any) => setValueForEnableNextButton(() => setTrainingSessionTime(value))
            }
          />
        )}

      {selectedSchema.type === 'ALTERNATIVE' && step === 3
        && (
          <SetCurrentPracticeScreen
            {...commonProps}
            currentPractice={currentPractice}
            onClick={(value: any) => setValueForEnableNextButton(() => setCurrentPractice(value))}
          />
        )}

      {selectedSchema.type === 'ALTERNATIVE' && step === 4
        && (
          <SetNotesScreen
            {...commonProps}
            onChangeHistoryNotes={
              (text: string) => setValueForEnableNextButton(() => setMHistoryNotes(text))
            }
            onChangeGeneralNotes={
              (text: string) => setValueForEnableNextButton(() => setGeneralNotes(text))
            }
          />
        )}
    </>
  );
}

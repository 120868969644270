import styled from 'styled-components';

import { appTheme } from '../../theme';

export const modalStyle = {
  overlay: {
    backgroundColor: appTheme.overlayBG,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    zIndex: '99',
  },
  content: {
    marginTop: 80,
    width: '93%',
    maxWidth: 675,
    height: 'fit-content',
    border: 'none',
    left: '50%',
    transform: 'translate(-50%)',
    background: appTheme.background,
    padding: 0,
    zIndex: '99',
  },
};

export const Form = styled.form`
  padding: 0 92px;

  @media (max-width: 667px) {
    padding: 0 15px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 30px 87px;

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  @media (max-width: 667px) {
    padding: 12px 15px;

    svg {
      margin: -9px -12px;
      width: 20px;
      height: 20px;
    }
  }
`;

export const HeaderText = styled.div`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 667px) {
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  width: 169px;
  height: 34px;
  background-color: ${appTheme.title};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const SubmitButton = styled.input`
width: 169px;
  height: 34px;
  background-color: ${appTheme.title};
  border-radius: 5px;
  text-transform: uppercase;
  color: ${appTheme.background};
  font-weight: 700;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

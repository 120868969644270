import React from 'react';
import {
  DistanceMetricTag,
  DistanceMetricTagText,
  InnerContainer, Input, Label, OuterContainer, Select,
} from './styles';

export interface IDistanceInputProps {
  kmValue: number | undefined;
  onChange: (value: number) => void;
  justKilometers?: boolean;
}

function DistanceInput({ kmValue, onChange, justKilometers }: IDistanceInputProps) {
  const [distanceType, setDistanceType] = React.useState<'m' | 'km'>('km');
  const [innerValue, setInnerValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (kmValue === undefined) {
      setInnerValue(undefined);
      return;
    }

    const valueToDisplay = distanceType === 'km' ? kmValue : kmValue * 1000;
    setInnerValue(valueToDisplay.toString());
  }, [kmValue]);

  React.useEffect(() => {
    if (innerValue === undefined) return;

    const parsedValue = parseFloat(innerValue);
    const kmValueToStore = distanceType === 'km' ? parsedValue : parsedValue / 1000;
    onChange(kmValueToStore);
  }, [innerValue, distanceType]);

  function handleDistanceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = event.target.value.replace(',', '.');
    const parsedValue = parseFloat(sanitizedValue);
    if (parsedValue > 999) return;

    setInnerValue(sanitizedValue);
  }

  function handleDistanceTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setDistanceType(event.target.value as 'm' | 'km');
  }

  return (
    <OuterContainer>
      <Label>Distância</Label>
      <InnerContainer>
        <Input
          type="number"
          value={innerValue || ''}
          onChange={handleDistanceChange}
          placeholder="00,00"
        />

        {
          (justKilometers && (
            <DistanceMetricTag>
              <DistanceMetricTagText>
                quilômetros
              </DistanceMetricTagText>
            </DistanceMetricTag>
          )) || (
            <Select
              onChange={handleDistanceTypeChange}
              value={distanceType}
            >
              <option value="km">quilômetros</option>
              <option value="m">metros</option>
            </Select>
          )
        }

      </InnerContainer>
    </OuterContainer>
  );
}

export default DistanceInput;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.section`
  border-radius: 10px;
  background-color: ${theme.colors.cardB2};
  padding: 8px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

export const ActivityName = styled.span`
  color: ${theme.colors.title};
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const NameContainer = styled.span`
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-wrap: wrap;
`;

export const MetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
 
`;

export const DistanceContainer = styled.span`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

`;

export const PaceContainer = styled.span`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
 
`;

export const TimeContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  
`;

export const MetricText = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${theme.colors.title};
  font-size: 15px;
`;

export const MetricName = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.text};
  font-size: 12px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  background: ${theme.colors.gray};
  margin: 5px;
  border-radius: 30px;
`;

export const AvatarContainer = styled.section`
  display: flex;
  align-items: flex-start;
`;

export const UserContainer = styled.span`
  flex-direction: column;
`;

export const UserName = styled.span`
  color: ${theme.colors.title};
`;

export const DateText = styled.span`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.text};
  font-size: 11px;
`;

export const ShoesBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ShoesBadgeImage = styled.img`
  background-color: ${theme.colors.primary};
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  max-width: 25px;
  height: 25px;
`;

export const ShoesBadge = styled.span`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.title};
  font-size: 12px;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 2px;
  height: 25px;
`;

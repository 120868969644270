import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import BackArrow from '../../components/BackArrow';
import PaceCalculatorDimmedIcon from '../../assets/club/pace-calculator-dimmed.svg';
import PolygonIcon from '../../assets/progress/polygon.svg';

import {
  Container,
  ContentContainer,
  ActionButton,
  ButtonTitle,
  FormContainer,
  ButtonsContainer,
  ResultContainer,
  ResultData,
  ResultDistance,
  ResultTitle,
  ResultValue,
  ContainerSVG,
  WhiteActionButton,
  WhiteButtonTitle,
  FooterButtonsContainer,
  Header,
  ContentContainerText,
  TextButton,
  ErrorText,
  TitleBlock,
  Title,
  Card,
} from './styles';
import CalculatorsContext from './Context/CalculatorsContext';
import { CalculatorStep, CalculatorType } from './Context/types';
import PaceInput from '../../components/SpecificInputs/PaceInput';
import TimeInput from '../../components/SpecificInputs/TimeInput';
import DistanceInput from '../../components/SpecificInputs/DistanceInput';
import { SecondsToMaskedPace, SecondsToMaskedTime } from '../../utils/TimeHelpers';
import { RootState } from '../../store';
import { AdBanner } from '../RaceCalendar/types';
import coreApi from '../../services/coreApi';

interface CalcProps {
  showNav: boolean;
}

export default function PaceCalculator({ showNav }: CalcProps) {
  const { currentActiveCalculator, calculatorStep } = React.useContext(CalculatorsContext).flowData;
  const token = useSelector((state: RootState) => state.auth.token);

  const {
    distance, pace, time, error,
  } = React.useContext(CalculatorsContext).logicData;
  const {
    setDistance, setPace, setTime, setCurrentActiveCalculator, calculate, reset: resetContextData,
  } = React.useContext(CalculatorsContext).actions;
  const {
    resultDistance, resultPace, resultTime, resultVelocity,
  } = React.useContext(CalculatorsContext).resultData;

  const [adBanner, setAdbanner] = React.useState<AdBanner>();

  React.useEffect(() => {
    let url = 'advertising/type/calculator';

    if (token) url += '/auth';

    coreApi.get(url)
      .then((response) => {
        setAdbanner(response.data);
      });
  }, []);

  React.useEffect(() => {
    resetContextData();
  }, []);

  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          data-react-helmet="true"
          content="Pace, velocidade ou ritmo. Calcule seu ritmo de corrida com facilidade. Nossa calculadora de pace de corrida ajuda você a planejar e monitorar seu desempenho. Experimente agora!"
        />
        <title>
          Calculadora de Ritmo
        </title>
      </Helmet>
      <TitleBlock>
        {showNav && token && <BackArrow text="" bigger />}
        <Title>
          Calculadora de Ritmo
        </Title>
      </TitleBlock>

      <Header>
        <img src={PaceCalculatorDimmedIcon} alt="Ícone" />
      </Header>

      {(calculatorStep === CalculatorStep.Input && (
        <ButtonsContainer>
          <ActionButton selected={currentActiveCalculator === CalculatorType.Time} onClick={() => setCurrentActiveCalculator(CalculatorType.Time)}>
            <ButtonTitle>Tempo</ButtonTitle>
            {currentActiveCalculator === CalculatorType.Time && (
              <ContainerSVG>
                <img src={PolygonIcon} alt="Aba" />
              </ContainerSVG>
            )}
          </ActionButton>
          <ActionButton selected={currentActiveCalculator === CalculatorType.Pace} onClick={() => setCurrentActiveCalculator(CalculatorType.Pace)}>
            <ButtonTitle>Pace</ButtonTitle>
            {currentActiveCalculator === CalculatorType.Pace && (
              <ContainerSVG>
                <img src={PolygonIcon} alt="Aba" />
              </ContainerSVG>
            )}
          </ActionButton>
          <ActionButton selected={currentActiveCalculator === CalculatorType.Distance} onClick={() => setCurrentActiveCalculator(CalculatorType.Distance)}>
            <ButtonTitle>Distância</ButtonTitle>
            {currentActiveCalculator === CalculatorType.Distance && (
              <ContainerSVG>
                <img src={PolygonIcon} alt="Aba" />
              </ContainerSVG>
            )}
          </ActionButton>
        </ButtonsContainer>
      ))}

      <ContentContainer transparent={calculatorStep === CalculatorStep.Result}>
        {adBanner && <a target="blank" href={adBanner.action_url}><Card alt={adBanner.title} src={adBanner.image} /></a>}
        <ContentContainerText>
          {
            (currentActiveCalculator === CalculatorType.Pace && (
              'Preencha distância e tempo para calcular o Pace'
            ))
            || (currentActiveCalculator === CalculatorType.Time && (
              'Preencha distância e pace para calcular o Tempo'
            ))
            || (currentActiveCalculator === CalculatorType.Distance && (
              'Preencha pace e tempo para calcular a Distância'
            ))
          }

        </ContentContainerText>
        <FormContainer>
          {currentActiveCalculator !== CalculatorType.Distance && (
            <DistanceInput
              kmValue={distance}
              onChange={(distance) => setDistance(distance)}
            />
          )}

          {currentActiveCalculator !== CalculatorType.Pace && (
            <PaceInput
              value={pace}
              onChange={(pace) => setPace(pace)}
            />
          )}

          {currentActiveCalculator !== CalculatorType.Time && (
            <TimeInput
              valueInSeconds={time}
              onChange={(time) => setTime(time)}
            />
          )}
        </FormContainer>

        {error && (
          <ErrorText>
            {error}
          </ErrorText>
        )}

        <FooterButtonsContainer>
          <WhiteActionButton onClick={calculate}>
            <WhiteButtonTitle>{calculatorStep === CalculatorStep.Input ? 'Calcular' : 'Calcular Novamente'}</WhiteButtonTitle>
          </WhiteActionButton>

          {calculatorStep === CalculatorStep.Result && (
            <TextButton onClick={resetContextData}>
              <ButtonTitle>Voltar</ButtonTitle>
            </TextButton>
          )}

        </FooterButtonsContainer>
      </ContentContainer>

      {(calculatorStep === CalculatorStep.Result && (
        <ResultContainer>
          <ResultDistance>
            <ResultValue>
              {resultDistance}
              KM
            </ResultValue>
          </ResultDistance>

          <ResultData>
            <ResultTitle>
              Tempo
            </ResultTitle>
            <ResultValue>
              {SecondsToMaskedTime(resultTime as number)}
            </ResultValue>
          </ResultData>

          <ResultData>
            <ResultTitle>
              Pace
            </ResultTitle>
            <ResultValue>
              {SecondsToMaskedPace(resultPace as number)}
              /Km
            </ResultValue>
          </ResultData>

          <ResultData>
            <ResultTitle>
              Velocidade
            </ResultTitle>
            <ResultValue>
              {resultVelocity}
              km/h
            </ResultValue>
          </ResultData>
        </ResultContainer>
      ))}
    </Container>
  );
}

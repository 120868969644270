import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const InfoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;

  p {
    color: ${theme.colors.title};
    margin-left: 10px;
  }
`;
export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const CoachContainer = styled.div`
  flex-direction: column;
`;

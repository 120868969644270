import React from 'react';
import { format } from 'date-fns';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  Card, InfoContainer, Title, DateText, IconButton, TextIconButton,
} from './style';

import { IRecordedLiveLectureDetails } from '../RecordedClasses';

export interface IRecordedClassItem {
  recordedClass: IRecordedLiveLectureDetails

}

export default function RecordedClassItem({ recordedClass } : IRecordedClassItem) {
  const navigate = useNavigate();
  const date = recordedClass.date != null ? format(new Date(recordedClass.date), 'dd-MM-yyyy') : recordedClass.date;
  return (
    <Card data-testid="recorded-class-card-container">
      <InfoContainer>
        <Title>{recordedClass.title}</Title>
        <DateText>{`Data: ${date}`}</DateText>
      </InfoContainer>
      <IconButton onClick={() => navigate({
        pathname: '/club/RecordedClassesExecution',
        search: `?${createSearchParams({
          id: recordedClass.id.toString(),
          name: recordedClass.name || '',
          title: recordedClass.title || '',
        })}`,
      })}
      >
        <BsFillPlayCircleFill size={15} color="white" />
        <TextIconButton>Assistir</TextIconButton>
      </IconButton>
    </Card>
  );
}

import styled from 'styled-components';

import { appTheme } from '../../../theme';

interface ThumbnailPropsI {
  imageUrl: string;
  showBorder: boolean;
}

export const ThumbnailContainer = styled.div<ThumbnailPropsI>`
  background-color: ${appTheme.background};
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  border: ${(props) => (props.showBorder ? '' : 'unset')} 8px dashed  ${appTheme.cardB3};

  .rnd-style {
    height: 100px;
    width: 300px;
    background-image: url(${(props) => (props.imageUrl)});
    background-size: cover;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  background-color: unset;
  cursor: pointer;
  width: 170px;
  border: solid;
  border-radius: 5px;
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 15px;
`;

export const InfoText = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: ${appTheme.title};
  font-size: 14px;
  align-self: flex-start;
  font-weight: 600;
  margin-bottom: 10px;
  margin: 20px 0
`;

import styled from 'styled-components';
import { appTheme } from '../../../theme';

export const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 85%;
    background-color: ${appTheme.background};
    padding: 15px 100px;
`;

export const ModalContentTitle = styled.p`
    color: ${appTheme.title};
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
`;

export const ModalContentSubTitleContainer = styled.div`
    margin: 50px 0;
`;

export const ModalContentSubTitle = styled.p`
    color: ${appTheme.title};
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
`;

export const ModalContentButton = styled.button`
  cursor: pointer;
  background-color: unset;
  width: 50%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: solid 1px;
  border-color: ${appTheme.title};
`;

export const ModalContentButtonText = styled.p`
    color: ${appTheme.title};
    font-size: 16px;
    text-transform: uppercase;
`;

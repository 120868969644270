import { Link } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import InputMask from 'react-input-mask';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 100px;
  width: 100%;
  align-items: center;

  @media (max-width: 724px) {
    padding: 20px 25px;
  }
`;

export const RaceTitle = styled.span`
  font-size: 16px;
  color: ${theme.colors.title};
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;

  @media (max-width: 724px) {
    font-size: 14px;
  }
`;

export const RaceTitle2 = styled(RaceTitle)`
  font-size: 18px;
  margin-bottom: 20px;
  color: ${theme.colors.secondary};
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"]::-moz-number-spin-box {
    display: none;
  }

  input[type="number"]::-ms-inner-spin-button {
    display: none;
  }
`;

const inputBaseStyle = (): CSSObject => ({
  backgroundColor: theme.colors.cardB3,
  width: '100%',
  height: 45,
  border: 'none',
  padding: 10,
  color: theme.colors.title,
  fontSize: 16,
  borderRadius: 5,
  '::placeholder': {
    color: theme.colors.title,
    fontSize: 16,
  },
});

export const Input = styled.input`
  ${inputBaseStyle}
`;

export const MaskedInput = styled(InputMask)`
  ${inputBaseStyle}
`;

export const SignInButton = styled.div`
  background-color: ${theme.colors.secondary};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 45px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.colors.black};
  transition: 0.3s;
  margin-top: 20px;
`;

export const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.title};
  margin-top: 50px;

  @media (max-width: 724px) {
    margin-top: 25px;
  }
`;

export const SignUpLink = styled.a`
  color: ${theme.colors.title};
  margin-top: 50px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 724px) {
    margin-top: 25px;
  }
`;

export const InputLabel = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;

export const HiddenCouponValue = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${theme.colors.gray};
  font-weight: 500;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;

  @media (max-width: 724px) {
    font-size: 16px;
  }
`;

export const CouponButton = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  background-color: ${theme.colors.primary2};
  cursor: pointer;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 40px 0;
  font-size: 18px;
`;

export const CouponCap = styled.div`
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 40px;
  flex: 1;
  display: flex;
  align-items: center;
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 18px;
  padding: 6px;
  position: relative;
  z-index: 1;
  justify-content: center;
  
  @media (max-width: 724px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const CouponCapEffect = styled.div`
  background-color: white;
  width: 200%;
  height: 500px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: rotate(-20deg) translateY(-50%);

  @media (max-width: 724px) {
    width: 300%;
  }

  @media (max-width: 400px) {
    width: 350%;
  }
`;

export const LoginButton = styled.div`
  color: ${theme.colors.title};
  font-size: 17px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;

  svg {
    margin-left: 10px;
    transition: transform 0.3s;
  }

  &.invert-arrow {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const LoginDrawerContainer = styled.div`
  width: 100%;
  opacity: 0;
  height: 0;
  transition: height 0.3s, opacity 0.3s;
  transition-delay: 0.3s, 0s;

  
  &.visible {
    opacity: 1;
    height: 200px;
    transition-delay: 0s, 0.3s;
  }
`;

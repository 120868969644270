import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  background-color: ${theme.colors.cardB3};
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  user-select: none;

  @media (max-width: 667px) {
    padding: 8px;
  }
`;

export const Header = styled.header`
  text-align: start;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: ${theme.colors.title};

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const Content = styled.div`
  text-align: start;
  color: ${theme.colors.text};
  font-size: 0.8rem;
`;

export const EventNameContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  width: 100%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;

`;

export const TextContainer = styled.div`
  padding: 5px;
  margin-bottom: 10px;
`;

export const TextInfo = styled.p`
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    color: ${theme.colors.title};
    font-weight: normal;
    font-weight: 500;
    @media (min-width: 768px) {
        font-size: 14px;
    }
`;

export const TimeInfoContainer = styled.div`
  display: flex;
  margin-top: 18px;
  align-items: center;
  align-self: center;
`;

export const TimeInfoText = styled.p`
  color: ${theme.colors.title};
  margin-left: 8px;
`;

export const Button = styled.div`
  margin: 13px;
  max-width: 80%;
  background-color: ${theme.colors.title};
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  align-self: center;

  :hover {
    cursor: pointer;
  }
`;

export const ButtonText = styled.p`
  color: ${theme.colors.primary};
  font-weight: 600;
  font-size: smaller;
  text-transform: uppercase;
  text-align: center;
`;

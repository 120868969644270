import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineClose } from 'react-icons/md';
import Loader from '../../components/Loader';
import api from '../../services/api';
import {
  Button,
  ButtonContainer,
  Container,
  IconButton,
  LoadingContainer,
  NoDataContainer,
  NoDataText,
  TextButton,
  RetroImageContainer,
} from './styles';

function MonthRetrospective() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [retroImageUrl, setRetroImageUrl] = useState<string>();

  const navigation = useNavigate();

  const backPage = () => {
    navigation('/progress');
  };

  const fetchRetroData = async () => {
    setLoading(true);

    try {
      const today = new Date();
      const url = `month_retrospective?full_year=${today.getFullYear()}&month_number=${today.getMonth()}`;
      const response = await api.get(url, {
        responseType: 'blob',
      });

      const blob = response.data;

      const reader = new FileReader();

      reader.onload = () => {
        const dataUrl = reader.result;
        setRetroImageUrl(dataUrl as string);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  const downloadImage = useCallback(() => {
    if (retroImageUrl) {
      const link = document.createElement('a');
      link.href = retroImageUrl;
      link.download = 'RetrospectivaCP.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [retroImageUrl]);

  useEffect(() => {
    fetchRetroData();
  }, []);

  return (
    <Container>
      {loading && !retroImageUrl && (
        <LoadingContainer>
          <Loader color="white" />
        </LoadingContainer>
      )}

      {retroImageUrl && !loading && (
        <RetroImageContainer
          src={retroImageUrl}
          alt="Imagem da retrospectiva"
        />
      )}

      {!loading && !retroImageUrl && !error && (
        <NoDataContainer>
          <NoDataText>Nenhum dado de retrospectiva encontrado</NoDataText>
        </NoDataContainer>
      )}

      {!loading && error && (
        <NoDataContainer>
          <NoDataText>
            Houve algum problema ao tentar obter sua retrospectiva, tente
            novamente mais tarde.
          </NoDataText>
        </NoDataContainer>
      )}

      <IconButton onClick={backPage}>
        <MdOutlineClose color="white" size={30} />
      </IconButton>

      {!loading && !error && retroImageUrl && (
        <ButtonContainer>
          <Button onClick={downloadImage}>
            <TextButton>Baixar</TextButton>
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
}

export default MonthRetrospective;

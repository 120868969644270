import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${appTheme.cardB2};
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;

  @media (max-width: 667px) {
    margin-bottom: 8px;
    padding: 8px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;

  svg{
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  @media (max-width: 667px) {
    margin-bottom: 8px;
  }
`;

export const HeaderText = styled.div`
  color: ${appTheme.title};
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 667px) {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const LinkText = styled.div`
  color: ${appTheme.title};
  text-decoration: underline;
  
  font-size: 13px;
  margin: 5px; 
  text-decoration-color: white;
  cursor: pointer;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;

export const ContainerItem = styled.div`
  background-color: ${appTheme.cardB3};
  padding: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  margin: 3px;
`;

export const ActivityText = styled.div`
  color: ${appTheme.title};
  font-size: 13px;
  margin-left: 5px;
`;

export const SyncActivitiesContainer = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SyncActivitiesText = styled.div`
  color: ${appTheme.title};
  font-size: 11px;
  text-align: center;
  margin-bottom: 10px;
`;

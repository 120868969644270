import styled from 'styled-components';
import theme from '../../theme';

interface Props {
  bigger?: boolean
}

export const Container = styled.section` 
    width: 100%;
`;

export const BackArrow = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    cursor: pointer;
`;

export const Title = styled.span<Props>`
    color: ${theme.colors.title};
    font-size: ${(props) => (props.bigger ? 14 : 12)}px;
    font-weight: bold;
    font-style: normal;    
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: 14px;
    }
    cursor: pointer;
`;

export const Button = styled.button`
    background-color: ${theme.colors.primary2};
    border: none;
    padding-top:5px;
    margin-right: .2rem;
    cursor: pointer;
`;

export const Text = styled.h5`
    width: 100%;
    padding-left: 10px;
    padding-top: 15px;
    color: ${theme.colors.title};
    font-weight: normal;
    font-weight: 500;
    @media (min-width: 768px) {
        font-size: 14px;
    }
`;

export const GroupButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    width: 250px;    
    padding: 10px;
    background-color: ${theme.colors.title};
    border-radius: 5px;    
    cursor: pointer;
    margin: 15px auto;

`;

export const ButtonTitle = styled.span`
    color: ${theme.colors.cardB2};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
`;

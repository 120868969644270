import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card, InfoContainer, Header, CardInfo, EditButton,
} from './style';

export enum PROFILE_FIELD {
  INFORMACOES_PESSOAIS = 'informacoes-pessoais',
  DADOS_DA_CONTA = 'dados-da-conta',
  FINANCEIRO = 'financeiro',
  INTEGRACOES = 'integracoes',
  MEUS_TENIS = 'meus-tenis',
  DETALHES_INTEGRACAO = 'detalhes-integracao'
}

export default function ProfileCard({
  header,
  info,
  field,
}: {
  header: string;
  info: string;
  field: PROFILE_FIELD;
}) {
  const navigate = useNavigate();

  const goToProfileEdit = () => {
    navigate(`/profile/edit?field=${field}`);
  };

  return (
    <Card onClick={goToProfileEdit}>
      <InfoContainer>
        <Header>{header}</Header>

        <CardInfo>{info}</CardInfo>
      </InfoContainer>
      <EditButton onClick={goToProfileEdit}>Editar</EditButton>
    </Card>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Modal from '../AccountModal';

import api from '../../services/api';
import { RootState } from '../../store';
import { logout, updateProfileProps } from '../../store/Auth.store';
import Loader from '../Loader';
import {
  Card, CardTitle, TInput, Input, InputLabel, ButtonContainer, SaveButton, SaveButtonTitle, RemoveAccountButton, RemoveContainer, MessageLabel,
} from './style';
import { requestSubscription } from '../../store/Subscription.store';

export default function AccountData() {
  const profile = useSelector((state: RootState) => state.auth.user);
  const [newPassword, setNewPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isSubscriptionConfirmationModal, setIsSubscriptionConfirmationModal] = useState(false);

  const dispatch = useDispatch();
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription,
  );

  useEffect(() => {
    setPhone(profile.phone ? profile.phone : '');
  }, []);

  useEffect(() => {
    dispatch(requestSubscription() as any);
  }, []);

  const dispatchLogout = () => {
    dispatch(logout());
  };

  const clearPassword = () => {
    setNewPassword('');
    setNewPasswordConfirmation('');
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModal((isOpen) => !isOpen);
  };

  const toggleSubscriptionConfirmationModal = () => {
    setIsSubscriptionConfirmationModal((isOpen) => !isOpen);
  };

  const handleUpdateData = async () => {
    try {
      if (!newPassword && !newPasswordConfirmation) {
        toast.error('Preencha todos os campos!');
        return;
      }

      if (newPassword !== newPasswordConfirmation) {
        toast.error('As senhas não conferem, corrija!');
        return;
      }

      const payload = {
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      };

      setLoading(true);

      await api.put(`/users/${profile.id}`, payload);

      setLoading(false);

      clearPassword();

      toast('Senha alterada com sucesso!');
    } catch (error) {
      setLoading(false);

      toast.error('Ocorreu um erro ao alterar sua senha, tente novamente!');
    }
  };

  const handleUpdatePhone = async () => {
    try {
      if (!phone) {
        toast.error('Preencha todos os campos!');
        return;
      }

      const payload = {
        phone,
      };

      setLoadingPhone(true);

      await api.put(`/users/${profile.id}`, payload);

      dispatch(updateProfileProps(payload) as any);

      setLoadingPhone(false);

      toast('Telefone atualizado com sucesso!');
    } catch (error) {
      setLoadingPhone(false);

      toast.error('Ocorreu um erro ao atualizar seu telefone, tente novamente!');
    }
  };

  const removeAccount = async () => {
    await api.delete(`/delete-user?userId=${profile.id}`)
      .then(() => {
        toast(
          'Sua conta foi removida com sucesso!',
        );
        dispatchLogout();
      })
      .catch(() => {
        toast.error('Não foi possível excluir a conta, tente novamente.');
      });
  };

  return (
    <>
      <Modal
        isOpen={isConfirmationModal}
        toggle={toggleConfirmationModal}
        onClick={removeAccount}
        title="Excluir conta"
        info="Deseja mesmo excluir sua conta?"
        confirmText="Excluir"
      />
      <Modal
        isOpen={isSubscriptionConfirmationModal}
        toggle={toggleSubscriptionConfirmationModal}
        onClick={removeAccount}
        title="Você ainda possui período de adesão pago em sua assinatura vigente"
        info="Ao excluir sua conta, você perderá esse período."
        confirmText="Excluir"
      />

      <Card data-testid="account-container">
        <CardTitle>DADOS DA CONTA</CardTitle>

        <InputLabel>Email</InputLabel>
        <Input
          value={profile.email}
          readOnly
        />
        <MessageLabel>
          *Para alterar o email de sua conta, faça contato com nosso time de assinaturas, no menu ajuda.
        </MessageLabel>
        <br />
        <br />

        <InputLabel>Telefone</InputLabel>
        <TInput
          mask={phone.length > 10 ? phone.length > 11 ? '+99 (99) 9.9999-9999' : '(99) 9.9999-99999' : '(99) 9999-99999'}
          onChange={(e) => {
            setPhone(e.target.value.replace(/\D/g, ''));
          }}
          value={phone}
          type="tel"
          placeholder="(00) 0.0000-0000"
          disabled={loading}
          maskChar={null}
        />
        <ButtonContainer>
          <SaveButton onClick={handleUpdatePhone}>
            <SaveButtonTitle>Atualizar Telefone</SaveButtonTitle>
            {loadingPhone && <Loader />}
          </SaveButton>
        </ButtonContainer>

        {!profile.social_login && (
          <>
            <InputLabel>Nova senha</InputLabel>
            <Input
              type="password"
              placeholder="Sua nova senha"
              value={newPassword}
              onChange={({ currentTarget: { value } }) => setNewPassword(value)}
            />

            <InputLabel>Confirmação da nova senha</InputLabel>
            <Input
              type="password"
              value={newPasswordConfirmation}
              onChange={
                ({ currentTarget: { value } }) => setNewPasswordConfirmation(value)

              }
            />

            <ButtonContainer>
              <SaveButton onClick={handleUpdateData}>
                <SaveButtonTitle>SALVAR</SaveButtonTitle>
                {loading && <Loader />}
              </SaveButton>
            </ButtonContainer>
          </>
        )}
      </Card>
      <RemoveContainer>
        <RemoveAccountButton onClick={subscription?.status === 'active'
          ? toggleSubscriptionConfirmationModal
          : toggleConfirmationModal}
        >
          Excluir Conta

        </RemoveAccountButton>
      </RemoveContainer>
    </>
  );
}

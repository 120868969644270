import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  padding: 13px; 
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 5px;
`;

export const Title = styled.p`
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }
`;

export const Description = styled.p`
  color: ${theme.colors.notifications};
  font-weight: bold;
  font-size: 13px; 
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TitleContainer = styled.div`
  margin-left: 5px;
`;

export const CouponContainer = styled.div`
  display: flex;
  width: 70%;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const Coupon = styled.p`
  color: ${theme.colors.text};
  padding: 8px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CouponTextContainer = styled.div`
  background-color: ${theme.colors.cardB3};
  width: 55%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 65%;
  }
`;

export const CouponButton = styled.button`
  width: 45%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: ${theme.colors.cardB1};
  font-weight: bold;
  border: none;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 35%;
  }
`;

export const LinkButton = styled.button`
  margin: 8px;
  padding: 6px;
  background-color: ${theme.colors.cardB2};
  border: none;
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  font-size: 12px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

import React from 'react';
import { HiPause } from 'react-icons/hi';

import theme from '../../theme';
import { Border, Container, Button } from './styles';

interface PropsI {
  onPress: () => void
}

function PauseButton({ onPress } : PropsI) {
  return (
    <Container onClick={onPress}>
      <Border />
      <Button>
        <HiPause size={25} color={theme.colors.title} style={{ opacity: 0.8 }} />
      </Button>
    </Container>
  );
}

export default PauseButton;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  format, subMonths, addMonths, startOfMonth, endOfMonth,
} from 'date-fns';
import toast from 'react-hot-toast';

import { ITrainingOfDay, ICalendarDayTraining } from '../types/TrainingPlan';
import { AppThunk } from '.';

import api from '../services/api';

interface ITrainingCalendarStore {
  events: ITrainingOfDay[],
  loading: boolean,
  error: any,
  currentLoadedMonth: number,
}

const initialState = {
  events: [],
  loading: true,
  error: null,
  currentLoadedMonth: 0,
} as ITrainingCalendarStore;

const store = createSlice({
  name: 'trainingCalendar',
  initialState,
  reducers: {
    setEventsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEventsSuccess: (state, action: PayloadAction<ITrainingOfDay[]>) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    },
    setEventsFailure: (state, action) => {
      state.events = [];
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentLoadedMonth: (state, action: PayloadAction<number>) => {
      state.currentLoadedMonth = action.payload;
    },
    setEventsEmpty: (state) => {
      state.events = [];
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  setEventsStart, setEventsSuccess, setEventsFailure, setCurrentLoadedMonth, setEventsEmpty,
} = store.actions;

export default store.reducer;

export const newRequestEventsData = (date: Date): AppThunk => async (dispatch, getState) => {
  dispatch(setEventsStart());

  try {
    const startDate = format(startOfMonth(subMonths(date, 1)), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(addMonths(date, 1)), 'yyyy-MM-dd');

    const { data } = await api.get(`calendar_user_training_plans/${getState().auth.user.id}?start_date=${startDate}&end_date=${endDate}`);

    const trainingsOfDays: ITrainingOfDay[] = [];

    data.days.forEach((dayTraining: ICalendarDayTraining) => {
      trainingsOfDays.push(...dayTraining.trainingsOfDay);
    });

    dispatch(setCurrentLoadedMonth(date.getMonth()));
    dispatch(setEventsSuccess(trainingsOfDays ?? []));
  } catch (error: any) {
    toast.error('Houve algum erro ao carregar seus treinos do mês.');
    dispatch(setEventsFailure(error?.message ?? JSON.stringify(error)));
  }
};

export const updateMonth = (date: Date): AppThunk => async (dispatch, getState) => {
  if (getState().trainingCalendar.currentLoadedMonth !== date.getMonth()) { dispatch(newRequestEventsData(date)); }
};

export const clearEvents = (): AppThunk => async (dispatch) => {
  dispatch(setEventsEmpty());
};

export function TruncateDecimalPlaces(number: number, decimalPlaces: number) {
  const factor = 10 ** decimalPlaces;

  return Math.floor(number * factor) / factor;
}

export function SecondsToMaskedPace(dateTime: number) {
  const minutes = Math.floor(dateTime / 60);
  const seconds = Math.floor(dateTime % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function SecondsToMaskedTime(dateTime: number) {
  const hours = Math.floor(dateTime / 3600);
  const minutes = Math.floor((dateTime % 3600) / 60);
  const seconds = Math.floor(dateTime % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function ParseMinutesOrSeconds(value: string) {
  const parsedValue = parseInt(value, 10);
  if (Number.isNaN(parsedValue)) return undefined;
  if (parsedValue > 59) return 59;
  if (parsedValue < 0) return 0;

  return parsedValue;
}

export function ParseHours(value: string) {
  const parsedValue = parseInt(value, 10);
  if (Number.isNaN(parsedValue)) return undefined;
  if (parsedValue > 99) return 99;
  if (parsedValue < 0) return 0;

  return parsedValue;
}

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StyledThumb } from './styles';

function Thumb(props: any, state: any) {
  return (
    <StyledThumb {...props} index={state.index}>{state.valueNow}</StyledThumb>
  );
}

export default Thumb;

import React, { useEffect, useState } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import BackArrow from '../BackArrow';
import LiveClassItem from '../LiveClassItem';
import api from '../../services/api';
import {
  Container, DetailContainer, HeaderContainer, Info, LinkText, LinkTextButton,
  Title, LiveButton, LiveButtonText, ButtonContainer, RecordedClassesContainer,
} from './style';
import { useQuery } from '../../utils';
import Loader from '../Loader';
import LiveClassInfoModal from '../LiveClassInfoModal';
import RecordedClassesInfoModal from '../RecordedClassesInfoModal';

export interface ILiveLectureDetails {
  id: number;
  description: string | null;
  level: string;
  when_to_do: string;
  equipments: string;
  day: string | null;
  zoom_link: string;
}

export default function LiveClassDetails() {
  const query = useQuery();
  const id = query.get('id');
  const [loading, setLoading] = useState(true);
  const [classDetails, setClassDetails] = useState<ILiveLectureDetails | null>(null);
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [recordedInfoModal, setRecordedInfoModal] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    api.get(`live_lectures/${id}`)
      .then((response) => {
        if (response) {
          setClassDetails(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const toggleInfoModal = () => {
    setInfoModal((isOpen) => !isOpen);
  };

  const toggleRecordedInfoModal = () => {
    setRecordedInfoModal((isOpen) => !isOpen);
  };

  return (
    <Container>
      <LiveClassInfoModal
        isOpen={infoModal}
        toggle={toggleInfoModal}
        confirmText="ok"
      />
      <HeaderContainer>
        <BackArrow text="AGENDA DE TREINOS AO VIVO" bigger backTo="/club/live-classes/schedule" />
        <LinkTextButton onClick={toggleInfoModal}>
          <IoMdInformationCircleOutline size={20} color="white" />
          <LinkText>Como funciona</LinkText>
        </LinkTextButton>
      </HeaderContainer>
      {loading ? <Loader /> : classDetails != null ? (
        <>
          <LiveClassItem liveClass={classDetails} detail />
          <DetailContainer>
            {classDetails.description && (
              <>
                <Title>Descrição:</Title>
                <Info>{classDetails.description}</Info>
              </>
            )}
            {classDetails.level && (
              <>
                <Title>Nível:</Title>
                <Info>{classDetails.level}</Info>
              </>
            )}
            {classDetails.when_to_do && (
              <>
                <Title>Quando fazer:</Title>
                <Info>{classDetails.when_to_do}</Info>
              </>
            )}
            {classDetails.equipments && (
              <>
                <Title>Equipamentos:</Title>
                <Info>{classDetails.equipments}</Info>
              </>
            )}
            {classDetails.day && (
              <>
                <Title>Transmissão ao vivo:</Title>
                <Info>{classDetails.day}</Info>
              </>
            )}
          </DetailContainer>
        </>
      ) : 'Erro! Por favor, atualize a página e tente novamente.'}
      <ButtonContainer>
        {classDetails?.zoom_link && (
          <LiveButton onClick={(e) => {
            e.preventDefault();
            window.location.href = classDetails?.zoom_link;
          }}
          >
            <LiveButtonText>acesso à sala do zoom com o professor</LiveButtonText>
          </LiveButton>
        )}

      </ButtonContainer>
      <RecordedClassesContainer>
        <RecordedClassesInfoModal
          isOpen={recordedInfoModal}
          toggle={toggleRecordedInfoModal}

        />
        <LinkTextButton onClick={toggleRecordedInfoModal}>
          <LinkText>Não poderá estar ao vivo no horário?</LinkText>
        </LinkTextButton>
      </RecordedClassesContainer>
    </Container>
  );
}

import React, { useEffect } from 'react';
import { IStep } from '../..';
import { appTheme } from '../../../../theme';
import FormInput from '../../../FormInput';
import {
  Content, Description, SelectedText, Title,
} from './styles';

export interface ISetStartAtScreenProps {
  step: IStep;
  minStartAt: Date;
  maxStartAt: Date;
  startAt?: Date;
  onChangeValue: (value: string) => void;
  setButtonDisabled: (value: boolean) => void;
}

function SetStartAtScreen({
  step, minStartAt, maxStartAt, startAt, onChangeValue, setButtonDisabled,
}: ISetStartAtScreenProps) {
  useEffect(() => {
    if (startAt) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [startAt]);

  return (
    <>
      <Title>{step.title}</Title>
      <Content>
        <SelectedText>{step.selectText}</SelectedText>
        <FormInput
          style={{
            backgroundColor: appTheme.cardB3,
            padding: 10,
            borderRadius: 5,
            marginBottom: 8,
            color: appTheme.gray,
            width: '100%',
            minHeight: 40,
            fontSize: 14,
          }}
          placeholder="00/00/0000"
          maxDate={maxStartAt}
          minDate={minStartAt}
          inputValue={startAt?.toDateString() || ''}
          type="date"
          onChange={(e) => onChangeValue(e.target.value)}
        />
      </Content>

      <Description>{step.description}</Description>
    </>
  );
}

export default SetStartAtScreen;

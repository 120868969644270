import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  background: ${appTheme.cardB1};
  flex: 1;
  padding: 3%;
`;

export const TextContainer = styled.div`
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TextInfo = styled.p`
  color: ${appTheme.title};
  margin-bottom: 5px;
`;

export const TextLinkContainer = styled.div`
  display: flex;
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LinkButtonText = styled.p`
  text-decoration: underline;
  color: ${appTheme.title};
  font-size: 13px;
  margin-left: 5px;
  text-decoration-color: white;
`;

export const NewTestButton = styled.button`
  background-color: ${appTheme.title};
  width: 100%;
  border-radius: 5px;
  align-items: center;
  align-self: center;
  padding: 10px;
  cursor: pointer;
`;

export const NewTextButtonText = styled.p`
  color: ${appTheme.background};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
`;

import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.p`
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${appTheme.title};
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const Description = styled.p`
    color: ${appTheme.title};
    font-size: 14px;
    margin-bottom: 30px;
`;

export const List = styled.div`
    width: 100%;
`;

export const Item = styled.button`
    cursor: pointer;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${appTheme.cardB3};
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const Radio = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: ${appTheme.title};
    padding: 5px;
`;

export const RadioContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 15%;
`;

export const RadioInner = styled.div`
    height: 10px;
    width: 10px;
`;

export const Label = styled.p`
    color: ${appTheme.title};
    font-weight: bold;
    margin-left: 10px;
`;

export const LabelContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 85%;
`;

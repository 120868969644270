import React from 'react';
import { startOfWeek, format, addDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';
import {
  DayName,
  Container,
  TrainingIconsContainer,
  FutureDayIcon,
  SmallDate,
  PastDayIcon,
  PastDayIconInnerContent,
} from './styles';
import { RootState } from '../../../../store';
import { ICalendarResult } from '../../../../store/CalendarProgress.store';
import Run from '../../../../assets/progress/Run.svg';
import WeightTraining from '../../../../assets/progress/WeightTraining.svg';
import Drills from '../../../../assets/progress/Drills.svg';
import RunGreen from '../../../../assets/progress/RunGreen.svg';
import WeightTrainingGreen from '../../../../assets/progress/WeightTrainingGreen.svg';
import DrillsGreen from '../../../../assets/progress/DrillsGreen.svg';
import RunDimmed from '../../../../assets/progress/RunDimmed.svg';
import WeightTrainingDimmed from '../../../../assets/progress/WeightTrainingDimmed.svg';
import DrillsDimmed from '../../../../assets/progress/DrillsDimmed.svg';

interface IWeekDayProps {
  weekDayIndex: number;
}

interface ITrainingIconData {
  hasRun: boolean;
  hasWeightTraining: boolean;
  hasDrills: boolean;
  runRegular: boolean;
  weightTrainingRegular: boolean;
  drillsRegular: boolean;
}

function parseTrainingIconData(trainingType: ICalendarResult): ITrainingIconData {
  const results = {
    hasRun: false,
    hasWeightTraining: false,
    hasDrills: false,
    runRegular: false,
    weightTrainingRegular: false,
    drillsRegular: false,
  };

  trainingType.trainings.forEach((training) => {
    switch (training.type) {
      case 'Run':
        if (!results.hasRun) results.hasRun = true;
        if (training.regular && !results.runRegular) results.runRegular = true;
        break;
      case 'WeightTraining':
        if (!results.hasWeightTraining) results.hasWeightTraining = true;
        if (training.regular && !results.weightTrainingRegular) results.weightTrainingRegular = true;
        break;
      case 'Drills':
        if (!results.hasDrills) results.hasDrills = true;
        if (training.regular && !results.drillsRegular) results.drillsRegular = true;
        break;
      default:
    }
  });

  return results;
}

const width = window.innerWidth;
const trainingIconSize = width > 667 ? 35 : 20;
const iconsProps = { height: trainingIconSize, width: trainingIconSize, style: { marginBottom: 8 } };

type TrainingType = 'Run' | 'Drills' | 'WeightTraining';

type IconsType = {
  [key in TrainingType]: {
    default: string;
    regular: string;
    dimmed: string;
  };
};

const IconsMap: IconsType = {
  Run: { default: Run, regular: RunGreen, dimmed: RunDimmed },
  Drills: { default: Drills, regular: DrillsGreen, dimmed: DrillsDimmed },
  WeightTraining: { default: WeightTraining, regular: WeightTrainingGreen, dimmed: WeightTrainingDimmed },
};

function toCamelCase(str: string): string {
  if (!str) return str;

  return str.charAt(0).toLowerCase() + str.slice(1);
}

const getTrainingIcon = (type: TrainingType, trainingIconData: ITrainingIconData, isToday: boolean) => {
  const IconMapForType = IconsMap[type as keyof IconsType];

  if (isToday && trainingIconData[`has${type}` as keyof ITrainingIconData]) {
    const Icon = IconMapForType.default;
    return <img alt="Ícone do treino" src={Icon} {...iconsProps} />;
  }

  if (trainingIconData[`has${type}` as keyof ITrainingIconData]) {
    const IconKey = `${toCamelCase(type)}Regular` as keyof ITrainingIconData;
    const IconComponent = trainingIconData[IconKey]
      ? IconMapForType.regular
      : IconMapForType.dimmed;
    return <img alt="Ícone do treino" src={IconComponent} {...iconsProps} />;
  }

  return null;
};

const weekStartsOnCorrectionIndex = 1;

function WeekDay({ weekDayIndex }: IWeekDayProps) {
  const thisDayIsPast = weekDayIndex + weekStartsOnCorrectionIndex < new Date().getDay();
  const thisDayIsToday = weekDayIndex + weekStartsOnCorrectionIndex === new Date().getDay();

  const start = startOfWeek(new Date(), { weekStartsOn: weekStartsOnCorrectionIndex });
  const weekDay = addDays(start, weekDayIndex);
  const formattedWeekDay = format(weekDay, 'iiiiii', { locale: ptBR });

  const calendarData = useSelector((state: RootState) => state.calendarData.calendarData);

  const weekDayData = React.useMemo(() => {
    if (calendarData.result) {
      return calendarData.result.find((dataStart: ICalendarResult) => format(addDays(new Date(dataStart.start_date), 1), 'yyyy-MM-dd') === format(weekDay, 'yyyy-MM-dd'));
    }

    return undefined;
  }, [calendarData]);

  const trainingIconData = React.useMemo(() => {
    if (weekDayData) {
      return parseTrainingIconData(weekDayData);
    }

    return undefined;
  }, [weekDayData]);

  return (
    <Container isToday={thisDayIsToday}>
      {trainingIconData && (
        <TrainingIconsContainer>
          {getTrainingIcon('Run', trainingIconData, thisDayIsToday)}
          {getTrainingIcon('Drills', trainingIconData, thisDayIsToday)}
          {getTrainingIcon('WeightTraining', trainingIconData, thisDayIsToday)}
        </TrainingIconsContainer>
      )}

      {!trainingIconData?.hasRun && !trainingIconData?.hasDrills && !trainingIconData?.hasWeightTraining && (
        thisDayIsPast ? (
          <PastDayIcon>
            <PastDayIconInnerContent>x</PastDayIconInnerContent>
          </PastDayIcon>
        ) : (
          <FutureDayIcon />
        )
      )}

      <DayName isToday={thisDayIsToday}>
        {thisDayIsToday ? 'HOJE' : formattedWeekDay}
      </DayName>

      {thisDayIsToday && (<SmallDate>{format(new Date(), 'dd/MM')}</SmallDate>)}
    </Container>
  );
}

export default WeekDay;

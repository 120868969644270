import React from 'react';
import {
  InfoText, Header,
  ModalContentButtonText,
  ModalContent,
  ModalContentButtonContainer,
  ModalContentButton,
  TextInfo,
  TextHelp,
} from './styles';
import {
  ModalDialog,
  ModalBackDrop,
  ModalContainer,
} from '../Modal/Modal.styles';

export default function ProgressModal({
  isOpen, toggle,
} : any) {
  const openHelp = () => {
    window.open('https://ajuda.corridaperfeita.com/support/solutions/articles/47001238697-n%C3%ADvel-de-regularidade');
  };
  return (
    isOpen && (
      <>
        <ModalContainer>
          <ModalDialog>
            <ModalContent>
              <Header> Acompanhe sua regularidade!</Header>

              <InfoText>
                Esta função te ajuda a acompanhar e manter a sua regularidade nos treinos, semana a semana.
              </InfoText>
              <InfoText>
                1. A cada semana sendo regular de treinos, você conquista um nível. O nível máximo (6), você mantém sendo regular em 6 ou mais semanas sem falhas;
              </InfoText>
              <InfoText>
                2. Ao passar uma semana sem cumprir os requisitos de regularidade, você zera seu nível e começa tudo novamente.
              </InfoText>
              <Header>
                O QUE É UMA SEMANA REGULAR?
              </Header>
              <InfoText>
                Uma semana é considerada regular quando você realiza ao menos 3 treinos em dias distintos, sendo ao menos 2 treinos de corrida. São considerados treinos com duração superior a 15 minutos ou 3 km.
              </InfoText>
              <InfoText>
                Seus resultados, principalmente para sua saúde, são conquistados com sua regularidade. Treine e registre suas atividades para acompanhar e se motivar com seu progresso.
              </InfoText>

              <ModalContentButtonContainer>
                <ModalContentButton onClick={toggle}>
                  <ModalContentButtonText>ok</ModalContentButtonText>
                </ModalContentButton>
                <TextInfo
                  onClick={openHelp}
                >
                  <TextHelp>
                    Veja mais detalhes
                  </TextHelp>
                </TextInfo>
              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}

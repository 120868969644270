import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { appTheme } from '../../../../theme';
import {
  Container, Description, List, Title,
  Item, Label, LabelContainer, Radio, RadioContainer, RadioInner,
} from './styles';

export interface ItemSetKeepFitConditionScreenProps {
  step: IStep,
  keepFitCondition: string | null,
  onClick: (value: string) => void,
  distance: number,
  setButtonDisabled: (bool: boolean) => void,
}

function SetKeepFitConditionScreen({
  step, keepFitCondition, onClick, distance, setButtonDisabled,
}: ItemSetKeepFitConditionScreenProps) {
  useEffect(() => {
    if (keepFitCondition) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [keepFitCondition]);

  return (
    <Container>
      <Title>{step.title}</Title>

      {
        step.data.map((option: IStepData) => {
          if (option.distance === distance) {
            return (
              <Description key={option.distance}>{option.requirement}</Description>
            );
          }

          return null;
        })
      }

      <Description>{step.selectText}</Description>

      <List>
        <Item
          onClick={() => onClick('yes')}
          style={{ borderColor: `${keepFitCondition === 'yes' ? appTheme.gray : appTheme.cardB3}` }}
        >
          <RadioContainer>
            <Radio
              style={{
                backgroundColor: keepFitCondition === 'yes' ? appTheme.title : appTheme.cardB3,
              }}
            >
              <RadioInner
                style={{
                  backgroundColor: keepFitCondition === 'yes' ? appTheme.title : appTheme.cardB3,
                }}
              />
            </Radio>
          </RadioContainer>

          <LabelContainer>
            <Label>SIM</Label>
          </LabelContainer>
        </Item>
        <Item
          onClick={() => onClick('no')}
          style={{ borderColor: `${keepFitCondition === 'no' ? appTheme.gray : appTheme.cardB3}` }}
        >
          <RadioContainer>
            <Radio
              style={{
                backgroundColor: keepFitCondition === 'no' ? appTheme.title : appTheme.cardB3,
              }}
            >
              <RadioInner
                style={{
                  backgroundColor: keepFitCondition === 'no' ? appTheme.title : appTheme.cardB3,
                }}
              />
            </Radio>
          </RadioContainer>

          <LabelContainer>
            <Label>NÃO</Label>
          </LabelContainer>
        </Item>
      </List>
    </Container>
  );
}

export default SetKeepFitConditionScreen;

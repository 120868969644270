import styled from 'styled-components';

import theme from '../../theme';

export const Card = styled.section`
  display: flex;
  border-radius: 10px;
  background: ${theme.colors.cardB3};
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const InfoContainer = styled.span`
  width: 60%;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  font-size:12px;
  color: ${theme.colors.title};
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 17px;

  @media (min-width: 668px) {
        font-size: 14px;
    }
`;

export const CancelTitle = styled.span`
  display: flex;
  flex-direction:column;
  font-size:12px;
  color: ${theme.colors.title};
  font-weight: 500;
  margin-bottom: 5px;
  text-decoration-line: line-through;
  line-height: 17px;
  @media (min-width: 668px) {
        font-size: 14px;
    }
`;

export const DateText = styled.span`
  font-size: 10px;
  color: ${theme.colors.title};
  line-height: 17px;
  @media (min-width: 668px) {
        font-size: 12px;
    }
`;

export const DateCancelText = styled.span`
  display: flex;
  font-size: 10px;
  flex-direction: column;
  color: ${theme.colors.title};
  text-decoration-line: line-through;
  line-height: 17px;
  @media (min-width: 668px) {
        font-size: 12px;
    }
`;

export const StatusText = styled.span`
 color: ${theme.colors.title};
 font-size: 10px;
 @media (min-width: 668px) {
        font-size: 12px;
    }
`;

export const PointsContainer = styled.div`
  align-items: center;
  padding: 8px;
  background-color: ${theme.colors.cardB3};
`;

export const Text = styled.span`
  color:  #FFC700;
  text-decoration-line: underline;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 700;
  line-height: 17px;
`;
export const CancelText = styled.span`
  color:  #FFC700;
  text-decoration-line: line-through;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 700;
  line-height: 17px;
`;

export const InfoContent = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
`;

export const StatusContent = styled.div`
 display: flex;
 align-items: center;
`;

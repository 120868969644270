import styled from 'styled-components';
import theme from '../../theme';

export const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.cardB1};
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  padding-bottom: 25px;
 
`;

export const Header = styled.span`
 
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.title};
  margin-top: 10px;
  margin-bottom: 18px;
  font-size: 15px;
  font-weight: 500;
`;

export const InfoText = styled.span`
  
  padding-top: 15px;
  color: ${theme.colors.title};
  text-align: center;
  font-size: 13px;
  margin-bottom: 18px;
`;

export const OkButton = styled.button`
  border: '2px';
  border-style: solid;
  border-color: ${theme.colors.title};
  background-color: ${theme.colors.cardB1};
  width: 40%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 6px;
  margin-bottom: 10px;
  height: 30px;
  @media (max-width: 667px) {
    height: 40px;
    width: 50%;
  }
`;

export const OkButtonText = styled.span`

  color: ${theme.colors.title};
  padding: 5px;
  text-transform: uppercase;
  font-weight: 500;
  
`;

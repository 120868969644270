import styled from 'styled-components';
import { appTheme } from '../../../theme';

export const Container = styled.div`
  width: 100%;
  background-color: ${appTheme.cardB1};
`;

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    cursor: pointer;
    border: unset;
  }
`;

export const LinkButton = styled.button`
  background-color: unset;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: center;
`;

export const LinkButtonText = styled.p`
  color: ${appTheme.title};
  text-decoration: underline;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
`;

export const MetricValue = styled.p`
  color: white;
  text-align: center;
  font-size: 17px;
`;

export const MetricTitle = styled.p`
  color: white;
  text-align: center;
  font-size: 17px;
`;

export const ShareContainer = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const MetricHeader = styled.p`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-size: 14px;
  align-self: flex-start;
  font-weight: 600;
  margin-bottom: 10px;
`;

interface RadioButtonProps {
  selected: boolean;
}

export const RadioButton = styled.button<RadioButtonProps>`
  background-color: unset;
  cursor: pointer;
  height: 40px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: ${appTheme.cardB3};
  display: flex;
  border: solid ${(props) => (props.selected ? '1px' : '0px')};
  border-color: white;
`;

export const RadioText = styled.p`
  text-transform: uppercase;
  color: ${appTheme.title};
  font-weight: 600;
`;

export const RadioButtonIcon = styled.div`
  height: 15px;
  width: 15px;
  border: 1px solid;
  border-radius: 12px;
  border-width: 1.5px;
  border-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const RadioButtonCheck = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 12px;
  background-color: white;
`;

interface ThumbnailPropsI {
  imageUrl: string;
}

export const Thumbnail = styled.div<ThumbnailPropsI>`
  background-image: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,0.5) 100%), url(${(props) => (props.imageUrl)});
  background-size: cover;
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: calc((100vh * 4.5) / 10)
`;

export const LogoImage = styled.img`
  width: 120px;
  height: 70px;
  position: absolute;
  top: 0px;
`;

export const MetricsContainer = styled.div`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 20px;
`;

export const ChooseImageBtn = styled.button`
  background-color: unset;
  text-decoration: underline;
  color: ${appTheme.title};
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

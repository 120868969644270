import React, { Dispatch, SetStateAction } from 'react';
import {
  ModalContent,
  ModalContentTitle,
  ModalContentSubTitle,
  ModalContentButton,
  ModalContentButtonText,
  ModalContentSubTitleContainer,
} from './styles';
import BaseModal from '../../BaseModal';

interface ILastRecordDisclaimerModalProps {
  isVisible: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedDistance: number | null;
  requiredLastRecordDistance?: number;
}

function LastRecordDisclaimerModal({
  isVisible, setShowModal, selectedDistance, requiredLastRecordDistance,
}: ILastRecordDisclaimerModalProps) {
  return (
    <BaseModal isVisible={isVisible}>
      <ModalContent>
        <ModalContentTitle>
          Experiências Anteriores
        </ModalContentTitle>

        <ModalContentSubTitleContainer>
          <ModalContentSubTitle>
            {`Para realizar um treino para ${selectedDistance}km de forma segura, é importante que você já tenha completado ao menos ${requiredLastRecordDistance}km nos últimos 30 dias, além de experiência na distância. Por isso o preenchimento do campo de desempenho é obrigatório.`}
            {' '}
            A segurança é nossa prioridade, e a progressão inadequada no treinamento pode levar a lesões. Por isso, recomendamos que entre em contato com seu treinador para esclarecer melhor seus objetivos.
          </ModalContentSubTitle>
        </ModalContentSubTitleContainer>

        <ModalContentButton onClick={() => setShowModal(false)}>
          <ModalContentButtonText>OK</ModalContentButtonText>
        </ModalContentButton>
      </ModalContent>
    </BaseModal>
  );
}

export default LastRecordDisclaimerModal;

import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { AppThunk } from '.';
import api from '../services/api';

const events = createSlice({
  name: 'events',
  initialState: {
    events: [],
    loading: true,
    error: null,
  },
  reducers: {
    getEventsStart: (state) => {
      state.events = [];
      state.loading = true;
      state.error = null;
    },
    getEventsSuccess: (state, action) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    },
    getEventsFailure: (state, action) => {
      state.events = [];
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getEventsStart, getEventsSuccess, getEventsFailure } = events.actions;

export default events.reducer;

export const requestEvents = (): AppThunk => async (dispatch) => {
  dispatch(getEventsStart());

  try {
    const today = format(new Date(), 'yyyy-MM-dd');

    const response = await api.get(`live_lectures?clone_type=SCHEMA&date=${today}&obj_type=LECTURE&isRecord=false`);

    dispatch(getEventsSuccess(response?.data?.data ?? []));
  } catch (error: any) {
    dispatch(getEventsFailure(error?.message ?? JSON.stringify(error)));
  }
};

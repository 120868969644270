import React from 'react';
import ContentLoader from 'react-content-loader';
import theme from '../../theme';
import { Container } from './style';

export default function ActivitiesListSkeleton() {
  return (
    <Container data-testid="activities-list-skeleton-container">
      <ContentLoader
        speed={2}
        width={360}
        height={160}
        viewBox="0 0 360 160"
        backgroundColor={theme.colors.cardB2}
        foregroundColor={theme.colors.gray}
        uniqueKey="puyuka-aria"
      >
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="178" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="350" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    </Container>
  );
}

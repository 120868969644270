import styled from 'styled-components';
import { appTheme } from '../../../theme';

export const AssociationContainer = styled.div`
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items:  center;
  background-color: ${appTheme.cardB2};
  padding-left: 10px;
  padding-right: 10px;
`;

export const TrainingAssociatedContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const TrainingInfo = styled.div`
  margin-left: 5px;
`;

export const NameContainer = styled.button`
  display: flex;
  align-items: center;
  width: 90%;
  cursor: pointer;
  background-color: unset;
  border: unset;
`;

export const TrainingName = styled.p`
  color: ${appTheme.title};
  font-weight: 600;
  font-size: 12px;
  text-align: start;
  width: 130px;
`;

export const TrainingMetric = styled.p`
  color: ${appTheme.text};
  font-size: 13px;
`;

export const UnlinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 45%;
`;

export const UnlinkButton = styled.button`
  margin-left: 5px;
  cursor: pointer;
  background-color: unset;
  border: unset;
`;

export const UnlinkText = styled.p`
  color: ${appTheme.redAlert};
  font-size: 11px;
  text-decoration: underline;
  text-decoration-color: red;
`;

export const OutContainer = styled.div`
  border-radius: 10px;
  background-color: ${appTheme.cardB2};
`;

export const TrainingDescriptionContainer = styled.div`
  padding: 0 0 20px 10px;
`;

export const ActivityInfoContainer = styled.div``;

export const DescriptionContainer = styled.div`
  margin-left: 20px;
  margin-top: 5px;
  align-self: flex-start;
  display: flex;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  color: ${appTheme.text};
`;

interface ZoneTextProps {
  margin?: number;
}

export const ZoneText = styled.p<ZoneTextProps>`
  color: ${appTheme.grayD1};
  font-size: 12px;
  margin-left: ${(props) => (props.margin ? `${props.margin}px` : '5px')};
`;

interface ActivityInfoTextProps {
  margin?: number;
}

export const ActivityInfoText = styled.p<ActivityInfoTextProps>`
  color: ${appTheme.text};
  margin-left: ${(props) => (props.margin ? `${props.margin}px` : '5px')};
`;

export const DescContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
`;

export const DescText = styled.p`
  color: ${appTheme.text};
  margin-left: 5px;
`;

export const RunDescContainer = styled.div`
  align-items: center;
  margin-top: 10px;
`;

export const RunDescText = styled.p`
  font-weight: 500;
  color: ${appTheme.text};
`;

export const LineContainer = styled.div`
  display: flex;
`;

export const RunDescWidthContainer = styled.div`
  align-items: flex-start;
  width: 80%;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.section`
  display: block;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 5px;
  padding: 10px;
  background-color: ${theme.colors.primary3};
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
`;

export const CardTitle = styled.section`
  color: ${theme.colors.gray};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const InputLabel = styled.span`
  color: ${theme.colors.title};
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;

export const Input = styled.input`
  color: ${theme.colors.gray};
  font-size: 13px;
  margin-bottom: 10px;
  background-color: #2f2f58;
  border-radius: 5px;
  height: 35px;
  padding: 0;
  padding-left: 5px;
  border: none;
  display: block;
  width: 100%;
`;

export const InputGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InputGroup = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2f2f58;
  min-height: 35px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const InputAddon = styled.span`
  color: ${theme.colors.gray};
  font-size: 14px;
  margin-left: 2px;
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 10px;
  background-color: ${theme.colors.title};
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;

`;

export const SaveButtonTitle = styled.span`
  color: #2f2f58;
  font-size: 14px;
  font-weight: bold;
  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const Select = styled.select`
  margin-bottom: 10px;
  background-color: #2f2f58;
  border-radius: 5px;
  min-height: 35px;
  border: none;
  color: ${theme.colors.gray};
  cursor: pointer;
`;
export const Option = styled.option`
  color: ${theme.colors.gray};
  font-size: 12px;
`;

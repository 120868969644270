import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
    cursor: pointer;
    width: 100%;
`;

export const Item = styled.div`
    border-radius: 10px;
    overflow: hidden;   
    min-height: 130px;
    margin: 1%;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${theme.colors.cardB2};
`;

export const ItemContent = styled.div`
  padding: 3%;
  padding-right: 0;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: ${theme.colors.title};
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 9px;

  @media (max-width: 667px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const Description = styled.p`
  color: ${theme.colors.title};
  font-weight: 500;
  margin-right: 10%;
  font-size: 14px; 
  margin-bottom: 20px;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const ContentView = styled.div`
  width: 100%;
`;

export const ContentImage = styled.div`
  width: 100%;
  min-height: 200px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  @media (max-width: 667px) {
    min-height: 0;
  }
`;

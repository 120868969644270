import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { TiChevronRight } from 'react-icons/ti';
import { RootState } from '../../store';
import FreeUserModal from '../FreeUserModal';

import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';
import {
  ImageContainer, ItemButton, Title, InfoContainer, Image, IconContainer,
} from './styles';
import { Avatar } from '../Items/style';

interface HelpItemProps {
  content: {
    image: string,
    title: string,
    desc: string,
    screen?: any,
    onPress?: string,
    showInPersonal?: boolean,
    showInFree?: boolean,
    blockedInFree?: boolean,
    showAvatar?: boolean,
  };

}

export default function HelpItem({
  content,
}: HelpItemProps) {
  const auth = useSelector((state: RootState) => state.auth);
  const { user, coach } = auth;
  const [freeModal, setFreeModal] = useState(false);
  const navigate = useNavigate();
  const { isFree } = user;

  const handleClick = () => {
    if (content.screen) {
      navigate(content.screen);
    } if (content.onPress) {
      window.open(content.onPress);
    }
  };

  const handleClickFree = () => {
    if (content.screen === 'CoachAttendance') {
      setFreeModal((isOpen) => !isOpen);
    } if (content.onPress) {
      window.open(content.onPress);
    } if (content.screen === 'tecnical-suport') {
      navigate(content.screen);
    }
  };

  const toggleModal = () => {
    setFreeModal((isOpen) => !isOpen);
  };
  return (
    <>
      <ItemButton
        onClick={isFree ? handleClickFree : handleClick}
        data-testid="help-item"
      >
        <ImageContainer>
          {coach && content.showAvatar
            ? <Avatar src={coach.s_photo ? coach.s_photo : ''} />
            : <Image src={content.image} alt={content.title} data-testid="image" />}
        </ImageContainer>
        <InfoContainer>
          <Title>{content.title}</Title>
        </InfoContainer>
        <IconContainer>
          <TiChevronRight size={40} color="white" />
        </IconContainer>
      </ItemButton>
      <FreeUserModal
        isModalOpen
        isVisible={freeModal}
        text="O suporte de treinadores está disponível apenas para membros do Corrida Perfeita."
        link={freeUserRedirectLinks.help}
        toggle={toggleModal}
      />
    </>
  );
}

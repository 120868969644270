import React from 'react';
import { Container, ContentImage } from './styles';

interface ClassesMediaContainerI {
  url?: string
  image?: boolean
}

function ClassesMediaContainer({
  url,
  image,
}: ClassesMediaContainerI) {
  return (
    <Container data-testid="classes-media-container">
      {!image && url
        ? (
          <iframe
            title="Video"
            src={url}
            allow="fullscreen"
            frameBorder="0"
            loading="lazy"
          />
        )
        : (
          <ContentImage
            style={{ backgroundImage: `url(${url})` }}
          />
        )}
    </Container>
  );
}

export default ClassesMediaContainer;

import React from 'react';
import {
  Modal, Line, Button, ButtonContainer,
  TextButton, MessageText, HeaderContainer,
  HeaderText, Card, ContainerModal,
} from './styles';
import {
  ModalContainer, ModalDialog, ModalBackDrop, ModalContent,
} from '../Modal/Modal.styles';

interface MessageModalProps {
  title: string;
  message: string;
  closeFunc: any;
  showOKButton: boolean;
  isVisible?: boolean;
  isModalOpen: boolean;
  toggle: () => void;

}

export default function MessageModal({
  isVisible, isModalOpen, title, message, closeFunc, showOKButton = true, toggle,
}: MessageModalProps) {
  const content = () => (

    <Modal transparent={!isModalOpen}>
      <ContainerModal>
        <Card>
          <HeaderContainer>
            <HeaderText>{title}</HeaderText>
          </HeaderContainer>
          <MessageText>
            {message}
          </MessageText>

          {showOKButton
              && (
                <ButtonContainer>
                  <Line />
                  <Button
                    onClick={() => { closeFunc(false); }}
                  >
                    <TextButton> OK </TextButton>
                  </Button>
                </ButtonContainer>
              )}
        </Card>
      </ContainerModal>
    </Modal>

  );
  return isModalOpen ? (
    <>
      {isVisible && (
        <>
          <ModalContainer>
            <ModalDialog>
              <ModalContent>
                {content()}
              </ModalContent>
            </ModalDialog>
          </ModalContainer>
          <ModalBackDrop onClick={toggle} />
        </>

      )}
    </>
  ) : <>{content()}</>;
}

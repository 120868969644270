import styled from 'styled-components';

import { DayContainerProps } from '../../types';

export const DayBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DayBoxTitle = styled.div`
    text-transform: capitalize;
    color: #fff;
    font-size: 20px;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

export const MonthDayContainer = styled.div<DayContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 63px;
    height: 55px;
    border: 2px solid ${(props) => (props.isSelected ? '#fff' : '#808099')};
    border-radius: 5px;
    color: ${(props) => (props.isSelected ? '#fff' : '#808099')};
    font-size: 15px;
    cursor: pointer;
    min-width: 30px;
    min-height: 30px;

    @media (max-width: 667px) {
        width: 26px;
        height: 26px;
        border-width: 1px;
        font-size: 8px;
    }
`;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import {
  Container, Card, Logo, Title, Form, SubmitButton,
} from './styles';
import { useQuery } from '../../utils';
import FormInput from '../../components/FormInput';

export default function ResetPassword() {
  const query = useQuery();
  const navigate = useNavigate();

  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');

  const [resetSucceeded, setResetSucceeded] = useState(false);

  async function submitNewPassword() {
    try {
      const response = await api.put('passwords', {
        token: query.get('token'),
        password: pass1,
        password_confirmation: pass2,
      });

      if (response.data.error) {
        toast.error(response.data.error.msg);
        return;
      }

      toast.success(`${response.data.message.msg} Faça o login agora`);

      setResetSucceeded(true);
    } catch (error) {
      toast.error('Ocorreu um erro, contate o suporte.');
    }
  }

  function handleValidSubmit(e: React.MouseEvent<any>) {
    e.preventDefault();

    if (pass2.length < 6) {
      toast.error('A senha deve possuir no mínimo 6 caracteres.');
      return;
    }

    if (pass1 !== pass2) {
      toast.error('As senhas devem ser iguais, verifique-as.');
      return;
    }

    submitNewPassword();
  }

  return (
    <Container>

      <Card>
        <Logo src={logo} alt="logo" />

        {
          (!resetSucceeded
          && (
            <>
              <Title>Recuperar de Senha</Title>

              <Form>
                <FormInput
                  className="input"
                  label="Senha"
                  placeholder="Sua nova senha"
                  type="password"
                  inputValue={pass1}
                  onChange={(e) => setPass1(e.target.value)}
                  showPasswordToggle
                />

                <FormInput
                  className="input"
                  label="Confirmação de senha"
                  placeholder="Repita sua nova senha"
                  type="password"
                  inputValue={pass2}
                  onChange={(e) => setPass2(e.target.value)}
                  showPasswordToggle
                />

                <SubmitButton onClick={(e) => handleValidSubmit(e)}>
                  Salvar
                </SubmitButton>
              </Form>
            </>
          )
          )
            || (
              <>
                <Title>Nova senha definida com sucesso!</Title>

                <SubmitButton onClick={() => navigate('/')}>
                  Ir para o Login
                </SubmitButton>
              </>
            )
        }
      </Card>
    </Container>
  );
}

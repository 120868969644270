import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

import imgPlayer from '../../assets/club/play.svg';

export const Container = styled.div`
  background: ${theme.colors.cardB1};
  height: 100%;
  width: 100%;
  padding: 2%;
`;

export const Content = styled.div`
  background: ${theme.colors.cardB2};
  min-height: 200px;  
  width: 100%;
  margin-top: 2%;
  border-radius: 10px;
  align-self: center;
  overflow: hidden;
`;

export const Cover = styled.div`
  width: 100%;
  height: 30%;
  min-height: 140px;
`;

export const ContentImage = styled.div`
  width: 100%;
  min-height: 200px;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.p`
  color: ${theme.colors.title};
  font-size: 12px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const Info = styled.div`
  height: 100%;
  width: 67%;
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AccordionContainer = styled.div`
  width: 100%;
`;

export const ListContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    margin-bottom: 16px;
    cursor: pointer;

    &.colapsed{
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
    span{
        svg{
            margin:0;
        }
    }
`;

export const Thumb = styled.div`
    display: flex;
    align-items: center;    
    color: ${theme.colors.title};    
    width: 100%;

    p{
        font-size: 10px;
        margin: 0 8px;
        @media(min-width: 768px){
            font-size: 14px;
        }
        span{
            color: ${appTheme.iconsOff};            
        }
    }
`;

export const ContainerImgThumb = styled.div`
    position: relative;
    img{
        width: 30px;
        height: 30px;
        border-radius: 5px;
    }

    &:before{
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 30px;
        height: 30px;
        background-image: url(${imgPlayer});
        background-position: center;
        background-repeat: no-repeat;
    }

`;

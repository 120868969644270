import styled from 'styled-components';
import theme from '../../theme';

export const ItemButton = styled.span`
  display: flex;
  background-color: ${theme.colors.cardB2};
  border-radius: 5px; 
  margin-bottom: 10px;
`;

export const DateText = styled.span`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
`;

export const DateContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  padding: 5px;
  justify-content: space-around;
  background-color: ${theme.colors.cardB3};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const SeparatorV = styled.span`
  display: block;
  background-color: ${theme.colors.title};
  width: 4px;
  height: 100%;
`;

export const Container = styled.section`
  display: flex;
  flex: 1;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  cursor:pointer;
`;

export const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  display: flex;
  text-transform: uppercase;
  color: ${theme.colors.title};
  font-size: 15px;
  margin-left: 5px;
  font-weight: 500;
  width: 80%;
`;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  background-color: ${theme.colors.cardB1};
`;

export const ListItemContainer = styled.button`
  width: 100%;
  display: flex;
  margin-top: 18px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  height: 60px;
`;

export const IconContainer = styled.div`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${theme.colors.cardB3};
  margin-right: 3px;
  padding: 2px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  height: 50px;
  width: 50px;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${theme.colors.cardB2};
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  height: 100%;
`;

export const ItemName = styled.p`
  color: ${theme.colors.title};
  font-size: 14px;
  text-transform: uppercase;
  width: 80%;

  @media (max-width: 667px) {
    font-size: 12px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.title};
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
`;

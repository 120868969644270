import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Run from '../../../../assets/progress/Run.svg';
import WeightTrainingun from '../../../../assets/progress/WeightTraining.svg';
import Drills from '../../../../assets/progress/Drills.svg';
import {
  Card, CardFooter, CardNumber, CardTitle, ContainerCards,
} from '../../WeekCalendar/Cards/styles';

const width = window.innerWidth;
const cardIconSize = width > 667 ? 27 : 20;

export default function Cards() {
  const calendarData = useSelector((state: RootState) => state.calendarData.calendarData);

  return (
    <ContainerCards>
      <Card>
        <CardNumber>
          {calendarData.weights}
        </CardNumber>

        <CardFooter>
          <img src={WeightTrainingun} alt="Ícone treino força" style={{ height: cardIconSize, width: cardIconSize }} />
          <CardTitle>
            Treinos de força
          </CardTitle>
        </CardFooter>
      </Card>

      <Card>
        <CardNumber>
          {calendarData.drills}
        </CardNumber>

        <CardFooter>
          <img src={Drills} alt="Ícone treino educativo" style={{ height: cardIconSize, width: cardIconSize }} />
          <CardTitle>
            Treinos de educativo
          </CardTitle>
        </CardFooter>
      </Card>

      <Card>
        <CardNumber>
          {calendarData.runs}
        </CardNumber>

        <CardFooter>
          <img src={Run} alt="Ícone treino corrida" style={{ height: cardIconSize, width: cardIconSize }} />
          <CardTitle>
            Treinos de corrida
          </CardTitle>
        </CardFooter>
      </Card>
    </ContainerCards>
  );
}

/* eslint-disable import/prefer-default-export */
export function formatDuration(duration: number, type: string | null = null) {
  let hoursStr = '';
  let minutesStr = '';
  let secondsStr = '';

  let hours = 0;
  let minutes = 0;
  let sec = 0;

  try {
    if (duration && duration > 0) {
      if (duration >= 60) {
        hours = duration / 60;
        minutes = (hours % 1) * 60;
      } else {
        minutes = duration;
      }
      sec = (minutes % 1) * 60;

      hoursStr = hours < 10 ? `0${Math.floor(hours)}` : `${Math.floor(hours)}`;
      minutesStr = minutes < 10 ? `0${Math.floor(minutes)}` : `${Math.floor(minutes)}`;
      secondsStr = sec < 10 ? `0${Math.floor(sec)}` : `${Math.floor(sec)}`;

      switch (type) {
        case 'time':
          return `${hoursStr}:${minutesStr}:${secondsStr}`;
        case 'pace':
          return `${minutesStr}:${secondsStr}`;
        default: {
          if (hours > 0) {
            return `${hoursStr}h ${minutesStr}m`;
          }
          return `${minutesStr}m ${secondsStr}s`;
        }
      }
    }
  } catch (error) {
    // console.log('|||||||||||||| [formatDuration] error: ', error);
  }

  return '--';
}

import React, { useEffect } from 'react';
import { IStep } from '../..';
import { appTheme } from '../../../../theme';
import FormInput from '../../../FormInput';
import {
  Content, Description, DescriptionContainer, SelectedText, Title,
} from './styles';

export interface ISetTargetDateScreenProps {
  step: IStep;
  targetDate?: Date;
  minTargetDate: Date;
  maxTargetDate: Date;
  onChangeValue: (value: string) => void;
  minWeek: Number;
  maxWeek: Number;
  setButtonDisabled: (value: boolean) => void;
  showStepDescription?: boolean;
  description?: string;
}

function SetTargetDateScreen({
  step, targetDate, minTargetDate, maxTargetDate, onChangeValue,
  minWeek, maxWeek, setButtonDisabled, showStepDescription: hasDescription, description,
}: ISetTargetDateScreenProps) {
  useEffect(() => {
    if (targetDate) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [targetDate]);

  return (
    <>
      <Title>{step.title}</Title>

      <Content>
        <SelectedText>{step.selectText}</SelectedText>
        <FormInput
          style={{
            backgroundColor: appTheme.cardB3,
            padding: 10,
            borderRadius: 5,
            marginBottom: 8,
            color: appTheme.gray,
            width: '100%',
            minHeight: 40,
            fontSize: 14,
          }}
          placeholder="00/00/0000"
          maxDate={maxTargetDate}
          minDate={minTargetDate}
          inputValue={targetDate?.toDateString() || ''}
          type="date"
          onChange={(e) => onChangeValue(e.target.value)}
          required
        />

        {
          description
          && <Description>{description}</Description>
        }

        {
          hasDescription
          && (
            <>
              <SelectedText>{step.description}</SelectedText>

              <DescriptionContainer>
                <Description>
                  <>
                    No mínimo
                    {' '}
                    {minWeek || 0}
                    {' '}
                    semanas
                  </>
                </Description>

                <Description>
                  <>
                    No máximo
                    {' '}
                    {maxWeek || 0}
                    {' '}
                    semanas
                  </>
                </Description>
              </DescriptionContainer>
            </>
          )
        }
      </Content>
    </>
  );
}

export default SetTargetDateScreen;

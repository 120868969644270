import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { Container, IconContainer, Text } from './style';
import theme from '../../theme';

interface PersonalUserCardProps {
  text: string;
}

export default function PersonalUserCard({ text }: PersonalUserCardProps) {
  return (
    <Container>
      <IconContainer>
        <AiOutlineCalendar size={25} color={theme.colors.title} />
      </IconContainer>
      <Text>{ text }</Text>
    </Container>
  );
}

import styled from 'styled-components';
import ReactSlider from 'react-slider';
import theme from '../../theme';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

export const StyledThumb = styled.div<any>`
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.cardTxt};
    color: ${theme.colors.cardB2};
    font-weight: 600;
    cursor: grab;
    line-height: 100px;
    border-radius: 7px;

    @media (max-width: 667px) {
        font-size: 12px;
        height: 30px;
    }
`;

export const StyledTrack = styled.div<any>`
    top: 18px;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? theme.colors.cardTxt : theme.colors.cardB2)};
    height: 5px;
    border-radius: 7px;

    ${(props) => props.index === 2 && 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;'}
    ${(props) => props.index === 0 && 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;'}

    @media (max-width: 667px) {
        top: 13px;
    }
`;

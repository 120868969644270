import React, { useState } from 'react';
import { format } from 'date-fns';

import { ptBR } from 'date-fns/locale';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import THEME from '../../../../../theme';
import MenuNotification from '../../MenuNotification';

import {
  IcoCircle, ContentIcon, CardHeader, CardHeaderDate, CardOptions, CardOptionsButton, CardContent, CardTitle, CardText, Card, TextDate,
} from './styles';
import { INotification } from '../../../../../store/Notifications.Store';

interface ICardComponentProps {
  notification: INotification,
  handleDeleteCard: (notification: INotification) => void,
  handleMarkAsRead: (notification: INotification) => void,
  handleCardSelect: (notification: INotification) => void,
  handleMarkAllAsViewed: () => void,
}

function CardComponent({
  notification,
  handleDeleteCard,
  handleMarkAsRead,
  handleCardSelect,
  handleMarkAllAsViewed,
} : ICardComponentProps) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Card key={notification._id}>
      <CardHeader>
        <CardHeaderDate>
          {
            notification.viewed
              ? (
                <TextDate>
                  {notification.created_at && format(new Date(notification.created_at), 'dd \'de\' MMMM', { locale: ptBR })}
                </TextDate>
              )
              : (
                <ContentIcon>
                  <IcoCircle />
                  <TextDate>Não lida</TextDate>
                </ContentIcon>
              )
          }
        </CardHeaderDate>
        <CardOptions>
          <CardOptionsButton onClick={() => setShowMenu(true)}>
            <MdOutlineMoreHoriz color={THEME.colors.title} size={20} />
          </CardOptionsButton>
        </CardOptions>
      </CardHeader>
      <CardContent onClick={() => handleCardSelect(notification)}>
        <CardTitle>{notification.title}</CardTitle>
        <CardText>{`${notification.content?.slice(0, 86)}${notification.content?.length ?? 0 > 96 ? '...' : ''}`}</CardText>
      </CardContent>

      <MenuNotification
        viewed={notification.viewed}
        onViewMessageClick={() => handleCardSelect(notification)}
        onDeleteCardClick={() => handleDeleteCard(notification)}
        onMarkAsRead={() => handleMarkAsRead(notification)}
        onMarkAllAsRead={() => handleMarkAllAsViewed()}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
    </Card>
  );
}

export default CardComponent;

import React from 'react';
import {
  Bar,
  BarContainer,
  Container, Label,
  LabelContainer,
} from './styles';

interface Props {
  label: string;
  color: string;
  value: number;
  full?: boolean;
}

export default function ProgressBar({
  label, color, value, full,
}: Props) {
  const numbersOfBars = 40;
  const bars = Array.from({ length: numbersOfBars }, (_, i) => i + 1);
  const progress = (value * numbersOfBars) / 100;

  return (
    <Container full={full}>
      {label && (
        <LabelContainer>
          <Label>{`${label}:`}</Label>
        </LabelContainer>
      )}
      <BarContainer full={full}>
        {bars.map((_, index) => <Bar key={index} color={color} active={progress > index} />)}
      </BarContainer>
    </Container>
  );
}

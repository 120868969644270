import React from 'react';

import ModalButton from '../ModalButton';
import { Container, Title } from './styles';

interface PropsI {
  onPressBack: () => void
  onPressAbandon: () => void
}

function AbandonExerciseModal({
  onPressBack,
  onPressAbandon,
}: PropsI) {
  return (
    <Container data-testid="abandon-exercise-modal">
      <Title>DESEJA MESMO TERMINAR O TREINO AQUI?</Title>
      <div>
        <ModalButton
          onPress={onPressAbandon}
          danger
          title="ABANDONAR TREINO"
        />
        <ModalButton
          onPress={onPressBack}
          secondary
          title="VOLTAR"
        />
      </div>
    </Container>
  );
}

export default AbandonExerciseModal;

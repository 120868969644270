import React from 'react';
import {
  Container, ErrorMessage, Input, Label,
} from './styles';

export interface ITimeInput extends React.InputHTMLAttributes<HTMLInputElement>{
  label?: string;
  value: string;
  error?: boolean;
  errorMessage?: string;
  type: 'duration' | 'time'
}

export default function TimeInput({
  label, errorMessage, error, type, value, ...rest
}: ITimeInput) {
  const mask = type === 'time' ? '99:99' : '99:99:99';
  const placeholder = type === 'time' ? '00:00' : '00:00:00';
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input mask={mask} placeholder={placeholder} {... rest} value={value} inputMode="numeric" />
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
}

import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { appTheme } from '../../../../theme';
import {
  Title, List, Container, Description, Item,
  RadioContainer, Radio, RadioInner,
  LabelContainer, Label,
} from './styles';

export interface ISetAlternativeTypeScreenProps {
  step: IStep,
  alternativeType: string | undefined,
  onClick: (value: string | number) => void,
  setButtonDisabled: (bool: boolean) => void,
}

function SetAlternativeTypeScreen({
  step, alternativeType, onClick, setButtonDisabled,
}: ISetAlternativeTypeScreenProps) {
  useEffect(() => {
    if (alternativeType) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [alternativeType]);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <List>
        {
          step.data.map((item: IStepData) => (
            <Item
              key={item.label}
              onClick={() => onClick(item.value as string | number)}
              style={{ borderColor: `${alternativeType === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: alternativeType === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: alternativeType === item.value ? appTheme.title : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{(item.label as string).toUpperCase()}</Label>
              </LabelContainer>
            </Item>
          ))
        }
      </List>
    </Container>
  );
}

export default SetAlternativeTypeScreen;

import styled from 'styled-components';
import theme from '../../theme';

interface ContainerProps {
  transparent: boolean;
}

export const Container = styled.section<ContainerProps>`
  background-color: ${({ transparent }) => (transparent ? 'transparent' : theme.colors.cardB1)};
  align-items: center;
  padding: 13px;
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.section`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const Text = styled.span`
  color: ${theme.colors.title};
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  background-color: ${theme.colors.graph};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 18px;
  padding-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: ${theme.colors.graph};
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  color: ${theme.colors.title};
  font-weight: 600;
  text-transform: uppercase;
`;

export const ButtonLink = styled.button`
  background-color: ${theme.colors.cardB1};
  border: none;
  padding: 8px;
  cursor: pointer;
`;

export const ButtonLinkText = styled.span`
  color: ${theme.colors.title};
  text-decoration: underline;
`;

import React, { useEffect } from 'react';
import { IStep } from '../..';
import { User } from '../../../../types/User';
import { appTheme } from '../../../../theme';
import {
  Container, Description, Item, Label,
  LabelContainer, List, Radio, RadioContainer,
  RadioInner, Title,
} from './styles';

export interface ISetDaysWeeklyWorkoutScreenProps {
  step: IStep,
  profile: User,
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  onClick: (value: any) => void,
  daysWeeklyWorkout: null | string,
}

function SetDaysWeeklyWorkoutScreen({
  step, onClick, daysWeeklyWorkout, profile, setButtonDisabled,
}: ISetDaysWeeklyWorkoutScreenProps) {
  useEffect(() => {
    onClick(profile.days_weekly_workout);
  }, []);

  useEffect(() => {
    if (daysWeeklyWorkout) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [daysWeeklyWorkout]);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <List>
        {
          step.data.map((item: any) => (
            <Item
              key={item.label}
              onClick={() => onClick(item.value)}
              style={{ borderColor: `${daysWeeklyWorkout === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: daysWeeklyWorkout === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: daysWeeklyWorkout === item.value
                        ? appTheme.title
                        : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{item.label.toUpperCase()}</Label>
              </LabelContainer>
            </Item>
          ))
        }
      </List>

      <Description>
        Nos demais dias poderemos prescrever treinos opcionais
        de corrida e treinos de fortalecimento.
      </Description>
    </Container>
  );
}

export default SetDaysWeeklyWorkoutScreen;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

interface ProgressBarI {
  percentage: number
}

export const ProgressText = styled.p`
  color: ${theme.colors.title};
  font-weight: 600;
  font-size: 8px;
  @media(min-width: 768px) {
    font-size: 12px;
  }
`;

export const ProgressBar = styled.div`
  background: ${appTheme.iconsOff};
  height: 8px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div<ProgressBarI>`
  background: ${theme.colors.graph};
  height: 8px;
  width: ${(props) => props.percentage || 10}%;
  border-radius: 4px;
`;

export const ProgressContainer = styled.div`
  width: 54%;
`;

import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { MdStarOutline, MdOutlineStar } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import BackArrow from '../BackArrow';
import api from '../../services/api';
import { RootState } from '../../store';
import { refreshActivities } from '../../store/Activities.store';
import {
  Container, VideoContainer, TitleContainer, FavoriteButton, FavoriteText, RegisterButton, RegisterButtonText, Title,
} from './style';
import theme from '../../theme';
import { useQuery } from '../../utils';
import { IRecordedLiveLectureDetails } from '../RecordedClasses';
import RegisterActivity from '../RegisterActivity';
import Loader from '../Loader';

export interface IRecordedClassesExecutionProps {
  handleFavoriteCallback?: () => {};
  liveLectureDetails?: IRecordedLiveLectureDetails;
}

export default function RecordedClassesExecution({ handleFavoriteCallback, liveLectureDetails }: IRecordedClassesExecutionProps) {
  const navigate = useNavigate();
  const query = useQuery();
  const recordedClass = { id: query.get('id'), title: query.get('title'), name: query.get('name') };
  const URLHasParams = recordedClass.id != null && recordedClass.title != null && recordedClass.name != null;
  const [liveLecture, setLiveLecture] = useState<IRecordedLiveLectureDetails>();
  const [favorited, setFavorited] = useState<boolean>(false);
  const [isLoadingLecture, setIsLoadingLecture] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const auth = useSelector((state : RootState) => state.auth);
  const { user } = auth;

  const dispatch = useDispatch();

  const handleFavorite = useCallback(async () => {
    if (handleFavoriteCallback) {
      return handleFavoriteCallback();
    }
    if (favorited) {
      await api.delete(`live_lectures/${liveLecture?.id}/removeFavorite`)
        .then((response) => {
          if (response.status === 200) {
            setFavorited(false);
          }
        })
        .catch(() => {
          toast.error('Ocorreu algum erro ao tentar desfavoritar essa aula.');
        });
    } else {
      await api.post(`live_lectures/${liveLecture?.id}/favorite`)
        .then((response) => {
          if (response.status === 200) {
            setFavorited(true);
          }
        })
        .catch(() => {
          toast.error('Ocorreu algum erro ao tentar favoritar essa aula.');
        });
    }
    return null;
  }, [favorited, liveLecture, handleFavoriteCallback]);

  useEffect(() => {
    if (liveLectureDetails) {
      setLiveLecture(liveLecture);
      setIsLoadingLecture(false);
      setHasError(false);
    } else if (URLHasParams) {
      setIsLoadingLecture(true);

      api.get(`live_lectures/${recordedClass.id}`)
        .then((response) => {
          setHasError(false);
          setLiveLecture(response.data);
          setFavorited(response.data.favorited);
        })
        .catch(() => {
          setHasError(true);
          toast.error('Ocorreu algum erro ao tentar carregar as aulas gravadas.');
        }).finally(() => {
          setIsLoadingLecture(false);
        });
    } else {
      navigate('/club/RecordedClasses');
    }
  }, []);

  const getTrainingType = () => {
    if (recordedClass?.name?.includes('Fortalecimento')) {
      return 'WeightTraining';
    }
    return 'Workout';
  };

  const refresh = () => {
    toast('Atividade criada com sucesso!');
    dispatch(refreshActivities(user.id) as any);
  };
  return (
    <Container data-testid="recorded-class-container">
      <BackArrow text="EXECUÇÃO DO TREINO" bigger backTo="/club/RecordedClasses" />
      { isLoadingLecture ? <Loader /> : hasError ? <div>Erro ao carregar aula.</div> : (
        <VideoContainer>
          <iframe
            title="Video"
            src={liveLecture?.record_link}
            allow="fullscreen"
            frameBorder="0"
            loading="lazy"
            style={{ height: '350px' }}
          />
          <TitleContainer>
            <Title>{recordedClass.title}</Title>
            {favorited ? (
              <FavoriteButton onClick={() => handleFavorite()}>
                <MdOutlineStar size={10} color={theme.colors.title} />
                <FavoriteText active>Favoritada</FavoriteText>
              </FavoriteButton>
            )
              : (
                <FavoriteButton onClick={() => handleFavorite()}>
                  <MdStarOutline size={10} color={theme.colors.text} />
                  <FavoriteText>Favoritar</FavoriteText>
                </FavoriteButton>
              )}
          </TitleContainer>
        </VideoContainer>
      )}
      <RegisterActivity
        visible={modalVisible}
        close={() => setModalVisible(false)}
        refresh={refresh}
        _type={getTrainingType()}
      />

      <RegisterButton onClick={() => setModalVisible(true)}>
        <RegisterButtonText>Registrar este treino</RegisterButtonText>
      </RegisterButton>
    </Container>
  );
}

import React from 'react';
import {
  DisclaimerContainer, DisclaimerDismissBtn, DisclaimerText, DisclaimerTitle, DisclaimerDismissBtnTitle, FooterMessage,
} from './styles';
import BaseModal from '../BaseModal';

export interface IPredictionDisclaimerModalProps {
  isVisible: boolean,
  dismissCallback: () => void,
}

export default function PredictionDisclaimerModal({ dismissCallback, isVisible }: IPredictionDisclaimerModalProps) {
  return (
    <BaseModal isVisible={isVisible}>
      <DisclaimerContainer>
        <DisclaimerTitle>Informação importante sobre o resultado</DisclaimerTitle>
        <DisclaimerText>
          O resultado da calculadora é apenas uma estimativa de seu potencial nas distâncias mais conhecidas, baseada nas suas capacidades atuais. Essas estimativas levam em conta ainda que você siga um ciclo de treinamento adequado para alcançar o resultado projetado. Esse resultado também pode variar em razão das condições no dia da sua corrida (clima, altimetria, etc.).
        </DisclaimerText>
        <FooterMessage>Método de cálculo desenvolvido pela equipe &quot;Sistema Força e Endurance&quot;.</FooterMessage>
        <DisclaimerDismissBtn
          onClick={dismissCallback}
        >
          <DisclaimerDismissBtnTitle>OK</DisclaimerDismissBtnTitle>
        </DisclaimerDismissBtn>
      </DisclaimerContainer>
    </BaseModal>
  );
}

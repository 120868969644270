import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow: hidden;
  outline: 0;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
`;

export const ModalDialog = styled.div`
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  position: relative;
  width: auto;
  margin: 60px auto;
  z-index: 1073;

  @media (min-width: 500px) {
    max-width: 433px;
  }

  @media (min-width: 576px) {
    max-width: 500px;
  }

  @media (min-width: 725px) {
    max-width: 680px;
  }
`;

interface ModalContextProps {
  height?: number;
}

export const ModalContainer = styled.div<ModalContextProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000033;
  background-clip: padding-box;
  outline: 0;

`;

export const ModalBackDrop = styled.div<{cursor?: string}>`
  opacity: 0.4;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: white;

  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-between;  
  align-items: center;
`;

export const Title = styled.h2`
  margin-left:5px;
  color: white;
  font-size: 16px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const ModalContent = styled.div`
  padding: 2%;
`;

import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Container = styled.div`
  width: 100%;
  background-color: ${appTheme.cardB2};
  border-radius: 10px;
  padding: 20px 25px 3px;
  margin-bottom: 25px;

  @media (max-width: 667px) {
    padding: 8px;
    margin-bottom: 8px;
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  margin: 5px;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.div`
  color: ${appTheme.title};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 26px;

  @media (max-width: 667px) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }
`;

import styled, { keyframes } from 'styled-components';
import { appTheme } from '../../theme';

const shine = keyframes`
    to {
      background-position-x: -200%;
    }
`;

export interface ISkeletonProps {
  customLayout: any
}

export const Container = styled.div<ISkeletonProps>`
    background: linear-gradient(110deg, ${appTheme.cardB3} 8%, ${appTheme.cardB2} 18%, ${appTheme.cardB3} 33%);
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
    height: 100%;
    width: 100%;
    
    ${(props) => (props ? Object.entries(props) : null)};
`;

import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import {
  ModalDialog,
  ModalContainer,
  ModalBackDrop,
  Modal,
  HeaderContainer,
  Title,
  IconContainer,
  ModalContent,
} from './styles';

export interface IBaseModalProps {
  isVisible: boolean
  closeCallback?: Function
  children?: ReactNode
  title?: string
  setIsVisible?: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void)
}

function BaseModal({
  isVisible, setIsVisible, title, children, closeCallback,
} : IBaseModalProps) {
  return (
    <>
      {isVisible && (
        <Modal className="modal" data-testid="modal">
          <ModalBackDrop onClick={setIsVisible ? () => setIsVisible(false) : () => {}} cursor={setIsVisible ? 'pointer' : ''} />
          <ModalDialog className="modal-dialog">
            <ModalContainer className="modal-content">
              <HeaderContainer>
                <Title>{title}</Title>
                {setIsVisible
                  && (
                    <IconContainer
                      onClick={() => {
                        setIsVisible(false);
                        closeCallback?.();
                      }}
                      data-testid="close-button"
                    >
                      <MdOutlineClose color="white" size={30} />
                    </IconContainer>
                  )}
              </HeaderContainer>
              {children && (
                <ModalContent>
                  {children}
                </ModalContent>
              )}
            </ModalContainer>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
}

export default BaseModal;

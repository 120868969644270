import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import api, { api2 } from '../../services/api';
import { RootState } from '../../store';
import { updateProfileProps } from '../../store/Auth.store';
import { IPayload } from './contracts';
import {
  Container,
  Item, ItemDescription,
  ItemButton, ItemTitle, ItemDescriptionContainer,
} from './styles';
import EditShoesModal from './EditShoesModal';
import DeactivateControlKmsModal from '../DeactivateControlKmsModal';
import { IShoes } from '../../store/Activities.store';
import RegisterShoes from '../RegisterShoes';
import ShoesTable from '../ShoesTable';
import ShoesDetailsModal from './ShoesDetailsModal';
import RemoveShoesModal from './RemoveShoesModal';

export default function MyShoes() {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const [askAboutActivityShoes, setAskAboutActivityShoes] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeactivateControlKmsModal, setShowDeactivateControlKmsModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deactivateControlKmsModalLoading, setDeactivateControlKmsModalLoading] = useState(false);
  const [shoes, setShoes] = useState<IShoes[]>([]);
  const [retireeShoes, setRetireeShoes] = useState<IShoes[]>([]);
  const [selectedShoes, setSelectedShoes] = useState<IShoes | null>(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const loadData = () => {
    api2.get('shoes?status=ACTIVE')
      .then((response) => {
        setShoes(response.data.data);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar seus tênis');
      });

    api2.get('shoes?status=RETIREE')
      .then((response) => {
        setRetireeShoes(response.data.data);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar seus tênis');
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setAskAboutActivityShoes(!!user?.ask_about_activity_shoes!);
  }, [user?.ask_about_activity_shoes!]);

  const updateOptions = useCallback(async (payload: IPayload, fn: () => void) => {
    try {
      await api.put(`/users/${user.id}`, payload);

      dispatch(updateProfileProps(payload) as any);

      fn();
    } catch (error) {
      toast.error('Ocorreu um erro ao alterar a opção, tente novamente');
    }
  }, []);

  const toggleAskAboutActivityShoes = () => {
    setDeactivateControlKmsModalLoading(true);

    updateOptions(
      {
        ask_about_activity_shoes: !askAboutActivityShoes,
      },
      () => {
        setAskAboutActivityShoes((prev) => !prev);
        setDeactivateControlKmsModalLoading(false);
        setShowDeactivateControlKmsModal(false);
      },
    );
  };

  const handleDeleteShoes = () => {
    setDeleteLoading(true);

    api2.delete(`shoes/${selectedShoes?.id}`)
      .then(() => {
        toast.success('Tênis apagado com sucesso');
        setRemoveModal(false);
        loadData();
      })
      .catch(() => {
        toast.error('Erro ao apagar tênis');
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const openRemoveModal = () => {
    setDetailsModal(false);
    setTimeout(() => setRemoveModal(true), 500);
  };

  const update = (payload: Partial<IShoes>) => {
    if (selectedShoes) {
      setEditLoading(true);

      api2.put(`shoes/${selectedShoes?.id}`, payload)
        .then(() => {
          toast.success('Tênis atualizado com sucesso');
          setRemoveModal(false);
          setShowEditModal(false);
          loadData();
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao atualizar o tênis, tente novamente');
        })
        .finally(() => {
          setEditLoading(false);
        });
    }
  };

  const openEditModal = () => {
    setDetailsModal(false);
    setTimeout(() => setShowEditModal(true), 500);
  };

  const backToDetailsModal = () => {
    setShowEditModal(false);
    setTimeout(() => setDetailsModal(true), 500);
  };

  return (
    <>
      <EditShoesModal
        visible={showEditModal}
        setVisible={setShowEditModal}
        update={update}
        loading={editLoading}
        shoes={selectedShoes!}
        backToDetails={backToDetailsModal}
      />

      <ShoesDetailsModal
        visible={detailsModal}
        setVisible={setDetailsModal}
        update={openEditModal}
        retire={openRemoveModal}
        shoes={selectedShoes!}
      />

      <RemoveShoesModal
        visible={removeModal}
        setVisible={setRemoveModal}
        shoes={selectedShoes!}
        remove={handleDeleteShoes}
        retire={update}
        removeLoading={deleteLoading}
        editLoading={editLoading}
      />

      <DeactivateControlKmsModal
        visible={showDeactivateControlKmsModal}
        setVisible={setShowDeactivateControlKmsModal}
        handleDeactivate={toggleAskAboutActivityShoes}
        loading={deactivateControlKmsModalLoading}
      />

      <Item>
        <ItemDescriptionContainer>
          <ItemTitle>
            CONTROLE OS KMS DE SEUS TÊNIS
          </ItemTitle>

          <ItemDescription>
            Vincule seus tênis às atividades de corrida para contabilizar o uso em kms. Quando ativada,
            essa função irá te enviar lembretes para atribuir um tênis a cada uma de suas atividades.
          </ItemDescription>
        </ItemDescriptionContainer>

        <ItemButton
          isEnabled={askAboutActivityShoes}
          toggle={askAboutActivityShoes ? () => setShowDeactivateControlKmsModal(true) : toggleAskAboutActivityShoes}
          labelEnabled
        />
      </Item>

      {
        shoes.length > 0
        && (
          <Container style={{ padding: 0 }}>
            <ItemTitle style={{ marginTop: '10px' }}>
              MEUS TÊNIS
            </ItemTitle>

            <ShoesTable
              shoes={shoes}
              setSelectedShoes={setSelectedShoes}
              setShowDetailsModal={setDetailsModal}
            />
          </Container>
        )
      }

      {
        retireeShoes.length > 0
        && (
          <Container style={{ padding: 0 }}>
            <ItemTitle style={{ marginTop: '10px' }}>
              APOSENTADOS
            </ItemTitle>

            <ShoesTable
              shoes={retireeShoes}
              setSelectedShoes={setSelectedShoes}
              setShowDetailsModal={setDetailsModal}
            />
          </Container>
        )
      }

      <Container>
        <RegisterShoes cb={loadData} />
      </Container>
    </>
  );
}

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  position: relative;
  width: 100%;  
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  background-color: ${theme.colors.cardB2};
  overflow: hidden;
  
  @media (max-width: 667px) {
    padding: 8px;
    margin-bottom: 8px;
  }
`;

export const Titles = styled.h2`
  color: ${theme.colors.title};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;

  @media (max-width: 667px) {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const NoCourseCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const NoCardText = styled.p`
  color: ${theme.colors.cardTxt};
`;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Header,
  EventsNone,
  EventsNoneContent,
  LoadingStyle,
  ErrorStyle,
  EventsRecorded,
  EventsButton,
  EventsButtonText,
} from './styles';
import EventCard from './EventCard';
import Loader from '../Loader';
import { RootState } from '../../store/index';
import { requestEvents } from '../../store/Events.store';
import Carousel from '../Carousel';

export default function NextEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state: RootState) => state.events.loading);
  const events = useSelector((state: RootState) => state.events.events);
  const error = useSelector((state: RootState) => state.events.error);

  useEffect(() => {
    dispatch(requestEvents() as any);
  }, []);

  useEffect(() => {
    if (error != null) {
      toast.error('Ocorreu um erro ao carregar os próximos eventos');
    }
  }, [JSON.stringify(error)]);

  return (
    <Container data-testid="events-container">
      <Header>
        <h4>TREINOS AO VIVO</h4>
      </Header>

      {loading ? (
        <LoadingStyle data-testid="loading">
          <Loader color="#fff" />
        </LoadingStyle>
      ) : error != null ? (
        <ErrorStyle>Erro. Por favor, tente novamente.</ErrorStyle>
      ) : (
        <>
          <Carousel>
            {events?.map((event: any) => (
              <EventCard key={event?.id} event={event!} />
            ))}
            {events?.length === 0 && (
              <EventsNone>
                <EventsNoneContent>
                  Nenhum evento planejado para os próximos dias
                </EventsNoneContent>
              </EventsNone>
            )}
          </Carousel>
        </>
      )}

      <EventsRecorded>
        <EventsButton
          onClick={() => navigate({
            pathname: '/club/RecordedClasses',
          })}
        >
          <EventsButtonText>VER TODAS AS GRAVAÇÕES</EventsButtonText>
        </EventsButton>
      </EventsRecorded>
    </Container>
  );
}

import React from 'react';
import {
  Button, ButtonText, Container, NumberDisplay, ButtonPlaceholder,
} from './styles';

export interface IProductCounterProps {
  number: number;
  setValue: React.Dispatch<React.SetStateAction<number>>
}

export default function ProductCounter({ number, setValue }: IProductCounterProps) {
  const increment = () => {
    setValue(number + 1);
  };

  const decrement = () => {
    if (number > 0) {
      setValue(number - 1);
    }
  };

  return (
    <Container>
      {(number > 1 && (
        <Button onClick={decrement}>
          <ButtonText>-</ButtonText>
        </Button>
      ))
      || <ButtonPlaceholder />}
      <NumberDisplay>{number}</NumberDisplay>
      <Button onClick={increment}>
        <ButtonText>+</ButtonText>
      </Button>
    </Container>
  );
}

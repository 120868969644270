import styled from 'styled-components';
import { appTheme } from '../../theme';

export const Background = styled.div`
  flex: 1;
`;

export const Container = styled.div`
  border-radius: 10px;
  background-color: ${appTheme.cardB1};
  width: 100%;
`;

export const RequestListContainer = styled.div`
  border-radius: 10px;
  background-color: ${appTheme.cardB1};
  flex: 1;
`;

export const ContainerCard = styled.div`
  background: #f9f9f9;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-top: 10px;
`;

export const ContainerGradientParent = styled.button`
  height: 160px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  border: unset;
  background-color: transparent;
  cursor: pointer;
`;

export const ContainerItem = styled.div`
  height: 140px;
`;

export const ContainerGradient = styled.div`
  background-color: red;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  height: 140px;
`;

export const ContainerHeaderBack = styled.div`
  margin-left: 7px;
`;

export const SubHeader = styled.div`
  width: 100%;
  padding: 10px;
`;

export const SubHeaderPrimaryTitle = styled.p`
  font-size: 13px;
  color: ${appTheme.title};
  margin-bottom: 10px;
`;

export const SubHeaderSecondaryTitle = styled.p`
  font-size: 12px;
  color: ${appTheme.title};
`;

export const RequestOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
  margin-left: 10px;
`;

export const RequestOptionTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${appTheme.title};
  margin-bottom: 5px; 
`;

export const RequestOptionSubTitle = styled.p`
  background-color: ${appTheme.cardB2};
  color: ${appTheme.gray};
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
`;

export const RequestOptionDescription = styled.p`
  text-align: left;
  color: ${appTheme.title};
  margin-bottom: 10px;
  font-size: 12px;
`;

export const RequestOptionTimer = styled.div` 
  background-color: ${appTheme.iconsOff};
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const RequestOptionTimerText = styled.p`
  color: ${appTheme.primaryD4};
  font-size: 13px;
  margin-left: 5px;
`;

export const RequestContainer = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${appTheme.cardB2};
  border-radius: 10px;
`;

export const RequestContent = styled.div`
  width: 100%;
  padding: 10px;
`;

export const RequestFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const TrainingSelectedContainer = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
  margin-top: 10px;
`;

export const TrainingSelectedHeaderTitle = styled.p`
  color: ${appTheme.title};
  font-size: 13px;
  margin-bottom: 20px;
`;

export const AuxDescriptionText = styled.p`
  color: ${appTheme.title};
  font-size: 12px;
  text-align: center;
  width: 90%;
  margin-bottom: 20px;
`;

export const TrainingSelected = styled.div`
  display: flex;
  align-items: center;
  background-color: ${appTheme.cardB2};
  padding: 10px;
  border-radius: 10px;
  width: 100%;
`;

export const TrainingSelectedSwitchButton = styled.button`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border: unset;
  background-color: transparent;
  cursor: pointer;

`;

export const TrainingSelectedSwitchButtonTitle = styled.p`
  color: ${appTheme.title};
  text-decoration-color: ${appTheme.title};
  text-decoration-style: solid;
  text-decoration-line: underline;
  border: unset;
  background-color: transparent;
  cursor: pointer;
`;

export const TrainingSelectedContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 60px;
    fill: ${appTheme.text};
  }
`;

export const TrainingSelectedDetailsContainer = styled.div`
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

export const TrainingSelectedDetailsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const TrainingSelectedTitleText = styled.p`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  color: ${appTheme.title};
`;

export const TrainingSelectedDetailsSubtitle = styled.div`
  width: 100%;
  margin-left: 10px;
  background-color: ${appTheme.cardB3};
  padding: 5px;
  border-radius: 5px;
`;

export const TrainingSelectedDetailsSubtitleText = styled.p`
  font-size: 12px;
  color: ${appTheme.grayBG};
`;

export const TrainingSelectedDetailsDescription = styled.p`
  max-width: 80%;
  font-size: 14px;
  color: ${appTheme.title};
  margin-bottom: 5px;
`;

export const TrainingSelectedDetailsTrainingType = styled.p`
  font-size: 10px;
  color: ${appTheme.title};
`;

interface IBackgroundProps {
  imageUrl: string
}

export const TrainingCardBackground = styled.div<IBackgroundProps>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-image: url(${(props) => (props.imageUrl)});
  background-position: center;
  background-size: cover;
  padding: 30px 0;
`;

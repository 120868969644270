import * as React from 'react';
import { Rnd } from 'react-rnd';
import html2canvas from 'html2canvas';

import Loader from '../../Loader';
import BaseModal, { IBaseModalProps } from '../../BaseModal';
import {
  Button, ButtonContainer, ThumbnailContainer, InfoText,
} from './styles';

export interface ICropModalProps extends IBaseModalProps {
  image: string;
  acceptCallback: (imageUrl: string) => void;
}

export default function CropModal(
  {
    image,
    acceptCallback,
    ...modalProps
  }: ICropModalProps,
) {
  const thumbRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  const exportAsImage = async () => {
    if (thumbRef.current) {
      const canvas = await html2canvas(thumbRef.current, {
        useCORS: true,
      });

      return canvas.toDataURL('image/png', 1.0);
    }

    return '';
  };

  const handleAccept = async () => {
    setLoading(true);

    setTimeout(async () => {
      const capuredImage = await exportAsImage();

      acceptCallback(capuredImage);
    }, 10);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <BaseModal {...modalProps}>
      <ThumbnailContainer ref={thumbRef} imageUrl={image} showBorder={!loading}>
        <Rnd
          className="rnd-style"
          minHeight={400}
          minWidth={400}
          default={
            {
              x: 0,
              y: 0,
              width: 400,
              height: 400,
            }
          }
        />
      </ThumbnailContainer>
      <InfoText>Clique na imagem e arraste para ajustar</InfoText>
      <ButtonContainer>
        {loading && <Loader className="loader-style" color="white" />}
        {!loading && (
          <>
            <Button type="button" onClick={() => handleAccept()}>Aceitar</Button>
            <Button type="button" onClick={() => modalProps?.closeCallback?.()}>Cancelar</Button>
          </>
        )}

      </ButtonContainer>
    </BaseModal>
  );
}

import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { appTheme } from '../../../../theme';
import {
  Container, Description, Item, Label,
  LabelContainer, List, Radio, RadioContainer,
  RadioInner, Title,
} from './styles';

export interface ISetDistanceHistoryScreenProps {
  step: IStep;
  onClick: (value: any) => void;
  setButtonDisabled: (set: boolean) => void;
  distanceHistory?: string;
}

function SetDistanceHistoryScreen({
  step, distanceHistory, onClick, setButtonDisabled,
}: ISetDistanceHistoryScreenProps) {
  useEffect(() => {
    if (distanceHistory) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [distanceHistory]);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <List>
        {
          step.data.map((item: IStepData) => (
            <Item
              key={item.label}
              onClick={() => onClick(item.value)}
              style={{ borderColor: `${distanceHistory === item.value ? appTheme.gray : appTheme.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: distanceHistory === item.value ? appTheme.title : appTheme.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: distanceHistory === item.value ? appTheme.title : appTheme.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{(item.label as string).toUpperCase()}</Label>
              </LabelContainer>
            </Item>
          ))
        }
      </List>
    </Container>
  );
}

export default SetDistanceHistoryScreen;

import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export const Container = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  background-color: ${appTheme.whiteBG};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
`;

export const Content = styled.div`
  background: ${theme.colors.cardB1};
  iframe{
    height: 50vh;
    width: 87vh;
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 768px) {
        height: 57vw;
        width: 100vw;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 4%;
  span{
    cursor: pointer;
  }
`;

export const Title = styled.h2`
  color: ${theme.colors.title};
  font-weight: bold;
  font-size: 18px;
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4%;
`;

export const ControlTitle = styled.h3`
  color: ${theme.colors.title};
  font-weight: bold;
  font-size: 18px;
`;

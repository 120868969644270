import React, { Dispatch, SetStateAction } from 'react';
import BaseModal from '../BaseModal';
import { Container, Text } from './styles';

interface Props {
  isVisible: boolean
  setVisible: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void)
}

export default function ChallengeInfoModal({ isVisible, setVisible }: Props) {
  return (
    <BaseModal isVisible={isVisible} setIsVisible={setVisible} title="Desafios Virtuais">
      <Container>
        <Text>Os desafios virtuais foram feitos para elevar a sua dedicação aos treinos.</Text>

        <Text>Cada desafio possui uma meta de km a ser alcançada, num prazo de tempo definido por você.</Text>

        <Text>Você acompanha seu progresso rumo à meta junto de uma experiência imersiva numa rota real no Google Maps, enquanto explora belas paisagens e locais icônicos no Street View.</Text>

        <Text>Ao alcançar a meta, você recebe uma medalha especial do desafio em seu endereço.</Text>

        <Text>Vale qualquer atividade baseada em distância: corrida, caminhada, ciclismo, natação, etc. E elas podem ser registradas e em seu equipamento, em nosso App, no Strava ou manualmente.</Text>
      </Container>
    </BaseModal>
  );
}

import React from 'react';

import { useSelector } from 'react-redux';

import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { MdChevronRight } from 'react-icons/md';
import { AiFillLock } from 'react-icons/ai';

import { createSearchParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import {
  DateContainer, DateText, ItemButton, SeparatorV, Title, Container, TitleContainer,
} from './style';
import theme from '../../theme';

export default function LiveClassItem({
  liveClass, detail = false, openFreeModal,
}: any) {
  const isFree = useSelector((state: RootState) => state.auth.user.isFree);
  const day = format(new Date(liveClass.date), 'EEEE', { locale: ptBR });
  const dayFormated = day.split('-')[0];
  const hours = format(new Date(liveClass.date), 'HH');
  const min = format(new Date(liveClass.date), 'mm') === '00' ? '' : format(new Date(liveClass.date), 'mm');

  const navigate = useNavigate();
  const handlePress = () => {
    if (isFree) {
      if (openFreeModal) {
        openFreeModal();
      }
    } else {
      navigate({
        pathname: '/club/live-classes/details',
        search: `?${createSearchParams({
          id: liveClass.id,
        })}`,
      });
    }
  };

  return (
    <ItemButton
      data-testid="live-class-item-container"
      onClick={() => handlePress()}
    >
      <DateContainer>
        <DateText>{`${dayFormated}s`}</DateText>
        <DateText>{`${hours}h${min}`}</DateText>
      </DateContainer>
      <SeparatorV />
      <Container>
        <TitleContainer>
          {isFree && (
            <>
              <AiFillLock size={20} color={theme.colors.title} />

            </>
          )}
          <Title>
            {liveClass.title}
          </Title>
        </TitleContainer>
        {!detail && <MdChevronRight size={40} color="white" />}
      </Container>
    </ItemButton>
  );
}

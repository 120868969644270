import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const Border = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background: ${theme.colors.cardB1};
    opacity: 0.8;
`;

export const Button = styled.div`
    display: flex;    
    border-radius: 20px;    
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg{
        width: 42px;
        height: 42px;
    }
`;

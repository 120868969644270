import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/logo.png';
import BaseModal from '../BaseModal';
import {
  BadgeContainer, BadgeDescription, BadgeGreeting, ButtonsFooter, CloseButton, Logo, Background,
} from './styles';
import { RootState } from '../../store';
import { Badges } from '../RegularityLevelProgress';
import {
  Badge, BadgeInnerContainer, BadgeOuterContainer, BadgeTitle,
} from '../RegularityLevelProgress/styles';

interface IBadgeLevelsModalProps {
  showModal: boolean;
  closeModalCallback: () => void;
}

export default function BadgeLevelsModal({
  showModal, closeModalCallback,
}: IBadgeLevelsModalProps) {
  const { genre } = useSelector((state: RootState) => state.auth.user);
  const badgesList = Badges(genre);

  return (
    <BaseModal isVisible={showModal}>
      <Background>
        <Logo src={logo} alt="Logo do Corrida Perfeita" />

        <BadgeGreeting>Acompanhe sua regularidade!</BadgeGreeting>

        <BadgeContainer>
          {
            badgesList.map((badge) => badge.id < 7 && (
              <BadgeOuterContainer key={badge.id}>
                <BadgeInnerContainer onClick={() => {}} active style={{ cursor: 'default' }}>
                  {!!badge.icon && <Badge src={badge.icon} alt="Última badge conquistada" />}
                </BadgeInnerContainer>

                <BadgeTitle active style={{ fontSize: 9 }}>
                  {badge.title}
                </BadgeTitle>
              </BadgeOuterContainer>
            ))
          }
        </BadgeContainer>

        <BadgeDescription>
          Esta função de ajuda a acompanhar e manter a sua regularidade nos treinos.
        </BadgeDescription>

        <BadgeDescription>
          A cada semana sendo regular de treinos, você conquista um nível.
        </BadgeDescription>

        <ButtonsFooter>
          <CloseButton onClick={closeModalCallback}>
            OK
          </CloseButton>
        </ButtonsFooter>
      </Background>
    </BaseModal>
  );
}

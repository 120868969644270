import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { AiFillSignal, AiOutlineInfoCircle, AiFillLock } from 'react-icons/ai';
import { BsClock } from 'react-icons/bs';
import { BiBody } from 'react-icons/bi';
import { loadExercises, startExercise } from '../../store/Exercises.store';
import BackArrow from '../../components/BackArrow';
import Accordion from '../../components/Accordion';
import {
  Buttom, ButtonsContainer, Container, Cover, List, CardExercise, InfoContainer, TitleContainer, OptionsContainer, BorderCard, ListContainer, Thumb, InfoExercise,
} from './styles';
import ExerciseInfoModal from '../../components/ExerciseInfoModal';
import { RootState } from '../../store';
import theme from '../../theme';
import ModalFreeUser from '../../components/ModalFreeUser';
import { ExerciseI, exercisesTypes } from '../../types/Exercise';
import ExerciseCoverSkeleton from '../../components/ExerciseCoverSkeleton';
import { setExercisesList } from '../../store/ExercisesList.store';
import { useQuery } from '../../utils';

function ExerciseTrainingCover() {
  const query = useQuery();
  const trainingToAttachId = query.get('trainingToAttachId');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type: exerciseType, id: exerciseId } = useParams();

  const user = useSelector((state: RootState) => state.auth.user);
  const { isFree } = user;
  const [modalFreeUser, setModalFreeUser] = useState(false);

  const [videotitle, setVideoTitle] = useState<string | null>();
  const [videoUri, setVideoUri] = useState<string | null>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const initVideo = (vtitle: string, uri: string) => {
    if (uri) {
      setVideoTitle(vtitle);
      setVideoUri(uri);
      setIsVisible(true);
    }
  };

  const [courseData, setCourseData] = useState<ExerciseI | undefined>();

  const { loading, courses } = useSelector((state: RootState) => state.exercises);

  useEffect(() => {
    checkCourseData();
  }, [courses]);

  function findCourse(courseId: number, type: exercisesTypes) {
    return courses[type].find((course) => course.id === courseId);
  }

  function handleStoredCourse(storedCourse: any) {
    if (storedCourse) {
      setCourseData(storedCourse);
      dispatch(setExercisesList(storedCourse) as any);
    } else {
      navigate('/training');
    }
  }

  function checkCourseData() {
    if (exerciseType && exerciseId) {
      const storedCourse = findCourse(Number(exerciseId), exerciseType as exercisesTypes);

      if (storedCourse) {
        handleStoredCourse(storedCourse);
      } else {
        dispatch(loadExercises(exercisesTypes[exerciseType as keyof typeof exercisesTypes]) as any);
      }
    } else {
      navigate('/training');
    }
  }

  useEffect(() => {
    checkCourseData();
  }, []);

  function handleStart() {
    dispatch(startExercise(courseData));
    if (isFree) {
      setModalFreeUser(true);
    } else {
      navigate({
        pathname: '/ExerciseExecution',
        search: trainingToAttachId ? `?${createSearchParams({
          trainingToAttachId,
        })}` : '',
      });
    }
  }

  return (
    <Container>
      {
        (loading && <ExerciseCoverSkeleton />)
        || (
          <>
            <BackArrow text={courseData?.name || ''} callback={() => navigate(`/exerciseList/${courseData?.type}`)} />
            <Cover>
              <CardExercise
                style={{ backgroundImage: `url(${courseData?.cover_link})` }}
              >
                {courseData?.intensity === 1 && <BorderCard style={{ backgroundColor: theme.colors.graph }} />}
                {courseData?.intensity === 2 && <BorderCard style={{ backgroundColor: theme.colors.notifications }} />}
                {courseData?.intensity === 3 && <BorderCard style={{ backgroundColor: theme.colors.redAlert }} />}
                <InfoContainer>
                  <TitleContainer>
                    <h2>
                      {(isFree) && (
                        <AiFillLock />
                      )}
                      {courseData?.name}
                      <span>{courseData?.level}</span>
                    </h2>
                  </TitleContainer>
                  <OptionsContainer>
                    <p>
                      <AiFillSignal />
                      {' '}
                      {courseData?.intensity === 1 && 'Iniciante'}
                      {courseData?.intensity === 2 && 'Intermediário'}
                      {courseData?.intensity === 3 && 'Avançado'}
                    </p>
                    <p>
                      <BsClock />
                      {' '}
                      {courseData?.estimated_init}
                      {' '}
                      a
                      {' '}
                      {courseData?.estimated_end}
                      {' '}
                      minutos
                    </p>
                    <p>
                      <BiBody />
                      {' '}
                      {courseData?.equipments}
                    </p>
                  </OptionsContainer>
                </InfoContainer>
              </CardExercise>
              <List>
                <div>
                  {
                    courseData?.trainingGroups?.map((item: any) => (
                      <Accordion
                        key={item.id}
                        title={item.name}
                        showCompletitionIcon
                        handleExpandCallback={isFree ? () => setModalFreeUser(true) : undefined}
                      >
                        {
                          item.trainingActivities.map((activitie: any) => (
                            <ListContainer
                              key={activitie.id}
                              onClick={() => initVideo(activitie.name, activitie.tuto_link)}
                            >
                              <Thumb>
                                <img src={activitie.thumb_link} alt="" />
                                <p>
                                  <span>
                                    {activitie.repetition ? `${activitie?.repetition} x ` : `${Math.round(activitie?.duration * 60)}s `}
                                  </span>
                                  {activitie.name}
                                </p>
                              </Thumb>
                              {activitie.tuto_link && (
                                <InfoExercise>
                                  <p>
                                    <AiOutlineInfoCircle />
                                    <span> Como executar</span>
                                  </p>
                                </InfoExercise>
                              )}
                            </ListContainer>
                          ))
                        }
                      </Accordion>
                    ))
                  }
                </div>
              </List>
            </Cover>
            <ButtonsContainer>
              <Buttom
                primary
                onClick={() => handleStart()}
              >
                INICIAR
              </Buttom>
              <Buttom onClick={() => navigate(-1)}>
                VOLTAR
              </Buttom>
            </ButtonsContainer>
            <ExerciseInfoModal
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              title={videotitle}
              videoUri={videoUri}
            />
            <ModalFreeUser
              modalFreeUser={modalFreeUser}
              setModalFreeUser={setModalFreeUser}
              text="Esta série de exercícios está disponível apenas para membros do Corrida Perfeita."
            />
          </>
        )
      }
    </Container>
  );
}

export default ExerciseTrainingCover;

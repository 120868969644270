import styled from 'styled-components';
import { appTheme } from '../../theme';

export const ModalBackground = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ModalContent = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  max-height: 85%;
  background-color: #000033;
  padding: 15px;
  margin-top: 40px;
`;

export const ModalContentTitle = styled.p`
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;

export const ModalContentText = styled.p`
  white-space: pre-line;
  color: white;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
`;
export interface IButtonProps {
  color: string;
  inverse?: boolean
}

export const OutlineButton = styled.div<IButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  border-color: ${(props) => props.color};
  background-color: ${(props) => (props.inverse ? props.color : appTheme.background)};
  border-width: 1.5px;
  border-style: solid;
  margin: 8px;
  cursor: pointer;
`;

export const OutlineButtonText = styled.p<IButtonProps>`
  color: ${(props) => (props.inverse ? appTheme.background : props.color)};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const ModalContentSubTitleContainer = styled.div`
  margin-top: 15px;
  width: 85%;
`;

export const ModalContentButtonContainer = styled.div`
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 80%;
`;

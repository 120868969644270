import React, { Dispatch, SetStateAction } from 'react';
import {
  Container,
} from './styles';
import BaseModal from '../BaseModal';

export interface IZoneInfoModalProps {
  videoUri: string
  setIsVisible: Dispatch<SetStateAction<boolean>>
  visible: boolean
  title: string
}

function ZoneInfoModal({
  videoUri,
  visible,
  title,
  setIsVisible,
}: IZoneInfoModalProps) {
  return (
    <BaseModal
      setIsVisible={setIsVisible}
      isVisible={visible}
      title={title || ''}
    >
      <Container>
        <iframe className="embed-responsive-item" title="video" src={videoUri} allow="fullscreen" />
      </Container>
    </BaseModal>
  );
}

export default ZoneInfoModal;

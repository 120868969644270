import styled from 'styled-components';
import THEME from '../../../../../theme';

export const Card = styled.div`  
  border-radius: 5px;  
  margin-bottom: 10px;
`;

export const CardHeader = styled.div`
  background-color: ${THEME.colors.cardB3};
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;  
  margin-bottom: 1px;
`;

export const CardHeaderDate = styled.div`
`;

export const TextDate = styled.div`  
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;    
  color: ${THEME.colors.iconsOff};
`;

export const CardOptions = styled.div`
`;

export const CardOptionsButton = styled.div`
   display: flex;
   align-items: flex-end;  
    cursor: pointer;
`;

export const CardContent = styled.div`
   width: 100%;
   padding: 15px;
   background-color: ${THEME.colors.cardB3};
   cursor: pointer;
`;

export const CardTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${THEME.colors.title};
  margin-bottom: 15px;
`;

export const CardText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${THEME.colors.title};  
`;

export const IcoCircle = styled.div`
  width  : 5px ;
  height: 5px;
  border-radius: 5px;
  background-color: #FFE100;
  margin-right: 10px;
`;

export const ContentIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

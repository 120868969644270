import styled from 'styled-components';
import THEME from '../../../../theme';

export const Container = styled.div`  
  display: flex;
  flex-direction: column;
`;

interface IButton {
  bottomBorder?: boolean;
}

export const Button = styled.div<IButton>`  
  padding: 12px 25px 10px;
  cursor: pointer;

  ${(props: IButton) => props.bottomBorder && `
    border-bottom-width: 3px;
    border-bottom-color: ${THEME.colors.cardB2};
    border-bottom-style: solid;
  `}


  &:hover {
    background-color: ${THEME.colors.cardB2};
  }
`;

export const ButtonText = styled.div`  
  font-weight: 400;
  font-size: 20px;
  color:${THEME.colors.title};
`;

import React, { useEffect } from 'react';
import { FaRegUser } from 'react-icons/fa';
import { BsQuestionCircle } from 'react-icons/bs';
import { FiGift } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Brand, Container, Main, Menu,
  Logo,
  MenuButton,
  Title,
} from './styles';
import logo from '../../assets/logo.png';
import theme from '../../theme';
import useNavbar from '../../hooks/useNavbar';
import { RootState } from '../../store';
import { fetchNotifications } from '../../store/Notifications.Store';
import IconNotifications from '../IconNotifications';
import { loadPermissions } from '../../store/Permissions.store';

export default function Header() {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<any>();
  const notificationsViewed = useSelector((state: RootState) => state.notifications.notViewed);

  const { user, token } = auth;

  const firstName = user.name ? user.name.split(' ')[0] : '';

  const { selected } = useNavbar();

  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
  };

  const goToHome = () => {
    navigate('/home');
  };
  const goToIndication = () => {
    navigate('/indication');
  };

  const goToHelp = () => {
    navigate('/help');
  };

  useEffect(() => {
    dispatch(loadPermissions() as any);
    dispatch(fetchNotifications());
  }, []);

  const permissions = useSelector((state: RootState) => state.permissions.items || []);

  const [indicationCentralPermission, setIndicationCentralPermission] = React.useState(false);

  useEffect(() => {
    if ((typeof permissions !== 'string') && (typeof permissions !== 'number') && permissions.length && permissions.find((p) => p.identifier === 'INDICATION_CENTRAL')) {
      setIndicationCentralPermission(true);
    }
  }, [permissions]);

  return (
    <Container>
      <Main>
        <Brand>
          <Logo src={logo} alt="logo" onClick={goToHome} style={{ cursor: 'pointer' }} />
          {selected === 'home' && firstName
            && (
              <Title>
                Olá,
                {' '}
                {firstName}
              </Title>
            )}
        </Brand>

        <Menu>
          {indicationCentralPermission && (
            <MenuButton onClick={goToIndication}>
              <FiGift
                size={22}
                color={theme.colors.title}
              />
            </MenuButton>

          )}
          {token && (
            <>
              <MenuButton type="button" onClick={goToHelp}>
                <BsQuestionCircle size={22} color={theme.colors.title} style={{ marginTop: 2 }} />
              </MenuButton>

              <MenuButton type="button" onClick={goToProfile}>
                <FaRegUser size={20} color={theme.colors.title} />
                {notificationsViewed >= 1 && <IconNotifications />}
              </MenuButton>
            </>
          )}
        </Menu>
      </Main>
    </Container>
  );
}

import React from 'react';

import {
  ChartContainer,
  Container,
  Label,
  ChartBar,
  SpeedLabel,
} from './styles';

interface PropsI {
  speed: number
}

const SPEED = [
  'Lento',
  'Moderado',
  'Rápido',
];

function ExercisePanelSpeed({ speed }: PropsI) {
  return (
    <Container data-testid="exercise-panelspeed">
      <Label>
        Velocidade
      </Label>
      <ChartContainer>
        <ChartBar condition={speed >= 1} height={5} />
        <ChartBar condition={speed >= 2} height={9} />
        <ChartBar condition={speed >= 3} height={13} />
      </ChartContainer>
      <SpeedLabel>
        {SPEED[speed - 1]}
      </SpeedLabel>
    </Container>
  );
}

export default ExercisePanelSpeed;

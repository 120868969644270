import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api2 } from '../services/api';

export interface INotification {
  _id: string;
  title: string;
  action_url?: string;
  content: string;
  viewed: boolean;
  viewed_at?: string;
  created_at?: string;
  updated_at?: string;
}

interface IState {
  notifications: INotification[];
  notViewed: number;
  loading?: boolean;
}

const initialState: IState = {
  notifications: [],
  notViewed: 0,
  loading: false,
};

export const fetchNotifications = createAsyncThunk('notifications/fetch', async () => {
  const response = await api2.get('notifications');
  return response.data.data;
});

export const markAsRead = createAsyncThunk('notifications/markAsRead', async (_id: string) => {
  await api2.put(`/notifications/${_id}/visualize`);
  return _id;
});

export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async (_id: string) => {
  await api2.delete(`notifications/${_id}`);
  return _id;
});

export const markAllAsRead = createAsyncThunk('notifications/markAllAsRead', async () => {
  const response = await api2.put('/notifications/visualize-all');
  return response.data;
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loading = false;
    });

    builder.addCase(markAsRead.fulfilled, (state, action) => {
      const _id = action.payload;
      const item = state.notifications.find((notif) => notif._id === _id);
      if (item) {
        item.viewed = true;
      }
    });

    builder.addCase(markAllAsRead.fulfilled, (state, action) => {
      const modifiedNotifications = action.payload;
      state.notifications = state.notifications.map((notification) => (modifiedNotifications.some((modified: INotification) => modified._id === notification._id)
        ? { ...notification, viewed: true }
        : notification));
    });

    builder.addCase(deleteNotification.fulfilled, (state, action) => {
      const _id = action.payload;
      const item = state.notifications.find((notif) => notif._id === _id);
      if (item) {
        state.notifications = state.notifications.filter((notif) => notif._id !== _id);
      }
    });
  },
});

export default notificationsSlice.reducer;

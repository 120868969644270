import React from 'react';
import ContentLoader from 'react-content-loader';
import theme from '../../theme';

interface SkeletonGenericProps {
  customContainerStyle?: React.CSSProperties;
  boneColor?: string;
  highlightColor?: string;
  animationSpeed?: number;
}

function SkeletonGeneric({
  customContainerStyle = {
    height: '100%',
    width: '100%',
    borderRadius: 10,
  },
  boneColor = theme.colors.cardB2,
  highlightColor = theme.colors.gray,
  animationSpeed = 2,
}: SkeletonGenericProps) {
  return (
    <ContentLoader
      style={customContainerStyle}
      speed={animationSpeed}
      backgroundColor={boneColor}
      foregroundColor={highlightColor}
      viewBox={`0 0 ${customContainerStyle.width} ${customContainerStyle.height}`}
    >
      <rect x="0" y="0" rx={customContainerStyle.borderRadius} ry={customContainerStyle.borderRadius} width={customContainerStyle.width} height={customContainerStyle.height} />
    </ContentLoader>
  );
}

export default SkeletonGeneric;

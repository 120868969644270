import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import { AiOutlineBell } from 'react-icons/ai';
import Modal from '../../components/AccountModal';
import { RootState } from '../../store';
import { fullLogout } from '../../store/Auth.store';

import ProfileCard, { PROFILE_FIELD } from '../../components/ProfileCard';
import {
  Container, AvatarContainer, Avatar, AvatarDefault,
  ProfileContainer, InfoContainer, PolicyContainer, LinkText, VersionText, LogoutButton, Buttons, ButtonProfile, ButtonNotifications, TextButton, TextButtonOff, IconNotification,
} from './style';
import ProfilePictureCard from '../../components/ProfilePictureCard';
import Loader from '../../components/Loader';
import theme from '../../theme';

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const showFin = user.company_id === 1;
  const notificationIcon = useSelector((state: RootState) => state.notifications.notViewed);
  const { strava_config } = useSelector((state: RootState) => state.integration);

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [updatePictureLoading, setUpdatePictureLoading] = useState(false);

  const dispatchLogout = () => {
    dispatch(fullLogout() as any);
    navigate('/login');
  };

  const toggleLogoutModal = () => {
    setIsLogoutModalOpen((isOpen) => !isOpen);
  };

  const handleNotificationsClick = () => {
    navigate('/notifications');
  };

  return (
    <>
      <Container data-testid="profile">
        <Buttons>
          <ButtonProfile>
            <FaCog color={theme.colors.title} size={15} />
            <TextButton>
              PERFIL E CONFIGURAÇÕES
            </TextButton>
          </ButtonProfile>
          <ButtonNotifications onClick={handleNotificationsClick}>
            <AiOutlineBell color={theme.colors.iconsOff} size={15} />
            <TextButtonOff>
              Notificações
              {notificationIcon >= 1 && <IconNotification />}
            </TextButtonOff>
          </ButtonNotifications>
        </Buttons>
        <AvatarContainer>
          {
            updatePictureLoading
              ? <Loader color={theme.colors.title} />
              : (
                <>
                  {
                    (!!user.s_photo || !!strava_config?.photo_url)
                      ? <Avatar src={user.s_photo || strava_config?.photo_url} />
                      : <AvatarDefault />
                  }
                </>
              )
          }
          <LogoutButton onClick={toggleLogoutModal}>Encerrar sessão</LogoutButton>
        </AvatarContainer>

        <ProfileContainer>
          <ProfilePictureCard
            header="Foto de perfil"
            info="Personalize com sua foto..."
            setUpdatePictureLoading={setUpdatePictureLoading}
          />
          <ProfileCard
            header="Informações Pessoais"
            field={PROFILE_FIELD.INFORMACOES_PESSOAIS}
            info="Nome, Nascimento, Peso, Altura..."
          />
          <ProfileCard
            header="Meus Tênis"
            field={PROFILE_FIELD.MEUS_TENIS}
            info="Cadastre seu tênis e contabilize os kms..."
          />
          <ProfileCard
            header="Dados da Conta"
            field={PROFILE_FIELD.DADOS_DA_CONTA}
            info="Email, Telefone, Senha..."
          />
          {showFin
            && (
              <ProfileCard
                header="Financeiro"
                field={PROFILE_FIELD.FINANCEIRO}
                info="Plano de adesão, Pagamento..."
              />
            )}
          <ProfileCard
            header="Integrações"
            field={PROFILE_FIELD.INTEGRACOES}
            info="Com seu relógio, app de registros de treinos..."
          />
        </ProfileContainer>
        <InfoContainer>
          <PolicyContainer>
            <LinkText onClick={() => { window.open('https://corridaperfeita.com/politica/'); }}>Política de Privacidade</LinkText>
            <LinkText onClick={() => { window.open('https://corridaperfeita.com/termos/'); }}>Termos de uso</LinkText>
          </PolicyContainer>
          <VersionText>Versão 1.1.0</VersionText>
        </InfoContainer>

        <Modal
          isOpen={isLogoutModalOpen}
          toggle={toggleLogoutModal}
          onClick={dispatchLogout}
          title="Encerrar sessão"
          info="Deseja mesmo encerrar a sessão?"
        />
      </Container>

    </>

  );
}

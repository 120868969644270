import styled from 'styled-components';
import { appTheme } from '../../../theme';

interface ItemContainerProps {
  index: number;
}

export const ItemContainer = styled.button<ItemContainerProps>`
  width: 100%;
  height: 60px;
  border: unset;
  background-color: ${(props) => (props.index % 2 === 0 ? appTheme.cardB3 : appTheme.cardB2)};
  border-top-left-radius: ${(props) => (props.index === 0 ? '10px' : '0')};
  border-top-right-radius: ${(props) => (props.index === 0 ? '10px' : '0')};
  border-bottom-left-radius: ${(props) => (props.index === 6 ? '10px' : '0')};
  border-bottom-right-radius: ${(props) => (props.index === 6 ? '10px' : '0')};
  display: flex;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const ItemText = styled.p`
  margin: 0 2px;
  color: ${appTheme.title};
  font-size: 14px;
`;

export const ItemTextZone = styled.p`
  margin: 0 2px;
  color: ${appTheme.title};
  font-size: 15px;
  font-weight: bold;
`;

export const ZoneInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 10px 0;

  p {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: start;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4%;
`;

export const InfoContainer = styled.div`
  height: 100%;
  flex: 1;
  justify-content: center;
  padding-left: 2%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

interface ColorIndicatorProps {
  color: string;
  index: number;
}

export const ColorIndicator = styled.div<ColorIndicatorProps>`
  width: 4.5%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-top-left-radius: ${(props) => (props.index === 0 ? '10px' : '0')};
  border-bottom-left-radius: ${(props) => (props.index === 6 ? '10px' : '0')};
`;

import styled from 'styled-components';
import theme from '../../theme';

export const ItemContainer = styled.section`
    display: flex;
    background: ${theme.colors.cardB2};
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    min-height: 150px;
    border-radius: 10px;

    img {
            height: 80px;
            width: 80px;
        }

    @media (max-width: 667px) {
        min-height: 100px;

        img {
            height: 50px;
            width: 50px;
        }
    }
`;

export const ItemTitle = styled.span`
    color: ${theme.colors.title};
    text-align: center;
    margin-top: 5%;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 667px) {
        font-size: 10px;
    }
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

import styled from 'styled-components';

const InconNotification = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: yellow;
    position: absolute;
    right: 0;
    top: 6px;
`;

export default InconNotification;

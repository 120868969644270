import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  padding: 15px;
  width: 100%;

`;

export const CouponCodeButton = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

export const CouponText = styled.div`
  background-color: ${theme.colors.title};
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 18px;
  font-weight: 700;
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CopyClick = styled.div`
  background-color: ${theme.colors.cardB3};
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  color: ${theme.colors.gray};
  font-weight: bold;
  font-size: 15px;
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  color:#FBE308;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  max-width: 80%;
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  align-self: center;
  cursor: pointer;
  @media (max-width: 667px) {
      padding: 5px;
  }
`;

export const ButtonText = styled.div`
  color: #000033;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 667px) {
      font-size: 10px;
  }

`;

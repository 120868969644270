import React from 'react';
import { BiChevronRight, BiInfoCircle } from 'react-icons/bi';
import { BsPauseCircle } from 'react-icons/bs';
import theme from '../../theme';

import ModalButton from '../ModalButton';
import {
  Container,
  Content,
  InstructionsText,
  IntrusctionsContainer,
  SkipSerieButton,
  SkipSerieText,
  StatusContainer,
  StatusText,
} from './styles';

interface PropsI {
  onPressContinue: () => void
  onPressAbandon: () => void
  onPressNextSerie: () => void
  openInfo: () => void
  hasTutoLink: boolean
}

function ExercisePauseModal({
  onPressContinue,
  onPressAbandon,
  onPressNextSerie,
  openInfo,
  hasTutoLink,
}: PropsI) {
  return (
    <Container data-testid="exercise-pause-modal">
      <Content hasTutoLink={hasTutoLink}>
        {hasTutoLink && (
          <IntrusctionsContainer
            onClick={openInfo}
          >
            <BiInfoCircle size={18} color={theme.colors.title} />
            <InstructionsText>
              Como executar
            </InstructionsText>
          </IntrusctionsContainer>
        )}
        <StatusContainer>
          <BsPauseCircle size={16} color={theme.colors.title} />
          <StatusText>
            PARADO
          </StatusText>
        </StatusContainer>
      </Content>
      <div>
        <ModalButton onPress={onPressContinue} title="CONTINUAR TREINO" />
        <ModalButton
          onPress={onPressAbandon}
          danger
          title="ABANDONAR TREINO"
        />
      </div>
      <SkipSerieButton
        onClick={onPressNextSerie}
      >
        <SkipSerieText>PULAR SÉRIE</SkipSerieText>
        <BiChevronRight size={28} color={theme.colors.title} />
      </SkipSerieButton>
    </Container>
  );
}
export default React.memo(ExercisePauseModal);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  ContainerImg,
  Header,
  InfoContainer,
  TextInfo,
} from './styles';
import Trophy from '../../assets/trophy.svg';

export default function NewObjective() {
  const navigate = useNavigate();

  return (
    <Container data-testid="new-objective-container">
      <ContainerImg>
        <img src={Trophy} alt="Seu novo objetivo" />
      </ContainerImg>
      <Header>
        Seu novo objetivo
      </Header>
      <InfoContainer>
        <TextInfo>
          Defina um objetivo para a sequência do seu treinamento e solicite um plano para alcançá-lo.
        </TextInfo>
      </InfoContainer>
      <Button onClick={() => { navigate('/request'); }}>
        SOLICITAR PLANO DE TREINO
      </Button>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ExerciseCategories from '../../components/ExerciseCategories';
import Tabs from '../../components/TabsButton';
import ZoneList from '../../components/ZoneList';
import TrainingCalendar from '../../components/TrainingCalendar';

import useNavbar from '../../hooks/useNavbar';
import { Container } from './styles';

import meuTreino from '../../assets/Training/Menu/meu-treino.svg';
import exercicios from '../../assets/Training/Menu/exercicios.svg';
import zonas from '../../assets/Training/Menu/zonas.svg';

import { hasAccess } from '../../services/utils';

export interface Itab {
  label: string;
  index: number;
  Component: React.FC<{}>;
  icon: string;
  alt: string;
}

export default function Training() {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  let tabs: Itab[] | null = null;

  if (hasAccess(user, ['BASIC'])) {
    tabs = [
      {
        label: 'EXERCÍCIOS',
        index: 1,
        Component: ExerciseCategories,
        icon: exercicios,
        alt: 'exercícios',
      },
    ];
  } else {
    tabs = [
      {
        label: 'MEU TREINO',
        index: 1,
        Component: TrainingCalendar,
        icon: meuTreino,
        alt: 'meu treino',
      },
      {
        label: 'EXERCÍCIOS',
        index: 2,
        Component: ExerciseCategories,
        icon: exercicios,
        alt: 'exercícios',
      },
      {
        label: 'ZONAS',
        index: 3,
        Component: ZoneList,
        icon: zonas,
        alt: 'zonas',
      },
    ];
  }
  const location = useLocation();
  const { setSelected } = useNavbar();
  const [selectedTab, setSelectedTab] = useState<number>(1);

  useEffect(() => {
    setSelected('training');

    if (location.state) {
      const routeState = location.state as any;

      setSelectedTab(routeState.selectedTabIndex);
    }
  }, []);

  return (
    <Container>
      <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
    </Container>
  );
}

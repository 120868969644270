import React from 'react';
import { IoMdClose } from 'react-icons/io';
import theme from '../../theme';
import {
  Container,
  Content,
  ControlContainer,
  ControlTitle,
  Title,
  TitleContainer,
} from './styles';

function ExerciseInfoModal({
  setIsVisible, isVisible, title, videoUri,
} : any) {
  return (
    <>
      {
        isVisible
        && (
          <Container data-testid="exercise-info-modal">
            <Content>
              <TitleContainer>
                <Title>
                  EXECUÇÃO DO EXERCÍCIO
                </Title>
                <span onClick={() => setIsVisible(false)} role="presentation">
                  <IoMdClose size={18} color={theme.colors.title} />
                </span>
              </TitleContainer>
              <iframe className="embed-responsive-item" title="video" src={videoUri} allow="fullscreen" />

              <ControlContainer>
                <ControlTitle>
                  {title}
                </ControlTitle>
              </ControlContainer>
            </Content>
          </Container>
        )
      }
    </>
  );
}

export default ExerciseInfoModal;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`  
  justify-content: center;
  align-items: center;  
  margin-bottom: 15px;
`;

export const Label = styled.p`
  color: ${theme.colors.text};
  font-weight: 600;
  font-size: 8px;  
  @media (min-width: 768px) {
    font-size: 12px;    
  }
  span{
    background: ${theme.colors.cardB3};  
    padding: 3px 5px;
    border-radius: 4px;
  }
`;

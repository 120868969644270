import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const Container = styled.div`
    background-color: ${appTheme.background};
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 70px;
`;

export const Label = styled.div`
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LabelText = styled.p`
    font-size: 12px;
    color: ${appTheme.title};
    font-weight: bold;
`;

export const Fields = styled.div`
    background-color: ${appTheme.cardB2};
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    height: 100%;
    border-radius: 5px;
    padding: 10px;
`;

export const SeparatorWithoutLeftMargin = styled.div`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`;

export const Separator = styled.div`
    margin-left: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`;

export const SeparatorTitle = styled.p`
    color: ${appTheme.title};
    margin-bottom: 5px;
    font-size: 12px;
`;

import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import BackArrow from '../../components/BackArrow';
import HelpItem from '../../components/HelpItem';
import Attendence from '../../assets/help/atendimento.svg';

import Financial from '../../assets/help/financeiro.svg';
import Suport from '../../assets/help/suporte.svg';
import Questions from '../../assets/club/tira-duvidas.svg';

import Container from './styles';
import { hasAccess } from '../../services/utils';

export default function Help() {
  const auth = useSelector((state: RootState) => state.auth);

  const { user, coach } = auth;
  const items = [
    {
      key: 1,
      title: 'Central tira-dúvidas',
      desc: 'A maioria de suas dúvidas já pode estar respondida em nossa central tira-dúvidas. Confira!',
      image: Questions,
      onPress: 'https://ajuda.corridaperfeita.com/support/home',
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    },

    {
      key: 2,
      title: `atendimento com ${coach?.genre === 'Feminino' ? 'sua treinadora' : 'seu treinador'} `,
      desc: 'Fale diretamente com seu treinador sobre seus treinos e dúvidas de uso da plataforma.',
      image: Attendence,
      screen: 'CoachAttendance',
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'START']),
      blockedInFree: true,
      showAvatar: true,
    },
    {
      key: 3,
      title: 'Atendimento financeiro',
      desc: 'Dúvidas sobre assinatura, pagamentos, etc.',
      image: Financial,
      screen: 'financial-service',
      show: hasAccess(user, ['ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
      showInFree: false,
      blockedInFree: true,
    },
    {
      key: 4,
      title: 'Suporte técnico',
      desc: 'Reporte problemas técnicos na plataforma.',
      image: Suport,
      screen: 'tecnical-suport',
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    }];

  const renderItems = () => items
    .filter((item) => item.show)
    .map((item) => <HelpItem content={item} key={item.key} />);

  return (
    <Container>
      <BackArrow text="AJUDA" bigger backTo="/home" />
      {renderItems()}
    </Container>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';

import api from '../services/api';

const trainingDay = createSlice({
  name: 'trainingDay',
  initialState: {
    trainingDay: [],
    loading: true,
    error: null,
  },
  reducers: {
    getTrainingsStart: (state) => {
      state.trainingDay = [];
      state.loading = true;
      state.error = null;
    },
    getTrainingsSuccess: (state, action) => {
      state.trainingDay = action.payload;
      state.loading = false;
      state.error = null;
    },
    getTrainingsFailure: (state, action) => {
      state.trainingDay = [];
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getTrainingsStart, getTrainingsSuccess, getTrainingsFailure } = trainingDay.actions;

export default trainingDay.reducer;

export const requestTrainings = (): AppThunk => async (dispatch, getState) => {
  dispatch(getTrainingsStart());

  try {
    const response = await api.get(`/training-day?trainingPlanId=${getState().trainingPlan.planId}`);

    dispatch(getTrainingsSuccess(response?.data?.trainings ?? []));
  } catch (error: any) {
    dispatch(getTrainingsFailure(error?.message ?? JSON.stringify(error)));
  }
};

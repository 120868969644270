import styled from 'styled-components';
import { appTheme } from '../../../../theme';

export const ModalBackground = styled.div`
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const ModalContent = styled.div`
    flex-direction: column;
    justify-content: flex-start;
    width: 95%;
    max-height: 85%;
    background-color: #000033;
    padding: 15px;
    margin-top: 40px;
`;

export const ModalContentBody = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

export const ModalContentTitle = styled.p`
    color: #fff;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
`;

export const ModalContentSubTitleContainer = styled.div`
    margin-top: 15px;
`;

export const ModalContentSubTitle = styled.p`
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
`;

export const ModalContentButtonContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 50%;
`;

export const ModalContentButton = styled.button`
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: ${appTheme.graph};
    display: flex;
`;

export const ModalContentButtonText = styled.p`
    color: #fff;
    font-size: 16px;
`;

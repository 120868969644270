import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import {
  Container,
  BackArrow,
  Button,
  Title,
  Text,
  GroupButton,
  ButtonTitle,
} from './style';
import theme from '../../theme';

export default function NutritionGuide({ bigger }: { bigger: boolean }) {
  const navigate = useNavigate();
  const goToBack = () => {
    navigate('/club');
  };

  return (
    <Container>
      <BackArrow>
        <Button type="button" onClick={goToBack}>
          <BsChevronLeft color={theme.colors.title} size={bigger ? 24 : 22} />
        </Button>
        <Title onClick={goToBack}>GUIA DE NUTRIÇÃO</Title>
      </BackArrow>
      <Text>
        Oferecemos um guia com orientações essenciais sobre nutrição para praticantes de corrida, principalmente para o
        pré, durante e pós corrida. São informações básicas para uma melhor conduta alimentar, que não substituem o
        apoio de um profissional de Nutrição.
      </Text>
      <GroupButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.open('https://cp-static-files.s3.sa-east-1.amazonaws.com/eBookCorridaeAlimentacao.pdf', '_blank');
        }}
      >
        <ButtonTitle>ACESSAR GUIA</ButtonTitle>
      </GroupButton>
    </Container>
  );
}
